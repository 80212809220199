import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  CompositeDecorator,
  DraftHandleValue,
  DraftModel,
  EditorState,
  KeyBindingUtil,
  Modifier,
  RichUtils,
  SelectionState,
  convertFromRaw,
  getDefaultKeyBinding
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import { plugins, InlineToolbar, customStyleMap } from './plugins';
import { ItalicButton, BoldButton, UnderlineButton } from '@draft-js-plugins/buttons';

import {
  HightLightButton,
  HightLightButtonSelected,
  LowercaseButton,
  SentencecaseButton,
  StrikeThroughButton,
  UppercaseButton
} from './InlineToolbarButtons';
import { TTranscript } from 'ts/types';
import { createRawContent } from 'utils/draftjs';
import { findEntitiesFunction } from 'utils/common';
import TimeCodedWord from './TimeCodedWord';
import { useVideoPlayerContext } from 'context/VideoPlayerContext';
import TranscriptRow from './TranscriptRow';
import TranscriptRowRefactor from './TranscriptRowRefactor';

type Props = {
  listTranscriptions: TTranscript[];
};
function PS4Refactor({ listTranscriptions }: Props) {
  const editorRef = useRef<Editor>(null);
  const { currentTime, isPlaying, userDefinedTimeCode, seekTo } = useVideoPlayerContext();
  const [currentEditorState, setCurrentEditorState] = useState(EditorState.createEmpty()); //for render display

  let scrollTimeout: any;

  function handleOnChange(editorState: EditorState) {
    setCurrentEditorState(editorState);
  }

  function forceCompositeDecorator(editorState: DraftModel.ImmutableData.EditorState) {
    const compositeDecorator = new CompositeDecorator([
      {
        strategy: findEntitiesFunction('TIME_CODED_WORD'),
        component: TimeCodedWord
      }
    ]);

    const newEditorState = EditorState.set(editorState, {
      decorator: compositeDecorator
    });
    return newEditorState;
  }

  function getInitialEditorState({ transcript }: { transcript: Array<TTranscript> }) {
    if (transcript) {
      const rawContent = createRawContent(transcript);

      if (rawContent) {
        const initialContentState = convertFromRaw(rawContent);
        const newState = forceCompositeDecorator(
          EditorState.createWithContent(initialContentState)
        );
        setCurrentEditorState(newState);
      }
    } else {
      setCurrentEditorState(EditorState.createEmpty());
    }
  }

  function handleBlockRendererFn(block: DraftModel.ImmutableData.ContentBlock) {
    const type = block.getType();
    const autoSpeaker = block.getData().get('autoSpeaker');
    const transcriptId = block.getData().get('id');
    if (type === 'TranscriptRow') {
      return {
        component: TranscriptRow,
        props: {
          onJumpToBlock: (startTime: number) => seekTo(startTime, 'seconds'),
          // updateAllMatchingAutoSpeaker: (speaker: TSpeaker) =>
          //   updateAllMatchingAutoSpeaker(speaker, transcriptId, autoSpeaker),
          userDefinedTimeCode: userDefinedTimeCode
          // updateAnnotation: () => {
          //   handleUpdateAnnotation();
          // },
          // onChangeInputAnnotation: (value: string) => {
          //   setEditorState(
          //     EditorState.push(
          //       editorState,
          //       Modifier.mergeBlockData(
          //         editorState.getCurrentContent(),
          //         SelectionState.createEmpty(block.getKey()),
          //         block.getData().set('annotation', value)
          //       ),
          //       'change-block-data'
          //     )
          //   );
          // }
        }
      };
    }
  }

  function handleScroll() {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      console.log('force render');
      const compositeDecorator = new CompositeDecorator([
        {
          strategy: findEntitiesFunction('TIME_CODED_WORD'),
          component: TimeCodedWord
        }
      ]);

      const newEditorState = EditorState.set(currentEditorState, {
        decorator: compositeDecorator
      });
      setCurrentEditorState(newEditorState);
    }, 250);
  }

  useEffect(() => {
    getInitialEditorState({ transcript: listTranscriptions });
  }, [listTranscriptions]);

  useEffect(() => {}, [editorRef]);

  return (
    <div
      className="ctn-ps4 py-1 rounded border overflow-y-scroll h-100"
      onScroll={() => handleScroll()}
    >
      <Editor
        ref={editorRef}
        editorState={currentEditorState}
        plugins={plugins}
        customStyleMap={customStyleMap}
        onChange={handleOnChange}
        blockRendererFn={handleBlockRendererFn}
      />
      <InlineToolbar>
        {(externalProps) => {
          return (
            <div>
              {currentEditorState.getCurrentInlineStyle().has('HIGHLIGHT') ? (
                <HightLightButtonSelected {...externalProps} />
              ) : (
                <HightLightButton {...externalProps} />
              )}
              <StrikeThroughButton {...externalProps} />
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <SentencecaseButton {...externalProps} />
              <UppercaseButton {...externalProps} />
              <LowercaseButton {...externalProps} />
            </div>
          );
        }}
      </InlineToolbar>
    </div>
  );
}

export default PS4Refactor;
