import { TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from '../axios-client';
import { TCommentPublish, TProject } from 'ts/types';

export async function getLastestPublishedProject({
  pageSize = 25,
  pageNumber = 0
}: {
  pageSize?: number;
  pageNumber?: number;
}): Promise<TResponse<{ data: TProject[]; total: number }>> {
  const res = await AxiosCustom.get(
    `published-projects?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  return { data: res.data, total: Number(res.headers['x-pagination-total']) };
}

export async function getLastestCommentPublishedProject({
  pageSize = 25,
  pageNumber = 0
}: {
  pageSize?: number;
  pageNumber?: number;
}): Promise<TResponse<TCommentPublish[]>> {
  const res = await AxiosCustom.get(
    `published-projects/annotations?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  return res.data;
}

export async function editLastestCommentPublishedProject({
  comment
}: {
  comment: TCommentPublish;
}): Promise<TResponse<TCommentPublish[]>> {
  const res = await AxiosCustom.put(
    `published-projects/annotations/${comment.transcriptionId}`,
    comment
  );
  return res.data;
}

export async function deleteCommentPublishedProject({
  transcriptionId
}: {
  transcriptionId: string | number;
}) {
  await AxiosCustom.delete(`published-projects/annotations/${transcriptionId}`);
}
