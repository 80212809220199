import { TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from '../axios-client';
import { TOwner, TTeam } from 'ts/types';
import { message, notification } from 'antd';

export async function getTeams(): Promise<TResponse<TTeam[]>> {
  const res = await AxiosCustom.get(`admin/teams`);
  return res.data;
}

export async function createTeams({ email }: { email: string }): Promise<TResponse<TTeam>> {
  const res = await AxiosCustom.post(`admin/teams`, { email });
  return res.data;
}

export async function deleteTeam({ id }: { id: number }) {
  const res = await AxiosCustom.delete(`admin/teams/${id}`);
  return res.data;
}

export async function getTeamWithId({ id }: { id: number | string }): Promise<TResponse<TTeam>> {
  const res = await AxiosCustom.get(`admin/teams/${id}`);
  return res.data;
}

export async function editTeamWithId({
  id,
  team
}: {
  id: number | string;
  team: TTeam;
}): Promise<TResponse<TTeam>> {
  const res = await AxiosCustom.put(`admin/teams/${id}`, team);
  return res.data;
}

export async function getOwnerWithId({ id }: { id: number | string }): Promise<TResponse<TOwner>> {
  const res = await AxiosCustom.get(`admin/teams/${id}/owner`);
  return res.data;
}

export async function getMemberWithId({
  id
}: {
  id: number | string;
}): Promise<TResponse<TOwner[]>> {
  const res = await AxiosCustom.get(`admin/teams/${id}/members`);
  return res.data;
}

export async function addMemberWithId({ id, email }: { id: number | string; email: string }) {
  const res = await AxiosCustom.post(`admin/teams/${id}/members`, { email });
  return res.data;
}

export async function deleteMemberWithId({
  id,
  memberId
}: {
  id: number | string;
  memberId: string;
}) {
  try {
    const res = await AxiosCustom.delete(`admin/teams/${id}/members/${memberId}`);
    return res.data;
  } catch (error: any) {
    notification.error({ message: error.response.data.message });
  }
}

export async function getTransactionWithId({
  id
}: {
  id: number | string;
}): Promise<TResponse<TOwner[]>> {
  const res = await AxiosCustom.get(`admin/teams/${id}/transactions`);
  return res.data;
}
