import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteTag, getListTags, updateTag } from 'apis/tags.api';
import { TTag } from 'ts/types';
import { TRequestUpdateTag } from 'ts/types/TRequest';

export const getListTagsAction = createAsyncThunk(
  'tags/getListTags',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getListTags();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTagAction = createAsyncThunk(
  'tags/createTag',
  async ({ tag }: { tag: TTag }, { rejectWithValue }) => {
    try {
      return tag;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTagAction = createAsyncThunk(
  'tags/updateTag',
  async ({ id, name, color }: TRequestUpdateTag, { rejectWithValue }) => {
    try {
      const res = await updateTag({ id, name, color });
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTagAction = createAsyncThunk(
  'tags/deleteTag',
  async ({ tag }: { tag: TTag }, { rejectWithValue }) => {
    try {
      await deleteTag({ tag });
      return tag;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
