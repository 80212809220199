import { TProjectTransaction } from 'ts/types';
import { AxiosCustom } from '../axios-client';
export async function getProjectsByUserID({
  userId
}: {
  userId: string;
}): Promise<{ totalElements: number; totalPages: number; content: TProjectTransaction[] }> {
  const res = await AxiosCustom.get(`admin/users/${userId}/projects`);
  return res.data;
}

export async function mergeProjects({
  target,
  sourceProjects
}: {
  target: string;
  sourceProjects: string[];
}) {
  const res = await AxiosCustom.post(`admin/projects/project/merge`, {
    target,
    'source-projects': sourceProjects
  });
  return res.status;
}
