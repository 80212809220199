const SearchHighlight = (props: any) => {
  const { selectionState, blockKey, start } = props;

  if (
    props.selectionState &&
    selectionState.anchorKey === blockKey &&
    start === selectionState.anchorOffset
  ) {
    return <span className="current-search-and-replace-highlight">{props.children}</span>;
  }

  return <span className="search-and-replace-highlight">{props.children}</span>;
};
export default SearchHighlight;
