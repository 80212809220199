import { createAsyncThunk } from '@reduxjs/toolkit';
import { changeContact, fetchSubscription, getUserInfor } from 'apis/auth.api';
import { TContact } from 'ts/types';

export const getUserInfoAction = createAsyncThunk('auth/login', async (_, { rejectWithValue }) => {
  const inviteCode = localStorage.getItem('inviteCode');
  try {
    const res = await getUserInfor(inviteCode || undefined);
    localStorage.removeItem('inviteCode');
    return res;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return rejectWithValue(error.response.status);
  }
});

export const changeContactAction = createAsyncThunk(
  'auth/change-contact',
  async ({ contact }: { contact: TContact | null }, { rejectWithValue }) => {
    try {
      await changeContact({ contact });
      return contact;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSubscriptionAction = createAsyncThunk(
  'auth/fetch-subcription',
  async (_, { rejectWithValue }) => {
    try {
      const res = await fetchSubscription();
      return res;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  }
);
