import { AutoComplete, Form, Input, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { addNewAdmin, deleteAdmin, getListAdmin, searchUser } from 'apis/admin/addRemoveAdmin';
import { useLoaderContext } from 'context/LoaderContext';
import { usePageLoading } from 'context/PageLoadingContext';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { FaHome } from 'react-icons/fa';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { ERole, ERoutes } from 'ts/enums';
import { TUser } from 'ts/types';
import { convertTimestampToDate } from 'utils/time';

interface DataType {
  id: string;
  fullName: string;
  email: string;
}

const AddRemoveAdminPage = () => {
  const [infoAdmin] = Form.useForm();
  const [addAdmin] = Form.useForm();

  const navigate = useNavigate();
  const { openMessage, destroyMessage } = usePageLoading();

  const [listAdmin, setListAdmin] = useState<DataType[]>([]);
  const [listUsers, setListUsers] = useState<TUser[]>([]);
  const [isSearchUserDone, setIsSearchUserDone] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<TUser | null>(null);
  const userData = useAppSelector((state) => state.auth.currentUser.data);
  const { loader } = useLoaderContext();

  const columns: ColumnsType<DataType> = [
    {
      title: 'Admin',
      dataIndex: 'fullName',
      key: 'fullName',
      width: '30%'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '50%'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: '20%',
      render: (record: string) => {
        return (
          <Popconfirm
            title={'Are you sure you want to remove this user?'}
            onConfirm={async () => {
              destroyMessage();
              openMessage('loading', 'Deleting...');
              const res = await deleteAdmin({ userId: record });
              if (res) {
                destroyMessage();
                openMessage('success', 'Delete success');
                getTableAdmin();
              } else {
                destroyMessage();
                openMessage('error', 'Delete fail!');
              }
            }}
            placement="left"
          >
            <div>
              <RiDeleteBin5Line color="var(--si-red)" className="cursor-pointer" />
            </div>
          </Popconfirm>
        );
      }
    }
  ];

  async function getTableAdmin() {
    loader.start();
    const res = await getListAdmin();
    if (res) {
      setListAdmin(res);
    }
    loader.complete();
  }

  const debouceSearch = debounce(async (value: string) => {
    if (value.length < 2) {
      setListUsers([]);
      setIsSearchUserDone(false);
      return;
    }
    loader.start();
    const res = await searchUser({ name: value });
    if (res) {
      setListUsers(res);
      setIsSearchUserDone(true);
    } else {
      setListUsers([]);
      setIsSearchUserDone(true);
    }
    loader.complete();
  }, 1000);

  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword);
    setIsSearchUserDone(false);
    debouceSearch(keyword);
  };

  const handleSubmitAddNewAdmin = async () => {
    if (!selectedUser) return;
    addAdmin.resetFields(['selectedUser']);
    destroyMessage();
    openMessage('loading', 'Adding...');
    loader.start();
    const res = await addNewAdmin({ userId: selectedUser.id });
    if (res) {
      destroyMessage();
      openMessage('success', 'Added!');
      getTableAdmin();
    } else {
      destroyMessage();
      openMessage('error', 'Add fail!');
    }
    setSearchKeyword('');
    loader.complete();
  };

  useEffect(() => {
    if (userData) {
      if (userData.role.name !== ERole.ROLE_SUPERADMIN) {
        navigate(ERoutes.LASTEST_TRANSACTION);
        return;
      }

      getTableAdmin();

      infoAdmin.setFieldsValue({
        name: userData.fullName,
        email: userData.email,
        joinDate: moment(convertTimestampToDate(userData.regDate)).format('mm/DD/YY hh:mm A')
      });
    }
  }, [userData]);

  return (
    <div className="add-remove-admin-page">
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome
            size={30}
            color="var(--si-primary)"
            onClick={() => navigate(ERoutes.LASTEST_TRANSACTION)}
            className="cursor-pointer"
          />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            Super Admin
          </span>
        </div>
      </div>
      <div className="container py-2">
        <Form form={infoAdmin} layout="vertical">
          <section className="section-admin p-2 mb-4 rounded">
            <label className="mb-2 title-admin">Super Admin</label>
            <div className="row">
              <Form.Item className="col-12 col-md-6" name={'name'} label="NAME">
                <Input className="w-100" height={34} disabled={true} />
              </Form.Item>
              <Form.Item className="col-12 col-md-6" name={'email'} label="EMAIL">
                <Input className="w-100" height={34} disabled={true} />
              </Form.Item>
              <Form.Item className="col-12 col-md-6" name={'joinDate'} label="JOIN DATE">
                <Input className="w-100" height={34} disabled={true} />
              </Form.Item>
            </div>
          </section>
        </Form>
        <Form form={addAdmin} onFinish={handleSubmitAddNewAdmin} layout="vertical">
          <section className="section-admin p-2 mb-4 rounded">
            <label className="mb-2 title-admin">Add Admin</label>
            <Form.Item className="col-12 col-md-6" label="SEARCH USER">
              <AutoComplete
                className={`w-100 user-search ${searchKeyword.length > 1 ? 'show-clear-icon' : ''}`}
                options={listUsers.map((user) => ({ value: user.email }))}
                onSelect={(value) => {
                  const user = listUsers.find((user) => user.email === value);
                  if (user) {
                    addAdmin.setFieldValue('selectedUser', `${user.fullName} (${user.email})`);
                    setSelectedUser(user);
                  }
                }}
                onSearch={handleSearch}
                value={searchKeyword}
                placeholder="Search User"
                allowClear={{ clearIcon: <AiOutlineClose /> }}
                notFoundContent={
                  searchKeyword.length > 1 && isSearchUserDone ? 'No matches found' : null
                }
              />
            </Form.Item>
            <div className="row align-items-end">
              <Form.Item className="col-12 col-md-6" name={'selectedUser'} label="SELECTED USER">
                <input
                  type="text"
                  className="disable-hover w-100"
                  placeholder="Search for Admin"
                  disabled
                />
              </Form.Item>
              <Form.Item className="col-12 col-md-6" name={'submit'} label={<div />}>
                <button
                  className="submit-button"
                  style={{ minWidth: 90, height: 34 }}
                  onClick={() => addAdmin.submit()}
                >
                  Add
                </button>
              </Form.Item>
            </div>
          </section>
        </Form>
        <Table columns={columns} dataSource={listAdmin} pagination={false} rowKey={'id'} />
      </div>
    </div>
  );
};

export default AddRemoveAdminPage;
