import { Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ERoutes, EStatusProject } from 'ts/enums';
import { BsThreeDots } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { setMedia } from 'redux/media/media.slice';

interface IControlLanguage {
  languageId: number;
  mediaId: string;
  code: string;
  label: string;
  original: boolean;
}

function LanguageControl() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const currentProject = useAppSelector((state) => state.projects?.currentProject);
  const listAllLanguages = useAppSelector((state) => state.languages.listAllLanguages);
  const dispatch = useAppDispatch();

  const [languages, setLanguages] = useState<IControlLanguage[]>([]);

  const mediaId = searchParams.get('mediaId');
  const currentMedia = currentProject.data?.medias?.find((media) => media.id === mediaId);

  useEffect(() => {
    if (
      !currentProject.data ||
      !currentMedia ||
      !listAllLanguages.isFetched ||
      !listAllLanguages.data
    )
      return;

    // Scan for all media with same audioFilePath
    var selectionList: IControlLanguage[] = [];

    currentProject.data.medias.forEach((m) => {
      if (m.audioFilePath != currentMedia?.audioFilePath) return;

      if (m.translateTargetLanguageId == null) {
        // Find the original language id
        let languageRegionCode = currentProject.data?.languageRegionCode?.split('-', 2);

        if (languageRegionCode?.length == 2) {
          let language = listAllLanguages.data?.find(
            (l) => l.code == (languageRegionCode ? languageRegionCode[0] : '')
          );
          if (language)
            selectionList.unshift({
              languageId: language.id,
              label: language.label,
              code: language.code,
              mediaId: m.id,
              original: true
            });
        }
      } else {
        let language = listAllLanguages.data?.find((l) => l.id == m.translateTargetLanguageId);
        if (language)
          selectionList.push({
            languageId: language.id,
            label: language.label,
            code: language.code,
            mediaId: m.id,
            original: false
          });
      }

      setLanguages(selectionList);
    });
  }, [currentMedia?.audioFilePath, listAllLanguages.isFetched]); // audioFilePath is the same for all translated media but will be different for each video

  const onClickLanguage = (
    lang: IControlLanguage,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (lang.mediaId != currentMedia?.id) {
      navigate(`${ERoutes.TRANSCRIPT_DETAIL}/${currentProject?.data?.id}?mediaId=${lang.mediaId}`);

      if (currentProject.data && currentProject.data.medias.length > 0) {
        const index = currentProject.data?.medias.findIndex((media) => media.id === lang.mediaId);
        if (index !== -1) {
          const media = currentProject.data?.medias[index];
          dispatch(setMedia({ media }));
        }
      }
    }
  };

  if (
    currentProject.isLoading ||
    !currentProject.isFetched ||
    !currentProject.data ||
    !currentMedia ||
    currentProject.data.projectStatus != EStatusProject.TRANSLATED
  )
    return null; // No data or not a translated project? Hide the language control

  const shortList = languages.slice(0, 5).map((l) => (
    <div
      onClick={onClickLanguage.bind(null, l)}
      key={l.languageId}
      className={`item ${l.mediaId == currentMedia.id ? 'active' : ''}`}
    >
      <img className="flag" src={`/img/flags/${l.code.toLowerCase()}.jpg`} />
      <span>{l.code.toUpperCase()}</span>
    </div>
  ));
  const expandedList = (
    <div className="ctn-mini-control-language expanded-list">
      {languages.map((l) => (
        <div
          onClick={onClickLanguage.bind(null, l)}
          key={l.languageId}
          className={`item ${l.mediaId == currentMedia.id ? 'active' : ''}`}
        >
          <img className="flag" src={`/img/flags/${l.code.toLowerCase()}.jpg`} />
          <span>{l.code.toUpperCase()}</span>
        </div>
      ))}
    </div>
  );

  return (
    <div className="ctn-mini-control-language p-0 rounded align-items-center justify-content-between border">
      <div className="wrapper d-flex flex-column p-1 pb-0 gap-1 rounded">{shortList}</div>
      {languages.length > 5 && (
        <Popover content={expandedList} title={t('Shared.Fields.Languages')} trigger="click">
          <div className="expand">
            <BsThreeDots />
          </div>
        </Popover>
      )}
    </div>
  );
}

export default React.memo(LanguageControl);
