import { TMetric } from 'ts/types';
import { TAxiosResponse } from 'ts/types/TResponse';

export function downloadFileLocally(response: TAxiosResponse<Blob>) {
  const data = response.data;
  const contentType = response.headers['content-type'];
  const contentDisposition = response.headers['content-disposition'];
  const filename = contentDisposition.split('filename=')[1];
  const blob = new Blob([data], { type: contentType + ';charset=utf-8' });

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.download = filename;
  a.href = url;
  a.textContent = filename;
  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, 100);

  return response;
}

export function exportCSV({
  metric,
  diffUser,
  diffCreateProject,
  diffTranscribed,
  diffTranslate,
  diffBurn,
  diffExported
}: {
  metric: TMetric;
  diffUser: number;
  diffCreateProject: number;
  diffTranscribed: number;
  diffTranslate: number;
  diffBurn: number;
  diffExported: number;
}) {
  var csvString = '';
  csvString +=
    'New Users,' +
    'Projects created,' +
    'Transcribed(' +
    metric.transcribed.transcribedFilesCount +
    '),' +
    'Translated(' +
    metric.translated.translatedFilesCount +
    '),' +
    'Burn-in(' +
    metric.burn.burnFilesCount +
    '),' +
    'Exported' +
    '\n';
  csvString +=
    metric.newUsers.length +
    ',' +
    metric.createdProject.createdProjectsCount +
    ',' +
    metric.transcribed.minutesTranscribed.toFixed(2) +
    ',' +
    metric.translated.minutesTranslated.toFixed(2) +
    ',' +
    metric.burn.minutesBurn.toFixed(2) +
    ',' +
    metric.exportedFile.length +
    '\n';
  csvString +=
    diffUser.toFixed(2) +
    '% From previous Period,' +
    diffCreateProject.toFixed(2) +
    '% From previous Period,' +
    diffTranscribed.toFixed(2) +
    '% From previous Period,' +
    diffTranslate.toFixed(2) +
    '% From previous Period,' +
    diffBurn.toFixed(2) +
    '% From previous Period,' +
    diffExported.toFixed(2) +
    '% From previous Period\n';
  csvString += '\n';

  csvString += 'New Users\n\n';

  for (let i = 0; i < metric.newUsers.length; i++) {
    csvString += metric.newUsers[i].email + '\n';
  }

  csvString += '\n\nProjects created\n\n';

  for (let i = 0; i < metric.createdProject.projectList.length; i++) {
    csvString += metric.createdProject.projectList[i].name + '\n';
  }

  csvString += '\n\nTranscribed\n\n';

  for (let i = 0; i < metric.transcribed.transcribedLanguageMap.length; i++) {
    csvString +=
      metric.transcribed.transcribedLanguageMap[i].title +
      ',' +
      metric.transcribed.transcribedLanguageMap[i].minutes.toFixed(2) +
      '\n';
  }

  csvString += '\n\nTranslated\n\n';

  for (let i = 0; i < metric.translated.translatedLanguageMap.length; i++) {
    csvString +=
      metric.translated.translatedLanguageMap[i].title +
      ',' +
      metric.translated.translatedLanguageMap[i].minutes.toFixed(2) +
      '\n';
  }

  csvString += '\n\nBurn-in\n\n';

  for (let i = 0; i < metric.burn.burnLanguageMap.length; i++) {
    csvString +=
      metric.burn.burnLanguageMap[i].title +
      ',' +
      metric.burn.burnLanguageMap[i].minutes.toFixed(2) +
      '\n';
  }

  csvString += '\n\nExported\n\n';

  for (let i = 0; i < metric.exportedFile.length; i++) {
    csvString += metric.exportedFile[i].title + ',' + metric.exportedFile[i].count + '\n';
  }

  const link = document.createElement('a');
  link.setAttribute('href', 'data:application/octet-stream;base64,' + btoa(csvString));
  link.setAttribute('download', 'ss-metrics.csv');
  document.body.appendChild(link); // Required for FF
  link.click();
}
