import { createAsyncThunk } from '@reduxjs/toolkit';
import { createFolder, deleteFolder, editFolder, getListFolder } from 'apis/folder.api';
import { TRequestCreateNewFolder, TRequestEditFolder } from 'ts/types/TRequest';

export const getListFolderAction = createAsyncThunk(
  'folder/getListFolder',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getListFolder();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createFolderAction = createAsyncThunk(
  'folder/createFolder',
  async ({ name, empty, encodedParentId }: TRequestCreateNewFolder, { rejectWithValue }) => {
    try {
      const res = await createFolder({ name, empty, encodedParentId });
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteFolderAction = createAsyncThunk(
  'folder/deleteFolder',
  async ({ folderId }: { folderId: string }, { rejectWithValue }) => {
    try {
      await deleteFolder({ folderId });
      return { folderId };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editFolderAction = createAsyncThunk(
  'folder/editFolder',
  async ({ name, id, encodedParentId }: TRequestEditFolder, { rejectWithValue }) => {
    try {
      const res = await editFolder({ name, id, encodedParentId });
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
