import { useTimeContext } from 'context/TimeContext';
import moment from 'moment';
import { useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { getMediaTimeCode } from 'utils/second-to-time';
import { convertNumberToString, getMiliSeconds, renderTime } from 'utils/time';

export const InputTime = ({
  userDefinedTimeCode,
  frameVideo,
  label,
  isOneInput = false,
  readOnly,
  timeCode,
  isDropFrame,
  validateTimeCode
}: {
  userDefinedTimeCode: number;
  frameVideo: number;
  label?: string;
  isOneInput?: boolean;
  readOnly?: boolean;
  timeCode: string;
  isDropFrame: boolean;
  validateTimeCode?: () => void;
}) => {
  const {
    hours,
    setHours,
    minute,
    setMinute,
    second,
    setSecond,
    frameCodeTime,
    setFrameCodeTime,
    setMiliSecond
  } = useTimeContext();

  const currentMedia = useAppSelector((state) => state.media.currentMedia);

  useEffect(() => {
    if (userDefinedTimeCode) {
      setHours(
        renderTime({
          time: userDefinedTimeCode,
          unit: 'hour'
        })
      );
      setMinute(
        renderTime({
          time: userDefinedTimeCode,
          unit: 'minute'
        })
      );
      setSecond(
        renderTime({
          time: userDefinedTimeCode,
          unit: 'second'
        })
      );
      setFrameCodeTime(frameVideo || 0);
      setMiliSecond(userDefinedTimeCode);
    } else if (currentMedia) {
      const startTimecodeOffset = getMediaTimeCode(currentMedia).time || '00:00:00';
      const frames = getMediaTimeCode(currentMedia).frames || 0;
      const millis = Number(frames) / currentMedia.fps;

      const [hours, minutes, seconds] = startTimecodeOffset.split(':').map(Number);

      const startTimeMilisecond = moment
        .duration({
          hours: hours,
          minutes: minutes,
          seconds: seconds
        })
        .asMilliseconds();

      const durationMiliseconds = (startTimeMilisecond + millis) | 0;
      setMiliSecond(durationMiliseconds);
      setFrameCodeTime(Number(frames));
      setHours(
        renderTime({
          time: durationMiliseconds,
          unit: 'hour'
        })
      );
      setMinute(
        renderTime({
          time: durationMiliseconds,
          unit: 'minute'
        })
      );
      setSecond(
        renderTime({
          time: durationMiliseconds,
          unit: 'second'
        })
      );
    }
  }, [timeCode]);

  return (
    <div className={`d-flex flex-column ${isOneInput ? 'time-code-wrapper' : 'time-code'}`}>
      {label && <label className="mb-1">{label}</label>}
      <div className="d-flex justify-content-between align-items-center group-input">
        <input
          type="number"
          name="hour"
          maxLength={2}
          readOnly={readOnly}
          value={`${convertNumberToString(hours)}`}
          onChange={(e) => {
            setHours(parseInt(e.target.value));
            setMiliSecond(
              getMiliSeconds({ hour: parseInt(e.target.value), minute, second: second })
            );
          }}
          onBlur={() => {
            validateTimeCode && validateTimeCode();
          }}
        />
        <label>:</label>
        <input
          type="number"
          name="minute"
          readOnly={readOnly}
          maxLength={2}
          value={`${convertNumberToString(minute)}`}
          onChange={(e) => {
            setMinute(parseInt(e.target.value));
            setMiliSecond(
              getMiliSeconds({ hour: hours, minute: parseInt(e.target.value), second: second })
            );
          }}
          onBlur={() => {
            validateTimeCode && validateTimeCode();
          }}
        />
        <label>:</label>
        <input
          type="number"
          name="second"
          maxLength={2}
          readOnly={readOnly}
          value={`${convertNumberToString(second)}`}
          onChange={(e) => {
            setSecond(parseInt(e.target.value));
            setMiliSecond(
              getMiliSeconds({ hour: hours, minute: minute, second: parseInt(e.target.value) })
            );
          }}
          onBlur={() => {
            validateTimeCode && validateTimeCode();
          }}
        />
        <label>{isDropFrame ? ';' : ':'}</label>
        <input
          type="number"
          name="frame"
          maxLength={2}
          readOnly={readOnly}
          value={`${convertNumberToString(frameCodeTime)}`}
          onChange={(e) => {
            setFrameCodeTime(parseInt(e.target.value));
            setMiliSecond(getMiliSeconds({ hour: hours, minute: minute, second: second }));
          }}
          onBlur={() => {
            validateTimeCode && validateTimeCode();
          }}
        />
      </div>
    </div>
  );
};
