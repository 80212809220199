import { ModalBase } from 'components/common';
import { IoMdClose } from 'react-icons/io';
import { PiWarningBold } from 'react-icons/pi';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { popModal, pushModal } from 'redux/modal/modal.slice';
import { EModals } from 'ts/enums';

export function ConfirmDeleteTeamModal({ handleDelete }: { handleDelete: (id: number) => void }) {
  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.modal.lastModal?.data);
  function handlePressNo() {
    dispatch(popModal());
  }

  return (
    <ModalBase
      modalName={EModals.CONFIRM_DELETE_TEAM_MODAL}
      onCloseModal={() => {
        dispatch(popModal());
      }}
      isCloseIcon={false}
      className="confirm-delete-team"
    >
      <div className="delete-content">
        <div className="row gx-1">
          <div className="icon col-2 d-flex align-items-center justify-content-center">
            <PiWarningBold size={20} />
          </div>
          <div className="text col-9">
            Are you sure you want to delete the team {data}
          </div>
          <div className="close-icon-custom col-1 text-end">
            <IoMdClose size={20} className="cursor-pointer" onClick={() => dispatch(popModal())} />
          </div>
        </div>
      </div>
      <div className="group-action-button mt-2 d-flex align-items-center justify-content-center gap-2">
        <button className="btn-no px-2 py-1 justify-content-center" onClick={handlePressNo}>
          No
        </button>
        <button className="btn-yes px-2 py-1 justify-content-center" onClick={() => { dispatch(popModal()); handleDelete(data); }}>
          Yes
        </button>
      </div>
    </ModalBase>
  );
}
