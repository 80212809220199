import { useEditorContext } from 'context/EditorContext';
import { useRef, useState } from 'react';
import { CiStickyNote } from 'react-icons/ci';
import { RiStickyNoteFill } from 'react-icons/ri';
import { isMobile } from 'react-device-detect';
import { DraftModel } from 'draft-js';

const Annotation = ({
  dataAnnotation,
  updateAnnotation,
  autoOpen,
  block,
  checkPermissionEdit
}: {
  dataAnnotation: string;
  updateAnnotation: (block: DraftModel.ImmutableData.ContentBlock, annotation: string) => void;
  autoOpen: boolean;
  block: DraftModel.ImmutableData.ContentBlock;
  checkPermissionEdit: () => void;
}) => {
  const { setIsReadonly, isNoneEdit, isLock } = useEditorContext();
  const [isOpen, setIsOpen] = useState<boolean>(autoOpen);
  const [isSaveAnnotation, setIsSaveAnnotation] = useState<boolean>(dataAnnotation ? true : false);
  const textNoteRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState<string>(dataAnnotation || '');

  return (
    <>
      <div
        className={`btn-note ${isNoneEdit || isLock ? '' : 'cursor-pointer'}`}
        onClick={(e) => {
          e.stopPropagation();
          if (isNoneEdit || isLock) {
            setIsOpen(!isOpen);
            return;
          }
          setIsReadonly(true);
          setIsOpen(!isOpen);
        }}
      >
        {isSaveAnnotation && (
          <RiStickyNoteFill className={`${isMobile ? '' : isOpen ? '' : 'btn-annotation'}`} />
        )}

        {!isSaveAnnotation && (
          <CiStickyNote className={`${isMobile ? '' : isOpen ? '' : 'btn-annotation'}`} />
        )}
      </div>
      <div
        className={`annotation-wrapper ${isNoneEdit || isLock ? 'annotation-input-disable' : ''}  ${
          isOpen ? '' : 'd-none'
        }`}
      >
        <textarea
          ref={textNoteRef}
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          onClick={(e) => {
            e.stopPropagation();
            checkPermissionEdit();
          }}
          onBlur={() => {
            setIsReadonly(false);
            if (isNoneEdit || isLock || text === dataAnnotation) return;
            if (!text) {
              setIsOpen(false);
              setIsSaveAnnotation(false);
            } else {
              setIsSaveAnnotation(true);
            }
            updateAnnotation(block, text);
          }}
          onFocus={() => {
            setIsReadonly(true);
          }}
          onKeyDown={(e) => {
            setIsReadonly(true);
            if (e.code === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              textNoteRef.current?.blur();
            }
          }}
          placeholder="Note"
          className={`annotation-input custom-scrollbar --hidden `}
          readOnly={isNoneEdit || isLock}
        />
      </div>
    </>
  );
};

export default Annotation;
