import { TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from '../axios-client';
import { TUser } from 'ts/types';

export async function getListAdmin(): Promise<TResponse<TUser[]>> {
  const res = await AxiosCustom.get(`super-admin/admin/admins`);
  return res.data;
}

export async function searchUser({ name }: { name: string }): Promise<TResponse<TUser[]>> {
  const res = await AxiosCustom.get(`/super-admin/admin/${name}`);
  return res.data;
}

export async function addNewAdmin({ userId }: { userId: string }) {
  const res = await AxiosCustom.post(`/super-admin/admin/${userId}`);
  return res.data;
}

export async function deleteAdmin({ userId }: { userId: string }) {
  const res = await AxiosCustom.put(`/super-admin/admin/${userId}`);
  return res.data;
}
