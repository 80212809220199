import { Popover } from 'antd';
import React, { ReactNode } from 'react';
import { TMedia, TProject } from 'ts/types';
import { RenderContentMedia } from './component';

type Props = {
  children: ReactNode;
  medias: TMedia[];
  project: TProject;
  className?: string;
};

export function MediaPopOver({ children, medias, project, className }: Props) {
  const content = RenderContentMedia({ medias, project });

  return (
    <Popover
      placement="right"
      content={content}
      trigger="click"
      style={{ width: 180 }}
      overlayClassName={`${className ? className : ''}`}
    >
      {children}
    </Popover>
  );
}
