import { Dropdown, Input } from 'antd';
import type { InputRef, MenuProps } from 'antd';
import { ModalBase } from 'components/common/ModalBase';
import { useEffect, useRef, useState } from 'react';
import { BiSolidDownArrow } from 'react-icons/bi';
import { RiErrorWarningFill } from 'react-icons/ri';
import { FaCopy } from 'react-icons/fa';
import { popModal, updateModalData } from 'redux/modal/modal.slice';
import {
  EModals,
  EPublishAction,
  ERoutes,
  EShareEmailStatus,
  EShareRight,
  EShareType
} from 'ts/enums';
import ImgShareProject from 'assets/img/share-project.png';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  getListSharedMember,
  shareProject,
  shareProjectWithEmail,
  updateProjectPublishDesc
} from 'apis/shareProject.api';
import { TProject, TShareMember } from 'ts/types';
import { getPublishLinkSlug, stringToSlug } from 'utils/slug';
import { TextAreaRef } from 'antd/es/input/TextArea';
import { updateListBaseProject } from 'redux/projects/projects.slice';
import { usePageLoading } from 'context/PageLoadingContext';
import { BsCheck } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const roleLevel = {
  DISABLED: 0,
  VIEW: 1,
  EDIT: 2,
  PUBLISH: 0
};
export default function ShareProjectModal() {
  const dispatch = useAppDispatch();
  const [shareLinkStatus, setShareLinkStatus] = useState<EShareRight>(EShareRight.DISABLED);
  const [shareEmailStatus, setShareEmailStatus] = useState<EShareRight>(EShareRight.DISABLED);
  const [listInvitedEmail, setListInvitedEmail] = useState<TShareMember[]>([]);
  const [publishProjectStatus, setPublishProjectStatus] = useState<EPublishAction>(
    EPublishAction.DISABLED
  );
  const modalStack = useAppSelector((state) => state.modal.modalStack);
  const modalData: TProject = modalStack.find((modal) => modal.name === EModals.SHARE_PROJECT_MODAL)
    ?.data.project;
  const { openMessage, destroyMessage } = usePageLoading();
  const { t } = useTranslation();

  const [isCopyShareLink, setIsCopyShareLink] = useState<boolean>(false);
  const [isCopyPublishLink, setIsCopyPublishLink] = useState<boolean>(false);

  const [inputEmailInvite, setInputEmailInvite] = useState<string>('');
  const textareaPublishRef = useRef<TextAreaRef>(null);
  const [currentUserEmailSelectedRight, setCurrentEmailSelectedRight] = useState<EShareRight>(
    EShareRight.DISABLED
  );

  const mapTypeToValue = {
    PUBLISH: t('Shared.Actions.Publish'),
    DISABLED: t('Shared.Fields.Disabled'),
    VIEW: t('Shared.Texts.CanView'),
    EDIT: t('Shared.Texts.CanEdit'),
    'RE-PUBLISH': t('Shared.Actions.Republish')
  };

  const itemsShareLink: MenuProps['items'] = [
    {
      key: EShareRight.DISABLED,
      label: (
        <div
          className={`ps-1 pe-2 cursor-pointer ${
            shareLinkStatus === EShareRight.DISABLED ? 'd-none' : 'd-block'
          }`}
        >
          {t('Shared.Fields.Disabled')}
        </div>
      )
    },
    {
      key: EShareRight.VIEW,
      label: (
        <div
          className={`ps-1 pe-2 cursor-pointer ${
            shareLinkStatus === EShareRight.VIEW ? 'd-none' : 'd-block'
          }`}
        >
          {t('Shared.Texts.CanView')}
        </div>
      )
    },
    {
      key: EShareRight.EDIT,
      label: (
        <div
          className={`ps-1 pe-2 cursor-pointer ${
            shareLinkStatus === EShareRight.EDIT ? 'd-none' : 'd-block'
          }`}
        >
          {t('Shared.Texts.CanEdit')}
        </div>
      )
    }
  ];
  const itemsShareEmail: MenuProps['items'] = [
    {
      key: EShareRight.DISABLED,
      label: (
        <div
          className={`ps-1 pe-2 cursor-pointer ${
            shareEmailStatus === EShareRight.DISABLED ? 'd-none' : 'd-block'
          }`}
        >
          {t('Shared.Fields.Disabled')}
        </div>
      )
    },
    {
      key: EShareRight.VIEW,
      label: (
        <div
          className={`ps-1 pe-2 cursor-pointer ${
            shareEmailStatus === EShareRight.VIEW ? 'd-none' : 'd-block'
          }`}
        >
          {t('Shared.Texts.CanView')}
        </div>
      )
    },
    {
      key: EShareRight.EDIT,
      label: (
        <div
          className={`ps-1 pe-2 cursor-pointer ${
            shareEmailStatus === EShareRight.EDIT ? 'd-none' : 'd-block'
          }`}
        >
          {t('Shared.Texts.CanEdit')}
        </div>
      )
    }
  ];
  const itemsShareEmailUser: MenuProps['items'] = [
    {
      key: EShareRight.DISABLED,
      label: (
        <div
          className={`ps-1 pe-2 cursor-pointer ${
            currentUserEmailSelectedRight === EShareRight.DISABLED ? 'd-none' : 'd-block'
          }`}
        >
          {t('Shared.Fields.Disabled')}
        </div>
      )
    },
    {
      key: EShareRight.VIEW,
      label: (
        <div
          className={`ps-1 pe-2 cursor-pointer ${
            currentUserEmailSelectedRight === EShareRight.VIEW ? 'd-none' : 'd-block'
          }`}
        >
          {t('Shared.Texts.CanView')}
        </div>
      )
    },
    {
      key: EShareRight.EDIT,
      label: (
        <div
          className={`ps-1 pe-2 cursor-pointer ${
            currentUserEmailSelectedRight === EShareRight.EDIT ? 'd-none' : 'd-block'
          }`}
        >
          {t('Shared.Texts.CanEdit')}
        </div>
      )
    }
  ];
  const itemsPublic: MenuProps['items'] = [
    {
      key: EPublishAction.DISABLED,
      label: (
        <div
          className={`ps-1 pe-2 cursor-pointer ${
            publishProjectStatus === EPublishAction.DISABLED ? 'd-none' : 'd-block'
          }`}
        >
          {t('Shared.Fields.Disabled')}
        </div>
      )
    },
    {
      key: EPublishAction.PUBLISH,
      label: (
        <div
          className={`ps-1 pe-2 cursor-pointer ${
            publishProjectStatus === EPublishAction.PUBLISH ? 'd-none' : 'd-block'
          }`}
        >
          {t('Shared.Fields.Public')}
        </div>
      )
    },
    {
      key: EPublishAction['RE-PUBLISH'],
      label: (
        <div
          className={`ps-1 pe-2 cursor-pointer ${
            publishProjectStatus === EPublishAction.DISABLED ||
            publishProjectStatus === EPublishAction['RE-PUBLISH']
              ? 'd-none'
              : 'd-block'
          }`}
        >
          {t('Shared.Fields.Re_public')}
        </div>
      )
    }
  ];
  function resetShareData() {
    setShareEmailStatus(EShareRight.DISABLED);
    setShareLinkStatus(EShareRight.DISABLED);
    setListInvitedEmail([]);
    setPublishProjectStatus(EPublishAction.DISABLED);
    setCurrentEmailSelectedRight(EShareRight.DISABLED);
  }
  async function handleOnclickInviteEmail() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputEmailInvite)) {
      openMessage('error', t('Shared.Errors.InvalidEmail'));
      return;
    }
    if (inputEmailInvite) {
      if (listInvitedEmail.find((item) => item.email == inputEmailInvite)) {
        openMessage('error', t('Shared.Errors.EmailAlreadyExists'));
        return;
      }
      destroyMessage();
      openMessage('loading', t('Shared.Texts.InvitingW3Dot'));
      const res = await shareProjectWithEmail({
        id: modalData.id,
        right: shareEmailStatus,
        email: inputEmailInvite
      });
      if (res && !('error' in res)) {
        if (res.data) {
          destroyMessage();
          openMessage('success', t('Shared.Texts.Invited'));
          const objRes = JSON.parse(res.data);
          setInputEmailInvite('');
          setListInvitedEmail((prev) => [
            ...prev,
            { ...objRes.invite, status: EShareEmailStatus.INACTIVE, type: EShareType.INVITE }
          ]);
        }
      } else {
        destroyMessage();
        openMessage('error', t('Shared.Errors.ErrorInviting'));
      }
    }
  }
  async function handleChangeMemberRole(listMenbers: TShareMember[]) {
    shareProject({
      id: modalData.id,
      linkRight: shareLinkStatus as EShareRight,
      publicDescription: modalData.publicDescription,
      publishAction: publishProjectStatus,
      shareMember: listMenbers.map((member) => ({ email: member.email, right: member.right })),
      teamRight: 'DISABLED'
    });
  }
  function handleChangeAccessLink(event: any) {
    setShareLinkStatus(event.key as EShareRight);
    shareProject({
      id: modalData.id,
      linkRight: event.key,
      publicDescription: textareaPublishRef.current?.resizableTextArea?.textArea.value || '',
      publishAction: EPublishAction.DISABLED,
      shareMember: listInvitedEmail.map((item) => ({ email: item.email, right: item.right })),
      teamRight: 'DISABLED'
    });
  }

  async function handlePublishProject(event: any) {
    if (event) {
      setPublishProjectStatus(event.key as EPublishAction);
    }
    const res = await shareProject({
      id: modalData.id,
      linkRight: shareLinkStatus as EShareRight,
      publicDescription: textareaPublishRef.current?.resizableTextArea?.textArea.value || '',
      publishAction: event ? event.key : publishProjectStatus,
      shareMember: listInvitedEmail.map((item) => ({ email: item.email, right: item.right })),
      teamRight: 'DISABLED'
    });
    if (res && 'data' in res) {
      dispatch(
        updateListBaseProject({
          project: {
            ...modalData,
            publicDescription: textareaPublishRef.current?.resizableTextArea?.textArea.value || ''
          }
        })
      );
    }
  }

  function handleUpdateProjectData() {
    if (publishProjectStatus !== EPublishAction.DISABLED) {
      if (
        textareaPublishRef.current?.resizableTextArea?.textArea.value != modalData.publicDescription
      ) {
        updateProjectPublishDesc(
          modalData,
          textareaPublishRef.current?.resizableTextArea?.textArea.value || ''
        );
      }
    }
  }

  function handleCopy(text: string) {
    navigator.clipboard.writeText(text);
    openMessage('success', t('Shared.Texts.Copied'));
  }

  useEffect(() => {
    resetShareData();
    (async () => {
      if (modalData) {
        const data = await getListSharedMember(modalData.id);
        if (data) {
          setListInvitedEmail(data.filter((item) => item.email));
          const shareLink = data.filter((item) => item.type === EShareType.LINK);
          if (shareLink.length > 0) {
            setShareLinkStatus(shareLink[0].right);
          }
          const publicAction = data.filter((item) => item.type === EShareType.PUBLISH);
          if (publicAction.length > 0) {
            setPublishProjectStatus(EPublishAction.PUBLISH);
          }
        } else {
          setListInvitedEmail([]);
        }
      }
    })();
  }, [modalData]);

  return (
    <ModalBase
      modalName={EModals.SHARE_PROJECT_MODAL}
      onCloseModal={() => {
        handleUpdateProjectData();
        dispatch(popModal());
      }}
      afterClose={() => {
        setIsCopyShareLink(false);
        setIsCopyPublishLink(false);
      }}
    >
      <div className="ctn-modal-share-project mb-3">
        <div className="title-top text-center">
          {t('Pages.TranscriptDetail.ShareProjectModal.Title')}
        </div>
        <div className="section-share-with-link mt-7 mb-3">
          <div className="d-flex justify-content-between">
            <div className="title-share-link">
              {t('Pages.TranscriptDetail.ShareProjectModal.AccessLinkTitle')}
            </div>
            <Dropdown
              menu={{
                items: itemsShareLink,
                onClick: (event) => {
                  // setShareLinkStatus((event.key as unknown) as EShareStatus)
                  handleChangeAccessLink(event);
                }
              }}
              trigger={['click']}
            >
              <button onClick={(e) => e.preventDefault()} className="text-dark fw-bold text-nowrap">
                {mapTypeToValue[shareLinkStatus]}
                <BiSolidDownArrow size={8} style={{ marginLeft: 5 }} />
              </button>
            </Dropdown>
          </div>
          <Input
            className="input-share-link mt-1"
            disabled={shareLinkStatus === EShareRight.DISABLED}
            readOnly
            placeholder={t('Pages.TranscriptDetail.ShareProjectModal.PlaceHolderShareLink')}
            suffix={
              isCopyShareLink ? (
                <BsCheck color="var(--si-primary)" size={20} />
              ) : (
                <FaCopy
                  onClick={() => {
                    if (shareLinkStatus === EShareRight.DISABLED) return;
                    handleCopy(
                      `${location.origin}${ERoutes.PROJECT_SHARING}/${modalData.projectId}?sharing=${modalData.projectId}`
                    );
                    setIsCopyShareLink(true);
                  }}
                  color="var(--si-primary)"
                />
              )
            }
            width="100%"
            value={
              modalData && shareLinkStatus !== EShareRight.DISABLED
                ? `${location.origin}/app/projects/transcript/${modalData.projectId}?sharing=${modalData.projectId}`
                : ''
            }
          />
        </div>
        {/*  */}
        <div className="section-share-with-email pt-2 mb-3">
          <div className="d-flex justify-content-between mb-1">
            <div className="title-share-email">
              {t('Pages.TranscriptDetail.ShareProjectModal.AccessRightTitle')}
            </div>
            <Dropdown
              menu={{
                items: itemsShareEmail,
                onClick: (event) => {
                  setShareEmailStatus(event.key as EShareRight);
                }
              }}
              trigger={['click']}
            >
              <button onClick={(e) => e.preventDefault()} className="text-dark fw-bold text-nowrap">
                {mapTypeToValue[shareEmailStatus]}
                <BiSolidDownArrow size={8} style={{ marginLeft: 5 }} />
              </button>
            </Dropdown>
          </div>
          <div className="d-flex">
            <Input
              className={`input-email-share ${
                shareEmailStatus !== EShareRight.DISABLED ? 'input-mail-active' : ''
              }`}
              value={inputEmailInvite}
              onChange={(e) => setInputEmailInvite(e.target.value)}
              disabled={shareEmailStatus === EShareRight.DISABLED}
              width="100%"
              placeholder={t('Shared.Texts.EnterEmailAddress')}
            />
            <button
              className={`btn-invite px-2 fw-bold ms-md-4 ms-sx-2 ms-1 ${
                shareEmailStatus === EShareRight.DISABLED ? 'opacity-50 cursor-disable' : ''
              }`}
              onClick={handleOnclickInviteEmail}
              disabled={shareEmailStatus === EShareRight.DISABLED}
            >
              {t('Shared.Actions.Invite')}
            </button>
          </div>
          <div className="list-email-invited mt-1">
            {listInvitedEmail.map((user) => {
              if (!user.email) return;
              return (
                <div key={user.email} className="d-flex justify-content-between">
                  <div
                    className={`email-item mt-1 ${
                      user.status === EShareEmailStatus.ACTIVE ? 'email-active' : ''
                    }`}
                  >
                    {user.email}
                  </div>
                  <Dropdown
                    menu={{
                      items: itemsShareEmailUser,
                      onClick: (event) => {
                        const newState = listInvitedEmail.map((u) => {
                          if (u.email === user.email) {
                            return { ...user, right: event.key as EShareRight };
                          }
                          return u;
                        });
                        handleChangeMemberRole(newState);
                        setListInvitedEmail(newState);
                      }
                    }}
                    trigger={['click']}
                    overlayClassName=""
                  >
                    <button
                      onClick={(e) => {
                        setCurrentEmailSelectedRight(user.right);
                        e.preventDefault();
                      }}
                      className={`fw-bold ${
                        roleLevel[user.right] < roleLevel[shareLinkStatus]
                          ? 'text-warning'
                          : 'text-normal'
                      }`}
                    >
                      <div className="d-flex">
                        <div className="d-flex align-items-center">
                          {roleLevel[user.right] < roleLevel[shareLinkStatus] && (
                            <RiErrorWarningFill color="var(--si-dark-orange)" />
                          )}
                        </div>
                        <div className="d-flex align-items-center">
                          {mapTypeToValue[user.right]}
                        </div>
                        <div className="d-flex align-items-center">
                          <BiSolidDownArrow
                            color="var(--si-black)"
                            size={8}
                            style={{ marginLeft: 5 }}
                          />
                        </div>
                      </div>
                    </button>
                  </Dropdown>
                </div>
              );
            })}
          </div>
        </div>
        {/*publish  */}

        <div className="section-public-project pt-3">
          <div className="title-public text-center">
            {t('Pages.TranscriptDetail.ShareProjectModal.PublishProjectTitle')}
          </div>
          <div className="d-flex justify-content-between align-items-center mt-1">
            <div className="title">
              {t('Pages.TranscriptDetail.ShareProjectModal.ViewAccessLinkTitle')}
            </div>
            <Dropdown
              menu={{
                items: itemsPublic,
                onClick: (event) => {
                  handlePublishProject(event);
                }
              }}
              trigger={['click']}
              overlayClassName=""
            >
              <button onClick={(e) => e.preventDefault()} className="text-dark fw-bold text-nowrap">
                {mapTypeToValue[publishProjectStatus]}
                <BiSolidDownArrow size={8} style={{ marginLeft: 5 }} />
              </button>
            </Dropdown>
          </div>
          <div className="d-flex mt-1">
            <Input
              className="input-link-publish"
              disabled={publishProjectStatus === EPublishAction.DISABLED}
              readOnly
              width="100%"
              suffix={
                isCopyPublishLink ? (
                  <BsCheck color="var(--si-primary)" size={20} />
                ) : (
                  <FaCopy
                    onClick={() => {
                      if (publishProjectStatus === EPublishAction.DISABLED) return;
                      handleCopy(
                        `${process.env.REACT_APP_PUBLIC_PAGE}/${modalData?.owner.id}/${getPublishLinkSlug(
                          modalData?.name
                        )}/${modalData?.id}`
                      );
                      setIsCopyPublishLink(true);
                    }}
                    color="var(--si-primary)"
                  />
                )
              }
              value={
                publishProjectStatus !== EPublishAction.DISABLED
                  ? `${process.env.REACT_APP_PUBLIC_PAGE}/${modalData?.owner.id}/${getPublishLinkSlug(
                      modalData?.name
                    )}/${modalData?.id}`
                  : ''
              }
            />
          </div>
          {publishProjectStatus !== EPublishAction.DISABLED ? (
            <div className={`my-1`}>
              <div className="mt-2">{t('Shared.Fields.PublicDescription')}</div>
              <Input.TextArea
                ref={textareaPublishRef}
                className="public-desc-text mt-1"
                defaultValue={modalData?.publicDescription}
                onBlur={handlePublishProject}
                showCount
                maxLength={255}
              />
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <img className="mx-auto mt-2" src={ImgShareProject} />
            </div>
          )}
        </div>
      </div>
    </ModalBase>
  );
}
