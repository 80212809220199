/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { TTag } from 'ts/types';
import {
  createTagAction,
  deleteTagAction,
  getListTagsAction,
  updateTagAction
} from './tags.action';

type TagsState = {
  currentListTags: {
    data: TTag[];
    isLoading: boolean;
    error: any;
    isFetched: boolean;
  };
};

const initialState: TagsState = {
  currentListTags: {
    data: [],
    isLoading: false,
    error: null,
    isFetched: false
  }
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    //Get list tags
    addCase(getListTagsAction.pending, (state) => {
      state.currentListTags = { data: [], isLoading: true, error: null, isFetched: false };
    });

    addCase(getListTagsAction.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentListTags = { data: payload, isLoading: false, error: null, isFetched: true };
      }
    });

    addCase(getListTagsAction.rejected, (state, { payload }) => {
      state.currentListTags = { data: [], isLoading: false, error: payload, isFetched: true };
    });

    //Create tag
    addCase(createTagAction.pending, (state) => {
      state.currentListTags = { ...state.currentListTags, isLoading: true, error: null };
    });

    addCase(createTagAction.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentListTags = {
          ...state.currentListTags,
          data: [...state.currentListTags.data, payload],
          isLoading: false
        };
      }
    });

    addCase(createTagAction.rejected, (state, { payload }) => {
      state.currentListTags = { data: [], isLoading: false, error: payload, isFetched: true };
    });

    //Update tag
    addCase(updateTagAction.pending, (state) => {
      state.currentListTags = { ...state.currentListTags, isLoading: true, error: null };
    });

    addCase(updateTagAction.fulfilled, (state, { payload }) => {
      if (payload) {
        const tmpListTags = [...state.currentListTags.data];

        const findItem = tmpListTags.find((tag) => tag.id === payload.id);
        if (findItem) {
          findItem.color = payload.color;
          findItem.name = payload.name;
        }

        state.currentListTags = {
          ...state.currentListTags,
          data: tmpListTags,
          isLoading: false
        };
      }
    });

    addCase(updateTagAction.rejected, (state, { payload }) => {
      state.currentListTags = { data: [], isLoading: false, error: payload, isFetched: true };
    });

    //Delete tag
    addCase(deleteTagAction.pending, (state) => {
      state.currentListTags = { ...state.currentListTags, isLoading: true, error: null };
    });

    addCase(deleteTagAction.fulfilled, (state, { payload }) => {
      if (payload) {
        const tmpListTags = [...state.currentListTags.data];
        state.currentListTags.data = tmpListTags.filter((tag) => tag.id !== payload.id);
      }
    });

    addCase(deleteTagAction.rejected, (state, { payload }) => {
      state.currentListTags = { data: [], isLoading: false, error: payload, isFetched: true };
    });
  }
});

export default tagsSlice.reducer;
