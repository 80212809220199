import { Avatar, notification, message } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { getDefaultPic, signedAvatarUrl, updateAvatar } from 'apis/userSetting.api';
import { ModalBase } from 'components/common';
import { Loader } from 'components/common/Loader';
import { useEffect, useState } from 'react';
import { FaDownload, FaUpload } from 'react-icons/fa';
import { GrAddCircle } from 'react-icons/gr';
import { useDispatch } from 'react-redux';
import { updateCurrentUser } from 'redux/auth/auth.slice';
import { popModal } from 'redux/modal/modal.slice';
import { EModals } from 'ts/enums';
import { TPicDefault } from 'ts/types';
import axios from 'axios';
import { RcFile } from 'antd/es/upload';
import { useTranslation } from 'react-i18next';

const PIC_CUSTOM = -1;
export default function UpdateAvatarModal() {
  const dispatch = useDispatch();
  const [listDefaultAvatar, setListDefaultAvatar] = useState<TPicDefault[]>([]);
  const [isLoadDefaultAvatar, setIsLoadDefaultAvatar] = useState<boolean>(false);
  const [isOpenUploadAvatar, setIsOpenUploadAvatar] = useState<boolean>(false);
  const [selectedAvatar, setSelectedAvatar] = useState<TPicDefault>();
  const [linkCustomAvatar, setLinkCustomAvatar] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const { t } = useTranslation();

  async function handleOpenModalChangeAvatar() {
    setIsLoadDefaultAvatar(true);
    const data = await getDefaultPic();
    if (data) {
      setListDefaultAvatar(data);
    }
    setIsLoadDefaultAvatar(false);
  }
  async function handleUpdateAvatar() {
    if (selectedAvatar) {
      const res = await updateAvatar(selectedAvatar?.url);
      if (res) {
        message.success(t('Shared.Texts.Updated'));
        dispatch(popModal());
        dispatch(updateCurrentUser(res));
      }
    }
  }
  useEffect(() => {
    handleOpenModalChangeAvatar();
  }, []);
  return (
    <ModalBase
      modalName={EModals.UPDATE_AVATAR}
      onCloseModal={() => {
        dispatch(popModal());
      }}
      afterClose={() => {
        setLinkCustomAvatar(null);
        setIsOpenUploadAvatar(false);
        setUploading(false);
      }}
    >
      <div className="ctn-modal-update-avatar">
        <div className="title mb-5">{ t('Pages.AccountSetting.Profile.UpdateAvatarModal.Title') }</div>
        {isOpenUploadAvatar && (
          <div className="alert">
            { t('Pages.AccountSetting.Profile.UpdateAvatarModal.Description') }
          </div>
        )}
        {isLoadDefaultAvatar ? (
          <Loader />
        ) : (
          <div className="row gy-6 align-items-center">
            {listDefaultAvatar.map((pic) => (
              <div
                key={pic.id}
                className={`col-4 d-flex justify-content-center default-avatar ${selectedAvatar?.id == pic.id ? 'avatar-selected' : ''
                  }`}
              >
                <Avatar
                  src={pic.url}
                  size={65}
                  onClick={() => {
                    setSelectedAvatar({ id: pic.id, url: pic.url });
                    setIsOpenUploadAvatar(false);
                  }}
                />
              </div>
            ))}
            <div
              className={`col-4 d-flex justify-content-center btn-upload-avatar cursor-pointer default-avatar ${selectedAvatar?.id == PIC_CUSTOM ? 'avatar-selected' : ''
                }`}
            >
              <Avatar
                src={linkCustomAvatar}
                size={65}
                onClick={() => {
                  if (linkCustomAvatar) {
                    setSelectedAvatar({ id: PIC_CUSTOM, url: linkCustomAvatar });
                    setIsOpenUploadAvatar(false);
                  } else {
                    setIsOpenUploadAvatar((prev) => !prev);
                  }
                }}
              >
                {' '}
                {uploading ? (
                  <Loader style={{ position: 'absolute', top: 32, left: -10 }} />
                ) : (
                  <FaUpload size={30} />
                )}
              </Avatar>
            </div>
          </div>
        )}
        {isOpenUploadAvatar && (
          <div className="ctn-upload-custom-avatar mt-3">
            <Dragger
              maxCount={1}
              showUploadList={false}
              accept="image/png, image/jpeg, image/jpg"
              customRequest={({ file, onSuccess, onError }) => {
                if ((file as Blob).size > 300000) {
                  notification.error({
                    message: t('Pages.AccountSetting.Profile.UpdateAvatarModal.ErrorFileSize', { fileName: (file as RcFile).name })
                  });
                  return;
                }
                setLinkCustomAvatar(null);
                setUploading(true);
                signedAvatarUrl((file as any).name, (file as any).type)
                  .then((data) => {
                    axios
                      .put(data.url, file, { headers: { 'Content-Type': (file as any).type } })
                      .then(() => {
                        onSuccess?.(data.url.split('?')[0]);
                      });
                  })
                  .catch((e: any) => {
                    onError?.(e);
                  });
              }}
              onChange={async (info) => {
                const { status } = info.file;
                setUploading(false);
                if (status === 'done' && info.file.response) {
                  setLinkCustomAvatar(info.file.response);
                  setSelectedAvatar({ id: PIC_CUSTOM, url: info.file.response });
                } else if (status === 'error') {
                  console.info(`${info.file.name} file upload failed.`);
                }
              }}
            >
              <div className="row">
                <div className="col-4">
                  <div>
                    <div>
                      <GrAddCircle size={75} />
                    </div>
                    <div>{ t('Pages.AccountSetting.Profile.UpdateAvatarModal.AddPicture') }</div>
                  </div>
                </div>
                <div className="col">
                  <div>{ t('Pages.AccountSetting.Profile.UpdateAvatarModal.Tip01') }</div>
                  <div>{ t('Pages.AccountSetting.Profile.UpdateAvatarModal.Tip02') }</div>
                </div>
              </div>
            </Dragger>
          </div>
        )}
        <div className="d-flex justify-content-center mt-3">
          <button className="btn-update px-2 py-1" onClick={handleUpdateAvatar}>
            {' '}
            <FaDownload color="var(--si-primary)" size={18} className="me-1" /> { t('Shared.Actions.Update') }
          </button>
        </div>
      </div>
    </ModalBase>
  );
}
