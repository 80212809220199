import React, { useEffect, useState } from "react";
import { CreditCard, ModalBase } from "components/common";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { popModal, pushModal } from "redux/modal/modal.slice";
import { FaMagic } from "react-icons/fa";
import { EModals, ETypeNoti, ETypeSubcribeModal } from "ts/enums";
import { convertHoursToSecond, convertPriceToMinute } from "utils/common";
import { getPriceBuyCredit } from "apis/price.api";
import { useCreditCard } from "context/CreditCardContext";
import { getUserInfor } from "apis/auth.api";
import { buyCredit } from "apis/subscribe.api";
import { updateCurrentUser } from "redux/auth/auth.slice";
import { Loader } from "components/common/Loader";
import { usePageLoading } from "context/PageLoadingContext";
import { changeNumberToCurrency } from "utils/price";
import { useTranslation } from "react-i18next";
import { Badge, Tabs } from "antd";
import type { TabsProps } from "antd";
import * as Paypal from "@paypal/paypal-js";
import type { PayPalNamespace } from "@paypal/paypal-js";
import {
  paypalCaptureOrder,
  paypalCreateOrder,
  stripeCreateBuyCreditOrder,
} from "apis/serverless.api";
import { useLoaderContext } from "context/LoaderContext";
import { VoucherBox } from "components/common/VoucherBox";
import { CouponVerification } from "ts/types/interface";
import { TFunction } from "i18next";
//import { loadStripe } from "@stripe/stripe-js";

//const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY || ""); // Is it necessary?

export function AddCreditModal() {
  const dispatch = useAppDispatch();
  const [creditToBuy, setCreditToBuy] = useState<number>(1);
  const [currentPrice, setCurrentPrice] = useState(10);
  const currentUser = useAppSelector((state) => state.auth.currentUser.data);
  const {
    cardData,
    cardName,
    isExistedCard,
    expiryMonthData,
    expiryYearData,
    cvcData,
    isUpdateCardSaved,
  } = useCreditCard({ currentUser: currentUser });
  const [loading, setLoading] = useState<boolean>(false);
  const [paypalClient, setPaypalClient] = useState<PayPalNamespace | null>(
    null
  );
  const { openMessage, destroyMessage } = usePageLoading();
  const { t } = useTranslation();
  const { loader } = useLoaderContext();
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponApplied, setCouponApplied] = useState<CouponVerification | null>(
    null
  );

  const optionAddCredit = TransformCreditOptions(couponApplied, t);

  async function handleCheckout() {
    const seconds = convertHoursToSecond(creditToBuy);
    setLoading(true);
    openMessage("loading", t("Shared.Texts.AddingW3Dot"));
    loader.start();
    if (isExistedCard) {
      // checkout with card saved
      buyCredit({
        buyCreditInSeconds: seconds,
        couponCode: couponApplied ? couponApplied.couponCode : "",
      })
        .then(async (res) => {
          if (res.response === "OK") {
            const res = await getUserInfor();
            if (res) {
              destroyMessage();
              openMessage("success", t("Shared.Texts.Added"));
              dispatch(updateCurrentUser(res));
              dispatch(popModal());
              setLoading(false);
              setCouponApplied(null);
            }
          }
          loader.complete();
        })
        .catch((error) => {
          setLoading(false);
          destroyMessage();
          dispatch(
            pushModal({
              name: EModals.NOTI_MODAL,
              data: null,
              notiData: {
                type: ETypeNoti.ERROR,
                title: error.response.data.message,
              },
            })
          );
        });
    } else {
      buyCredit({
        buyCreditInSeconds: seconds,
        cvc: cvcData.data,
        cardHolderName: cardName.data,
        exp_month: expiryMonthData.data,
        exp_year: expiryYearData.data,
        number: cardData?.cardNumber,
        replaceCard: true,
        saveCard: isUpdateCardSaved,
        couponCode: couponApplied ? couponApplied.couponCode : "",
      })
        .then(async (res) => {
          if (res.response === "OK") {
            const res = await getUserInfor();
            if (res) {
              destroyMessage();
              openMessage("success", t("Shared.Texts.Added"));
              dispatch(updateCurrentUser(res));
              dispatch(popModal());
              setLoading(false);
              setCouponApplied(null);
            }
          }
          loader.complete();
        })
        .catch((error) => {
          setLoading(false);
          destroyMessage();
          dispatch(
            pushModal({
              name: EModals.NOTI_MODAL,
              data: null,
              notiData: {
                type: ETypeNoti.ERROR,
                title: error.response.data.message,
              },
            })
          );
        });
    }
  }

  function handleGetBetterRate() {
    dispatch(popModal());
    dispatch(
      pushModal({
        name: EModals.SUBSCRIBE_MODAL,
        data: {
          type: ETypeSubcribeModal.UPDRAGE,
        },
      })
    );
  }

  useEffect(() => {
    if (currentUser) {
      setCurrentPrice(currentUser.plan.pricePerHour);
    }
    return () => {
      setCreditToBuy(1);
      setCurrentPrice(10);
    };
  }, [currentUser]);

  useEffect(() => {
    if (paypalClient) return;
    Paypal.loadScript({
      clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || "",
      currency: "USD",
      disableFunding: "card",
    })
      .then((pp) => {
        setPaypalClient(pp);
      })
      .catch((err) => {
        console.error("Error loading paypal's script");
      });
  }, [paypalClient]);

  const handleStripeCheckout = () => {
    if (!currentUser) return;

    setLoading(true);
    const seconds = convertHoursToSecond(creditToBuy);
    const selectedOption = optionAddCredit.find((o) => o.value == creditToBuy);
    const itemDescription = selectedOption
      ? `${selectedOption.label}${
          couponApplied?.couponCode
            ? ` (Coupon: ${couponApplied.couponCode})`
            : ""
        }`
      : null;

    stripeCreateBuyCreditOrder(
      seconds,
      currentUser?.email,
      currentUser?.id,
      couponApplied ? couponApplied.couponCode : null,
      itemDescription
    )
      .then((data) => {
        window.location.href = data.url;
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const paymentMethods: TabsProps["items"] = [
    {
      key: "stripe",
      label: (
        <>
          {t("Shared.PaymentMethods.Stripe")}{" "}
          {/* <Badge size="small" count={"NEW"} className="mb-2" /> */ }
        </>
      ),
      children: (
        <>
          <div className={`${!loading ? "d-none" : "mt-2"}`}>
            <Loader />
          </div>
          <VoucherBox
            value={couponCode}
            onCouponApply={setCouponApplied}
            onChange={setCouponCode}
            isVisible={!loading}
            defaultOpen={false}
          />
          {!loading && (
            <div className="pt-3 pb-3 text-center">
              <button
                className="submit-button"
                onClick={handleStripeCheckout}
                disabled={loading}
              >
                <FaMagic size={24} className="me-1" />
                <span>{t("Shared.Texts.LetsDoThis")}</span>
              </button>
            </div>
          )}
        </>
      ),
    },
    {
      key: "creditcard",
      label: t("Shared.PaymentMethods.CreditCard"),
      children: (
        <>
          <CreditCard />
          <VoucherBox
            value={couponCode}
            onCouponApply={setCouponApplied}
            onChange={setCouponCode}
            isVisible
            defaultOpen={false}
          />
          <div className="pt-3 pb-3 text-center">
            <button
              className="submit-button"
              onClick={handleCheckout}
              disabled={loading}
            >
              {loading ? (
                <Loader />
              ) : (
                <>
                  <FaMagic size={24} className="me-1" />
                  <span>{t("Shared.Texts.LetsDoThis")}</span>
                </>
              )}
            </button>
          </div>
        </>
      ),
    },
    {
      key: "paypal",
      label: t("Shared.PaymentMethods.Paypal"),
      children: (
        <>
          <p>
            <b>{t("Pages.Main.AddCreditModal.PaypalNote")}</b>
          </p>
          <div
            id="paypal-button-container"
            className={`${loading ? "d-none" : ""}`}
          />
          <div className={`${!loading ? "d-none" : ""}`}>
            <Loader />
          </div>
        </>
      ),
    },
  ];

  async function onPaymentMethodChange(key: string) {
    if (key == "paypal") {
      setTimeout(async () => {
        await renderPaypalButton(convertHoursToSecond(creditToBuy));
      }, 1); // Prevent rendering too fast
    } else if (key == "stripe") {
      // Nothing
    }
  }

  async function renderPaypalButton(creditInSeconds: number) {
    if (!paypalClient || !currentUser) return;

    // Clear previous
    let paypalButton = document.querySelector("#paypal-button-container");
    if (paypalButton) {
      paypalButton.innerHTML = ""; // Clear the previous paypal modal and button

      try {
        // @ts-ignore
        await paypalClient
          .Buttons({
            async createOrder() {
              try {
                const orderData = await paypalCreateOrder(
                  creditInSeconds,
                  currentUser.id
                );
                if (orderData.id) {
                  return orderData.id;
                } else {
                  const errorDetail = orderData?.details?.[0];
                  const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData);

                  throw new Error(errorMessage);
                }
              } catch (error) {
                console.error(error);
                destroyMessage();
                dispatch(
                  pushModal({
                    name: EModals.NOTI_MODAL,
                    data: null,
                    notiData: {
                      type: ETypeNoti.ERROR,
                      title: t(
                        "Pages.Main.AddCreditModal.UnableToCreatePaypalOrder"
                      ),
                    },
                  })
                );
              }
            },
            async onApprove(data, actions) {
              try {
                const orderData = await paypalCaptureOrder(data.orderID);

                // Three cases to handle:
                //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                //   (2) Other non-recoverable errors -> Show a failure message
                //   (3) Successful transaction -> Show confirmation or thank you message
                const errorDetail = orderData?.details?.[0];

                if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                  // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                  // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                  return actions.restart();
                } else if (errorDetail) {
                  // (2) Other non-recoverable errors -> Show a failure message
                  throw new Error(
                    `${errorDetail.description} (${orderData.debug_id})`
                  );
                } else if (!orderData.purchase_units) {
                  throw new Error(JSON.stringify(orderData));
                } else {
                  // (3) Successful transaction -> Show confirmation or thank you message Or go to another URL:  actions.redirect('thank_you.html');
                  const transaction =
                    orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                    orderData?.purchase_units?.[0]?.payments
                      ?.authorizations?.[0];
                  destroyMessage();
                  setTimeout(() => {
                    dispatch(popModal());
                  }, 1000); // Prevent Paypal's bug
                  openMessage(
                    "success",
                    t("Pages.Main.AddCreditModal.PaypalBuySuccess")
                  );
                }
              } catch (error) {
                console.error(error);
                destroyMessage();
                dispatch(
                  pushModal({
                    name: EModals.NOTI_MODAL,
                    data: null,
                    notiData: {
                      type: ETypeNoti.ERROR,
                      title: t(
                        "Pages.Main.AddCreditModal.UnableToProcessOrder"
                      ),
                    },
                  })
                );
              }
            },
            async onError(error) {
              console.error(error);
              destroyMessage();
              dispatch(
                pushModal({
                  name: EModals.NOTI_MODAL,
                  data: null,
                  notiData: {
                    type: ETypeNoti.ERROR,
                    title: t("Pages.Main.AddCreditModal.UnableToCreateOrder"),
                  },
                })
              );
            },
          })
          .render("#paypal-button-container");
      } catch (error) {
        console.error("Unable to render Paypal button.", error);
      }
    }
  }

  return (
    <ModalBase
      modalName={EModals.ADD_CREDIT_MODAL}
      onCloseModal={() => {
        dispatch(popModal());
        setCouponApplied(null);
      }}
    >
      <div className="add-credit-modal">
        <div className="title mb-2">{t("Pages.Main.AddCreditModal.Title")}</div>
        <div style={{ padding: "30px 10px 10px" }}>
          <section className="add-credit mb-1">
            <div className="d-md-flex gap-2 justify-content-evenly">
              <div className="mb-1 mb-md-0">
                <div className="add-credit-hours d-flex flex-wrap flex-md-nowrap ps-2 ps-md-0 mb-1 mb-md-0">
                  <h3
                    className="mb-0 me-2 title-add"
                    style={{ alignSelf: "center", fontSize: 24 }}
                  >
                    {t("Pages.Main.AddCreditModal.IWantToAdd")}
                  </h3>
                  <select
                    value={creditToBuy}
                    name="add-credit-hours-options"
                    className="add-credit-hours-options cursor-pointer py-1 w-100 pe-2 pe-md-0"
                    onChange={async (e) => {
                      setLoading(true);
                      setCreditToBuy(Number(e.target.value));
                      const seconds = convertHoursToSecond(
                        Number(e.target.value)
                      );
                      const price = await getPriceBuyCredit(seconds);
                      if (price) {
                        setCurrentPrice(price.totalPrice);
                      }
                      setLoading(false);

                      // Paypal
                      renderPaypalButton(seconds);
                    }}
                  >
                    {optionAddCredit.map((item) => (
                      <option
                        key={item.value}
                        value={item.value}
                        label={item.label}
                      />
                    ))}
                  </select>
                </div>
                <div
                  className="text-md-center text-start ps-2 ps-md-0 mb-1 mb-md-0"
                  style={{ fontWeight: 700, fontSize: 13 }}
                >
                  <span style={{ fontSize: 24 }}>
                    {t("Shared.Fields.Total")}:{" "}
                    {changeNumberToCurrency(currentPrice || 0).prevPriod}
                  </span>
                  .{changeNumberToCurrency(currentPrice || 0).afterPriod}
                </div>
              </div>
              <div>
                {currentUser && (
                  <div className="plan-details ps-2">
                    <p className="mb-0">
                      {t("Pages.Main.AddCreditModal.YourPlanRate")}:{" "}
                      <span className="ps-1">
                        {convertPriceToMinute(currentUser?.plan.pricePerHour)}
                        &cent;/
                        {t("Shared.Fields.min", { count: 0 })}
                      </span>
                    </p>
                    <p className="mb-0">
                      {t("Pages.Main.AddCreditModal.YourPlan")}:{" "}
                      <span className="plan-label ps-1">
                        {currentUser?.plan.label}
                      </span>
                    </p>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleGetBetterRate();
                      }}
                      className="custom-a-tag"
                    >
                      {t("Pages.Main.AddCreditModal.GetBetterRate")}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </section>
          <Tabs
            defaultActiveKey="stripe"
            items={paymentMethods}
            onChange={onPaymentMethodChange}
            style={{ fontFamily: "GothamBook" }}
          />
        </div>
      </div>
    </ModalBase>
  );
}

const creditBuyOptions = [1, 2, 5, 10, 20, 50, 100];
function TransformCreditOptions(
  appliedCoupon: CouponVerification | null,
  t: TFunction<"translation", undefined>
) {
  if (!appliedCoupon || !appliedCoupon.benefits.startsWith("MultiCreditBy"))
    return creditBuyOptions.map((h) => {
      return {
        value: h,
        label: `${h} ${t("Shared.Fields.hour", { count: h })}`,
      };
    });

  var [command, amount] = appliedCoupon.benefits.split("=");
  return creditBuyOptions.map((h) => {
    return {
      value: h,
      label: `${h * Number(amount)} ${t("Shared.Fields.hour", {
        count: h * Number(amount),
      })}`,
    };
  });
}
