import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import CustomControlPlayer from './CustomControlPlayer';
import { useVideoPlayerContext } from 'context/VideoPlayerContext';
import { notification } from 'antd';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { TWord } from 'ts/types';
import { BiChevronDown } from 'react-icons/bi';
import { EStyle } from 'ts/enums';
import PosterAudioImg from 'assets/img/poster-audio.png';
import PosterVideoImg from 'assets/img/poster-video.png';
import { BsFillPlayFill } from 'react-icons/bs';
import { YoutuBeLoader } from 'components/common/Loader';
import { setTranscriptCursorTime } from 'redux/transcription/transcription.slice';
import CaptionBox from './CaptionBox';
import { getMediaTimeCode } from 'utils/second-to-time';
import moment from 'moment';

type Props = {
  url?: string;
  thumbnail?: string;
  duration: number;
  userDefinedTimeCode: number;
};

function VideoPlayer({ url, thumbnail, duration, userDefinedTimeCode }: Props) {
  const {
    volume,
    setVideoDuration,
    setCurrentTime,
    videoRef,
    isPlaying,
    setIsPlaying,
    setMarkList,
    isVisible,
    setIsVisible,
    showPreview,
    setShowPreview,
    playBackRate,
    setUserDefinedTimeCode,
    isAudio,
    currentTime,
    isReady,
    setIsReady
  } = useVideoPlayerContext();

  const { t } = useTranslation();
  const currentTranscription = useAppSelector((state) => state.transcription.currentTranscription);
  const media = useAppSelector((state) => state.media.currentMedia);
  const thumbnailRender =
    thumbnail?.includes('poster-audio') || !media?.videoFilePath
      ? PosterAudioImg
      : thumbnail
      ? thumbnail
      : PosterVideoImg;

  const dispatch = useAppDispatch();
  const getTimeCode = () => {
    if (!media) return 0;

    const fps = media.fps;
    const startTimecodeOffset = getMediaTimeCode(media).time || 0;
    const frames = getMediaTimeCode(media).frames || 0;
    const millis = Number(frames) / fps;

    const startTimeMilisecond = moment.duration(
      moment.duration(moment(startTimecodeOffset, 'HH:mm:ss').format('HH:mm:ss')).asMilliseconds()
    );

    const startTimecodeInSeconds = startTimeMilisecond.asMilliseconds() / 1000 + millis;

    return startTimecodeInSeconds;
  };

  useEffect(() => {
    if (duration) {
      setVideoDuration(duration);
    }
  }, [duration]);

  useEffect(() => {
    if (userDefinedTimeCode) {
      setUserDefinedTimeCode(userDefinedTimeCode / 1000);
    } else if (media) {
      setUserDefinedTimeCode(getTimeCode());
    }
  }, [userDefinedTimeCode, media]);

  useEffect(() => {
    const res = filterTranscriptRowBookmarked().map((data) => {
      return {
        start: data?.startTime || 0,
        end: data?.endTime || 0
      };
    });
    setMarkList(res);
  }, [currentTranscription]);

  function filterTranscriptRowBookmarked() {
    if (!currentTranscription.data) return [];
    const transcriptBookmarked = currentTranscription.data
      .filter((data) => data.bookmark)
      .map((data) => {
        const parseWordJson = JSON.parse(data.wordsJson) as Array<TWord>;
        const findWordsBookmarked = parseWordJson.filter((word) =>
          word.style?.includes(EStyle.HIGHLIGHT)
        );
        if (findWordsBookmarked.length > 0) {
          const startItem = findWordsBookmarked[0];
          const endItem = findWordsBookmarked[findWordsBookmarked.length - 1];
          return {
            startTime: parseFloat(startItem.startTime) + userDefinedTimeCode / 1000,
            endTime: parseFloat(endItem.endTime) + userDefinedTimeCode / 1000
          };
        }
      });
    return transcriptBookmarked;
  }

  return (
    <div className={`ctn-video-player d-none ${isVisible && !isAudio ? 'd-md-block' : 'd-none'}`}>
      <div
        id="video-player-wrapper-id"
        className="position-relative d-flex justify-content-center align-items-center video-wrapper w-100 h-auto"
      >
        <div
          className="hidden-video-icon position-absolute"
          onClick={(e) => {
            setIsVisible(!isVisible);
            e.stopPropagation();
          }}
        >
          <BiChevronDown size={24} color="var(--si-white)" />
        </div>
        <div
          className="w-100 position-relative"
          style={{ height: 'auto' }}
          onClick={(e) => {
            setIsPlaying(!isPlaying);
          }}
        >
          <img
            src={thumbnailRender}
            alt="thumbail-media"
            className="rounded position-absolute top-0 start-0 w-100"
            style={{
              display: isReady ? 'none' : 'block'
            }}
          />
          {((isPlaying && currentTime === 0) || !isReady) && (
            <div
              className="position-absolute top-50 start-50"
              style={{ transform: 'translate(-50%, -50%)' }}
            >
              <YoutuBeLoader />
            </div>
          )}
          {!isPlaying && url && isReady && (
            <BsFillPlayFill
              className="position-absolute"
              color="var(--si-white)"
              size={100}
              style={{ opacity: 0.5, top: 'calc(50% - 50px)', left: 'calc(50% - 50px)' }}
            />
          )}
          <ReactPlayer
            playsinline={true}
            key={media?.id}
            ref={videoRef}
            playing={isPlaying && isReady}
            url={url}
            width={'100%'}
            height={'100%'}
            controls={false}
            pip={true}
            onReady={() => {
              setIsReady(true);
            }}
            volume={volume / 100}
            playbackRate={playBackRate}
            onPlay={() => {
              setIsPlaying(true);
            }}
            onPause={() => {
              setIsPlaying(false);
            }}
            onEnded={() => {
              setIsPlaying(false);
            }}
            progressInterval={100}
            onProgress={(value) => {
              let currentTime = value.playedSeconds;

              setCurrentTime(currentTime);
              dispatch(setTranscriptCursorTime({ time: currentTime }));
            }}
            onError={(e) => {
              notification.open({
                message:
                  e.target?.error?.message || t('Pages.TranscriptDetail.Errors.ErrorPlayingMedia'),
                type: 'error'
              });
            }}
            style={{
              flex: 1,
              color: 'white'
            }}
            playIcon={<></>}
          />
        </div>
        {!showPreview && thumbnail?.includes('poster-audio') && (
          <img
            onClick={() => {
              if (isReady) {
                setShowPreview(false);
                setIsPlaying(!isPlaying);
              }
            }}
            src={
              thumbnail?.includes('poster-audio')
                ? PosterAudioImg
                : thumbnail
                ? thumbnail
                : PosterVideoImg
            }
            alt="thumbail-media"
            width={'100%'}
            height={'auto'}
            className="rounded cursor-pointer"
          />
        )}
        {isReady && <CustomControlPlayer />}
        {media && media.status == 'subtitled' && <CaptionBox />}
      </div>
    </div>
  );
}

export default VideoPlayer;
