import { Spin, SpinProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const PageLoader = () => (
  <div className="d-flex align-items-center justify-content-center h-100">
    <LoadingOutlined style={{ color: 'var(--si-primary)', fontSize: 70 }} />
  </div>
);

export const YoutuBeLoader = () => (
  <div className="d-flex align-items-center justify-content-center h-100">
    <Loader size="large" indicator={<LoadingOutlined style={{ color: '#ffffff80' }} />} />
  </div>
);

export const Loader = ({ size, indicator, style }: SpinProps) => (
  <Spin
    style={style}
    indicator={indicator}
    className="d-flex justify-content-center align-items-center h-100"
    size={size}
  />
);

export const LoadingIndicator = () => {};
