import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

type Props = {
  src: string;
  alt: string;
  className: string;
  dummyImage: string;
};

export function ImageContainer({ src, alt, className, dummyImage }: Props) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasTriedToLoad, setHasTriedToLoad] = useState(false);

  useEffect(() => {
    setHasTriedToLoad(false);
    const img = new Image();
    img.onload = () => {
      setTimeout(() => {
        setImageLoaded(true);
      }, 500);
    };
    img.onerror = () => {
      setHasError(true);
      setHasTriedToLoad(true);
    };
    img.src = src;
  }, [src]);

  return (
    <div className="image-container">
      {!imageLoaded && !hasError && (
        <div className="">
          <Skeleton style={{ width: 62, height: 35 }} />
        </div>
      )}
      {hasError && !hasTriedToLoad && <div>Loading...</div>}
      {hasError && hasTriedToLoad && <img src={dummyImage} />}
      {imageLoaded && !hasError && (
        <img
          src={src}
          alt={alt}
          height={'100%'}
          className={`${className}`}
          width={'100%'}
          loading="lazy"
        />
      )}
    </div>
  );
}
