import { TCustomer, TProjectTransaction, TUser, TSubscription, TUserDeleted } from 'ts/types';
import { TAxiosResponse, TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from '../axios-client';
import { EFilterUserType } from 'ts/enums';

export async function getListUsersWithType({
  type,
  topCount = 50
}: {
  type: EFilterUserType;
  topCount?: number;
}): Promise<TResponse<TUser[]>> {
  const res = await AxiosCustom.get(`admin/users?topTypeCount=${topCount}&type=${type}`);
  return res.data;
}

export async function getListUsersDelete(): Promise<TResponse<TUserDeleted[]>> {
  const res = await AxiosCustom.get(`admin/users/deleted-users`);
  return res.data;
}

export async function getListUsersWithKeyword({
  keyword
}: {
  keyword: string;
}): Promise<TResponse<TUser[]>> {
  const res = await AxiosCustom.get(`admin/users?keyword=${keyword}`);
  return res.data;
}

export async function getListStripeInfor({ userId }: { userId: string }): Promise<TCustomer[]> {
  const res = await AxiosCustom.get(`admin/users/${userId}/stripe-info`);
  const data = Object.keys(res.data).map((key) => {
    return {
      id: key,
      ...res.data[key]
    };
  });
  return data;
}

export async function getListTransactionsByUser({
  userId,
  pageSize = 20,
  pageNumber = 0
}: {
  userId: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<{
  content: TProjectTransaction[];
  first: boolean;
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
}> {
  const res = await AxiosCustom.get(
    `admin/users/${userId}/transactions?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  return res.data;
}

export async function getListProjectsByUser({
  userId,
  pageSize = 20,
  pageNumber = 0
}: {
  userId: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<{
  content: TProjectTransaction[];
  first: boolean;
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
}> {
  const res = await AxiosCustom.get(
    `admin/users/${userId}/projects?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  return res.data;
}

export async function unlockProject({ projectId }: { projectId: string }) {
  const res = await AxiosCustom.post(`admin/users/${projectId}/unlock-project`);
  return res.status;
}

export async function getSubscriptionByUserId({
  userId
}: {
  userId: string;
}): Promise<TSubscription> {
  const res = await AxiosCustom.get(`admin/subscriptions/users/${userId}`);
  return res.data;
}

export async function getDetailUserByUserId({ userId }: { userId: string }): Promise<TUser> {
  const res = await AxiosCustom.get(`admin/users/${userId}`);
  return res.data;
}

export async function exportCSVUserData({
  userId
}: {
  userId: string;
}): Promise<TAxiosResponse<Blob>> {
  return await AxiosCustom.post(`admin/users/${userId}/export-data`, undefined, {
    responseType: 'arraybuffer'
  });
}

export async function deleteUser({ userId }: { userId: string }) {
  const res = await AxiosCustom.delete(`admin/users/${userId}`);
  return res.status;
}

export async function updateUser({
  userId,
  credit,
  subscriptionCredit,
  subscriptionCreditExpireDate
}: {
  userId: string;
  credit: number;
  subscriptionCredit: number;
  subscriptionCreditExpireDate: Date;
}) {
  const res = await AxiosCustom.put(`admin/users/${userId}`, {
    credit,
    subscriptionCredit,
    subscriptionCreditExpireDate
  });
  return res.data;
}

export async function syncSubscription({ userId }: { userId: string }): Promise<TUser> {
  const res = await AxiosCustom.post(`admin/users/${userId}/subscription-synchronizing`);
  return res.data;
}
