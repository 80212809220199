import { Slider, Tooltip } from 'antd';
import { PiSpeakerSimpleXFill, PiSpeakerSimpleNoneFill, PiSpeakerSimpleLowFill, PiSpeakerSimpleHighFill } from 'react-icons/pi';
import React, { useRef } from "react";
import { useVideoPlayerContext } from 'context/VideoPlayerContext';

export default function SpeakerControl() {
  const ctnRef = useRef<HTMLDivElement>(null);
  const { volume, setVolume } = useVideoPlayerContext();
  const [prevVolumn, setPrevVolumn] = React.useState<number>(volume);
  return <div ref={ctnRef} className='ctn-speaker-control'>
    <Tooltip
      overlayClassName='tooltip-overlay'
      placement="top"
      title={
        <div className='tooltip-title' style={{ height: 50 }}>
          <Slider
            trackStyle={{ background: 'white' }}
            railStyle={{ background: 'grey' }}
            handleStyle={{}}
            vertical
            value={volume}
            defaultValue={volume}
            onChange={(value: number) => {
              setVolume(value)
            }} />
        </div>
      }
      arrow={false}
    >
      <div className='d-flex align-items-center' onClick={() => {
        if (volume > 0) {
          setVolume(0);
          setPrevVolumn(volume);
        } else {
          setVolume(prevVolumn);
        }
      }}>
        {volume === 0 ? <PiSpeakerSimpleXFill />
          : volume < 40 ? <PiSpeakerSimpleNoneFill />
            : volume < 80 ? <PiSpeakerSimpleLowFill />
              : <PiSpeakerSimpleHighFill />}
      </div>
    </Tooltip>
  </div>
}