import { Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaDesktop,
  FaFileVideo,
  FaFilm,
  FaLaptop,
  FaMobileAlt,
  FaRegWindowMaximize,
  FaVideo
} from 'react-icons/fa';

import { LanguageSelector } from 'components/Footer';
import { EDelayTime } from 'ts/enums';

type TFooterList = {
  id: number;
  title: string;
  icon?: ReactNode;
  href?: string;
  style?: React.CSSProperties;
};

function Footer() {
  const { t } = useTranslation();

  const ListFooter: TFooterList[] = [
    {
      id: 0,
      title: t('Layouts.Footer.Texts.MyProjects'),
      href: '/'
    },
    {
      id: 1,
      title: t('Layouts.Footer.Texts.Pricing'),
      href: 'https://www.simonsaysai.com/pricing',
      style: { color: 'var(--si-dim-gray)' }
    },
    {
      id: 2,
      title: t('Layouts.Footer.Texts.FAQ'),
      href: 'https://www.simonsaysai.com/help',
      style: { color: 'var(--si-dim-gray)' }
    },
    {
      id: 3,
      title: t('Layouts.Footer.Texts.ToS'),
      href: 'https://www.simonsaysai.com/help/2657979-can-i-read-your-privacy-policy-terms-of-service',
      style: { color: 'var(--si-dim-gray)' }
    },
    {
      id: 4,
      title: t('Layouts.Footer.Texts.Contact'),
      href: 'https://www.simonsaysai.com/help/support-contact-us',
      style: { color: 'var(--si-dim-gray)' }
    },
    {
      id: 5,
      title: t('Layouts.Footer.Texts.CopyrightLabel'),
      href: 'https://www.simonsaysai.com/help',
      style: { color: 'var(--si-dim-gray)' }
    }
  ];

  const SubListFooter: TFooterList[] = [
    {
      id: 0,
      title: `${t('Layouts.Footer.Texts.Products.Title')}:`
    },
    {
      id: 1,
      title: t('Layouts.Footer.Texts.Products.List.Web'),
      icon: <FaRegWindowMaximize className="ms-1" size={14} />,
      href: 'https://www.simonsaysai.com/'
    },
    {
      id: 2,
      title: t('Layouts.Footer.Texts.Products.List.MacApp'),
      icon: <FaLaptop className="ms-1" size={14} />,
      href: 'https://apps.apple.com/app/simon-says-transcription/id1441555493'
    },
    {
      id: 3,
      title: t('Layouts.Footer.Texts.Products.List.AdobeExt'),
      icon: <FaFileVideo className="ms-1" size={14} />,
      href: 'https://exchange.adobe.com/apps/cc/105339/simon-says-ai'
    },
    {
      id: 4,
      title: t('Layouts.Footer.Texts.Products.List.FCPXExt'),
      icon: <FaFilm className="ms-1" size={14} />,
      href: 'https://apps.apple.com/app/simon-says-transcription/id1441555493'
    },
    {
      id: 5,
      title: t('Layouts.Footer.Texts.Products.List.DaVinciExt'),
      icon: <FaVideo className="ms-1" size={14} />,
      href: 'https://www.simonsaysai.com/blackmagic-davinci-resolve-extension'
    },
    {
      id: 6,
      title: t('Layouts.Footer.Texts.Products.List.iPhoneApp'),
      icon: <FaMobileAlt className="ms-1" size={14} />,
      href: 'https://apps.apple.com/app/simon-says-transcription/id1434952638'
    },
    {
      id: 7,
      title: t('Layouts.Footer.Texts.Products.List.MacPCOnPremise'),
      icon: <FaDesktop className="ms-1" size={14} />,
      href: 'https://www.simonsaysai.com/on-premise-ai-transcription'
    }
  ];

  const SmallScreenFooter: TFooterList[] = [
    {
      id: 0,
      title: t('Layouts.Footer.Texts.CopyrightLabel'),
      href: 'https://www.simonsaysai.com/'
    },
    {
      id: 1,
      title: t('Layouts.Footer.Texts.Pricing'),
      href: 'https://www.simonsaysai.com/pricing'
    },
    {
      id: 2,
      title: t('Layouts.Footer.Texts.FAQ'),
      href: 'https://www.simonsaysai.com/help'
    },
    {
      id: 3,
      title: t('Layouts.Footer.Texts.ToS'),
      href: 'https://www.simonsaysai.com/help/2657979-can-i-read-your-privacy-policy-terms-of-service'
    },
    {
      id: 4,
      title: t('Layouts.Footer.Texts.Contact'),
      href: 'https://www.simonsaysai.com/help/support-contact-us'
    }
  ];

  function renderFooterList({ listFooter }: { listFooter: TFooterList[] }) {
    return listFooter.map((itemFooter, index) => (
      <li className="d-flex align-items-center" key={itemFooter.id} style={{ fontWeight: 700 }}>
        {itemFooter.icon && itemFooter.icon}
        <Tooltip
          placement="topRight"
          trigger={'hover'}
          title={itemFooter.id !== 0 ? itemFooter.title : ''}
          mouseEnterDelay={EDelayTime.FAST}
        >
          {itemFooter.href ? (
            <a
              className={`${index != 0 ? 'a-hover' : ''}`}
              target="_blank"
              rel="noreferrer"
              href={`${itemFooter.href}`}
              style={itemFooter.style ? itemFooter.style : {}}
            >
              {itemFooter.title}
            </a>
          ) : (
            <p style={itemFooter.style ? itemFooter.style : {}}>{itemFooter.title}</p>
          )}
        </Tooltip>
        {index === 0 || index === listFooter.length - 1 || <span>|</span>}
      </li>
    ));
  }

  return (
    <footer>
      <div className="position-relative d-flex flex-column ">
        <ul className="footer-list d-md-flex d-none">
          {renderFooterList({ listFooter: ListFooter })}
          <LanguageSelector />
        </ul>
        <ul className="footer-sub-list d-none d-md-flex align-items-center">
          {renderFooterList({ listFooter: SubListFooter })}
        </ul>
        <ul className="footer-small-screen d-flex flex-wrap d-md-none justify-content-center">
          {renderFooterList({ listFooter: SmallScreenFooter })}
          <LanguageSelector />
        </ul>
        {process.env.REACT_APP_ENV !== 'PROD' && (
          <small className="position-absolute end-0 " style={{ bottom: '-10px' }}>
            {t('Shared.Fields.Version')}: {process.env.REACT_APP_VERSION}
          </small>
        )}
      </div>
    </footer>
  );
}

export default Footer;
