import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { popModal } from 'redux/modal/modal.slice';
import { EModals } from 'ts/enums';
import { ModalBase } from 'components/common';

export function QuickTipsModal() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <ModalBase
      modalName={EModals.QUICK_TIPS_MODAL}
      onCloseModal={() => {
        dispatch(popModal());
      }}
    >
      <div className="quick-tips-modal">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="quick-tips-content p-2 mt-8">
                <div className="header mb-2">{t('Pages.UploadMedia.QuickTipsModal.Title')}</div>
                <ol>
                  <li className="mt-1 mb-1">
                    <span>
                      <Trans t={t} i18nKey="Pages.UploadMedia.QuickTipsModal.Tip01">
                        This should be the link
                        <a href="https://www.simonsaysai.com/help/2657898-what-is-the-optimal-way-to-record-audio-for-transcription">
                          Link
                        </a>
                      </Trans>
                    </span>
                  </li>
                  <li className="mt-1 mb-1">
                    <span>{t('Pages.UploadMedia.QuickTipsModal.Tip02')}</span>
                  </li>
                  <li className="mt-1 mb-1">
                    <span>
                      <Trans t={t} i18nKey="Pages.UploadMedia.QuickTipsModal.Tip03">
                        This should be the link
                        <a href="https://www.simonsaysai.com/help/2650675-global-language-support">
                          Link
                        </a>
                      </Trans>
                    </span>
                  </li>
                  <li className="mt-1 mb-1">
                    <span>{t('Pages.UploadMedia.QuickTipsModal.Tip04')}</span>
                  </li>
                  <li className="mt-1 mb-1">
                    <span>
                      <Trans t={t} i18nKey="Pages.UploadMedia.QuickTipsModal.Tip05">
                        This should be the link
                        <a href="https://www.simonsaysai.com/help/3555917-what-formats-codecs-do-you-support">
                          Link
                        </a>
                      </Trans>
                    </span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
  );
}
