import React, { createContext, useContext, useEffect, useState } from 'react';
import { TUser } from 'ts/types';

interface CreditCardType {
  cardName: {
    data: string;
    error: string;
  };
  setCardName: ({ data, error }: { data: string; error: string }) => void;
  cardData: {
    cardNumber: string;
    error: string;
    type: string | null;
  } | null;
  setCardData: ({
    cardNumber,
    error,
    type
  }: {
    cardNumber: string;
    error: string;
    type: string | null;
  }) => void;

  isExistedCard: boolean;
  setIsExistedCard: (val: boolean) => void;

  isUpdateCardSaved: boolean;
  setIsUpdateCardSaved: (val: boolean) => void;

  expiryMonthData: {
    data: string;
    error: string;
  };
  setExpiryMonthData: ({ data, error }: { data: string; error: string }) => void;

  expiryYearData: {
    data: string;
    error: string;
  };
  setExpiryYearData: ({ data, error }: { data: string; error: string }) => void;

  cvcData: {
    data: string;
    error: string;
  };
  setCvcData: ({ data, error }: { data: string; error: string }) => void;

  isUpdatePayment: boolean;
  setIsUpdatePayment: (val: boolean) => void;

  isUpdateSubscriptionPayment: boolean;
  setIsUpdateSubscriptionPayment: (val: boolean) => void;

  triggerShowError: () => void;
  isReadyCard: boolean;
}

const CreditCardContext = createContext<CreditCardType>(null!);

export function CreditCardProvider({ children }: { children: React.ReactNode }) {
  const [cardName, setCardName] = useState<{ data: string; error: string }>({
    data: '',
    error: ''
  });

  const [cardData, setCardData] = useState<{
    cardNumber: string;
    error: string;
    type: string | null;
  } | null>({ cardNumber: '', error: '', type: null });

  const [isExistedCard, setIsExistedCard] = useState<boolean>(false);
  const [isUpdatePayment, setIsUpdatePayment] = useState<boolean>(true);
  const [isUpdateSubscriptionPayment, setIsUpdateSubscriptionPayment] = useState<boolean>(true);
  const [isUpdateCardSaved, setIsUpdateCardSaved] = useState<boolean>(false);
  const [expiryMonthData, setExpiryMonthData] = useState<{ data: string; error: string }>({
    data: '',
    error: ''
  });

  const [expiryYearData, setExpiryYearData] = useState<{ data: string; error: string }>({
    data: '',
    error: ''
  });

  const [cvcData, setCvcData] = useState<{ data: string; error: string }>({
    data: '',
    error: ''
  });

  const [isReadyCard, setIsReadyCard] = useState(false);

  useEffect(() => {
    if (
      !cardData?.cardNumber ||
      !cardName.data ||
      !cvcData.data ||
      !expiryMonthData.data ||
      !expiryYearData.data
    ) {
      setIsReadyCard(false);
    } else {
      setIsReadyCard(true);
    }
  }, [cardData, cardName, cvcData, expiryMonthData, expiryYearData]);

  function handleShowError() {
    if (!cardName.data) {
      setCardName({ data: '', error: 'required' });
    }
    if (!cardData?.cardNumber) {
      setCardData({
        error: 'This does not appear to be a valid credit card.',
        cardNumber: '',
        type: ''
      });
    }
    if (!expiryMonthData.data) {
      setExpiryMonthData({ data: '', error: 'required between 1-12' });
    }
    if (!expiryYearData.data) {
      setExpiryYearData({ data: '', error: 'required between 23-99' });
    }
    if (!cvcData.data) {
      setCvcData({ data: '', error: 'required 3 numbers' });
    }
  }

  const value = {
    cardName,
    setCardName: ({ data, error }: { data: string; error: string }) => setCardName({ data, error }),
    cardData,
    setCardData: ({
      cardNumber,
      error,
      type
    }: {
      cardNumber: string;
      error: string;
      type: string | null;
    }) => setCardData({ cardNumber, error, type }),
    isExistedCard,
    setIsExistedCard: (val: boolean) => setIsExistedCard(val),
    expiryMonthData,
    setExpiryMonthData: ({ data, error }: { data: string; error: string }) =>
      setExpiryMonthData({ data, error }),
    expiryYearData,
    setExpiryYearData: ({ data, error }: { data: string; error: string }) =>
      setExpiryYearData({ data, error }),
    cvcData,
    setCvcData: ({ data, error }: { data: string; error: string }) => setCvcData({ data, error }),
    isUpdateCardSaved,
    setIsUpdateCardSaved: (val: boolean) => setIsUpdateCardSaved(val),
    isUpdatePayment,
    setIsUpdatePayment: (val: boolean) => setIsUpdatePayment(val),
    isUpdateSubscriptionPayment,
    setIsUpdateSubscriptionPayment: (val: boolean) => setIsUpdateSubscriptionPayment(val),
    triggerShowError: () => handleShowError(),
    isReadyCard
  };

  return <CreditCardContext.Provider value={value}>{children}</CreditCardContext.Provider>;
}

export function useCreditCard({ currentUser }: { currentUser: TUser | null }) {
  const creditCard = useContext(CreditCardContext);
  const { setIsExistedCard } = creditCard;

  useEffect(() => {
    if (currentUser) {
      setIsExistedCard(currentUser.cardDigits ? true : false);
    }
  }, [currentUser]);

  return creditCard;
}
