// import { css } from 'linaria';
import { DraftJsButtonTheme } from '@draft-js-plugins/buttons';

export interface InlineToolbarPluginTheme {
  buttonStyles: DraftJsButtonTheme;
  toolbarStyles: {
    toolbar?: string;
  };
}

// HACK: Grt the default class names from @draft-js-plugins/inline-toolbar lib (check index.esm.js and plugin.css files for details)
const buttonStyles = {
  buttonWrapper: 'bpsgbes',
  button: 'b181v2oy',
  active: 'a9immln'
};

const toolbarStyles = {
  toolbar: 'tukdd6b'
};

export const defaultTheme: InlineToolbarPluginTheme = {
  buttonStyles,
  toolbarStyles
};
