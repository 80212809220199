import React, { useMemo, useState } from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { Avatar } from 'antd';
import type { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { IoSettings } from 'react-icons/io5';
import {
  EAccountSettingId,
  EModals,
  EPlanOption,
  ERole,
  ERoutes,
  ETypeSubcribeModal
} from 'ts/enums';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'antd';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { pushModal } from 'redux/modal/modal.slice';
import { useAuth } from 'context/AuthContext';
import { Crisp } from 'crisp-sdk-web';
// import { Crisp } from 'crisp-sdk-web';

export function AvatarDropdown() {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const dispatch = useAppDispatch();
  const { logout } = useAuth();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function openSubscribeModal() {
    dispatch(
      pushModal({
        name: EModals.SUBSCRIBE_MODAL,
        data: {
          type: ETypeSubcribeModal.UPDRAGE
        }
      })
    );
  }

  const renderName = () => {
    if (!currentUser.data) return;
    switch (currentUser.data.plan.planEnum) {
      case EPlanOption.PROPLUS_MONTHLY:
      case EPlanOption.PRO_ANNUAL:
      case EPlanOption.PRO_MONTHLY:
        return `${currentUser.data?.fullName} 🎥`;
      case EPlanOption.STARTER_ANNUAL:
      case EPlanOption.STARTER_MONTHLY:
        return `${currentUser.data?.fullName} 🎙️`;
      default:
        return `${currentUser.data?.fullName}`;
    }
  };

  const itemsS: MenuProps['items'] = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let displayMenu: any = [];
    const mainMenu = [
      {
        label: <span className="user-profile-name p-2">{renderName()}</span>,
        key: '0'
      },
      {
        label: (
          <div className="my-profile-button me-auto ms-auto">
            <Link
              to={`${ERoutes.ACCOUNT_SETTING}#${EAccountSettingId.PROFILE}`}
              className="d-flex align-items-center justify-content-center gap-1"
              onClick={() => {
                setIsOpen(false);
              }}
              reloadDocument
            >
              <IoSettings size={13} /> <span>{t('Shared.Fields.MyProfile')}</span>
            </Link>
          </div>
        ),
        key: '1'
      },
      {
        label: (
          <div
            className="action py-2"
            onClick={(e) => {
              e.stopPropagation();
              openSubscribeModal();
              setIsOpen(false);
            }}
          >
            <span>
              {currentUser.data?.plan.planEnum === EPlanOption.PAY_AS_YOU_GO
                ? t('Shared.Fields.SubscribeUpgrade')
                : t('Shared.Actions.Upgrade')}
            </span>
          </div>
        ),
        key: '2'
      },
      {
        label: (
          <div className="action d-flex align-items-center justify-content-center gap-1">
            <Link
              className="d-flex align-items-center justify-content-center flex-fill h-100"
              to={`${ERoutes.ACCOUNT_SETTING}#${EAccountSettingId.TRANSACTION_HISTORY}`}
              onClick={() => {
                setIsOpen(false);
              }}
              reloadDocument
            >
              <span>{t('Shared.Fields.TransactionHistory')}</span>
            </Link>
          </div>
        ),
        key: '3'
      }
    ];

    const adminMenu = [
      {
        label: (
          <div className="action d-flex align-items-center justify-content-center gap-1">
            <Link
              className="d-flex align-items-center justify-content-center flex-fill h-100"
              to={`${ERoutes.LASTEST_TRANSACTION}`}
              onClick={() => {
                setIsOpen(false);
              }}
              reloadDocument
            >
              <span>{t('Shared.Fields.LatestTransactions')}</span>
            </Link>
          </div>
        ),
        key: '4'
      },
      {
        label: (
          <div className="action d-flex align-items-center justify-content-center gap-1">
            <Link
              className="d-flex align-items-center justify-content-center flex-fill h-100"
              to={ERoutes.BLACK_WHITE_LIST}
              onClick={() => {
                setIsOpen(false);
              }}
              reloadDocument
            >
              <span>{t('Shared.Fields.BlacklistWhitelist')}</span>
            </Link>
          </div>
        ),
        key: '5'
      },
      {
        label: (
          <div className="action d-flex align-items-center justify-content-center gap-1">
            <Link
              className="d-flex align-items-center justify-content-center flex-fill h-100"
              to={ERoutes.USER_LOOKUP}
              onClick={() => {
                setIsOpen(false);
              }}
              reloadDocument
            >
              <span>{t('Shared.Fields.UserLookup')}</span>
            </Link>
          </div>
        ),
        key: '6'
      },
      {
        label: (
          <div className="action d-flex align-items-center justify-content-center gap-1">
            <Link
              className="d-flex align-items-center justify-content-center flex-fill h-100"
              to={ERoutes.PLANS}
              onClick={() => {
                setIsOpen(false);
              }}
              reloadDocument
            >
              <span>{t('Shared.Fields.Plans')}</span>
            </Link>
          </div>
        ),
        key: '7'
      },
      {
        label: (
          <div className="action d-flex align-items-center justify-content-center gap-1">
            <Link
              className="d-flex align-items-center justify-content-center flex-fill h-100"
              to={ERoutes.ADMIN_DEFAULT_CONFIG}
              onClick={() => {
                setIsOpen(false);
              }}
              reloadDocument
            >
              <span>{t('Shared.Fields.DefaultConfig')}</span>
            </Link>
          </div>
        ),
        key: '8'
      },
      {
        label: (
          <div className="action d-flex align-items-center justify-content-center gap-1">
            <Link
              className="d-flex align-items-center justify-content-center flex-fill h-100"
              to={ERoutes.TEAM}
              onClick={() => {
                setIsOpen(false);
              }}
              reloadDocument
            >
              <span>{t('Shared.Fields.Teams')}</span>
            </Link>
          </div>
        ),
        key: '9'
      },
      {
        label: (
          <div className="action d-flex align-items-center justify-content-center gap-1">
            <Link
              className="d-flex align-items-center justify-content-center flex-fill h-100"
              to={ERoutes.METRICS}
              onClick={() => {
                setIsOpen(false);
              }}
              reloadDocument
            >
              <span>{t('Shared.Fields.Metrics')}</span>
            </Link>
          </div>
        ),
        key: '10'
      },
      {
        label: (
          <div className="action d-flex align-items-center justify-content-center gap-1">
            <Link
              className="d-flex align-items-center justify-content-center flex-fill h-100"
              to={ERoutes.PUBLISHED_PROJECT}
              onClick={() => {
                setIsOpen(false);
              }}
              reloadDocument
            >
              <span>{t('Shared.Fields.PublishedProjects')}</span>
            </Link>
          </div>
        ),
        key: '11'
      },
      {
        label: (
          <div className="action d-flex align-items-center justify-content-center gap-1">
            <Link
              className="d-flex align-items-center justify-content-center flex-fill h-100"
              to={ERoutes.LANGUAGES}
              onClick={() => {
                setIsOpen(false);
              }}
              reloadDocument
            >
              <span>{t('Shared.Fields.Languages')}</span>
            </Link>
          </div>
        ),
        key: '12'
      }
    ];
    const superAdminMenu = [
      {
        label: (
          <div className="action d-flex align-items-center justify-content-center gap-1">
            <Link
              className="d-flex align-items-center justify-content-center flex-fill h-100"
              to={ERoutes.ADD_REMOVE_ADMIN}
              onClick={() => {
                setIsOpen(false);
              }}
              reloadDocument
            >
              <span>{t('Shared.Fields.AddRemoveAdmin')}</span>
            </Link>
          </div>
        ),
        key: '13'
      }
    ];

    const logoutItem = {
      label: (
        <div className="action d-flex align-items-center justify-content-center gap-1">
          <a
            className="d-flex align-items-center justify-content-center flex-fill h-100"
            onClick={() => {
              logout();
              if (Crisp && Crisp.isCrispInjected()) Crisp.session.reset();
              setIsOpen(false);
            }}
          >
            <span>{t('Shared.Actions.LogOut')}</span>
          </a>
        </div>
      ),
      key: '14'
    };

    if (currentUser.data?.role && currentUser.data.role.name === ERole.ROLE_ADMIN) {
      displayMenu = [...mainMenu, ...adminMenu, ...[logoutItem]];
    } else if (currentUser.data?.role.name === ERole.ROLE_SUPERADMIN) {
      displayMenu = [...mainMenu, ...adminMenu, ...superAdminMenu, ...[logoutItem]];
    } else {
      displayMenu = [...mainMenu, ...[logoutItem]];
    }
    return displayMenu;
  }, [currentUser]);
  return (
    <div
      className="dropdown-avatar cursor-pointer d-flex gap-1 align-items-center"
      onBlur={() => setIsOpen(false)}
    >
      <Dropdown
        menu={{ items: itemsS }}
        trigger={['click']}
        overlayClassName="dropdown-avatar-profile custom-scrollbar --hidden border"
        open={isOpen}
        onOpenChange={() => {
          setIsOpen(!isOpen);
        }}
      >
        <a
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
          onBlur={() => {
            setIsOpen(false);
          }}
        >
          <div className="d-none d-sm-inline-block">
            <Avatar style={{ marginRight: 3 }} size={55} src={currentUser.data?.avatar}>
              {currentUser.data?.fullName.slice(0, 1)}
            </Avatar>
          </div>
          <div className="d-inline-block d-sm-none">
            <Avatar style={{ marginRight: 3 }} size={27} src={currentUser.data?.avatar}>
              {currentUser.data?.fullName.slice(0, 1)}
            </Avatar>
          </div>
          <AiFillCaretDown size={12} color="var(--si-primary)" />
        </a>
      </Dropdown>
    </div>
  );
}
