import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

type Props = {
  isVisible: boolean;
  setIsvisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ShortCut({ isVisible, setIsvisible }: Props) {
  const { t } = useTranslation();

  const KeyShortCutMac = [
    {
      id: 0,
      key: '⌥ + space',
      text: t('Pages.TranscriptDetail.Shortcuts.PlayPause')
    },
    {
      id: 1,
      key: '⌘ + z',
      text: t('Pages.TranscriptDetail.Shortcuts.UndoChanges')
    },
    {
      id: 2,
      key: '⌘ + shift + z',
      text: t('Pages.TranscriptDetail.Shortcuts.RedoChanges')
    },
    {
      id: 3,
      key: '⌘ + ←',
      text: t('Pages.TranscriptDetail.Shortcuts.GoBack5S')
    },
    {
      id: 4,
      key: '⌘ + →',
      text: t('Pages.TranscriptDetail.Shortcuts.GoForward5S')
    },
    {
      id: 5,
      key: '⌘ + ↑',
      text: t('Pages.TranscriptDetail.Shortcuts.GoPreviousRow')
    },
    {
      id: 6,
      key: '⌘ + ↓',
      text: t('Pages.TranscriptDetail.Shortcuts.GoNextRow')
    },
    {
      id: 7,
      key: 'ctrl + .',
      text: t('Pages.TranscriptDetail.Shortcuts.IncreasePlaybackSpeed')
    },
    {
      id: 8,
      key: 'ctrl + ,',
      text: t('Pages.TranscriptDetail.Shortcuts.DecreasePlaybackSpeed')
    },
    {
      id: 9,
      key: 'shift + enter',
      text: t('Pages.TranscriptDetail.Shortcuts.CreateParagraphLine')
    },
    {
      id: 10,
      key: 'enter',
      text: t('Pages.TranscriptDetail.Shortcuts.CreateRow')
    },
    {
      id: 11,
      key: '⌘ + mouse-click',
      text: t('Pages.TranscriptDetail.Shortcuts.GoToTimecodePoint')
    },
    {
      id: 12,
      key: '⌘ + s',
      text: t('Pages.TranscriptDetail.Shortcuts.SaveChanges')
    }
  ];

  const KeyShortCutWin = [
    {
      id: 0,
      key: 'ctrl + space',
      text: t('Pages.TranscriptDetail.Shortcuts.PlayPause')
    },
    {
      id: 1,
      key: 'ctrl + z',
      text: t('Pages.TranscriptDetail.Shortcuts.UndoChanges')
    },
    {
      id: 2,
      key: 'ctrl + shift + z',
      text: t('Pages.TranscriptDetail.Shortcuts.RedoChanges')
    },
    {
      id: 3,
      key: 'ctrl + ←',
      text: t('Pages.TranscriptDetail.Shortcuts.GoBack5S')
    },
    {
      id: 4,
      key: 'ctrl + →',
      text: t('Pages.TranscriptDetail.Shortcuts.GoForward5S')
    },
    {
      id: 5,
      key: 'ctrl + ↑',
      text: t('Pages.TranscriptDetail.Shortcuts.GoPreviousRow')
    },
    {
      id: 6,
      key: 'ctrl + ↓',
      text: t('Pages.TranscriptDetail.Shortcuts.GoNextRow')
    },
    {
      id: 7,
      key: 'ctrl + .',
      text: t('Pages.TranscriptDetail.Shortcuts.IncreasePlaybackSpeed')
    },
    {
      id: 8,
      key: 'ctrl + ,',
      text: t('Pages.TranscriptDetail.Shortcuts.DecreasePlaybackSpeed')
    },
    {
      id: 9,
      key: 'shift + enter',
      text: t('Pages.TranscriptDetail.Shortcuts.CreateParagraphLine')
    },
    {
      id: 10,
      key: 'enter',
      text: t('Pages.TranscriptDetail.Shortcuts.CreateRow')
    },
    {
      id: 11,
      key: 'ctrl + mouse-click',
      text: t('Pages.TranscriptDetail.Shortcuts.GoToTimecodePoint')
    },
    {
      id: 12,
      key: 'ctrl + s',
      text: t('Pages.TranscriptDetail.Shortcuts.SaveChanges')
    }
  ];

  const [keyboard, setKeyboard] = useState(KeyShortCutMac);

  function changeVisible() {
    setIsvisible(!isVisible);
  }

  useEffect(() => {
    function osfunction() {
      let os = navigator.userAgent;
      let finalOs = '';
      if (os.search('Windows') !== -1) {
        finalOs = 'Windows';
        setKeyboard(KeyShortCutWin);
      } else if (os.search('Mac') !== -1) {
        finalOs = 'MacOS';
        setKeyboard(KeyShortCutMac);
      } else if (os.search('X11') !== -1 && !(os.search('Linux') !== -1)) {
        finalOs = 'UNIX';
      } else if (os.search('Linux') !== -1 && os.search('X11') !== -1) {
        finalOs = 'Linux';
      }
    }
    osfunction();
  }, []);

  return (
    <div className={`short-cut ${isVisible ? 'fade-in' : 'invisible'}`} onClick={changeVisible}>
      <button className="close-button cursor-pointer" onClick={changeVisible}>
        x
      </button>
      <div className="container-fluid d-flex flex-column justify-content-center h-100">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="left-container" onClick={(e) => e.stopPropagation()}>
              <div className="title">{t('Pages.TranscriptDetail.Shortcuts.Title')}</div>
              <div className="short-cut-container ms-auto">
                {keyboard.map((item) => (
                  <div className="short-cut-item mt-1" key={item.id}>
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-6">
                          <div className="short-cut-key ms-auto">{item.key}</div>
                        </div>
                        <div className="col-6">
                          <div className="text-short-cut">{item.text}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="right-container" onClick={(e) => e.stopPropagation()}>
              <div className="title ms-4 mt-7">{t('Pages.TranscriptDetail.Shortcuts.Intro')}</div>
              <ol>
                <li className="mt-2">
                  <a
                    href="https://www.youtube.com/watch?v=jE--LVWkufc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('Pages.TranscriptDetail.Shortcuts.Tip01')}
                  </a>
                </li>
                <li className="mt-2">
                  <span>{t('Pages.TranscriptDetail.Shortcuts.Tip02')}</span>
                </li>
                <li className="mt-2">
                  <a
                    href="https://www.simonsaysai.com/help/2770173-auto-sync-timecode-framerate-and-resolution"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('Pages.TranscriptDetail.Shortcuts.Tip03')}
                  </a>
                </li>
                <li className="mt-2">
                  <Trans t={t} i18nKey="Pages.TranscriptDetail.Shortcuts.Tip04">
                    Here are the links:{' '}
                    <a
                      href="https://www.simonsaysai.com/help/2657992-how-to-translate-your-project"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Link 1
                    </a>
                    <a
                      href="https://www.simonsaysai.com/help/3870087-visual-subtitle-editor"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Link 2
                    </a>
                    <a
                      href="https://www.simonsaysai.com/help/2650685-which-formats-can-i-export-my-transcript-to"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Link 3
                    </a>
                  </Trans>
                </li>
                <li className="mt-2">
                  <a
                    href="https://www.simonsaysai.com/help/4123009-how-to-share-projects"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('Pages.TranscriptDetail.Shortcuts.Tip05')}
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
