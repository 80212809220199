import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import SPEAKER_COLORS from 'constant/SpeakerColors';
import React, { useRef, useState } from 'react';
import { AiOutlineRightCircle } from 'react-icons/ai';
import { useAppSelector } from 'redux/hooks';
import { TSpeaker } from 'ts/types';
import _ from 'lodash';
import { createNewSpeaker } from 'apis/transcript.api';
import { useSearchParams } from 'react-router-dom';
import SpeakerItem from './SpeakerItem';

type Props = {
  currentSpeaker: TSpeaker | null;
  // eslint-disable-next-line no-unused-vars
  updateAllMatchingAutoSpeaker: (speaker: TSpeaker | null) => void;
  id: string;
};

function ContentPopoverSpeaker({ currentSpeaker, updateAllMatchingAutoSpeaker, id }: Props) {
  const [searchParams] = useSearchParams();
  const mediaId = searchParams.get('mediaId');
  const currentProject = useAppSelector((state) => state.projects.currentProject.data);
  const currentListSpeakers =
    currentProject?.medias.find((media) => media.id === mediaId)?.speakers || [];
  const { t } = useTranslation();
  const [newSpeaker, setNewSpeaker] = useState<string>('');

  function getNoRepeatedColor() {
    const availableColors = _.differenceBy(SPEAKER_COLORS, currentListSpeakers, 'color');
    if (availableColors[0]) {
      return availableColors[0].color;
    }
  }

  return (
    <div className="ctn-content-popover-speaker" onClick={(e) => e.stopPropagation()}>
      <Input
        id={`${id}-input-add-speaker`}
        maxLength={25}
        variant="borderless"
        placeholder={t('Shared.Actions.AddSpeaker')}
        onClick={(e) => {
          e.stopPropagation();
        }}
        value={newSpeaker}
        onChange={(e) => {
          setNewSpeaker(e.currentTarget.value);
        }}
        onPressEnter={async (e) => {
          const speakerName = e.currentTarget.value;
          const color = getNoRepeatedColor();
          if (speakerName && mediaId) {
            createNewSpeaker({ name: speakerName.trim(), color, mediaId }).then((value) =>
              updateAllMatchingAutoSpeaker(value)
            );
            setNewSpeaker('');
          }
        }}
        style={{ background: 'var(--si-light)' }}
      />
      <div className="mt-1" style={{ maxHeight: 210, overflow: 'auto' }}>
        <div className="mb-1 cursor-pointer">
          <AiOutlineRightCircle
            onClick={() => {
              updateAllMatchingAutoSpeaker(null);
            }}
            size={20}
          />
          <span className="align-middle ms-1">({t('Pages.TranscriptDetail.PS4.NoSpeaker')})</span>
        </div>
        {currentListSpeakers.map((speaker: TSpeaker) => (
          <SpeakerItem
            speaker={speaker}
            key={speaker.id}
            onChooseSpeaker={updateAllMatchingAutoSpeaker}
            active={currentSpeaker?.id === speaker.id ? true : false}
          />
        ))}
      </div>
    </div>
  );
}

export default ContentPopoverSpeaker;
