import { Button, Input, Select, notification } from 'antd';
import {
  getDetailTransaction,
  getListUsers,
  retryJob,
  updateProjectName
} from 'apis/admin/lastestTransaction.api';
import CardFile from 'components/Admin/ProjectDetailPage/CardFile';
import { usePageLoading } from 'context/PageLoadingContext';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHome } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { pushModal } from 'redux/modal/modal.slice';
import { getProjectByIdAction } from 'redux/projects/projects.action';
import { EHttpStatusCode, EModals, ERoutes } from 'ts/enums';
import { TProjectTransaction, TUser } from 'ts/types';

function ProjectDetailPage() {
  const [listUsers, setListUsers] = useState<TUser[]>([]);
  const { projectId } = useParams();
  const [currentProjectDetail, setCurrentProjectDetail] = useState<TProjectTransaction | null>(
    null
  );
  const { openMessage, destroyMessage } = usePageLoading();
  const [projectNameInput, setProjectNameInput] = useState('');
  const [isDisableSaveName, setDisableSaveName] = useState(true);
  const [isOpenAssign, setIsOpenAssign] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (projectId) {
      handleGetCurrentProjectDetail({ id: projectId });
    }
  }, [projectId]);

  async function handleGetCurrentProjectDetail({ id }: { id: string }) {
    try {
      destroyMessage();
      openMessage('loading', t('Shared.Texts.LoadingW3Dot'));
      const res = await getDetailTransaction({ transactionId: id });
      if (res) {
        setProjectNameInput(res.name);
        setCurrentProjectDetail(res);
      }
      destroyMessage();
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleGetListUsers() {
    try {
      destroyMessage();
      openMessage('loading', t('Shared.Texts.LoadingW3Dot'));
      const res = await getListUsers();
      if (res) {
        setListUsers(res);
        setIsOpenAssign(true);
      }
      destroyMessage();
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  function handleOnChangeName(val: string) {
    setDisableSaveName(!(val !== currentProjectDetail?.name));
    setProjectNameInput(val);
  }

  async function handleUpdateProjectName() {
    if (!projectId || !currentProjectDetail) return;
    try {
      destroyMessage();
      openMessage('loading', t('Shared.Texts.UpdatingW3Dot'));
      const res = await updateProjectName({
        projectId: projectId,
        name: projectNameInput,
        userEmail: currentProjectDetail?.userEmail
      });
      if (res === EHttpStatusCode.OK) {
        destroyMessage();
        openMessage('success', t('Shared.Texts.Updated'));
        handleGetCurrentProjectDetail({ id: projectId });
        setDisableSaveName(true);
      }
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleAssignNewUser({ email }: { email: string }) {
    if (!projectId || !email || !currentProjectDetail) return;
    try {
      destroyMessage();
      openMessage('loading', t('Shared.Texts.UpdatingW3Dot'));
      const res = await updateProjectName({
        projectId: projectId,
        name: currentProjectDetail?.name,
        userEmail: email
      });
      if (res === EHttpStatusCode.OK) {
        destroyMessage();
        openMessage('success', t('Shared.Texts.Updated'));
        handleGetCurrentProjectDetail({ id: projectId });
        setIsOpenAssign(false);
      }
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleRefetchData() {
    if (!projectId) return;
    handleGetCurrentProjectDetail({ id: projectId });
  }

  async function handleRetryJob() {
    if (!projectId) return;
    try {
      destroyMessage();
      openMessage('loading', t('Shared.Texts.RetryingW3Dot'));
      const res = await retryJob({ projectId });
      if (res) {
        destroyMessage();
        openMessage('success', ' Retry job process has been started successfully.');
        if (projectId) {
          setTimeout(() => {
            handleGetCurrentProjectDetail({ id: projectId });
          }, 2000);
        }
      }
    } catch (error: any) {
      destroyMessage();
      openMessage('error', `${error.response.data.msg}`);
    }
  }

  return (
    <div>
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome
            size={30}
            color="var(--si-primary)"
            onClick={() => navigate(ERoutes.LASTEST_TRANSACTION)}
            className="cursor-pointer"
          />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            {t('Pages.Admin.ProjectDetail.Title')}
          </span>
        </div>
      </div>
      <div className="container p-3 my-3 rounded" style={{ background: 'var(--bs-gray-200)' }}>
        <div className="d-flex align-items-center justify-content-between">
          <h4>{t('Pages.Admin.ProjectDetail.ProjectInfo')}</h4>
          <div>
            <Button
              type="primary"
              onClick={() => {
                if (projectId) {
                  dispatch(getProjectByIdAction({ projectId })).then(() => {
                    dispatch(pushModal({ name: EModals.EXPORT_MODAL, data: { mediaId: null } }));
                  });
                }
              }}
            >
              {t('Shared.Actions.Export')}
            </Button>
            <Button
              type="primary"
              className="mx-1"
              disabled={
                currentProjectDetail?.status !== 'failed' &&
                currentProjectDetail?.files.find((file) => file.status !== 'failed')
                  ? true
                  : false
              }
              onClick={handleRetryJob}
            >
              {t('Shared.Actions.RetryJob')}
            </Button>
            <Button type="primary" danger>
              {t('Shared.Actions.Delete')}
            </Button>
          </div>
        </div>
        <div className="row my-4 g-3">
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">{t('Shared.Fields.Owner')}</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail?.username} / {currentProjectDetail?.userEmail}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">{t('Shared.Fields.CreationDate')}</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {moment(currentProjectDetail?.creationDate).format('MM/DD/YY hh:mm A')}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">{t('Shared.Fields.ProjectName')}</small>
              <Button
                onClick={handleUpdateProjectName}
                className="ms-1"
                type="primary"
                disabled={isDisableSaveName || projectNameInput.length <= 0}
              >
                {t('Shared.Actions.Save')}
              </Button>
            </div>
            <div className="border rounded" style={{ minHeight: 42, background: 'white' }}>
              <Input
                value={projectNameInput}
                onChange={(e) => handleOnChangeName(e.target.value)}
                placeholder={t('Shared.Fields.ProjectName')}
                variant="borderless"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">{t('Shared.Fields.Status')}</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail?.status === 'ready'
                ? t('Shared.Texts.Transcribed')
                : currentProjectDetail?.status}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">{t('Shared.Fields.ProjectUrl')}</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail?.files && currentProjectDetail?.files?.length > 0 && (
                <Link
                  to={`${ERoutes.TRANSCRIPT_DETAIL}/${currentProjectDetail?.id}?mediaId=${
                    currentProjectDetail?.files[currentProjectDetail?.files.length - 1].id
                  }`}
                  className="custom-a-tag"
                >
                  {t('Shared.Actions.GoToProject')}
                </Link>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">{t('Shared.Fields.PaymentMethod')}</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail && currentProjectDetail?.credit > 0
                ? t('Shared.Fields.MethodCredit')
                : t('Shared.Fields.MethodCreditCard')}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">{t('Shared.Fields.Language')}</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail?.languageDisplayName}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">{t('Shared.Fields.TotalLength')}</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail?.totalLengthOfProject}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">{t('Shared.Fields.CreditSpent')}</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail?.credit}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">{t('Shared.Fields.AmountSpent')}</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(currentProjectDetail?.price ? currentProjectDetail.price : 0)}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">{t('Shared.Fields.AppType')}</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail?.applicationType}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">{t('Shared.Fields.PublishStatus')}</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail?.sharedProject ? t('Shared.Texts.Yes') : t('Shared.Texts.No')}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold me-1">{t('Shared.Fields.Ownership')}</small>
              <Button
                type="primary"
                onClick={() => {
                  handleGetListUsers();
                }}
              >
                {t('Shared.Actions.AssignToNewUser')}
              </Button>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail?.username} ({currentProjectDetail?.userEmail})
            </div>
          </div>
          <div className={`col-12 col-md-6 ${isOpenAssign ? 'd-block' : 'd-none'}`}>
            <div className="mb-1">
              <small className="fw-bold me-1">{t('Shared.Texts.ChangeOwnership')}</small>
              <Button type="primary" danger onClick={() => setIsOpenAssign(false)}>
                {t('Shared.Actions.Cancel')}
              </Button>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              <Select
                className="w-100"
                showSearch
                suffixIcon={null}
                allowClear
                onSelect={(value) => {
                  handleAssignNewUser({ email: value });
                }}
                placeholder={t('Shared.Texts.SelectAUser')}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={listUsers
                  .filter((user) => user.email)
                  .map((user) => {
                    return {
                      label: user.fullName + ' (' + user.email + ')',
                      value: user.email
                    };
                  })}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold me-1">{t('Shared.Actions.Merge')}</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              <Link
                to={`${ERoutes.MERGE_PROJECTS}/${currentProjectDetail?.id}/${currentProjectDetail?.userId}`}
                className="custom-a-tag"
              >
                {t('Shared.Actions.MergeProject')}
              </Link>
            </div>
          </div>
        </div>
        <h4 className="mb-3">{t('Shared.Fields.FileS')}:</h4>
        {currentProjectDetail?.files &&
          currentProjectDetail?.files.length > 0 &&
          currentProjectDetail?.files.map((file, index) => {
            return (
              <CardFile key={file.id} file={file} index={index} onSuccess={handleRefetchData} />
            );
          })}
      </div>
    </div>
  );
}

export default ProjectDetailPage;
