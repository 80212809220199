import { setAccessTokenBox } from 'apis/userSetting.api';
import { PageLoader } from 'components/common/Loader';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUserInfoAction } from 'redux/auth/auth.action';
import { useAppDispatch } from 'redux/hooks';
import { pushModal } from 'redux/modal/modal.slice';
import { EModals, ERoutes, ETypeNoti } from 'ts/enums';

function BoxCallBackPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const accesstoken = searchParams.get('accesstoken');
  const refreshtoken = searchParams.get('refreshtoken');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (accesstoken && refreshtoken) {
      setAccessTokenBox({ accesstoken, refreshtoken })
        .then(() => {
          dispatch(
            pushModal({
              name: EModals.NOTI_MODAL,
              data: null,
              notiData: {
                type: ETypeNoti.SUCCESS,
                title: t('Pages.BoxCallBack.Texts.ConnectedSuccessfully')
              }
            })
          );
        })
        .catch(() => {
          dispatch(
            pushModal({
              name: EModals.NOTI_MODAL,
              data: null,
              notiData: {
                type: ETypeNoti.ERROR,
                title: 'Error'
              }
            })
          );
          navigate(`${ERoutes.ACCOUNT_SETTING}#connected-apps`);
        })
        .finally(() => {
          navigate(`${ERoutes.ACCOUNT_SETTING}#connected-apps`);
          dispatch(getUserInfoAction());
        });
    }
  }, [accesstoken, refreshtoken]);

  return (
    <div className="d-flex justify-content-center pt-3">
      <PageLoader />
    </div>
  );
}

export default BoxCallBackPage;
