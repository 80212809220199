import { Badge, Radio, RadioChangeEvent, Select, Tabs, TabsProps } from 'antd';
import { CreditCard, ModalBase, TimeDisplay } from 'components/common';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { popModal, pushModal } from 'redux/modal/modal.slice';
import { EMaxLength, EModals, ERoutes, ETypeNoti } from 'ts/enums';
import { TChangeTime, TDictionary, TLanguage, TLanguageConvert } from 'ts/types';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import {
  convertCreditToHourMinute,
  convertDurationToHourMinute,
  pushStripeTransactionTracking
} from 'utils/common';
import { TResponseTranslatePrice } from 'ts/types/TResponse';
import { Link, useNavigate } from 'react-router-dom';
import { FaExternalLinkSquareAlt, FaInfoCircle, FaMagic } from 'react-icons/fa';
import {
  checkoutTranscribe,
  checkoutTranscribeWithoutCredit,
  combinePrice,
  getPriceForTranscribe
} from 'apis/price.api';
import { transcribing } from 'apis/transcript.api';
import { updateCurrentProjectAction } from 'redux/projects/projects.action';
import { Loader } from 'components/common/Loader';
import { useCreditCard } from 'context/CreditCardContext';
import { setDictionaryScreen } from 'redux/dictionary/dictionary.slice';
import { changeNumberToCurrency } from 'utils/price';
import { updateTranslateProjectId } from 'redux/projects/projects.slice';
import { useTranslation } from 'react-i18next';
import { usePageLoading } from 'context/PageLoadingContext';
import { stripeCreateTranscribeOrder } from 'apis/serverless.api';

export const PaymentModal = () => {
  const dispatch = useAppDispatch();
  const listAllLanguages = useAppSelector((state) => state.languages.listAllLanguages);
  const [listLangConvert, setListLangConvert] = useState<TLanguageConvert[]>([]);
  const [listLastUsed, setListLastUsed] = useState<TLanguageConvert[]>([]);
  const [listFrequentlyUsed, setListFrequentlyUsed] = useState<TLanguageConvert[]>([]);
  const [listLanguagesOther, setListLanguagesOther] = useState<TLanguageConvert[]>([]);

  const [langLabelSelected, setLangLabelSelected] = useState<string | null>(null);
  const lastUsedTranscriptionLanguageRegion = useAppSelector(
    (state) => state.auth.currentUser.data?.lastUsedTranscriptionLanguageRegion
  );
  const [currentPriceInfo, setCurrentPriceInfo] = useState<TResponseTranslatePrice | null>(null);
  const [transcribePrice, setTranscribePrice] = useState<TResponseTranslatePrice | null>(null);
  const userData = useAppSelector((state) => state.auth.currentUser.data);
  const creditToTime = (currentPriceInfo && convertCreditToHourMinute(currentPriceInfo.credit)) || {
    hour: 0,
    minutes: 0
  };
  const [changeAbleTime, setChangeAbleTime] = useState<TChangeTime>({ hour: 0, minutes: 0 });
  const projectId = useAppSelector((state) => state.modal.lastModal?.data?.projectId);
  const currentProject = useAppSelector((state) => state.projects.currentProject);
  const navigate = useNavigate();
  const currentDictionary = useAppSelector((state) => state.dictionary.currentDictionary);
  const [dictionaryIdSelected, setDictionaryIdSelected] = useState<number | null>(null);
  const [listDictionary, setListDictionary] = useState<TDictionary[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const currentUser = useAppSelector((state) => state.auth.currentUser.data);
  const { t } = useTranslation();
  const { openMessage, destroyMessage } = usePageLoading();

  const [includeTranslate, setIncludeTranslate] = useState<boolean>(false);
  const {
    cardData,
    cardName,
    cvcData,
    expiryMonthData,
    expiryYearData,
    isUpdateCardSaved,
    isExistedCard,
    isReadyCard,
    triggerShowError
  } = useCreditCard({
    currentUser
  });

  const durationToTime = (transcribePrice?.totalDuration &&
    convertDurationToHourMinute(transcribePrice?.totalDuration)) || {
    hour: 0,
    minutes: 0
  };

  const [listLanguagesAvailable, setListLanguagesAvailable] = useState<TLanguage[]>([]);
  const [listLanguagesUsed, setListLanguagesUsed] = useState<TLanguage[]>([]);
  const [listLangLabelSelected, setListLangLabelSelected] = useState<Array<any>>([]);
  const [listLangIDSelected, setListLangIDSelected] = useState<Array<number>>([]);

  const lastUsedTranslationLanguage = useAppSelector(
    (state) => state.auth.currentUser.data?.lastUsedTranslationLanguage || ''
  );

  useEffect(() => {
    if (listAllLanguages.data && listAllLanguages.data?.length > 0) {
      const listLangRegions = listAllLanguages.data.filter((lang) => lang.regions.length > 0);
      const newListLang = listLangRegions.flatMap((lang) =>
        lang.regions.map((region) => ({
          id: lang.id,
          label: lang.label,
          direction: lang.direction,
          code: `${lang.code}-${region.code}`,
          translationCode: lang.translationCode,
          captionCode: lang.captionCode,
          fullStopSymbol: lang.fullStopSymbol,
          characterRatio: lang.characterRatio,
          fontName: lang.fontName,
          fontSize: lang.fontSize,
          regionName:
            `${lang.label} (${region.name})` == 'English (Automatic)'
              ? t('Pages.Main.PaymentModal.AutomaticLanguageDetection')
              : `${lang.label} (${region.name})`,
          defaultSpeechApi: region.defaultSpeechApi,
          diarizationSupported: region.diarizationSupported,
          favorite: region.favorite,
          regionId: region.id
        }))
      );

      setListLangConvert(newListLang);
    }
  }, [listAllLanguages, lastUsedTranscriptionLanguageRegion]);

  useEffect(() => {
    //Set list Last used
    const listLastUsed = listLangConvert.filter(
      (lang) => lastUsedTranscriptionLanguageRegion === lang.regionId
    );
    setListLastUsed(listLastUsed);

    //Set list Frequently
    const listFrequentlyConvert = listLangConvert.filter(
      (lang) => lang.favorite && lastUsedTranscriptionLanguageRegion !== lang.regionId
    );
    setListFrequentlyUsed(listFrequentlyConvert);

    //Set list Other
    const listOtherConvert = listLangConvert.filter(
      (lang) => !lang.favorite && lastUsedTranscriptionLanguageRegion !== lang.regionId
    );
    setListLanguagesOther(listOtherConvert);
  }, [listLangConvert]);

  function setDictionary() {
    if (!currentProject.data) return;
    const langProject = currentProject.data.languageRegionCode;
    const defaultSpeechApi = currentProject.data.defaultSpeechApi;

    if (langProject && listLangConvert.length > 0) {
      const listLangMatch = listLangConvert.filter(
        (lang) => lang.code === langProject && lang.defaultSpeechApi === defaultSpeechApi
      );
      const listDictionaryFilter = currentDictionary.data.filter(
        (dic) => dic.languageId === listLangMatch[0].id
      );
      setLangLabelSelected(listLangMatch[0].regionName);
      setListDictionary(listDictionaryFilter);
    }
  }

  function onChangeSelectLang(e: string) {
    const value = JSON.parse(e);
    setLangLabelSelected(value.label);
    setLoading(true);

    if (currentProject.data) {
      const newProject = {
        ...currentProject.data,
        languageRegionCode: value.code,
        defaultSpeechApi: value.defaultSpeechApi
      };

      if (newProject) {
        dispatch(
          updateCurrentProjectAction({
            project: newProject
          })
        ).then(() => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });

        const listLangMatch = listLangConvert.filter(
          (lang) =>
            lang.code === newProject.languageRegionCode &&
            lang.defaultSpeechApi === newProject.defaultSpeechApi
        );
        const listDictionaryFilter = currentDictionary.data.filter(
          (dic) => dic.languageId === listLangMatch[0].id
        );
        setListDictionary(listDictionaryFilter);
      }
    }
  }

  function onChangeSelectDictionary(e: number) {
    setDictionaryIdSelected(e);
  }

  function openHoorayModal() {
    dispatch(
      pushModal({
        name: EModals.HOORAY_MODAL,
        data: undefined
      })
    );
  }

  async function transcribeWithoutCreditCard({ projectId }: { projectId: string }) {
    setLoading(true);
    openMessage('loading', t('Shared.Texts.LoadingW3Dot'));
    const res = await checkoutTranscribe({
      projectId,
      ...(listLangIDSelected.length > 0 ? { listTranslateId: listLangIDSelected } : {})
    });

    if (res && 'message' in res) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: { type: ETypeNoti.ERROR, title: `${res.message}` }
        })
      );
      setLoading(false);
      destroyMessage();
      return;
    }

    if (res && !('message' in res)) {
      if (res.stripeTransactionDetail) pushStripeTransactionTracking(res.stripeTransactionDetail);

      const response = dictionaryIdSelected
        ? await transcribing({ projectId, dictionaryId: dictionaryIdSelected })
        : await transcribing({ projectId });

      if (response && response.data.translateProjectId) {
        dispatch(
          updateTranslateProjectId({ translateProjectId: response.data.translateProjectId })
        );
      }

      destroyMessage();
      //navigate(`${ERoutes.PURCHASE_SUCCESS}/transcription?projectId=${projectId}`);

      openHoorayModal();
      setTimeout(() => {
        dispatch(popModal());
        setLangLabelSelected(null);
        setLoading(false);
        setCurrentPriceInfo(null);
        setTranscribePrice(null);
      }, 1500);
      setTimeout(() => {
        navigate(`${ERoutes.TRANSCRIPT_DETAIL}/${projectId}`);
      }, 2000);
    }
  }

  async function transcribeWithCreditCard({ projectId }: { projectId: string }) {
    setLoading(true);
    openMessage('loading', t('Shared.Texts.LoadingW3Dot'));
    const res = await checkoutTranscribeWithoutCredit({
      projectId,
      cardHolderName: cardName.data,
      cvc: cvcData.data,
      exp_month: expiryMonthData.data,
      exp_year: expiryYearData.data,
      number: cardData?.cardNumber ?? '',
      saveCard: isUpdateCardSaved,
      replaceCard: true,
      ...(listLangIDSelected.length > 0 ? { listTranslateId: listLangIDSelected } : {})
    });

    if (res && 'message' in res) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: { type: ETypeNoti.ERROR, title: `${res.message}` }
        })
      );
      setLoading(false);
      destroyMessage();
      return;
    }

    if (res && !('message' in res)) {
      if (res.stripeTransactionDetail) pushStripeTransactionTracking(res.stripeTransactionDetail);

      dictionaryIdSelected
        ? transcribing({ projectId, dictionaryId: dictionaryIdSelected })
        : transcribing({ projectId });

      destroyMessage();
      //navigate(`${ERoutes.PURCHASE_SUCCESS}/transcription?projectId=${projectId}`);

      openHoorayModal();
      setTimeout(() => {
        dispatch(popModal());
        setLangLabelSelected(null);
        setLoading(false);
        setCurrentPriceInfo(null);
        setTranscribePrice(null);
      }, 1500);
      setTimeout(() => {
        navigate(`${ERoutes.TRANSCRIPT_DETAIL}/${projectId}`);
      }, 2000);
    }
  }

  async function transcribeData() {
    if (!projectId) return;

    if (!langLabelSelected) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: {
            type: ETypeNoti.WARNING,
            title: t('Pages.Main.PaymentModal.SelectTranscriptLanguage')
          }
        })
      );
      return;
    }

    if (includeTranslate && (!listLangIDSelected || listLangIDSelected.length === 0)) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: { type: ETypeNoti.WARNING, title: t('Shared.Texts.SelectTranslationLanguage') }
        })
      );
      return;
    }

    if (!isReadyCard && !isExistedCard && currentPriceInfo && currentPriceInfo.totalPrice > 0) {
      triggerShowError();
      return;
    }

    if (isReadyCard && !isExistedCard) {
      transcribeWithCreditCard({ projectId });
    } else {
      transcribeWithoutCreditCard({ projectId });
    }
  }

  useEffect(() => {
    if (projectId && !includeTranslate) {
      (async () => {
        setLoading(true);
        const res = await getPriceForTranscribe({ projectId });
        if (res && !('message' in res)) {
          setCurrentPriceInfo(res);
          setTranscribePrice(res);
          setChangeAbleTime(convertDurationToHourMinute(res.chargeableTimeInSeconds));
          setDictionary();
          setListLangIDSelected([]);
          setListLangLabelSelected([]);
        }

        if (res && 'message' in res) {
          dispatch(
            pushModal({
              name: EModals.NOTI_MODAL,
              data: null,
              notiData: { type: ETypeNoti.ERROR, title: `Unable to obtain the price, please refresh the page and try again :)` }
            })
          );
          setLoading(false);
          destroyMessage();
          return;
        }
        
        setLoading(false);
      })();
    }
  }, [projectId, includeTranslate]);

  useEffect(() => {
    if (listAllLanguages.data && listAllLanguages.data?.length > 0) {
      const tempAvailable = listAllLanguages.data.filter((lang) =>
        lastUsedTranslationLanguage
          ? !JSON.parse(lastUsedTranslationLanguage).includes(lang.id)
          : lang
      );
      const tempUsed = listAllLanguages.data.filter((lang) =>
        lastUsedTranslationLanguage
          ? JSON.parse(lastUsedTranslationLanguage).includes(lang.id)
          : false
      );
      setListLanguagesUsed(tempUsed);
      setListLanguagesAvailable(tempAvailable);
    }
  }, [listAllLanguages]);

  const onChangeOptionTranscribe = (e: RadioChangeEvent) => {
    setIncludeTranslate(e.target.value);
  };

  function handleCheckAll() {
    if (listAllLanguages.data) {
      const allLang = listAllLanguages.data.map((lang) =>
        JSON.stringify({ label: lang.label, id: lang.id })
      );
      if (allLang) {
        setListLangLabelSelected(allLang);
      }

      const listId = listAllLanguages.data.map((lang) => lang.id);
      setListLangIDSelected(listId);
    }
  }

  function handleUnCheckAll() {
    setListLangLabelSelected([]);
    setListLangIDSelected([]);
  }

  async function onChangeSelectLangTranslate(e: Array<any>) {
    setLoading(true);
    const listValue = e.map((item) => JSON.parse(item));
    setListLangLabelSelected(e);
    const listId = listValue.map((lang) => lang.id);
    setListLangIDSelected(listId);

    if(listId.length > 0) {
      const price = await combinePrice(projectId || '', e.length);
      if(transcribePrice) {
        price.totalPrice += transcribePrice.totalPrice
      }
  
      setCurrentPriceInfo(price);
      setChangeAbleTime(convertDurationToHourMinute(price.chargeableTimeInSeconds));
    } else if(transcribePrice) {
      setCurrentPriceInfo(transcribePrice);
      setChangeAbleTime(convertDurationToHourMinute(transcribePrice.chargeableTimeInSeconds));
    }
    
    setLoading(false);
  }

  if (currentPriceInfo === null || (!currentPriceInfo && !userData)) return null;

  const handleStripeCheckout = () => {
    if (!projectId || !currentUser || !currentProject.isFetched || !currentProject.data) return;

    if (!langLabelSelected) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: {
            type: ETypeNoti.WARNING,
            title: t('Pages.Main.PaymentModal.SelectTranscriptLanguage')
          }
        })
      );
      return;
    }

    if (includeTranslate && (!listLangIDSelected || listLangIDSelected.length === 0)) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: { type: ETypeNoti.WARNING, title: t('Shared.Texts.SelectTranslationLanguage') }
        })
      );
      return;
    }

    setLoading(true);

    stripeCreateTranscribeOrder(
      currentProject?.data?.id,
      currentUser?.email,
      currentUser?.id,
      dictionaryIdSelected || null,
      listLangIDSelected || []
    ).then((data) => {
      window.location.href = data.url;
    }).catch((err) => {
      console.error(err);
      setLoading(false);
    });
  };

  const paymentMethods: TabsProps["items"] = [
    {
      key: "stripe",
      label: (
        <>
          {t("Shared.PaymentMethods.Stripe")}{" "}
          {/* <Badge size="small" count={"BETA"} className="mb-2" /> */ }
        </>
      ),
      children: (
        <>
          <div className={`${!loading ? "d-none" : "mt-2"}`}>
            <Loader />
          </div>
          {!loading && (
            <div className="pt-3 pb-3 text-center">
              <button
                className="submit-button"
                onClick={handleStripeCheckout}
                disabled={loading}
              >
                <FaMagic size={24} className="me-1" />
                <span>{t("Shared.Texts.LetsDoThis")}</span>
              </button>
            </div>
          )}
        </>
      ),
    },
    {
      key: "creditcard",
      label: t("Shared.PaymentMethods.CreditCard"),
      children: (
        <>
          <div className="mb-1">
            <CreditCard/>
          </div>
          <div className="text-center">
            <button
              className="submit-button px-2 py-1"
              style={{ borderRadius: 98 }}
              onClick={transcribeData}
              disabled={loading}
            >
              {loading ? (
                <Loader />
              ) : (
                <>
                  <FaWandMagicSparkles />
                  <span className="ms-1">{t('Shared.Texts.LetsDoThis')}</span>{' '}
                </>
              )}
            </button>
          </div>
        </>
      ),
    }
  ];

  async function onPaymentMethodChange(key: string) {
    if (key == "stripe") {
      // Nothing
    }
  }

  return (
    <ModalBase
      modalName={EModals.PAYMENT}
      onCloseModal={() => {
        if (loading) return;
        dispatch(popModal());
        setCurrentPriceInfo(null);
        setTranscribePrice(null);
      }}
    >
      <div className="ctn-payment-modal">
        <h4 className="text-center mb-3" style={{ marginTop: 5 }}>
          {t('Pages.Main.PaymentModal.Title')}
        </h4>
        {/* transcribe payment modal */}
        <div className="transcribe-option-wrapper mb-3">
          <Radio.Group
            size="large"
            buttonStyle="solid"
            onChange={onChangeOptionTranscribe}
            value={includeTranslate}
          >
            <Radio value={false} style={{ fontFamily: 'GothamBook' }}>
              {t('Shared.Actions.Transcribe')}
            </Radio>
            <Radio value={true} style={{ fontFamily: 'GothamBook' }}>
              {t('Shared.Actions.TranscribeThenTranslate')}
            </Radio>
          </Radio.Group>
        </div>

        <div className="mb-2">
          <div className="fw-bold">{t('Pages.Main.PaymentModal.TranscriptionLanguage')}:</div>
          <Select
            virtual={false}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
            }
            value={langLabelSelected}
            getPopupContainer={(trigger) => trigger.parentNode}
            className="language-select"
            placeholder={
              <span>{t('Pages.Main.PaymentModal.TranscriptionLanguagePlaceholder')}</span>
            }
            style={{ width: '100%', maxWidth: '100%' }}
            onChange={onChangeSelectLang}
            options={[
              {
                label: t('Shared.Texts.LastUsed'),
                options: listLastUsed.map((language, index) => ({
                  key: `${language.code}-${language.id}-${index}-${language.regionName}`,
                  label: language.regionName,
                  value: JSON.stringify({
                    code: language.code,
                    defaultSpeechApi: language.defaultSpeechApi,
                    langCode: language.code,
                    label: language.regionName
                  })
                }))
              },
              {
                label: t('Shared.Texts.FrequentlyUsed'),
                options: listFrequentlyUsed
                  .sort((lang1, lang2) => (lang1.regionName > lang2.regionName ? 1 : -1))
                  .map((language, index) => ({
                    key: `${language.code}-${language.id}-${index}-${language.regionName}`,
                    label: language.regionName,
                    value: JSON.stringify({
                      code: language.code,
                      defaultSpeechApi: language.defaultSpeechApi,
                      langCode: language.code,
                      label: language.regionName
                    })
                  }))
              },
              {
                label: t('Shared.Texts.Others'),
                options: listLanguagesOther
                  .sort((lang1, lang2) => (lang1.regionName > lang2.regionName ? 1 : -1))
                  .map((language, index) => ({
                    key: `${language.code}-${language.id}-${index}-${language.regionName}`,
                    label: language.regionName,
                    value: JSON.stringify({
                      code: language.code,
                      defaultSpeechApi: language.defaultSpeechApi,
                      langCode: language.code,
                      label: language.regionName
                    })
                  }))
              }
            ]}
          />
        </div>

        {/* <small>
          Looking to{' '}
          <Link to="https://www.simonsaysai.com/help/2657992-how-to-translate-your-project">
            Translate?
          </Link>{' '}
          First transcribe.
        </small> */}

        {includeTranslate && (
          <>
            <div className="mb-1">
              <div className="fw-bold">{t('Pages.Main.PaymentModal.TranslationLanguages')}:</div>
              <Select
                virtual={false}
                value={listLangLabelSelected}
                maxCount={EMaxLength.LANGUAGES}
                mode="multiple"
                getPopupContainer={(trigger) => trigger.parentNode}
                className="language-select"
                style={{ width: '100%', maxWidth: '100%' }}
                placeholder={
                  <span>{t('Pages.Main.PaymentModal.TranslationLanguagesPlaceholder')}</span>
                }
                onChange={onChangeSelectLangTranslate}
                options={[
                  {
                    label: t('Shared.Texts.LastUsed'),
                    options: listLanguagesUsed.map((language) => ({
                      key: language.id,
                      label: language.label,
                      value: JSON.stringify({ label: language.label, id: language.id })
                    }))
                  },
                  {
                    label: t('Shared.Texts.Others'),
                    options: listLanguagesAvailable
                      .sort((lang1, lang2) => (lang1.label > lang2.label ? 1 : -1))
                      .map((language) => ({
                        key: language.id,
                        label: language.label,
                        value: JSON.stringify({ label: language.label, id: language.id })
                      }))
                  }
                ]}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              {/* <button onClick={handleCheckAll} style={{ color: 'var(--si-primary)' }}>
                {t('Shared.Actions.CheckAll')}
              </button> */}
              <button
                className={`${listLangIDSelected.length > 0 ? 'visible' : 'invisible'}`}
                onClick={handleUnCheckAll}
                style={{ color: 'var(--si-primary)' }}
                disabled={listLangIDSelected.length === 0}
              >
                {t('Shared.Actions.UncheckAll')}
              </button>
              {listLangIDSelected.length}/{EMaxLength.LANGUAGES}
            </div>
          </>
        )}

        <div className="mt-2 mb-1">
          <div className="fw-bold d-flex align-items-center gap-1">
            {t('Pages.Main.PaymentModal.CustomDictionary')}:
            <FaInfoCircle size={14} color="var(--si-primary)" />
          </div>

          <Select
            virtual={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            className="dictionary-select"
            placeholder={<span>{t('Pages.Main.PaymentModal.SelectDictionary')}</span>}
            suffixIcon
            onChange={onChangeSelectDictionary}
            disabled={langLabelSelected === null}
            style={{ width: '100%', maxWidth: '100%' }}
            options={[
              {
                options: listDictionary.map((dic) => ({
                  key: `${dic.id}`,
                  label: dic.name,
                  value: dic.id
                }))
              },
              {
                options: [1].map(() => ({
                  key: `${'new dictionary'}`,
                  label: (
                    <span
                      style={{ color: 'var(--si-primary)' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setDictionaryScreen({ isDictionaryScreen: true }));
                        navigate(ERoutes.MAIN);
                        dispatch(popModal());
                      }}
                    >
                      <span className="align-middle">
                        {t('Pages.Main.PaymentModal.CreateYourDictionary')}
                      </span>
                      <FaExternalLinkSquareAlt color="var(--si-primary)" className="ms-1" />
                    </span>
                  )
                }))
              }
            ]}
          />
        </div>
        <small>{t('Pages.Main.PaymentModal.DictionaryTip')}</small>

        <div className="ctn-cost rounded border d-flex align-items-center justify-content-center">
          <div className="text-center" style={{ flex: 1 }}>
            <span
              style={{
                color: 'var(--si-old-silver)',
                fontWeight: 500,
                fontFamily: 'GothamMedium',
                fontSize: 22
              }}
            >
              {t('Shared.Fields.TotalCost')}
            </span>
            <div style={{ fontWeight: 700 }}>
              <span style={{ fontSize: 22, fontFamily: 'GothamBook' }}>
                {changeNumberToCurrency(currentPriceInfo?.totalPrice || 0).prevPriod}.
              </span>
              <span style={{ fontSize: 13, fontFamily: 'GothamBook' }}>
                {changeNumberToCurrency(currentPriceInfo?.totalPrice || 0).afterPriod}
              </span>{' '}
              <span style={{ fontSize: 14, fontFamily: 'GothamBook', fontWeight: 400 }}>USD</span>
            </div>
          </div>
          <div className="border-start ps-3 ms-3" style={{ fontSize: 10, flex: 1 }}>
            <div style={{ color: 'var(--si-old-silver)' }}>
              <small>{t('Pages.Main.PaymentModal.TotalDurationToTranscribe')}:</small>
            </div>
            <div>
              {<TimeDisplay hours={durationToTime.hour} minutes={durationToTime.minutes} />}
            </div>
            {includeTranslate && (
              <>
                <div style={{ color: 'var(--si-old-silver)' }}>
                  <small>{t('Pages.Main.PaymentModal.TotalDurationToTranslate')}</small>
                </div>
                <div>
                  {listLangLabelSelected.length > 0 ? (
                    <TimeDisplay
                      hours={
                        convertDurationToHourMinute(
                          currentPriceInfo?.totalDuration
                        ).hour || 0
                      }
                      minutes={
                        convertDurationToHourMinute(
                          currentPriceInfo?.totalDuration
                        ).minutes || 0
                      }
                    />
                  ) : (
                    <span className="fw-bolder">
                      {t('Pages.Main.PaymentModal.SelectLanguageToSeeCost')}
                    </span>
                  )}
                </div>
              </>
            )}
            {currentPriceInfo && currentPriceInfo.credit > 0 && (
              <>
                <div style={{ color: 'var(--si-old-silver)' }}>
                  <small>{t('Shared.Fields.CreditAvailable')}:</small>
                </div>
                <div>
                  <TimeDisplay hours={creditToTime.hour} minutes={creditToTime.minutes} />
                </div>
              </>
            )}

            {currentPriceInfo && currentPriceInfo.credit > 0 && (
              <>
                <div style={{ color: 'var(--si-old-silver)' }}>
                  <small>{t('Shared.Fields.ChargeableTime')}:</small>
                </div>
                <div>
                  <TimeDisplay hours={changeAbleTime.hour} minutes={changeAbleTime.minutes} />
                </div>
              </>
            )}
            {currentPriceInfo && currentPriceInfo.chargeableTimeInSeconds > 0 && (
              <>
                <div style={{ color: 'var(--si-old-silver)' }}>
                  <small>{t('Shared.Fields.HourlyTranscriptionRate')}:</small>
                </div>
                <span className="fw-bolder">
                  ${currentPriceInfo?.rate}/{t('Shared.Fields.hour', { count: 0 })}
                </span>
              </>
            )}
            {currentPriceInfo &&
              currentPriceInfo.chargeableTimeInSeconds > 0 &&
              currentPriceInfo.chargeableTimeInSeconds <
                currentPriceInfo.minimumChargeableTimeInSeconds && (
                <>
                  <div style={{ color: 'var(--si-old-silver)' }}>
                    <small>{t('Shared.Fields.MinimumCost')}:</small>
                  </div>
                  <div>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD'
                    }).format(currentPriceInfo ? currentPriceInfo.minimumPrice : 0)}
                  </div>
                </>
              )}
              <div className='policy-text'>
                <p className='mb-0 mt-2'>{ t('Pages.Main.PaymentModal.NoRefundPolicy') }</p>
                <Link
                  to="https://www.simonsaysai.com/help/2657979-can-i-read-your-privacy-policy-terms-of-service"
                  target="_blank"
                  rel="noopener"
                  className="custom-a-tag"
                >
                  {t("Shared.Texts.TermsAndConditions")}
                </Link>
              </div>
          </div>
        </div>
        { currentPriceInfo && currentPriceInfo.totalPrice > 0 && 
          <Tabs
            defaultActiveKey="stripe"
            items={paymentMethods}
            onChange={onPaymentMethodChange}
            style={{ fontFamily: "GothamBook" }}
          />
        }
        { !(currentPriceInfo && currentPriceInfo.totalPrice > 0) && (
          <div className="text-center">
            <button
              className="submit-button px-2 py-1"
              style={{ borderRadius: 98 }}
              onClick={transcribeData}
              disabled={loading}
            >
              {loading ? (
                <Loader />
              ) : (
                <>
                  <FaWandMagicSparkles />
                  <span className="ms-1">{t('Shared.Texts.LetsDoThis')}</span>{' '}
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </ModalBase>
  );
};
