import { Popover, Select, Tabs, TabsProps, Tag } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { CreditCard, ModalBase, TimeDisplay } from 'components/common';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { popModal, pushModal } from 'redux/modal/modal.slice';
import { EMaxLength, EModals, EPlanOption, ERoutes, ETypeNoti } from 'ts/enums';
import { TChangeTime, TLanguage } from 'ts/types';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import {
  convertCreditToHourMinute,
  convertDurationToHourMinute,
  pushStripeTransactionTracking
} from 'utils/common';
import { getPriceTranslate, translateProject } from 'apis/projects.api';
import { TResponseTranslatePrice } from 'ts/types/TResponse';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getProjectByIdAction } from 'redux/projects/projects.action';
import { Loader } from 'components/common/Loader';
import { useCreditCard } from 'context/CreditCardContext';
import { changeNumberToCurrency } from 'utils/price';
import { useTranslation } from 'react-i18next';
import { stripeCreateTranslateOrder } from 'apis/serverless.api';

export const TranslateModal = () => {
  const params = useParams();
  const currentProject = useAppSelector((state) => state.projects.currentProject);
  const projectId = params.projectId || currentProject.data?.id;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const listAllLanguages = useAppSelector((state) => state.languages.listAllLanguages);
  const [listLanguagesAvailable, setListLanguagesAvailable] = useState<TLanguage[]>([]);
  const [listLanguagesUsed, setListLanguagesUsed] = useState<TLanguage[]>([]);

  const [listLangLabelSelected, setListLangLabelSelected] = useState<Array<any>>([]);
  const [listLangIDSelected, setListLangIDSelected] = useState<Array<any>>([]);
  const lastUsedTranslationLanguage = useAppSelector(
    (state) => state.auth.currentUser.data?.lastUsedTranslationLanguage || ''
  );
  const [currentPriceInfo, setCurrentPriceInfo] = useState<TResponseTranslatePrice | null>(null);
  const [nameMedia, setNameMedia] = useState<string>('');
  const [timeMedia, setTimeMedia] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const currentUser = useAppSelector((state) => state.auth.currentUser.data);
  const { t } = useTranslation();

  const {
    cardData,
    cardName,
    cvcData,
    expiryMonthData,
    expiryYearData,
    isUpdateCardSaved,
    isExistedCard,
    triggerShowError,
    isReadyCard
  } = useCreditCard({
    currentUser
  });
  const [changeAbleTime, setChangeAbleTime] = useState<TChangeTime>({ hour: 0, minutes: 0 });

  useEffect(() => {
    if (listAllLanguages.data && listAllLanguages.data?.length > 0) {
      const tempAvailable = listAllLanguages.data.filter((lang) =>
        lastUsedTranslationLanguage
          ? !JSON.parse(lastUsedTranslationLanguage).includes(lang.id)
          : lang
      );
      const tempUsed = listAllLanguages.data.filter((lang) =>
        lastUsedTranslationLanguage
          ? JSON.parse(lastUsedTranslationLanguage).includes(lang.id)
          : false
      );
      setListLanguagesUsed(tempUsed);
      setListLanguagesAvailable(tempAvailable);
    }
  }, [listAllLanguages, lastUsedTranslationLanguage]);

  useEffect(() => {
    if (projectId) {
      (async () => {
        setIsDisable(true);
        setLoading(true);
        const res = await getPriceTranslate({
          projectId: projectId,
          languageCount: listLangLabelSelected.length
        });
        setCurrentPriceInfo(res);
        setChangeAbleTime(convertDurationToHourMinute(res.chargeableTimeInSeconds));

        const mediaDetail = JSON.parse(res.mediaDetails);
        if (mediaDetail.length > 0) {
          setNameMedia(mediaDetail[0].name);
          setTimeMedia(mediaDetail[0].duration);
        }
        setIsDisable(false);
        setLoading(false);
      })();
    }
  }, [listLangLabelSelected, projectId]);

  function onChangeSelectLang(e: Array<any>) {
    const listValue = e.map((item) => JSON.parse(item));
    setListLangLabelSelected(e);
    const listId = listValue.map((lang) => lang.id);
    setListLangIDSelected(listId);
    setIsDisable(false);
  }

  function handleCheckAll() {
    if (listAllLanguages.data) {
      const allLang = listAllLanguages.data.map((lang) =>
        JSON.stringify({ label: lang.label, id: lang.id })
      );
      if (allLang) {
        setListLangLabelSelected(allLang);
      }
      const listId = listAllLanguages.data.map((lang) => lang.id);
      setListLangIDSelected(listId);
    }
    setIsDisable(false);
  }

  function handleUnCheckAll() {
    setListLangLabelSelected([]);
    setListLangIDSelected([]);
    setIsDisable(true);
  }

  async function translateProjectWithCreditCard({ projectId }: { projectId: string }) {
    return await translateProject({
      projectId,
      paymentDetails: {
        card: {
          cardHolderName: cardName.data,
          cardNumber: cardData?.cardNumber,
          cvv: cvcData.data,
          expirationMonth: expiryMonthData.data,
          expirationYear: expiryYearData.data
        },
        shouldSaveCard: isUpdateCardSaved
      },
      targetLanguageIds: listLangIDSelected
    });
  }

  async function translateProjectWithoutCreditCard({ projectId }: { projectId: string }) {
    return await translateProject({
      projectId,
      paymentDetails: { card: {}, shouldSaveCard: false, useSavedCard: true },
      targetLanguageIds: listLangIDSelected
    });
  }

  async function handleTranslateProject() {
    if (isDisable) return;

    if (!isReadyCard && !isExistedCard && currentPriceInfo && currentPriceInfo.totalPrice > 0) {
      triggerShowError();
      return;
    }

    if (!listLangIDSelected || listLangIDSelected.length === 0) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: { type: ETypeNoti.WARNING, title: t('Shared.Texts.SelectTranslationLanguage') }
        })
      );
      return;
    }

    setIsDisable(true);
    setLoading(true);
    searchParams.delete('mediaId');
    setSearchParams(searchParams);

    if (currentProject.data && currentPriceInfo) {
      const res =
        currentPriceInfo.totalPrice > 0
          ? !isExistedCard
            ? await translateProjectWithCreditCard({ projectId: currentProject.data.id })
            : await translateProjectWithoutCreditCard({ projectId: currentProject.data.id })
          : await translateProjectWithoutCreditCard({ projectId: currentProject.data.id });

      if (res && 'message' in res) {
        dispatch(
          pushModal({
            name: EModals.NOTI_MODAL,
            data: null,
            notiData: { type: ETypeNoti.ERROR, title: `${res.message}` }
          })
        );
        setLoading(false);
        setIsDisable(false);
        return;
      }

      if (res && res.stripeTransactionDetail) {
        if (res.stripeTransactionDetail) pushStripeTransactionTracking(res.stripeTransactionDetail);
      }

      if (res && res.projectId) {
        searchParams.delete('mediaId');
        setSearchParams(searchParams);
        dispatch(getProjectByIdAction({ projectId: res.projectId })).then(() => {
          navigate(`${ERoutes.TRANSCRIPT_DETAIL}/${res.projectId}`);
        });
        dispatch(popModal());
      }
    }
    setLoading(false);
    handleUnCheckAll();
  }

  const creditToTime = (currentPriceInfo && convertCreditToHourMinute(currentPriceInfo.credit)) || {
    hour: 0,
    minutes: 0
  };
  const durationToTime = (currentPriceInfo &&
    convertDurationToHourMinute(currentPriceInfo.totalDuration)) || {
    hour: 0,
    minutes: 0
  };

  const mediaToTime = convertDurationToHourMinute(timeMedia) || {
    hour: 0,
    minutes: 0
  };

  const handleStripeCheckout = () => {
    if (isDisable) return;
    if (!currentUser || !currentProject || !currentProject.data || !currentPriceInfo || currentPriceInfo.totalPrice <= 0)
      return;

    if (!listLangIDSelected || listLangIDSelected.length === 0) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: { type: ETypeNoti.WARNING, title: t('Shared.Texts.SelectTranslationLanguage') }
        })
      );
      return;
    }

    setIsDisable(true);
    setLoading(true);

    stripeCreateTranslateOrder(
      currentProject?.data?.id,
      currentUser?.email,
      currentUser?.id,
      listLangIDSelected || []
    ).then((data) => {
      window.location.href = data.url;
    }).catch((err) => {
      console.error(err);
      setLoading(false);
      setIsDisable(false);
    });
  };

  const paymentMethods: TabsProps["items"] = [
    {
      key: "stripe",
      label: (
        <>
          {t("Shared.PaymentMethods.Stripe")}{" "}
          {/* <Badge size="small" count={"BETA"} className="mb-2" /> */ }
        </>
      ),
      children: (
        <>
          <div className={`${!loading ? "d-none" : "mt-2"}`}>
            <Loader />
          </div>
          {!loading && (
            <div className="text-center mt-4">
              <button
                className="submit-button px-2 py-1"
                onClick={handleStripeCheckout}
                disabled={loading}
              >
                <FaWandMagicSparkles />
                <span className="ms-1">{t('Shared.Texts.LetsDoThis')}</span>{' '}
              </button>
            </div>
          )}
        </>
      ),
    },
    {
      key: "creditcard",
      label: t("Shared.PaymentMethods.CreditCard"),
      children: (
        <>
          <div className="mb-1">
            <CreditCard/>
          </div>
          <div className="text-center mt-4">
            <button
              className="submit-button px-2 py-1"
              style={{ borderRadius: 98 }}
              onClick={handleTranslateProject}
              disabled={isDisable}
            >
              {loading ? (
                <Loader />
              ) : (
                <>
                  <FaWandMagicSparkles />
                  <span className="ms-1">{t('Shared.Texts.LetsDoThis')}</span>{' '}
                </>
              )}
            </button>
          </div>
        </>
      ),
    }
  ];

  async function onPaymentMethodChange(key: string) {
    if (key == "stripe") {
      // Nothing
    }
  }

  return (
    <ModalBase
      modalName={EModals.TRANSLATE_MODAL}
      onCloseModal={() => {
        dispatch(popModal());
      }}
    >
      <div className="ctn-translate-modal">
        <h4 className="text-center">{t('Pages.TranscriptDetail.TranslateModal.Title')}</h4>
        <div className="mt-5">
          <div className="fw-bold">{t('Shared.Fields.LanguageS')}</div>
          <Select
            virtual={false}
            value={listLangLabelSelected}
            mode="multiple"
            maxCount={EMaxLength.LANGUAGES}
            getPopupContainer={(trigger) => trigger.parentNode}
            className="language-select"
            style={{ width: '100%' }}
            placeholder={<span>{t('Shared.Texts.SelectTranslationLanguage')}</span>}
            onChange={onChangeSelectLang}
            suffixIcon={null}
            options={[
              listLanguagesUsed.length > 0
                ? {
                    label: t('Shared.Texts.LastUsed'),
                    options: listLanguagesUsed.map((language) => ({
                      key: language.id,
                      label: language.label,
                      value: JSON.stringify({ label: language.label, id: language.id })
                    }))
                  }
                : {},
              {
                label: t('Shared.Texts.Others'),
                options: listLanguagesAvailable
                  .sort((lang1, lang2) => (lang1.label > lang2.label ? 1 : -1))
                  .map((language) => ({
                    key: language.id,
                    label: language.label,
                    value: JSON.stringify({ label: language.label, id: language.id })
                  }))
              }
            ]}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-1 mb-2">
          {/* <button onClick={handleCheckAll} style={{ color: 'var(--si-primary)' }}>
            {t('Shared.Actions.CheckAll')}
          </button> */}
          <button
            className={`${listLangIDSelected.length > 0 ? 'visible' : 'invisible'}`}
            onClick={handleUnCheckAll}
            style={{ color: 'var(--si-primary)' }}
            disabled={listLangIDSelected.length === 0}
          >
            {t('Shared.Actions.UncheckAll')}
          </button>
          {listLangIDSelected.length}/{EMaxLength.LANGUAGES}
        </div>
        <div
          className="ctn-cost rounded border row p-2 mx-0"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <div className="d-flex flex-column align-items-center justify-content-center col-6">
            <h4 style={{ color: 'var(--si-old-silver)' }}>{t('Shared.Fields.TotalCost')}</h4>
            <div>
              <span style={{ fontSize: 22, fontWeight: 700 }}>
                {changeNumberToCurrency(currentPriceInfo?.totalPrice || 0).prevPriod}
              </span>
              .{changeNumberToCurrency(currentPriceInfo?.totalPrice || 0).afterPriod} USD
            </div>
          </div>
          <div className="border-start col-6" style={{ fontSize: 10, paddingLeft: 0 }}>
            <div className="ps-5">
              <div style={{ color: 'var(--si-old-silver)', lineHeight: '12px' }}>
                <span>{t('Pages.TranscriptDetail.TranslateModal.TotalDurationToTranslate')}</span>
              </div>
              <div>
                <TimeDisplay
                  hours={durationToTime.hour}
                  minutes={durationToTime.minutes}
                  isVisibleInfo={true}
                  nameMedia={nameMedia}
                  minuteMedia={mediaToTime.minutes}
                  hoursMedia={mediaToTime.hour}
                />
              </div>
              {currentPriceInfo && currentPriceInfo.credit > 0 && (
                <>
                  <div style={{ color: 'var(--si-old-silver)' }}>
                    <small>{t('Shared.Fields.CreditAvailable')}:</small>
                  </div>
                  <div>
                    <TimeDisplay hours={creditToTime.hour} minutes={creditToTime.minutes} />
                  </div>
                </>
              )}

              {currentPriceInfo && currentPriceInfo.credit > 0 && (
                <>
                  <div style={{ color: 'var(--si-old-silver)' }}>
                    <small>{t('Shared.Fields.ChargeableTime')}:</small>
                  </div>
                  <div>
                    <TimeDisplay hours={changeAbleTime.hour} minutes={changeAbleTime.minutes} />
                  </div>
                </>
              )}
              {currentPriceInfo && currentPriceInfo.chargeableTimeInSeconds > 0 && (
                <>
                  <div style={{ color: 'var(--si-old-silver)' }}>
                    <small>{t('Shared.Fields.HourlyTranscriptionRate')}:</small>
                  </div>
                  <span className="fw-bolder">
                    ${currentPriceInfo?.rate}/{t('Shared.Fields.hour', { count: 0 })}
                  </span>
                </>
              )}
              {currentPriceInfo &&
                currentPriceInfo.chargeableTimeInSeconds > 0 &&
                currentPriceInfo.chargeableTimeInSeconds <
                  currentPriceInfo.minimumChargeableTimeInSeconds && (
                  <>
                    <div style={{ color: 'var(--si-old-silver)' }}>
                      <small>{t('Shared.Fields.MinimumCost')}:</small>
                    </div>
                    <div className="fw-bolder">
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                      }).format(currentPriceInfo ? currentPriceInfo.minimumPrice : 0)}
                    </div>
                  </>
                )}
                <div className='policy-text'>
                <p className='mb-0 mt-2'>{ t('Pages.Main.PaymentModal.NoRefundPolicy') }</p>
                <Link
                  to="https://www.simonsaysai.com/help/2657979-can-i-read-your-privacy-policy-terms-of-service"
                  target="_blank"
                  rel="noopener"
                  className="custom-a-tag"
                >
                  {t("Shared.Texts.TermsAndConditions")}
                </Link>
              </div>
            </div>
          </div>
        </div>
        {currentPriceInfo && currentPriceInfo.totalPrice > 0 && (
          <Tabs
            defaultActiveKey="stripe"
            items={paymentMethods}
            onChange={onPaymentMethodChange}
            style={{ fontFamily: "GothamBook" }}
          />
        )}
        { !(currentPriceInfo && currentPriceInfo.totalPrice > 0) && (
          <div className="text-center mt-4">
            <button
              className="submit-button px-2 py-1"
              style={{ borderRadius: 98 }}
              onClick={handleTranslateProject}
              disabled={isDisable}
            >
              {loading ? (
                <Loader />
              ) : (
                <>
                  <FaWandMagicSparkles />
                  <span className="ms-1">{t('Shared.Texts.LetsDoThis')}</span>{' '}
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </ModalBase>
  );
};
