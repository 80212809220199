import { useTranslation } from "react-i18next";

export function EmptyStateProjectDefault() {
  const { t } = useTranslation();
  
  return (
    <div
      className="d-flex justify-content-center align-items-center h-100"
      style={{ color: 'var(--bs-gray-400)' }}
    >
      { t('Shared.Texts.NoProject') }
    </div>
  );
}
