/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useState } from 'react';

interface AutoScrollContextType {
  isAutoScroll: boolean;
  setAutoScroll: (e: boolean) => void;
  idBlockPlaying: string | null;
  idTranscriptRowPlaying: number | null;
  // eslint-disable-next-line no-unused-vars
  setIdBlockPlaying: (id: string) => void;
  setIdTranscriptRowPlaying: (id: number) => void;
}

const AutoScrollContext = createContext<AutoScrollContextType>({
  isAutoScroll: false,
  setAutoScroll: (e: boolean) => e,
  idBlockPlaying: null,
  setIdBlockPlaying: (id: string) => id,
  idTranscriptRowPlaying: null,
  setIdTranscriptRowPlaying: (id: number) => id
});

export function AutoScrollProvider({ children }: { children: React.ReactNode }) {
  const [isAutoScroll, setAutoScroll] = useState<boolean>(true);
  const [idBlockPlaying, setIdBlockPlaying] = useState<string | null>(null);
  const [idTranscriptRowPlaying, setIdTranscriptRowPlaying] = useState<number | null>(null);
  const value = {
    isAutoScroll,
    setAutoScroll: (value: boolean) => {
      setAutoScroll(value);
    },
    idBlockPlaying,
    setIdBlockPlaying: (value: string) => setIdBlockPlaying(value),
    idTranscriptRowPlaying,
    setIdTranscriptRowPlaying: (value: number) => setIdTranscriptRowPlaying(value)
  };

  return <AutoScrollContext.Provider value={value}>{children}</AutoScrollContext.Provider>;
}

export function useAutoScroll() {
  return useContext(AutoScrollContext);
}
