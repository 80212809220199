import {
  getTimeHistoryTranscript,
  getTranscriptWithVersion,
  revertTranscriptWithVersion
} from 'apis/transcript.api';
import { ModalBase } from 'components/common';
import { Loader } from 'components/common/Loader';
import { usePageLoading } from 'context/PageLoadingContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { popModal } from 'redux/modal/modal.slice';
import { EModals, EStyle } from 'ts/enums';
import { TTranscripHistory, TTranscript, TWord } from 'ts/types';
import { convertTimeTranscrip } from 'utils/time';

export function VersionHistoryModal() {
  const dispatch = useAppDispatch();
  const currentMedia = useAppSelector((state) => state.media.currentMedia);
  const dataMedia = useAppSelector((state) => state.modal.lastModal?.data);
  const [transcriptTime, setTranscripTime] = useState<TTranscripHistory[]>([]);
  const [chooseTranscrip, setChooseTranscrip] = useState<TTranscripHistory | null>();
  const lastTranscript = useAppSelector((state) => state.transcription.lastTranscript);
  const [transcripHistory, setTrancsripHistory] = useState<TTranscript[] | null>(null);
  const [transcripActive, setTranscripDisplay] = useState<TTranscript[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [disabled, setDisable] = useState<boolean>(true);
  const { t } = useTranslation();
  const { openMessage, destroyMessage } = usePageLoading();

  function convertStyle({ style }: { style: EStyle }) {
    switch (style) {
      case EStyle.BOLD:
        return 'fw-bold';
      case EStyle.ITALIC:
        return 'fst-italic';
      case EStyle.UNDERLINE:
        return 'td-underline';
      default:
        return null;
    }
  }

  function convertWordJsonToBlockDisplay({ wordJson }: { wordJson: string }) {
    if (!wordJson) return null;

    const parseJson = JSON.parse(wordJson);

    if (parseJson.length == 0) return null;

    return parseJson.map((json: TWord, index: number) => (
      <span
        key={`${new Date().getTime() + index}`}
        className={
          json.style &&
          json.style
            .map((st) => convertStyle({ style: st }))
            .toString()
            .replace(/\,/g, ' ')
        }
      >
        {json.text}
      </span>
    ));
  }

  useEffect(() => {
    if (dataMedia && dataMedia.mediaId) {
      setTrancsripHistory(null);
      setLoading(true);
      setTranscripDisplay(null);
      (async () => {
        const res = await getTimeHistoryTranscript({ mediaId: dataMedia.mediaId });
        if (res && res.length > 0) {
          setTranscripTime(res);
          setChooseTranscrip(res[0]);
          setDisable(false);
        }
        setLoading(false);
        setTranscripDisplay(lastTranscript);
      })();
    }
  }, [dataMedia]);

  useEffect(() => {
    if (dataMedia && dataMedia.mediaId && chooseTranscrip) {
      setTrancsripHistory(null);
      setLoading(true);
      setTranscripDisplay(null);
      (async () => {
        const res = await getTranscriptWithVersion({
          mediaId: dataMedia.mediaId,
          version: chooseTranscrip?.version
        });
        if (res) {
          setTrancsripHistory(res);
        }
        setLoading(false);
        setTranscripDisplay(lastTranscript);
      })();
    }
  }, [chooseTranscrip, dataMedia]);

  useEffect(() => {
    setTranscripDisplay(lastTranscript);
  }, [lastTranscript]);

  function revertTranscript() {
    if (disabled) return;
    destroyMessage();
    setDisable(true);
    if (
      dataMedia &&
      dataMedia.mediaId &&
      transcripHistory &&
      transcripHistory.length > 0 &&
      chooseTranscrip
    ) {
      openMessage('loading', 'Reverting...');
      (async () => {
        await revertTranscriptWithVersion({
          mediaId: dataMedia.mediaId,
          version: chooseTranscrip?.version,
          transcript: transcripHistory
        }).then(() => {
          location.reload();
        });
      })();
    }
  }

  if (!currentMedia) return null;

  return (
    <ModalBase
      modalName={EModals.VERSION_HISTORY_MODAL}
      onCloseModal={() => dispatch(popModal())}
      className="version-history-modal"
      isCloseIcon={false}
    >
      <div className="content">
        <div className="row gx-0">
          <div className="col-12 col-md-2 py-1 px-2">
            <div className="d-flex flex-column h-100">
              <div className="info">
                <strong className="version-text">
                  {t('Pages.TranscriptDetail.VersionHistoryModal.Title')}
                </strong>
                <div className="truncate-1">{currentMedia.name}</div>
                <div className="timestamp mt-1 mb-1">
                  {transcriptTime.map((time) => (
                    <div
                      className={`create-time p-1 cursor-pointer ${
                        chooseTranscrip?.version === time.version ? 'fw-bold active' : ''
                      }`}
                      key={time.creationDate}
                      onClick={() => {
                        setChooseTranscrip(time);
                      }}
                    >
                      {convertTimeTranscrip(time.creationDate)}
                    </div>
                  ))}
                </div>
              </div>
              <div className="qa d-none d-md-block mt-auto">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="question"
                  href="https://www.simonsaysai.com/help/4468305-version-history-restore-previous-versions-of-a-transcript"
                >
                  {t('Pages.TranscriptDetail.VersionHistoryModal.Intro')}
                </a>
                <div>{t('Pages.TranscriptDetail.VersionHistoryModal.Description')}</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 border-left">
            <div className="transcript-restore">
              <div className="header p-1 d-flex align-items-center justify-content-between">
                <div className="time">
                  {chooseTranscrip?.creationDate &&
                    `Transcript saved ${convertTimeTranscrip(chooseTranscrip?.creationDate || 0)}`}
                </div>
                <button
                  className="btn-si-primary --fill rounded restore-button px-1"
                  disabled={chooseTranscrip ? chooseTranscrip.version === 0 : disabled}
                  onClick={revertTranscript}
                >
                  {t('Pages.TranscriptDetail.VersionHistoryModal.RestoreThisVersion')}
                </button>
              </div>
              <div className="body px-2 py-1">
                {loading ? (
                  <Loader />
                ) : transcripHistory && transcripHistory.length > 0 ? (
                  transcripHistory.map((transcript) => (
                    <div key={transcript.id} className="mt-1">
                      {convertWordJsonToBlockDisplay({ wordJson: transcript.wordsJson })}
                    </div>
                  ))
                ) : (
                  <div className="text-center fw-bold">
                    {t('Pages.TranscriptDetail.VersionHistoryModal.NoVersionToRestore')}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 border-left">
            <div className="transcript-active">
              <div className="header p-1 d-flex align-items-center justify-content-between">
                <div className="text">
                  {t('Pages.TranscriptDetail.VersionHistoryModal.ActiveTranscript')}
                </div>
              </div>
              <div className="body px-2 py-1">
                {loading ? (
                  <Loader />
                ) : transcripActive && transcripActive.length > 0 ? (
                  transcripActive.map((transcript) => (
                    <div key={transcript.id} className="mt-1">
                      {convertWordJsonToBlockDisplay({ wordJson: transcript.wordsJson })}
                    </div>
                  ))
                ) : (
                  <div className="text-center fw-bold">
                    {t('Pages.TranscriptDetail.VersionHistoryModal.NoVersionToRestore')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="close-modal p-1 mt-2 text-center">
        <button className="cancel-button px-1" onClick={() => dispatch(popModal())}>
          {t('Shared.Actions.Cancel')}
        </button>
      </div>
    </ModalBase>
  );
}
