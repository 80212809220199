import React from 'react';
import { createInlineStyleButton } from '@draft-js-plugins/buttons';
import { EStyle } from 'ts/enums';

const styleDefault = createInlineStyleButton({
  style: EStyle.STRIKETHROUGH,
  children: (
    <svg
      width="14"
      height="14"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="strikethrough"
      className="svg-inline--fa fa-strikethrough fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M496 224H293.9l-87.17-26.83A43.55 43.55 0 0 1 219.55 112h66.79A49.89 49.89 0 0 1 331 139.58a16 16 0 0 0 21.46 7.15l42.94-21.47a16 16 0 0 0 7.16-21.46l-.53-1A128 128 0 0 0 287.51 32h-68a123.68 123.68 0 0 0-123 135.64c2 20.89 10.1 39.83 21.78 56.36H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h480a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-180.24 96A43 43 0 0 1 336 356.45 43.59 43.59 0 0 1 292.45 400h-66.79A49.89 49.89 0 0 1 181 372.42a16 16 0 0 0-21.46-7.15l-42.94 21.47a16 16 0 0 0-7.16 21.46l.53 1A128 128 0 0 0 224.49 480h68a123.68 123.68 0 0 0 123-135.64 114.25 114.25 0 0 0-5.34-24.36z"
      />
    </svg>
  )
});

export default function (active: boolean) {
  // if(active) return styleActive
  return styleDefault;
}
