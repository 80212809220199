import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Modal, Tooltip } from 'antd';
import {
  EAccountSettingId,
  EChangePlanOption,
  EDelayTime,
  EModals,
  EPlanOption,
  EPlanType,
  ETypeSubcribeModal
} from 'ts/enums';
import { BasicSection } from './BasicSection';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { pushModal, setChangePlanModalDefaultOption } from 'redux/modal/modal.slice';
import UpdateAvatarModal from './UpdateAvatarModal';
import ChangeEmailModal from './ChangeEmailModal';
import { getDefaultPic, updateAvatar, updateFullName } from 'apis/userSetting.api';
import { updateCurrentUser } from 'redux/auth/auth.slice';
import moment from 'moment';
import { getMySubscription, undoDeletePlan } from 'apis/subscribe.api';
import { TSubscription } from 'ts/types';
import { usePageLoading } from 'context/PageLoadingContext';
import { AiOutlineClose, AiOutlineWarning } from 'react-icons/ai';
import { useTranslation, Trans } from 'react-i18next';
import { fetchSubscriptionAction } from 'redux/auth/auth.action';
import { useLoaderContext } from 'context/LoaderContext';

export function InformationSection() {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const modalState = useAppSelector((state) => state.modal.modalStack);
  const dispatch = useAppDispatch();
  const [currentMySubscription, setCurrenMySubscription] = useState<TSubscription | null>(null);
  const { openMessage, destroyMessage } = usePageLoading();
  const [isOpenUndoPlanConfirm, setIsOpenUndoPlanConfirm] = useState<boolean>(false);
  const isHasTeam = !!currentUser.data?.team;
  const [isChangingName, setIsChangingName] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>('');
  const fullNameRef = useRef<HTMLInputElement>(null);
  const isOwnerTeam = currentUser.data?.team?.owner === currentUser.data?.email;
  const { t } = useTranslation();
  const { loader } = useLoaderContext();

  useEffect(() => {
    (async () => {
      setCurrenMySubscription(await getMySubscription());
    })();
  }, [modalState]);

  useEffect(() => {
    if (currentUser.data) {
      setFullName(currentUser.data.fullName);
    }
    dispatch(fetchSubscriptionAction());
  }, [currentUser]);

  async function removeAvatar() {
    const data = await getDefaultPic();
    if (data) {
      const res = await updateAvatar(data[0].url);
      if (res) {
        dispatch(updateCurrentUser(res));
      }
    }
  }

  function handleOpenUpdateCreditCardModal() {
    dispatch(pushModal({ name: EModals.UPDATE_CREDIT_CARD, data: '' }));
  }

  async function handleChangeFullName() {
    if (!currentUser.data || isChangingName) return;
    if (fullName !== currentUser.data?.fullName) {
      loader.start();
      openMessage('loading', t('Shared.Texts.UpdatingW3Dot'));
      setIsChangingName(true);
      const res = await updateFullName(fullName);
      if (res) {
        dispatch(updateCurrentUser(res));
        destroyMessage();
        openMessage('success', t('Shared.Texts.Updated'));
      } else {
        destroyMessage();
        openMessage('error', t('Shared.Errors.ErrorUpdating'));
      }
      setIsChangingName(false);
      loader.complete();
    }
  }

  async function handleUndoCancelPlan() {
    loader.start();
    if (await undoDeletePlan()) {
      (async () => {
        openMessage('loading', t('Pages.AccountSetting.Profile.UndoingCancellationW3Dot'));
        setCurrenMySubscription(await getMySubscription());
        destroyMessage();
        openMessage('success', t('Pages.AccountSetting.Profile.YourPlanRestored'));
      })();
    }
    loader.complete();
  }

  return (
    <>
      <BasicSection
        className="information-section mb-9"
        header={t('Pages.AccountSetting.Profile.SubTitle')}
        id={EAccountSettingId.PROFILE}
      >
        <article>
          <div className="row gy-2" style={{ margin: '20px 0px 14px' }}>
            <div className="label col-12 col-md-6 p-0">{t('Shared.Fields.ProfilePicture')}:</div>
            <div className="action col-12 col-md-6 p-0 px-md-1">
              <a
                onClick={(event) => {
                  event.stopPropagation();
                  dispatch(pushModal({ name: EModals.UPDATE_AVATAR, data: '' }));
                }}
              >
                {t('Shared.Actions.Update')}
              </a>
              {!currentUser.data?.avatar?.includes('simon-says-public-profile-pics') && (
                <>
                  <span className="ms-1 me-1">|</span>
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                      removeAvatar();
                    }}
                  >
                    {t('Shared.Actions.Remove')}
                  </a>
                </>
              )}
            </div>
          </div>
        </article>
        <article className="mb-4">
          <Avatar size={100} src={currentUser.data?.avatar}>
            {currentUser.data?.fullName.slice(0, 1)}
          </Avatar>
        </article>
        <article className="mb-4">
          <div className="row">
            <div className="col-sm-6">
              <div className="label" style={{ margin: '20px 0px 14px', lineHeight: '13px' }}>
                {t('Shared.Fields.YourName')}
              </div>
              <Tooltip
                title={t('Pages.AccountSetting.Profile.ChangeYourName')}
                trigger={'hover'}
                mouseEnterDelay={EDelayTime.FAST}
                placement="bottom"
              >
                <input
                  ref={fullNameRef}
                  readOnly={isChangingName}
                  className="text"
                  value={fullName}
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                  onBlur={() => {
                    handleChangeFullName();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      fullNameRef.current?.blur();
                    }
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                />
              </Tooltip>
            </div>
            <div className="col-sm-6">
              <div className="label" style={{ margin: '20px 0px 14px' }}>
                {t('Shared.Fields.YourEmail')}{' '}
                <span
                  className="ms-1 action"
                  onClick={(event) => {
                    event.stopPropagation();
                    dispatch(pushModal({ name: EModals.CHANGE_EMAIL, data: '' }));
                  }}
                >
                  {t('Shared.Actions.Change')}
                </span>
              </div>
              <div className="text">{currentUser.data?.email}</div>
            </div>
          </div>
        </article>
        <article className="d-flex flex-column">
          <div className="row mb-3">
            <div className="col-sm-6">
              <div className="mb-3">
                <div className="label">{t('Shared.Fields.CurrentPlan')}</div>
                <div className="text">{currentUser.data?.plan.fullLabel}</div>
                {isHasTeam && (
                  <div className="text">
                    {t('Shared.Fields.TeamOwner')}: {currentUser.data?.team.owner}
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-6">
              {currentUser.data?.subscriptionCreditExpireDate &&
                !currentMySubscription?.cancelAtPeriodEnd && (
                  <>
                    <div className="label">{t('Shared.Fields.RenewalDate')}</div>
                    <div className="text">
                      <span style={{ color: '#008000' }}>
                        {moment(currentUser.data?.subscriptionCreditExpireDate).format(
                          'MMM D, YYYY'
                        )}
                      </span>
                    </div>
                  </>
                )}
            </div>
          </div>
          {(!isHasTeam || isOwnerTeam) && (
            <div
              className={`mb-2 ${
                currentUser.data?.plan.planEnum === EPlanOption.STUDIO_ANNUAL ? 'd-none' : ''
              }`}
            >
              <div
                className="action action-lg"
                onClick={() => {
                  dispatch(
                    pushModal({
                      name: EModals.SUBSCRIBE_MODAL,
                      data: { type: ETypeSubcribeModal.UPDRAGE }
                    })
                  );
                }}
              >
                {t('Shared.Actions.UpgradePlan')}
              </div>
            </div>
          )}
          <div
            onClick={() => {
              dispatch(pushModal({ name: EModals.ADD_CREDIT_MODAL, data: '' }));
            }}
            className="action mb-2"
          >
            {t('Shared.Actions.AddCredit')}
          </div>
          {(!isHasTeam || isOwnerTeam) &&
            currentUser.data?.subscriptionCardDigits &&
            !currentMySubscription?.cancelAtPeriodEnd && (
              <div className="mb-2 action" onClick={handleOpenUpdateCreditCardModal}>
                {t('Pages.AccountSetting.Profile.UpdatePaymentDetails')}{' '}
              </div>
            )}
          {(!isHasTeam || isOwnerTeam) &&
            currentUser.data?.plan.planEnum !== EPlanOption.PAY_AS_YOU_GO &&
            !currentMySubscription?.cancelAtPeriodEnd && (
              <>
                {!(currentUser.data && currentUser.data?.plan.downgradeOptions.length === 0) && (
                  <div
                    className="mb-2 action action"
                    onClick={() => {
                      dispatch(setChangePlanModalDefaultOption(EChangePlanOption.DOWNGRADE_PLAN));
                      dispatch(pushModal({ name: EModals.CHOOSER_OPTIONS_PLAN_MODAL, data: '' }));
                    }}
                  >
                    {t('Pages.AccountSetting.Profile.DowngradePlan')}
                  </div>
                )}
                <div
                  className="mb-2 action action-sm"
                  onClick={() => {
                    dispatch(setChangePlanModalDefaultOption(EChangePlanOption.CANCEL_PLAN));
                    dispatch(pushModal({ name: EModals.CHOOSER_OPTIONS_PLAN_MODAL, data: '' }));
                  }}
                >
                  {t('Shared.Actions.CancelPlan')}
                </div>
              </>
            )}
          {currentMySubscription?.cancelAtPeriodEnd && (
            <div>
              {/* <div>Your plan will be cancelled on {moment(currentMySubscription?.canceledAt).format('MMM DD, YYYY')} as requested.</div> */}
              <div className="mb-2">
                {currentMySubscription.currentPeriodEnd && (
                  <Trans
                    t={t}
                    i18nKey="Pages.AccountSetting.Profile.YourPlanWillBeCanceled"
                    values={{
                      date: moment.unix(currentMySubscription.currentPeriodEnd).format('MMM D, yyy')
                    }}
                  >
                    Here is the <span style={{ fontWeight: 600 }}>date</span>
                  </Trans>
                )}
              </div>
              <div
                className="action"
                onClick={() => {
                  setIsOpenUndoPlanConfirm(true);
                }}
              >
                {t('Shared.Actions.Undo')}: {t('Pages.AccountSetting.Profile.DontCancelMyPlan')}
              </div>
            </div>
          )}
        </article>
      </BasicSection>
      <UpdateAvatarModal />
      <ChangeEmailModal />
      <Modal
        open={isOpenUndoPlanConfirm}
        width={300}
        onCancel={() => {
          setIsOpenUndoPlanConfirm(false);
        }}
        mask={false}
        closeIcon={<AiOutlineClose color="var(--si-white)" />}
        footer={[
          <button
            className="no-button"
            key={'no'}
            onClick={() => {
              setIsOpenUndoPlanConfirm(false);
            }}
          >
            {t('Shared.Texts.No')}
          </button>,
          <button
            className="yes-button"
            key={'yes'}
            onClick={() => {
              setIsOpenUndoPlanConfirm(false);
              handleUndoCancelPlan();
            }}
          >
            {t('Shared.Texts.Yes')}
          </button>
        ]}
        wrapClassName="modal-delete-folder"
      >
        <div className="d-flex">
          <div className="d-flex align-items-center me-1">
            <AiOutlineWarning size={30} color="var(--si-white)" />
          </div>
          <div className="warning-text">
            {t('Pages.AccountSetting.Profile.ConfirmUndoCancellation')}
          </div>
        </div>
      </Modal>
    </>
  );
}
