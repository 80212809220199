import { AxiosCustom } from 'apis/axios-client';
import { TDefaultConfig, TPlan } from 'ts/types';

export async function getListPlan(): Promise<TPlan[] | []> {
  const res = await AxiosCustom.get('/plans');
  return res.data;
}

export async function getDefaultConfig(): Promise<TDefaultConfig> {
  const res = await AxiosCustom.get('/admin/config');
  return res.data;
}

export async function updateDefaultConfig(newConfig: TDefaultConfig): Promise<TDefaultConfig> {
  const res = await AxiosCustom.put('/admin/config', newConfig);
  return res.data;
}

export async function updatePlan(id: number | string, subscriptionCredit: number): Promise<number> {
  const res = await AxiosCustom.put(`/plans/${id}`, { subscriptionCredit });
  return res.status;
}
