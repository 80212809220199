export function parseStringToArray(inputString: string) {
  if (inputString) {
    return inputString.split(', ');
  }
  return [];
  // const keyValuePairs = inputString.split(', ');
  // const result: string[] = [];

  // keyValuePairs.forEach((pair) => {
  //   result.push(pair);
  // });

  // return result;
}

export function SpeechApiIdToCode(code: string) {
  switch (code) {
    case '1':
      return 'watson';
    case '2':
      return 'google';
    case '4':
      return 'speechmatics';
    case '5':
      return 'speechmaticsv1';
    case '6':
      return 'revai';
    case '7':
      return 'avflow';
    case '8':
      return 'whisper';
    default:
      return null;
  }
}

export function SpeechApiCodeToName(code: string) {
  switch (code) {
    case 'watson':
      return 'Watson';
    case 'google':
      return 'Google';
    case 'speechmatics':
      return 'Speechmatics v2';
    case 'speechmaticsv1':
      return 'Speechmatics v1';
    case 'revai':
      return 'RevAI';
    case 'avflow':
      return 'AVFlow';
    case 'whisper':
      return 'Whisper';
    default:
      return 'Unknown';
  }
}

export function changeInlineStyle(string: string | null | undefined) {
  if (!string) return '';

  const emphasizeContent = (match: string) => {
    return `<b>${match}</b>`;
  };

  const emphasizedString = string.replace(/\[([^\]]+)\]/g, emphasizeContent);

  const processedString = emphasizedString
    .replace(/, \[/g, ',<br/> [')
    .replace(/\(Success\)/g, '<span style="color: green;"><b>(Success)</b></span>')
    .replace(/\(Failed\)/g, '<span style="color: red;"><b>(Failed)</b></span>')
    .split('</span>,')
    .map((item, index, array) => {
      const isLastItem = index === array.length - 1;
      const comma = isLastItem ? '' : ',';
      return `<div style="white-space: nowrap">${item}${comma}</div>`;
    })
    .join('');

  return processedString;
}

function shortenFileName(fullFileName: string, maxLength: number) {
  if (fullFileName.length <= maxLength) {
    return fullFileName;
  }

  const extensionIndex = fullFileName.lastIndexOf('.');
  const extension = fullFileName.substring(extensionIndex);
  const fileNameSplit =
    fullFileName.substring(0, maxLength) +
    '...' +
    fullFileName.slice(-(maxLength + extension.length));

  return fileNameSplit;
}

export function breakFileNameWithScreenSize({
  fileName,
  sizeScreen
}: {
  fileName: string;
  sizeScreen: number;
}) {
  if (sizeScreen > 515 && sizeScreen < 1250 && fileName.length > 30) {
    return `${shortenFileName(fileName, 15)}`;
  } else if (sizeScreen <= 515 && fileName.length > 10) {
    return `${shortenFileName(fileName, 5)}`;
  }
  return fileName;
}
