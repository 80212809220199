import { createAsyncThunk } from '@reduxjs/toolkit';
import { createProjectTag, deleteProjectTag, getListProjectTags } from 'apis/tags.api';
import { TProjectTags } from 'ts/types';
import { TRequestCreateProjectTag } from 'ts/types/TRequest';

export const getListProjectTagsAction = createAsyncThunk(
  'project-tags/getListProjectTags',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getListProjectTags();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createProjectTagsAction = createAsyncThunk(
  'project-tags/createProjectTags',
  async ({ projectId, tagId }: TRequestCreateProjectTag, { rejectWithValue }) => {
    try {
      const res = await createProjectTag({ projectId, tagId });
      return res;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProjectTagAction = createAsyncThunk(
  'project-tags/deleteProjectTag',
  async (
    { projectTag, isHandleApi = true }: { projectTag: TProjectTags; isHandleApi?: boolean },
    { rejectWithValue }
  ) => {
    try {
      if (isHandleApi) {
        const res = await deleteProjectTag({ projectTag });
        return res;
      }
      return projectTag;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
