import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { TControllerMark } from 'ts/types';

interface TTimeDivided {
  startTime: number;
  endTime: number;
}
interface videoPlayerContextType {
  currentTime: number;
  setCurrentTime: (val: number) => void;
  videoRef: React.RefObject<ReactPlayer>;
  markList: TControllerMark[];
  setMarkList: React.Dispatch<React.SetStateAction<TControllerMark[]>>;
  // eslint-disable-next-line no-unused-vars
  seekTo: (value: number, type?: 'seconds' | 'fraction') => void;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  handleFullScreen: VoidFunction;
  videoDuration: number;
  setVideoDuration: React.Dispatch<React.SetStateAction<number>>;
  volume: number;
  // eslint-disable-next-line no-unused-vars
  setVolume: (volume: number) => void;
  isFullscreen: boolean;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  showPreview: boolean;
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
  playBackRate: number;
  setPlayBackRate: React.Dispatch<React.SetStateAction<number>>;
  userDefinedTimeCode: number;
  setUserDefinedTimeCode: React.Dispatch<React.SetStateAction<number>>;
  isAudio: boolean;
  setIsAudio: React.Dispatch<React.SetStateAction<boolean>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
  timeDivided: TTimeDivided[];
  setTimeDivied: React.Dispatch<React.SetStateAction<TTimeDivided[]>>;
  isReady: boolean;
  setIsReady: React.Dispatch<React.SetStateAction<boolean>>;
}

const VideoPlayerContext = createContext<videoPlayerContextType>(null!);

export function useVideoPlayerContext() {
  return useContext(VideoPlayerContext);
}
export function VideoPlayerProvider({ children }: { children: React.ReactNode }) {
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [markList, setMarkList] = useState<TControllerMark[]>([]);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [volume, setNewVolume] = useState<number>(100);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [showPreview, setShowPreview] = useState<boolean>(true);
  const [playBackRate, setPlayBackRate] = useState<number>(1);
  const [userDefinedTimeCode, setUserDefinedTimeCode] = useState<number>(0);
  const [isAudio, setIsAudio] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [timeDivided, setTimeDivied] = useState<TTimeDivided[]>([]);
  const [isReady, setIsReady] = useState<boolean>(false);

  const videoRef = useRef<ReactPlayer>(null);

  function seekTo(value: number, type?: 'seconds' | 'fraction') {
    setShowPreview(false);
    // setIsPlaying(true);
    videoRef.current?.seekTo(value, type);
  }

  function handleFullScreen() {
    if (!document.fullscreenElement) {
      setIsFullscreen(true);
      document.getElementById('video-player-wrapper-id')?.requestFullscreen();
    } else {
      setIsFullscreen(false);
      document.exitFullscreen();
    }
  }

  function setVolume(volume: number) {
    if (volume >= 0 && volume < 101) {
      setNewVolume(volume);
    }
  }
  useEffect(() => {
    if (isPlaying) {
      setShowPreview(false);
    }
  }, [isPlaying]);

  const value = {
    isFullscreen,
    volume,
    setVolume,
    setVideoDuration,
    videoDuration,
    currentTime,
    setCurrentTime: (val: number) => {
      setCurrentTime(val);
    },
    markList,
    setMarkList,
    seekTo,
    videoRef,
    isPlaying,
    setIsPlaying,
    handleFullScreen,
    isVisible,
    setIsVisible,
    showPreview,
    setShowPreview,
    setPlayBackRate,
    playBackRate,
    userDefinedTimeCode,
    setUserDefinedTimeCode,
    isAudio,
    setIsAudio,
    currentPage,
    setCurrentPage,
    total,
    setTotal,
    timeDivided,
    setTimeDivied,
    isReady,
    setIsReady
  };

  return <VideoPlayerContext.Provider value={value}>{children}</VideoPlayerContext.Provider>;
}
