import React, { useEffect, useRef, useState } from 'react';
import IconsUpload from './IconsUpload';
import { isSafariBrower } from 'utils/ua';
import { deleteMedia, signUrlToUpload, uploadLocalFileToMedia } from 'apis/media.api';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useWebSocket } from 'context/WebSocketConnectionContext';
import { TBoxUpload, TFileDropbox, TMedia } from 'ts/types';
import {
  changeActiveOpenPaymentModal,
  reloadCurrentProject,
  updateListMediasInCurrentProject,
  updateStatusMedia
} from 'redux/projects/projects.slice';
import { notification } from 'antd';
import {
  EDurationMax,
  EFileSizeMax,
  EModals,
  ESocketTopic,
  EStatusMedia,
  ETypeFileAccept,
  ETypeNoti,
  EUploaderType,
  SUPPORTED_CHECK_DURATION
} from 'ts/enums';
import { convertByteToMegaByte, createChannelId } from 'utils/common';
import { createNewMediaAction, getProjectByIdAction } from 'redux/projects/projects.action';
import loadScript from 'load-script';
import { useGA } from 'context/CustomAnalytics';
import { IoIosAddCircleOutline } from 'react-icons/io';
import useDrivePicker from 'react-google-drive-picker';
import { popModal, pushModal } from 'redux/modal/modal.slice';
import { getAccessTokenBox } from 'apis/userSetting.api';
import { HttpStatusCode } from 'axios';
import { getProjectById } from 'apis/projects.api';
import { removeGdriveToken, setGdriveToken } from 'redux/token/token.slice';
import { useTranslation, Trans } from 'react-i18next';
import { PickerCallback } from 'react-google-drive-picker/dist/typeDefs';
import { useLoaderContext } from 'context/LoaderContext';

const DROPBOX_SDK_URL = 'https://www.dropbox.com/static/api/2/dropins.js';
const DROPBOX_SCRIPT_ID = 'dropboxjs';
const DROPBOX_APP_KEY = 'l0yj3opuzc3gop5'; // App key

const BOX_SDK_URL = 'https://cdn01.boxcdn.net/platform/elements/7.2.0/en-US/picker.js';
const BOX_SCRIPT_ID = 'boxjs';
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Dropbox: any;
    Box: any;
  }
}

function UploadFile() {
  const params = useParams();
  const { projectId } = params;
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const { subscribe, isConnected, disconnected, resetReconnectCount } = useWebSocket();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropboxRef = useRef<any>(null);
  const boxRef = useRef<any>(null);
  const currentProject = useAppSelector((state) => state.projects.currentProject.data);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const mediaId = searchParams.get('mediaId');

  const { trackEvent } = useGA();
  const [openPicker, authResponse] = useDrivePicker();
  const userData = useAppSelector((state) => state.auth.currentUser);
  const gDriveToken = useAppSelector((state) => state.token.gDriveToken);
  const [pickerData, setPickerData] = useState<PickerCallback>();
  const { loader } = useLoaderContext();

  const handleStatusByProgress = (progress: number) => {
    if (progress > 99) return EStatusMedia.READY;
    if (progress > 49) return EStatusMedia.PREPARING;
    if (progress > 39) return EStatusMedia.STARTING;
    return EStatusMedia.UPLOADING;
  };

  const reloadProject = async () => {
    if (!projectId) return;
    const project = await getProjectById({ projectId });
    const currentMediaLocal = currentProject?.medias;
    const mediaFromRemote = project?.medias;

    if (mediaFromRemote && mediaFromRemote.length > 0) {
      const allMediaReady =
        mediaFromRemote.filter((media) => media.status === EStatusMedia.READY).length ===
        mediaFromRemote.length
          ? true
          : false;
      if (allMediaReady) {
        resetReconnectCount();
      }
      project && dispatch(reloadCurrentProject({ project }));
    } else {
      disconnected();
    }
  };

  const refetchProject = async () => {
    if (!projectId) return;
    loader.start();
    try {
      const project = await getProjectById({ projectId });
      const mediaFromRemote = project?.medias;
      if (mediaFromRemote && mediaFromRemote.length > 0) {
        project &&
          dispatch(
            reloadCurrentProject({
              project: {
                ...project,
                medias: [...project.medias].map((media) => {
                  const progress =
                    media.fileUploadType !== EUploaderType.LOCAL
                      ? media.prepareProgressPercentage
                      : (media.prepareProgressPercentage + 100) / 2;
  
                  return {
                    ...media,
                    uploadInfor: {
                      progress: progress
                    }
                  };
                })
              }
            })
          );
        }
    } catch(err) {
      console.error(err);
    } finally {
      loader.complete();
    }
  };

  // useEffect(() => {
  //   const currentMediaLocal = currentProject?.medias;
  //   const allMediaReady =
  //     currentMediaLocal?.filter((media) => media.status === EStatusMedia.READY).length ===
  //     currentMediaLocal?.length
  //       ? true
  //       : false;
  //   if (!allMediaReady) {
  //     console.log('reconnectCount', reconnectCount);
  //     if (reconnectCount === 15) {
  //       reloadProject();
  //       console.log('reload project');
  //       console.info(`trying to reconnect ${reconnectCount}s`);
  //     }
  //   }
  // }, [reconnectCount, currentProject]);

  useEffect(() => {
    if (currentProject) {
      const interval = setInterval(async () => {
        const currentMediaLocal = currentProject.medias;
        const allMediaReady =
          currentMediaLocal?.filter((media) => media.status === EStatusMedia.READY).length ===
          currentMediaLocal?.length
            ? true
            : false;
        if (!allMediaReady) {
          await refetchProject();
        }
      }, 30 * 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [currentProject]);

  useEffect(() => {
    if (isConnected && projectId) {
      subscribe({
        topicChannelId: createChannelId('topic', ESocketTopic.UPLOAD_PROGRESS, projectId),
        callback: (res: { progress: number; mediaId: string }) => {
          // if (res.progress !== 100) {
          //   dispatch(
          //     updateStatusMedia({
          //       mediaId: res.mediaId,
          //       status: handleStatusByProgress(res.progress * 0.5 + 50),
          //       uploadInfor: {
          //         progress: res.progress * 0.5 + 50
          //       }
          //     })
          //   );
          // }
        }
      });

      subscribe({
        topicChannelId: createChannelId('topic', ESocketTopic.MEDIA_UPDATE_PROJECT, projectId),
        callback: (res: TMedia) => {
          const progress =
            res.fileUploadType !== EUploaderType.LOCAL
              ? res.prepareProgressPercentage
              : (res.prepareProgressPercentage + 100) / 2;

          if (progress > 0 && progress !== 100) {
            dispatch(
              updateStatusMedia({
                mediaId: res.id,
                status: handleStatusByProgress(progress),
                uploadInfor: {
                  progress: progress
                }
              })
            );
          }

          if (res.status === 'ready' && progress === 100) {
            dispatch(
              updateListMediasInCurrentProject({
                media: res
              })
            );
          }
        }
      });

      subscribe({
        topicChannelId: createChannelId('topic', ESocketTopic.ERRORS, projectId),
        callback: (res: any) => {
          if (res) {
            const resContentParse = JSON.parse(res.content);
            dispatch(popModal());
            dispatch(
              pushModal({
                name: EModals.NOTI_MODAL,
                data: null,
                notiData: { type: ETypeNoti.ERROR, title: resContentParse.message }
              })
            );
            deleteMedia({ mediaId: resContentParse.mediaId })
              .then((res) => {
                if (res.status === HttpStatusCode.Ok && projectId) {
                  dispatch(getProjectByIdAction({ projectId }));
                  dispatch(changeActiveOpenPaymentModal(false));
                }
              })
              .catch((error) => {});
          }
        }
      });
    }
    // return () => {
    //   if (isConnected && projectId) {
    //     unsubscribe(createChannelId('topic', ESocketTopic.UPLOAD_PROGRESS, projectId));
    //     unsubscribe(createChannelId('topic', ESocketTopic.MEDIA_UPDATE_PROJECT, projectId));
    //     unsubscribe(createChannelId('topic', ESocketTopic.ERRORS, projectId));
    //   }
    // };
  }, [isConnected, projectId]);

  useEffect(() => {
    loadScript(
      BOX_SDK_URL,
      {
        attrs: {
          id: BOX_SCRIPT_ID
        }
      },
      () => (boxRef.current = window.Box)
    );
  }, []);

  useEffect(() => {
    loadScript(
      DROPBOX_SDK_URL,
      {
        attrs: {
          id: DROPBOX_SCRIPT_ID,
          'data-app-key': DROPBOX_APP_KEY
        }
      },
      () => (dropboxRef.current = window.Dropbox)
    );
  }, []);

  useEffect(() => {
    if (authResponse?.access_token) {
      const expired = new Date().getTime() + authResponse.expires_in * 1000;
      dispatch(setGdriveToken({ gDriveToken: { token: authResponse.access_token, expired } }));
    } else {
      const picker = document.getElementsByClassName('picker');
      for (let i = 0; i < picker.length; i++) {
        (picker[i] as any).style.display = 'none';
      }
    }
  }, [authResponse]);

  useEffect(() => {
    pickerData && gDriveUploadCallback(pickerData);
  }, [pickerData]);

  const upLoadLocalFile = (file: File, projectId: string) => {
    if(file.name.toLowerCase().endsWith(".srt")) {
      notification.open({ placement: 'top', type: 'info', message: <Trans t={t} i18nKey="Pages.UploadMedia.Texts.UploadedSrtTip">This should be the link<a href="https://www.simonsaysai.com/convert/translate-srt-files">Link</a></Trans> });
      return;
    }

    // STEP 1: Create new media
    loader.start();
    dispatch(createNewMediaAction({ name: file.name, projectId }))
      .then(async ({ payload }) => {
        if ((payload as any).response?.data?.error) {
          notification.open({
            type: 'error',
            message: (payload as any).response.data.message
          });
          return;
        }
        loader.complete();
        const newMedia = payload as TMedia;
        if (newMedia) {
          // STEP 2: Sign url to upload
          try {
            const signUrl = await signUrlToUpload({
              fileName: file.name,
              projectId,
              mediaId: newMedia.id,
              mimeType: file.type
            });
            if (signUrl) {
              // STEP 3: Upload file to s3
              dispatch(
                updateStatusMedia({
                  mediaId: newMedia.id,
                  status: EStatusMedia.PREPARING,
                  uploadInfor: {
                    progress: 0
                  }
                })
              );
              try {
                axios
                  .put(signUrl?.url, file, {
                    onUploadProgress: (processEvent) => {
                      dispatch(
                        updateStatusMedia({
                          mediaId: newMedia.id,
                          status: EStatusMedia.UPLOADING,
                          uploadInfor: {
                            progress: processEvent.progress ? processEvent.progress * 50 : 0,
                            loaded: convertByteToMegaByte({ bytes: processEvent.loaded }),
                            total: convertByteToMegaByte({
                              bytes: processEvent.total || 0
                            })
                          }
                        })
                      );
                    },
                    headers: {
                      'Content-Type': file.type
                    }
                  })
                  .then(async () => {
                    dispatch(
                      updateStatusMedia({
                        mediaId: newMedia.id,
                        status: EStatusMedia.STARTING,
                        uploadInfor: {
                          progress: 50
                        }
                      })
                    );
                    // STEP 4: Upload media file
                    const project = await getProjectById({ projectId });
                    const isHasMedia = project?.medias
                      .map((media) => media.id)
                      .includes(newMedia.id);
                    if (isHasMedia) {
                      try {
                        uploadLocalFileToMedia({
                          mediaId: newMedia.id,
                          fileSignName: signUrl.key,
                          fileSize: file.size,
                          type: EUploaderType.LOCAL
                        });
                        dispatch(changeActiveOpenPaymentModal(true));
                      } catch (error) {
                        notification.open({
                          type: 'error',
                          message: t('Shared.Errors.ErrorUploadingFile')
                        });
                      }
                    }
                  });
              } catch (error) {
                notification.open({
                  type: 'error',
                  message: t('Shared.Errors.ErrorUploadingFile')
                });
              }
            }
          } catch (error) {
            notification.open({ type: 'error', message: t('Shared.Errors.ErrorUploadingFile') });
          }
        }
      })
      .catch((error) => {
        console.error('error', error);
        notification.open({ type: 'error', message: t('Shared.Errors.ErrorUploadingFile') });
      });
  };

  function getFileExtension(filename: string) {
    var parts = filename.split('.');
    if (parts.length > 1 && parts[0]) {
      return parts[parts.length - 1].toLowerCase();
    }
    return '';
  }

  function supportCheckDuration(file: File): boolean {
    if (file && file.name) {
      const fileExtension = getFileExtension(file.name);
      if (fileExtension && SUPPORTED_CHECK_DURATION[fileExtension]) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  async function handleUploadFile(files: FileList | null) {
    if (!projectId) return;
    if (files && files?.length > 0) {
      try {
        Array.from(files).forEach((file) => {
          if (supportCheckDuration(file)) {
            var video = document.createElement('video');
            video.preload = 'metadata';
            video.onerror = function () {
              upLoadLocalFile(file, projectId);
            };
            video.onloadedmetadata = function () {
              window.URL.revokeObjectURL(video.src);
              var duration = video.duration;
              if (duration && duration > EDurationMax.LOCAL) {
                notification.open({
                  type: 'error',
                  message: t('Pages.UploadMedia.Errors.ErrorMaxDuration')
                });
              } else if (file.size <= EFileSizeMax.LOCAL) {
                upLoadLocalFile(file, projectId);
              } else {
                notification.open({
                  type: 'error',
                  message: t('Pages.UploadMedia.Errors.FileSizeTooLarge', { maxGB: 5 })
                });
              }
            };
            video.src = URL.createObjectURL(file);
          } else if (file.size <= EFileSizeMax.LOCAL) {
            upLoadLocalFile(file, projectId);
          } else {
            notification.open({
              type: 'error',
              message: t('Pages.UploadMedia.Errors.FileSizeTooLarge', { maxGB: 5 })
            });
          }
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        notification.open({ type: 'error', message: error.response.data.message });
      }
    }
  }

  const handleShowBoxUpload = (type: 'show' | 'hide') => {
    const element = document.getElementById('box-upload');
    if (element) {
      switch (type) {
        case 'show': {
          element.classList.remove('d-none');
          break;
        }
        case 'hide': {
          element.classList.add('d-none');
          break;
        }
        default:
          break;
      }
    }
  };

  async function handleBoxUploadFile() {
    const boxAuthToken = userData.data?.boxAuthToken;
    if (!boxAuthToken) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: {
            type: ETypeNoti.ERROR,
            title: `${t(
              'Pages.UploadMedia.Texts.BoxConnectRemindWColon'
            )} <a class='white-link' href='/app/account-setting#connected-apps'>${t(
              'Shared.Fields.ProfilePage'
            )}</a>.`
          }
        })
      );
    } else {
      const res = await getAccessTokenBox();
      if (res && res.accessToken) {
        const accessToken = res.accessToken;
        handleShowBoxUpload('show');
        const FilePicker = new boxRef.current.FilePicker();
        FilePicker.show('0', accessToken, {
          container: '.box-upload',
          extensions: [
            '3g2',
            'asf',
            'avchd',
            'flv',
            'm4v',
            'mxf',
            'mp4',
            'mpeg',
            'mpg',
            'mov',
            'mkv',
            'mts',
            'rm',
            'swf',
            'vob',
            'wmv',
            'aac',
            'aif',
            'aiff',
            'iff',
            'flac',
            'm3u',
            'm4a',
            'midi',
            'mkv',
            'mp2',
            'mp3',
            'mp4',
            'mpa',
            'ogg',
            'opus',
            'wav',
            '.wma',
            '.avi'
          ]
        });
        FilePicker.addListener('cancel', () => {
          handleShowBoxUpload('hide');
        });

        FilePicker.addListener('choose', (files: TBoxUpload[]) => {
          handleShowBoxUpload('hide');
          FilePicker.hide();
          if (!projectId) {
            return;
          }
          try {
            loader.start();
            files.forEach((file) => {
              if (file.size <= EFileSizeMax.OTHER) {
                dispatch(createNewMediaAction({ name: file.name, projectId })).then(
                  ({ payload }) => {
                    if ((payload as any).response?.data?.error) {
                      notification.open({
                        type: 'error',
                        message: (payload as any).response.data.message
                      });
                      loader.complete();
                      return;
                    }
                    const newMedia = payload as TMedia;
                    dispatch(
                      updateStatusMedia({
                        mediaId: newMedia.id,
                        status: EStatusMedia.PREPARING,
                        uploadInfor: {
                          progress: 0
                        }
                      })
                    );
                    uploadLocalFileToMedia({
                      mediaId: newMedia.id,
                      fileSignName: file.name,
                      fileSize: file.size,
                      type: EUploaderType.BOX,
                      boxFileId: file.id
                    })
                      .then((uploadedMedia) => {
                        if (uploadedMedia) {
                          dispatch(
                            updateStatusMedia({
                              mediaId: newMedia.id,
                              status: EStatusMedia.UPLOADING,
                              uploadInfor: {
                                progress: 0
                              }
                            })
                          );
                        }
                      })
                      .catch((error) => {
                        notification.open({
                          type: 'error',
                          message: t('Shared.Errors.ErrorUploadingFile')
                        });
                      });
                  }
                );
              } else {
                notification.open({
                  type: 'error',
                  message: t('Pages.UploadMedia.Errors.FileSizeTooLarge', { maxGB: 20 })
                });
              }
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            notification.open({ type: 'error', message: error.response.data.message });
          }
        });
      } else {
        dispatch(
          pushModal({
            name: EModals.NOTI_MODAL,
            data: null,
            notiData: {
              type: ETypeNoti.ERROR,
              title: `${t(
                'Pages.UploadMedia.Texts.BoxConnectRemindWColon'
              )} <a class='white-link' href='/app/account-setting#connected-apps'>${t(
                'Shared.Fields.ProfilePage'
              )}</a>.`
            }
          })
        );
      }
    }
  }

  const upLoadDropBoxFile = (file: TFileDropbox, projectId: string) => {
    loader.start();
    dispatch(createNewMediaAction({ name: file.name, projectId })).then(({ payload }) => {
      if ((payload as any).response?.data?.error) {
        notification.open({
          type: 'error',
          message: (payload as any).response.data.message
        });
        loader.complete();
        return;
      }
      const newMedia = payload as TMedia;
      dispatch(
        updateStatusMedia({
          mediaId: newMedia.id,
          status: EStatusMedia.PREPARING,
          uploadInfor: {
            progress: 0
          }
        })
      );
      uploadLocalFileToMedia({
        mediaId: newMedia.id,
        fileSignName: file.name,
        fileSize: file.bytes,
        type: EUploaderType.DROPBOX,
        linkToFile: file.link
      })
        .then((uploadedMedia) => {
          if (uploadedMedia) {
            dispatch(
              updateStatusMedia({
                mediaId: newMedia.id,
                status: EStatusMedia.PREPARING,
                uploadInfor: {
                  progress: 0
                }
              })
            );
            dispatch(changeActiveOpenPaymentModal(true));
          }
        })
        .catch((error) => {
          console.error('error', error);
          notification.open({ type: 'error', message: t('Shared.Errors.ErrorUploadingFile') });
        });
    });
  };

  function handleDropboxUploadFile() {
    if (!projectId) return;
    dropboxRef.current.choose({
      multiselect: true,
      extensions: [
        '.3g2',
        '.asf',
        '.avchd',
        '.flv',
        '.m4v',
        '.mxf',
        '.mp4',
        '.mpeg',
        '.mpg',
        '.mov',
        '.mkv',
        '.mts',
        '.rm',
        '.swf',
        '.vob',
        '.wmv',
        '.aac',
        '.aif',
        '.aiff',
        '.iff',
        '.flac',
        '.m3u',
        '.m4a',
        '.midi',
        '.mkv',
        '.mp2',
        '.mp3',
        '.mp4',
        '.mpa',
        '.ogg',
        '.opus',
        '.wav',
        '.wma',
        '.avi'
      ],
      success: (files: TFileDropbox[]) => {
        try {
          files.forEach((file) => {
            var video = document.createElement('video');
            video.preload = 'metadata';
            if (video.onloadedmetadata) {
              video.onloadedmetadata = function () {
                window.URL.revokeObjectURL(video.src);
                var duration = video.duration;

                if (duration >= EDurationMax.LOCAL) {
                  notification.open({
                    type: 'error',
                    message: t('Pages.UploadMedia.Errors.ErrorMaxDuration')
                  });
                } else if (file.bytes <= EFileSizeMax.OTHER) {
                  upLoadDropBoxFile(file, projectId);
                } else {
                  notification.open({
                    type: 'error',
                    message: t('Pages.UploadMedia.Errors.FileSizeTooLarge', { maxGB: 20 })
                  });
                }
              };
            } else if (file.bytes <= EFileSizeMax.OTHER) {
              upLoadDropBoxFile(file, projectId);
            } else {
              notification.open({
                type: 'error',
                message: t('Pages.UploadMedia.Errors.FileSizeTooLarge', { maxGB: 20 })
              });
            }
            video.src = file.link;
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          notification.open({ type: 'error', message: error.response.data.message });
        }
      },
      cancel: () => {
        console.info('cancel');
      },
      linkType: 'direct',
      folderselect: false
    });
  }

  function gDriveUploadCallback(data: PickerCallback) {
    if (data.action === 'picked' && projectId) {
      data.docs.map((media) => {
        if (media.sizeBytes > EFileSizeMax.OTHER) {
          dispatch(
            pushModal({
              name: EModals.NOTI_MODAL,
              data: null,
              notiData: {
                type: ETypeNoti.ERROR,
                title:
                  'The file size is too large (>20GB). Transcode it to a smaller size or use our <a target="_blank" href="https://www.simonsaysai.com/help/2657471-download-the-macos-app-fcp-x-extension" >macOS application</a> instead.'
              }
            })
          );
          return;
        }
        loader.start();
        dispatch(createNewMediaAction({ name: media.name, projectId }))
          .then(async ({ payload }) => {
            if ((payload as any).response?.data?.error) {
              notification.open({
                type: 'error',
                message: (payload as any).response.data.message
              });
              loader.complete();
              return;
            }

            const newMedia = payload as TMedia;
            if (newMedia) {
              dispatch(
                updateStatusMedia({
                  mediaId: newMedia.id,
                  status: EStatusMedia.PREPARING,
                  uploadInfor: {
                    progress: 0
                  }
                })
              );
              dispatch(changeActiveOpenPaymentModal(true));
              try {
                uploadLocalFileToMedia({
                  mediaId: newMedia.id,
                  accessToken: gDriveToken.token,
                  linkToFile: media.id,
                  fileSignName: media.name,
                  fileSize: media.sizeBytes,
                  type: EUploaderType.GDRIVE
                });
              } catch (error) {
                notification.open({ type: 'error', message: 'Error upload file' });
              }
            }
          })
          .catch((error) => {
            console.error('error', error);
            notification.open({ type: 'error', message: 'Error upload file' });
          });
      });
    }
  }

  function handleGoogleDriveUploadFile() {
    if (gDriveToken.expired && new Date(gDriveToken.expired) < new Date()) {
      dispatch(removeGdriveToken());
    }
    setPickerData(undefined);
    openPicker({
      clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID || '',
      developerKey: '',
      viewId: 'DOCS',
      token: gDriveToken.token || '',
      showUploadView: true,
      showUploadFolders: true,
      setIncludeFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: setPickerData
    });
  }

  return (
    <div className="ctn-upload-file">
      <div className="ctn-upload-file-drag d-none d-md-block">
        <div
          className="upload-wrapper"
          onClick={() => ref.current?.click()}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDrop={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleUploadFile(e.dataTransfer.files);
          }}
          onDragEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="icon-text-add-media">
            <div>
              <IoIosAddCircleOutline size={132} />
            </div>
            <div className="text-add-media">{t('Shared.Actions.AddMedia')}</div>
          </div>
          <div className="text-drag-drap text-uppercase w-100">
            {t('Pages.UploadMedia.Texts.DragAndDropExplain')}
          </div>
        </div>
        <input
          ref={ref}
          type="file"
          hidden
          multiple
          onChange={(e) => {
            e.preventDefault();

            handleUploadFile(e.target.files);
          }}
          accept={ETypeFileAccept.VIDEO}
        />
      </div>
      <div className="d-flex justify-content-md-between justify-content-center my-2 ms-2">
        <IconsUpload
          onClickFileUpload={() => {
            trackEvent({ category: 'Upload', action: 'Upload file from local' });
            ref.current?.click();
          }}
          onClickGDriveUpload={() => {
            trackEvent({ category: 'Upload', action: 'Upload file from google drive' });
            handleGoogleDriveUploadFile();
          }}
          onClickDropboxUpload={() => {
            trackEvent({ category: 'Upload', action: 'Upload file from dropbox' });
            handleDropboxUploadFile();
          }}
          onClickBoxUpload={() => {
            trackEvent({ category: 'Upload', action: 'Upload file from box' });
            handleBoxUploadFile();
          }}
        />
        {isSafariBrower ? (
          <div className="text-end d-none d-md-block">
            <Trans
              t={t}
              i18nKey="Pages.UploadMedia.Texts.TroubleUploadRemind"
              components={{ linebreak: <br /> }}
            />{' '}
            <a
              href="https://www.simonsaysai.com/help/2657471-download-the-macos-app-fcp-x-extension"
              target="_blank"
              rel="noreferrer"
            >
              {t('App.Title')} - {t('Layouts.Footer.Texts.Products.List.MacApp')}
            </a>
          </div>
        ) : (
          <div className="text-end d-none d-md-block">
            {t('Pages.UploadMedia.Texts.SimpleUploadRemind')}{' '}
            <a
              className="custom-a-tag"
              href="https://www.simonsaysai.com/help/2657471-download-the-macos-app-fcp-x-extension"
              target="_blank"
              rel="noreferrer"
            >
              {t('App.Title')} - {t('Layouts.Footer.Texts.Products.List.MacApp')}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default UploadFile;
