import moment from 'moment';
import { TMedia } from 'ts/types';

export default function secondsToTimeFilter() {
  function padTime(t: number) {
    return t < 10 ? '0' + t : t;
  }
  function adjustFramesToShowDueToDropping(FF: number, MM: number, fps: number) {
    if (MM % 10 > 0) {
      if (fps === 29.97) {
        if (FF === 0) {
          FF = 29;
        } else if (FF === 1) {
          FF = 2;
        }
      }
      if (fps === 59.94) {
        if (FF === 0 || FF === 1) {
          FF = 59;
        } else if (FF === 2 || FF === 3) {
          FF = 4;
        }
      }
    }
    return FF;
  }

  function adjustTotalSecondsToShowDueToDropping(
    FF: number,
    MM: number,
    seconds: number,
    fps: number
  ) {
    if (MM % 10 > 0) {
      if (fps === 29.97) {
        if (FF === 0) {
          --seconds;
        }
      }
      if (fps === 59.94) {
        if (FF === 0 || FF === 1) {
          --seconds;
        }
      }
    }
    return seconds;
  }

  return function (_seconds: any, fps = 1, separator = ':') {
    if (isNaN(Number(_seconds)) || _seconds < 0) {
      return '00:00:00:00';
    }

    const hours = Math.floor(_seconds / 3600);
    const minutes = Math.floor((_seconds % 3600) / 60);
    let seconds = Math.floor(_seconds % 60);
    let ff = Math.floor((_seconds % 1) * fps);

    if (fps === 29.97 || fps === 59.94) {
      seconds = adjustTotalSecondsToShowDueToDropping(ff, minutes, seconds, fps);
      ff = adjustFramesToShowDueToDropping(ff, minutes, fps);
    }

    const hoursTime = hours > 0 ? hours : '00';
    return hoursTime + ':' + padTime(minutes) + ':' + padTime(seconds) + separator + padTime(ff);
  };
}

export function getMediaTimeCode(media: TMedia) {
  const mediaTimecode: { time: string; frames: string } = { time: '00:00:00', frames: '00' };
  if (media.userDefinedTimeCode && media.userDefinedTimeCodeFrames) {
    if (media.userDefinedTimeCode) {
      const momObj = moment.duration(media.userDefinedTimeCode);
      let hours = momObj.hours();
      if (momObj.days()) {
        hours += 24 * momObj.days();
      }
      mediaTimecode.time = hours + ':' + momObj.minutes() + ':' + momObj.seconds();
    }
    if (media.userDefinedTimeCodeFrames) {
      mediaTimecode.frames = String(media.userDefinedTimeCodeFrames);
    }
  } else if (media.timeCode) {
    mediaTimecode.time = media.timeCode.substring(0, 8);
    mediaTimecode.frames = media.timeCode.substring(9, 11);
  }
  if (!mediaTimecode.time) {
    mediaTimecode.time = '00:00:00';
  }
  if (!mediaTimecode.frames) {
    mediaTimecode.frames = '00';
  }

  return mediaTimecode;
}

export function validateFrameRate({
  fps,
  dropFrame,
  minuteInput,
  frameInput,
  secondInput
}: {
  fps: number;
  dropFrame: boolean;
  minuteInput: number;
  frameInput: number;
  secondInput: number;
}) {
  if (fps === 29.97 && dropFrame) {
    const invalidFrame = frameInput === 0 || frameInput === 1;
    const invalid = minuteInput % 10 !== 0 && secondInput === 0 && invalidFrame;
    return !invalid;
  }
  if (fps === 59.94 && dropFrame) {
    const invalidFrame =
      frameInput === 0 || frameInput === 1 || frameInput === 2 || frameInput === 3;
    const invalid = minuteInput % 10 !== 0 && secondInput === 0 && invalidFrame;

    return !invalid;
  }
  return true;
}
