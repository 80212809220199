import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaUserCircle } from 'react-icons/fa';
import { updateFolderSelected } from 'redux/folder/folder.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { searchProjectListWithFolder } from 'redux/projects/projects.slice';
import { EFolderIdDefault } from 'ts/enums';
import { TFolder } from 'ts/types';
import { separateFolders } from 'utils/common';

interface IBreadcrumbItem {
    label: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string,
    folderId: string;
}

interface IProps {

}

function Breadcrumb(props: IProps) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const currentFolderSelected = useAppSelector((state) => state.folder.currentFolderSelected);
    const listFolders = useAppSelector((state) => state.folder.listFolders);

    const folderStack: IBreadcrumbItem[] = [];
    const newListFolders: TFolder[] = separateFolders(JSON.parse(JSON.stringify(listFolders)) as TFolder[]);
    
    switch(currentFolderSelected) {
        case EFolderIdDefault.PROJECT_ALL:
            folderStack.push({
                label: t('Pages.Main.Texts.Projects'),
                folderId: EFolderIdDefault.PROJECT_ALL
            });
            break;
        case EFolderIdDefault.MY_PROJECT:
            folderStack.push({
                label: t('Pages.Main.Texts.MyProjects'),
                folderId: EFolderIdDefault.MY_PROJECT
            });
            break;
        case EFolderIdDefault.SHARED_WITH_ME:
            folderStack.push({
                label: t('Pages.Main.Texts.SharedWithMe'),
                folderId: EFolderIdDefault.SHARED_WITH_ME
            });
            break;
        default: {// Regular folder
            // Root
            folderStack.push({
                label: t('Pages.Main.Texts.MyProjects'),
                folderId: EFolderIdDefault.MY_PROJECT
            });

            // Then regular folder
            let folder = newListFolders.find((f) => f.id == currentFolderSelected)
            if(folder) {
                if(folder?.encodedParentId) {
                    let parentFolder = newListFolders.find((f) => folder?.encodedParentId == f.id)
                    if(parentFolder) folderStack.push({
                        label: parentFolder.name,
                        folderId: parentFolder.id
                    })
                }

                folderStack.push({
                    label: folder.name,
                    folderId: folder.id
                })
            }
        }
    }

    const onClickFolder = (folderId: string) => {
        dispatch(updateFolderSelected({ folderId: folderId }));
        dispatch(searchProjectListWithFolder({ folderID: folderId }));
    }

    return (
        <p className='breadcrumb'>
            { folderStack.map((f, i) => {
                return <span key={f.folderId}>{i !== 0 ? <span className='mx-1'>&#47;</span>: ''}<span className={`breadcrumb-item ${i == folderStack.length - 1 ? 'breadcrumb-item--active' : ''}`} onClick={onClickFolder.bind(null, folderStack[i].folderId)}>{ folderStack[i].label }</span></span>
            }) }
        </p>
    )
}

export default React.memo(Breadcrumb)