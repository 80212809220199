import React from 'react';
import getBookmarkButton from './bookmarkBtn';
import getUpperCaseButton from './upperCaseBtn';
import getLowerCaseButton from './lowerCaseBtn';
import getSentenceCaseButton from './sentenceCaseBtn';
import getStrikeThroughButton from './strikeThroughBtn';
import getBoldButton from './boldBtn';
import getItalicButton from './italicBtn';
import getDictionaryButton from './dictionaryBtn';
import { EStyle } from 'ts/enums';
import { ToolbarChildrenProps } from '@draft-js-plugins/inline-toolbar/lib/components/Toolbar';

const mapComponent: any = {
  [EStyle.HIGHLIGHT]: getBookmarkButton,
  [EStyle.STRIKETHROUGH]: getStrikeThroughButton,
  [EStyle.SENTENCECASE]: getSentenceCaseButton,
  [EStyle.UPPERCASE]: getUpperCaseButton,
  [EStyle.LOWERCASE]: getLowerCaseButton,
  [EStyle.BOLD]: getBoldButton,
  [EStyle.ITALIC]: getItalicButton,
  [EStyle.DICTIONARY]: getDictionaryButton
};

const getInlineToolbarComps = ({
  keyComp,
  props,
  onClick
}: {
  keyComp: string;
  props: ToolbarChildrenProps;
  onClick: () => void;
}) => {
  const editorState = props.getEditorState();
  const inlineStyle = editorState.getCurrentInlineStyle();
  const DynamicComponent = mapComponent[keyComp](inlineStyle.has(keyComp));

  return (
    (DynamicComponent && (
      <span key={`DynamicComponent-${keyComp}`} onClick={onClick}>
        <DynamicComponent {...props} />
      </span>
    )) ||
    null
  );
};

export { getInlineToolbarComps };
