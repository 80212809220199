import { TDictionary, TDirectory } from 'ts/types';
import { TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from './axios-client';
import { TRequestCreateDictionary, TRequestUpdateDictionary } from 'ts/types/TRequest';

export async function getDictionary(): Promise<TResponse<TDirectory>> {
  const res = await AxiosCustom.get(`dictionary`);
  return res.data;
}

export async function createDictionary({
  dictionary
}: {
  dictionary: TRequestCreateDictionary;
}): Promise<TResponse<TDirectory>> {
  const res = await AxiosCustom.post(`dictionary`, dictionary);
  return res.data;
}

export async function deleteDictionary({
  dictionary
}: {
  dictionary: TDictionary;
}): Promise<TResponse<TDirectory>> {
  const res = await AxiosCustom.delete(`dictionary/${dictionary.id}`);
  return dictionary;
}

export async function updateDictionary({
  dictionary
}: {
  dictionary: TRequestUpdateDictionary;
}): Promise<TResponse<TDirectory>> {
  const res = await AxiosCustom.post(`dictionary`, dictionary);
  return res.data;
}
