import React from 'react';
import { createInlineStyleButton } from '@draft-js-plugins/buttons';
import { EStyle } from 'ts/enums';

const styleDefault = createInlineStyleButton({
  style: EStyle.ITALIC,
  children: (
    <svg
      width="14"
      height="14"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="italic"
      className="svg-inline--fa fa-italic fa-w-10"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill="currentColor"
        d="M320 48v32a16 16 0 0 1-16 16h-62.76l-80 320H208a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H16a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h62.76l80-320H112a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h192a16 16 0 0 1 16 16z"
      />
    </svg>
  )
});

export default function (active: boolean) {
  // if(active) return styleActive
  return styleDefault;
}
