/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useState } from 'react';

interface EditorContextType {
  isReadOnly: boolean;
  setIsReadonly: (e: boolean) => void;
  isNoneEdit: boolean;
  setIsNoneEdit: (e: boolean) => void;
  isLock: boolean;
  setIsLock: (e: boolean) => void;
}

const EditorContext = createContext<EditorContextType>({
  isReadOnly: false,
  setIsReadonly: (e: boolean) => e,
  isNoneEdit: false,
  setIsNoneEdit: (e: boolean) => e,
  isLock: false,
  setIsLock: (e: boolean) => e
});

export function EditorProvider({ children }: { children: React.ReactNode }) {
  const [isReadOnly, setIsReadonly] = useState<boolean>(false);
  const [isNoneEdit, setIsNoneEdit] = useState<boolean>(false);
  const [isLock, setIsLock] = useState<boolean>(false);

  const value = {
    isReadOnly,
    setIsReadonly: (value: boolean) => {
      setIsReadonly(value);
    },
    isNoneEdit,
    setIsNoneEdit: (value: boolean) => {
      setIsNoneEdit(value);
    },
    isLock,
    setIsLock: (value: boolean) => {
      setIsLock(value);
    }
  };

  return <EditorContext.Provider value={value}>{children}</EditorContext.Provider>;
}

export function useEditorContext() {
  return useContext(EditorContext);
}
