import { Form, InputRef, Popconfirm, Table } from 'antd';
import {
  deleteCommentPublishedProject,
  editLastestCommentPublishedProject,
  getLastestCommentPublishedProject,
  getLastestPublishedProject
} from 'apis/admin/published-project.api';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import type { FormInstance } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import { usePageLoading } from 'context/PageLoadingContext';
import { useLoaderContext } from 'context/LoaderContext';
import { async } from 'q';

type projectType = {
  name: string;
  ownerId: string;
  id: string;
};

type DataSourceProjectType = {
  date: string;
  projectName: projectType;
  projectOwner: string;
  email: string;
  projectId: string;
};

type DataSourceCommentType = {
  comment: string | null;
  project: projectType;
  projectOwner: string;
  file: string;
  adminNotes: string;
  transcriptionId: number;
  key: number;
  mediaId: string;
  projectOwnerEmail: string;
  mediaName: string;
};

type Item = {
  comment: string | null;
  project: projectType;
  projectOwner: string;
  file: string;
  adminNotes: string;
  transcriptionId: number;
  key: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;
  const { loader } = useLoaderContext();

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (form && record && dataIndex) form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  }, [record, form, dataIndex]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async () => {
    try {
      loader.start();
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
      loader.complete();
    } catch (errInfo) {
      console.error('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = (
      <div onClick={toggleEdit}>
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <TextArea
            readOnly={!editing}
            ref={inputRef}
            onPressEnter={() => inputRef.current?.blur()}
            onBlur={save}
            placeholder="Notes"
          />
        </Form.Item>
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

function LastestPublishedProjectPage() {
  const [dataSourceProject, setDataSourceProject] = useState<DataSourceProjectType[]>([]);
  const [dataSourceComment, setDataSourceComment] = useState<DataSourceCommentType[]>([]);
  const [isLoadingProject, setIsLoadingProject] = useState<boolean>(false);
  const [isLoadingComment, setIsLoadingComment] = useState<boolean>(false);
  const [totalProject, setTotalProject] = useState<number>(0);
  const [currentPageProject, setCurrentPageProject] = useState<number>(1);
  const [totalAnnotation, setTotalAnnotation] = useState<number>(0);
  const [currentPageAnnotaion, setCurrentPageAnnotation] = useState<number>(1);

  const { openMessage } = usePageLoading();
  const { loader } = useLoaderContext();

  function deleteComment(transcriptionId: number) {
    const newDataComment = dataSourceComment.filter((f) => f.transcriptionId !== transcriptionId);
    setDataSourceComment(newDataComment);
    deleteCommentPublishedProject({ transcriptionId });
  }

  function processString(input: string): string {
    const trimmedString = input.trim().toLowerCase();
    const words = trimmedString.split(/\s+/);

    const processedWords = words
      .map((word) => (word.includes('-') ? word : word.replace(/-/g, '')))
      .filter(Boolean);

    return processedWords.join('-');
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      width: '20%'
    },
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      width: '20%',
      render(project: projectType) {
        return {
          children: (
            <Link
              to={`${process.env.REACT_APP_PUBLIC_PAGE}/${project.ownerId}/${processString(
                project.name
              )}/${project.id}`}
              target="_blank"
            >
              {project.name}
            </Link>
          )
        };
      }
    },
    {
      title: 'Project Owner',
      dataIndex: 'projectOwner',
      key: 'projectOwner',
      width: '10%'
    },
    {
      title: 'File',
      dataIndex: 'file',
      key: 'file',
      width: '20%'
    },
    {
      title: 'Admin Notes',
      dataIndex: 'adminNotes',
      key: 'adminNotes',
      width: '20%',
      editable: false
    },
    {
      title: 'Action',
      dataIndex: 'transcriptionId',
      key: 'action',
      width: '10%',
      render: (record: number) => {
        return (
          <Popconfirm
            title={'Are you sure you want to delete this comment?'}
            onConfirm={() => deleteComment(record)}
            placement="left"
            disabled
          >
            <div>
              <RiDeleteBin5Line color="var(--si-primary)" className="cursor-disabled" />
            </div>
          </Popconfirm>
        );
      }
    }
  ];

  const handleSave = (row: DataSourceCommentType) => {
    const newData = [...dataSourceComment];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    if (dataSourceComment[index].adminNotes !== newData[index].adminNotes) {
      editLastestCommentPublishedProject({
        comment: {
          annotationAdminComment: row.adminNotes,
          content: row.comment,
          lastUpdateIsoUtcDate: null,
          lastUpdatePerson: null,
          mediaId: row.mediaId,
          mediaName: row.mediaName,
          projectId: row.project.id,
          projectName: row.project.name,
          projectOwnerEmail: row.projectOwnerEmail,
          projectOwnerId: row.project.ownerId,
          projectOwnerName: row.projectOwner,
          transcriptionId: row.transcriptionId
        }
      });
      setDataSourceComment(newData);
    }
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataSourceCommentType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    };
  });

  const columnsTableProject = [
    {
      title: 'Created Date',
      dataIndex: 'date',
      key: 'date',
      width: '10%'
    },
    {
      title: 'Project',
      dataIndex: 'projectName',
      key: 'projectName',
      width: '40%',
      render(project: projectType) {
        return {
          children: (
            <Link
              to={`${process.env.REACT_APP_PUBLIC_PAGE}/${project.ownerId}/${processString(
                project.name
              )}/${project.id}`}
              target="_blank"
            >
              {project.name}
            </Link>
          )
        };
      }
    },
    {
      title: 'Project Owner',
      dataIndex: 'projectOwner',
      key: 'projectOwner',
      width: '25%'
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      width: '25%'
    }
  ];

  const handleOnTableProjectSizeChange = async (page: number) => {
    setIsLoadingProject(true);
    setCurrentPageProject(page);
    loader.start();
    const res = await getLastestPublishedProject({ pageNumber: page - 1 });
    if (res) {
      setDataSourceProject(
        res.data.map((project) => {
          return {
            date: moment(project.creationDate).format('MM/DD/YYYY'),
            projectName: { name: project.name, ownerId: project.owner.id, id: project.id },
            projectOwner: project.owner.fullName,
            email: project.owner.email,
            projectId: project.id
          };
        })
      );
      loader.complete();
    } else {
      openMessage('error', 'Error load latest publish project');
      setIsLoadingProject(false);
    }
    setIsLoadingProject(false);
  };

  const handleOnTableAnnotaionSizeChange = async (page: number) => {
    loader.start();
    // setIsLoadingComment(true);
    setCurrentPageAnnotation(page);
    // const res = await getLastestCommentPublishedProject({});
    // if (res) {
    //   setDataSourceComment(
    //     res.map((comment) => {
    //       return {
    //         comment: comment.content,
    //         project: {
    //           name: comment.projectName,
    //           ownerId: comment.projectOwnerId,
    //           id: comment.projectId
    //         },
    //         projectOwner: comment.projectOwnerName,
    //         file: comment.mediaName,
    //         adminNotes: comment.annotationAdminComment,
    //         transcriptionId: comment.transcriptionId,
    //         key: comment.transcriptionId,
    //         mediaId: comment.mediaId,
    //         projectOwnerEmail: comment.projectOwnerEmail,
    //         mediaName: comment.mediaName
    //       };
    //     })
    //   );
    // } else {
    //   openMessage('error', 'Error load latest comment');
    //   setIsLoadingComment(false);
    // }
    // loader.complete();
    // setIsLoadingComment(false);
  };

  useEffect(() => {
    (async () => {
      setIsLoadingProject(true);
      loader.start();
      const res = await getLastestPublishedProject({});
      if (res) {
        setDataSourceProject(
          res.data.map((project) => {
            return {
              date: moment(project.creationDate).format('MM/DD/YYYY'),
              projectName: { name: project.name, ownerId: project.owner.id, id: project.id },
              projectOwner: project.owner.fullName,
              email: project.owner.email,
              projectId: project.id
            };
          })
        );
        setTotalProject(res.total);
        loader.complete();
      } else {
        openMessage('error', 'Error load latest publish project');
        setIsLoadingProject(false);
      }
      setIsLoadingProject(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoadingComment(true);
      loader.start();
      const res = await getLastestCommentPublishedProject({});
      if (res) {
        setDataSourceComment(
          res.map((comment) => {
            return {
              comment: comment.content,
              project: {
                name: comment.projectName,
                ownerId: comment.projectOwnerId,
                id: comment.projectId
              },
              projectOwner: comment.projectOwnerName,
              file: comment.mediaName,
              adminNotes: comment.annotationAdminComment,
              transcriptionId: comment.transcriptionId,
              key: comment.transcriptionId,
              mediaId: comment.mediaId,
              projectOwnerEmail: comment.projectOwnerEmail,
              mediaName: comment.mediaName
            };
          })
        );
      } else {
        openMessage('error', 'Error load latest comment');
        setIsLoadingComment(false);
      }
      loader.complete();
      setIsLoadingComment(false);
    })();
  }, []);

  return (
    <div>
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome size={30} color="var(--si-primary)" className="cursor-pointer" />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            Published Projects
          </span>
        </div>
      </div>
      <div className="container py-2">
        <div className="fs-4">Latest published projects</div>
        <Table
          loading={isLoadingProject}
          className="mt-2 table-lastest-project"
          dataSource={dataSourceProject}
          columns={columnsTableProject}
          rowKey={'projectId'}
          scroll={{ x: 1295 }}
          tableLayout="fixed"
          pagination={{
            current: currentPageProject,
            pageSize: 25,
            total: totalProject,
            showSizeChanger: false,
            position: ['bottomCenter'],
            onChange: (page: number) => handleOnTableProjectSizeChange(page)
          }}
        />
        <div className="fs-4 mt-4">Latest comments in published projects</div>
        <Table
          loading={isLoadingComment}
          className="mt-2 table-comment"
          dataSource={dataSourceComment}
          columns={columns as ColumnTypes}
          rowKey={'transcriptionId'}
          components={components}
          scroll={{ x: 1295 }}
          tableLayout="fixed"
          pagination={{
            current: currentPageAnnotaion,
            pageSize: 25,
            total: dataSourceComment.length,
            showSizeChanger: false,
            position: ['bottomCenter'],
            onChange: (page: number) => handleOnTableAnnotaionSizeChange(page)
          }}
        />
      </div>
    </div>
  );
}

export default LastestPublishedProjectPage;
