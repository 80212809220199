import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderItem } from './FolderItem';
import { FaUserCircle } from 'react-icons/fa';
import { useAppSelector } from 'redux/hooks';
import MyProjectIcon from 'assets/icons/myprojects-icon.svg';
import ProjectIcon from 'assets/icons/projects-icon.svg';
import { EFolderSortOption } from 'ts/enums';
import { TFolder } from 'ts/types';
import { combineFolders, separateFolders } from 'utils/common';

export function ListFolders() {
  const { t } = useTranslation();
  const listFolders = useAppSelector((state) => state.folder.listFolders);
  const listFolderDefault = useAppSelector((state) => state.folder.listFolderDefault);

  const currentFolderSelected = useAppSelector((state) => state.folder.currentFolderSelected);
  const isDrop =
    currentFolderSelected !== listFolderDefault[2].id ||
    currentFolderSelected !== listFolderDefault[0].id;

  const listMyProject = useAppSelector((state) => state.projects.listMyProject);
  const listProjectAll = useAppSelector((state) => state.projects.listProjectAll);
  const listProjectShareWithMe = useAppSelector((state) => state.projects.listProjectShareWithMe);

  // Folder Sort
  const [folderSortBy, setFolderSortBy] = useState<EFolderSortOption>(EFolderSortOption.DATE);
  const [folderSortAscending, setFolderSortAscending] = useState<boolean>(true);

  const onClickSort = (sortBy: EFolderSortOption) => {
    if (sortBy != folderSortBy) {
      // Change sort type
      setFolderSortBy(sortBy);
    } else {
      // Change sort direction
      setFolderSortAscending(!folderSortAscending);
    }
  };

  var sortedListFolders: TFolder[] = JSON.parse(JSON.stringify(listFolders)) as TFolder[];
  var separateSortedListFolders = separateFolders(sortedListFolders);

  switch (folderSortBy) {
    case EFolderSortOption.NAME:
      if (folderSortAscending)
        separateSortedListFolders.sort((a, b) => {
          if (a.name < b.name) return -1;
          else if (a.name > b.name) return 1;
          return 0;
        });
      else
        separateSortedListFolders.sort((a, b) => {
          if (a.name > b.name) return -1;
          else if (a.name < b.name) return 1;
          return 0;
        });
      break;
    case EFolderSortOption.DATE:
      if (folderSortAscending)
        separateSortedListFolders.sort((a, b) => {
          if (a.creationDate < b.creationDate) return -1;
          else if (a.creationDate > b.creationDate) return 1;
          return 0;
        });
      else
        separateSortedListFolders.sort((a, b) => {
          if (a.creationDate > b.creationDate) return -1;
          else if (a.creationDate < b.creationDate) return 1;
          return 0;
        });
      break;
  }

  return (
    <div className="list-folders">
      <FolderItem
        folder={listFolderDefault[0]}
        startIcon={<img src={MyProjectIcon} />}
        isDrop={false}
        count={listMyProject.data.length}
        disableHover
        overrideName={t('Pages.Main.Texts.MyProjects')}
      />
      <FolderItem
        folder={listFolderDefault[1]}
        startIcon={<FaUserCircle size={28} />}
        isDrop={false}
        count={listProjectShareWithMe.data.length}
        disableHover
        overrideName={t('Pages.Main.Texts.SharedWithMe')}
      />
      <FolderItem
        folder={listFolderDefault[2]}
        startIcon={<img src={ProjectIcon} />}
        isDrop={isDrop}
        count={listProjectAll.data.length}
        disableHover
        overrideName={t('Pages.Main.Texts.Projects')}
      />
      <p className="d-none d-lg-block folder-sort ms-2 mt-2 mb-1">
        {t('Shared.Fields.FolderSort')}:{' '}
        <span
          onClick={onClickSort.bind(null, EFolderSortOption.NAME)}
          className={`option ${folderSortBy == EFolderSortOption.NAME ? 'option--selected' : ''}`}
        >
          {t('Shared.Fields.Name')}{' '}
          {folderSortBy == EFolderSortOption.NAME ? (
            folderSortAscending ? (
              <span>&#8595;</span>
            ) : (
              <span>&#8593;</span>
            )
          ) : null}
        </span>{' '}
        |{' '}
        <span
          onClick={onClickSort.bind(null, EFolderSortOption.DATE)}
          className={`option ${folderSortBy == EFolderSortOption.DATE ? 'option--selected' : ''}`}
        >
          {t('Shared.Fields.Date')}{' '}
          {folderSortBy == EFolderSortOption.DATE ? (
            folderSortAscending ? (
              <span>&#8595;</span>
            ) : (
              <span>&#8593;</span>
            )
          ) : null}
        </span>
      </p>
      {separateSortedListFolders.length > 0 &&
        combineFolders(separateSortedListFolders).map((folder) => (
          <FolderItem folder={folder} key={folder.id} count={folder.totalProjectCount} />
        ))}
    </div>
  );
}
