import React from 'react';
import { EAccountSettingId } from 'ts/enums';
import { BasicSection } from './BasicSection';
import { useAppSelector } from 'redux/hooks';
import { useTranslation } from 'react-i18next';

export function EarnFreeCreditSection() {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const hostname = window.location.origin;
  const { t } = useTranslation();

  return (
    <BasicSection
      className="earn-free-credit-section mb-9"
      header={t('Pages.AccountSetting.Invitation.SubTitle')}
      id={EAccountSettingId.INVITE_LINK_FREE_CREDIT}
    >
      <p className="mb-2">
        { t('Pages.AccountSetting.Invitation.Description') }
      </p>
      <p className="mb-2">{ t('Pages.AccountSetting.Invitation.UseLinkBelow') }</p>
      <article>
        <input
          className="input-invite-cde"
          type="text"
          readOnly
          value={`${hostname}/signup?inviteCode=${currentUser.data?.inviteCode}`}
          onFocus={(e) => {
            e.preventDefault();
            const target = e.target;
            setTimeout(() => target.setSelectionRange(0, target.value.length), 0);
          }}
        />
      </article>
    </BasicSection>
  );
}
