import { deleteUser } from 'apis/auth.api';
import { ModalBase } from 'components/common';
import { useAuth } from 'context/AuthContext';
import { useTranslation } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';
import { PiWarningBold } from 'react-icons/pi';
import { useAppDispatch } from 'redux/hooks';
import { popModal } from 'redux/modal/modal.slice';
import { EModals } from 'ts/enums';

export function LastConfirmDeleteAccountModal() {
  const dispatch = useAppDispatch();
  const { logout } = useAuth();
  const { t } = useTranslation();

  function closeLastConfirmModal() {
    dispatch(popModal());
  }

  function handleDeleteAccount() {
    (async () => {
      await deleteUser();
      logout();
      closeLastConfirmModal();
    })();
  }

  return (
    <ModalBase
      modalName={EModals.LAST_CONFIRM_DELETE_ACCOUNT}
      onCloseModal={() => {
        dispatch(popModal());
      }}
      isCloseIcon={false}
      className="confirm-delete-account"
    >
      <div className="delete-content">
        <div className="row gx-1">
          <div className="icon col-2 d-flex align-items-center justify-content-center">
            <PiWarningBold size={20} />
          </div>
          <div className="text col-9">
            { t('Pages.AccountSetting.CloseAccount.FinalConfirm') }
          </div>
          <div className="close-icon-custom col-1 text-end">
            <IoMdClose size={20} className="cursor-pointer" onClick={() => dispatch(popModal())} />
          </div>
        </div>
      </div>
      <div className="group-action-button mt-2 d-flex align-items-center justify-content-center gap-2">
        <button className="btn-no px-2 py-1 justify-content-center" onClick={closeLastConfirmModal}>
        { t('Shared.Texts.No') }
        </button>
        <button className="btn-yes px-2 py-1 justify-content-center" onClick={handleDeleteAccount}>
        { t('Shared.Texts.Yes') }
        </button>
      </div>
    </ModalBase>
  );
}
