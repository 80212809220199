import React from 'react';
import { EAccountSettingId, EModals, EPlanOption, ERole } from 'ts/enums';
import { BasicSection } from './BasicSection';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { pushModal } from 'redux/modal/modal.slice';
import { LastConfirmDeleteAccountModal } from './LastConfirmModalDeleteAccount';
import { ConfirmDeleteAccountModal } from './ConfirmDeleteAccountModal';
import { useTranslation } from 'react-i18next';

export function CloseAccountSection() {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.auth.currentUser.data);
  const subscriptionDetail = useAppSelector((state) => state.auth.subcriptionDetail);
  const { t } = useTranslation();

  function openConfirmDeleteAccountModal() {
    dispatch(pushModal({ name: EModals.CONFIRM_DELETE_ACCOUNT, data: null }));
  }
  function isUserSubscriptionActive() {
    if (!subscriptionDetail) return false;
    return !subscriptionDetail.cancelAtPeriodEnd && subscriptionDetail.status !== 'canceled';
  }

  return (
    <BasicSection
      className="close-account-section mb-13"
      header={t('Pages.AccountSetting.CloseAccount.SubTitle')}
      id={EAccountSettingId.CLOSE_ACCOUNT}
    >
      <ConfirmDeleteAccountModal />
      <LastConfirmDeleteAccountModal />
      <div className="text mb-2">{t('Pages.AccountSetting.CloseAccount.Description')}</div>
      <button
        className={`btn-delete-account mb-2 ${
          (userData && userData.role.name !== ERole.ROLE_USER) ||
          (userData && userData.plan.planEnum !== EPlanOption.PAY_AS_YOU_GO)
            ? 'btn-delete-account-disabled'
            : 'btn-delete-account-enabled'
        }`}
        onClick={openConfirmDeleteAccountModal}
        disabled={isUserSubscriptionActive()}
      >
        {t('Pages.AccountSetting.CloseAccount.CloseConfirmYes')}
      </button>
      <div className="sub-text">{t('Pages.AccountSetting.CloseAccount.Tip')}</div>
    </BasicSection>
  );
}
