import { Progress, Tooltip } from 'antd';
import { useVideoPlayerContext } from 'context/VideoPlayerContext';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { BsCameraVideoFill, BsFillPlayFill, BsPauseFill } from 'react-icons/bs';
import { GoUnmute, GoMute } from 'react-icons/go';
import { useAppDispatch } from 'redux/hooks';
import { MiniHeader } from './MiniHeader';
import LanguageControl from './LanguageControl';
import { useHotkeys } from 'react-hotkeys-hook';
import { BiSolidLeftArrow, BiSolidRightArrow } from 'react-icons/bi';
import {
  handleNextTranscript,
  handlePrevTranscript
} from 'redux/transcription/transcription.slice';
import { EDelayTime } from 'ts/enums';
import SpeakerBox from './SpeakerBox';
import { FaBookmark } from 'react-icons/fa';

const PlayBackRate = [0.5, 0.75, 1, 1.25, 1.5, 2];

function MiniControlPlayer() {
  const {
    isPlaying,
    volume,
    setVolume,
    setIsPlaying,
    setIsVisible,
    isVisible,
    setShowPreview,
    showPreview,
    playBackRate,
    setPlayBackRate,
    isAudio
  } = useVideoPlayerContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  function handlePlayPause() {
    if (showPreview) {
      setShowPreview(false);
    }
    setIsPlaying(!isPlaying);
  }

  function handleVisiblePlayer() {
    setIsVisible(!isVisible);
  }

  // function handleNext() {
  //   if (!currentTranscription.data) return;
  //   let findCurrentItemIdx = -1;
  //   if (idTranscriptRowPlaying != null) {
  //     findCurrentItemIdx = currentTranscription.data.findIndex(
  //       (transcript) => transcript.id === idTranscriptRowPlaying
  //     );
  //   }
  //   currentTranscription.data.find((transcript: TTranscript, index: number) => {
  //     if (transcript.bookmark && index > findCurrentItemIdx) {
  //       const wordsJson = JSON.parse(transcript.wordsJson) as TWord[];
  //       if (wordsJson.length > 0) {
  //         const startTime = Number(wordsJson[0].startTime);
  //         setShowPreview(false);
  //         seekTo(startTime, 'seconds');
  //         return true;
  //       }
  //     }
  //     return false;
  //   });
  // }

  function handleNext(onlyWithBookmark: boolean) {
    dispatch(handleNextTranscript({ isNext: true, isOnlyBookmark: onlyWithBookmark }));
  }

  // function handlePrev() {
  //   if (!currentTranscription.data) return;
  //   let findCurrentItemIdx = currentTranscription.data?.findIndex(
  //     (transcript) => transcript.id === idTranscriptRowPlaying
  //   );
  //   if (findCurrentItemIdx > 0) {
  //     let findNextItem = currentTranscription.data[--findCurrentItemIdx];
  //     while (findNextItem.bookmark !== true && findCurrentItemIdx >= 0) {
  //       findNextItem = currentTranscription.data[findCurrentItemIdx--];
  //     }
  //     if (findNextItem.bookmark) {
  //       const wordsJson = JSON.parse(findNextItem.wordsJson) as TWord[];
  //       if (wordsJson.length > 0) {
  //         const startTime = Number(wordsJson[0].startTime);
  //         seekTo(startTime, 'seconds');
  //       }
  //     }
  //   }
  // }

  function handlePrev(onlyWithBookmark: boolean) {
    dispatch(handlePrevTranscript({ isPrev: true, isOnlyBookmark: onlyWithBookmark }));
  }

  useHotkeys('ctrl+ArrowUp, Meta+ArrowUp', (e) => {
    e.preventDefault(), handlePrev(false);
  });
  useHotkeys('ctrl+ArrowDown, Meta+ArrowDown', (e) => {
    e.preventDefault(), handleNext(false);
  });

  function handleChangePlayBackRate() {
    const findNextIdx = PlayBackRate.findIndex((rate) => rate === playBackRate) + 1;
    const nextRate = PlayBackRate[findNextIdx];
    if (!nextRate) {
      setPlayBackRate(PlayBackRate[0]); //default;
      return;
    }
    setPlayBackRate(nextRate);
  }

  return (
    <div className="d-none d-sm-flex flex-column gap-1" style={{ width: 70 }}>
      <MiniHeader />
      <div className="ctn-mini-control-player p-1 border d-flex flex-column gap-1 rounded align-items-center justify-content-between border">
        <Tooltip
          placement="right"
          mouseEnterDelay={EDelayTime.FAST}
          trigger={'hover'}
          title={
            isPlaying
              ? t('Pages.TranscriptDetail.VideoPlayer.TooltipPause')
              : t('Pages.TranscriptDetail.VideoPlayer.TooltipPlay')
          }
          arrow={false}
          overlayClassName="custom-toolstip"
        >
          <button onClick={handlePlayPause} className="hover-state --play">
            {isPlaying ? <BsPauseFill size={24} /> : <BsFillPlayFill size={24} />}
          </button>
        </Tooltip>
        <Tooltip
          placement="right"
          trigger={'hover'}
          mouseEnterDelay={EDelayTime.FAST}
          title="Volume"
          arrow={false}
          overlayClassName="custom-toolstip"
        >
          <button
            className="hover-state"
            onClick={() => {
              switch (Math.floor(volume / 33)) {
                case 0:
                  setVolume(100 / 3);
                  break;
                case 1:
                  setVolume((100 / 3) * 2);
                  break;
                case 2:
                  setVolume(100);
                  break;
                default:
                  setVolume(0);
                  break;
              }
            }}
          >
            <Progress
              type="circle"
              size={32}
              percent={volume}
              strokeColor="#7e56a2"
              format={() =>
                volume ? (
                  <GoUnmute size={18} color="var(--si-primary)" />
                ) : (
                  <GoMute color="var(--si-primary)" size={18} />
                )
              }
            />
          </button>
        </Tooltip>
        <div className="d-flex justify-content-center">
          <Tooltip
            placement="left"
            trigger={'hover'}
            mouseEnterDelay={EDelayTime.FAST}
            title={t('Pages.TranscriptDetail.VideoPlayer.TooltipPreviousBookmark')}
            overlayClassName="custom-toolstip"
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                handlePrev(true);
              }}
              className="hover-state --prev px-0"
            >
              <BiSolidLeftArrow size={14} />
            </button>
          </Tooltip>
          
            <button
              onClick={(e) => {}}
              className=""
            >
              <FaBookmark size={14} />
            </button>
          <Tooltip
            placement="right"
            trigger={'hover'}
            mouseEnterDelay={EDelayTime.FAST}
            title={t('Pages.TranscriptDetail.VideoPlayer.TooltipNextBookmark')}
            overlayClassName="custom-toolstip"
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleNext(true);
              }}
              className="hover-state --next px-0"
            >
              <BiSolidRightArrow size={14} />
            </button>
          </Tooltip>
        </div>

        {!isAudio && (
          <Tooltip
            placement="right"
            trigger={'hover'}
            mouseEnterDelay={EDelayTime.FAST}
            title={t('Pages.TranscriptDetail.VideoPlayer.TooltipShowHideVideo')}
            arrow={false}
            overlayClassName="custom-toolstip"
          >
            <button
              onClick={handleVisiblePlayer}
              className={`d-none d-md-block hover-state ${isVisible ? '--active' : ''}`}
            >
              <BsCameraVideoFill size={24} />
            </button>
          </Tooltip>
        )}
        <Tooltip
          placement="right"
          trigger={'hover'}
          mouseEnterDelay={EDelayTime.FAST}
          title={t('Pages.TranscriptDetail.VideoPlayer.TooltipPlaybackSpeed')}
          arrow={false}
          overlayClassName="custom-toolstip"
        >
          <button onClick={handleChangePlayBackRate} className="hover-state">
            {playBackRate}x
          </button>
        </Tooltip>
      </div>
      <SpeakerBox/>
      <LanguageControl/>
    </div>
  );
}

export default MiniControlPlayer;
