import React, { ReactNode } from 'react';
import { EAccountSettingId } from 'ts/enums';

type Props = {
  children: ReactNode;
  header: string;
  id: EAccountSettingId;
  className?: string;
};
// important: only custom for use in AccountSettingPage
export function BasicSection({ children, header, id, className }: Props) {
  return (
    <section className={`basic-section ${className}`} id={id}>
      <div className="header-section">{header}</div>
      <div className="body-section">{children}</div>
    </section>
  );
}
