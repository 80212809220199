import { useAuth0 } from '@auth0/auth0-react';
import { Button, Form, Input } from 'antd';
import { setNewEmail } from 'apis/userSetting.api';
import { ModalBase } from 'components/common';
import { useAuth } from 'context/AuthContext';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { popModal, pushModal } from 'redux/modal/modal.slice';
import { EModals, ETypeNoti } from 'ts/enums';

export default function ChangeEmailModal() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { logout } = useAuth();
  const { t } = useTranslation();

  async function handleChangePassword(values: { email: string; password: string }) {
    const res = await setNewEmail(values.email, values.password);
    if (!res) {
      logout();
    } else {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: {
            type: ETypeNoti.ERROR,
            title:
              res && res.message !== 'Email is exist in auth0' ? res?.message : t('Pages.AccountSetting.Profile.ChangeEmailModal.EmailExists')
          }
        })
      );
    }
  }
  return (
    <ModalBase
      modalName={EModals.CHANGE_EMAIL}
      onCloseModal={() => {
        dispatch(popModal());
      }}
    >
      <div className="ctn-modal-change-email">
        <div className="title">{ t('Pages.AccountSetting.Profile.ChangeEmailModal.Title') }</div>
        <div className="body mt-5 px-2">
          <div className="info-text">
            { t('Pages.AccountSetting.Profile.ChangeEmailModal.Description') }
          </div>
          <Form form={form} layout="vertical" onFinish={handleChangePassword}>
            <Form.Item
              name={['email']}
              label={t('Pages.AccountSetting.Profile.ChangeEmailModal.NewEmail')}
              className="mt-2 input-title"
              rules={[{ type: 'email', message: t('Pages.AccountSetting.Profile.ChangeEmailModal.EnterNewEmail') }]}
            >
              <Input placeholder={t('Pages.AccountSetting.Profile.ChangeEmailModal.NewEmail')} className="input-email" />
            </Form.Item>
            <Form.Item
              name={['password']}
              label={t('Shared.Fields.Password')}
              className="mt-2 input-title"
              rules={[{ required: true, message: t('Pages.AccountSetting.Profile.ChangeEmailModal.EnterCurrentPass') }]}
            >
              <Input placeholder="Password" type="password" className="input-pwd" />
            </Form.Item>
            <Form.Item className="d-flex justify-content-end">
              <Button
                className="cancel-btn"
                onClick={() => {
                  dispatch(popModal());
                }}
              >
                { t('Shared.Actions.Cancel') }
              </Button>
              <Button className="submit-btn ms-1" htmlType="submit">
                { t('Shared.Actions.Change') }
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </ModalBase>
  );
}
