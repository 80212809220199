import { ModalBase, SubscribeBody } from "components/common";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { popModal } from "redux/modal/modal.slice";
import { EModals } from "ts/enums";

export function SubscribeModal() {
  const dispatch = useAppDispatch();
  const modalData = useAppSelector((state) => state.modal.lastModal?.data);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const removeSubscribePrefilledCouponParams = () => {
    searchParams.delete("subscribePrefilledCoupon");
    setSearchParams(searchParams);
  };

  return (
    <>
      <ModalBase
        modalName={EModals.SUBSCRIBE_MODAL}
        onCloseModal={() => {
          dispatch(popModal());
          removeSubscribePrefilledCouponParams();
        }}
        className="subscribe-modal"
      >
        <div className="ctn-subscribe-modal">
          <h3 className="heading d-flex justify-content-center">
            {t("Layouts.SubscribeModal.Title")}
          </h3>
          <SubscribeBody type={modalData?.type} />
        </div>
      </ModalBase>
    </>
  );
}
