import PS4 from 'components/PS4';
import Waveform from 'components/WaveForm';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useTranslation } from 'react-i18next';
import { getProjectByIdAction } from 'redux/projects/projects.action';
import { getTranscriptionByMediaIdAction } from 'redux/transcription/transcription.action';
import VideoPlayer from 'components/VideoPlayer';
import MiniControlPlayer from 'components/VideoPlayer/MiniControlPlayer';
import { useVideoPlayerContext } from 'context/VideoPlayerContext';
import { ButtonWithIcons } from 'components/common';
import { HiOutlineArrowLongLeft, HiOutlineArrowLongRight } from 'react-icons/hi2';
import ShareProjectModal from 'components/MainPageContainer/ShareProjectModal';
import { EModals, ERoutes, ESocketTopic, EStatusMedia, EStatusProject, ETypeNoti } from 'ts/enums';
import { Transcribing } from 'components/TranscriptPage';
import { setMedia } from 'redux/media/media.slice';
import { useWebSocket } from 'context/WebSocketConnectionContext';
import { createChannelId } from 'utils/common';
import { notification } from 'antd';
import ControlPlayerMobile from 'components/VideoPlayer/ControlPlayerMobile';
import { usePageLoading } from 'context/PageLoadingContext';
import { checkEditProject } from 'apis/shareProject.api';
import { useEditorContext } from 'context/EditorContext';
import { useAuth } from 'context/AuthContext';
import { useHotkeys } from 'react-hotkeys-hook';
import NotFoundPage from './NotFoundPage';
import { pushModal } from 'redux/modal/modal.slice';
import { getProjectById, lock, unLock } from 'apis/projects.api';
import { reloadCurrentProject } from 'redux/projects/projects.slice';
import { useAutoScroll } from 'context/AutoScrollContext';
import ProgressMediaTranscoding from 'components/TranscriptPage/ProgressMediaTranscoding';
import { resetCurrentTranscript } from 'redux/transcription/transcription.slice';
import { checkIfRTL } from 'utils/draftjs';
import { useLoaderContext } from 'context/LoaderContext';
import { PlayBackRate } from 'constant/PlayRate';
import DictionaryQuickAddModal from 'components/MainPageContainer/Dictionary/DictionaryQuickAddModal';

function TranscriptDetailPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const mediaId = searchParams.get('mediaId');
  const projectId = params.projectId;
  const dispatch = useAppDispatch();
  const currentProject = useAppSelector((state) => state.projects.currentProject);
  const {
    isVisible,
    currentTime,
    setCurrentTime,
    isPlaying,
    setIsPlaying,
    showPreview,
    setShowPreview,
    playBackRate,
    setPlayBackRate,
    seekTo,
    isAudio,
    setIsAudio,
    setIsReady
  } = useVideoPlayerContext();
  const [currentMediaId, setCurrentMediaId] = useState(mediaId);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const currentMedia = useAppSelector((state) => state.media.currentMedia);
  const { subscribe, unsubscribe } = useWebSocket();
  const { t } = useTranslation();

  const { openMessage, destroyMessage } = usePageLoading();
  const { setIsNoneEdit, setIsLock } = useEditorContext();
  const userData = useAppSelector((state) => state.auth.currentUser.data);
  const { isLogged } = useAuth();
  const navigate = useNavigate();
  const currentTranscription = useAppSelector((state) => state.transcription.currentTranscription);
  const translateProjectId = useAppSelector((state) => state.projects.translateProjectId);
  const { idBlockPlaying, isAutoScroll } = useAutoScroll();
  const ref = useRef<HTMLDivElement>(null);
  const blockTranscription = useAppSelector((state) => state.transcription.blockDomItem);
  const barRef = useRef<HTMLDivElement>(null);

  const audioFileUrl = currentMedia?.audioFileForBrowserPath;
  const videoFileUrl = currentMedia?.videoFilePath;
  const isNext =
    currentProject.data &&
    currentProject.data.medias.findIndex((media) => media.id === currentMediaId) !==
    currentProject.data.medias.length - 1;
  const isPrev =
    currentProject.data &&
    currentProject.data.medias.findIndex((media) => media.id === currentMediaId) !== 0;

  const isFailed =
    currentProject.data?.status === EStatusProject.TRANSCRIBING &&
    currentMedia?.status === EStatusMedia.FAILED;

  const isTranscribingOrTranslating = currentMedia
    ? currentMedia.status === EStatusMedia.TRANSCRIBING ||
    currentMedia.status === EStatusMedia.TRANSLATING ||
    currentMedia.status === EStatusMedia.TRANSCODING
    : false;

  let scrollTimeout: any;

  const isRenderPS4 =
    currentMedia?.status !== EStatusMedia.TRANSCRIBING &&
    currentMedia?.status !== EStatusMedia.TRANSLATING &&
    currentMedia?.status !== EStatusMedia.TRANSCODING &&
    currentMedia?.status !== EStatusMedia.READY;

  const isTextRightToLeft = currentMedia ? checkIfRTL({ currentMedia }) : false;
  const { loader } = useLoaderContext();

  const refetchProject = async () => {
    if (!projectId || fetching) return;
    setFetching(true);

    try {
      const project = await getProjectById({ projectId });
      project && dispatch(reloadCurrentProject({ project }));
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (currentProject.data) {
      localStorage.setItem('ss.ai.languageRegionCode', currentProject.data.languageRegionCode);
    }
  }, [currentProject]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (
        isTranscribingOrTranslating &&
        currentProject.data?.medias &&
        currentProject.data?.medias?.length > 0
      ) {
        await refetchProject();
      } else {
        clearInterval(interval);
      }
    }, 30 * 1000);

    return () => {
      clearInterval(interval);
      dispatch(resetCurrentTranscript());
    };
  }, []);

  function addClassById(elementId: string, className: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.classList.add(className);
    } else {
      console.error('Element with ID ' + elementId + ' not found.');
    }
  }

  function removeClassesByClassName(classNameToRemove: string) {
    const elements = document.querySelectorAll('.' + classNameToRemove);
    elements.forEach((element) => {
      element.classList.remove(classNameToRemove);
    });
  }

  function scrollToElement(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      console.error('Element with ID ' + elementId + ' not found.');
    }
  }

  useEffect(() => {
    if (!idBlockPlaying && isAutoScroll) {
      ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    } else if (idBlockPlaying && isAutoScroll) {
      scrollToElement(idBlockPlaying);
    }
  }, [idBlockPlaying, isAutoScroll]);

  useEffect(() => {
    if (idBlockPlaying) {
      removeClassesByClassName('playing');
      addClassById(idBlockPlaying, 'playing');
    }
  }, [idBlockPlaying]);

  useEffect(() => {
    function backToHome() {
      navigate(ERoutes.MAIN);
    }
    window.addEventListener('popstate', backToHome);
    return () => {
      window.removeEventListener('popstate', backToHome);
    };
  }, []);

  useLayoutEffect(() => {
    if (!currentMedia) {
      setIsReady(false);
      return;
    }
    if (currentMedia?.frameFilePath?.includes('poster-audio')) {
      setIsAudio(true);
    } else {
      setIsAudio(false);
    }
  }, [currentMedia]);

  useEffect(() => {
    if (!mediaId) return;

    lock({ mediaId: mediaId });

    return () => {
      unLock({ mediaId: mediaId });
    };
  }, [mediaId]);

  useEffect(() => {
    if (mediaId) {
      setIsReady(false);
      setCurrentMediaId(mediaId);
    }
  }, [mediaId]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (currentProject.data?.medias && currentProject.data?.medias.length > 0 && !mediaId) {
        setSearchParams({ mediaId: currentProject.data?.medias[0].id });
        dispatch(setMedia({ media: currentProject.data?.medias[0] }));
      } else {
        refetchProject();
      }
    }, 1 * 1000);

    if (mediaId) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [currentProject.data?.id, isTranscribingOrTranslating, mediaId]);

  useEffect(() => {
    if (userData?.id) {
      subscribe({
        topicChannelId: createChannelId(
          'topic',
          ESocketTopic.CHANGED_RIGHT_SHARED_PROJECT_WITH_USER,
          userData.id
        ),
        callback: async (payload: { projectId: string; right: string }) => {
          try {
            if (payload.right === 'DISABLED') {
              window.location.reload();
              return;
            }
            const res = await checkEditProject({ projectId: payload.projectId });
            setIsNoneEdit(res);
          } catch (error) {
            notification.open({ type: 'error', message: 'Error' });
          }
        }
      });

      return () => {
        if (userData.id) {
          unsubscribe(
            createChannelId(
              'topic',
              ESocketTopic.CHANGED_RIGHT_SHARED_PROJECT_WITH_USER,
              userData.id
            )
          );
        }
      };
    }
  }, [userData]);

  useEffect(() => {
    if (projectId) {
      (async () => {
        try {
          const res = await checkEditProject({ projectId });
          setIsNoneEdit(res);
        } catch (error) {
          // notification.open({ type: 'error', message: 'Error' });
        }
      })();
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProject.data && currentProject.data.medias) {
      if (currentProject.data.status === EStatusProject.OPEN || currentProject.data.status === EStatusProject.FAILED) {
        navigate(`/app/${ERoutes.UPLOAD}/${currentProject.data.id}`, { preventScrollReset: true });
        return
      }
      const media = currentProject.data?.medias.find((media) => media.id === currentMediaId);
      if (media) {
        dispatch(setMedia({ media }));
      }
    }
  }, [currentProject, currentMediaId, isTranscribingOrTranslating, isRenderPS4]);

  useEffect(() => {
    if (projectId && isLogged) {
      dispatch(getProjectByIdAction({ projectId })).then(() => {
        destroyMessage();
      });
      if (currentProject.error) {
        dispatch(
          pushModal({
            name: EModals.NOTI_MODAL,
            data: '',
            notiData: {
              type: ETypeNoti.ERROR,
              title: currentProject.error
            }
          })
        );
        setTimeout(() => {
          navigate(ERoutes.MAIN);
        }, 2000);
      }
    }
  }, [projectId, isLogged, isTranscribingOrTranslating]);

  useEffect(() => {
    if (currentMedia && !currentMedia.lockByUserId) {
      setIsLock(false);
      return;
    }

    if (currentMedia && userData && currentMedia.lockByUserId !== userData.id) {
      setIsLock(true);
    } else {
      setIsLock(false);
    }
  }, [currentMedia, userData]);

  function handleNextMedia() {
    if (currentProject.data) {
      const findNextIdx =
        currentProject.data.medias.findIndex((media) => media.id === currentMediaId) + 1;
      if (findNextIdx) {
        const nextMedia = currentProject.data.medias[findNextIdx];
        if (nextMedia) {
          setSearchParams({ mediaId: nextMedia.id });
          dispatch(setMedia({ media: nextMedia }));
        }
      }
    }
  }

  function handlePrevMedia() {
    if (currentProject.data) {
      const findPrevIdx =
        currentProject.data.medias.findIndex((media) => media.id === currentMediaId) - 1;
      if (findPrevIdx >= 0) {
        const prevMedia = currentProject.data.medias[findPrevIdx];
        if (prevMedia) {
          setSearchParams({ mediaId: prevMedia.id });
          dispatch(setMedia({ media: prevMedia }));
        }
      }
    }
  }

  function handleChangeIncrePlayBackRate() {
    const findNextIdx = PlayBackRate.findIndex((rate) => rate === playBackRate) + 1;
    const nextRate = PlayBackRate[findNextIdx];
    if (!nextRate) {
      setPlayBackRate(PlayBackRate[PlayBackRate.length - 1]);
      return;
    }
    setPlayBackRate(nextRate);
  }

  function handleChangeDecrePlayBackRate() {
    const findPrevIdx = PlayBackRate.findIndex((rate) => rate === playBackRate) - 1;
    const prevRate = PlayBackRate[findPrevIdx];
    if (!prevRate) {
      setPlayBackRate(PlayBackRate[0]);
      return;
    }
    setPlayBackRate(prevRate);
  }

  function handlePlayPause() {
    if (showPreview) {
      setShowPreview(false);
    }
    setIsPlaying(!isPlaying);
  }

  function handleRenderVideo() {
    if (currentMedia && currentProject.data?.applicationType === 'WEB') {
      if (
        currentMedia.transcodedVideoProgressPercentage > 0 &&
        currentMedia.transcodedVideoProgressPercentage < 100
      ) {
        return <ProgressMediaTranscoding media={currentMedia} />;
      } else {
        return (
          <VideoPlayer
            url={videoFileUrl || audioFileUrl}
            thumbnail={currentMedia.frameFilePath}
            duration={currentMedia.duration}
            userDefinedTimeCode={currentMedia.userDefinedTimeCode}
          />
        );
      }
    } else if (
      (currentMedia && currentProject.data?.applicationType === 'MAC_APP') ||
      (currentMedia && currentProject.data?.applicationType === null)
    ) {
      return (
        <VideoPlayer
          url={videoFileUrl || audioFileUrl}
          thumbnail={currentMedia.frameFilePath}
          duration={currentMedia.duration}
          userDefinedTimeCode={currentMedia.userDefinedTimeCode}
        />
      );
    }
  }

  useHotkeys('alt+space, ctrl+space', (e) => {
    e.preventDefault();
    handlePlayPause();
  });

  useHotkeys('ctrl+Period', (e) => {
    e.preventDefault(), handleChangeIncrePlayBackRate();
  });
  useHotkeys('ctrl+Comma', (e) => {
    e.preventDefault(), handleChangeDecrePlayBackRate();
  });
  useHotkeys('ctrl+ArrowRight, Meta+ArrowRight', (e) => {
    e.preventDefault();
    if (currentMedia?.duration) {
      const newCurentTime =
        currentTime >= currentMedia.duration ? currentMedia.duration : currentTime + 5;
      setCurrentTime(newCurentTime);
      seekTo(newCurentTime);
    }
  });
  useHotkeys('ctrl+ArrowLeft, Meta+ArrowLeft', (e) => {
    e.preventDefault();
    const newCurentTime = currentTime > 5 ? currentTime - 5 : 0;
    setCurrentTime(newCurentTime);
    seekTo(newCurentTime);
  });

  useEffect(() => {
    if (
      mediaId &&
      (currentMedia?.status === EStatusMedia.TRANSCRIBED ||
        currentMedia?.status === EStatusMedia.TRANSLATED ||
        currentMedia?.status === EStatusMedia.SUBTITLED)
    ) {
      loader.start();
      destroyMessage();

      openMessage('loading', 'Fetching transcript...');
      dispatch(getTranscriptionByMediaIdAction({ mediaId: mediaId })).then(() => {
        destroyMessage();
        loader.complete();
      });
      setCurrentTime(0);
      setIsPlaying(false);
      setShowPreview(true);
      seekTo(0);
    }
  }, [mediaId, currentMedia?.status]);

  /*
  useEffect(() => {
    setTimeout(() => {
      if (
        translateProjectId &&
        currentProject.data?.medias.filter((media) => media.transcodedVideoProgressPercentage > 90)
          .length === currentProject.data?.medias.length
          ? true
          : false
      ) {
        navigate(`${ERoutes.TRANSCRIPT_DETAIL}/${translateProjectId}`);
        dispatch(updateTranslateProjectId({ translateProjectId: null }));
      }
    }, 10000);
  }, [isTranscribingOrTranslating, translateProjectId]); */

  useEffect(() => {
    if (currentProject.data?.status == EStatusProject.READY && translateProjectId) {
      setTimeout(() => {
        //navigate(`${ERoutes.TRANSCRIPT_DETAIL}/${translateProjectId}`, { replace: true, state: { refresh: true } });
        //dispatch(updateTranslateProjectId({ translateProjectId: null }));
        window.location.href = `${ERoutes.TRANSCRIPT_DETAIL}/${translateProjectId}`;
      }, 3000);
    }
  }, [isTranscribingOrTranslating, currentProject.data?.status]);

  useEffect(() => {
    window.addEventListener('mouseup', () => {
      setIsSelecting(false);
    });
    window.addEventListener('mousedown', () => {
      setIsSelecting(true);
    });

    return () => {
      window.removeEventListener('mouseup', () => { });
      window.removeEventListener('mousedown', () => { });
    };
  }, []);

  if (!projectId || (currentProject.isFetched && !currentProject.data))
    return <NotFoundPage message={t('Shared.Errors.ProjectNotFound')} />;
  // if (!projectId || (currentProject.isFetched && !currentProject.data)) navigate('/app');
  return (
    <>
      <div className="progress-container">
        <div className="progress-bar" ref={barRef} />
      </div>
      <Waveform
        audio={currentMedia?.audioFileForWaveFormUrl}
        duration={currentMedia?.duration}
        peaks={currentMedia?.peaks}
        blockTranscription={blockTranscription}
      />
      <main
        id="main-page"
        className={`transcript-layout`}
        onScroll={(e) => {
          const currentTarget = e.currentTarget;
          const res =
            currentTarget.scrollTop / (currentTarget.scrollHeight - currentTarget.clientHeight);
          const scrolled = Number(res * 100).toFixed(1);

          const progressBar = barRef.current;
          if (progressBar) progressBar.style.width = `${scrolled}%`;
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(() => {
            setIsScrolling(true);
          }, 250);
        }}
      >
        <div className="container py-1" ref={ref}>
          <div className="row justify-content-center gx-md-1 gy-1">
            <div
              className={`${isAudio && currentMedia?.transcodedVideoProgressPercentage === 100
                ? 'col-lg-1'
                : isVisible
                  ? 'col-lg-5'
                  : 'col-lg-1'
                } col-sm-2 `}
              style={{
                transition: 'all 0.3s linear',
                position: 'sticky',
                top: 10,
                zIndex: 2
              }}
            >
              <div className="d-block d-sm-none background-coltrol-mobile" />
              <div style={{ position: 'sticky', top: 8, zIndex: 1 }}>
                <div className="d-flex gap-1 justify-content-start justify-content-lg-start">
                  {currentMedia && (
                    <>
                      {handleRenderVideo()}
                      <MiniControlPlayer />
                    </>
                  )}
                </div>
                {currentMedia && <ControlPlayerMobile />}
              </div>
            </div>
            <div
              className={`${isAudio && currentMedia?.transcodedVideoProgressPercentage === 100
                ? 'col-lg-11'
                : isVisible
                  ? 'col-lg-7'
                  : 'col-lg-11'
                } col-sm-10 `}
              style={{ transition: 'all 0.3s linear' }}
            >
              {!isRenderPS4 && <Transcribing />}
              {isFailed && (
                <div className="text-center">
                  {t('Pages.TranscriptDetail.Texts.TranscriptEmptyOrError')}
                </div>
              )}

              {isRenderPS4 && (
                <PS4
                  currentMedia={currentMedia}
                  currentTranscription={currentTranscription.data || []}
                  isLoading={currentTranscription.isLoading}
                  isTextRightToLeft={isTextRightToLeft}
                  isScrolling={isScrolling}
                  setIsScrolling={setIsScrolling}
                  isSelecting={isSelecting}
                />
              )}
              {currentProject.data && currentProject.data.medias.length > 1 && (
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <ButtonWithIcons
                    icon={<HiOutlineArrowLongLeft size={18} />}
                    text="Previous media"
                    onClick={handlePrevMedia}
                    className={`btn-si-text-primary fw-bold ${isPrev ? 'visible' : 'invisible'}`}
                  />
                  <ButtonWithIcons
                    icon={<HiOutlineArrowLongRight size={18} />}
                    text="Next media"
                    onClick={handleNextMedia}
                    className={`flex-row-reverse btn-si-text-primary fw-bold ${isNext ? 'visible' : 'invisible'
                      }`}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <ShareProjectModal />
        <DictionaryQuickAddModal />
      </main>
    </>
  );
}

export default TranscriptDetailPage;
