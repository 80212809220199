import { TProjectTransaction, TUser } from 'ts/types';
import { TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from '../axios-client';

export async function getListUsers(): Promise<TResponse<TUser[]>> {
  const res = await AxiosCustom.get(`admin/users`);
  return res.data;
}

export async function getListProjectTransactions({
  pageNumber,
  toDateSearch,
  userIdSearch,
  fromDateSearch
}: {
  pageNumber?: number;
  fromDateSearch?: string | null;
  toDateSearch?: string | null;
  userIdSearch?: string | null;
}): Promise<{
  projects: TProjectTransaction[];
  countOfCall: number;
}> {
  const res = await AxiosCustom.post(`admin/projects/project-transactions`, {
    enablePagination: true,
    pageSize: 50,
    ...(pageNumber ? { pageNumber } : {}),
    ...(userIdSearch ? { userIdSearch } : {}),
    ...(toDateSearch ? { toDateSearch } : {}),
    ...(fromDateSearch ? { fromDateSearch } : {})
  });
  return res.data;
}

export async function getDetailTransaction({
  transactionId
}: {
  transactionId: string;
}): Promise<TProjectTransaction> {
  const res = await AxiosCustom.get(`admin/projects/${transactionId}`);
  return res.data;
}

export async function updateProjectName({
  projectId,
  name,
  userEmail
}: {
  projectId: string;
  name: string;
  userEmail: string;
}) {
  const res = await AxiosCustom.put(`admin/projects/${projectId}`, { name, userEmail });
  return res.status;
}

export async function retryJob({ projectId }: { projectId: string }) {
  const res = await AxiosCustom.get(`admin/projects/${projectId}/retry-job`);
  return res;
}

export async function updateProjectStatus({
  projectId,
  status
}: {
  projectId: string;
  status: string;
}) {
  const res = await AxiosCustom.post(`admin/projects/update-status/${projectId}`, { status });
  return res.status;
}

// https://dev-api.simonsaysai.com/admin/projects/update-status/maVQDvbL3O7l
