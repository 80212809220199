import { useEditorContext } from 'context/EditorContext';
import _ from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillCaretDown, AiFillCaretRight } from 'react-icons/ai';
import { FaAngleLeft, FaAngleRight, FaSearch } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { useAppSelector } from 'redux/hooks';

type Props = {
  nextSelectionToReplace: () => void;
  prevSelectionToReplace: () => void;
  handleReplace: () => void;
  handleReplaceAll: () => void;
  searchInput: string;
  // eslint-disable-next-line no-unused-vars
  hasCaseSensitive: boolean;
  // eslint-disable-next-line no-unused-vars
  setHasCaseSensitive: (e: boolean) => void;
  replaceText: string;
  // eslint-disable-next-line no-unused-vars
  setReplaceText: (e: string) => void;
  onSearch: (searchInput: string, hasCaseSensitive: boolean) => void;
};

export function SearchTranscript({
  nextSelectionToReplace,
  prevSelectionToReplace,
  handleReplace,
  handleReplaceAll,
  searchInput,
  hasCaseSensitive,
  setHasCaseSensitive,
  replaceText,
  setReplaceText,
  onSearch
}: Props) {
  const [showMoreOption, setShowMoreOption] = useState<boolean>(false);
  const openSearchBox = useAppSelector((state) => state.transcription.openSearchBox);
  const { isLock, isNoneEdit } = useEditorContext();
  const { t } = useTranslation();

  // const searchDebounce = _.debounce(onSearch, 500);

  useEffect(() => {
    if (openSearchBox) {
      setTimeout(() => {
        document.getElementById('search-transcript')?.focus();
      }, 50);
    } else {
      setReplaceText('');
      onSearch('', hasCaseSensitive);
    }
  }, [openSearchBox]);

  return (
    <div
      className={`search-transcript p-1 d-none d-lg-block ${
        openSearchBox ? 'visible' : 'invisible'
      }`}
    >
      <div className="content">
        <div className="d-flex gap-1">
          {!isLock && !isNoneEdit && (
            <div
              className="icon cursor-pointer"
              style={{ marginTop: 4 }}
              onClick={() => {
                setShowMoreOption(!showMoreOption);
              }}
            >
              {showMoreOption ? <AiFillCaretDown /> : <AiFillCaretRight />}
            </div>
          )}

          <div className="block-input">
            <div className="d-flex align-items-center gap-1">
              <div className="d-flex align-items-center gap-1">
                <div
                  className={`icon ${searchInput && openSearchBox ? 'visible' : 'invisible'}`}
                  onClick={() => {
                    onSearch('', hasCaseSensitive);
                  }}
                >
                  <IoMdClose />
                </div>
                <input
                  id="search-transcript"
                  type="text"
                  value={searchInput}
                  placeholder={t('Shared.Actions.Search')}
                  autoFocus
                  onChange={(e) => {
                    onSearch(e.target.value, hasCaseSensitive);
                  }}
                />
              </div>
              <div className="search-icon">
                <FaSearch />
              </div>
              <div className="change-selection d-flex">
                <div className="prev cursor-pointer" onClick={prevSelectionToReplace}>
                  <FaAngleLeft size={20} />
                </div>
                <div className="next cursor-pointer" onClick={nextSelectionToReplace}>
                  <FaAngleRight size={20} />
                </div>
              </div>
            </div>

            {showMoreOption && !isLock && !isNoneEdit && (
              <div className="replace mt-1">
                <div className="d-flex align-items-center gap-1">
                  <div
                    className={`icon ${replaceText && openSearchBox ? 'visible' : 'invisible'}`}
                    onClick={() => {
                      setReplaceText('');
                    }}
                  >
                    <IoMdClose />
                  </div>
                  <input
                    type="text"
                    value={replaceText}
                    placeholder={t('Shared.Actions.Replace')}
                    onChange={(e) => {
                      setReplaceText(e.target.value);
                    }}
                  />
                </div>
                <div className={`${isLock ? 'd-none' : 'd-flex gap-1 mt-1 ms-3'}`}>
                  <span className="cursor-pointer" onClick={handleReplace}>
                    {t('Shared.Actions.Replace')}
                  </span>
                  <span>|</span>
                  <span className="cursor-pointer" onClick={handleReplaceAll}>
                    {t('Shared.Actions.ReplaceAll')}
                  </span>
                </div>
                <label className="mt-1 ms-3 d-flex align-items-center cursor-pointer">
                  <input
                    type="checkbox"
                    name="case-sensitive"
                    className="check-box"
                    onChange={() => {
                      setHasCaseSensitive(!hasCaseSensitive);
                      onSearch(searchInput, !hasCaseSensitive);
                    }}
                  />
                  <span className="ms-1">{t('Shared.Texts.CaseSensitive')}</span>
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
