import { TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from './svl-client';
import { ERoutes } from 'ts/enums';

export async function paypalCreateOrder(durationInSeconds: number, encodedUserId: string): Promise<TResponse<any | { message: string }>> {
  const token = localStorage.getItem('ss.accessToken');
  const response = await AxiosCustom.post("/payments/paypal/create-order", {
    "encodedUserId": encodedUserId,
    "auth0Token": token
  }, { 
    params: { durationInSeconds },
    headers: { "Content-Type": "application/json" }
  });
  
  return response.data;
}

export async function paypalCaptureOrder(orderId: string): Promise<TResponse<any | { message: string }>> {
  const token = localStorage.getItem('ss.accessToken');
  const response = await AxiosCustom.post("/payments/paypal/capture-order", {}, { 
    params: { orderId },
    headers: { "Content-Type": "application/json" }
  });
  
  return response.data;
}

export async function stripeCreateBuyCreditOrder(durationInSeconds: number, userEmail: string, encodedUserId: string, couponCode: string | null | undefined, itemDescription: string | null): Promise<TResponse<any | { message: string }>> {
  const token = localStorage.getItem('ss.accessToken');
  const response = await AxiosCustom.post("/payments/stripe/create-order", {
    "email": userEmail,
    "encodedUserId": encodedUserId,
    "auth0Token": token,
    "durationInSeconds": durationInSeconds,
    "couponCode": couponCode || undefined,
    "overrideItemDescription": itemDescription,
    "returnUrl": location.href
  }, { 
    params: { action: 'create-buycredit-checkout-session' },
    headers: { "Content-Type": "application/json" }
  });
  
  return response.data;
}

/*
export async function paypalCaptureOrder(orderId: string): Promise<TResponse<any | { message: string }>> {
  const token = localStorage.getItem('ss.accessToken');
  const response = await AxiosCustom.post("/payments/paypal/capture-order", {}, { 
    params: { orderId },
    headers: { "Content-Type": "application/json" }
  });
  
  return response.data;
}*/

export async function stripeCouponCheckAvailable(userEmail: string, stripeCoupon: string): Promise<TResponse<any | { message: string }>> {
  const token = localStorage.getItem('ss.accessToken');
  const response = await AxiosCustom.post("/payments/stripe/coupon", {
    "email": userEmail,
    "auth0Token": token,
    "stripeCoupon": stripeCoupon
  }, { 
    params: { action: 'check-coupon-activation' },
    headers: { "Content-Type": "application/json" }
  });
  
  return response.data;
}


export async function stripeCouponApply(userEmail: string, stripeCoupon: string): Promise<TResponse<any | { message: string }>> {
  const token = localStorage.getItem('ss.accessToken');
  const response = await AxiosCustom.post("/payments/stripe/coupon", {
    "email": userEmail,
    "auth0Token": token,
    "stripeCoupon": stripeCoupon
  }, { 
    params: { action: 'activate-coupon' },
    headers: { "Content-Type": "application/json" }
  });
  
  return response.data;
}

export async function stripeCreateTranscribeOrder(encodedProjectId: string, userEmail: string, encodedUserId: string, dictionaryId: number | null, translateLanguageIds: number[]): Promise<TResponse<any | { message: string }>> {
  const token = localStorage.getItem('ss.accessToken');
  const response = await AxiosCustom.post("/payments/stripe/create-order", {
    "email": userEmail,
    "encodedUserId": encodedUserId,
    "auth0Token": token,
    "encodedProjectId": encodedProjectId,
    "dictionaryId": dictionaryId,
    "targetLanguageIds": translateLanguageIds,
    "returnUrl": location.href
  }, { 
    params: { action: 'create-transcribe-checkout-session' },
    headers: { "Content-Type": "application/json" }
  });
  
  return response.data;
}

export async function stripeCreateTranslateOrder(encodedProjectId: string, userEmail: string, encodedUserId: string, translateLanguageIds: number[]): Promise<TResponse<any | { message: string }>> {
  const token = localStorage.getItem('ss.accessToken');
  const response = await AxiosCustom.post("/payments/stripe/create-order", {
    "email": userEmail,
    "encodedUserId": encodedUserId,
    "auth0Token": token,
    "encodedProjectId": encodedProjectId,
    "targetLanguageIds": translateLanguageIds,
    "returnUrl": location.href,
    "returnUrlSuccess": `${location.origin}${ERoutes.MAIN}`
  }, { 
    params: { action: 'create-translate-checkout-session' },
    headers: { "Content-Type": "application/json" }
  });
  
  return response.data;
}