import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaHome } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { setDictionaryScreen } from 'redux/dictionary/dictionary.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { EDelayTime, ERoutes } from 'ts/enums';

type Props = {
  menuElement?: React.ReactElement;
  rightButton: React.ReactElement;
};

function HeaderMainLayout({ menuElement, rightButton }: Props) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isDictionaryScreen = useAppSelector((state) => state.dictionary.isDictionaryScreen);
  const { t } = useTranslation();

  return (
    <div className="ctn-header-main-layout d-flex align-items-center justify-content-between ps-3 pe-2">
      <div className="d-flex align-items-center gap-2">
        <Tooltip
          title={`${t('Layouts.Header.Texts.AllProjects')}`}
          trigger={'hover'}
          mouseEnterDelay={EDelayTime.FAST}
          placement="bottom"
          overlayClassName="custom-toolstip"
        >
          <Link
            reloadDocument={location.pathname !== ERoutes.MAIN}
            to={ERoutes.MAIN}
            onClick={() => {
              dispatch(setDictionaryScreen({ isDictionaryScreen: false }));
            }}
            className={`${
              location.pathname === ERoutes.MAIN && !isDictionaryScreen
                ? 'opacity-50 cursor-default'
                : ''
            }`}
          >
            <FaHome size={28} color="var(--si-primary)" />
          </Link>
        </Tooltip>
        <div className="header-menu d-flex align-items-center gap-1">{menuElement}</div>
      </div>
      <React.Fragment>{rightButton}</React.Fragment>
    </div>
  );
}

export default HeaderMainLayout;
