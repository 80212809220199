export function stringToSlug(str: string) {
  if (str)
    return str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

  return '';
}

export function getPublishLinkSlug(name: string) {
  let result = removeAllSpecialChars(name, true);
  result = removeExtraWhiteSpaces(result);
  result = result.trim().toLowerCase();
  result = replaceAll(result, ' ', '-');

  return result;
}

function removeAllSpecialChars(stringToReplace: string, allowWhiteSpace: boolean) {
  var regex = allowWhiteSpace ? /[^\w\s]/gi : /[^\w]/gi;
  return stringToReplace.replace(regex, '');
}

function removeExtraWhiteSpaces(stringToReplace: string) {
  return stringToReplace.replace(/\s\s+/g, ' ');
}

function replaceAll(target: string, search: string, replacement: string) {
  return target.replace(new RegExp(search, 'g'), replacement);
}
