import { TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from './axios-client';
import { TProjectTags, TTag } from 'ts/types';
import { TRequestCreateProjectTag, TRequestCreateTag, TRequestUpdateTag } from 'ts/types/TRequest';

export async function getListTags(): Promise<TResponse<Array<TTag>>> {
  const res = await AxiosCustom.get(`user/tags`);
  return res.data;
}

export async function getListProjectTags(): Promise<TResponse<Array<TProjectTags>>> {
  const res = await AxiosCustom.get(`user/project-tags`);
  return res.data;
}

export async function createTag({ name, color }: TRequestCreateTag): Promise<TResponse<TTag>> {
  const res = await AxiosCustom.post(`user/tags`, { name, color });
  return res.data;
}

export async function createProjectTag({
  projectId,
  tagId
}: TRequestCreateProjectTag): Promise<TResponse<TProjectTags>> {
  const res = await AxiosCustom.post(`user/project-tags`, { projectId, tagId });
  return res.data;
}

export async function updateTag({ name, color, id }: TRequestUpdateTag): Promise<TResponse<TTag>> {
  const res = await AxiosCustom.put(`user/tags/${id}`, { name, color, id });
  return res.data;
}

export async function deleteProjectTag({
  projectTag
}: {
  projectTag: TProjectTags;
}): Promise<TResponse<TProjectTags>> {
  await AxiosCustom.delete(`user/project-tags/${projectTag.id}`);
  return projectTag;
}

export async function deleteTag({ tag }: { tag: TTag }): Promise<TResponse<TTag>> {
  await AxiosCustom.delete(`user/tags/${tag.id}`);
  return tag;
}
