export const BookmarkFilter = ({
  width,
  height,
  color
}: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width || 33}
      height={height || 35}
      viewBox="0 0 33 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8C0 6.34315 1.34315 5 3 5H21C22.6569 5 24 6.34315 24 8V34C24 34.3958 23.7665 34.7544 23.4046 34.9145C23.0426 35.0746 22.6202 35.0062 22.3273 34.7399L12 25.3515L1.67267 34.7399C1.37979 35.0062 0.957427 35.0746 0.595446 34.9145C0.233466 34.7544 0 34.3958 0 34V8ZM3 7C2.44772 7 2 7.44772 2 8V31.7394L11.3273 23.2601C11.7087 22.9133 12.2913 22.9133 12.6727 23.2601L22 31.7394V8C22 7.44771 21.5523 7 21 7H3Z"
        fill={color || 'black'}
      />
      <path
        d="M33 10C33 15.5228 28.5228 20 23 20C17.4772 20 13 15.5228 13 10C13 4.47715 17.4772 0 23 0C28.5228 0 33 4.47715 33 10Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 18.5C27.6944 18.5 31.5 14.6944 31.5 10C31.5 5.30558 27.6944 1.5 23 1.5C18.3056 1.5 14.5 5.30558 14.5 10C14.5 14.6944 18.3056 18.5 23 18.5ZM23 20C28.5228 20 33 15.5228 33 10C33 4.47715 28.5228 0 23 0C17.4772 0 13 4.47715 13 10C13 15.5228 17.4772 20 23 20Z"
        fill={color || 'black'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9375 5.83147C17.0615 5.56943 17.3255 5.40234 17.6154 5.40234H28.3846C28.6745 5.40234 28.9385 5.56943 29.0625 5.83147C29.1865 6.09351 29.1484 6.40356 28.9646 6.62778L24.8269 11.676V16.1523C24.8269 16.414 24.6906 16.6567 24.4672 16.7928C24.2438 16.9289 23.9656 16.9388 23.7331 16.8189L21.5792 15.7078C21.3298 15.5791 21.1731 15.3219 21.1731 15.0412V11.676L17.0353 6.62778C16.8515 6.40356 16.8134 6.09351 16.9375 5.83147ZM19.1998 6.90234L22.5031 10.9325C22.613 11.0665 22.6731 11.2345 22.6731 11.4079V14.5842L23.3269 14.9215V11.4079C23.3269 11.2345 23.387 11.0665 23.4969 10.9325L26.8001 6.90234H19.1998Z"
        fill={color || 'black'}
      />
    </svg>
  );
};
