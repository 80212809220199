import { Spin } from 'antd';
import { ModalBase } from 'components/common';
import React from 'react';
import { useAppDispatch } from 'redux/hooks';
import { popModal } from 'redux/modal/modal.slice';
import { EModals } from 'ts/enums';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export const HoorayModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <ModalBase
      modalName={EModals.HOORAY_MODAL}
      onCloseModal={() => {
        dispatch(popModal());
      }}
      className="hooray-modal"
      isCloseIcon={false}
    >
      <div className="content">
        <div className="text-center fs-2">{ t('Pages.Main.HoorayModal.Title') }</div>
        <div className="text-center mt-1">
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 40, color: 'var(--si-white)' }} spin />}
          />
        </div>
        <div
          className="text-center fs-5 ms-auto me-auto mt-1"
          style={{ maxWidth: 470 }}
        >{ t('Pages.Main.HoorayModal.PaymentReceived') }</div>
      </div>
    </ModalBase>
  );
};
