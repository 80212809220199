import FCPIcon from 'assets/img/fcp-icon.png';
import { PropsExport } from '.';
import { EExportName, EExportSubData } from 'ts/enums';

const subDataRender = [
  {
    key: EExportSubData.CAPTIONS,
    title: 'Captions',
    option: {},
    allowMultipleMedia: false
  },
  {
    key: EExportSubData.RANGERS,
    title: 'Ranges',
    option: {},
    allowMultipleMedia: false
  },
  {
    key: EExportSubData.TITLES,
    title: 'Titles',
    option: { withGapless: false },
    allowMultipleMedia: false
  }
];
export function FCPExport({ exportOption, setExportOption }: PropsExport) {
  return (
    <div className="fcpx-export export-item">
      <div className="d-flex flex-column justify-content-center align-items-center cursor-pointer"
        onClick={() => {
          setExportOption(draft => {
            draft.key = EExportName.FCPX;
            draft.data.subData = EExportSubData.CAPTIONS;
            draft.data.option = subDataRender[0].option;
            draft.allowMultipleMedia = false;
          })
        }}>
        <div
          className={`icon ${exportOption.key === EExportName.FCPX ? 'opacity-100' : 'opacity-50'}`}
        >
          <img src={FCPIcon} alt="" />
        </div>
        <div className={`text mt-1 ${exportOption.key === EExportName.FCPX ? '--selected' : ''}`}>
          <span>FCP</span>
        </div>
      </div>
      <div className={`text mt-1 ${exportOption.key === EExportName.FCPX ? '--selected' : ''}`}>
        {exportOption.key === EExportName.FCPX && (
          <div className="choose-option mt-2">
            {subDataRender.map((s) => (
              <label className="d-flex align-items-center mb-1 gap-1 cursor-pointer" key={s.key}>
                <input
                  type="radio"
                  name="select"
                  defaultChecked={s.key === EExportSubData.CAPTIONS}
                  onClick={() => {
                    setExportOption(draft => {
                      draft.key = EExportName.FCPX;
                      draft.data.subData = s.key;
                      draft.data.option = s.option;
                      draft.allowMultipleMedia = s.allowMultipleMedia;
                    })
                  }}
                />
                <span>{s.title}</span>
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
