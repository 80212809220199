import createInlineToolbarPlugin from 'components/DraftInlineToolbar';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
// import { buttonStyles, toolbarStyles } from './components/toolbarcss';

const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;

const myDraftStyle = {
  HIGHLIGHT: {
    backgroundColor: 'rgba(249, 142, 29, 0.2)' // css property
  },
  STRIKETHROUGH: {
    textDecoration: 'line-through'
  },
  SENTENCECASE: {
    textTransform: 'capitalize' as const
  },
  UPPERCASE: {
    textTransform: 'uppercase' as const
  },
  LOWERCASE: {
    textTransform: 'lowercase' as const
  },
  DICTIONARY: {
    
  }
};

const plugins = [inlineToolbarPlugin];
const customStyleMap = { ...myDraftStyle };

export { plugins, InlineToolbar, customStyleMap };
