import React, { useState } from 'react';
import { Select, Tooltip } from 'antd';
import { BsTagFill } from 'react-icons/bs';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { filterListProjectWithTags } from 'redux/projects/projects.slice';
import { BiSolidDownArrow } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { EDelayTime } from 'ts/enums';

export function FilterTags() {
  const listProjectTags = useAppSelector((state) => state.projectTags.currentListProjectTags.data);
  const listTags = useAppSelector((state) => state.tags.currentListTags.data);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [toolstip, setShowTooltip] = useState<boolean>(false);

  const searchProject = (idLabel: string[]) => {
    if (listProjectTags) {
      const listIdColor = idLabel.map((stringNumber) => parseInt(stringNumber));
      dispatch(
        filterListProjectWithTags({
          tagId: listIdColor
        })
      );
    }
  };

  return (
    <Tooltip
      title={`${t('Layouts.Header.Texts.ProjectTags')}`}
      trigger={'hover'}
      mouseEnterDelay={EDelayTime.FAST}
      placement="bottom"
      overlayClassName="custom-toolstip"
      open={toolstip}
    >
      <div className="filter-tags d-none d-md-flex align-items-center">
        <Select
          mode="tags"
          optionFilterProp="children"
          // filterOption={(input, option) => (option?.label.toLocaleLowerCase() ?? '').includes(input)}
          style={{ minWidth: 142, height: 30 }}
          placeholder={
            <span
              style={{
                color: 'var(--si-grey-text-disable)',
                fontFamily: 'GothamBook',
                fontWeight: 600,
                fontSize: 14
              }}
            >
              <BsTagFill className="ms-1" color="var(--si-primary)" />{' '}
              {t('Pages.Main.Texts.FilterByLabel')}
            </span>
          }
          onChange={searchProject}
          suffixIcon={false}
          dropdownStyle={{ maxWidth: 200, marginRight: 30 }}
          className="filter-select"
          onMouseEnter={() => dropdownVisible || setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          onDropdownVisibleChange={(open) => {
            if (open) setShowTooltip(false);
            setDropdownVisible(open);
          }}
        >
          {listTags?.map((tag) => (
            <Select.Option key={tag.id}>
              <span
                style={{
                  backgroundColor: `${tag.color}`,
                  padding: '2px 5px',
                  borderRadius: 3,
                  color: 'var(--si-white)',
                  display: 'block',
                  width: '100%'
                }}
              >
                {tag.name}
              </span>
            </Select.Option>
          ))}
        </Select>
        <BiSolidDownArrow size={8} className="me-1" />
      </div>
    </Tooltip>
  );
}
