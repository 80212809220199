import { Progress } from 'antd';
import { useWebSocket } from 'context/WebSocketConnectionContext';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { updateListMediasInCurrentProject } from 'redux/projects/projects.slice';
import { ESocketTopic } from 'ts/enums';
import { TMedia } from 'ts/types';
import { createChannelId } from 'utils/common';
import { BiChevronDown } from 'react-icons/bi';
import { useVideoPlayerContext } from 'context/VideoPlayerContext';
import { setMedia } from 'redux/media/media.slice';

const ProgressMediaTranscoding = ({ media }: { media: TMedia }) => {
  const params = useParams();
  const { projectId } = params;
  const dispatch = useAppDispatch();
  const { subscribe, unsubscribe } = useWebSocket();
  const { isVisible, setIsVisible } = useVideoPlayerContext();
  const [currentProgress, setCurrentProgress] = useState<number>(0);

  useEffect(() => {
    if (!projectId) return;

    subscribe({
      topicChannelId: createChannelId('topic', ESocketTopic.MEDIA_UPDATE_PROJECT, projectId),
      callback: (res: TMedia) => {
        const progress = res.transcodedVideoProgressPercentage;

        if (progress > 0 && progress !== 100) {
          dispatch(
            setMedia({
              media: res
            })
          );
        }

        if (res.status === 'ready' && progress === 100) {
          dispatch(
            updateListMediasInCurrentProject({
              media: res
            })
          );
        }
      }
    });

    return () => {
      if (projectId) {
        unsubscribe(createChannelId('topic', ESocketTopic.MEDIA_UPDATE_PROJECT, projectId));
      }
    };
  }, []);

  useEffect(() => {
    if (media.transcodedVideoProgressPercentage > currentProgress) {
      setCurrentProgress(media.transcodedVideoProgressPercentage);
    }
  }, [media.transcodedVideoProgressPercentage]);

  return (
    <div
      className={`progress-media-transcoding d-sm-none flex-column ${
        isVisible ? 'd-md-flex' : 'd-none'
      }`}
    >
      <div className="header p-1 p-lg-2 text-center">
        <div>Your video is converting.</div>
        <div
          onClick={(e) => {
            setIsVisible(!isVisible);
            e.stopPropagation();
          }}
        >
          <BiChevronDown size={24} />
        </div>
      </div>
      <div className="body d-flex align-items-center justify-content-center">
        <div className="d-none d-lg-block">
          <Progress
            strokeColor={'#7e56a2'}
            type="circle"
            percent={currentProgress}
            trailColor="#edf2f4"
            showInfo={false}
            size={110}
          />
        </div>
        <div className="d-block d-lg-none">
          <Progress
            strokeColor={'#7e56a2'}
            type="circle"
            percent={currentProgress}
            trailColor="#edf2f4"
            showInfo={false}
            size={60}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressMediaTranscoding;
