import { useTranslation, Trans } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { pushModal } from 'redux/modal/modal.slice';
import { EModals, EPlanOption, ETypeSubcribeModal } from 'ts/enums';

export function EmptyStateDictionary() {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const dispatch = useAppDispatch();
  const enable = currentUser.data?.plan.planEnum !== EPlanOption.PAY_AS_YOU_GO;
  const { t } = useTranslation();

  function openSubscribeModal() {
    dispatch(
      pushModal({
        name: EModals.SUBSCRIBE_MODAL,
        data: {
          type: ETypeSubcribeModal.UPDRAGE
        }
      })
    );
  }

  function openAddDictionaryModal() {
    dispatch(
      pushModal({
        name: EModals.ADD_DICTIONARY_MODAL,
        data: {}
      })
    );
  }

  return (
    <div className="empty-state-dictionary my-auto">
      {enable ? (
        <div className="text-center">
          <span>{t('Pages.Main.Dictionary.NoDictionaryCreated')} </span>
          <span onClick={openAddDictionaryModal} className="create-new-dictionary">
            {t('Pages.Main.Dictionary.CreateYourFirstDictionary')}
          </span>
        </div>
      ) : (
        <>
          <div className="text-center">
            <Trans t={t} i18nKey="Pages.Main.Dictionary.PlanNotSupported">
              Here is the link
              <a
                className="custom-a-tag"
                onClick={openSubscribeModal}
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            </Trans>
          </div>
          <div className="text-center">
            <Trans t={t} i18nKey="Pages.Main.Dictionary.SeeMoreDetail">
              Here is the link
              <a
                className="custom-a-tag"
                href="https://www.simonsaysai.com/help/4535020-custom-dictionaries-limits-per-plan"
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            </Trans>
          </div>
        </>
      )}
    </div>
  );
}
