import { TMedia } from 'ts/types';
import { TResponse, TResponseSignedUrl } from 'ts/types/TResponse';
import { AxiosCustom } from './axios-client';

export async function createNewMedia({
  projectId,
  name
}: {
  projectId: string;
  name: string;
}): Promise<TResponse<TMedia>> {
  const res = await AxiosCustom.post(`projects/${projectId}/media/`, { name });
  return res.data;
}

export async function signUrlToUpload({
  fileName,
  projectId,
  mediaId,
  mimeType
}: {
  fileName: string;
  projectId: string;
  mediaId: string;
  mimeType: string;
}): Promise<TResponse<TResponseSignedUrl>> {
  const res = await AxiosCustom.post(`projects/${projectId}/signed-url-to-upload`, {
    fileName,
    mediaId,
    mimeType
  });
  return res.data;
}

export async function uploadLocalFileToMedia({
  mediaId,
  fileSignName,
  fileSize,
  type,
  linkToFile,
  accessToken,
  boxFileId
}: {
  mediaId: string;
  fileSignName: string;
  fileSize: number;
  type: string;
  linkToFile?: string;
  accessToken?: string | null;
  boxFileId?: string;
}): Promise<TResponse<TMedia>> {
  const res = await AxiosCustom.post(`media/${mediaId}/upload`, {
    fileName: fileSignName,
    fileSize,
    type,
    ...(linkToFile ? { linkToFile } : {}),
    ...(accessToken ? { accessToken } : {}),
    ...(boxFileId ? { boxFileId } : {})
  });
  return res.data;
}

export async function changeMedia({ media }: { media: TMedia }): Promise<TResponse<TMedia>> {
  const res = await AxiosCustom.put(`media/${media.id}`, media);
  return res.data;
}

export async function changeMediaForAll({
  projectId,
  width,
  height,
  fps
}: {
  projectId: string;
  width?: number;
  height?: number;
  fps?: number;
}): Promise<TResponse<TMedia>> {
  const res = await AxiosCustom.put(`projects/${projectId}/medias`, { width, height, fps });
  return res.data;
}

export async function deleteMedia({ mediaId }: { mediaId: string }) {
  const res = await AxiosCustom.delete(`media/${mediaId}`);
  return res;
}

export async function retryTranscoding({ mediaId }: { mediaId: string }) {
  const res = await AxiosCustom.post(`media/${mediaId}/retry-transcoding`);
  return res.status;
}
