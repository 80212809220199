const SPEAKER_COLORS = [
  {
    color: '#b189d5',
    label: '#FFF'
  },
  {
    color: '#C5E098',
    label: '#FFF'
  },
  {
    color: '#FFC873',
    label: '#FFF'
  },
  {
    color: '#62C6E7',
    label: '#FFF'
  },
  {
    color: '#778FC5',
    label: '#FFF'
  },
  {
    color: '#838383',
    label: '#FFF'
  },
  {
    color: '#553B75',
    label: '#FFF'
  },
  {
    color: '#C3EDFB',
    label: '#1D9AC3'
  },
  {
    color: '#BF8224',
    label: '#FFE7C2'
  },
  {
    color: '#E0F1C3',
    label: '#769939'
  }
];

export default SPEAKER_COLORS;
