import { Select, Tag, notification } from 'antd';
import { ModalBase } from 'components/common';
import { Loader } from 'components/common/Loader';
import { useAuth } from 'context/AuthContext';
import React, { useEffect, useRef, useState } from 'react';
import { FaDownload, FaEdit } from 'react-icons/fa';
import {
  createDictionaryAction,
  getDictionaryAction,
  updateDictionaryAction
} from 'redux/dictionary/dictionary.action';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { popModal } from 'redux/modal/modal.slice';
import { EModals } from 'ts/enums';
import { TLanguageConvert } from 'ts/types';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { useTranslation } from 'react-i18next';
import { usePageLoading } from 'context/PageLoadingContext';
import { useLoaderContext } from 'context/LoaderContext';

function DictionaryQuickAddModal() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isLogged } = useAuth();
  const { openMessage } = usePageLoading();
  const { loader } = useLoaderContext();

  const [dictionarySelected, setDictionarySelected] = useState<number | null>(null);
  const dictionaryData = useAppSelector((state) => state.dictionary.currentDictionary);

  const [tags, setTags] = useState<string[]>([]);
  const modalData = useAppSelector((state) => state.modal.lastModal?.data);
  const enable = tags.length > 0 && dictionarySelected !== null;
  const [loading, setLoading] = useState<boolean>(false);
  
  const selectTagRef = useRef<any>(null);

  useEffect(() => {
    isLogged && dispatch(getDictionaryAction());
  }, [isLogged]);

  useEffect(() => {
    if(!modalData?.selectedText) return;

    let originalList: string[] = [];
    let dictionary = dictionaryData.data.find((d) => d.id == dictionarySelected);
    if(dictionary) originalList = dictionary.tags;

    let rawWords = modalData.selectedText.split(' ');
    let newWords = rawWords.map((w: string) => {
      let trimmed = w.trim();
      trimmed = trimmed.replaceAll(/[\.,]+$/gi, "");
      return trimmed;
    });

    let combinedWords = Array.from(new Set(originalList.concat(newWords)));
    setTags(combinedWords);
  }, [modalData?.selectedText, dictionarySelected])
  function onChangeDictionary(value: any) {
    setDictionarySelected(Number(value));
  }
 
  function saveDictionary() {
    if(!dictionaryData.isFetched || !dictionarySelected) return;

    let dictionary = dictionaryData.data.find((d) => d.id == dictionarySelected);
    if(!dictionary) return;

    setLoading(true);
    loader.start();

    dispatch(
      updateDictionaryAction({
        dictionary: {
          id: dictionarySelected,
          languageId: dictionary.languageId,
          name: dictionary.name,
          tags: tags
        }
      })
    ).then((res) => {
      setLoading(false);
      loader.complete();

      if (res.type === 'dictionary/update-dictionary/rejected') {
        notification.open({
          type: 'error',
          message: t('Pages.Main.Errors.UnableToUpdateDictionary')
        });
        return;
      }

      openMessage('success', 'Saved!');
      dispatch(popModal());
      dispatch(getDictionaryAction());
    });
  }

  const tagRender = (props: CustomTagProps) => {
    /* eslint-disable react/prop-types */
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        className="tag-custom-dictionary-render"
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <ModalBase
      modalName={EModals.DICTIONARY_QUICK_ADD}
      onCloseModal={() => {
        dispatch(popModal());
      }}
    >
      <div className="dictionary-quick-add-modal">
        <div className="title">{t('Pages.TranscriptDetail.DictionaryQuickAddModal.Title')}</div>
        <div className="body">
          <div>
            <div className="fw-bold">{t('Shared.Fields.Dictionary')}</div>
            <Select
              dropdownStyle={{ fontFamily: 'GothamBook' }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
              }
              value={dictionarySelected}
              getPopupContainer={(trigger) => trigger.parentNode}
              className={`dictionary-select ${dictionarySelected ? '' : 'error'}`}
              style={{ width: '100%' }}
              onChange={onChangeDictionary}
              placeholder={
                <span style={{ fontStyle: 'italic', fontWeight: 500 }}>
                  {t('Pages.TranscriptDetail.DictionaryQuickAddModal.ChooseDictionary')}
                </span>
              }
              suffixIcon={null}
              options={dictionaryData.data.map((d, i) => ({
                key: d.id,
                label: `${d.name} (${d.languageLabel})`,
                value: d.id
              }))}
            />
          </div>
          <div className="section-add-tags">
            <Select
              ref={selectTagRef}
              mode="tags"
              tagRender={tagRender}
              style={{ width: '100%' }}
              suffixIcon={null}
              showSearch={false}
              open={false}
              value={tags}
              onChange={(e) => setTags(e)}
              onInputKeyDown={(e) => {
                if (e.key === ',') {
                  e.preventDefault();
                  selectTagRef.current.blur();
                  selectTagRef.current.focus();
                }
              }}
              className={`${tags.length === 0 ? 'error' : ''}`}
              placeholder={
                <span style={{ fontStyle: 'italic', fontWeight: 500 }}>
                  {t('Pages.TranscriptDetail.DictionaryQuickAddModal.Tip')}
                </span>
              }
            />
            <div className="mt-1">{t('Pages.TranscriptDetail.DictionaryQuickAddModal.CommaValue')}</div>
            <div className={`error-text ${enable ? 'invisible' : 'visible'}`}>
              {t('Pages.TranscriptDetail.DictionaryQuickAddModal.CommaValueInput')}
            </div>
          </div>
        </div>
        <div className="footer mt-4 d-flex justify-content-center">
          <button
            className="submit-button d-flex align-items-center justify-content-center gap-1"
            onClick={saveDictionary}
            disabled={loading}
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                <FaDownload size={24} />
                <span>{t('Shared.Actions.Save')}</span>
              </>
            )}
          </button>
        </div>
      </div>
    </ModalBase>
  );
}

export default React.memo(DictionaryQuickAddModal);