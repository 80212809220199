import { TPlan, TSubscription } from 'ts/types';
import { AxiosCustom } from './axios-client';
import { EPlanOption, EPlanType } from 'ts/enums';

export async function getPlan(): Promise<TPlan[]> {
  const res = await AxiosCustom.get(`plans`);
  return res.data;
}

export async function buyCredit({
  buyCreditInSeconds,
  number,
  cardHolderName,
  cvc,
  exp_month,
  exp_year,
  replaceCard,
  saveCard,
  couponCode
}: {
  buyCreditInSeconds: number;
  cardHolderName?: string;
  cvc?: string;
  exp_month?: string;
  exp_year?: string;
  number?: string;
  replaceCard?: boolean;
  saveCard?: boolean;
  couponCode: string | undefined;
}): Promise<{ eventLogId: number; response: string }> {
  const res = await AxiosCustom.post(
    number
      ? `projects/checkout?buyCreditInSeconds=${buyCreditInSeconds}&replaceCard=${replaceCard}&cardHolderName=${cardHolderName?.replace(/\s/g,'+')}&cvc=${cvc}&exp_month=${exp_month}&exp_year=${exp_year}&saveCard=${saveCard}&number=${number.replace(/\s/g,'')}${couponCode ? `&couponCode=${couponCode}` : ''}`
      : `projects/checkout?buyCreditInSeconds=${buyCreditInSeconds}${couponCode ? `&couponCode=${couponCode}` : ''}`
  );
  return res.data;
}

export async function updatePlan({
  planType,
  cardHolderName,
  cvc,
  exp_month,
  exp_year,
  cardNumber,
  couponCode
}: {
  planType: EPlanOption;
  cardHolderName: string;
  cvc: number | string;
  exp_month: number | string;
  exp_year: number | string;
  cardNumber: number | string;
  couponCode: string | undefined;
}) {
  try {
    let requestString = `subscription/subscribe/${planType}?cardHolderName=${cardHolderName}&cvc=${cvc}&exp_month=${exp_month}&exp_year=${exp_year}&number=${cardNumber}`;
    if(couponCode) requestString += `&couponCode=${couponCode}`
    await AxiosCustom.post(requestString);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return error.response.data;
  }
}

export async function updatePlanWithSaveCard({ planType, couponCode }: { planType: EPlanOption, couponCode: string | undefined }) {
  try {
    let requestString = `subscription/subscribe/${planType}?use_saved_card=true`;
    if(couponCode) requestString += `&couponCode=${couponCode}`
    await AxiosCustom.post(requestString);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return error.response.data;
  }
}

export async function updateCreditCard({
  planType,
  cardHolderName,
  cvc,
  exp_year,
  exp_month,
  cardNumber,
  updatePayment,
  updateSubscriptionPayment
}: {
  planType: EPlanOption;
  cardHolderName: string;
  cvc: number | string;
  exp_month: number | string;
  exp_year: number | string;
  cardNumber: number | string;
  updatePayment: boolean;
  updateSubscriptionPayment: boolean;
}) {
  try {
    const res = await AxiosCustom.put(
      `subscription/updatePayment/${planType}?cardHolderName=${cardHolderName}&cvc=${cvc}&exp_month=${exp_month}&exp_year=${exp_year}&number=${cardNumber}&updatePayment=${updatePayment}&updateSubscriptionPayment=${updateSubscriptionPayment}`
    );
    return res.status;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return error.response.data;
  }
}

export async function deleteMyPlan(): Promise<boolean> {
  try {
    const res = await AxiosCustom.delete('subscription/user');
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error: any) {
    return false;
  }
}

export async function undoDeletePlan(): Promise<boolean> {
  try {
    const res = await AxiosCustom.post('subscription/user');
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error: any) {
    return false;
  }
}

export async function getMySubscription(): Promise<TSubscription | null> {
  try {
    const res = await AxiosCustom.get('subscription/user');
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}