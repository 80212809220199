import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import Logo from './../assets/img/share-project.png'
import { ERoutes } from 'ts/enums';

function PurchaseSuccessPage() {
  const params = useParams();
  const { type } = params;
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(type == "transcription") {
      setTimeout(() => {
        navigate(`${ERoutes.TRANSCRIPT_DETAIL}/${searchParams.get('projectId')}`);
      }, 5000);
    } else if(type?.startsWith("plan-")) {
      setTimeout(() => {
        navigate(`${ERoutes.MAIN}`);
      }, 5000);
    }
  }, []);

  return (
    <div className="purchase-success-page">
      <div className="container">
        <h2 className='text-center pt-6 mb-1'>Thank you!</h2>
        <h5 className='text-center mb-6'>Your new plan is now active.</h5>
        <div className='text-center mb-6'><img src={Logo}/></div>
        <p className='text-center'>You will be redirected in 5 seconds.</p>
      </div>
    </div>
  );
}

export default PurchaseSuccessPage;
