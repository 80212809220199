import { Button, Input, Table, notification } from 'antd';
import { getDetailTransaction, updateProjectName } from 'apis/admin/lastestTransaction.api';
import { getProjectsByUserID, mergeProjects } from 'apis/admin/mergeProject.api';
import CardFile from 'components/Admin/ProjectDetailPage/CardFile';
import { usePageLoading } from 'context/PageLoadingContext';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EHttpStatusCode, ERoutes } from 'ts/enums';
import { TProjectTransaction } from 'ts/types';

const columnsTable = [
  {
    title: 'Project Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Project Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Length Of Project',
    dataIndex: 'totalLengthOfProject',
    key: 'totalLengthOfProject'
  },
  {
    title: 'Creation Date (EST)',
    dataIndex: 'creationDate',
    key: 'creationDate'
  }
];

function MergeProjectsPage() {
  const { projectId, userId } = useParams();
  const { openMessage, destroyMessage } = usePageLoading();
  const [currentProjectDetail, setCurrentProjectDetail] = useState<TProjectTransaction | null>(
    null
  );
  const [projectNameInput, setProjectNameInput] = useState('');
  const [isDisableSaveName, setDisableSaveName] = useState(true);
  const [listProjects, setListProjects] = useState<TProjectTransaction[]>([]);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (projectId) {
      handleGetCurrentProjectDetail({ id: projectId });
    }
  }, [projectId]);

  useEffect(() => {
    if (userId) {
      handleGetListProjectsByUserId({ id: userId });
    }
  }, [userId]);

  async function handleGetCurrentProjectDetail({ id }: { id: string }) {
    try {
      openMessage('loading', 'Fetching project...');
      const res = await getDetailTransaction({ transactionId: id });
      if (res) {
        setProjectNameInput(res.name);
        setCurrentProjectDetail(res);
      }
      destroyMessage();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleGetListProjectsByUserId({ id }: { id: string }) {
    try {
      setIsLoadingTable(true);
      const res = await getProjectsByUserID({ userId: id });
      if (res) {
        setListProjects(res.content);
      }
      setIsLoadingTable(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  function handleOnChangeName(val: string) {
    setDisableSaveName(!(val !== currentProjectDetail?.name));
    setProjectNameInput(val);
  }

  async function handleUpdateProjectName() {
    if (!projectId || !currentProjectDetail) return;
    try {
      destroyMessage();
      openMessage('loading', 'Saving name...');
      const res = await updateProjectName({
        projectId: projectId,
        name: projectNameInput,
        userEmail: currentProjectDetail?.userEmail
      });
      if (res === EHttpStatusCode.OK) {
        destroyMessage();
        openMessage('success', 'Saved');
        handleGetCurrentProjectDetail({ id: projectId });
        setDisableSaveName(true);
      }
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleMergeProject() {
    if (!projectId || !userId) return;
    try {
      destroyMessage();
      openMessage('loading', 'Merging...');
      const res = await mergeProjects({
        target: projectId,
        sourceProjects: selectedIds as string[]
      });
      destroyMessage();
      if (res === EHttpStatusCode.OK) {
        openMessage('success', 'Merged!');
        handleGetCurrentProjectDetail({ id: projectId });
        handleGetListProjectsByUserId({ id: userId });
      }
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  return (
    <div>
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome
            size={30}
            color="var(--si-primary)"
            onClick={() => navigate(ERoutes.LASTEST_TRANSACTION)}
            className="cursor-pointer"
          />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            Merge Project
          </span>
        </div>
      </div>
      <div
        className="container py-3 px-3 my-3 rounded"
        style={{ background: 'var(--bs-gray-200)' }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <h4>Target Project</h4>
        </div>
        <div className="row my-3 g-3">
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">OWNER</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail?.username} / {currentProjectDetail?.userEmail}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">PROJECT CREATION</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {moment(currentProjectDetail?.creationDate).format('MM/DD/YY hh:mm A')}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">PROJECT NAME</small>
              <Button
                onClick={handleUpdateProjectName}
                className="ms-1"
                type="primary"
                disabled={isDisableSaveName || projectNameInput.length <= 0}
              >
                Save
              </Button>
            </div>
            <div className="border rounded" style={{ minHeight: 42, background: 'white' }}>
              <Input
                value={projectNameInput}
                onChange={(e) => handleOnChangeName(e.target.value)}
                placeholder="Project name"
                variant="borderless"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">PROJECT STATUS</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail?.status === 'ready'
                ? 'transcribed'
                : currentProjectDetail?.status}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">PROJECT URL</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              <Link
                to={`${ERoutes.TRANSCRIPT_DETAIL}/${currentProjectDetail?.id}?mediaId=${
                  currentProjectDetail?.files[currentProjectDetail?.files.length - 1].id
                }`}
                className="custom-a-tag"
              >
                Go to project
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">METHOD USED</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail && currentProjectDetail?.credit > 0
                ? 'Credit used'
                : 'Charged to credit card'}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">PROJECT LANGUAGE</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail?.languageDisplayName}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">LENGTH OF PROJECT</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail?.totalLengthOfProject}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">CREDITS USED</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {currentProjectDetail?.credit}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">AMOUNT SPENT</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(currentProjectDetail?.price ? currentProjectDetail.price : 0)}
            </div>
          </div>
        </div>
        <h4 className="mb-3">File(s):</h4>
        {currentProjectDetail?.files &&
          currentProjectDetail?.files.length > 0 &&
          currentProjectDetail?.files.map((file, index) => {
            return <CardFile key={file.id} file={file} index={index} readOnly />;
          })}
      </div>
      <div className="container p-3 my-3 rounded" style={{ background: 'var(--bs-gray-200)' }}>
        <div className="d-flex justify-content-between">
          <div>
            <h3>Projects to be merged into Target Project</h3>
          </div>
          <div>
            <Button onClick={handleMergeProject} type="primary" disabled={selectedIds.length <= 0}>
              Merge
            </Button>
          </div>
        </div>
        <Table
          loading={isLoadingTable}
          className="mt-2"
          dataSource={listProjects}
          columns={columnsTable}
          rowClassName={'cursor-pointer'}
          rowSelection={{
            preserveSelectedRowKeys: true,
            selectedRowKeys: selectedIds,
            onChange: (newSelectedRowKeys) => {
              setSelectedIds(newSelectedRowKeys);
            }
          }}
          rowKey={(val) => val.id}
          scroll={{ x: 0, y: 500 }}
          pagination={false}
        />
      </div>
    </div>
  );
}

export default MergeProjectsPage;
