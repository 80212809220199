import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { TFolder, TProject, TUser } from 'ts/types';
import { useDrop } from 'react-dnd';
import { EFolderIdDefault, EMoveProject, ETypeDragDrop } from 'ts/enums';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  changeCountFolder,
  updateEditedFolder,
  updateFolderSelected
} from 'redux/folder/folder.slice';
import {
  AiOutlineClose,
  AiOutlineDelete,
  AiOutlineFolder,
  AiOutlineFolderOpen,
  AiOutlineWarning
} from 'react-icons/ai';
import { LiaLayerGroupSolid } from 'react-icons/lia';
import {
  searchProjectListWithFolder,
  selectProjects,
  updateItemProjectFolder
} from 'redux/projects/projects.slice';
import { deleteFolderAction, editFolderAction } from 'redux/folder/folder.action';

import { getListProjectAction, moveProjectToFolderAction } from 'redux/projects/projects.action';
import { usePageLoading } from 'context/PageLoadingContext';
import { Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListProjects } from './ListProjects';
import { useLoaderContext } from 'context/LoaderContext';

type Props = {
  folder: TFolder;
  startIcon?: ReactNode;
  isDrop?: boolean;
  count: number;
  disableHover?: boolean;
  overrideName?: string;
};

export function ChildFolder({
  folder,
  startIcon,
  isDrop = true,
  count,
  disableHover = false,
  overrideName = undefined
}: Props) {
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>(folder.name);
  const inputRef = useRef<HTMLInputElement>(null);
  const currentFolderSelected = useAppSelector((state) => state.folder.currentFolderSelected);
  const isSelected = currentFolderSelected === folder.id;
  const { openMessage, destroyMessage } = usePageLoading();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isOpenDeleteFolderConfirm, setIsOpenDeleteFolderConfirm] = useState<boolean>(false);
  const { t } = useTranslation();
  const { loader } = useLoaderContext();
  const projectSelected = useAppSelector((state) => state.projects.projectSelected);
  const isSelectMultiple = useAppSelector((state) => state.projects.isSelectMultiple);
  const isSelectMultipleRef = useRef(true);
  const currentUser = useAppSelector((state) => state.auth.currentUser.data);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ETypeDragDrop.PROJECT,
    drop: (item: { project: TProject; currentUser: TUser }) => {
      handleMoveProject(item);
    },
    canDrop: () => isDrop,
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  }));
  const projectSelectedRef = useRef(projectSelected);

  useEffect(() => {
    projectSelectedRef.current = projectSelected;
  }, [projectSelected]);

  function moveOneProject(item: { project: TProject; currentUser: TUser }) {
    const { project, currentUser } = item;
    const previousFolder: TFolder | null = project.directory;
    if (folder.id === project.directory?.id) {
      openMessage('warning', t('Pages.Main.Errors.ProjectAlreadyExists'));
      return;
    }
    if (project.owner?.id !== currentUser.id) {
      openMessage('warning', t('Pages.Main.Errors.NowAllowedToMoveSharedProjects'));
      return;
    }
    destroyMessage();
    openMessage('loading', t('Shared.Texts.MovingW3Dot'));
    loader.start();
    if (previousFolder) {
      dispatch(changeCountFolder({ folderID: previousFolder.id, count: -1 }));
    }
    dispatch(
      updateItemProjectFolder({
        folder,
        projectsId: [project.id],
        isFrom:
          folder.id === EFolderIdDefault.PROJECT_ALL
            ? EMoveProject.FOLDER_TO_MAIN
            : EMoveProject.MAIN_TO_FOLDER
      })
    );
    dispatch(changeCountFolder({ folderID: folder.id, count: 1 }));
    if (previousFolder) {
      dispatch(searchProjectListWithFolder({ folderID: previousFolder.id }));
    }

    dispatch(
      moveProjectToFolderAction({
        folder,
        projectsId: [project.id],
        isFrom:
          folder.id === EFolderIdDefault.PROJECT_ALL
            ? EMoveProject.FOLDER_TO_MAIN
            : EMoveProject.MAIN_TO_FOLDER
      })
    ).then(async (value: any) => {
      loader.complete();
      destroyMessage();
      if (value.payload != '500') {
        openMessage('success', t('Shared.Texts.Moved'));
        dispatch(selectProjects({ projects: [] }));
      } else {
        //back project if fails
        if (previousFolder) {
          dispatch(changeCountFolder({ folderID: previousFolder.id, count: 1 }));
        }
        dispatch(
          updateItemProjectFolder({
            folder: previousFolder || folder,
            projectsId: [project.id],
            isFrom: previousFolder ? EMoveProject.MAIN_TO_FOLDER : EMoveProject.FOLDER_TO_MAIN
          })
        );
        dispatch(changeCountFolder({ folderID: folder.id, count: -1 }));
        if (previousFolder) {
          dispatch(searchProjectListWithFolder({ folderID: previousFolder.id }));
        }
        openMessage('error', t('Pages.Main.Errors.NowAllowedToMoveToThisFolder'));
      }
    });
  }

  function moveMultipleProject(item: { project: TProject; currentUser: TUser }) {
    const { project, currentUser } = item;
    const projectSelected = projectSelectedRef.current;

    const projectsToMove = projectSelected.filter(
      (project) => project.owner?.id === currentUser.id
    );
    const previousFolder: TFolder | null = project.directory;

    if (projectsToMove.length === 0) {
      openMessage('warning', t('Pages.Main.Errors.NoProjectsSelected'));
      return;
    }

    const projectIds = projectsToMove.map((project) => project.id);
    const previousFolders = projectsToMove.map((project) => project.directory);

    projectsToMove.forEach((project) => {
      if (folder.id === project.directory?.id) {
        openMessage('warning', t('Pages.Main.Errors.ProjectAlreadyExists'));
        return;
      }
      if (project.owner?.id !== currentUser.id) {
        openMessage('warning', t('Pages.Main.Errors.NowAllowedToMoveSharedProjects'));
        return;
      }
    });

    destroyMessage();
    openMessage('loading', t('Shared.Texts.MovingW3Dot'));
    loader.start();

    previousFolders.forEach((previousFolder) => {
      if (previousFolder) {
        dispatch(changeCountFolder({ folderID: previousFolder.id, count: -1 }));
      }
    });

    dispatch(
      updateItemProjectFolder({
        folder,
        projectsId: projectIds,
        isFrom:
          folder.id === EFolderIdDefault.PROJECT_ALL
            ? EMoveProject.FOLDER_TO_MAIN
            : EMoveProject.MAIN_TO_FOLDER
      })
    );

    dispatch(changeCountFolder({ folderID: folder.id, count: projectIds.length }));

    previousFolders.forEach((previousFolder) => {
      if (previousFolder) {
        dispatch(searchProjectListWithFolder({ folderID: previousFolder.id }));
      }
    });

    dispatch(
      moveProjectToFolderAction({
        folder,
        projectsId: projectIds,
        isFrom:
          folder.id === EFolderIdDefault.PROJECT_ALL
            ? EMoveProject.FOLDER_TO_MAIN
            : EMoveProject.MAIN_TO_FOLDER
      })
    ).then(async (value: any) => {
      loader.complete();
      destroyMessage();
      if (value.payload != '500') {
        openMessage('success', t('Shared.Texts.Moved'));
        dispatch(selectProjects({ projects: [] }));
      } else {
        previousFolders.forEach((previousFolder) => {
          if (previousFolder) {
            dispatch(changeCountFolder({ folderID: previousFolder.id, count: 1 }));
          }
        });
        dispatch(
          updateItemProjectFolder({
            folder: previousFolder || folder,
            projectsId: projectIds,
            isFrom: previousFolder ? EMoveProject.MAIN_TO_FOLDER : EMoveProject.FOLDER_TO_MAIN
          })
        );
        dispatch(changeCountFolder({ folderID: folder.id, count: -projectIds.length }));
        previousFolders.forEach((previousFolder) => {
          if (previousFolder) {
            dispatch(searchProjectListWithFolder({ folderID: previousFolder.id }));
          }
        });
        openMessage('error', t('Pages.Main.Errors.NowAllowedToMoveToThisFolder'));
      }
    });
  }

  useEffect(() => {
    isSelectMultipleRef.current = isSelectMultiple;
  }, [isSelectMultiple]);

  function handleMoveProject(item: { project: TProject; currentUser: TUser }) {
    if (!isDrop) return;
    if (isSelectMultipleRef.current) {
      moveMultipleProject(item);
    } else {
      moveOneProject(item);
    }
  }

  function handleDeleteFolder() {
    if (isDisabled) return;
    loader.start();
    setIsDisabled(true);
    destroyMessage();
    openMessage('loading', t('Shared.Texts.DeletingW3Dot'));
    dispatch(deleteFolderAction({ folderId: folder.id })).then(() => {
      destroyMessage();
      openMessage('success', t('Shared.Texts.Deleted'));
      setIsDisabled(false);
      if (currentUser) {
        dispatch(getListProjectAction({ currentUser })).then(() => {
          destroyMessage();
          loader.complete();
        });
      }
      loader.complete();
    });
  }

  function chooseFolder() {
    if (isDisabled) return;
    dispatch(updateFolderSelected({ folderId: folder.id }));
    dispatch(searchProjectListWithFolder({ folderID: folder.id }));
  }

  function changeNameFolder() {
    if (isDisabled) return;
    setIsDisabled(true);
    dispatch(updateEditedFolder({ folder, isEdit: false }));
    if (!name) {
      setName(folder.name);
      setIsDisabled(false);
      return;
    }
    const nameNoHtml = name.replace(/<[^>]+>/g, '');
    if (nameNoHtml !== folder.name) {
      destroyMessage();
      loader.start();
      openMessage('loading', t('Shared.Texts.UpdatingW3Dot'));

      dispatch(
        editFolderAction({
          id: folder.id,
          name: nameNoHtml,
          encodedParentId: folder.encodedParentId || undefined
        })
      ).then(() => {
        destroyMessage();
        setIsDisabled(false);
        loader.complete();
      });
    } else {
      setIsDisabled(false);
    }
  }

  return (
    <div
      className={`folder-item
      ${isSelected ? '--selected' : ''} 
      ${isDrop && isOver ? 'is-over' : 'cursor-disable'}
      ${folder.encodedParentId ? '--child' : ''}
      `}
      ref={drop}
      onClick={(e) => {
        e.stopPropagation();
        chooseFolder();
      }}
    >
      <div className="d-flex align-items-center justify-content-between px-2 py-0">
        <div className="icon-and-name d-flex align-items-center">
          <div className="icon me-1">
            {startIcon ||
              (isSelected ? <AiOutlineFolderOpen size={28} /> : <AiOutlineFolder size={28} />)}
          </div>
          {folder.isEdited && isDrop && !isDisabled ? (
            <input
              style={!disableHover ? { fontWeight: 400 } : {}}
              type="text"
              value={overrideName || name}
              ref={inputRef}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onFocus={(e) => {
                e.target.select();
              }}
              autoFocus
              onChange={(e) => {
                setName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  changeNameFolder();
                  return;
                }
              }}
              onBlur={() => {
                changeNameFolder();
                return;
              }}
            />
          ) : (
            <span
              style={!disableHover ? { fontWeight: 400 } : {}}
              onClick={(e) => {
                e.stopPropagation();
                if (disableHover) {
                  chooseFolder();
                } else {
                  dispatch(updateEditedFolder({ folder, isEdit: true }));
                }
              }}
              className={` ${disableHover ? '--disable-hover' : ''} p-1`}
            >
              {overrideName || name}
            </span>
          )}
        </div>
        <div className="count-and-icon gap-1 d-none d-md-flex align-items-center">
          <span className="d-flex align-items-center">
            <span className="align-middle" style={{ height: 16 }}>
              {count}
            </span>{' '}
            <span className="icon-folder ">
              <LiaLayerGroupSolid size={17} color="var(--si-dim-gray)" />
            </span>
          </span>
          {folder.id !== EFolderIdDefault.PROJECT_ALL &&
            folder.id !== EFolderIdDefault.MY_PROJECT &&
            folder.id !== EFolderIdDefault.SHARED_WITH_ME && (
              <div className="delete-icon" onClick={(e) => e.stopPropagation()}>
                <Tooltip title={t('Shared.Actions.Delete')}>
                  <button
                    onClick={() => {
                      setIsOpenDeleteFolderConfirm((prev) => !prev);
                    }}
                  >
                    <AiOutlineDelete size={17} color="var(--si-primary)" />
                  </button>
                </Tooltip>
                <Modal
                  open={isOpenDeleteFolderConfirm}
                  width={300}
                  onOk={handleDeleteFolder}
                  onCancel={() => {
                    setIsOpenDeleteFolderConfirm(false);
                  }}
                  mask={false}
                  closeIcon={<AiOutlineClose color="var(--si-white)" />}
                  footer={[
                    <button
                      className="no-button"
                      key={'no'}
                      onClick={() => {
                        setIsOpenDeleteFolderConfirm(false);
                      }}
                    >
                      {t('Shared.Texts.No')}
                    </button>,
                    <button
                      className="yes-button"
                      key={'yes'}
                      onClick={() => {
                        setIsOpenDeleteFolderConfirm(false);
                        handleDeleteFolder();
                      }}
                    >
                      {t('Shared.Texts.Yes')}
                    </button>
                  ]}
                  wrapClassName="modal-delete-folder"
                >
                  <div className="d-flex">
                    <div className="d-flex align-items-center me-1">
                      <AiOutlineWarning size={30} color="var(--si-white)" />
                    </div>
                    <div className="warning-text">
                      {t('Pages.Main.Texts.FolderDeletionConfirm')}
                    </div>
                  </div>
                </Modal>
              </div>
            )}
        </div>
      </div>
      {isSelected &&
        folder.id !== EFolderIdDefault.PROJECT_ALL &&
        folder.id !== EFolderIdDefault.MY_PROJECT &&
        folder.id !== EFolderIdDefault.SHARED_WITH_ME && (
          <div className="d-block d-lg-none">{<ListProjects />}</div>
        )}
    </div>
  );
}
