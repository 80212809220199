import { TContact, TSubscription, TUser } from 'ts/types';
import { TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from './axios-client';

export async function getUserInfor(inviteCode?: string): Promise<TResponse<TUser>> {
  const res = await AxiosCustom.get(`user`, { params: { ...(inviteCode ? { inviteCode } : {}) } });
  return res.data;
}

export async function changeContact({ contact }: { contact: TContact | null }) {
  const res = await AxiosCustom.put(`/user/contact`, contact);

  return res.data;
}

export async function deleteUser() {
  const res = await AxiosCustom.delete(`user`);
  return res.data;
}

export async function getAuthorize(): Promise<TResponse<{ boxURI: string }>> {
  const res = await AxiosCustom.get(`box-auth/oauth2/`);
  return res.data;
}

export async function deAuthorize() {
  const res = await AxiosCustom.get(`box-auth/oauth2/deauthorize`);
  return res.data;
}

export async function fetchSubscription(): Promise<TResponse<TSubscription>> {
  const res = await AxiosCustom.get(`subscription/user`);
  return res.data;
}
