import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createDictionary,
  deleteDictionary,
  getDictionary,
  updateDictionary
} from 'apis/dictionary.api';
import { TDictionary } from 'ts/types';
import { TRequestCreateDictionary, TRequestUpdateDictionary } from 'ts/types/TRequest';

export const getDictionaryAction = createAsyncThunk(
  'dictionary/get-list-dictionary',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getDictionary();
      return res;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const createDictionaryAction = createAsyncThunk(
  'dictionary/create-dictionary',
  async ({ dictionary }: { dictionary: TRequestCreateDictionary }, { rejectWithValue }) => {
    try {
      const res = await createDictionary({ dictionary });
      return res;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteDictionaryAction = createAsyncThunk(
  'dictionary/delete-dictionary',
  async ({ dictionary }: { dictionary: TDictionary }, { rejectWithValue }) => {
    try {
      const res = await deleteDictionary({ dictionary });
      return res;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateDictionaryAction = createAsyncThunk(
  'dictionary/update-dictionary',
  async ({ dictionary }: { dictionary: TRequestUpdateDictionary }, { rejectWithValue }) => {
    try {
      const res = await updateDictionary({ dictionary });
      return res;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
