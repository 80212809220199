/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { TProjectTags } from 'ts/types';
import {
  createProjectTagsAction,
  deleteProjectTagAction,
  getListProjectTagsAction
} from './project-tags.action';

type ProjectTagsState = {
  currentListProjectTags: {
    data: TProjectTags[];
    isLoading: boolean;
    error: any;
    isFetched: boolean;
  };
};

const initialState: ProjectTagsState = {
  currentListProjectTags: {
    data: [],
    isLoading: false,
    error: null,
    isFetched: false
  }
};

const projectTagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    //Get project tag
    addCase(getListProjectTagsAction.pending, (state) => {
      state.currentListProjectTags = { data: [], isLoading: true, error: null, isFetched: false };
    });

    addCase(getListProjectTagsAction.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentListProjectTags = {
          data: payload,
          isLoading: false,
          error: null,
          isFetched: true
        };
      }
    });

    addCase(getListProjectTagsAction.rejected, (state, { payload }) => {
      state.currentListProjectTags = {
        data: [],
        isLoading: false,
        error: payload,
        isFetched: true
      };
    });

    //Create - Update project tag
    addCase(createProjectTagsAction.pending, (state) => {
      state.currentListProjectTags = { ...state.currentListProjectTags, isLoading: true };
    });

    addCase(createProjectTagsAction.fulfilled, (state, { payload }) => {
      if (payload) {
        const tmpListProjectTags = [...state.currentListProjectTags.data];

        const newList = tmpListProjectTags.filter(
          (projectTag) => projectTag.projectId !== payload.projectId
        );

        state.currentListProjectTags = {
          ...state.currentListProjectTags,
          data: [...newList, payload],
          isLoading: false
        };
      }
    });

    addCase(createProjectTagsAction.rejected, (state, { payload }) => {
      state.currentListProjectTags = {
        data: [],
        isLoading: false,
        error: payload,
        isFetched: true
      };
    });

    //Delete project tag
    addCase(deleteProjectTagAction.pending, (state) => {
      state.currentListProjectTags = { ...state.currentListProjectTags, isLoading: true };
    });

    addCase(deleteProjectTagAction.fulfilled, (state, { payload }) => {
      if (payload) {
        const tmpListProjectTags = [...state.currentListProjectTags.data];

        const newList = tmpListProjectTags.filter((projectTag) => projectTag.id !== payload.id);

        state.currentListProjectTags = {
          ...state.currentListProjectTags,
          data: [...newList],
          isLoading: false
        };
      }
    });

    addCase(deleteProjectTagAction.rejected, (state, { payload }) => {
      state.currentListProjectTags = {
        data: [],
        isLoading: false,
        error: payload,
        isFetched: true
      };
    });
  }
});

export default projectTagsSlice.reducer;
