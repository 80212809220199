import { Popover } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';

type Props = {
  hours: number;
  minutes: number;
  isVisibleInfo?: boolean;
  nameMedia?: string;
  minuteMedia?: number;
  hoursMedia?: number;
  className?: string;
};

export function TimeDisplay({
  hours,
  minutes,
  isVisibleInfo = false,
  nameMedia,
  minuteMedia,
  hoursMedia,
  className
}: Props) {
  const { t } = useTranslation();

  function content() {
    return (
      <div
        className={`d-flex gap-1 ${className}`}
        style={{ maxWidth: 250, color: 'var(--si-white)' }}
      >
        <div
          className="name-media"
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          <div>{t('Shared.Fields.Name')}</div>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{nameMedia}</div>
        </div>
        {hoursMedia && hoursMedia > 0 ? (
          <div className="hour-media">
            <div>{t('Shared.Fields.hour', { count: hoursMedia })}</div>
            <div>{hoursMedia}</div>
          </div>
        ) : null}

        <div className="minute-media">
          <div>{t('Shared.Fields.minute', { count: minuteMedia })}</div>
          <div>{minuteMedia}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <span className={`d-none d-sm-inline-block ${className}`}>
        {hours > 0 && minutes > 0 && (
          <span className="fw-bolder align-middle">
            {hours} {t('Shared.Fields.hour', { count: hours })}{' '}
            <span className="fw-normal"> {t('Shared.Fields.and')} </span> {minutes}{' '}
            {t('Shared.Fields.minute', { count: minutes })}
          </span>
        )}
        {minutes >= 0 && hours === 0 && (
          <span className="fw-bolder align-middle">
            {minutes} {t('Shared.Fields.minute', { count: minutes })}{' '}
          </span>
        )}
        {hours > 0 && minutes === 0 && (
          <span className="fw-bolder align-middle">
            {hours} {t('Shared.Fields.hour', { count: hours })}
          </span>
        )}
        {isVisibleInfo && (
          <Popover
            content={content}
            trigger={['hover', 'click']}
            className="info-media-translate"
            placement="top"
            overlayClassName="dark"
          >
            <span>
              <FaInfoCircle size={12} color="var(--si-old-silver)" />
            </span>
          </Popover>
        )}
      </span>
      <span className="d-inline-block d-sm-none" style={{ fontSize: 12 }}>
        {hours > 0 && <span className="fw-bolder align-middle">{hours} h</span>}{' '}
        {minutes >= 0 && <span className="fw-bolder align-middle">{minutes} m</span>}
        {isVisibleInfo && (
          <Popover
            content={content}
            trigger={['hover', 'click']}
            className="info-media-translate"
            placement="top"
            overlayClassName="dark"
          >
            <span>
              <FaInfoCircle size={12} />
            </span>
          </Popover>
        )}
      </span>
    </>
  );
}
