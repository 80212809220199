import { TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from './axios-client';
import { TFolder } from 'ts/types';
import { TRequestCreateNewFolder, TRequestEditFolder } from 'ts/types/TRequest';

export async function getListFolder(): Promise<TResponse<Array<TFolder>>> {
  const res = await AxiosCustom.get(`directory`);
  return res.data;
}

export async function createFolder({
  name,
  empty,
  encodedParentId
}: TRequestCreateNewFolder): Promise<TResponse<TFolder>> {
  const res = await AxiosCustom.post(`directory`, { name, empty, encodedParentId });
  return res.data;
}

export async function deleteFolder({
  folderId
}: {
  folderId: string;
}): Promise<TResponse<{ message: string }>> {
  const res = await AxiosCustom.delete(`directory/remove/${folderId}`);
  return res.data;
}

export async function editFolder({ name, id, encodedParentId }: TRequestEditFolder): Promise<TResponse<TFolder>> {
  const res = await AxiosCustom.post(`directory`, { name, id, encodedParentId });
  return res.data;
}
