import {
  AccountSettingNav,
  CloseAccountSection,
  ConnectedAppsSection,
  EarnFreeCreditSection,
  InformationSection,
  InvoicesSection,
  TransactionHistorySection
} from 'components/AccountSetting';
import { LoginMethodSection } from 'components/AccountSetting/LoginMethodSection';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ProfilePage() {
  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        const mainElement = document.getElementById('main-element');

        if (element) {
          const top = element?.getBoundingClientRect().top - 150;
          mainElement?.scrollTo({
            top: top,
            behavior: 'smooth'
          });
        }
      }, 150);
    }
  }, []);

  return (
    <div className="ctn-account-setting-page container d-flex justify-content-center">
      <AccountSettingNav />
      <div className="account-setting-body">
        <InformationSection />
        <LoginMethodSection />
        <EarnFreeCreditSection />
        <InvoicesSection />
        <TransactionHistorySection />
        <ConnectedAppsSection />
        <CloseAccountSection />
      </div>
    </div>
  );
}

export default ProfilePage;
