import { useTranslation } from 'react-i18next';
import IcGDrive from 'assets/icons/IcGDrive';
import React from 'react';
import { AiOutlineCloudUpload, AiOutlineDropbox } from 'react-icons/ai';
import IcBox from 'assets/icons/IcBox';

type Props = {
  onClickFileUpload: () => void;
  onClickDropboxUpload: () => void;
  onClickGDriveUpload: () => void;
  onClickBoxUpload: () => void;
};

function IconsUpload({
  onClickFileUpload,
  onClickDropboxUpload,
  onClickGDriveUpload,
  onClickBoxUpload
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="ctn-icons-upload d-flex gap-2" id="list-option-upload">
      <div className="icon-upload wavy" onClick={onClickFileUpload}>
        <AiOutlineCloudUpload size={48} color="#fff" />
        <span>{t('Pages.UploadMedia.Texts.UploadWithLocal')}</span>
      </div>
      <div className="icon-upload dropbox wavy" onClick={onClickDropboxUpload}>
        <AiOutlineDropbox size={48} color="#fff" />
        <span>{t('Pages.UploadMedia.Texts.UploadWithDropbox')}</span>
      </div>
      <div className="icon-upload g-drive wavy" onClick={onClickGDriveUpload}>
        <IcGDrive size={48} />
        <span>{t('Pages.UploadMedia.Texts.UploadWithGD')}</span>
      </div>
      <div className="icon-upload box wavy" onClick={onClickBoxUpload}>
        <IcBox size={48} />
        <span>{t('Pages.UploadMedia.Texts.UploadWithBox')}</span>
      </div>
    </div>
  );
}

export default IconsUpload;
