import { Radio, Switch, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  EDelayTime,
  EModals,
  EPlanOption,
  EPlanType,
  ERoutes,
  ETypeNoti,
  ETypeSubcribeModal,
} from "ts/enums";
import { TPlan } from "ts/types";
import { IoInformationCircle } from "react-icons/io5";
import { FaMagic } from "react-icons/fa";
import { CreditCard } from "./CreditCard";
import { VoucherBox } from "./VoucherBox";
import { popModal, pushModal } from "redux/modal/modal.slice";
import {
  getPlan,
  updatePlan,
  updatePlanWithSaveCard,
} from "apis/subscribe.api";
import { useCreditCard } from "context/CreditCardContext";
import { Loader } from "./Loader";
import { getUserInfoAction } from "redux/auth/auth.action";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import {
  CouponVerification,
  StripeTransactionDetail,
  SubscribeTransactionDetail,
} from "ts/types/interface";
import { pushStripeTransactionTracking, pushSubscribeTracking } from "utils/common";
import { usePageLoading } from "context/PageLoadingContext";

type Props = {
  type: string;
};

declare global {
  interface Window {
    dataLayer: object[];
  }
}

export function SubscribeBody({ type }: Props) {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [listPlansAvaiable, setListPlansAvaiable] = useState<TPlan[]>([]);
  const [listPlansOptions, setListPlansOptions] = useState<TPlan[]>();
  const [selectedPlan, setSelectedPlan] = useState<TPlan | null>(null);
  const [upgradePlanType, setUpgradePlanType] = useState<EPlanType>(
    EPlanType.MONTHLY
  );
  const dispatch = useAppDispatch();
  const {
    cardData,
    cardName,
    expiryMonthData,
    expiryYearData,
    cvcData,
    isExistedCard,
  } = useCreditCard({
    currentUser: currentUser.data,
  });
  const [isEnable, setIsEnable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponApplied, setCouponApplied] = useState<CouponVerification | null>(
    null
  );
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [resetCouponApply, setResetCouponApply] = useState<boolean>(false);
  //const navigate = useNavigate();
  const { openMessage, destroyMessage } = usePageLoading();

  useEffect(() => {
    if (searchParams.has("subscribePrefilledCoupon")) {
      setCouponCode(searchParams.get("subscribePrefilledCoupon") || "");
    }
  }, []);

  useEffect(() => {
    (async () => {
      const listPlans = await getPlan();
      const filterListPlansActive = listPlans.filter((plan) => plan.active);
      setListPlansAvaiable(filterListPlansActive);
    })();
  }, []);

  useEffect(() => {
    if(resetCouponApply == true) setResetCouponApply(false);
  }, [resetCouponApply])

  useEffect(() => {
    if (loading) return;
    if (type === ETypeSubcribeModal.UPDRAGE) {
      if (currentUser.data?.plan.upgradeOptions) {
        const tempList = listPlansAvaiable.filter((plan) => {
          if (upgradePlanType === EPlanType.MONTHLY) {
            return (
              currentUser.data?.plan.upgradeOptions.includes(plan.planEnum) &&
              plan.interval === "month"
            );
          }
          if (upgradePlanType === EPlanType.ANNUAL) {
            return (
              currentUser.data?.plan.upgradeOptions.includes(plan.planEnum) &&
              plan.interval === "year"
            );
          }
          return currentUser.data?.plan.upgradeOptions.includes(plan.planEnum);
        });
        setSelectedPlan(tempList[0]);
        setListPlansOptions(tempList);
      }
    }
    if (type === ETypeSubcribeModal.DOWNGRADE) {
      if (currentUser.data?.plan.downgradeOptions) {
        const tempList = listPlansAvaiable.filter((plan) => {
          if (upgradePlanType === EPlanType.MONTHLY) {
            return (
              currentUser.data?.plan.downgradeOptions.includes(plan.planEnum) &&
              plan.interval === "month"
            );
          }
          if (upgradePlanType === EPlanType.ANNUAL) {
            return (
              currentUser.data?.plan.downgradeOptions.includes(plan.planEnum) &&
              plan.interval === "year"
            );
          }
          return currentUser.data?.plan.downgradeOptions.includes(
            plan.planEnum
          );
        });
        setSelectedPlan(tempList[0]);
        setListPlansOptions(tempList);
      }
    }
  }, [listPlansAvaiable, currentUser, upgradePlanType, type]);

  useEffect(() => {
    if (isExistedCard) {
      setIsEnable(true);
      return;
    }

    if (
      cardData &&
      cardData.cardNumber &&
      cardName &&
      expiryMonthData &&
      expiryMonthData.data &&
      expiryYearData &&
      expiryYearData.data &&
      cvcData &&
      cvcData.data
    ) {
      setIsEnable(true);
    } else {
      setIsEnable(false);
    }
  }, [
    cardData,
    cardName,
    expiryMonthData,
    expiryYearData,
    cvcData,
    isExistedCard,
  ]);

  async function handleUpdatePlan() {
    if (!currentUser.data || !selectedPlan) return;
    setLoading(true);
    setIsEnable(false);

    const currentStripeTransactionDetail: StripeTransactionDetail = {
      currency: "USD",
      amount:
        TransformPrice(couponApplied, selectedPlan.subscriptionCharge) * 100,
      chargeId: null,
      userEmail: currentUser.data.email,
      itemName: `Subscribe: ${selectedPlan.fullLabel}`,
      itemQuantity: 1,
      itemPrice: selectedPlan.subscriptionCharge,
      applicationFee: null,
    };

    const subscribeTransactionDetail: SubscribeTransactionDetail = {
      subscriptionType: selectedPlan.type,
      planType: selectedPlan.label,
      duration: selectedPlan.interval,
      price: selectedPlan.subscriptionCharge,
      discount: couponApplied ? selectedPlan.subscriptionCharge - TransformPrice(couponApplied, selectedPlan.subscriptionCharge) : 0.00,
      couponCode: couponApplied ? couponApplied.couponCode : null
    }

    if (isExistedCard && selectedPlan) {
      const res = await updatePlanWithSaveCard({
        planType: selectedPlan?.planEnum,
        couponCode: couponApplied ? couponApplied.couponCode : "",
      });
      dispatch(popModal());

      if (res && "message" in res) {
        setLoading(false);
        setIsEnable(true);
        dispatch(
          pushModal({
            name: EModals.NOTI_MODAL,
            data: null,
            notiData: { type: ETypeNoti.ERROR, title: res.message },
          })
        );
        return;
      }

      setLoading(false);
      setIsEnable(true);

      pushStripeTransactionTracking(currentStripeTransactionDetail);
      pushSubscribeTracking(subscribeTransactionDetail);

      setTimeout(() => {
        destroyMessage();
        dispatch(getUserInfoAction());
      }, 5000);

      openMessage('loading', t('Shared.Texts.LoadingW3Dot'));
      //navigate(`${ERoutes.PURCHASE_SUCCESS}/plan-${selectedPlan.name}`);
      searchParams.delete("subscribePrefilledCoupon");
      setSearchParams(searchParams);
      return;
    }

    if (selectedPlan && cardData) {
      const res = await updatePlan({
        planType: selectedPlan?.planEnum,
        cardHolderName: cardName.data,
        cvc: cvcData.data,
        exp_month: expiryMonthData.data,
        exp_year: expiryYearData.data,
        cardNumber: cardData?.cardNumber,
        couponCode: couponApplied ? couponApplied.couponCode : "",
      });

      if (res && "message" in res) {
        setLoading(false);
        setIsEnable(true);
        dispatch(
          pushModal({
            name: EModals.NOTI_MODAL,
            data: null,
            notiData: { type: ETypeNoti.ERROR, title: res.message },
          })
        );
        return;
      }
      setLoading(false);
      setIsEnable(true);
      pushStripeTransactionTracking(currentStripeTransactionDetail);
      pushSubscribeTracking(subscribeTransactionDetail);

      setTimeout(() => {
        destroyMessage();
        dispatch(getUserInfoAction());
      }, 5000);

      openMessage('loading', t('Shared.Texts.LoadingW3Dot'));
      //navigate(`${ERoutes.PURCHASE_SUCCESS}/plan-${selectedPlan.name}`);
      dispatch(popModal());
      searchParams.delete("subscribePrefilledCoupon");
      setSearchParams(searchParams);
      return;
    }
  }

  const isHasOptions = listPlansOptions && listPlansOptions?.length > 0;
  return (
    <div className="subscriber-body mb-2">
      <div className="current-plan pb-1">
        <div>
          <span>{t("Layouts.SubscribeModal.YourCurrentPlan")}: </span>
          <strong>{currentUser.data?.plan.fullLabel}</strong>
        </div>
        <small className="current-plan-description">
          <Trans t={t} i18nKey="Layouts.SubscribeModal.Tip01">
            Here is the
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.simonsaysai.com/pricing"
            >
              Link
            </a>
          </Trans>
        </small>
      </div>
      <div className="upgrade-plan">
        {isHasOptions && (
          <div className="title">
            {t("Layouts.SubscribeModal.YourNewPlan")} :{" "}
            <strong>{selectedPlan?.fullLabel}</strong>
          </div>
        )}

        <div className="type-selection">
          <div className="row">
            <div className="col-12 col-sm-5 d-flex align-items-center gap-1">
              <strong
                className={`type-monthly ${
                  upgradePlanType === EPlanType.MONTHLY ? "active" : "disable"
                }`}
              >
                {t("Shared.Texts.Monthly")}
              </strong>
              <Switch
                size="small"
                className="select-type-switch"
                value={upgradePlanType == EPlanType.ANNUAL}
                onChange={(value: boolean) => {
                  if (value) {
                    setUpgradePlanType(EPlanType.ANNUAL);
                  } else {
                    if(couponApplied && couponApplied.couponCode == 'ANNUAL40') { // Nope
                      setCouponApplied(null);
                      setResetCouponApply(true);
                    }

                    setUpgradePlanType(EPlanType.MONTHLY);
                  }
                }}
              />
              <strong
                className={`type-annual ${
                  upgradePlanType === EPlanType.ANNUAL ? "active" : "disable"
                }`}
              >
                {t("Shared.Texts.Annual")}
              </strong>
            </div>
            <div className="col-12 col-sm-7 fw-bolder">
              {t("Layouts.SubscribeModal.Tip02")}
            </div>
          </div>
        </div>
        <div className="plan-selection d-flex gap-5 pb-2">
          {listPlansOptions &&
            listPlansOptions.map((plan) => {
              const isRecommended =
                plan.planEnum === EPlanOption.PRO_ANNUAL ||
                plan.planEnum === EPlanOption.PRO_MONTHLY;
              return (
                <div key={plan.name} className="row mt-2">
                  <div className="d-flex align-items-center">
                    <Radio
                      checked={selectedPlan?.planEnum == plan.planEnum}
                      value={plan.planEnum}
                      className="invert-radio-box me-2"
                      onChange={(checked) => {
                        if (checked) {
                          setSelectedPlan(plan);
                        }
                      }}
                    />
                    <div>
                      <div
                        className={`plan-name text-capitalize text-nowrap ${
                          isRecommended ? "recommended" : ""
                        } `}
                      >
                        {plan.label}{" "}
                        {isRecommended && (
                          <small style={{ verticalAlign: "top" }}>
                            {t("Shared.Texts.Recommend")}
                          </small>
                        )}
                      </div>
                      <div
                        className={`plan-price ${
                          isRecommended ? "recommended" : ""
                        } `}
                      >
                        <small>
                          ${plan.subscriptionCharge}/
                          {upgradePlanType === EPlanType.MONTHLY
                            ? "month"
                            : "yr"}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="ctn-total-price mt-2 mb-1">
          {isHasOptions ? (
            <div className="row gx-5">
              <div className="col-12 col-md-6 d-flex flex-column align-items-center px-4 px-md-0">
                <span className="text-center status">
                  {t("Layouts.SubscribeModal.SubscriptionLabel", {
                    name: selectedPlan?.fullLabel,
                  })}{" "}
                </span>
                <div className="d-flex align-items-end">
                  <div className="total-price">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(
                      TransformPrice(
                        couponApplied,
                        selectedPlan?.subscriptionCharge || 0
                      )
                    )}
                  </div>
                  <div className="ms-1 unit">USD</div>
                </div>
                <div className="detail-plan mt-1 d-flex align-items-center">
                  <span className="me-1">
                    {t("Layouts.SubscribeModal.PackageInfo", {
                      creditHoursText: `${
                        selectedPlan?.creditInMinutes &&
                        TransformBonusCredit(
                          couponApplied,
                          Math.floor(selectedPlan.creditInMinutes / 60)
                        )
                      } ${t("Shared.Fields.hour", {
                        count:
                          selectedPlan?.creditInMinutes &&
                          TransformBonusCredit(
                            couponApplied,
                            Math.floor(selectedPlan.creditInMinutes / 60)
                          ),
                      })}`,
                      interval: t(`Shared.Fields.${selectedPlan?.interval}`, {
                        count: 0,
                      }),
                    })}
                  </span>
                  <Tooltip
                    placement={"top"}
                    overlayClassName="custom-toolstip --modal"
                    trigger={"hover"}
                    mouseEnterDelay={EDelayTime.FAST}
                    title={
                      <Trans
                        t={t}
                        i18nKey="Layouts.SubscribeModal.Tip05"
                        tOptions={{
                          price: selectedPlan?.pricePerHour,
                          percent:
                            currentUser.data?.plan.pricePerHour &&
                            selectedPlan?.pricePerHour &&
                            Math.round(
                              100 -
                                (selectedPlan.pricePerHour /
                                  currentUser.data.plan.pricePerHour) *
                                  100
                            ),
                        }}
                        components={{ linebreak: <br /> }}
                      />
                    }
                  >
                    <div>
                      <IoInformationCircle size={18} />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="col-12 col-md-6 description px-4 mt-2 mt-md-0">
                {t("Layouts.SubscribeModal.Tip03", {
                  interval: t(`Shared.Fields.${selectedPlan?.interval}`, {
                    count: 0,
                  }),
                })}
                <br />
                <Link
                  to="https://www.simonsaysai.com/help/2657979-can-i-read-your-privacy-policy-terms-of-service"
                  target="_blank"
                  rel="noopener"
                  className="custom-a-tag"
                >
                  {t("Shared.Texts.TermsAndConditions")}
                </Link>
              </div>
            </div>
          ) : (
            <div>
              {upgradePlanType === EPlanType.ANNUAL && !isHasOptions ? (
                <>{t("Layouts.SubscribeModal.AlreadyTopPlan")}</>
              ) : (
                <>
                  {t(
                    type == "upgrade"
                      ? "Layouts.SubscribeModal.Tip04Upgrade"
                      : "Layouts.SubscribeModal.Tip04Downgrade",
                    {
                      interval: t(
                        `Shared.Fields.${
                          upgradePlanType === EPlanType.ANNUAL
                            ? "month"
                            : "annual"
                        }`
                      ),
                    }
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {isHasOptions && <CreditCard />}
      {isHasOptions && type !== ETypeSubcribeModal.DOWNGRADE && (
        <VoucherBox
          value={couponCode}
          onCouponApply={(data: CouponVerification | null) => {
            if(data && data.couponCode == 'ANNUAL40') {
              setUpgradePlanType(EPlanType.ANNUAL);
            }
            setCouponApplied(data);
          }}
          onChange={setCouponCode}
          isVisible
          defaultOpen={searchParams.has("subscribePrefilledCoupon")}
          unapplyRequest={resetCouponApply}
        />
      )}
      {isHasOptions && (
        <div className="d-flex justify-content-center mt-2">
          <button
            className="submit-button px-3 py-1"
            onClick={handleUpdatePlan}
            disabled={!isEnable}
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                <FaMagic className="me-1" />
                <span>
                  {type === "upgrade"
                    ? t("Shared.Actions.Upgrade")
                    : t("Shared.Actions.Downgrade")}
                </span>
              </>
            )}
          </button>
        </div>
      )}
    </div>
  );
}

function TransformBonusCredit(
  appliedCoupon: CouponVerification | null,
  credit: number
) {
  if (!appliedCoupon || !appliedCoupon.benefits.startsWith("MultiCreditBy"))
    return credit;

  var [command, amount] = appliedCoupon.benefits.split("=");
  return credit * Number(amount);
}

function TransformPrice(
  appliedCoupon: CouponVerification | null,
  price: number
): number {
  if (!appliedCoupon || !appliedCoupon.benefits.startsWith("MultiPriceBy"))
    return price;

  var [command, amount] = appliedCoupon.benefits.split("=");
  return price * Number(amount);
}
