import { ButtonWithIcons } from 'components/common';
import React, { useState } from 'react';
import { FaRegClock } from 'react-icons/fa';
import { BookmarkFilter, NoteFilter } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { changeFilterStyle } from 'redux/transcription/transcription.slice';
import { TranscriptConfigPopOver } from 'components/TranscriptPage/TranscriptConfigPopOver';
import { useEditorContext } from 'context/EditorContext';
import { EStyleFilter } from 'ts/enums';

export function MiniHeader() {
  const dispatch = useAppDispatch();
  const [openTranscriptConfig, setOpenTransciptConfig] = useState<boolean>(false);
  const { isLock } = useEditorContext();
  const currentFilters = useAppSelector((state) => state.transcription.currentFilters);

  return (
    <div className="mini-header d-flex flex-column gap-1 p-1 mb-1 rounded d-lg-none">
      <TranscriptConfigPopOver isOpen={openTranscriptConfig} setIsOpen={setOpenTransciptConfig}>
        <div className="text-center">
          <ButtonWithIcons
            className="p-1"
            icon={<FaRegClock size={24} color="var(--si-primary)" />}
            onClick={() => {
              if (!isLock) setOpenTransciptConfig(!openTranscriptConfig);
            }}
          />
        </div>
      </TranscriptConfigPopOver>
      <ButtonWithIcons
        className="p-1"
        icon={
          <BookmarkFilter
            width="22"
            height="27"
            color={
              currentFilters.includes(EStyleFilter.HIGHLIGHT)
                ? 'var(--si-bright-yellow)'
                : 'var(--si-primary)'
            }
          />
        }
        onClick={() => {
          dispatch(changeFilterStyle({ filterStyle: EStyleFilter.HIGHLIGHT }));
        }}
      />
      <ButtonWithIcons
        className="p-1"
        icon={
          <NoteFilter
            width="22"
            height="27"
            color={
              currentFilters.includes(EStyleFilter.ANNOTATION)
                ? 'var(--si-battery-charged-blue)'
                : 'var(--si-primary)'
            }
          />
        }
        onClick={() => {
          dispatch(changeFilterStyle({ filterStyle: EStyleFilter.ANNOTATION }));
        }}
      />
    </div>
  );
}
