import React, { useEffect } from 'react';
import { PageLoader } from 'components/common/Loader';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from 'ts/enums';
import { useAuth } from 'context/AuthContext';
import { useAppDispatch } from 'redux/hooks';
import { setUserJustLoggedIn } from 'redux/auth/auth.slice';

function AuthCallbackPage() {
  const { isLogged } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLogged) {
      dispatch(setUserJustLoggedIn(true));
      navigate(ERoutes.MAIN, { replace: true });
    }
  }, [isLogged]);

  return (
    <div className="pt-8">
      <PageLoader />
    </div>
  );
}

export default AuthCallbackPage;
