import React from 'react';
import { MediaItem } from './MediaItem';
import { TMedia, TProject } from 'ts/types';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from 'ts/enums';
import { useTranslation } from 'react-i18next';

export function RenderContentMedia({ medias, project }: { medias: TMedia[]; project: TProject }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function redirectToUploadMedia(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    navigate(`${ERoutes.UPLOAD}/${project.id}`);
    e.stopPropagation();
  }

  if (medias.length === 0)
    return (
      <div className="media-item cursor-pointer px-2 py-1" onClick={redirectToUploadMedia}>
        {t('Shared.Actions.ImportMedia')}
      </div>
    );

  return (
    <div className="content-medias custom-scrollbar --hidden">
      {medias.map((media, index) => (
        <MediaItem media={media} index={index} key={media.id} projectStatus={project.status} />
      ))}
    </div>
  );
}
