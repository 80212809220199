import React from 'react';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';

import FlagEN from '../../assets/img/flags/en.jpg';
import FlagVI from '../../assets/img/flags/vi.jpg';
import FlagFR from '../../assets/img/flags/fr.jpg';
import FlagDE from '../../assets/img/flags/de.jpg';
import FlagES from '../../assets/img/flags/es.jpg';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const languages = [
    { label: "English (US)", code: "en", thumbnail: FlagEN },
    { label: "français", code: "fr", thumbnail: FlagFR },
    { label: "Deutsch", code: "de", thumbnail: FlagDE },
    { label: "español", code: "es", thumbnail: FlagES },
    { label: "Tiếng Việt", code: "vi", thumbnail: FlagVI },
]

const items: MenuProps['items'] = languages.map((l) => ({
    label: l.label,
    key: l.code,
    icon: <img className='flag' src={l.thumbnail}/>
}))
    
interface IProps {

}

function LanguageSelector(props: IProps) {
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const langCode = i18n.resolvedLanguage || 'en';
    const lang = languages.find((l) => l.code == langCode);

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if(langCode == e.key) return;

        i18n.changeLanguage(e.key);
        navigate(0);
    };
    
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <Dropdown className='language-selector' overlayClassName='language-selector' menu={menuProps}>
            <div><p className='title'><img className='flag' src={lang?.thumbnail}/> { lang?.label } <DownOutlined /></p></div>
        </Dropdown>
    )
}

export default React.memo(LanguageSelector);