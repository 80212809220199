import { useTranslation } from 'react-i18next';
import { TPlan } from 'ts/types';
import {
  changePricePerHourToMinumunChangeInDolars,
  changePricePerHourToPricePerMinute
} from 'utils/price';

export function PlanItem({ plan }: { plan: TPlan }) {
  const { t } = useTranslation();

  return (
    <div className={`plan-item mb-3 ${plan.active ? '--active' : '--disable'}`}>
      <div className="title mb-4">{plan.fullLabel}</div>

      <div className="content">
        <div className="row gx-2">
          <div className="col-12 col-lg-4">
            <div className="mb-3">
              <div className="plan-item-title mb-1">{ t('Pages.Admin.Plans.Interval') }</div>
              <div className="content-input">{plan.interval || 'none'}</div>
            </div>

            <div className="mb-3">
              <div className="plan-item-title mb-1">{ t('Pages.Admin.Plans.CreditInMinutes') }</div>
              <div className="content-input">{plan.creditInMinutes || '0'}</div>
            </div>

            <div className="mb-3">
              <div className="plan-item-title mb-1">{ t('Pages.Admin.Plans.MinimumChargeInMinutes') }</div>
              <div className="content-input">{plan.minimumChargeInMinutes || '0'}</div>
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <div className="mb-3">
              <div className="plan-item-title mb-1">{ t('Pages.Admin.Plans.Type') }</div>
              <div className="content-input">{plan.type || '0'}</div>
            </div>

            <div className="mb-3">
              <div className="plan-item-title mb-1">{ t('Pages.Admin.Plans.PricePerHour') }</div>
              <div className="content-input">${plan.pricePerHour || '0'}/{ t('Shared.Fields.hour', { count: 0 }) }</div>
            </div>

            <div className="mb-3">
              <div className="plan-item-title mb-1">{ t('Pages.Admin.Plans.MinimumChargeInDollars') }</div>
              <div className="content-input">
                $
                {changePricePerHourToMinumunChangeInDolars({
                  pricePerHour: plan.pricePerHour,
                  minimunChargeInMinute: plan.minimumChargeInMinutes
                })}
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <div className="mb-3">
              <div className="plan-item-title mb-1">{ t('Pages.Admin.Plans.SubscriptionCharge') }</div>
              <div className="content-input">${plan.subscriptionCharge || '0'}</div>
            </div>

            <div className="mb-3">
              <div className="plan-item-title mb-1">{ t('Pages.Admin.Plans.PricePerMinute') }</div>
              <div className="content-input">
                {changePricePerHourToPricePerMinute({ pricePerHour: plan.pricePerHour })}¢/{ t('Shared.Fields.minute', { count: 0 }) }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
