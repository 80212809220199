import { EAvidLocatorType, EBurnInType, EFileType } from 'ts/enums';
import { AxiosCustom } from './axios-client';
import { TAxiosResponse, TErrorResponse, TResponeDubbing } from 'ts/types/TResponse';
import { TRequestDubbing } from 'ts/types/TRequest';
import { StripeTransactionDetail } from 'ts/types/interface';

type TPayload = {
  projectId: string;
  fileType?: EFileType;
  onlyBookmarks?: boolean;
  withTimeCode?: boolean;
  withGapless?: boolean;
  withoutNotesContent?: boolean;
  currentMediaId?: string | null;
  avidLocatorType?: EAvidLocatorType;
  jsonFileOptions?: string;
  customField?: string;
};
type exportSubtitleData = {
  characterPerLine: number;
  withSingleLine: boolean;
  withUppercase: boolean;
  withGapless: boolean;
};
export async function exportFile(payload: TPayload): Promise<TAxiosResponse<Blob> | undefined> {
  let data: Pick<
    TPayload,
    | 'fileType'
    | 'onlyBookmarks'
    | 'withTimeCode'
    | 'withGapless'
    | 'withoutNotesContent'
    | 'currentMediaId'
    | 'avidLocatorType'
    | 'jsonFileOptions'
    | 'customField'
  > = {
    fileType: payload.fileType || EFileType.DOC,
    onlyBookmarks: payload.onlyBookmarks === undefined ? false : payload.onlyBookmarks,
    withTimeCode: payload.withTimeCode === undefined ? true : payload.withTimeCode,
    withGapless: payload.withGapless === undefined ? false : payload.withGapless,
    withoutNotesContent: payload.withoutNotesContent === undefined ? false : payload.withoutNotesContent,
    currentMediaId: payload.currentMediaId || null,
    avidLocatorType: payload.avidLocatorType || EAvidLocatorType.AVID_LOCATOR_UTF8,
    jsonFileOptions: payload.jsonFileOptions || '{"fcpXExportType":"captions"}'
  };
  if (payload.customField) {
    data = { ...data, customField: payload.customField };
  }

  return AxiosCustom.post(`projects/${payload.projectId}/exported-file/`, data, {
    responseType: 'arraybuffer'
  });
}

export async function exportVisualFile({
  projectId,
  data
}: {
  projectId: string;
  data: exportSubtitleData;
}): Promise<TAxiosResponse<{ projectId: string }>> {
  return AxiosCustom.post(`projects/${projectId}/exported-subtitle/`, data);
}

export async function exportInvoices({
  endUtcTime,
  starUtcTime,
  folderIds
}: {
  endUtcTime: string;
  starUtcTime: string;
  folderIds?: string[];
}): Promise<TAxiosResponse<Blob>> {
  let idFolder = '';
  folderIds?.forEach((id: string) => (idFolder = idFolder.concat(`folderIds[]=${id}&`)));
  return AxiosCustom.post(
    `user/export-invoice?${idFolder}endUtcTime=${endUtcTime}&starUtcTime=${starUtcTime}`,
    undefined,
    {
      responseType: 'arraybuffer'
    }
  );
}

export async function exportBurnInCheckout({
  projectId,
  mediaId
}: {
  projectId: string;
  mediaId: string;
}): Promise<{ eventLogId: number; response: string, stripeTransactionDetail?: StripeTransactionDetail } | TErrorResponse> {
  try {
    const res = await AxiosCustom.post(
      `projects/checkout?projectId=${projectId}&replaceCard=false&currentMediaId=${mediaId}&payType=BURNIN`
    );
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function exportBurnInCheckoutWithoutCredit({
  projectId,
  mediaId,
  cardHolderName,
  cvc,
  exp_month,
  exp_year,
  number,
  saveCard
}: {
  projectId: string;
  mediaId: string;
  cardHolderName: string;
  cvc: string;
  exp_month: string;
  exp_year: string;
  number: string;
  saveCard: boolean;
}): Promise<{ eventLogId: number; response: string, stripeTransactionDetail?: StripeTransactionDetail } | TErrorResponse> {
  try {
    const res = await AxiosCustom.post(
      `projects/checkout?projectId=${projectId}&replaceCard=true&currentMediaId=${mediaId}&payType=BURNIN&cardHolderName=${cardHolderName}&cvc=${cvc}&exp_month=${exp_month}&exp_year=${exp_year}&number=${number}&saveCard=${saveCard}`
    );
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function exportBurnIn({
  projectId,
  currentMediaId,
  eventLogId,
  burnInType
}: {
  projectId: string;
  currentMediaId: string;
  eventLogId: number;
  burnInType: EBurnInType;
}) {
  return AxiosCustom.post(`projects/${projectId}/burn-in`, {
    burnInType,
    eventLogId,
    currentMediaId
  });
}

export async function checkoutBeforeDubbing({
  projectId,
  mediaId
}: {
  projectId: string;
  mediaId: string;
}): Promise<{ eventLogId: number; response: string, stripeTransactionDetail?: StripeTransactionDetail } | TErrorResponse> {
  try {
    const res = await AxiosCustom.post(
      `projects/checkout?projectId=${projectId}&replaceCard=false&currentMediaId=${mediaId}&payType=DUB`
    );
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function checkoutBeforeDubbingWithoutCredit({
  projectId,
  mediaId,
  cardHolderName,
  cvc,
  exp_month,
  exp_year,
  number,
  saveCard
}: {
  projectId: string;
  mediaId: string;
  cardHolderName: string;
  cvc: string;
  exp_month: string;
  exp_year: string;
  number: string;
  saveCard: boolean;
}): Promise<{ eventLogId: number; response: string, stripeTransactionDetail?: StripeTransactionDetail } | TErrorResponse> {
  try {
    const res = await AxiosCustom.post(
      `projects/checkout?projectId=${projectId}&replaceCard=true&currentMediaId=${mediaId}&payType=DUB&cardHolderName=${cardHolderName}&cvc=${cvc}&exp_month=${exp_month}&exp_year=${exp_year}&number=${number}&saveCard=${saveCard}`
    );
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function dubbing({
  projectId,
  mediaId,
  eventLogId,
  projectName,
  ttsCode,
  speakers
}: TRequestDubbing & { projectId: string }): Promise<TAxiosResponse<TResponeDubbing>> {
  return AxiosCustom.post(`projects/${projectId}/dubbing`, {
    mediaId,
    eventLogId,
    projectName,
    ttsCode,
    speakers
  });
}
