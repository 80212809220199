/* eslint-disable no-unused-vars */
import { Spin, message } from 'antd';
import React, { createContext, useContext, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { NoticeType } from 'antd/es/message/interface';

interface PageLoadingContextType {
  openMessage: (type: NoticeType, content: string) => void;
  destroyMessage: () => void;
}

const PageLoadingContext = createContext<PageLoadingContextType>(null!);

export function PageLoadingProvider({ children }: { children: React.ReactNode }) {
  const [messageApi, contextHolder] = message.useMessage();

  function handleOpenMessage(type: NoticeType, content: string) {
    if (type === 'loading') {
      messageApi.open({ type, content, duration: 60 * 1000 });
    } else {
      messageApi.open({ type, content });
    }
  }

  const value = {
    openMessage: (type: NoticeType, content: string) => handleOpenMessage(type, content),
    destroyMessage: () => messageApi.destroy()
  };

  return (
    <PageLoadingContext.Provider value={value}>
      {contextHolder}
      {children}
    </PageLoadingContext.Provider>
  );
}

export function usePageLoading() {
  return useContext(PageLoadingContext);
}
