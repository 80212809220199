import { Popover } from 'antd';
import React, { ReactNode, useState } from 'react';
import { TProject, TTag } from 'ts/types';
import { EStatePopOverLabel } from 'ts/enums';
import { RenderContentPopOver } from './components/RenderContentPopOver';

type Props = {
  children: ReactNode;
  tag?: TTag | null;
  project: TProject;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function TagsPopOver({ children, tag, project, open, setOpen }: Props) {
  const [statePopOver, setStatePopOver] = useState<EStatePopOverLabel>(
    EStatePopOverLabel.LISTING_LABEL
  );
  const [color, setColor] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const content = RenderContentPopOver({
    tagSelected: tag,
    projectSelected: project,
    nameProps: name,
    colorProps: color,
    statePopOverProps: statePopOver,
    setNameProps: setName,
    setColorProps: setColor,
    setStatePopOverProps: setStatePopOver,
    open,
    setOpen
  });

  return (
    <Popover
      placement="rightTop"
      content={content}
      trigger="click"
      style={{ width: 180 }}
      open={open}
      onOpenChange={() => {
        setStatePopOver(EStatePopOverLabel.LISTING_LABEL);
        setColor(null);
        setName(null);
        setOpen(false);
      }}
    >
      {children}
    </Popover>
  );
}
