import { getUserInfor } from 'apis/auth.api';
import { updateCreditCard } from 'apis/subscribe.api';
import { CreditCard, ModalBase } from 'components/common';
import { useCreditCard } from 'context/CreditCardContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaMagic } from 'react-icons/fa';
import { updateCurrentUser } from 'redux/auth/auth.slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { popModal } from 'redux/modal/modal.slice';
import { EHttpStatusCode, EModals } from 'ts/enums';

export function UpdateCreditCardModal() {
  const currentUser = useAppSelector((state) => state.auth.currentUser.data);
  const dispatch = useAppDispatch();
  const {
    cardData,
    cardName,
    cvcData,
    expiryMonthData,
    expiryYearData,
    isUpdatePayment,
    isUpdateSubscriptionPayment
  } = useCreditCard({
    currentUser
  });
  const { t } = useTranslation();

  async function handleUpdateCard() {
    if (currentUser?.plan.planEnum && cardData?.cardNumber) {
      const planType = currentUser?.plan.planEnum;
      const res = await updateCreditCard({
        planType,
        cardHolderName: cardName.data,
        cardNumber: cardData?.cardNumber.replace(/\s/g, ''),
        cvc: cvcData.data,
        exp_month: expiryMonthData.data,
        exp_year: expiryYearData.data,
        updatePayment: isUpdatePayment,
        updateSubscriptionPayment: isUpdateSubscriptionPayment
      });
      if (res === EHttpStatusCode.OK) {
        const user = await getUserInfor();
        if (user) {
          dispatch(updateCurrentUser(user));
          dispatch(popModal());
        }
      }
    }
  }

  return (
    <ModalBase
      modalName={EModals.UPDATE_CREDIT_CARD}
      onCloseModal={() => {
        dispatch(popModal());
      }}
      className="update-credit-card-modal"
    >
      <h4 className="text-center mb-4">
        {t('Pages.AccountSetting.Profile.UpdateCreditCardModal.Title')}
      </h4>
      <CreditCard showUpdateCreditCard />
      <div className="mt-3 text-center">
        <button className="btn-si-primary --outline px-2 py-1 rounded-5" onClick={handleUpdateCard}>
          <FaMagic className="me-1" />
          {t('Pages.AccountSetting.Profile.UpdateCreditCardModal.UpdateCard')}
        </button>
      </div>
    </ModalBase>
  );
}
