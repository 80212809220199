import { TProject } from 'ts/types';
import {
  TRequestCreateNewProject,
  TRequestDeleteProject,
  TRequestEditProject,
  TRequestGetProjectById,
  TRequestMoveProjectToFolder,
  TRequestPriceTranslateProject,
  TRequestTranslateProject
} from 'ts/types/TRequest';
import { TResponse, TResponseMoveProject, TResponseTranslatePrice } from 'ts/types/TResponse';
import { AxiosCustom } from './axios-client';
import { EFolderIdDefault } from 'ts/enums';

export async function getProjectById({
  projectId
}: TRequestGetProjectById): Promise<TResponse<TProject>> {
  const res = await AxiosCustom.get(`projects/${projectId}`);
  return res.data;
}

export async function getListProject(): Promise<TResponse<Array<TProject>>> {
  const res = await AxiosCustom.get(`projects?all=true`);
  return res.data;
}
export async function createNewProject({
  name
}: TRequestCreateNewProject): Promise<TResponse<TProject>> {
  const res = await AxiosCustom.post(`projects`, { name });
  return res.data;
}

export async function editProject({ project }: TRequestEditProject) {
  const res = await AxiosCustom.put(`projects/${project.id}`, { ...project });
  return res.data;
}

export async function deleteProject({ project }: TRequestDeleteProject) {
  await AxiosCustom.delete(`projects/${project.id}`);
  return { project };
}

export async function getListProjectShareWithMe(): Promise<TResponse<Array<TProject>>> {
  const res = await AxiosCustom.get(`shared-projects-with-me`);
  return res.data;
}

export async function moveProjectToFolder({
  folder,
  isFrom,
  projectsId
}: TRequestMoveProjectToFolder): Promise<TResponse<TResponseMoveProject>> {
  if (
    folder.id === EFolderIdDefault.MY_PROJECT ||
    folder.id === EFolderIdDefault.PROJECT_ALL ||
    folder.id === EFolderIdDefault.SHARED_WITH_ME
  ) {
    await AxiosCustom.post(`directory/move`, { folderId: null, isFrom, projectsId });
  } else {
    await AxiosCustom.post(`directory/move`, { folderId: folder.id, isFrom, projectsId });
  }
  return { folder, projectsId };
}
export async function getPriceTranslate({
  projectId,
  languageCount
}: TRequestPriceTranslateProject): Promise<TResponseTranslatePrice> {
  const res = await AxiosCustom.get(`project/${projectId}/translation/price`, {
    params: { languageCount }
  });
  return res.data;
}

export async function translateProject({
  projectId,
  paymentDetails,
  targetLanguageIds
}: TRequestTranslateProject) {
  try {
    const res = await AxiosCustom.post(`project/${projectId}/translation`, {
      paymentDetails,
      targetLanguageIds
    });
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function lock({ mediaId, forceLock }: { mediaId: string; forceLock?: boolean }) {
  try {
    const res = await AxiosCustom.post(`media/${mediaId}/lock`, {
      forceLock
    });
    return res.data;
  } catch (error: any) {
    // console.error('error.response.data.message');
    // notification.open({ type: 'error', message: error.response.data.message });
  }
}

export async function unLock({ mediaId }: { mediaId: string }) {
  try {
    const res = await AxiosCustom.post(`media/${mediaId}/unLock`);
    return res.data;
  } catch (error: any) {
    console.error('error.response.data.message');
    // notification.open({ type: 'error', message: error.response.data.message });
  }
}
