import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from 'components/common/Loader';
import { useEffect } from 'react';

function SignInPage() {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect();
  }, []);
  return (
    <>
      <Loader />
    </>
  );
}

export default SignInPage;
