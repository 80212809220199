import React from 'react';
import { useTranslation } from 'react-i18next';

function NotFoundPage({ message }: { message?: string }) {
  const { t } = useTranslation();

  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      { message || t('Shared.Texts.PageNotFound') }
    </div>
  );
}

export default NotFoundPage;
