import { Radio, RadioChangeEvent } from 'antd';
import { AiFillInfoCircle } from 'react-icons/ai';
import { useAppSelector } from 'redux/hooks';
import React from 'react';
import { PropsExport } from '.';
import { useTranslation } from 'react-i18next';

export function RenderOption({ exportOption, setExportOption }: PropsExport) {
  const currentProject = useAppSelector((state) => state.projects.currentProject).data;
  const data = useAppSelector((state) => state.modal.lastModal?.data);
  const { t } = useTranslation();

  if (!exportOption.data.option) return null;

  function handleChangeWithTimeCode() {
    setExportOption((draft) => {
      draft.data.option.withTimeCode = !draft.data.option.withTimeCode;
    });
  }

  function handleChangeWithGapless() {
    setExportOption((draft) => {
      draft.data.option.withGapless = !draft.data.option.withGapless;
    });
  }

  function handleChangeMediaExport(value: string) {
    setExportOption((draft) => {
      draft.currentMediaId = value == 'one' ? data.mediaId : null;
    });
  }
  function handleChangeCharacterPerLine(value: number) {
    setExportOption((draft) => {
      draft.data.option.characterPerLine = value;
    });
  }
  function handleSingleLine() {
    setExportOption((draft) => {
      draft.data.option.withSingleLine = !draft.data.option.withSingleLine;
    });
  }
  function handleUpperCase() {
    setExportOption((draft) => {
      draft.data.option.withUppercase = !draft.data.option.withUppercase;
    });
  }
  function handleChangeOnlyBookmark() {
    setExportOption((draft) => {
      draft.data.option.onlyBookmarks = !draft.data.option.onlyBookmarks;
    });
  }
  function handleChangeWithoutNotesContent() {
    setExportOption((draft) => {
      draft.data.option.withoutNotesContent = !draft.data.option.withoutNotesContent;
    });
  }
  return (
    <>
      <div className="mt-2 render-visual-subtitle">
        {/*render Characters Per Line*/}
        {exportOption.data.option.characterPerLine != undefined && (
          <div className="d-flex align-items-center justify-content-start number-checkbox">
            <label className="d-flex align-items-center gap-1">
              <input
                style={{ fontSize: 14 }}
                type="number"
                defaultValue={32}
                onChange={(value) => {
                  handleChangeCharacterPerLine(value.target.valueAsNumber);
                }}
                min={20}
                max={70}
              />
              <span>
                {t('Layouts.ExportModal.ExportOptions.CharacterPerLine', { min: 20, max: 70 })}
              </span>
            </label>
          </div>
        )}
        {/* render single line */}
        {exportOption.data.option.withSingleLine != undefined && (
          <div
            className="d-flex align-items-center justify-content-start"
            style={{ marginTop: 12 }}
          >
            <label className="d-flex align-items-center gap-1">
              <input
                style={{ width: 17, height: 17 }}
                onChange={handleSingleLine}
                type="checkbox"
                name="with-Single-Line"
                checked={exportOption.data.option.withSingleLine}
              />
              <span>{t('Layouts.ExportModal.ExportOptions.SingleLine')}</span>
            </label>
          </div>
        )}
        {/* render ALL UPPERCASE */}
        {exportOption.data.option.withSingleLine != undefined && (
          <div
            className=" d-flex align-items-center justify-content-start"
            style={{ marginTop: 12 }}
          >
            <label className="d-flex align-items-center gap-1">
              <input
                style={{ width: 17, height: 17 }}
                onChange={handleUpperCase}
                type="checkbox"
                name="with-upper-case"
                checked={exportOption.data.option.withUppercase}
              />
              <span>{t('Layouts.ExportModal.ExportOptions.AllUppercase')}</span>
            </label>
          </div>
        )}
      </div>

      {/* render select has time code in export file */}
      {exportOption.data.option.withTimeCode != undefined && (
        <div className="mt-2 d-flex align-items-center justify-content-center">
          <label className="d-flex align-items-center gap-1">
            <input
              onChange={handleChangeWithTimeCode}
              type="checkbox"
              name="with-time-code"
              checked={exportOption.data.option.withTimeCode}
            />
            <span>{t('Layouts.ExportModal.ExportOptions.WithTimecode')}</span>
          </label>
        </div>
      )}
      {/* render select has gap less in export file */}
      {exportOption.data.option.withGapless != undefined && (
        <div className="mt-2 d-flex align-items-center justify-content-center">
          <label className="d-flex align-items-center gap-1">
            <input
              onChange={handleChangeWithGapless}
              type="checkbox"
              name="with-gap-less"
              checked={exportOption.data.option.withGapless}
            />
            <span>{t('Layouts.ExportModal.ExportOptions.GaplessWhen1s')}</span>
            <a
              href="https://www.simonsaysai.com/help/4468677-gapless-subtitles"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillInfoCircle size={11} color="var(--si-primary)" />
            </a>
          </label>
        </div>
      )}
      {/* render only boookmark */}
      {exportOption.data.option.onlyBookmarks != undefined && (
        <div className="mt-2 d-flex align-items-center justify-content-center">
          <label className="d-flex align-items-center gap-1">
            <input
              onChange={handleChangeOnlyBookmark}
              type="checkbox"
              name="with-gap-less"
              checked={exportOption.data.option.onlyBookmarks}
            />
            <span>{t('Layouts.ExportModal.ExportOptions.OnlyBookmarks')}</span>
          </label>
        </div>
      )}
      {/* render Without Notes' Content */}
      {exportOption.data.option.withoutNotesContent != undefined && (
        <div className="d-flex align-items-center justify-content-center">
          <label className="d-flex align-items-center gap-1">
            <input
              onChange={handleChangeWithoutNotesContent}
              type="checkbox"
              name="without-notes-content"
              checked={exportOption.data.option.withoutNotesContent}
            />
            <span>{t('Layouts.ExportModal.ExportOptions.WithoutNotesContent')}</span>
          </label>
        </div>
      )}
      {/* render has many media */}
      {exportOption.allowMultipleMedia &&
        currentProject?.medias &&
        currentProject?.medias.length >= 2 && (
          <div className="mt-1 d-flex align-items-center justify-content-center">
            <Radio.Group
              options={[
                { label: 'ALL FILES IN THE PROJECT', value: 'all' },
                { label: 'CURRENT FILE', value: 'one' }
              ]}
              onChange={({ target: { value } }: RadioChangeEvent) => {
                handleChangeMediaExport(value);
              }}
              defaultValue="one"
            />
          </div>
        )}
    </>
  );
}
