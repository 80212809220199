import { getProjectById } from 'apis/projects.api';
import { getTranscriptionByMediaId } from 'apis/transcript.api';
import PS4Refactor from 'components/PS4/PS4Refactor';
import VideoPlayer from 'components/VideoPlayer';
import { AutoScrollProvider } from 'context/AutoScrollContext';
import { VideoPlayerProvider } from 'context/VideoPlayerContext';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { TMedia, TProject, TTranscript } from 'ts/types';
import ReactPlayer from 'react-player';

type Props = {};

function TestPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const { projectId } = params;
  const [currentProject, setCurrentProject] = useState<TProject | null>(null);
  const [currentListMedia, setCurrentListMedia] = useState<TMedia[]>([]);
  const [currentMedia, setCurrentMedia] = useState<TMedia | null>(null);
  const [currentListTranscription, setCurrentListTranscription] = useState<TTranscript[]>([]);

  async function handleFetchCurrentProject({ projectId }: { projectId: string }) {
    try {
      const data = await getProjectById({ projectId });
      if (data) {
        if (data.medias.length) {
          searchParams.set('mediaId', data.medias[0].id);
          setSearchParams(searchParams);
          setCurrentMedia(data.medias[0]);
        }
        setCurrentProject(data);
        setCurrentListMedia(data.medias);
      }
    } catch (error) {
      // console.log(JSON.stringify(error));
    }
  }

  async function handleFetchTranscription({ mediaId }: { mediaId: string }) {
    try {
      const data = (await getTranscriptionByMediaId({ mediaId })) as TTranscript[];
      setCurrentListTranscription(data);
    } catch (error) {
      // console.log(JSON.stringify(error));
    }
  }

  useEffect(() => {
    if (projectId) {
      handleFetchCurrentProject({ projectId });
    }
  }, [projectId]);

  useEffect(() => {
    if (currentMedia) {
      handleFetchTranscription({ mediaId: currentMedia.id });
    }
  }, [currentMedia]);

  return (
    <div className="container h-100">
      <VideoPlayerProvider>
        <div className="row h-100">
          <div className="col-6">
            {currentMedia && (
              <VideoPlayer
                url={currentMedia.videoFilePath || currentMedia.audioFilePath}
                thumbnail={currentMedia.frameFilePath}
                duration={currentMedia.duration}
                userDefinedTimeCode={currentMedia.userDefinedTimeCode}
              />
            )}
          </div>
          <AutoScrollProvider>
            <div className="col-6 h-100">
              {currentListTranscription.length > 0 && (
                <PS4Refactor listTranscriptions={currentListTranscription} />
              )}
            </div>
          </AutoScrollProvider>
        </div>
      </VideoPlayerProvider>
    </div>
  );
}

export default TestPage;
