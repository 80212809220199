import React, { useState } from 'react';
import { EAccountSettingId } from 'ts/enums';
import { BasicSection } from './BasicSection';
import { useAppSelector } from 'redux/hooks';
import { TUser } from 'ts/types';
import { requestChangePassword, setNewPassword } from 'apis/userSetting.api';
import { useTranslation } from 'react-i18next';
import { usePageLoading } from 'context/PageLoadingContext';
import { useAuth } from 'context/AuthContext';

export function LoginMethodSection() {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [isOpenFieldSetPassword, setIsOpenFieldSetPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const { openMessage, destroyMessage } = usePageLoading();
  const { t } = useTranslation();
  const { logout } = useAuth();

  function isUserUsingEmailAuthentication(user: TUser | null) {
    return user && user.identities && user.identities.indexOf('auth0') !== -1;
  }
  function isUserConnectGooogle(user: TUser | null) {
    return user && user.identities && user.identities.indexOf('google-oauth2') !== -1;
  }

  async function handleOpenFieldSetPwd(user: TUser | null) {
    if (user && !isUserUsingEmailAuthentication(user)) {
      setIsOpenFieldSetPassword(true);
      return;
    }
    if (user && currentUser.data) {
      const res = await requestChangePassword({
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
        connection: 'Username-Password-Authentication',
        email: currentUser.data.email
      });
      if (typeof res == 'string') {
        destroyMessage();
        openMessage('success', res);
      } else {
        destroyMessage();
        openMessage('error', res?.error || 'error');
      }
    }
  }

  async function handleSetPassword() {
    const message = await setNewPassword(password);
    if (message) {
      destroyMessage();
      openMessage('error', message.message);
    } else {
      logout();
    }
  }
  return (
    <>
      <BasicSection
        className="login-method-section mb-9"
        header={t('Pages.AccountSetting.LoginPreferences.SubTitle')}
        id={EAccountSettingId.LOGIN_PREFERENCES}
      >
        <article className="mb-4">
          <div className="label mb-2">{t('Shared.Fields.Email')}</div>
          <div className="text">{currentUser.data?.email}</div>
        </article>
        <article className="mb-4">
          <div className="label mb-2">{t('Shared.Fields.GoogleAccount')}</div>
          <div className="text">
            <span style={{ fontWeight: 500 }}>{t('Shared.Fields.Status')}</span>:{' '}
            <span
              className={`${
                isUserConnectGooogle(currentUser.data) ? 'text-success' : 'text-danger'
              } `}
            >
              {isUserConnectGooogle(currentUser.data)
                ? t('Shared.Texts.Connected')
                : t('Shared.Texts.NotConnected')}
            </span>
          </div>
        </article>
        <article>
          <div className="label mb-2">{t('Shared.Fields.Password')}</div>
          <div className="text">
            <span style={{ fontWeight: 500 }}>{t('Shared.Fields.Status')}</span>:{' '}
            <span
              className={`${
                isUserUsingEmailAuthentication(currentUser.data) ? 'text-success' : 'text-danger'
              } `}
            >
              {isUserUsingEmailAuthentication(currentUser.data)
                ? t('Pages.AccountSetting.LoginPreferences.PasswordHasBeenSet')
                : t('Pages.AccountSetting.LoginPreferences.NoPasswordHasBeenSet')}
            </span>
            <a
              className="ms-1 custom-a-tag"
              onClick={() => {
                handleOpenFieldSetPwd(currentUser.data);
              }}
            >
              {isUserUsingEmailAuthentication(currentUser.data)
                ? t('Shared.Actions.ChangePassword')
                : t('Shared.Actions.CreatePassword')}
            </a>
          </div>
          {isOpenFieldSetPassword && (
            <div className="ctn-set-password mt-2 px-2">
              <input
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type="password"
                placeholder={t('Shared.Fields.Password')}
                className="input-create-password"
              />
              <input
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                type="password"
                placeholder={t('Shared.Fields.PasswordConfirmation')}
                className="input-confirm-password mt-4"
              />
              {confirmPassword.length > 0 && confirmPassword !== password && (
                <div className="password-alert">
                  {t('Pages.AccountSetting.LoginPreferences.PasswordsNotMatch')}
                </div>
              )}
              <div className="d-flex mt-2 justify-content-center gap-1">
                <button
                  onClick={() => {
                    setConfirmPassword('');
                    setPassword('');
                    setIsOpenFieldSetPassword(false);
                  }}
                  className="btn-cancel px-2 py-1"
                >
                  {t('Shared.Actions.Cancel')}
                </button>
                <button onClick={handleSetPassword} className="btn-ok px-2 py-1">
                  {t('Shared.Actions.Create')}
                </button>
              </div>
            </div>
          )}
        </article>
      </BasicSection>
    </>
  );
}
