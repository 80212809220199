import { ModalBase } from 'components/common';
import { BsCheckLg, BsShieldFillExclamation } from 'react-icons/bs';
import { PiWarningBold } from 'react-icons/pi';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { popModal } from 'redux/modal/modal.slice';
import { EModals, ETypeNoti } from 'ts/enums';
import parse from 'html-react-parser';
import { IoCloseSharp } from 'react-icons/io5';

export function NotiModal() {
  const dispatch = useAppDispatch();
  const notiData = useAppSelector((state) => state.modal.lastModal?.notiData);

  function checkType() {
    if (notiData) {
      switch (notiData.type) {
        case ETypeNoti.ERROR:
          return 'error';
        case ETypeNoti.WARNING:
          return 'warning';
        case ETypeNoti.SUCCESS:
          return 'success';
        default:
          return '';
      }
    }
  }

  function checkIcon() {
    if (notiData) {
      switch (notiData.type) {
        case ETypeNoti.ERROR:
          return <BsShieldFillExclamation size={20} />;
        case ETypeNoti.WARNING:
          return <PiWarningBold size={20} />;
        case ETypeNoti.SUCCESS:
          return <BsCheckLg size={20} />;
        default:
          return '';
      }
    }
  }

  if (!notiData) return null;

  return (
    <ModalBase
      modalName={EModals.NOTI_MODAL}
      onCloseModal={() => {
        dispatch(popModal());
      }}
      className="noti-modal"
      customModalInner={`${checkType()}`}
      isCloseIcon={false}
    >
      <div
        className="close-icon"
        onClick={() => {
          dispatch(popModal());
        }}
      >
        <IoCloseSharp size={20} />
      </div>
      <div className="noti-content mt-1 me-2 d-flex gap-1 align-items-center">
        <div className="icon">{checkIcon()}</div>
        <div className="text">{parse(notiData.title)}</div>
      </div>
    </ModalBase>
  );
}
