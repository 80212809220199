import { Button, Form, Popconfirm, Table, notification } from 'antd';
import { createTeams, deleteTeam, getTeams } from 'apis/admin/teams.api';
import { ConfirmDeleteTeamModal } from 'components/Admin/ConfirmDeleteTeamModal';
import { useLoaderContext } from 'context/LoaderContext';
import { usePageLoading } from 'context/PageLoadingContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHome } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { pushModal } from 'redux/modal/modal.slice';
import { EModals, ERoutes, ETypeNoti } from 'ts/enums';
import { TTeam } from 'ts/types';

type TTeamOwnerCustom = {
  name: string;
  id: number;
};

type DataSourceType = {
  teamOwner: TTeamOwnerCustom;
  id: number;
};

export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export default function TeamPage() {
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [formData] = Form.useForm();
  const [dataSource, setDataSource] = useState<DataSourceType[]>([]);
  const [teams, setTeams] = useState<TTeam[]>([]);
  const { openMessage } = usePageLoading();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { loader } = useLoaderContext();

  async function handleCreateTeam() {
    const email = formData.getFieldValue('email');

    if (!email || !emailRegex.test(email)) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: {
            type: ETypeNoti.ERROR,
            title: t('Shared.Errors.InvalidEmail')
          }
        })
      );

      return;
    }

    if (email) {
      setIsLoadingTable(true);
      try {
        loader.start();
        const res = await createTeams({ email });
        if (res) {
          openMessage('success', t('Shared.Texts.Created'));
          const newTeams = [...teams, res];
          setTeams(newTeams);
          setDataSource(
            newTeams.map((team) => {
              return {
                teamOwner: { name: team.owner, id: team.id },
                id: team.id
              };
            })
          );
          formData.resetFields(['email']);
        }
        loader.complete();
      } catch (error: any) {
        dispatch(
          pushModal({
            name: EModals.NOTI_MODAL,
            data: null,
            notiData: {
              type: ETypeNoti.ERROR,
              title: `${error.response.data.message}`
            }
          })
        );
      }
      setIsLoadingTable(false);
    }
  }

  async function deleteTeams(id: number) {
    setIsLoadingTable(true);
    loader.start();
    await deleteTeam({ id });
    openMessage('success', t('Shared.Texts.Deleted'));
    const newTeams = teams.filter((team) => team.id !== id);
    setTeams(newTeams);
    setDataSource(
      newTeams.map((team) => {
        return {
          teamOwner: { name: team.owner, id: team.id },
          id: team.id
        };
      })
    );
    setIsLoadingTable(false);
    loader.complete();
  }

  const columnsTable = [
    {
      title: t('Shared.Fields.TeamOwner'),
      dataIndex: 'teamOwner',
      key: 'teamOwner',
      width: '50%',
      render: (record: TTeamOwnerCustom) => {
        return (
          <Link className="custom-a-tag" target="_blank" to={`${ERoutes.TEAM}/${record.id}`}>
            {record.name}
          </Link>
        );
      }
    },
    {
      title: (
        <input
          type="text"
          className="w-100 none-focus"
          placeholder={t('Shared.Actions.Search')}
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
        />
      ),
      dataIndex: 'id',
      key: 'id',
      width: '50%',
      render: (record: number) => {
        return (
          <div
            style={{ width: 'max-content' }}
            onClick={() => {
              dispatch(
                pushModal({
                  name: EModals.CONFIRM_DELETE_TEAM_MODAL,
                  data: record
                })
              );
            }}
          >
            <RiDeleteBin6Line className="cursor-pointer delete-icon" size={16} />
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    setIsLoadingTable(true);
    (async () => {
      loader.start();
      const res = await getTeams();
      if (res) {
        setTeams(res);
        setDataSource(
          res.map((team) => {
            return {
              teamOwner: { name: team.owner, id: team.id },
              id: team.id
            };
          })
        );
      }
      loader.complete();
      setIsLoadingTable(false);
    })();
  }, []);

  function handleSearch(e: string) {
    if (!e || e.length === 0) {
      setDataSource(
        teams.map((team) => {
          return {
            teamOwner: { name: team.owner, id: team.id },
            id: team.id
          };
        })
      );
      return;
    }
    const newTeams = teams.filter((team) => {
      return team.owner.indexOf(e) > -1;
    });

    setDataSource(
      newTeams.map((team) => {
        return {
          teamOwner: { name: team.owner, id: team.id },
          id: team.id
        };
      })
    );
  }

  return (
    <div>
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome
            size={30}
            color="var(--si-primary)"
            onClick={() => navigate(ERoutes.LASTEST_TRANSACTION)}
            className="cursor-pointer"
          />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            {t('Pages.Admin.Team.Title')}
          </span>
        </div>
      </div>
      <div className="container py-2">
        <Form form={formData} layout="vertical" className="border p-2" onFinish={handleCreateTeam}>
          <h5 className="mb-3">{t('Pages.Admin.Team.CreateNewTeam')}</h5>
          <div className="row">
            <Form.Item
              className="col-12 col-md-6 align-self-end"
              name={'email'}
              label={t('Shared.Fields.Email')}
            >
              <input
                className="w-100"
                style={{ height: 34 }}
                placeholder={t('Shared.Texts.EnterYourEmail')}
              />
            </Form.Item>
            <Form.Item className="col-12 col-md-6" label={<div />}>
              <button className="submit-button" style={{ minWidth: 90, height: 34 }} type="submit">
                {t('Shared.Actions.Create')}
              </button>
            </Form.Item>
          </div>
        </Form>

        <Table
          loading={isLoadingTable}
          className="mt-2"
          dataSource={dataSource}
          columns={columnsTable}
          pagination={false}
          rowKey={'id'}
        />
      </div>
      <ConfirmDeleteTeamModal handleDelete={deleteTeams} />
    </div>
  );
}
