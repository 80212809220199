import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTranscriptionByMediaId, updateTranscription } from 'apis/transcript.api';
import { TTranscript } from 'ts/types';
import { TRequestGetTranscriptionByMediaId } from 'ts/types/TRequest';

export const getTranscriptionByMediaIdAction = createAsyncThunk(
  'transcript/getTranscriptionByMediaId',
  async ({ mediaId }: TRequestGetTranscriptionByMediaId, { rejectWithValue }) => {
    try {
      const res = await getTranscriptionByMediaId({ mediaId });
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTranscriptionByMediaIdAction = createAsyncThunk(
  'transcript/updateTranscriptionByMediaId',
  async (
    { mediaId, listTranscript }: { mediaId: string; listTranscript: TTranscript[] },
    { rejectWithValue }
  ) => {
    try {
      const res = await updateTranscription({ mediaId, listTranscript });
      if (res === 200) return listTranscript;
      return [];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
