import { Button, DatePicker, notification } from 'antd';
import { getMetrics } from 'apis/admin/metrics.api';
import { usePageLoading } from 'context/PageLoadingContext';
import dayjs from 'dayjs';
import React, { ReactNode, useEffect, useState } from 'react';
import { AiFillCopy, AiOutlineExport } from 'react-icons/ai';
import { BiSolidDownArrow, BiSolidUpArrow, BiSolidUser } from 'react-icons/bi';
import { BsTranslate } from 'react-icons/bs';
import { FaClosedCaptioning, FaGlobe, FaHome, FaProductHunt, FaRegFileCode } from 'react-icons/fa';
import { GiBurningEye } from 'react-icons/gi';
import { GrProjects } from 'react-icons/gr';
import { EMetrics } from 'ts/enums';
import utc from 'dayjs/plugin/utc';
import { TMetric } from 'ts/types';
import { Link, useNavigate } from 'react-router-dom';
import { ERoutes } from 'ts/enums';
import { exportCSV } from 'utils/export';
import { CiUser } from 'react-icons/ci';
import { useLoaderContext } from 'context/LoaderContext';
dayjs.extend(utc);

const METRICS_NAME = [
  {
    id: EMetrics.NEW_USERS,
    name: 'New Users'
  },
  {
    id: EMetrics.PROJECTS_CREATED,
    name: 'Projects Created'
  },
  {
    id: EMetrics.TRANSCRIBED,
    name: 'Transcribed'
  },
  {
    id: EMetrics.TRANSLATED,
    name: 'Translated'
  },
  {
    id: EMetrics.BURN_IN,
    name: 'Burn-in'
  },
  {
    id: EMetrics.EXPORTED,
    name: 'Exported'
  }
];

function MetricsPage() {
  const [metricSeleted, setMetricSeleted] = useState<EMetrics>(EMetrics.NEW_USERS);
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(dayjs().add(1, 'days'));
  const { openMessage, destroyMessage } = usePageLoading();
  const [currentMetrics, setCurrentMetrics] = useState<TMetric | null>(null);
  const [prevMetrics, setPrevMetrics] = useState<TMetric | null>(null);
  const [metricDisplay, setMetricDisplay] = useState<
    Array<{
      name: string;
      count?: number;
      minutes?: number;
      id: string | number;
      isUrl?: boolean;
    }>
  >([]);
  const navigate = useNavigate();
  const { loader } = useLoaderContext();

  useEffect(() => {
    handleGetListMetricsData({ startDate, endDate });
  }, []);

  useEffect(() => {
    if (currentMetrics) {
      switch (metricSeleted) {
        case EMetrics.NEW_USERS:
          setMetricDisplay(
            currentMetrics[metricSeleted].map((user) => {
              return {
                name: user.email,
                id: user.userId,
                isUrl: true
              };
            })
          );
          break;
        case EMetrics.PROJECTS_CREATED:
          setMetricDisplay(
            currentMetrics[metricSeleted].projectList.map((project) => {
              return {
                name: project.name,
                id: project.url,
                isUrl: true
              };
            })
          );
          break;
        case EMetrics.TRANSCRIBED:
          setMetricDisplay(
            currentMetrics[metricSeleted].transcribedLanguageMap.map((transcribed) => {
              return {
                name: transcribed.title,
                id: transcribed.code,
                minutes: transcribed.minutes
              };
            })
          );
          break;
        case EMetrics.TRANSLATED:
          setMetricDisplay(
            currentMetrics[metricSeleted].translatedLanguageMap.map((language) => {
              return {
                name: language.title,
                id: language.code,
                minutes: language.minutes
              };
            })
          );
          break;
        case EMetrics.BURN_IN:
          setMetricDisplay(
            currentMetrics[metricSeleted].burnLanguageMap.map((language) => {
              return {
                name: language.title,
                id: language.code,
                minutes: language.minutes
              };
            })
          );
          break;
        case EMetrics.EXPORTED:
          setMetricDisplay(
            currentMetrics[metricSeleted].map((val) => {
              return {
                name: val.title,
                id: val.fileType,
                count: val.count
              };
            })
          );
          break;
        default:
          break;
      }
    }
  }, [metricSeleted, currentMetrics]);

  async function handleGetListMetricsData({
    startDate,
    endDate
  }: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) {
    destroyMessage();
    loader.start();
    openMessage('loading', 'Fetching metrics...');
    try {
      const res = await getMetrics({
        startDate: startDate.utc().format('YYYY-MM-DD[T00:00:00]'),
        endDate: endDate.utc().format('YYYY-MM-DD[T00:00:00]')
      });
      if (res) {
        setCurrentMetrics(res[0]);
        setPrevMetrics(res[1]);
        destroyMessage();
        loader.complete();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  function handleSearch() {
    handleGetListMetricsData({ startDate, endDate });
  }

  function handleExportCSV() {
    if (currentMetrics) {
      exportCSV({
        metric: currentMetrics,
        diffUser: diffData({
          currentCount: currentMetrics?.newUsers.length || 0,
          prevCount: prevMetrics?.newUsers.length || 0
        }),
        diffBurn: diffData({
          currentCount: currentMetrics.burn.minutesBurn || 0,
          prevCount: prevMetrics?.burn.minutesBurn || 0
        }),
        diffCreateProject: diffData({
          currentCount: currentMetrics.createdProject.createdProjectsCount,
          prevCount: prevMetrics?.createdProject.createdProjectsCount || 0
        }),
        diffExported: diffData({
          currentCount: currentMetrics.exportedFile.length || 0,
          prevCount: prevMetrics?.exportedFile.length || 0
        }),
        diffTranscribed: diffData({
          currentCount: currentMetrics.transcribed.minutesTranscribed,
          prevCount: prevMetrics?.transcribed.minutesTranscribed || 0
        }),
        diffTranslate: diffData({
          currentCount: currentMetrics.translated.minutesTranslated,
          prevCount: prevMetrics?.translated.minutesTranslated || 0
        })
      });
    }
  }

  function diffData({ currentCount, prevCount }: { currentCount: number; prevCount: number }) {
    let percent = 0;
    if (currentCount > prevCount) {
      percent = prevCount > 0 ? ((currentCount - prevCount) / prevCount) * 100 : 100;
    } else {
      percent = ((prevCount - currentCount) / prevCount) * 100;
    }
    return percent;
  }

  return (
    <div>
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome
            size={30}
            color="var(--si-primary)"
            onClick={() => navigate(ERoutes.LASTEST_TRANSACTION)}
            className="cursor-pointer"
          />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            Metrics
          </span>
        </div>
      </div>
      <div className="container py-4">
        <div className="row mb-3 bg-body p-2 rounded gy-2">
          <div className="col-12 col-lg-6">
            <div className="row gy-2">
              <MetricItem
                setMetricSeleted={() => setMetricSeleted(EMetrics.NEW_USERS)}
                currentMetricscount={currentMetrics?.newUsers.length || 0}
                prevMetricsCount={prevMetrics?.newUsers.length || 0}
                icon={<BiSolidUser size={18} />}
                title={'New Users'}
              />
              <MetricItem
                setMetricSeleted={() => setMetricSeleted(EMetrics.PROJECTS_CREATED)}
                currentMetricscount={currentMetrics?.createdProject.createdProjectsCount || 0}
                prevMetricsCount={prevMetrics?.createdProject.createdProjectsCount || 0}
                icon={<FaProductHunt size={18} />}
                title={'Projects created'}
              />

              <MetricItem
                setMetricSeleted={() => setMetricSeleted(EMetrics.TRANSCRIBED)}
                currentMetricscount={currentMetrics?.transcribed.minutesTranscribed || 0}
                prevMetricsCount={prevMetrics?.transcribed.minutesTranscribed || 0}
                icon={<AiFillCopy size={18} />}
                title={`Transcribed (${currentMetrics?.transcribed.transcribedFilesCount || 0})`}
                borderRightClassNameReponsive="d-lg-block"
              />
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="row gy-2">
              <MetricItem
                setMetricSeleted={() => setMetricSeleted(EMetrics.TRANSLATED)}
                currentMetricscount={currentMetrics?.translated.minutesTranslated || 0}
                prevMetricsCount={prevMetrics?.translated.minutesTranslated || 0}
                icon={<FaGlobe size={18} />}
                title={`Translated (${currentMetrics?.translated.translatedFilesCount || 0})`}
              />

              <MetricItem
                setMetricSeleted={() => setMetricSeleted(EMetrics.BURN_IN)}
                currentMetricscount={currentMetrics?.burn.minutesBurn || 0}
                prevMetricsCount={prevMetrics?.burn.minutesBurn || 0}
                icon={<GiBurningEye size={18} />}
                title={`Burn-in (${currentMetrics?.burn.burnFilesCount || 0})`}
              />

              <MetricItem
                setMetricSeleted={() => setMetricSeleted(EMetrics.EXPORTED)}
                currentMetricscount={
                  currentMetrics?.exportedFile.reduce((accumulator, item) => {
                    return accumulator + item.count;
                  }, 0) || 0
                }
                prevMetricsCount={
                  prevMetrics?.exportedFile.reduce((accumulator, item) => {
                    return accumulator + item.count;
                  }, 0) || 0
                }
                icon={<FaClosedCaptioning size={18} />}
                title={`Exported`}
                renderBorder={false}
              />
            </div>
          </div>
        </div>
        <div className="bg-body p-2 rounded">
          <div className="d-flex flex-wrap justify-content-between border-bottom mb-3 pb-3">
            <div>
              <h5>{METRICS_NAME.filter((metric) => metric.id === metricSeleted)[0].name}</h5>
            </div>
            <div className="d-flex align-items-end flex-wrap gap-2">
              <div className="d-flex flex-wrap gap-2">
                <div>
                  <div>
                    <small>Start time (UTC)</small>
                  </div>
                  <DatePicker
                    value={startDate}
                    className="me-2"
                    placeholder="Start time (UTC)"
                    onChange={(value) => {
                      if (value) {
                        setStartDate(value);
                      }
                    }}
                    format="MM/DD/YYYY"
                    suffixIcon={<></>}
                  />
                </div>
                <div>
                  <div>
                    <small>End time (UTC)</small>
                  </div>
                  <DatePicker
                    defaultValue={dayjs().add(1, 'days')}
                    className="me-2"
                    placeholder="End time (UTC)"
                    onChange={(value) => {
                      if (value) {
                        setEndDate(value);
                      }
                    }}
                    format="MM/DD/YYYY"
                    suffixIcon={<></>}
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <Button type="primary" className="me-2" onClick={handleSearch}>
                  Search
                </Button>
                <Button type="primary" onClick={handleExportCSV}>
                  Export CSV
                </Button>
              </div>
            </div>
          </div>
          <div>
            {metricDisplay.map((data) => {
              return (
                <div className="row mb-1" key={data.id}>
                  <div className="col-12 col-md-4">
                    {data.isUrl ? (
                      <Link
                        className="custom-a-tag"
                        to={`${
                          metricSeleted === EMetrics.NEW_USERS
                            ? ` ${ERoutes.USER_LOOKUP}/${data.id}`
                            : `${data.id.toString()}`
                        }`}
                      >
                        {metricSeleted === EMetrics.NEW_USERS && (
                          <CiUser color="var(--si-dim-gray)" className="me-1" />
                        )}
                        {metricSeleted === EMetrics.PROJECTS_CREATED && (
                          <FaProductHunt color="var(--si-dim-gray)" className="me-1" />
                        )}

                        <span className="align-middle">{data.name}</span>
                      </Link>
                    ) : (
                      <span>
                        {(metricSeleted === EMetrics.TRANSCRIBED ||
                          metricSeleted === EMetrics.TRANSLATED) && (
                          <FaGlobe color="var(--si-dim-gray)" className="me-1" />
                        )}
                        {metricSeleted === EMetrics.EXPORTED && (
                          <FaRegFileCode color="var(--si-dim-gray)" className="me-1" />
                        )}
                        <span className="align-middle">{data.name}</span>
                      </span>
                    )}
                  </div>
                  {data.minutes && (
                    <div className="col-12 col-md-4">{data.minutes.toFixed(2)} minutes</div>
                  )}
                  {data.count !== null && <div className="col-12 col-md-4">{data.count}</div>}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function Period({ prevCount, currentCount }: { prevCount: number; currentCount: number }) {
  let isIncrease = false;
  let percent = 0;

  if (currentCount >= prevCount) {
    isIncrease = true;
    percent = prevCount > 0 ? ((currentCount - prevCount) / prevCount) * 100 : 100;
  } else {
    isIncrease = false;
    percent = prevCount > 0 ? ((prevCount - currentCount) / prevCount) * 100 : 100;
  }

  return (
    <div>
      {isIncrease ? (
        <BiSolidUpArrow color="green" size={14} className="me-1" />
      ) : (
        <BiSolidDownArrow color="red" size={14} className="me-1" />
      )}
      <small className="align-middle" style={{ color: isIncrease ? 'green' : 'red' }}>
        {percent.toFixed(0)}%
      </small>
      <small className="align-middle truncate-1"> From previous Period</small>
    </div>
  );
}

function MetricItem({
  setMetricSeleted,
  currentMetricscount,
  prevMetricsCount,
  icon,
  title,
  renderBorder = true,
  borderRightClassNameReponsive = 'd-md-block'
}: {
  setMetricSeleted: React.Dispatch<React.SetStateAction<EMetrics>>;
  currentMetricscount: number;
  prevMetricsCount: number;
  icon: ReactNode;
  title: string;
  renderBorder?: boolean;
  borderRightClassNameReponsive?: string;
}) {
  return (
    <div
      className="col-12 col-md-4"
      onClick={() => setMetricSeleted(EMetrics.NEW_USERS)}
      style={{ cursor: 'pointer' }}
    >
      <div className="d-flex justify-content-between h-100 gap-1 flex-column flex-md-row">
        <div>
          <div>
            {icon}
            <span className="align-middle ms-1" style={{ fontSize: 13 }}>
              {title}
            </span>
          </div>
          <h1 className="pt-1">{currentMetricscount.toFixed()}</h1>
          <Period prevCount={prevMetricsCount} currentCount={currentMetricscount} />
        </div>
        {renderBorder && (
          <>
            <div
              className={`d-none ${borderRightClassNameReponsive}`}
              style={{ borderRight: '2px solid var(--bs-gray-400)' }}
            />
            <div
              className="d-block d-md-none"
              style={{ borderBottom: '2px solid var(--bs-gray-400)' }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default MetricsPage;
