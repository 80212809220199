import { CSSProperties } from 'react';

export default function IcBox({
  style,
  size,
  color
}: {
  style?: CSSProperties;
  size?: number;
  color?: string;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="250" cy="250" r="250" fill="#2685C7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.546 58C127.676 58 114 71.6761 114 88.5464V313.469V316.256H114.028C115.512 390.119 175.852 449.54 250.07 449.54C325.22 449.54 386.141 388.619 386.141 313.469C386.141 238.32 325.22 177.399 250.07 177.399C222.364 177.399 196.591 185.68 175.093 199.902V88.5464C175.093 71.6761 161.417 58 144.546 58ZM250.07 388.422C208.675 388.422 175.118 354.864 175.118 313.469C175.118 272.074 208.675 238.517 250.07 238.517C291.465 238.517 325.023 272.074 325.023 313.469C325.023 354.864 291.465 388.422 250.07 388.422Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M386.03 307.934H324.821C324.955 309.761 325.023 311.607 325.023 313.468C325.023 354.863 291.465 388.421 250.07 388.421C208.675 388.421 175.118 354.863 175.118 313.468C175.118 311.607 175.186 309.761 175.319 307.934H114V313.468V316.255H114.028C115.512 390.118 175.852 449.539 250.07 449.539C325.22 449.539 386.141 388.618 386.141 313.468C386.141 311.615 386.104 309.769 386.03 307.934Z"
        fill="#C1E1F8"
      />
    </svg>
  );
}
