import { FilterTags } from 'components/Header';
import HeaderMainLayout from 'components/HeaderMainLayout';
import { MainPageContainer } from 'components/MainPageContainer';
import { ButtonWithIcons } from 'components/common';
import React, { useEffect, useState } from 'react';
import { AiFillFolderAdd, AiFillPlusCircle } from 'react-icons/ai';
import { BiSolidBookAlt } from 'react-icons/bi';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useTranslation } from 'react-i18next';
import { ERoutes } from 'ts/enums';
import { getListProjectTagsAction } from 'redux/project-tags/project-tags.action';
import { getListTagsAction } from 'redux/tags/tags.action';
import { createNewProject } from 'apis/projects.api';
import { createFolderAction } from 'redux/folder/folder.action';
import { setDictionaryScreen } from 'redux/dictionary/dictionary.slice';
import ShareProjectModal from 'components/MainPageContainer/ShareProjectModal';
import { useAuth } from 'context/AuthContext';
import { usePageLoading } from 'context/PageLoadingContext';
import VideoAssembleIcon from 'assets/img/video-assemble.png';
import { setMedia } from 'redux/media/media.slice';
import { resetCurrentProject } from 'redux/projects/projects.slice';
import { useGA } from 'context/CustomAnalytics';
import { changeActiveOpenPaymentModal } from 'redux/projects/projects.slice';
import { Crisp } from 'crisp-sdk-web';
import { useLoaderContext } from 'context/LoaderContext';

// declare global {
//   interface Window {
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     $crisp: any;
//   }
// }

function MainPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isLogged } = useAuth();
  const isDictionaryScreen = useAppSelector((state) => state.dictionary.isDictionaryScreen);
  const { openMessage, destroyMessage } = usePageLoading();
  const [disabledCreateFolder, setDisableCreateFolder] = useState<boolean>(false);
  const [disabledCreateProject, setDisableCreateProject] = useState<boolean>(false);
  const { trackEvent } = useGA();
  const userInfo = useAppSelector((state) => state.auth.currentUser.data);
  const { loader } = useLoaderContext();
  const crispInjected = Crisp.isCrispInjected();

  useEffect(() => {
    if (isLogged) {
      dispatch(getListTagsAction());
      dispatch(getListProjectTagsAction());
      const url = localStorage.getItem('redirect-url');
      if (url) {
        localStorage.removeItem('redirect-url');
        window.location.replace(url);
      }
    }
  }, [isLogged, dispatch]);

  useEffect(() => {
    if (crispInjected && userInfo && isLogged) {
      Crisp.user.setEmail(userInfo.email);
      Crisp.user.setAvatar(userInfo.avatar || '');
      Crisp.user.setNickname(userInfo.fullName);
    }
  }, [userInfo, isLogged, crispInjected]);

  if (!isLogged) {
    return <Navigate to={ERoutes.MAIN} />;
  }

  return (
    <div>
      <HeaderMainLayout
        menuElement={
          <>
            {!isDictionaryScreen && (
              <div className="d-none d-md-flex gap-1">
                <FilterTags />
                <ButtonWithIcons
                  tooltipTile={t('Pages.Main.Texts.NewFolder')}
                  style={{ marginLeft: 12 }}
                  disabled={disabledCreateFolder}
                  onClick={() => {
                    loader.start();
                    setDisableCreateFolder(true);
                    destroyMessage();
                    openMessage('loading', t('Pages.Main.Texts.CreatingFolder'));
                    dispatch(
                      createFolderAction({ name: t('Pages.Main.Texts.MyNewFolder'), empty: false })
                    ).then(() => {
                      destroyMessage();
                      setDisableCreateFolder(false);
                      loader.complete();
                    });
                  }}
                  icon={<AiFillFolderAdd size={33} color="var(--si-primary)" />}
                />
                <ButtonWithIcons
                  tooltipTile={t('Pages.Main.Texts.MyDictionaries')}
                  style={{ marginLeft: 4 }}
                  onClick={() => {
                    dispatch(setDictionaryScreen({ isDictionaryScreen: true }));
                  }}
                  icon={<BiSolidBookAlt size={28} color="var(--si-primary)" />}
                />
              </div>
            )}
            <ButtonWithIcons
              onClick={() => {
                window.open('https://edit.simonsaysai.com/', '_blank');
              }}
              tooltipTile={t('Pages.Main.Texts.VideoAssembleMode')}
              icon={<img src={VideoAssembleIcon} width={20} height={20} />}
              className="btn-si-primary --fill ms-1 rounded-2 d-none d-md-flex"
              style={{ padding: '5px 6px', fontSize: '14px', marginLeft: 2 }}
              text={t('Pages.Main.Texts.VideoAssembleMode')}
              hiddenLargeTablet
            />
          </>
        }
        rightButton={
          <ButtonWithIcons
            disabled={disabledCreateProject}
            icon={<AiFillPlusCircle size={24} />}
            className="btn-si-primary --outline rounded-5 btn-new-project"
            style={{ padding: '10px 23px', border: '3px solid var(--si-primary)' }}
            text={t('Pages.Main.Texts.NewProject')}
            onClick={async () => {
              loader.start();
              setDisableCreateProject(true);
              trackEvent({ category: 'Project', action: 'Create new project' });
              destroyMessage();
              openMessage('loading', t('Pages.Main.Texts.CreatingNewProject'));
              dispatch(setMedia({ media: null }));
              dispatch(resetCurrentProject());
              createNewProject({ name: t('Pages.Main.Texts.MyNewProject') }).then((project) => {
                destroyMessage();
                navigate(`${ERoutes.UPLOAD}/${project?.id}`, { preventScrollReset: true });
                setDisableCreateProject(false);
                dispatch(changeActiveOpenPaymentModal(true));
                loader.complete();
              });
            }}
            hiddenTablet
          />
        }
      />
      <MainPageContainer />
      <ShareProjectModal />
    </div>
  );
}

export default MainPage;
