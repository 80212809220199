import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { usePageLoading } from 'context/PageLoadingContext';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiSolidBookAlt } from 'react-icons/bi';
import { FaCalendarAlt, FaInfoCircle, FaLanguage } from 'react-icons/fa';
import { deleteDictionaryAction } from 'redux/dictionary/dictionary.action';
import { useAppDispatch } from 'redux/hooks';
import { pushModal } from 'redux/modal/modal.slice';
import { EModals } from 'ts/enums';
import { TDictionary } from 'ts/types';
import { getFormattedDate } from 'utils/time';

export function DictionaryItem({ dictionary }: { dictionary: TDictionary }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { openMessage, destroyMessage } = usePageLoading();

  function openAddDictionaryModal() {
    dispatch(
      pushModal({
        name: EModals.ADD_DICTIONARY_MODAL,
        data: { dictionary: dictionary }
      })
    );
  }

  function handleDeleteDictionary() {
    destroyMessage();
    openMessage('loading', 'Deleting...');
    dispatch(deleteDictionaryAction({ dictionary })).then(() => {
      destroyMessage();
      openMessage('success', 'Deleted!');
    });
  }

  return (
    <div
      className="dictionary-item px-2 py-1 mb-1 d-flex align-items-md-center justify-content-between flex-column flex-md-row gap-1 cursor-pointer"
      onClick={(e) => {
        openAddDictionaryModal(), e.stopPropagation();
      }}
    >
      <div className="d-flex align-items-center gap-1">
        <BiSolidBookAlt size={32} color="var(--si-primary)" />
        <span>{dictionary.name}</span>
      </div>
      <div className="d-flex align-items-center gap-2">
        <span className="d-flex align-items-center gap-1">
          <FaInfoCircle size={17} color="var(--si-primary)" />
          {dictionary.tags.length}
        </span>
        <span className="d-flex align-items-center gap-1">
          <FaLanguage size={20} color="var(--si-primary)" />
          {dictionary.languageLabel}
        </span>
        <span className="d-flex align-items-center gap-1">
          <FaCalendarAlt size={20} color="var(--si-primary)" />
          {getFormattedDate(dictionary.creationDate)}
        </span>
        <Popconfirm
          placement="right"
          title={t('Pages.Main.Dictionary.DeletionConfirm')}
          onConfirm={(e) => {
            handleDeleteDictionary(), e?.stopPropagation();
          }}
          okText={t('Shared.Texts.Yes')}
          cancelText={t('Shared.Texts.No')}
          onCancel={(e) => {
            e?.stopPropagation();
          }}
        >
          <span
            className="delete-icon cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <AiOutlineDelete size={20} color="var(--si-primary)" />
          </span>
        </Popconfirm>
      </div>
    </div>
  );
}
