/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject, useEffect, useState } from 'react';

const useClickOutside = ({
  insideRef,
  afterClickOutSide
}: {
  insideRef: RefObject<HTMLDivElement> | RefObject<HTMLInputElement>;
  afterClickOutSide: () => void;
}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);

    function handleClickOutside(event: any) {
      if (isMounted && insideRef.current && !insideRef.current.contains(event.target)) {
        afterClickOutSide();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMounted, insideRef, afterClickOutSide]);
};

export default useClickOutside;
