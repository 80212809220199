import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import * as Sentry from '@sentry/react';
interface WebSocketConnectionContextType {
  unsubscribe: (topicChannelId: string) => void;
  isConnected: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscribe: ({ topicChannelId, callback }: { topicChannelId: string; callback: any }) => void;
  disconnected: () => void;
  reconnect: () => void;
  reconnectCount: number;
  resetReconnectCount: () => void;
}

const WebSocketConnectionContext = createContext<WebSocketConnectionContextType>(null!);

export function WebSocketConnectionProvider({ children }: { children: React.ReactNode }) {
  const socketClient = useRef<Client | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [listTopicQueue, setListTopicQueue] = useState<string[]>([]);
  const [reconnectCount, setReconnectCount] = useState(0);

  function createSocket() {
    const sockJsProtocols = ['xhr-polling'];
    return new SockJS(`${process.env.REACT_APP_API_URL}simonsocket`, null, {
      transports: sockJsProtocols
    });
  }

  function connect({ isReconnect }: { isReconnect?: boolean }) {
    const client = new Client({
      webSocketFactory: createSocket,
      reconnectDelay: 100,
      stompVersions: {
        versions: ['1.1', '1.0'],
        supportedVersions: () => '1.1',
        protocolVersions: () => ['1.1', '1.0']
      },
      connectionTimeout: 3000,
      onConnect: () => {
        setIsConnected(true);
        socketClient.current = client;
        if (isReconnect) {
          setTimeout(() => {
            listTopicQueue.forEach((topic) => {
              handleSubscribeTopic({
                topicChannelId: topic,
                callback: () => {},
                forceReSubscribe: true
              });
            });
          }, 2000);
        }
      },
      onDisconnect: () => {
        listTopicQueue.forEach((topic) => {
          handleUnSubcribeTopic(topic, true);
        });
        setIsConnected(false);
      },
      onWebSocketError: (e) => {
        console.log('connect socket error', e);
        Sentry.captureMessage(JSON.stringify({ topic: 'connect socket error', message: e }));
      },
      onStompError: (e) => {
        console.log('connect stomp error', e);
        Sentry.captureMessage(JSON.stringify({ topic: 'connect stomp error', message: e }));
      },
      splitLargeFrames: true
    });
    if (client) {
      client.activate();
    }
  }

  function reconnect() {
    connect({ isReconnect: true });
  }

  function disconnected() {
    socketClient.current?.deactivate();
  }

  const handleSubscribeTopic = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({
      topicChannelId,
      callback,
      forceReSubscribe = false
    }: {
      topicChannelId: string;
      callback: any;
      forceReSubscribe?: boolean;
    }) => {
      try {
        if (isConnected || forceReSubscribe) {
          setListTopicQueue((prev) =>
            [...prev, topicChannelId].filter(
              (val, index) => [...prev, topicChannelId].indexOf(val) === index
            )
          );

          socketClient.current?.subscribe(
            topicChannelId,
            (response) => {
              setTimeout(() => {
                callback(JSON.parse(response.body));
              }, 0);
            },
            { id: topicChannelId }
          );
          console.groupEnd();
        }
      } catch (error) {
        console.log('handleSubscribeTopic error:', error);
      }
    },
    [isConnected]
  );

  const handleUnSubcribeTopic = useCallback(
    (id: string, forceUnSubcribe?: boolean) => {
      try {
        if (socketClient.current) {
          if (isConnected || forceUnSubcribe) {
            socketClient.current.unsubscribe(id);
          }
        }
      } catch (error) {
        console.log('handleUnSubcribeTopic error:', error);
      }
    },
    [isConnected]
  );

  useEffect(() => {
    connect({});
  }, []);

  useEffect(() => {
    let interval: any;
    if (!isConnected && socketClient.current) {
      interval = setInterval(() => {
        // reconnect();
        setReconnectCount((prev) => (prev += 1));
      }, 1000);
    }
    if (isConnected) {
      clearInterval(interval);
      setReconnectCount(0);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isConnected, socketClient.current]);

  // useEffect(() => {
  //   if (reconnectCount >= 60) {
  //     window.location.reload();
  //   } else {
  //     console.info(`trying to reconnect ${reconnectCount}s`);
  //   }
  // }, [reconnectCount]);

  const value = {
    unsubscribe: (topicChannelId: string) => handleUnSubcribeTopic(topicChannelId),
    isConnected,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subscribe: ({ topicChannelId, callback }: { topicChannelId: string; callback: any }) =>
      handleSubscribeTopic({ topicChannelId, callback }),
    disconnected: disconnected,
    reconnect,
    reconnectCount,
    resetReconnectCount: () => setReconnectCount(0)
  };

  return (
    <WebSocketConnectionContext.Provider value={value}>
      {children}
    </WebSocketConnectionContext.Provider>
  );
}

export function useWebSocket() {
  return useContext(WebSocketConnectionContext);
}
