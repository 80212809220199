import { CreditCard, ModalBase, TimeDisplay } from 'components/common';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { popModal, pushModal } from 'redux/modal/modal.slice';
import {
  EBurnInType,
  EExportSubData,
  EModals,
  EPayType,
  EPlanOption,
  ERoutes,
  EStatusMedia,
  ETypeNoti
} from 'ts/enums';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import {
  convertCreditToHourMinute,
  convertDurationToHourMinute,
  pushStripeTransactionTracking
} from 'utils/common';
import { TResponseTranslatePrice } from 'ts/types/TResponse';
import { Link, useParams } from 'react-router-dom';
import { TChangeTime } from 'ts/types';
import { getPriceWithPayType } from 'apis/price.api';
import {
  exportBurnInCheckout,
  exportBurnIn,
  exportBurnInCheckoutWithoutCredit
} from 'apis/export.api';
import { notification } from 'antd';
import { usePageLoading } from 'context/PageLoadingContext';
import { useCreditCard } from 'context/CreditCardContext';
import { changeNumberToCurrency } from 'utils/price';
import { useTranslation } from 'react-i18next';

export function BurnInModal() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [price, setPrice] = useState<TResponseTranslatePrice | null>(null);
  const userData = useAppSelector((state) => state.auth.currentUser.data);
  const currentProject = useAppSelector((state) => state.projects.currentProject);
  const [changeAbleTime, setChangeAbleTime] = useState<TChangeTime>({ hour: 0, minutes: 0 });
  const data = useAppSelector((state) => state.modal.lastModal?.data);
  const { openMessage, destroyMessage } = usePageLoading();
  const [nameMedia, setNameMedia] = useState<string>('');
  const [timeMedia, setTimeMedia] = useState<number>(0);
  const {
    cardData,
    cardName,
    cvcData,
    expiryMonthData,
    expiryYearData,
    isUpdateCardSaved,
    isExistedCard,
    isReadyCard,
    triggerShowError
  } = useCreditCard({ currentUser: userData });
  const [isLoading, setIsLoading] = useState(false);

  const durationToTime = (price?.totalDuration &&
    convertDurationToHourMinute(price?.totalDuration)) || {
    hour: 0,
    minutes: 0
  };

  const creditToTime = (price?.credit && convertCreditToHourMinute(price?.credit)) || {
    hour: 0,
    minutes: 0
  };

  async function getPriceData() {
    if (currentProject.data && data && data.mediaId) {
      return await getPriceWithPayType({
        projectId: currentProject.data?.id,
        mediaId: data.mediaId,
        payType: EPayType.BURN_IN
      });
    } else return null;
  }

  const checkBurnin = async () => {
    if (!currentProject.data || !data) return;
    if (price && price.totalPrice > 0) {
      if (isExistedCard) {
        return await exportBurnInCheckout({
          projectId: currentProject.data.id,
          mediaId: data.mediaId
        });
      } else {
        return await exportBurnInCheckoutWithoutCredit({
          cardHolderName: cardName.data,
          cvc: cvcData.data,
          exp_month: expiryMonthData.data,
          exp_year: expiryYearData.data,
          mediaId: data.mediaId,
          number: cardData?.cardNumber || '',
          projectId: currentProject.data.id,
          saveCard: isUpdateCardSaved
        });
      }
    }
    return await exportBurnInCheckout({
      projectId: currentProject.data.id,
      mediaId: data.mediaId
    });
  };

  async function handleExportBurnIn() {
    if (!price) return;
    if (!isReadyCard && !isExistedCard && price.totalPrice > 0) {
      triggerShowError();
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    if (currentProject.data && data && data.mediaId) {
      destroyMessage();
      openMessage('loading', 'Exporting...');
      const res = await checkBurnin();

      if (res && 'message' in res) {
        setIsLoading(false);
        destroyMessage();
        dispatch(
          pushModal({
            name: EModals.NOTI_MODAL,
            data: null,
            notiData: { type: ETypeNoti.ERROR, title: `${res.message}` }
          })
        );
        return;
      }

      if (res && !('message' in res)) {
        if (res.stripeTransactionDetail) pushStripeTransactionTracking(res.stripeTransactionDetail);

        const burnInRes = await exportBurnIn({
          projectId: currentProject.data.id,
          currentMediaId: data.mediaId,
          eventLogId: res.eventLogId,
          burnInType:
            data.subData === EExportSubData.TIME_CODE_BITC
              ? EBurnInType.WITH_TIME_CODE
              : EBurnInType.SUBTITLE
        });
        destroyMessage();
        if (burnInRes.status === 200) {
          notification.success({
            message:
              'Thank you. In a few minutes you will receive an email with a link to download the subtitled video(s). Please check your spam box if you do not see it.'
          });
          dispatch(popModal());
        } else {
          notification.error({
            message: 'Something went wrong. Please try again later.'
          });
        }
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    if (currentProject.data && currentProject.data.medias.length > 0 && data && data.subData) {
      (async () => {
        const res = await getPriceData();
        if (res) {
          setPrice(res);
          setChangeAbleTime(convertDurationToHourMinute(res.chargeableTimeInSeconds));
          const mediaDetail = JSON.parse(res.mediaDetails);
          if (mediaDetail.length > 0) {
            setNameMedia(mediaDetail[0].name);
            setTimeMedia(mediaDetail[0].duration);
          }
        }
      })();
    }
  }, [currentProject, data]);

  const mediaToTime = convertDurationToHourMinute(timeMedia) || {
    hour: 0,
    minutes: 0
  };

  if (price === null || (!price && !userData)) return null;

  return (
    <ModalBase
      modalName={EModals.BURN_IN_MODAL}
      onCloseModal={() => {
        dispatch(popModal());
      }}
    >
      <div className="ctn-payment-modal">
        <h4 className="text-center">Ready when you are.</h4>

        <div
          className="ctn-cost rounded border row p-2 mx-0"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <div className="d-flex flex-column align-items-center justify-content-center col-6">
            <h4 style={{ color: 'var(--si-old-silver)' }}>{t('Shared.Fields.TotalCost')}</h4>
            <div>
              <span style={{ fontSize: 22, fontWeight: 700 }}>
                {changeNumberToCurrency(price?.totalPrice || 0).prevPriod}
              </span>
              .{changeNumberToCurrency(price?.totalPrice || 0).afterPriod} USD
            </div>
          </div>
          <div className="border-start col-6" style={{ fontSize: 10, paddingLeft: 0 }}>
            <div className="ps-5">
              <div style={{ color: 'var(--si-old-silver)', lineHeight: '12px' }}>
                <span>{t('Pages.TranscriptDetail.TranslateModal.TotalDurationToTranslate')}</span>
              </div>
              <div>
                <TimeDisplay
                  hours={durationToTime.hour}
                  minutes={durationToTime.minutes}
                  isVisibleInfo={false}
                  nameMedia={nameMedia}
                  minuteMedia={mediaToTime.minutes}
                  hoursMedia={mediaToTime.hour}
                />
              </div>
              {price && price.credit > 0 && (
                <>
                  <div style={{ color: 'var(--si-old-silver)' }}>
                    <small>{t('Shared.Fields.CreditAvailable')}:</small>
                  </div>
                  <div>
                    <TimeDisplay hours={creditToTime.hour} minutes={creditToTime.minutes} />
                  </div>
                </>
              )}

              {price && price.credit > 0 && (
                <>
                  <div style={{ color: 'var(--si-old-silver)' }}>
                    <small>{t('Shared.Fields.ChargeableTime')}:</small>
                  </div>
                  <div>
                    <TimeDisplay hours={changeAbleTime.hour} minutes={changeAbleTime.minutes} />
                  </div>
                </>
              )}
              {price && price.chargeableTimeInSeconds > 0 && (
                <>
                  <div style={{ color: 'var(--si-old-silver)' }}>
                    <small>{t('Shared.Fields.HourlyTranscriptionRate')}:</small>
                  </div>
                  <span className="fw-bolder">
                    ${price?.rate}/{t('Shared.Fields.hour', { count: 0 })}
                  </span>
                </>
              )}
              {price &&
                price.chargeableTimeInSeconds > 0 &&
                price.chargeableTimeInSeconds < price.minimumChargeableTimeInSeconds && (
                  <>
                    <div style={{ color: 'var(--si-old-silver)' }}>
                      <small>{t('Shared.Fields.MinimumCost')}:</small>
                    </div>
                    <div className="fw-bolder">
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                      }).format(price ? price.minimumPrice : 0)}
                    </div>
                  </>
                )}
                <div className='policy-text'>
                  <p className='mb-0 mt-2'>{ t('Pages.Main.PaymentModal.NoRefundPolicy') }</p>
                  <Link
                    to="https://www.simonsaysai.com/help/2657979-can-i-read-your-privacy-policy-terms-of-service"
                    target="_blank"
                    rel="noopener"
                    className="custom-a-tag"
                  >
                    {t("Shared.Texts.TermsAndConditions")}
                  </Link>
                </div>
            </div>
          </div>
        </div>
        {price && price.totalPrice > 0 && (
          <div className="mb-1">
            <CreditCard />
          </div>
        )}
        <div className="text-center">
          <button
            className="submit-button px-2 py-1"
            style={{ borderRadius: 98 }}
            onClick={handleExportBurnIn}
            disabled={isLoading}
          >
            <FaWandMagicSparkles />
            <span className="ms-1">Let&apos;s do this</span>
          </button>
        </div>
      </div>
    </ModalBase>
  );
}
