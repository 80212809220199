import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './auth/auth.slice';
import folderSlice from './folder/folder.slice';
import projectsSlice from './projects/projects.slice';
import transcriptionSlice from './transcription/transcription.slice';
import tagsSlice from './tags/tags.slice';
import projectTagSlice from './project-tags/project-tag.slice';
import modalSlice from './modal/modal.slice';
import languageSlice from './language/language.slice';
import mediaSlice from './media/media.slice';
import dictionarySlice from './dictionary/dictionary.slice';
import tokenSlice from './token/token.slice';

const rootReducers = combineReducers({
  auth: authSlice,
  folder: folderSlice,
  projects: projectsSlice,
  transcription: transcriptionSlice,
  tags: tagsSlice,
  projectTags: projectTagSlice,
  modal: modalSlice,
  languages: languageSlice,
  media: mediaSlice,
  dictionary: dictionarySlice,
  token: tokenSlice
});

export default rootReducers;
