import { useTranslation, Trans } from "react-i18next";

export function EmptyStateProjectShareWithMe() {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center h-100" style={{ color: 'var(--bs-gray-600)' }}>
      <span className="mt-2" style={{ fontSize: 18 }}>
        <Trans t={t} i18nKey="Shared.Texts.NoProjectSharedWithYou">
          Here is the link <a style={{ color: 'var(--si-primary)' }} href="https://www.simonsaysai.com/help/4123009-how-to-share-projects" target="_blank" rel="noreferrer">Link</a>
        </Trans>
      </span>
    </div>
  );
}
