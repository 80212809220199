import { ButtonWithIcons } from 'components/common';
import { useVideoPlayerContext } from 'context/VideoPlayerContext';
import { BsFillPlayFill, BsPauseFill } from 'react-icons/bs';
import { Scrubber } from 'react-scrubber';
import { Progress } from 'antd';
import { GoMute, GoUnmute } from 'react-icons/go';
import { formatTime } from 'utils/common';

const PlayBackRate = [0.5, 0.75, 1, 1.25, 1.5, 2];

export default function ControlPlayerMobile() {
  const {
    currentTime,
    isPlaying,
    setIsPlaying,
    seekTo,
    markList,
    volume,
    setVolume,
    videoDuration,
    playBackRate,
    setPlayBackRate,
    userDefinedTimeCode,
    showPreview,
    setShowPreview
  } = useVideoPlayerContext();
  const remainingTime = videoDuration - currentTime + userDefinedTimeCode;

  function handlePlayPause() {
    if (showPreview) {
      setShowPreview(false);
    }
    setIsPlaying(!isPlaying);
  }

  function handleChangePlayBackRate() {
    const findNextIdx = PlayBackRate.findIndex((rate) => rate === playBackRate) + 1;
    const nextRate = PlayBackRate[findNextIdx];
    if (!nextRate) {
      setPlayBackRate(PlayBackRate[0]); //default;
      return;
    }
    setPlayBackRate(nextRate);
  }

  return (
    <div className="control-player-mobile d-flex align-items-center d-sm-none mb-1">
      <div className="left d-flex align-items-center gap-1 p-1">
        <button style={{ color: 'var(--si-primary)' }} onClick={handleChangePlayBackRate}>
          {playBackRate}x
        </button>
        <button
          onClick={() => {
            if (volume > 0) {
              setVolume(volume - 25);
            } else {
              setVolume(100);
            }
          }}
          className="d-flex align-items-center"
        >
          <Progress
            type="circle"
            size={22}
            percent={volume}
            strokeColor="#7e56a2"
            format={() => (volume ? <GoUnmute size={12} color="#7e56a2" /> : <GoMute size={12} />)}
          />
        </button>
        <ButtonWithIcons
          className="d-flex align-items-center"
          icon={
            isPlaying ? (
              <BsPauseFill size={24} color="var(--si-primary)" />
            ) : (
              <BsFillPlayFill size={24} color="var(--si-primary)" />
            )
          }
          onClick={handlePlayPause}
        />
      </div>

      <div className="right d-flex align-items-center gap-1 p-1">
        <div className="current-time ms-1 d-flex justify-content-start">
          {formatTime(currentTime + userDefinedTimeCode)}
        </div>
        <div className="scrub-bar">
          <Scrubber
            min={0 + userDefinedTimeCode}
            max={videoDuration + userDefinedTimeCode}
            className="cursor-pointer"
            value={currentTime}
            onScrubChange={(value: number) => {
              seekTo && seekTo(value - userDefinedTimeCode);
            }}
            onScrubEnd={(value: number) => {
              seekTo && seekTo(value - userDefinedTimeCode);
            }}
            markers={markList}
            tooltip={{
              enabledOnHover: true,
              formatString: (value: number) => formatTime(value),
              className: 'control-tooltip-class'
            }}
          />
        </div>
        <div className="left-time ms-1 d-flex justify-content-start">
          {remainingTime > 0 ? formatTime(remainingTime) : formatTime(0)}
        </div>
      </div>
    </div>
  );
}
