import { useAuth0 } from '@auth0/auth0-react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getUserInfoAction } from 'redux/auth/auth.action';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getListLanguagesAction } from 'redux/language/language.action';
import { useWebSocket } from 'context/WebSocketConnectionContext';
import { createChannelId } from 'utils/common';
import { TUser } from 'ts/types';
import { updateCurrentUser } from 'redux/auth/auth.slice';
import { EHttpStatusCode, ESocketTopic } from 'ts/enums';
import { useCookies } from 'react-cookie';
import { usePageLoading } from './PageLoadingContext';
import { useTranslation } from 'react-i18next';

interface AuthContextType {
  isLogged: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsLogged: (val: boolean) => void;
  isLoading: boolean;
  setIsLoading: (val: boolean) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, getIdTokenClaims, getAccessTokenSilently, logout } = useAuth0();
  const [cookies, setCookie] = useCookies();
  const [isLogged, setIsLogged] = useState<boolean>(
    Object.keys(cookies).filter((name) => name.includes('auth0')).length > 0 ? true : false
  );
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const accessToken = localStorage.getItem('ss.accessToken');
  const { subscribe, unsubscribe } = useWebSocket();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const { openMessage } = usePageLoading();

  useEffect(() => {
    const url = location.href;
    const currentUrl = new URL(url);
    const params = new URLSearchParams(currentUrl.search);
    const returnType = params.get('returnType');
    const returnResult = params.get('returnResult');

    if (returnType && returnResult && returnResult === 'success' && returnType === 'BuyCredit') {
      setTimeout(() => {
        openMessage('success', t('Shared.Texts.Added'));
      }, 2000);

      const paramsToDelete = ['returnType', 'returnResult'];
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      paramsToDelete.forEach((param) => params.delete(param));
      url.search = params.toString();
      window.history.replaceState({}, '', url);
    }
  }, [location.href]);

  useEffect(() => {
    if (currentUser.data) {
      subscribe({
        topicChannelId: createChannelId('topic', ESocketTopic.USER_DATA, currentUser.data.id),
        callback: (res: TUser) => {
          dispatch(updateCurrentUser(res));
        }
      });
    }

    return () => {
      if (currentUser.data) {
        unsubscribe(createChannelId('topic', ESocketTopic.USER_DATA, currentUser.data.id));
      }
    };
  }, [currentUser.data]);

  useEffect(() => {
    if (isAuthenticated) {
      handleGetUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!accessToken) {
      setIsLogged(false);
    }
  }, [accessToken]);

  async function handleGetUser() {
    setIsLoading(true);
    const idTokenClaims = await getIdTokenClaims();
    const accessToken = await getAccessTokenSilently();
    const idToken = idTokenClaims?.__raw;
    const expired = idTokenClaims?.exp;
    if (idToken && accessToken) {
      localStorage.setItem('ss.accessToken', accessToken);
      localStorage.setItem('ss.expired', JSON.stringify(expired));
      setIsLogged(true);
      setIsLoading(false);
    }
  }

  function handleLogout() {
    localStorage.removeItem('ss.accessToken');
    localStorage.removeItem('ss.expired');
    logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH0_REDIRECT_LOGOUT } });
  }

  useEffect(() => {
    if (isLogged) {
      dispatch(getUserInfoAction()).then((user) => {
        if (user.payload === EHttpStatusCode.UNAUTHORIZED) {
          handleLogout();
        }
      });
      dispatch(getListLanguagesAction());
    }
  }, [isLogged]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const tokenExpired = localStorage.getItem('ss.expired');
      const now = new Date().getTime() / 1000;

      if (tokenExpired && now > Number(tokenExpired)) {
        handleLogout();
      }
    }, 3 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const value = {
    isLogged,
    setIsLogged: (val: boolean) => setIsLogged(val),
    isLoading,
    setIsLoading: (val: boolean) => setIsLoading(val),
    logout: () => handleLogout()
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
