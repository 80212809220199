import BoxLanguageIcon from 'assets/icons/box-language.svg';
import { PropsExport } from '.';
import { EExportName, EStatusProject } from 'ts/enums';
import { FaCircleInfo } from 'react-icons/fa6';
import { Tooltip } from 'antd';
import { useAppSelector } from 'redux/hooks';
import { useTranslation } from 'react-i18next';

export function TranslateExport({ exportOption, setExportOption }: PropsExport) {
  const currentProject = useAppSelector((state) => state.projects.currentProject.data);
  const { t } = useTranslation();

  return (
    <div className="translate-export export-item">
      <div
        className={`d-flex flex-column justify-content-center align-items-center ${
          currentProject?.status !== EStatusProject.TRANSLATED ? 'cursor-pointer' : 'disable'
        }`}
        onClick={() => {
          if (currentProject?.status === EStatusProject.TRANSLATED) return;
          currentProject &&
            setExportOption((draft) => {
              draft.key = EExportName.TRANSLATE;
              draft.data.subData = null;
              draft.data.option = {};
              draft.allowMultipleMedia = false;
            });
        }}
      >
        <div
          className={`icon ${
            exportOption.key === EExportName.TRANSLATE && currentProject
              ? 'opacity-100'
              : 'opacity-50'
          }`}
        >
          <img src={BoxLanguageIcon} alt="" />
        </div>
        <div
          className={`text mt-1 ${exportOption.key === EExportName.TRANSLATE ? '--selected' : ''}`}
        >
          <span className="align-middle">{t('Layouts.ExportModal.Translate.Title')}</span>
          {currentProject?.status === EStatusProject.TRANSLATED && (
            <span className="cursor-pointer ms-1">
              <Tooltip
                placement="top"
                trigger={'hover'}
                title={t('Layouts.ExportModal.Translate.UseSourceProjectPls')}
                arrow={false}
                overlayClassName="custom-toolstip"
              >
                <FaCircleInfo />
              </Tooltip>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
