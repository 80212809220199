import { createTag } from 'apis/tags.api';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  createProjectTagsAction,
  deleteProjectTagAction
} from 'redux/project-tags/project-tags.action';
import { createTagAction, deleteTagAction, updateTagAction } from 'redux/tags/tags.action';
import { EStatePopOverLabel, ETagColor } from 'ts/enums';
import { TProject, TTag } from 'ts/types';
import { TagItem } from './TagItem';
import { MdArrowBackIos } from 'react-icons/md';
import { Popconfirm, notification } from 'antd';
import { usePageLoading } from 'context/PageLoadingContext';
import { useTranslation, Trans } from 'react-i18next';
import { useLoaderContext } from 'context/LoaderContext';

const listColor = [
  ETagColor.LIGHT_SEA_GREEN,
  ETagColor.MOONSTONE,
  ETagColor.DEEP_LILAC,
  ETagColor.POLICE_BLUE,
  ETagColor.JONQUI,
  ETagColor.VIVID_TANGELO,
  ETagColor.CARMINE_PINK,
  ETagColor.UFO_GREEN,
  ETagColor.GREEN,
  ETagColor.SILVER_SAND
];

export function RenderContentPopOver({
  tagSelected,
  projectSelected,
  statePopOverProps,
  nameProps,
  colorProps,
  setNameProps,
  setColorProps,
  setStatePopOverProps,
  open,
  setOpen
}: {
  tagSelected?: TTag | null;
  projectSelected: TProject;
  nameProps: string | null;
  colorProps: string | null;
  statePopOverProps: EStatePopOverLabel;
  setNameProps: React.Dispatch<React.SetStateAction<string | null>>;
  setColorProps: React.Dispatch<React.SetStateAction<string | null>>;
  setStatePopOverProps: React.Dispatch<React.SetStateAction<EStatePopOverLabel>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const listTags = useAppSelector((state) => state.tags.currentListTags.data);
  const listProjectTags = useAppSelector((state) => state.projectTags.currentListProjectTags.data);
  const [tagUpdate, setTagUpdate] = useState<TTag | null>(null);
  const dispatch = useAppDispatch();
  const { openMessage, destroyMessage } = usePageLoading();
  const { t } = useTranslation();
  const { loader } = useLoaderContext();

  function handleSaveTag({ tag }: { tag?: TTag }) {
    setStatePopOverProps(EStatePopOverLabel.LISTING_LABEL);
    loader.start();
    if (tag && nameProps && colorProps) {
      destroyMessage();
      openMessage('loading', t('Shared.Texts.UpdatingW3Dot'));
      dispatch(updateTagAction({ id: tag.id, name: nameProps, color: colorProps })).then(() => {
        destroyMessage();
        openMessage('success', t('Shared.Texts.Updated'));
        loader.complete();
      });
      return;
    }
    if (nameProps && colorProps) {
      destroyMessage();
      openMessage('loading', t('Shared.Texts.CreatingW3Dot'));
      createTag({ name: nameProps, color: colorProps })
        .then((result) => {
          destroyMessage();
          if (result) {
            openMessage('success', t('Shared.Texts.Created'));
            dispatch(createTagAction({ tag: result }));
            dispatch(createProjectTagsAction({ tagId: result.id, projectId: projectSelected.id }));
          }
          loader.complete();
        })
        .catch((error: any) => {
          destroyMessage();
          notification.open({ message: error.response.data.message, type: 'error' });
          loader.complete();
        });
      return;
    }
  }

  function updateProjectTag({ tagItem }: { tagItem: TTag }) {
    destroyMessage();
    openMessage('loading', t('Shared.Texts.UpdatingW3Dot'));
    loader.start();
    dispatch(createProjectTagsAction({ tagId: tagItem.id, projectId: projectSelected.id })).then(
      () => {
        destroyMessage();
        openMessage('success', t('Shared.Texts.Updated'));
        loader.complete();
      }
    );
    setStatePopOverProps(EStatePopOverLabel.LISTING_LABEL);
  }

  async function deleteProjectTags({ tagItem }: { tagItem: TTag }) {
    const findItem = listProjectTags.find(
      (projectTag) => projectTag.projectId === projectSelected.id && projectTag.tagId === tagItem.id
    );
    if (findItem) {
      destroyMessage();
      loader.start();
      openMessage('loading', t('Shared.Texts.DeletingW3Dot'));
      await dispatch(deleteProjectTagAction({ projectTag: findItem })).then(() => {
        destroyMessage();
        openMessage('success', t('Shared.Texts.Deleted'));
        loader.complete();
      });
    }
    setStatePopOverProps(EStatePopOverLabel.LISTING_LABEL);
  }

  function updateTag({ tagItem }: { tagItem: TTag }) {
    setStatePopOverProps(EStatePopOverLabel.UPDATE_LABEL);
    setTagUpdate(tagItem);
    setNameProps(tagItem.name);
    setColorProps(tagItem.color);
  }

  function deleteTag({ tag }: { tag: TTag }) {
    if (tag) {
      loader.start();
      dispatch(deleteTagAction({ tag }));
      const findItem = listProjectTags.find(
        (projectTag) => projectTag.projectId === projectSelected.id && projectTag.tagId === tag.id
      );
      if (findItem) {
        dispatch(deleteProjectTagAction({ projectTag: findItem, isHandleApi: false }));
      }
      setStatePopOverProps(EStatePopOverLabel.LISTING_LABEL);
      loader.complete();
    }
  }

  function ListingLabel() {
    return (
      <div className="list-tag d-flex align-items-center flex-column">
        {listTags.map((tagItem) => (
          <TagItem
            tag={tagItem}
            key={tagItem.id}
            idTagSelected={tagSelected && tagSelected.id}
            updateProjectTag={() => updateProjectTag({ tagItem })}
            updateTag={() => {
              updateTag({ tagItem });
            }}
            deleteProjectTags={async () => {
              await deleteProjectTags({ tagItem });
              setOpen(!open);
            }}
          />
        ))}
        {listTags.length < 10 && (
          <button
            className="create-label-button p-1 mt-3"
            onClick={(e) => {
              setStatePopOverProps(EStatePopOverLabel.CREATE_LABEL);
              setNameProps(null);
              setColorProps(null);
              e.stopPropagation();
            }}
          >
            +{t('Shared.Actions.CreateLabel')}
          </button>
        )}
      </div>
    );
  }

  function CRUDLabel({ tag }: { tag?: TTag }) {
    return (
      <div className="crud-label">
        <div className="header pb-1">
          <button
            className="back-button"
            onClick={(e) => {
              e.stopPropagation();
              setStatePopOverProps(EStatePopOverLabel.LISTING_LABEL);
            }}
          >
            <MdArrowBackIos size={14} />
          </button>
        </div>
        <div className="body">
          <div className="d-flex flex-column">
            <span className="label mb-1">{t('Shared.Fields.Name')}: </span>
            <input
              type="text"
              className="input-color mb-1"
              placeholder="Type Label Name"
              maxLength={25}
              value={nameProps || undefined}
              onChange={(e) => setNameProps(e.target.value)}
              onFocus={(e) => {
                e.target.select();
              }}
            />
          </div>
          <div className="d-flex flex-column">
            <span className="label mb-1">{t('Shared.Fields.Color')}: </span>
            <span
              className="color-display mb-1"
              style={{ backgroundColor: `${colorProps || tag?.color || 'var(--si-cultured)'}` }}
            />
          </div>
          <div className="color-picker d-flex justify-content-center flex-wrap gap-1 py-1 cursor-pointer">
            {listColor.map((color, index) => (
              <div
                className="color-item"
                style={{ backgroundColor: color }}
                key={color}
                onClick={(e) => {
                  e.stopPropagation();
                  setColorProps(color);
                }}
              >
                {index}
              </div>
            ))}
          </div>
        </div>
        <div className="action mt-1">
          <button
            className="save-button p-1"
            disabled={!nameProps || !colorProps}
            onClick={(e) => {
              handleSaveTag({ tag });
              e.stopPropagation();
            }}
          >
            {t('Shared.Actions.Save')}
          </button>
          {tag && (
            <Popconfirm
              placement="right"
              title={() => (
                <span>
                  <Trans
                    t={t}
                    i18nKey="Shared.Texts.LabelDeletionConfirm"
                    components={{ linebreak: <br /> }}
                  />{' '}
                </span>
              )}
              onConfirm={() => {
                deleteTag({ tag });
              }}
              okText={t('Shared.Texts.Yes')}
              cancelText={t('Shared.Texts.No')}
            >
              <button
                className="delete-button p-1 ms-1"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {t('Shared.Actions.Delete')}
              </button>
            </Popconfirm>
          )}
        </div>
      </div>
    );
  }

  switch (statePopOverProps) {
    case EStatePopOverLabel.LISTING_LABEL:
      return ListingLabel();
    case EStatePopOverLabel.CREATE_LABEL:
      return CRUDLabel({});
    case EStatePopOverLabel.UPDATE_LABEL:
      if (tagUpdate) return CRUDLabel({ tag: tagUpdate });
      break;
    default:
      return ListingLabel();
  }
}
