import { useEffect, useState } from 'react';

export const useUpdateWidth = () => {
  const [width, setWidth] = useState<number>(0);
  useEffect(() => {
    const setAppWidth = () => {
      setWidth(window.innerWidth);
    };

    setAppWidth();

    window.addEventListener('resize', setAppWidth);
    return () => {
      window.removeEventListener('resize', setAppWidth);
    };
  }, []);

  return { width };
};
