import { Select, Tag, notification } from 'antd';
import { ModalBase } from 'components/common';
import { Loader } from 'components/common/Loader';
import { useAuth } from 'context/AuthContext';
import React, { useEffect, useRef, useState } from 'react';
import { FaDownload, FaEdit } from 'react-icons/fa';
import {
  createDictionaryAction,
  getDictionaryAction,
  updateDictionaryAction
} from 'redux/dictionary/dictionary.action';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { popModal } from 'redux/modal/modal.slice';
import { EModals } from 'ts/enums';
import { TLanguageConvert } from 'ts/types';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { useTranslation } from 'react-i18next';
import { usePageLoading } from 'context/PageLoadingContext';
import { useLoaderContext } from 'context/LoaderContext';

export function AddDictionaryModal() {
  const dispatch = useAppDispatch();
  const [dictionarySelected, setDictionarySelected] = useState<any | null>(null);
  const [dictionaryIdSelected, setDictionaryIdSelected] = useState<number | null>(null);
  const listAllLanguages = useAppSelector((state) => state.languages.listAllLanguages);
  const [listFrequentlyUsed, setListFrequentlyUsed] = useState<TLanguageConvert[]>([]);
  const [listOther, setListOther] = useState<TLanguageConvert[]>([]);
  const [name, setName] = useState<string>('My new custom dictionary');
  const [tags, setTags] = useState<string[]>([]);
  const data = useAppSelector((state) => state.modal.lastModal?.data);
  const enable = name.length > 0 && tags.length > 0 && dictionarySelected !== null;
  const [loading, setLoading] = useState<boolean>(false);
  const { isLogged } = useAuth();
  const selectTagRef = useRef<any>(null);
  const { t } = useTranslation();
  const { openMessage } = usePageLoading();
  const { loader } = useLoaderContext();

  useEffect(() => {
    isLogged && dispatch(getDictionaryAction());
  }, [isLogged]);

  useEffect(() => {
    if (listAllLanguages.data && listAllLanguages.data?.length > 0) {
      const listLangRegions = listAllLanguages.data.filter((lang) => lang.regions.length > 0);
      const newListLang = listLangRegions.flatMap((lang) =>
        lang.regions.map((region) => ({
          id: lang.id,
          label: lang.label,
          direction: lang.direction,
          code: `${lang.code}-${region.code}`,
          translationCode: lang.translationCode,
          captionCode: lang.captionCode,
          fullStopSymbol: lang.fullStopSymbol,
          characterRatio: lang.characterRatio,
          fontName: lang.fontName,
          fontSize: lang.fontSize,
          regionName: `${lang.label} (${region.name})`,
          defaultSpeechApi: region.defaultSpeechApi,
          diarizationSupported: region.diarizationSupported,
          favorite: region.favorite,
          regionId: region.id
        }))
      );

      //set list Frequently
      const listFrequentlyConvert = newListLang.filter((lang) => lang.favorite);
      setListFrequentlyUsed(listFrequentlyConvert);

      //set list other
      const listOtherConvert = newListLang.filter((lang) => !lang.favorite);
      setListOther(listOtherConvert);

      //set Dictionary selected
      if (data && data.dictionary) {
        const dictionarySelect = newListLang.filter(
          (lang) => lang.id === data.dictionary.languageId
        );
        setDictionarySelected(dictionarySelect[0].regionName);
        setDictionaryIdSelected(dictionarySelect[0].id);
      }
    }
  }, [listAllLanguages, data]);

  function onChangeDictionary(e: any) {
    const value = JSON.parse(e);
    setDictionarySelected(value.name);
    setDictionaryIdSelected(value.languageId);
  }

  function clearAddDictionaryModal() {
    setName(t('Pages.Main.Dictionary.Modal.DefaultNewDictionaryName'));
    setDictionarySelected(null);
    setTags([]);
    dispatch(popModal());
  }

  function saveDictionary() {
    setLoading(true);
    loader.start();
    if (data && data.dictionary && dictionaryIdSelected) {
      dispatch(
        updateDictionaryAction({
          dictionary: {
            id: data.dictionary.id,
            languageId: dictionaryIdSelected,
            name: name,
            tags: tags
          }
        })
      ).then((res) => {
        clearAddDictionaryModal();
        setLoading(false);
        loader.complete();

        if (res.type === 'dictionary/update-dictionary/rejected') {
          notification.open({
            type: 'error',
            message: t('Pages.Main.Errors.UnableToUpdateDictionary')
          });
          return;
        }
        openMessage('success', 'Saved!');
      });
      return;
    }

    if (dictionaryIdSelected && name && tags.length > 0) {
      dispatch(
        createDictionaryAction({
          dictionary: {
            languageId: dictionaryIdSelected,
            name,
            tags
          }
        })
      ).then((res) => {
        loader.complete();
        clearAddDictionaryModal();
        setLoading(false);
        if (res.type === 'dictionary/create-dictionary/rejected') {
          notification.open({
            type: 'error',
            message: t('Pages.Main.Errors.UnableToCreateDictionary')
          });
          return;
        }
        openMessage('success', 'Created!');
      });
      return;
    }
  }

  useEffect(() => {
    if (data && data.dictionary) {
      setName(data.dictionary.name);
      setTags(data.dictionary.tags);
    } else {
      setName(t('Pages.Main.Dictionary.Modal.DefaultNewDictionaryName'));
      setDictionarySelected(null);
      setTags([]);
    }
  }, [data]);
  const tagRender = (props: CustomTagProps) => {
    /* eslint-disable react/prop-types */
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        className="tag-custom-dictionary-render"
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };
  return (
    <ModalBase
      modalName={EModals.ADD_DICTIONARY_MODAL}
      onCloseModal={() => {
        dispatch(popModal());
      }}
    >
      <div className="add-dictonary-modal">
        <div className="title">{t('Pages.Main.Dictionary.Modal.Title')}</div>
        <div className="body">
          <div className="section-input d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center" style={{ width: '70%' }}>
              <input
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                className={name ? '' : 'error'}
                placeholder={t('Pages.Main.Dictionary.Modal.InsertDictionaryName')}
                onFocus={(e) => {
                  e.target.select();
                }}
              />
              <FaEdit className="edit-icon ms-1" />
            </div>
            <a
              href="https://www.simonsaysai.com/help/4267675-custom-dictionary-to-increase-accuracy"
              target="_blank"
              rel="noreferrer"
            >
              {t('Pages.Main.Dictionary.Modal.DictionaryIntroLink')}
            </a>
          </div>
          <div className="section-choose-language">
            <Select
              dropdownStyle={{ fontFamily: 'GothamBook' }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
              }
              value={dictionarySelected}
              getPopupContainer={(trigger) => trigger.parentNode}
              className={`dictionary-select ${dictionarySelected ? '' : 'error'}`}
              style={{ width: '100%' }}
              onChange={onChangeDictionary}
              placeholder={
                <span style={{ fontStyle: 'italic', fontWeight: 500 }}>
                  {t('Pages.Main.Dictionary.Modal.ChooseDictionaryLanguage')}
                </span>
              }
              suffixIcon={null}
              options={[
                {
                  label: t('Shared.Texts.FrequentlyUsed'),
                  options: listFrequentlyUsed.map((language, index) => ({
                    key: `${language.id}-${language.regionId}-${language.code}`,
                    label: language.regionName,
                    value: JSON.stringify({ languageId: language.id, name: language.regionName })
                  }))
                },
                {
                  label: t('Shared.Texts.Others'),
                  options: listOther.map((language, index) => ({
                    key: `${language.id}-${language.regionId}-${language.code}`,
                    label: language.regionName,
                    value: JSON.stringify({ languageId: language.id, name: language.regionName })
                  }))
                }
              ]}
            />
          </div>

          <div className="section-add-tags">
            <Select
              ref={selectTagRef}
              mode="tags"
              tagRender={tagRender}
              style={{ width: '100%' }}
              suffixIcon={null}
              showSearch={false}
              open={false}
              value={tags}
              onChange={(e) => setTags(e)}
              onInputKeyDown={(e) => {
                if (e.key === ',') {
                  e.preventDefault();
                  selectTagRef.current.blur();
                  selectTagRef.current.focus();
                }
              }}
              className={`${tags.length === 0 ? 'error' : ''}`}
              placeholder={
                <span style={{ fontStyle: 'italic', fontWeight: 500 }}>
                  {t('Pages.Main.Dictionary.Modal.Tip')}
                </span>
              }
            />
            <div className="mt-1">{t('Pages.Main.Dictionary.Modal.CommaValue')}</div>

            <div className={`error-text ${enable ? 'invisible' : 'visible'}`}>
              {t('Pages.Main.Dictionary.Modal.CommaValueInput')}
            </div>
          </div>
        </div>
        <div className="footer mt-4 d-flex justify-content-center">
          <button
            className="submit-button d-flex align-items-center justify-content-center gap-1"
            onClick={saveDictionary}
            disabled={loading}
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                <FaDownload size={24} />
                <span>{t('Shared.Actions.Save')}</span>
              </>
            )}
          </button>
        </div>
      </div>
    </ModalBase>
  );
}
