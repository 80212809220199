import { TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from './axios-client';
import { TPicDefault, TRequestChangePwd, TTransactionHistory, TUser } from 'ts/types';
import { AxiosAuth0 } from './auth0-client';

export async function getTransactionHistory(): Promise<TResponse<TTransactionHistory[]>> {
  const res = await AxiosCustom.get('user/transactions');
  return res.data;
}

export async function getDefaultPic(): Promise<TResponse<TPicDefault[]>> {
  const res = await AxiosCustom.get('user/defaultProfilePic');
  return res.data;
}

export async function updateAvatar(avatarUrl: string): Promise<TResponse<TUser>> {
  const res = await AxiosCustom.put('user', { avatarUrl });
  return res.data;
}

export async function updateFullName(fullName: string): Promise<TResponse<TUser>> {
  const res = await AxiosCustom.put('user', { fullName, removeAvatar: false });
  return res.data;
}

export async function setNewPassword(pwd: string): Promise<TResponse<null | { message: string }>> {
  try {
    const res = await AxiosCustom.post('user/set-password', pwd, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return res.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return error.response.data;
  }
}

export async function requestChangePassword({
  client_id,
  connection,
  email
}: TRequestChangePwd): Promise<TResponse<string | { error: string }>> {
  try {
    const res = await AxiosAuth0.post('dbconnections/change_password', {
      client_id,
      connection,
      email
    });
    return res.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // return error.message;
    return error.response.data;
  }
}

export async function setNewEmail(
  email: string,
  password: string
): Promise<TResponse<{ message: string }>> {
  try {
    const res = await AxiosCustom.post('user/updateEmail', { email, password });
    return res.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return error.response.data;
  }
}

export async function signedAvatarUrl(fileName: string, mimeType: string) {
  try {
    const res = await AxiosCustom.post('user/signed-url-to-upload', { fileName, mimeType });
    return res.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return error.response.data;
  }
}

export async function setAccessTokenBox({
  accesstoken,
  refreshtoken
}: {
  accesstoken: string;
  refreshtoken: string;
}) {
  try {
    const res = await AxiosCustom.post(
      `box-auth/oauth2/complete?accesstoken=${accesstoken}&refreshtoken=${refreshtoken}`
    );
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function getAccessTokenBox() {
  try {
    const res = await AxiosCustom.get(`box-auth/oauth2/token`);
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function verifyCoupon(couponCode: string) {
  try {
    const res = await AxiosCustom.post('user/coupon/verify', {}, { params: { couponCode }});
    return res.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return error.response.data;
  }
}
