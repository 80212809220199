/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useState } from 'react';

interface TimeContextType {
  frameCodeTime: number;
  setFrameCodeTime: (e: number) => void;
  hours: number;
  setHours: (e: number) => void;
  minute: number;
  setMinute: (e: number) => void;
  second: number;
  setSecond: (e: number) => void;
  miliSecond: number;
  setMiliSecond: (e: number) => void;
}

const TimeContext = createContext<TimeContextType>({
  frameCodeTime: 0,
  setFrameCodeTime: (e: number) => e,
  hours: 0,
  setHours: (e: number) => e,
  minute: 0,
  setMinute: (e: number) => e,
  second: 0,
  setSecond: (e: number) => e,
  miliSecond: 0,
  setMiliSecond: (e: number) => e
});

export function TimeProvider({ children }: { children: React.ReactNode }) {
  const [frameCodeTime, setFrameCodeTime] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minute, setMinute] = useState<number>(0);
  const [second, setSecond] = useState<number>(0);
  const [miliSecond, setMiliSecond] = useState<number>(0);

  const value = {
    frameCodeTime,
    setFrameCodeTime: (value: number) => {
      setFrameCodeTime(value);
    },
    hours,
    setHours: (value: number) => {
      setHours(value);
    },
    minute,
    setMinute: (value: number) => {
      setMinute(value);
    },
    second,
    setSecond: (value: number) => {
      setSecond(value);
    },
    miliSecond,
    setMiliSecond: (value: number) => {
      setMiliSecond(value);
    }
  };

  return <TimeContext.Provider value={value}>{children}</TimeContext.Provider>;
}

export function useTimeContext() {
  return useContext(TimeContext);
}
