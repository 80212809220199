import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AvatarDropdown } from "components/Header";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { pushModal } from "redux/modal/modal.slice";
import { EModals, EPlanOption, ERoutes, ETypeSubcribeModal } from "ts/enums";
import Logo from "assets/img/logo-close-eye.png";
import { TimeDisplay } from "components/common";
import { convertCreditToHourMinute, pushSignUpTracking } from "utils/common";
import Eye from "assets/img/eye.png";
import { useAuth } from "context/AuthContext";
import { getListFolderAction } from "redux/folder/folder.action";
import { usePageLoading } from "context/PageLoadingContext";
import { setUserJustLoggedIn } from "redux/auth/auth.slice";

function Header() {
  const { isLogged } = useAuth();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.currentUser.data);
  const justLoggedIn = useAppSelector((state) => state.auth.justLoggedIn);
  const { destroyMessage } = usePageLoading();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  function openModalAddCredit() {
    dispatch(
      pushModal({
        name: EModals.ADD_CREDIT_MODAL,
        data: {},
      })
    );
  }
  function openSubscribeModal() {
    dispatch(
      pushModal({
        name: EModals.SUBSCRIBE_MODAL,
        data: {
          type: ETypeSubcribeModal.UPDRAGE,
        },
      })
    );
  }

  useEffect(() => {
    if (isLogged) {
      dispatch(getListFolderAction()).then(() => {
        destroyMessage();
      });
      if (searchParams.has("subscribePrefilledCoupon")) {
        dispatch(
          pushModal({
            name: EModals.SUBSCRIBE_MODAL,
            data: {
              type: ETypeSubcribeModal.UPDRAGE,
            },
          })
        );
      }
    }
  }, [isLogged]);

  useEffect(() => {
    if(!isLogged || !justLoggedIn || !currentUser) return;

    let token = localStorage.getItem('ss.accessToken');
    if(!token) return;

    try {
      let tokenObject: any = JSON.parse(atob(token.split('.')[1])); // Dynamic
      let tokenConnectionKey = null;

      for(let k in tokenObject)
        if(k.endsWith("/connection")) {
          tokenConnectionKey = k;
          break;
        }

      if(!tokenConnectionKey) return;
      let tokenConnection = tokenObject[tokenConnectionKey];

      let elapsedTimeSinceRegistration = new Date().getTime() - currentUser.regDate;
      let elapsedTimeInMinute = elapsedTimeSinceRegistration / 1000 / 60;

      if(elapsedTimeInMinute <= 3) // Under 3 minutes, this user just registered
        pushSignUpTracking(tokenConnection, currentUser.id);
  
      dispatch(setUserJustLoggedIn(false)); // This event fires only once
    } catch(err) {
      console.log(`Unable to push sign-up tracking`);
      console.error(err);
    }
  }, [isLogged, justLoggedIn, currentUser]);

  const credit = currentUser
    ? currentUser.team
      ? currentUser.team.credit + currentUser.team.subscriptionCredit
      : currentUser.credit + currentUser.subscriptionCredit
    : 0;

  const isRenderMenu =
    currentUser && currentUser.team
      ? currentUser.team.owner === currentUser.email
      : true;

  const creditToTime = convertCreditToHourMinute(credit) || {
    hour: 0,
    minutes: 0,
  };

  return (
    <header className="px-3 pb-1" style={{ paddingTop: 12 }}>
      <nav className="d-flex justify-content-between align-items-center">
        <div className="left-header">
          <Link to={ERoutes.MAIN} reloadDocument>
            <div className="logo">
              <img src={Logo} alt="logo" width={200} height={70} />
              <div className="eye" style={{ backgroundImage: `url(${Eye})` }} />
            </div>
          </Link>
        </div>
        {isLogged && (
          <div className="right-header d-flex gap-1 align-items-center">
            <div>
              <div
                style={{ whiteSpace: "nowrap", lineHeight: "15px" }}
                className={`text-end ${credit !== 0 ? "d-block" : "d-none"}`}
              >
                <span className="align-middle credit-text">{`${
                  currentUser?.team
                    ? t("Layouts.Header.Texts.TeamCredits")
                    : t("Layouts.Header.Texts.Credits")
                }`}</span>{" "}
                <TimeDisplay
                  className="credit-text"
                  hours={creditToTime.hour}
                  minutes={creditToTime.minutes}
                />
              </div>
              {isRenderMenu && (
                <div className="menu d-flex gap-1 align-items-center justify-content-end flex-nowrap">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      openModalAddCredit();
                    }}
                    className="custom-a-tag"
                  >
                    <span
                      className="d-block d-sm-none"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {t("Layouts.Header.Texts.PlusCredit")}
                    </span>
                    <span className="d-none d-sm-block">
                      {t("Shared.Actions.AddCredit")}
                    </span>
                  </button>
                  <div style={{ color: "var(--si-primary)" }}>|</div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      openSubscribeModal();
                    }}
                    className="custom-a-tag"
                  >
                    <span>
                      {currentUser?.plan.planEnum !== EPlanOption.PAY_AS_YOU_GO
                        ? t("Shared.Actions.UpgradePlan")
                        : t("Shared.Actions.SubscribePlan")}
                    </span>
                  </button>
                  <div
                    style={{ color: "var(--si-primary)" }}
                    className="d-none d-sm-block"
                  >
                    |
                  </div>
                  <Link
                    to={`${ERoutes.ACCOUNT_SETTING}#invite-link-free-credit`}
                    className="d-none d-sm-block custom-a-tag"
                    reloadDocument
                  >
                    <span>{t("Shared.Actions.GetFreeCredit")}</span>
                  </Link>
                </div>
              )}
            </div>
            <AvatarDropdown />
          </div>
        )}
      </nav>
    </header>
  );
}

export default Header;
