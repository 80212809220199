import HeaderMainLayout from 'components/HeaderMainLayout';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UploadFile from 'components/UploadMediaPage/UploadFile';
import { ButtonWithIcons } from 'components/common';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getProjectByIdAction } from 'redux/projects/projects.action';
import { TMedia } from 'ts/types';
import { editProject } from 'apis/projects.api';
import { FaRegComments, FaRegQuestionCircle } from 'react-icons/fa';
import { pushModal } from 'redux/modal/modal.slice';
import { EModals, ERoutes, EStatusMedia, EStatusProject, ETypeNoti } from 'ts/enums';
import MediaUpload from 'components/UploadMediaPage/MediaUpload';
import { Loader } from 'components/common/Loader';
import { usePageLoading } from 'context/PageLoadingContext';
import { changeActiveOpenPaymentModal, updateNameProject } from 'redux/projects/projects.slice';
import { deleteMedia } from 'apis/media.api';

function UploadMediaPage() {
  const params = useParams();
  const { projectId } = params;
  const dispatch = useAppDispatch();
  const currentProject = useAppSelector((state) => state.projects.currentProject);
  const [inputNameProject, setInputNameProject] = useState(currentProject.data?.name);
  const isEmptyMedias = currentProject.data?.medias && currentProject.data?.medias.length <= 0;
  const currentListMedias: TMedia[] =
    !isEmptyMedias && currentProject.data ? currentProject.data.medias : [];
  const [isTranscribed, setIsTranscribed] = useState<boolean>(false);
  const [isTranscribing, setIsTranscribing] = useState<boolean>(false);
  const { openMessage, destroyMessage } = usePageLoading();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      const listMediaNotReady = currentListMedias.filter(
        (media) => media.status !== EStatusMedia.READY
      );
      if (listMediaNotReady.length > 0) {
        listMediaNotReady.map((media) => {
          try {
            return deleteMedia({ mediaId: media.id })
          } catch (err) {
            console.error(err);
          }
        });
      }
      event.returnValue = true;
    };

    if (
      currentListMedias.some((media) => media.status !== EStatusMedia.READY) &&
      currentListMedias.length > 0
    ) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [currentListMedias]);

  useEffect(() => {
    if (!currentProject.data) return;
    if (
      currentProject.data.status === EStatusProject.OPEN ||
      currentProject.data.status === EStatusProject.UPLOADING ||
      currentProject.data.status === EStatusProject.FAILED
    ) {
      return;
    } else {
      navigate(`${ERoutes.TRANSCRIPT_DETAIL}/${projectId}`);
    }
  }, [currentProject.data]);

  useEffect(() => {
    if (projectId) {
      dispatch(getProjectByIdAction({ projectId }));
    }
  }, [projectId]);

  // useEffect(() => {
  //   if (projectId) {
  //     subscribe({
  //       topicChannelId: createChannelId('topic', ESocketTopic.ERRORS, projectId),
  //       callback: (res: any) => {}
  //     });
  //   }
  // }, [projectId]);

  useEffect(() => {
    const checkMediaReady = currentProject.data?.medias.every(
      (media) => media.status === EStatusMedia.READY
    );

    if (!currentProject.activeOpenPaymentModal) return;

    if (checkMediaReady && currentListMedias.length > 0) {
      dispatch(pushModal({ name: EModals.PAYMENT, data: { projectId: currentProject.data?.id } }));
      dispatch(changeActiveOpenPaymentModal(false));
    } else {
      dispatch(changeActiveOpenPaymentModal(true));
    }
  }, [currentProject.data?.medias]);

  useEffect(() => {
    if (currentProject.data?.name) {
      setInputNameProject(currentProject.data?.name);
    }
  }, [currentProject.data?.name]);

  useEffect(() => {
    if (!currentProject.data) return;

    if (
      currentProject.data.status === EStatusProject.OPEN &&
      currentProject.data.medias.length === 0
    ) {
      setIsTranscribed(false);
    } else {
      setIsTranscribed(true);
    }

    const listMediaNotReady = currentProject.data.medias.filter(
      (media) => media.status !== EStatusMedia.READY
    );
    if (listMediaNotReady.length > 0) {
      setIsTranscribing(true);
    } else {
      setIsTranscribing(false);
    }
  }, [currentProject]);

  async function handleSaveNewName() {
    const newName = document.getElementById('project-name')?.innerHTML.replace(/<[^>]+>/g, '');
    if (newName === currentProject.data?.name) return;

    if (!newName) {
      openMessage('warning', t('Pages.UploadMedia.Texts.PleaseAddProjectName'));
      return;
    }

    if (newName && currentProject.data && projectId) {
      destroyMessage();
      openMessage('loading', t('Pages.UploadMedia.Texts.EditingProjectName'));
      editProject({
        project: {
          ...currentProject.data,
          name: newName
        }
      }).then(() => {
        destroyMessage();
        openMessage('success', t('Pages.UploadMedia.Texts.EditSuccess'));
        dispatch(updateNameProject({ name: newName }));
      });
    }
  }

  function handleClickTranscribe() {
    if (isEmptyMedias) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: '',
          notiData: {
            type: ETypeNoti.WARNING,
            title: t('Pages.UploadMedia.Texts.UploadFileFirstThenTranscribe')
          }
        })
      );
    } else {
      openPaymentModal();
    }
  }

  function openQuickTipsModal() {
    dispatch(pushModal({ name: EModals.QUICK_TIPS_MODAL, data: '' }));
  }

  function openPaymentModal() {
    if (isTranscribed) {
      dispatch(pushModal({ name: EModals.PAYMENT, data: { projectId: currentProject.data?.id } }));
    }
  }

  return (
    <div className="upload-media-page">
      <HeaderMainLayout
        menuElement={
          <div className="d-flex align-items-center">
            {currentProject.isLoading ? (
              <Loader />
            ) : (
              <div
                id="project-name"
                dangerouslySetInnerHTML={{ __html: inputNameProject || '' }}
                className="project-name"
                placeholder={t('Pages.UploadMedia.Texts.PleaseAddProjectName')}
                onBlur={handleSaveNewName}
                onKeyDown={(e) => {
                  e.key === 'Enter' && document.getElementById('project-name')?.blur();
                  const modifier = e.ctrlKey || e.metaKey;
                  const isValidKeyCode = [
                    'Backspace',
                    'Shift',
                    'Control',
                    'ArrowLeft',
                    'ArrowUp',
                    'ArrowRight',
                    'ArrowDown',
                    'NumpadDecimal'
                  ].includes(e.key);
                  if (
                    e.currentTarget.textContent &&
                    e.currentTarget.textContent.length > 60 &&
                    !isValidKeyCode &&
                    !modifier
                  ) {
                    e.preventDefault();
                  }
                }}
                style={{ background: 'transparent' }}
                contentEditable={true}
                onFocus={() => {
                  const projectNameElm = document.getElementById('project-name');
                  if (projectNameElm) {
                    const range = document.createRange();
                    range.selectNodeContents(projectNameElm);
                    const selection = window.getSelection();
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                  }
                }}
              />
            )}

            <div className="quick-tips-icon px-1 ms-1 d-flex align-items-center">
              <FaRegQuestionCircle
                size={21}
                className="cursor-pointer"
                onClick={openQuickTipsModal}
              />
            </div>
          </div>
        }
        rightButton={
          isTranscribing ? (
            <span
              className="d-flex align-items-center gap-1"
              style={{ color: 'var(--si-primary)' }}
            >
              <Loader /> {t('Pages.UploadMedia.Texts.Preparing')}
            </span>
          ) : (
            <ButtonWithIcons
              icon={<FaRegComments size={24} />}
              text={t('Shared.Actions.Transcribe')}
              className={`btn-si-primary btn-transcribe --outline px-2 py-1 rounded-5 justify-content-center ${!isTranscribed ? '--disable' : ''
                } ${isTranscribed ? 'shake-animation' : ''}`}
              onClick={handleClickTranscribe}
              hiddenTablet
            />
          )
        }
      />
      <div className="container">
        {projectId && (
          <div className="pt-8">
            <UploadFile />
          </div>
        )}
        <div className="ctn-list-medias-upload row g-2 w-100">
          {currentListMedias.map((media) => (
            <MediaUpload key={media.id} media={media} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default UploadMediaPage;
