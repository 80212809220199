import React from 'react';
import { createInlineStyleButton } from '@draft-js-plugins/buttons';
import { EStyle } from 'ts/enums';

var styleDefault = createInlineStyleButton({
  style: EStyle.DICTIONARY,
  children: (
    <svg
    width="14"
    height="14"
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="italic"
    className="svg-inline--fa fa-italic fa-w-10"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320 512"
    >
      <path fill="currentColor"
      d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/>
    </svg>
  )
});

export default function (active: boolean) {
  // if(active) return styleActive
  return styleDefault;
}
