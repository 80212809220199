import { useTranslation } from 'react-i18next';
import { FaBookOpen, FaPlusCircle } from 'react-icons/fa';
import { LiaLayerGroupSolid } from 'react-icons/lia';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { pushModal } from 'redux/modal/modal.slice';
import { EModals, EPlanOption } from 'ts/enums';

export function CreateDictionary() {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const currentDictionary = useAppSelector((state) => state.dictionary.currentDictionary);
  const enable = currentUser.data?.plan.planEnum !== EPlanOption.PAY_AS_YOU_GO;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  function openAddDictionaryModal() {
    enable &&
      dispatch(
        pushModal({
          name: EModals.ADD_DICTIONARY_MODAL,
          data: null
        })
      );
  }
  return (
    <div
      className={`create-dictionary p-2 d-flex align-items-center justify-content-between ${
        enable ? '' : 'disable-create'
      }`}
      onClick={openAddDictionaryModal}
    >
      <div className="icon-and-text d-flex gap-1">
        <FaBookOpen size={28} color="var(--si-primary)" />
        <span className="d-flex align-items-center gap-1">
          {t('Shared.Actions.CreateDictionary')}{' '}
          <FaPlusCircle size={14} color="var(--si-primary)" />
        </span>
      </div>
      <div className="count">
        <span>{currentDictionary.data ? currentDictionary.data.length : 0}</span>
        <LiaLayerGroupSolid color="var(--si-dim-gray)" size={17} className="ms-1" />
      </div>
    </div>
  );
}
