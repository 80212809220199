import React, { createContext, useContext, useRef } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';

interface ILoaderContext {
  loader: {
    start: () => void;
    complete: () => void;
  };
}

const LoaderContext = createContext<ILoaderContext>(null!);

export function LoaderProvider({ children }: { children: React.ReactNode }) {
  const ref = useRef<LoadingBarRef>(null);

  function handleStartLoading() {
    if (ref.current) {
      ref.current.continuousStart();
    }
  }

  function handleCompleteLoading() {
    if (ref.current) {
      ref.current.complete();
    }
  }
  const value: ILoaderContext = {
    loader: {
      start: () => handleStartLoading(),
      complete: () => handleCompleteLoading()
    }
  };

  return (
    <LoaderContext.Provider value={value}>
      <LoadingBar color="#7e56a2" ref={ref} />
      {children}
    </LoaderContext.Provider>
  );
}

export function useLoaderContext() {
  return useContext(LoaderContext);
}
