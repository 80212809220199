export function isDecimalNumber(number: string) {
  return !isNaN(parseFloat(number)) && number.includes('.');
}

export function changePricePerHourToPricePerMinute({ pricePerHour }: { pricePerHour: number }) {
  const result = (pricePerHour * 100) / 60;
  if (isDecimalNumber(result.toString())) return result.toFixed(1);
  return result;
}

export function changePricePerHourToMinumunChangeInDolars({
  pricePerHour,
  minimunChargeInMinute
}: {
  pricePerHour: number;
  minimunChargeInMinute: number;
}) {
  const pricePerMinute = (pricePerHour * 100) / 60;
  const result = (pricePerMinute * minimunChargeInMinute) / 100;

  if (isDecimalNumber(result.toString())) return result.toFixed(1);
  return result;
}

export function changeNumberToCurrency(number: number) {
  const currencyString = number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return {
    full: currencyString,
    prevPriod: currencyString.split('.')[0],
    afterPriod: currencyString.split('.')[1]
  }
}