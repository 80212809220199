import { TLanguageDubbing } from 'ts/types';
import { TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from './axios-client';

export async function getLanguageDubbing({
  languageCode
}: {
  languageCode: string;
}): Promise<TResponse<Array<TLanguageDubbing>>> {
  const res = await AxiosCustom.get(`dubbing/?languageCode=${languageCode}`);
  return res.data;
}
