import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import { ButtonWithIcons } from 'components/common';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { TMedia } from 'ts/types';
import { useParams } from 'react-router-dom';
import { changeMediaForProjectAction } from 'redux/projects/projects.action';
import { changeMediaForAll } from 'apis/media.api';
import { InputTime } from 'components/common/InputTime';
import { useTimeContext } from 'context/TimeContext';
import { formatTimeTwoNumber } from 'utils/time';
import { validateFrameRate } from 'utils/second-to-time';
import { getProjectById } from 'apis/projects.api';
import { reloadCurrentProject } from 'redux/projects/projects.slice';
import { setMedia } from 'redux/media/media.slice';
import { useLoaderContext } from 'context/LoaderContext';
import { usePageLoading } from 'context/PageLoadingContext';

type OptionFrameRate = {
  id: number;
  frameValue: number;
  frameDisplay: string;
  isDropFrame: boolean;
};

const ListOptionFrameRate: OptionFrameRate[] = [
  { id: 0, frameDisplay: '23.976', frameValue: 23.976, isDropFrame: false },
  { id: 1, frameDisplay: '24', frameValue: 24, isDropFrame: false },
  { id: 2, frameDisplay: '25', frameValue: 25, isDropFrame: false },
  { id: 3, frameDisplay: '29.97DF', frameValue: 29.97, isDropFrame: true },
  { id: 4, frameDisplay: '29.97NDF', frameValue: 29.97, isDropFrame: false },
  { id: 5, frameDisplay: '30', frameValue: 30, isDropFrame: false },
  { id: 6, frameDisplay: '50', frameValue: 50, isDropFrame: false },
  { id: 7, frameDisplay: '59.94DF', frameValue: 59.94, isDropFrame: true },
  { id: 8, frameDisplay: '59.94NDF', frameValue: 59.94, isDropFrame: false },
  { id: 9, frameDisplay: '60', frameValue: 60, isDropFrame: false }
];

type Props = {
  children?: ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type TApplyFrameForAll = {
  fps: number;
  isApplyAll: boolean;
};

type TApplySizeForAll = {
  isApplyAll: boolean;
  width: number;
  height: number;
};

export function TranscriptConfigPopOver({ children, isOpen, setIsOpen }: Props) {
  const currentMedia = useAppSelector((state) => state.media.currentMedia);
  const currentProject = useAppSelector((state) => state.projects.currentProject);
  const [mediaConfig, setMediaConfig] = useState<TMedia | null>(null);
  const [frameSelected, setFrameSelected] = useState<OptionFrameRate>();
  const [timeCode, setTimeCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const params = useParams();
  const dispatch = useAppDispatch();
  const [applyFrameForAll, setApplyFrameForAll] = useState<TApplyFrameForAll>({
    fps: currentMedia?.fps || 30,
    isApplyAll: false
  });
  const [applySizeForAll, setApplySizeForAll] = useState<TApplySizeForAll>({
    isApplyAll: false,
    width: currentMedia?.width || 1440,
    height: currentMedia?.height || 1080
  });
  const { miliSecond, frameCodeTime, hours, minute, second, setFrameCodeTime } = useTimeContext();
  const { loader } = useLoaderContext();
  const { destroyMessage, openMessage } = usePageLoading();

  function handleCheckChangeFps() {
    if (mediaConfig && mediaConfig.fps !== currentMedia?.fps) {
      return true;
    }
    return false;
  }

  function handleCheckChangeSize() {
    if (
      (mediaConfig && mediaConfig.width !== currentMedia?.width) ||
      mediaConfig?.height !== currentMedia?.height
    ) {
      return true;
    }
    return false;
  }

  function handleCheckChangeTime() {
    if (mediaConfig && mediaConfig.userDefinedTimeCode !== currentMedia?.userDefinedTimeCode) {
      return true;
    }
    return false;
  }

  function handleCheckChangeTimeCodeFrame() {
    if (timeCode !== currentMedia?.timeCode) {
      return true;
    }
    return false;
  }

  function handleChangeMedia() {
    if (mediaConfig && params.projectId) {
      const validTimeCode = validateFrameRate({
        fps: frameSelected?.frameValue || 30,
        dropFrame: frameSelected?.isDropFrame || false,
        minuteInput: minute,
        frameInput: frameCodeTime,
        secondInput: second
      });
      if (handleCheckChangeFps() && applyFrameForAll.isApplyAll && currentProject.data) {
        changeMediaForAll({ projectId: currentProject.data.id, fps: applyFrameForAll.fps });
      }

      if (handleCheckChangeSize() && applySizeForAll.isApplyAll && currentProject.data) {
        changeMediaForAll({
          projectId: currentProject.data.id,
          width: applySizeForAll.width,
          height: applySizeForAll.height
        });
      }

      if (
        (handleCheckChangeFps() ||
          handleCheckChangeSize() ||
          handleCheckChangeTime() ||
          handleCheckChangeTimeCodeFrame()) &&
        validTimeCode
      ) {
        setIsOpen(false);
        const newMediaConfig = {
          ...mediaConfig,
          timeCode: timeCode,
          userDefinedTimeCode: miliSecond
        };
        loader.start();
        dispatch(
          changeMediaForProjectAction({
            media: newMediaConfig,
            projectId: params.projectId
          })
        )
          .then(async () => {
            if (!currentProject.data) return;
            loader.complete();
            dispatch(setMedia({ media: newMediaConfig }));
            const project = await getProjectById({ projectId: currentProject.data.id });
            project && dispatch(reloadCurrentProject({ project }));
          })
          .catch((e) => {
            loader.complete();
            destroyMessage();
            openMessage('error', e);
          });
      }
    }
  }

  useEffect(() => {
    setMediaConfig(currentMedia);
    if (currentMedia) {
      const foundFpsList = ListOptionFrameRate.filter(
        (frame) => frame.frameValue === currentMedia.fps
      );

      if (foundFpsList.length > 1) {
        if (currentMedia.timeCode && currentMedia.timeCode?.includes(';')) {
          const finalValue = foundFpsList.find((frame) => frame.isDropFrame);
          setFrameSelected(finalValue);
        } else {
          const finalValue = foundFpsList.find((frame) => !frame.isDropFrame);
          setFrameSelected(finalValue);
        }
      } else {
        setFrameSelected(foundFpsList[0]);
      }
      currentMedia.timeCode && setTimeCode(currentMedia.timeCode);
    }
  }, [currentMedia]);

  useEffect(() => {
    validateTimeCode();
    if (frameSelected?.isDropFrame) {
      const newTimeCode = `${formatTimeTwoNumber(hours)}:${formatTimeTwoNumber(
        minute
      )}:${formatTimeTwoNumber(second)};${formatTimeTwoNumber(frameCodeTime)}`;
      setTimeCode(newTimeCode);
    } else {
      const newTimeCode = `${formatTimeTwoNumber(hours)}:${formatTimeTwoNumber(
        minute
      )}:${formatTimeTwoNumber(second)}:${formatTimeTwoNumber(frameCodeTime)}`;
      setTimeCode(newTimeCode);
    }
  }, [frameSelected, hours, minute, second, frameCodeTime]);

  useEffect(() => {
    mediaConfig &&
      setMediaConfig({
        ...mediaConfig,
        userDefinedTimeCode: miliSecond
      });
  }, [miliSecond]);

  useEffect(() => {
    mediaConfig &&
      setMediaConfig({
        ...mediaConfig,
        userDefinedTimeCodeFrames: frameCodeTime
      });
  }, [frameCodeTime]);

  const validateTimeCode = () => {
    const valid = validateFrameRate({
      fps: frameSelected?.frameValue || 30,
      dropFrame: frameSelected?.isDropFrame || false,
      minuteInput: Number(formatTimeTwoNumber(minute)),
      frameInput: Number(formatTimeTwoNumber(frameCodeTime)),
      secondInput: Number(formatTimeTwoNumber(second))
    });

    if (frameSelected && Number(formatTimeTwoNumber(frameCodeTime)) > frameSelected.frameValue) {
      setFrameCodeTime(Math.floor(frameSelected.frameValue));
    }
    if (valid) {
      setError('');
    } else {
      setError('This is an illegal timecode. Please fix it.');
    }
  };

  return (
    <Popover
      placement="bottomRight"
      content={
        <Content
          frameSelected={frameSelected}
          setFrameSelected={setFrameSelected}
          mediaConfig={mediaConfig}
          setMediaConfig={setMediaConfig}
          applyFrameForAll={applyFrameForAll}
          setApplyFrameForAll={setApplyFrameForAll}
          applySizeForAll={applySizeForAll}
          setApplySizeForAll={setApplySizeForAll}
          validateTimeCode={validateTimeCode}
          handleChangeMedia={handleChangeMedia}
          error={error}
          setIsOpen={setIsOpen}
        />
      }
      trigger="click"
      style={{ maxWidth: 290 }}
      open={isOpen}
      arrow={false}
    >
      {children && children}
    </Popover>
  );
}

type PropsContent = {
  frameSelected: OptionFrameRate | undefined;
  setFrameSelected: React.Dispatch<React.SetStateAction<OptionFrameRate | undefined>>;
  mediaConfig: TMedia | null;
  setMediaConfig: React.Dispatch<React.SetStateAction<TMedia | null>>;
  applyFrameForAll: TApplyFrameForAll;
  setApplyFrameForAll: React.Dispatch<React.SetStateAction<TApplyFrameForAll>>;
  applySizeForAll: TApplySizeForAll;
  setApplySizeForAll: React.Dispatch<React.SetStateAction<TApplySizeForAll>>;
  validateTimeCode: () => void;
  handleChangeMedia: () => void;
  error: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Content = ({
  frameSelected,
  setFrameSelected,
  mediaConfig,
  setMediaConfig,
  applyFrameForAll,
  setApplyFrameForAll,
  applySizeForAll,
  setApplySizeForAll,
  validateTimeCode,
  handleChangeMedia,
  error,
  setIsOpen
}: PropsContent) => {
  const { t } = useTranslation();

  if (!mediaConfig) return null;

  return (
    <div className="transcript-config-pop-over-content ">
      <div className="frame d-flex flex-column">
        <label>{t('Shared.Fields.Framerate')}</label>
        <select
          style={{ marginTop: 4, color: 'var(--si-black-grey)' }}
          name="frame-rate"
          value={frameSelected?.frameDisplay}
          onChange={(e) => {
            const foundOption = ListOptionFrameRate.find(
              (frame) => frame.frameDisplay === e.currentTarget.value
            );
            if (foundOption && mediaConfig) {
              setMediaConfig({ ...mediaConfig, fps: foundOption.frameValue });
              setApplyFrameForAll({
                ...applyFrameForAll,
                fps: foundOption.frameValue
              });
              setFrameSelected(foundOption);
            }
          }}
        >
          {ListOptionFrameRate.map((frame) => (
            <option key={frame.id} value={frame.frameDisplay}>
              {frame.frameDisplay}
            </option>
          ))}
        </select>
        <label className="d-flex align-items-center mt-1">
          <input
            type="checkbox"
            name="apply-frame-all"
            onChange={() => {
              setApplyFrameForAll({
                ...applyFrameForAll,
                isApplyAll: !applyFrameForAll.isApplyAll
              });
            }}
          />
          <span className="ms-1">{t('Pages.TranscriptDetail.Texts.ApplyFramerateToAll')}</span>
        </label>
      </div>
      <div className="video-dimensions d-none d-sm-flex flex-column ">
        <label>{t('Shared.Fields.VideoDimensions')}</label>
        <div className="form-set-size-video d-flex justify-content-between mt-1">
          <div className="width d-flex flex-column">
            <label>{t('Shared.Fields.Width')}</label>
            <input
              type="number"
              name="width"
              maxLength={4}
              className="mt-1"
              value={mediaConfig.width}
              onChange={(e) => {
                mediaConfig &&
                  setMediaConfig({ ...mediaConfig, width: parseFloat(e.currentTarget.value) });
                setApplySizeForAll({
                  ...applySizeForAll,
                  width: parseFloat(e.currentTarget.value)
                });
              }}
            />
          </div>
          <div className="height d-flex flex-column">
            <label>{t('Shared.Fields.Height')}</label>
            <input
              type="number"
              name="height"
              maxLength={4}
              className="mt-1"
              value={mediaConfig.height}
              onChange={(e) => {
                mediaConfig &&
                  setMediaConfig({ ...mediaConfig, height: parseFloat(e.currentTarget.value) });
                setApplySizeForAll({
                  ...applySizeForAll,
                  height: parseFloat(e.currentTarget.value)
                });
              }}
            />
          </div>
        </div>
        <label className="d-flex align-items-center mt-1">
          <input
            type="checkbox"
            onChange={() => {
              setApplySizeForAll({
                ...applySizeForAll,
                isApplyAll: !applySizeForAll.isApplyAll
              });
            }}
          />
          <span className="ms-1">
            {t('Pages.TranscriptDetail.Texts.ApplyVideoDimensionsToAll')}
          </span>
        </label>
      </div>
      <div className="input-time">
        <InputTime
          userDefinedTimeCode={mediaConfig.userDefinedTimeCode}
          frameVideo={mediaConfig.userDefinedTimeCodeFrames}
          label={`${t('Pages.TranscriptDetail.Texts.StartTimecodeHint')} ${
            frameSelected?.isDropFrame ? '(HH:MM:SS;FF)' : '(HH:MM:SS:FF)'
          }`}
          timeCode={mediaConfig.timeCode}
          isDropFrame={frameSelected?.isDropFrame || false}
          validateTimeCode={validateTimeCode}
        />
      </div>
      {error && <div className="text-danger">{error}</div>}
      <div className="gr-btn-action mt-2 d-flex justify-content-end">
        <ButtonWithIcons
          icon={<FaCheckCircle size={24} color="var(--si-primary)" />}
          onClick={() => {
            handleChangeMedia();
          }}
        />
        <ButtonWithIcons
          className="ms-1"
          icon={<FaTimesCircle size={24} color="var(--si-granite-gray)" />}
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
    </div>
  );
};
