import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TFolder } from 'ts/types';
import {
  createFolderAction,
  deleteFolderAction,
  editFolderAction,
  getListFolderAction
} from './folder.action';
import { EFolderIdDefault } from 'ts/enums';
import moment from 'moment';
import { usePageLoading } from 'context/PageLoadingContext';
import { combineFolders, separateFolders } from 'utils/common';

type FoldersState = {
  listFolders: TFolder[];
  listFolderDefault: TFolder[];
  currentFolderSelected: string;
  isLoading: boolean;
  error: any;
  isFetched: boolean;
};

const initialFoldersState: FoldersState = {
  listFolders: [],
  currentFolderSelected: EFolderIdDefault.PROJECT_ALL,
  isLoading: false,
  error: null,
  isFetched: false,
  listFolderDefault: [
    {
      id: EFolderIdDefault.MY_PROJECT,
      name: 'My projects',
      totalProjectCount: 0,
      owner: null,
      empty: false,
      creationDate: moment().unix(),
      isEdited: false,
      encodedParentId: null,
      children: []
    },
    {
      id: EFolderIdDefault.SHARED_WITH_ME,
      name: 'Shared With Me',
      totalProjectCount: 0,
      owner: null,
      empty: false,
      creationDate: moment().unix(),
      isEdited: false,
      encodedParentId: null,
      children: []
    },
    {
      id: EFolderIdDefault.PROJECT_ALL,
      name: 'Projects',
      totalProjectCount: 0,
      owner: null,
      empty: false,
      creationDate: moment().unix(),
      isEdited: false,
      encodedParentId: null,
      children: []
    }
  ]
};

const foldersSlice = createSlice({
  name: 'si-folder',
  initialState: initialFoldersState,
  reducers: {
    updateFolderSelected: (state, { payload }: PayloadAction<{ folderId: string }>) => {
      state.currentFolderSelected = payload.folderId;
    },
    updateEditedFolder: (
      state,
      { payload }: PayloadAction<{ folder: TFolder; isEdit: boolean }>
    ) => {
      const tmpArray = separateFolders([...state.listFolders]);
      const findItem = tmpArray.find((folder) => folder.id === payload.folder.id);
      if (findItem) findItem.isEdited = payload.isEdit;
      state.listFolders = combineFolders(tmpArray);
    },
    changeCountVideoInFolder: (
      state,
      { payload }: PayloadAction<{ folderIDIncre: string; folderIdDecre?: string }>
    ) => {
      const tmpArray = [...state.listFolderDefault, ...state.listFolders];
      const itemIncrement = tmpArray.find((folder) => folder.id === payload.folderIDIncre);

      if (itemIncrement) {
        itemIncrement.totalProjectCount += 1;
      }

      if (payload.folderIdDecre) {
        const itemDecrement = tmpArray.find((folder) => folder.id === payload.folderIdDecre);
        if (itemDecrement) {
          itemDecrement.totalProjectCount -= 1;
        }
      }

      state.listFolderDefault = tmpArray.slice(0, 3);
      state.listFolders = tmpArray.slice(3);
    },
    changeCountFolder: (state, { payload }: PayloadAction<{ folderID: string; count: number }>) => {
      if (payload.folderID) {
        const tmpSeparateFolders = separateFolders(state.listFolders);
        const tmpArray = [...state.listFolderDefault, ...tmpSeparateFolders];
        const item = tmpArray.find((folder) => folder.id === payload.folderID);
        if (item) {
          item.totalProjectCount += payload.count;
        }
        const tmpCombineFolders = combineFolders(tmpArray);
        state.listFolderDefault = tmpCombineFolders.slice(0, 3);
        state.listFolders = tmpCombineFolders.slice(3);
      }
    }
  },
  extraReducers: ({ addCase }) => {
    //Get list folder
    addCase(getListFolderAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.isFetched = false;
      state.listFolders = [...state.listFolders];
    });

    addCase(getListFolderAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      if (payload) {
        const tmpPayload = [...payload];

        const newArray = tmpPayload.map((f) => {
          f.isEdited = false;
          f.children = [];
          return f;
        });

        state.listFolders = combineFolders(newArray);
      }
      state.isFetched = true;
    });

    addCase(getListFolderAction.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.isFetched = false;
    });

    //Create folder
    addCase(createFolderAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    addCase(createFolderAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      if (payload) {
        const tmpFolder = separateFolders([...state.listFolders]);
        const newFolder = { ...payload, isEdited: true };

        state.listFolders = combineFolders([newFolder, ...tmpFolder]);
      }
    });

    addCase(createFolderAction.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.isFetched = false;
    });

    //Delete folder
    addCase(deleteFolderAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    addCase(deleteFolderAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;

      const tmpFolder = separateFolders([...state.listFolders]);
      const filterFolders = tmpFolder.filter((f) => f.id !== payload.folderId);
      state.listFolders = combineFolders(filterFolders);

      if (
        payload.folderId === state.currentFolderSelected ||
        !filterFolders.map((f) => f.id).includes(payload.folderId)
      ) {
        state.currentFolderSelected = EFolderIdDefault.PROJECT_ALL;
      }
    });

    addCase(deleteFolderAction.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.isFetched = false;
    });

    //Edit folder
    addCase(editFolderAction.pending, (state) => {
      state.error = null;
    });

    addCase(editFolderAction.fulfilled, (state, { payload }) => {
      state.error = null;
      if (payload) {
        const tmpArray = separateFolders([...state.listFolders]);
        const findItem = tmpArray.find((folder) => folder.id === payload.id);
        if (findItem) {
          findItem.name = payload.name;
          findItem.isEdited = false;
        }

        state.listFolders = combineFolders(tmpArray);
      }
    });

    addCase(editFolderAction.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.isFetched = false;
    });
  }
});
export const {
  updateFolderSelected,
  changeCountVideoInFolder,
  updateEditedFolder,
  changeCountFolder
} = foldersSlice.actions;

export default foldersSlice.reducer;

// function arrangeData(data) {
//   const rootNodes = [];

//   for (const nodeData of data) {
//     const node = new Node(nodeData.id, nodeData.parentId);

//     if (node.parentId === null) {
//       rootNodes.push(node);
//       continue;
//     }

//     const parentNode = findNodeById(rootNodes, node.parentId);
//     if (parentNode) {
//       parentNode.children.push(node);
//     }
//   }

//   return rootNodes;
// }

// function findNodeById(nodes, id) {
//   for (const node of nodes) {
//     if (node.id === id) {
//       return node;
//     }

//     const childNode = findNodeById(node.children, id);
//     if (childNode) {
//       return childNode;
//     }
//   }

//   return null;
// }
