import { TMetric } from 'ts/types';
import { AxiosCustom } from '../axios-client';
export async function getMetrics({
  startDate,
  endDate
}: {
  startDate: string;
  endDate: string;
}): Promise<TMetric[]> {
  const res = await AxiosCustom.get(`metrics?endUtcTime=${endDate}&starUtcTime=${startDate}`);
  return res.data;
}
