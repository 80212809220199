import { TProject, TProjectTags, TTag } from 'ts/types';

type TGetColorLabel = {
  listTags: TTag[] | null;
  listProjectTags: TProjectTags[] | null;
  project: TProject;
};

export function getColorLabel({ listProjectTags, listTags, project }: TGetColorLabel): TTag | null {
  if (!listProjectTags || !listTags) {
    return null;
  }

  const projectTagIds = listProjectTags
    .filter((projectTag) => projectTag.projectId === project.projectId)
    .map((projectTag) => projectTag.tagId);

  const tags = listTags.filter((tag) => projectTagIds.includes(tag.id));

  if (tags.length === 0) {
    return null;
  }

  return tags[0];
}
