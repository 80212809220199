import OtherIcon from 'assets/icons/other-logo.svg';
import { PropsExport } from '.';
import { EExportName, EExportSubData } from 'ts/enums';
import { useTranslation } from 'react-i18next';
const subDataRender = [
  {
    key: EExportSubData.AVID_PRO_TOOLS_AFF,
    title: 'Avid Pro Tools AAF',
    option: {},
    allowMultipleMedia: true
  },
  {
    key: EExportSubData.DAVINCI_RESOLVE,
    title: 'Davinci Resolve',
    option: { onlyBookmarks: false },
    allowMultipleMedia: true
  },
  {
    key: EExportSubData.ADOBE_AUDITION,
    title: 'Adobe Audition',
    option: { onlyBookmarks: false },
    allowMultipleMedia: true
  },
  {
    key: EExportSubData.EXCEL,
    title: 'Microsoft Excel',
    option: {},
    allowMultipleMedia: true
  },
  {
    key: EExportSubData.JSON,
    title: 'Json',
    option: {},
    allowMultipleMedia: true
  },
  {
    key: EExportSubData.XML,
    title: 'Xml',
    option: {},
    allowMultipleMedia: true
  },
  {
    key: EExportSubData.FINAL_CUT_PRO7,
    title: 'Final Cut Pro 7',
    option: { onlyBookmarks: false },
    allowMultipleMedia: true
  },
  {
    key: EExportSubData.TEXT,
    title: 'Text',
    option: { withTimeCode: true },
    allowMultipleMedia: true
  },
  {
    key: EExportSubData.EDIUS,
    title: 'Edius',
    option: {},
    allowMultipleMedia: true
  },
  /* 
  { // Removed by Shamir's request (2023-01-04)
    key: EExportSubData.LUMBER_JACK_READY,
    title: 'Lumberjack Ready',
    option: {},
    allowMultipleMedia: true
  },*/
  {
    key: EExportSubData.MAXQDA,
    title: 'MAXQDA',
    option: {},
    allowMultipleMedia: true
  }
];
export function OtherExport({ exportOption, setExportOption }: PropsExport) {
  const { t } = useTranslation();

  return (
    <div className="other-export export-item">
      <div className="d-flex flex-column justify-content-center align-items-center cursor-pointer"
        onClick={() => {
          setExportOption(draft => {
            draft.data.option = subDataRender[0].option;
            draft.data.subData = subDataRender[0].key;
            draft.key = EExportName.OTHER;
            draft.allowMultipleMedia = true;
          })
        }}>
        <div
          className={`icon ${exportOption.key === EExportName.OTHER ? 'opacity-100' : 'opacity-50'
            }`}
        >
          <img src={OtherIcon} alt="" />
        </div>
        <div className={`text mt-1 ${exportOption.key === EExportName.OTHER ? '--selected' : ''}`}>
          <span>{ t('Shared.Texts.Other') }</span>
        </div>
      </div>
      <div className={`text mt-1 ${exportOption.key === EExportName.OTHER ? '--selected' : ''}`}>
        {exportOption.key === EExportName.OTHER && (
          <div className="choose-option mt-2">
            {subDataRender.map((s) => (
              <label className="d-flex align-items-center mb-1 gap-1 cursor-pointer" key={s.key}>
                <input
                  type="radio"
                  name="select"
                  defaultChecked={s.key === EExportSubData.AVID_PRO_TOOLS_AFF}
                  onClick={() => {
                    setExportOption(draft => {
                      draft.key = EExportName.OTHER;
                      draft.data.subData = s.key;
                      draft.data.option = s.option;
                      draft.allowMultipleMedia = s.allowMultipleMedia;
                    })
                  }}
                />
                <span>{s.title}</span>
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
