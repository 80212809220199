import React from 'react';
import { EAccountSettingId, EModals, ETypeNoti } from 'ts/enums';
import { BasicSection } from './BasicSection';
import { deAuthorize, getAuthorize } from 'apis/auth.api';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getUserInfoAction } from 'redux/auth/auth.action';
import { pushModal } from 'redux/modal/modal.slice';
import { useTranslation } from 'react-i18next';
import { useLoaderContext } from 'context/LoaderContext';

export function ConnectedAppsSection() {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.auth.currentUser.data);
  const { t } = useTranslation();
  const { loader } = useLoaderContext();

  async function handleAuthorize() {
    loader.start();
    const res = await getAuthorize();
    if (res && res.boxURI) {
      window.location.replace(res.boxURI);
    }
    loader.complete();
  }

  return (
    <BasicSection
      className="connected-apps-section mb-9"
      header={t('Pages.AccountSetting.Connections.SubTitle')}
      id={EAccountSettingId.CONNECTED_APPS}
    >
      <div className="label mb-2 text-uppercase">
        {t('Pages.AccountSetting.Connections.ImportFilesFrom')}:
      </div>
      <span className="text">
        Box:{' '}
        {userData && !!userData.boxAuthToken ? (
          <a
            className="custom-a-tag cursor-pointer"
            onClick={() => {
              loader.start();
              deAuthorize()
                .then(() => {
                  dispatch(getUserInfoAction());
                })
                .catch(() => {
                  dispatch(
                    pushModal({
                      name: EModals.NOTI_MODAL,
                      data: null,
                      notiData: {
                        type: ETypeNoti.ERROR,
                        title: 'Error'
                      }
                    })
                  );
                })
                .finally(() => {
                  loader.complete();
                  dispatch(
                    pushModal({
                      name: EModals.NOTI_MODAL,
                      data: null,
                      notiData: {
                        type: ETypeNoti.SUCCESS,
                        title: t('Pages.AccountSetting.Connections.BoxAccountDisconnected')
                      }
                    })
                  );
                });
            }}
          >
            {t('Shared.Actions.Deauthorize')}
          </a>
        ) : (
          <a className="custom-a-tag cursor-pointer" onClick={handleAuthorize}>
            {t('Shared.Actions.Authorize')}
          </a>
        )}
      </span>
    </BasicSection>
  );
}
