import TimeCodedWord from 'components/PS4/TimeCodedWord';
import { CompositeDecorator, DraftModel, EditorState } from 'draft-js';
import { EStyle } from 'ts/enums';
import { TFolder } from 'ts/types';
import { StripeTransactionDetail, SubscribeTransactionDetail } from 'ts/types/interface';

export function isValidHttpString(str: string) {
  const urlPattern = new RegExp(
    '^(http(s)?:\\/\\/)[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/+,;=.]+'
  );
  return !!urlPattern.test(str);
}

export function convertFormatTimeFromSecond(second: number, fps?: number) {
  let pad = function (second: number) {
    if (Number.isNaN(second)) return '00';
    return second < 10 ? '0' + second : second;
  };
  fps = typeof fps !== 'undefined' ? fps : 30;

  return `${[
    pad(Math.floor(second / 3600)),
    pad(Math.floor((second % 3600) / 60)),
    pad(Math.floor(second % 60)),
    pad(Math.floor((second * fps) % fps))
  ].join(':')}`;
}

// export const findEntitiesFunction = (type: string) =>
//   function (
//     contentBlock: DraftModel.ImmutableData.ContentBlock,
//     // eslint-disable-next-line no-unused-vars
//     callback: (start: number, end: number) => void,
//     contentState: DraftModel.ImmutableData.ContentState
//   ) {
//     contentBlock.findEntityRanges((character) => {
//       const entityKey = character.getEntity();
//       return entityKey !== null && contentState.getEntity(entityKey).getType() === type;
//     }, callback);
//   };

function isInViewport(elem: any, paddingTop: any) {
  if (elem) {
    const bounding = elem.getBoundingClientRect().toJSON();

    // The issue is when typing accent character on MacOs all value is 0
    // Because it just fire when users are typing, so we assume it ALWAYS into viewport
    const allValueIsZero = Object.keys(bounding).every((key) => bounding[key] === 0);
    if (allValueIsZero) return true;

    return (
      bounding.bottom >= paddingTop &&
      // bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + paddingTop
      // bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}

function getPositionRelativeToViewport(elem: any, paddingTop: any) {
  if (!elem) return null;
  const bounding = elem.getBoundingClientRect().toJSON();
  return bounding.bottom - paddingTop;
}

export const findEntitiesFunction = (type: any) => {
  const contentNode = document.querySelector('[data-contents="true"]');
  return function (contentBlock: any, callback: any, contentState: any) {
    if (!contentNode || !contentNode.children || contentNode.children.length === 0) return;
    const paddingTop = 150;
    // console.log(contentBlock.getText().length);
    // console.log(contentBlock.getKey(), contentBlock.getText());

    //const querySelector = `[data-block="true"][data-offset-key="${contentBlock.getKey()}-0-0"]`;
    //const blockDomElement = contentNode.querySelector(querySelector);
    let blockDomElement = null;
    const key = contentBlock.getKey();
    // find a node within the viewport.
    let seekIndex = 0;
    let L = 0;
    let R = contentNode.children.length - 1;
    if (!isInViewport(contentNode.children[seekIndex], paddingTop)) {
      while (L < R) {
        seekIndex = Math.floor((L + R) / 2);
        if (!contentNode.children[seekIndex]) {
          return;
        }
        if (isInViewport(contentNode.children[seekIndex], paddingTop)) {
          break;
        }
        const offset = getPositionRelativeToViewport(contentNode.children[seekIndex], paddingTop);
        if (offset && offset > 0) {
          R = seekIndex - 1;
        } else {
          L = seekIndex + 1;
        }
      }
    }

    // then search backwards until we leave viewport
    // then search forwards until we leave viewport
    let searchDirection = seekIndex === 0 ? 1 : -1;
    let preciseSeekIndex = seekIndex;
    while (!blockDomElement) {
      if (!contentNode.children[preciseSeekIndex]) return;
      const node = contentNode.children[preciseSeekIndex];
      if (isInViewport(node, paddingTop)) {
        if (node.getAttribute('data-offset-key') == `${key}-0-0`) {
          blockDomElement = node;
          break;
        }
        if (preciseSeekIndex === 0) {
          searchDirection = 1;
        }
        preciseSeekIndex += searchDirection;
      } else if (searchDirection === -1) {
        searchDirection = 1;
        preciseSeekIndex = seekIndex;
      } else {
        //not found forwards or backwards. Return
        return;
      }
    }

    contentBlock.findEntityRanges((character: any) => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === type;
    }, callback);
  };
};

export const convertHoursToSecond = (hours: number) => hours * 3600;
export const convertPriceToMinute = (price: number) => {
  const centPrice = price * 100;
  const pricePerMinute = centPrice / 60;
  return pricePerMinute.toFixed(1);
};

export const convertCreditToHourMinute = (credit: number) => {
  const hour = parseInt((credit / 60).toString());
  const minutes = credit % 60;

  return { hour, minutes };
};

export const convertDurationToHourMinute = (duration: number) => {
  const hour = parseInt((duration / 3600).toString());
  const minuteRound = Math.round((duration % 3600) / 60);

  const minutes = parseInt(minuteRound.toString());
  return { hour, minutes };
};

export function createChannelId(prefix: string, topic: string, id: string) {
  const args = Array.prototype.slice.call(arguments);
  return '/' + args.join('/');
}

export function convertByteToMegaByte({ bytes }: { bytes: number }) {
  return bytes / (1024 * 1024);
}

export function formatTime(value: number): string {
  const secondValue = Math.floor(value % 60)
    .toString()
    .padStart(2, '0');
  const minuteValue = (Math.floor(value / 60) % 60).toString().padStart(2, '0');
  const hour = Math.floor(value / 3600)
    .toString()
    .padStart(2, '0');
  return `${hour !== '00' ? `${hour}:` : ''}${minuteValue}:${secondValue}`;
}

export function forceCompositeDecorator(editorState: DraftModel.ImmutableData.EditorState) {
  const compositeDecorator = new CompositeDecorator([
    {
      strategy: findEntitiesFunction('TIME_CODED_WORD'),
      component: TimeCodedWord
    }
  ]);

  const newEditorState = EditorState.set(editorState, {
    decorator: compositeDecorator
  });
  return newEditorState;
}

export const roundToTwo = (num: number): number => +(Math.round(Number(num + 'e+2')) + 'e-2');

export function goToNextTranscription({
  onlyWithBookmark,
  idBlockPlaying,
  editorState
}: {
  onlyWithBookmark: boolean;
  idBlockPlaying: string | null;
  editorState: EditorState;
}) {
  const blocks = editorState.getCurrentContent().getBlocksAsArray();

  let nextBlock: any;

  if (idBlockPlaying) {
    nextBlock = editorState.getCurrentContent().getBlockAfter(idBlockPlaying);
  } else {
    nextBlock = blocks[0];
  }

  if (onlyWithBookmark && nextBlock) {
    const nextIndexBlock = blocks.findIndex((block) => block.getKey() === nextBlock.getKey());
    const nextBlocks = blocks.slice(nextIndexBlock);
    nextBlock = nextBlocks.find((block) => {
      const characterList = block.getCharacterList();
      return characterList.some((char) => char?.hasStyle(EStyle.HIGHLIGHT) || false);
    });
  }

  return nextBlock;
}

export function goToPreviousTranscription({
  onlyWithBookmark,
  idBlockPlaying,
  editorState
}: {
  onlyWithBookmark: boolean;
  idBlockPlaying: string | null;
  editorState: EditorState;
}) {
  const blocks = editorState.getCurrentContent().getBlocksAsArray();

  let previousBlock: any;

  if (idBlockPlaying) {
    previousBlock = editorState.getCurrentContent().getBlockBefore(idBlockPlaying);
  } else {
    const tmpBlock = [...blocks].reverse();
    previousBlock = tmpBlock[0];
  }

  if (onlyWithBookmark && previousBlock) {
    const currentIndexBlock = blocks.findIndex(
      (block) => block.getKey() === previousBlock.getKey()
    );
    const previousBlocks = blocks.slice(0, currentIndexBlock).reverse();
    previousBlock = previousBlocks.find((block) => {
      const characterList = block.getCharacterList();
      return characterList.some((char) => char?.hasStyle(EStyle.HIGHLIGHT) || false);
    });
  }

  return previousBlock;
}

export function getStartTime(
  block: DraftModel.ImmutableData.ContentBlock,
  editorState: DraftModel.ImmutableData.EditorState
): string | number {
  const contentState = editorState.getCurrentContent();
  let startEntityKey;
  let counterStartChar = 0;
  let startTime: number;
  do {
    startEntityKey = block.getEntityAt(counterStartChar++);
  } while (!startEntityKey && counterStartChar < block.getLength());

  if (startEntityKey) startTime = contentState.getEntity(startEntityKey).getData().startTime;
  else startTime = block.getData().get('startTime');

  return +startTime;
}

export function getEndTime(
  block: DraftModel.ImmutableData.ContentBlock,
  editorState: DraftModel.ImmutableData.EditorState
): string | number {
  const contentState = editorState.getCurrentContent();
  let endEntityKey;
  let counterEndChar = block.getLength();
  let endTime: number;

  do {
    endEntityKey = block.getEntityAt(counterEndChar--);
  } while (!endEntityKey && counterEndChar > 0);

  if (endEntityKey) endTime = contentState.getEntity(endEntityKey).getData().endTime;
  else endTime = block.getData().get('endTime');

  if (isNaN(endTime)) {
    let startEntityKey;
    let startTime: number;
    let counterStartChar = 0;
    do {
      startEntityKey = block.getEntityAt(counterStartChar++);
    } while (!startEntityKey && counterStartChar < block.getLength());

    if (startEntityKey) startTime = contentState.getEntity(startEntityKey).getData().startTime;
    else startTime = block.getData().get('startTime');

    endTime = startTime;
  }

  return +endTime;
}

export function findValueByKey(
  keyValuePairs: { [key: string]: string },
  keyToFind: string
): string | undefined {
  return keyValuePairs[keyToFind];
}

export function pushStripeTransactionTracking(detail: StripeTransactionDetail) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      currency: detail.currency,
      value: Number((detail.amount / 100).toFixed(2)), // order total (price of all products + shipping).
      tax: 0.0, // tax
      shipping: 0.0, // shipping costs
      transaction_id: detail.chargeId,
      user_email: detail.userEmail, // email that user entered
      coupon: '', // if coupon was applied to the order, include it here
      items: [
        {
          // an array with all products
          item_name: detail.itemName, // insert an actual product name
          item_id: detail.itemName, // insert an actual product ID
          price: detail.itemPrice, // insert an actual product price. Number or a string. Don't include currency code
          quantity: detail.itemQuantity // product quantity
        }
      ]
    }
  });
}

export function combineFolders(folders: TFolder[]) {
  let tmpFolder = JSON.parse(JSON.stringify(folders)) as TFolder[];
  let parentFolders = tmpFolder.filter((folder) => !folder.encodedParentId);
  let childFolders = tmpFolder.filter((folder) => folder.encodedParentId);

  const parentFolderMap = new Map();
  parentFolders.forEach((parentFolder) => {
    if (!parentFolder.children) {
      parentFolder.children = [];
    }
    parentFolderMap.set(parentFolder.id, parentFolder);
  });

  childFolders.forEach((childFolder) => {
    const parentFolder = parentFolderMap.get(childFolder.encodedParentId);
    if (parentFolder) {
      parentFolder.children.push(childFolder);
    }
  });

  return Array.from(parentFolderMap.values()) as TFolder[];
}

export function separateFolders(folders: TFolder[]) {
  let tmpFolder = JSON.parse(JSON.stringify(folders)) as TFolder[];

  let result: TFolder[] = [];

  tmpFolder.forEach((folder) => {
    result.push(folder);
    if (folder.children && folder.children.length > 0) {
      folder.children.forEach((child) => {
        result.push(child);
      });
      folder.children = [];
    }
  });
  return result;
}


export function pushSignUpTracking(method: string, encodedUserId: string) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'sign_up',
    method,
    user_id: encodedUserId,
    status: true
  });
}

export function pushSubscribeTracking(detail: SubscribeTransactionDetail) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'subscribe',
    subscription_type: detail.subscriptionType,
    plan_type: detail.planType,
    duration: detail.duration,
    price: detail.price,
    discount: detail.discount,
    coupon_code: detail.couponCode
  });
}