import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { TWord } from 'ts/types';

type TFormatedTranscript = {
  text: string;
  startTime: number;
  endTime: number;
};

function CaptionBox() {
  const transcripts = useAppSelector((state) => state.transcription.lastTranscript); // Prevent unnecessary re-renders
  const cursorTime = useAppSelector((state) => state.transcription.transcriptCursorTime); // Prevent unnecessary re-renders

  const formattedTranscripts: TFormatedTranscript[] = useMemo(() => {
    return transcripts.map((t) => {
      let words: TWord[] = JSON.parse(t.wordsJson);
      let text: string = '';
      words.forEach((w) => {
        text += w.text;
      });

      return {
        text,
        startTime: Number(words[0].startTime),
        endTime: Number(words[words.length - 1].endTime)
      };
    });
  }, [JSON.stringify(transcripts)]);

  const currentText: string | null = useMemo(() => {
    let transcript = formattedTranscripts.find(
      (t) => t.startTime <= cursorTime && t.endTime >= cursorTime
    );
    if (!transcript) return null;

    return transcript.text;
  }, [formattedTranscripts, cursorTime]);

  if (!currentText) return null;

  return (
    <div className="video-player-caption-box">
      <span className="text">
        {currentText.split(/\n/).map((text, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={`key-${index}-${text}`}>
            {text}
            <br />
          </span>
        ))}
      </span>
    </div>
  );
}

export default React.memo(CaptionBox);
