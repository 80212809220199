import { useEditorContext } from 'context/EditorContext';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { SearchTranscript } from './SearchTranscrip';
import {
  ContentBlock,
  ContentState,
  DraftHandleValue,
  DraftModel,
  EditorState,
  Entity,
  KeyBindingUtil,
  Modifier,
  RichUtils,
  SelectionState,
  convertFromRaw,
  genKey,
  getDefaultKeyBinding
} from 'draft-js';
import { InlineToolbar, customStyleMap, plugins } from './plugins';
import { useAutoScroll } from 'context/AutoScrollContext';
import TranscriptRow from './TranscriptRow';
import { useVideoPlayerContext } from 'context/VideoPlayerContext';
import { TMedia, TSpeaker, TTranscript } from 'ts/types';
import { useSearchParams } from 'react-router-dom';
import Editor from '@draft-js-plugins/editor';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  createRawContent,
  findWithRegex,
  generateDecorator,
  getEndTimeOfBlock,
  getEntityRange,
  getLastTranscriptRows,
  getStartTimeOfBlock,
  mergeMultipleEditorStates,
  normalizedStringRegex
} from 'utils/draftjs';
import {
  forceCompositeDecorator,
  getEndTime,
  getStartTime,
  goToNextTranscription,
  goToPreviousTranscription,
  roundToTwo
} from 'utils/common';
import {
  BlockDomItem,
  changeOpenSearchBox,
  handleNextTranscript,
  handlePrevTranscript,
  setBlockDomItem,
  setIsChangeTranscrip,
  setIsHandleSave,
  setLastTranscrip,
} from 'redux/transcription/transcription.slice';
import { divideArray } from 'utils/transcript';
import { updateTranscriptionByMediaIdAction } from 'redux/transcription/transcription.action';
import { changeTimeMedia, updateListSpeakers } from 'redux/projects/projects.slice';
import { updateTranscription } from 'apis/transcript.api';
import { useTranslation } from 'react-i18next';
import { useLoaderContext } from 'context/LoaderContext';
import { EModals, EStatusMedia, EStyle, ETypeNoti } from 'ts/enums';
import { PlayBackRate } from 'constant/PlayRate';
import { getInlineToolbarComps } from './components';
import _, { debounce } from 'lodash';
import { pushModal } from 'redux/modal/modal.slice';
import Pagination from 'components/Pagination';
import * as Sentry from '@sentry/react';
import { getDictionaryAction } from 'redux/dictionary/dictionary.action';

const timeAutoSave = 60 * 2 * 1000;

declare global {
  interface ObjectConstructor {
    filter<T>(obj: { [key: string]: T }, predicate: (value: T) => boolean): { [key: string]: T };
  }
}

Object.filter = (obj, predicate) =>
  Object.fromEntries(Object.entries(obj).filter(([key, value]) => predicate(value)));

const toolbarInline: { [key: string]: boolean } = {
  HIGHLIGHT: true,
  STRIKETHROUGH: true,
  BOLD: true,
  ITALIC: true,
  SENTENCECASE: true,
  UPPERCASE: true,
  LOWERCASE: true,
  DICTIONARY: true
};

const exclusiveStyle: any = {
  STRIKETHROUGH: [EStyle.HIGHLIGHT],
  HIGHLIGHT: [EStyle.STRIKETHROUGH],
  UPPERCASE: [EStyle.LOWERCASE, EStyle.SENTENCECASE],
  SENTENCECASE: [EStyle.LOWERCASE, EStyle.UPPERCASE],
  LOWERCASE: [EStyle.UPPERCASE, EStyle.SENTENCECASE],
  BOLD: [],
  ITALIC: [],
  DICTIONARY: []
};

const visibilityInlineToolbars = Object.filter(toolbarInline, (item) => item);

interface TSelection {
  anchorKey: string;
  focusKey: string;
  anchorOffset: number;
  focusOffset: number;
}

interface TSelectionReplace {
  data: SelectionState;
  index: number;
}

export interface TBlockFilter {
  blockId: string;
  index: number;
}

function PS4({
  currentTranscription,
  isLoading,
  isTextRightToLeft,
  currentMedia,
  isScrolling,
  setIsScrolling,
  isSelecting
}: {
  currentTranscription: TTranscript[];
  isLoading: boolean;
  isTextRightToLeft: boolean;
  currentMedia: TMedia | null;
  isScrolling: boolean;
  setIsScrolling: React.Dispatch<React.SetStateAction<boolean>>;
  isSelecting: boolean;
}) {
  const [searchParams] = useSearchParams();
  const mediaId = searchParams.get('mediaId');
  const { isReadOnly, setIsReadonly, isNoneEdit, isLock } = useEditorContext();
  const ctnPS4Ref = useRef<HTMLDivElement>(null);
  const [searchInput, setSeactInput] = useState('');
  const [hasCaseSensitive, setHasCaseSensitive] = useState<boolean>(false);
  const [replaceText, setReplaceText] = useState<string>('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty()); //for render display
  const [transcriptDivided, setTranscrisptDivided] = useState<TTranscript[][]>([]);
  const [editorStateDivided, setEditorDivided] = useState<EditorState[]>([]);
  const [currentPlainText, setCurrentPlainText] = useState<string>('');
  const [rowPerPage, setRowPerPage] = useState<number>(500);

  const openSearchBox = useAppSelector((state) => state.transcription.openSearchBox);
  const selectedSpeakerId = useAppSelector((state) => state.transcription.selectedSpeaker.id);
  const currentDictionary = useAppSelector((state) => state.dictionary.currentDictionary);

  let scrollTimeout: any;

  const {
    isAutoScroll,
    setAutoScroll,
    idBlockPlaying,
    setIdTranscriptRowPlaying,
    setIdBlockPlaying
  } = useAutoScroll();
  const hasCommandModifier = KeyBindingUtil.hasCommandModifier;
  const { isChangeTranscript, currentFilters, isNextTranscript, isPrevTranscript, isOnlyBookmark } =
    useAppSelector((state) => state.transcription);
  const currentUser = useAppSelector((state) => state.auth.currentUser.data);
  const dispatch = useAppDispatch();
  const [selectionsToReplace, setSelectionsToReplace] = useState<TSelectionReplace[]>([]);
  const [currentSelectionToReplace, setCurrentSelectionToReplace] = useState<number>(0);
  const { t } = useTranslation();
  const AUTO_CAPS_CHARS_SUPPORTED = '\\.|\\?|\\!';
  const TemponaryIdxPeriod = new Map();
  const isHandleSave = useAppSelector((state) => state.transcription.isHandleSave);
  const [blocksRelevantFilter, setBlocksRelevantFilter] = useState<TBlockFilter[]>([]);
  const { loader } = useLoaderContext();
  const editorRef = useRef<Editor | null>(null);
  const {
    seekTo,
    showPreview,
    setShowPreview,
    isPlaying,
    setIsPlaying,
    playBackRate,
    setPlayBackRate,
    currentTime,
    setCurrentTime,
    userDefinedTimeCode,
    total,
    setTotal,
    currentPage,
    setCurrentPage,
    setTimeDivied,
    timeDivided
  } = useVideoPlayerContext();

  const setWidthRef = () => {
    if (ctnPS4Ref.current) {
      const widthCtnRef = ctnPS4Ref.current.clientWidth;
      if (window.innerWidth >= 1460 && window.innerWidth < 1600) {
        document.documentElement.style.setProperty('--translate-value', `${widthCtnRef - 10}px`);
      } else if (window.innerWidth >= 992 && window.innerWidth < 1460) {
        document.documentElement.style.setProperty('--translate-value', `${widthCtnRef - 240}px`);
      } else if (window.innerWidth < 992) {
        document.documentElement.style.setProperty('--translate-value', `${widthCtnRef - 230}px`);
      } else {
        document.documentElement.style.setProperty('--translate-value', `${widthCtnRef}px`);
      }
    }
  };

  const checkOpenAnnotation = (dataAnnotation: any) => {
    if (window.innerWidth >= 1460 && dataAnnotation) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setWidthRef();
    window.addEventListener('resize', () => {
      setWidthRef();
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, [ctnPS4Ref.current]);

  useLayoutEffect(() => {
    if (isLoading) {
      setEditorState(EditorState.createEmpty());
    } else if (currentTranscription && !isLoading) {
      setEditorDivided([]);
      setTotal(currentTranscription.length);

      const transcriptDivided = divideArray(currentTranscription, rowPerPage);
      setTranscrisptDivided(transcriptDivided);

      const rawContent = createRawContent(currentTranscription);
      if (rawContent) {
        const initialContentState = convertFromRaw(rawContent);
        const newState = EditorState.createWithContent(initialContentState);
        setCurrentPlainText(newState.getCurrentContent().getPlainText());
      }

      for (var i = 0; i <= transcriptDivided.length; i++) {
        setInitialDividedEditorState({ transcriptDivided, currentPage: i });
      }
    }
  }, [isLoading, currentTranscription, rowPerPage]);

  useEffect(() => {
    if (editorStateDivided.length > 0) {
      const blockDomItem: BlockDomItem[] = [];
      editorStateDivided[currentPage - 1]
        .getCurrentContent()
        .getBlocksAsArray()
        .forEach((block) => {
          blockDomItem.push({
            id: block.getKey(),
            start: Number(getStartTime(block, editorState)),
            end: Number(getEndTime(block, editorState))
          });
        });

      dispatch(setBlockDomItem({ blockDomItem }));
      setEditorState(editorStateDivided[currentPage - 1]);
    }
  }, [currentPage, transcriptDivided]);

  useEffect(() => {
    document.getElementsByTagName('main')[0].scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  useEffect(() => {
    !isLoading && isCurrentContentDistinctThanOriginal();
  }, [editorState, isLoading]);

  useEffect(() => {
    if (isHandleSave) {
      saveTranscript();
      dispatch(setIsHandleSave({ isHandleSave: false }));
    }
  }, [isHandleSave]);

  useEffect(() => {
    if (isChangeTranscript && mediaId) {
      const timeout = setTimeout(() => {
        dispatch(setIsHandleSave({ isHandleSave: true }));
      }, timeAutoSave);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isChangeTranscript]);

  useEffect(() => {
    if(!currentDictionary.isLoading && !currentDictionary.isFetched)
      dispatch(getDictionaryAction());

    setCurrentPage(1);
    dispatch(setIsChangeTranscrip({ isChange: false }));
  }, [mediaId]);

  useEffect(() => {
    window.onbeforeunload = (e) => {
      if (isChangeTranscript) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [isChangeTranscript]);

  useEffect(() => {
    if (!ctnPS4Ref.current) return;

    document.addEventListener('wheel', (e) => {
      if (
        Math.abs(e.deltaX) <= 0 &&
        ctnPS4Ref.current &&
        document.body.offsetHeight <= ctnPS4Ref.current?.clientHeight
      ) {
        setAutoScroll(false);
      }
    });
    return () => {
      document.removeEventListener('wheel', () => {
        setAutoScroll(true);
      });
    };
  }, [ctnPS4Ref.current]);

  const handleSaveSelection = useCallback(
    (editorState: EditorState) => {
      const selectionState = editorState.getSelection();
      const selection = window.getSelection();
      if (!selection?.isCollapsed) {
        const anchorKey = selectionState.getStartKey();
        const focusKey = selectionState.getEndKey();
        const anchorOffset = selectionState.getStartOffset();
        const focusOffset = selectionState.getEndOffset();

        return {
          anchorKey,
          focusKey,
          anchorOffset,
          focusOffset
        };
      }
      return null;
    },
    [editorState]
  );

  const reSelect = useCallback(
    (editorState: EditorState, selection: TSelection | null) => {
      if (!selection) {
        return editorState;
      }

      const newSelection = SelectionState.createEmpty(selection.anchorKey).merge({
        anchorOffset: selection.anchorOffset,
        focusOffset: selection.focusOffset,
        focusKey: selection.focusKey
      });

      const newEditorState = EditorState.forceSelection(editorState, newSelection);

      return newEditorState;
    },
    [editorState]
  );

  useEffect(() => {
    const selection = handleSaveSelection(editorState);
    if (isScrolling && !openSearchBox && !isSelecting) {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        if (selection) {
          const newEditorState = forceCompositeDecorator(editorState);
          setEditorState(reSelect(newEditorState, selection));
        } else {
          setEditorState((prev) => forceCompositeDecorator(prev));
        }
        setIsScrolling(false);
      }, 250);
      return;
    }
  }, [isScrolling, openSearchBox, isSelecting]);

  useEffect(() => {
    function checkHeight() {
      const height = ctnPS4Ref.current?.getBoundingClientRect().height || 0;
      if (height > 39) {
        if (!openSearchBox) setEditorState((prev) => forceCompositeDecorator(prev));
        return true;
      }
      return false;
    }

    const interval = setInterval(() => {
      if (checkHeight()) {
        clearInterval(interval);
      }
    }, 2 * 1000);
  }, [mediaId, currentPage]);

  useEffect(() => {
    handleFilter(currentFilters);
  }, [currentFilters]);

  useEffect(() => {
    if (blocksRelevantFilter.length > 0) {
      if (!blocksRelevantFilter.map((block) => block.index + 1).includes(currentPage)) {
        setCurrentPage(blocksRelevantFilter[0].index + 1);
      }
    }
  }, [blocksRelevantFilter]);

  const isLastBlock = (blockKey: string) => {
    return blockKey === editorState.getCurrentContent().getLastBlock().getKey();
  };

  const isFirstBlock = (blockKey: string) => {
    return blockKey === editorState.getCurrentContent().getFirstBlock().getKey();
  };

  const setBlockPlaying = (block: any, editorState: EditorState) => {
    const blockey = block.getKey();
    const startTime = Number(getStartTime(block, editorState));
    setIdTranscriptRowPlaying(blockey);
    setIdBlockPlaying(blockey);
    seekTo(startTime + userDefinedTimeCode, 'seconds');
    setCurrentTime(startTime + userDefinedTimeCode);
    setAutoScroll(true);
  };

  const handleNextBlock = (editorState: EditorState, idBlockPlaying: string | null) => {
    const nextBlock = goToNextTranscription({
      onlyWithBookmark: isOnlyBookmark,
      idBlockPlaying,
      editorState
    });

    if (nextBlock) {
      setBlockPlaying(nextBlock, editorState);
    } else {
      const currentIndex = currentPage - 1;
      const tmpEditorState = editorStateDivided.slice(currentIndex + 1, editorStateDivided.length);

      tmpEditorState.some((editorState, index) => {
        const nextBlock = goToNextTranscription({
          onlyWithBookmark: isOnlyBookmark,
          idBlockPlaying: null,
          editorState
        });

        if (nextBlock) {
          setBlockPlaying(nextBlock, editorState);
          return true;
        }
      });
    }

    dispatch(handleNextTranscript({ isNext: false, isOnlyBookmark }));
  };

  const handleNextTranscriptWithPage = () => {
    if (idBlockPlaying && isLastBlock(idBlockPlaying) && currentPage !== total) {
      setCurrentPage((prev) => prev + 1);
      handleNextBlock(editorStateDivided[currentPage + 1], null);
    } else {
      handleNextBlock(editorState, idBlockPlaying);
    }
  };

  const handlePrevBlock = (editorState: EditorState, idBlockPlaying: string | null) => {
    const prevBlock = goToPreviousTranscription({
      onlyWithBookmark: isOnlyBookmark,
      idBlockPlaying,
      editorState
    });
    if (prevBlock) {
      setBlockPlaying(prevBlock, editorState);
    } else {
      const currentIndex = currentPage - 1;
      const tmpEditorState = editorStateDivided.slice(0, currentIndex).reverse();

      tmpEditorState.some((editorState, index) => {
        const prevBlock = goToPreviousTranscription({
          onlyWithBookmark: isOnlyBookmark,
          idBlockPlaying: null,
          editorState
        });

        if (prevBlock) {
          setBlockPlaying(prevBlock, editorState);
          return true;
        }
      });
    }

    dispatch(handlePrevTranscript({ isPrev: false, isOnlyBookmark }));
  };

  const handlePrevTranscriptWithPage = () => {
    if (idBlockPlaying && isFirstBlock(idBlockPlaying) && currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      handlePrevBlock(editorStateDivided[currentPage - 1], null);
    } else {
      handlePrevBlock(editorState, idBlockPlaying);
    }
  };

  useEffect(() => {
    if (isNextTranscript) {
      handleNextTranscriptWithPage();
    }
  }, [isNextTranscript, isOnlyBookmark]);

  useEffect(() => {
    if (isPrevTranscript) {
      handlePrevTranscriptWithPage();
    }
  }, [isPrevTranscript, isOnlyBookmark]);

  const isEditorFocused = () => {
    return document.activeElement?.className === 'notranslate public-DraftEditor-content';
  };

  const handleGlobalKeyDown = (e: any) => {
    if (isEditorFocused()) return;
    if (e.key === 'f' && hasCommandModifier(e)) {
      e.preventDefault();
      dispatch(changeOpenSearchBox());
    }
    if (e.key === 'z' && hasCommandModifier(e)) {
      e.preventDefault();
      if (editorState.getUndoStack().size > 0) setEditorState(EditorState.undo(editorState));
    }
    if (e.key === 'z' && e.shiftKey && hasCommandModifier(e)) {
      e.preventDefault();
      if (editorState.getRedoStack().size > 0) setEditorState(EditorState.redo(editorState));
    }
  };

  const handleGlobalKeyDownRef = useRef(handleGlobalKeyDown);

  useEffect(() => {
    handleGlobalKeyDownRef.current = handleGlobalKeyDown;
  }, [handleGlobalKeyDown]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      handleGlobalKeyDownRef.current(e);
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  function setInitialDividedEditorState({
    transcriptDivided,
    currentPage
  }: {
    transcriptDivided: TTranscript[][];
    currentPage: number;
  }) {
    if (transcriptDivided) {
      const rawContent = createRawContent(transcriptDivided[currentPage]);

      if (rawContent) {
        const initialContentState = convertFromRaw(rawContent);
        const newState = EditorState.createWithContent(initialContentState);

        setEditorDivided((prev: EditorState[]) => {
          const newStateArray = [...prev];
          newStateArray[currentPage] = newState;
          return newStateArray;
        });

        const currentContent = newState.getCurrentContent();
        const firstBlock = currentContent.getFirstBlock();
        const lastBlock = currentContent.getLastBlock();

        const startTime = Number(getStartTime(firstBlock, newState));
        const endTime = Number(getEndTime(lastBlock, newState));

        setTimeDivied((prev) => {
          const newTimeArray = [...prev];
          newTimeArray[currentPage] = { startTime, endTime };
          return newTimeArray;
        });
      }
    }
  }

  const isCurrentContentDistinctThanOriginal = () => {
    if (editorStateDivided.length === 0) return;
    const mergedState = mergeMultipleEditorStates(editorStateDivided);
    const currentText = mergedState.getCurrentContent().getPlainText();
    const difference = currentPlainText !== currentText;
    if (difference) {
      dispatch(setIsChangeTranscrip({ isChange: difference }));
    }
  };

  async function updateAllMatchingAutoSpeaker(
    speaker: TSpeaker,
    autoSpeaker: string,
    block: DraftModel.ImmutableData.ContentBlock
  ) {
    if (!mediaId) return;
    const newEditorState = editorState;
    let newContentState = newEditorState.getCurrentContent();

    newContentState = Modifier.mergeBlockData(
      newContentState,
      SelectionState.createEmpty(block.getKey()),
      block.getData().set('speaker', JSON.stringify(speaker))
    );

    newEditorState
      .getCurrentContent()
      .getBlockMap()
      .forEach((contentBlock) => {
        if (!contentBlock) return;

        const currentAutoSpeaker = contentBlock.getData().get('autoSpeaker');
        const currentSpeaker = contentBlock.getData().get('speaker');

        if (
          (autoSpeaker === currentAutoSpeaker && !currentSpeaker) ||
          (currentSpeaker && speaker && JSON.parse(currentSpeaker)?.id === speaker?.id)
        ) {
          newContentState = Modifier.mergeBlockData(
            newContentState,
            SelectionState.createEmpty(contentBlock.getKey()),
            contentBlock.getData().set('speaker', JSON.stringify(speaker))
          );
        }
      });

    let newState = EditorState.push(newEditorState, newContentState, 'change-block-data');

    let tmpEditorDivided = [...editorStateDivided];
    tmpEditorDivided[currentPage - 1] = newState;
    setEditorState(newState);
    setEditorDivided(tmpEditorDivided);
    const newTranscript: TTranscript[] = getLastTranscriptRows(
      mergeMultipleEditorStates(tmpEditorDivided)
    );

    loader.start();
    await updateTranscription({ mediaId, listTranscript: newTranscript });
    loader.complete();

    if (speaker) {
      dispatch(updateListSpeakers({ mediaId, speaker }));
    }
  }

  async function handleUpdateAnnotation(
    block: DraftModel.ImmutableData.ContentBlock,
    annotation: string
  ) {
    const newEditorState = EditorState.push(
      editorState,
      Modifier.mergeBlockData(
        editorState.getCurrentContent(),
        SelectionState.createEmpty(block.getKey()),
        block.getData().set('annotation', annotation)
      ),
      'change-block-data'
    );
    setEditorState(newEditorState);

    const tmpEditorDivided = editorStateDivided;
    tmpEditorDivided[currentPage - 1] = newEditorState;
    const newEditorStateMerged = mergeMultipleEditorStates(tmpEditorDivided);
    const lastTranscript: TTranscript[] = getLastTranscriptRows(newEditorStateMerged);

    const transcriptDivided = divideArray(lastTranscript, rowPerPage);
    setTranscrisptDivided(transcriptDivided);
    for (var i = 0; i <= transcriptDivided.length; i++) {
      setInitialDividedEditorState({ transcriptDivided, currentPage: i });
    }

    dispatch(setLastTranscrip({ newTranscript: lastTranscript }));
    if (mediaId) {
      loader.start();
      await updateTranscription({ mediaId, listTranscript: lastTranscript });
      loader.complete();
      dispatch(setIsChangeTranscrip({ isChange: false }));
    }
  }

  function saveTranscript() {
    if (!isChangeTranscript || isLock || isNoneEdit) return;
    try {
      const newState = mergeMultipleEditorStates(editorStateDivided);
      loader.start();
      dispatch(setIsChangeTranscrip({ isChange: false }));
      const newTranscript = getLastTranscriptRows(newState);
      if (mediaId && newTranscript)
        dispatch(
          updateTranscriptionByMediaIdAction({
            mediaId,
            listTranscript: newTranscript
          })
        ).then(() => {
          loader.complete();
        });
      for (var i = 0; i <= editorStateDivided.length - 1; i++) {
        setCurrentPlainText(newState.getCurrentContent().getPlainText());
      }
      mediaId && dispatch(changeTimeMedia({ mediaId }));
    } catch (error) {
      Sentry.captureMessage(JSON.stringify({ topic: 'save transcript error', message: error }));
    }
  }

  let timeoutScrollIntoView: any;

  function scrollIntoView(selectionState: SelectionState) {
    const blockKey = selectionState.getAnchorKey();

    let currentTime: any;

    editorStateDivided.forEach((editorState) => {
      const contentState = editorState.getCurrentContent();
      const block = contentState.getBlockForKey(selectionState.getStartKey());
      if (block) {
        currentTime = getStartTime(block, editorState);
      }
    });

    const currrentEl = document.querySelector(
      `[data-block=true][data-offset-key="${blockKey}-0-0"]`
    );

    if (currrentEl)
      currrentEl.scrollIntoView({ block: 'start', inline: 'center', behavior: 'smooth' });
    else {
      let foundIndex = -1;
      for (let i = 0; i < timeDivided.length; i++) {
        const interval = timeDivided[i];
        if (currentTime >= interval.startTime && currentTime <= interval.endTime) {
          foundIndex = i;
          break;
        }
      }

      if (foundIndex !== -1) {
        setCurrentPage(foundIndex + 1);
        clearTimeout(timeoutScrollIntoView);

        timeoutScrollIntoView = setTimeout(() => {
          const currrentEl = document.querySelector(
            `[data-block=true][data-offset-key="${blockKey}-0-0"]`
          );
          if (currrentEl)
            currrentEl.scrollIntoView({ block: 'start', inline: 'center', behavior: 'smooth' });
        }, 1000);
      }
    }
  }

  function nextSelectionToReplace() {
    if (selectionsToReplace.length === 0) return;
    const next = currentSelectionToReplace + 1;
    const newCurrentSelectionToReplace = next > selectionsToReplace.length - 1 ? 0 : next;

    setCurrentSelectionToReplace(newCurrentSelectionToReplace);
    setEditorState(
      EditorState.set(editorState, {
        decorator: generateDecorator({
          highlightTerm: searchInput,
          selectionState: selectionsToReplace[newCurrentSelectionToReplace].data,
          hasCaseSensitive: hasCaseSensitive
        })
      })
    );

    editorStateDivided.forEach((editorState, index) => {
      setEditorDivided((prev: EditorState[]) => {
        const newStateArray = [...prev];
        newStateArray[index] = EditorState.set(editorState, {
          decorator: generateDecorator({
            highlightTerm: searchInput,
            selectionState: selectionsToReplace[newCurrentSelectionToReplace].data,
            hasCaseSensitive: hasCaseSensitive
          })
        });
        return newStateArray;
      });
    });

    scrollIntoView(selectionsToReplace[newCurrentSelectionToReplace].data);
  }

  function prevSelectionToReplace() {
    if (selectionsToReplace.length === 0) return;
    const prev = currentSelectionToReplace - 1;
    const newCurrentSelectionToReplace = prev < 0 ? selectionsToReplace.length - 1 : prev;

    setCurrentSelectionToReplace(newCurrentSelectionToReplace);
    setEditorState(
      EditorState.set(editorState, {
        decorator: generateDecorator({
          highlightTerm: searchInput,
          selectionState: selectionsToReplace[newCurrentSelectionToReplace].data,
          hasCaseSensitive: hasCaseSensitive
        })
      })
    );

    editorStateDivided.forEach((editorState, index) => {
      setEditorDivided((prev: EditorState[]) => {
        const newStateArray = [...prev];
        newStateArray[index] = EditorState.set(editorState, {
          decorator: generateDecorator({
            highlightTerm: searchInput,
            selectionState: selectionsToReplace[newCurrentSelectionToReplace].data,
            hasCaseSensitive: hasCaseSensitive
          })
        });
        return newStateArray;
      });
    });
    scrollIntoView(selectionsToReplace[newCurrentSelectionToReplace].data);
  }

  function handleReplace() {
    if (
      selectionsToReplace.length > 0 &&
      currentSelectionToReplace <= selectionsToReplace.length - 1 &&
      replaceText !== searchInput
    ) {
      let selectionState = selectionsToReplace[currentSelectionToReplace];
      let contentState = editorState.getCurrentContent();
      const newEditorState = EditorState.acceptSelection(editorState, selectionState.data);
      contentState = Modifier.replaceText(
        contentState,
        selectionState.data,
        replaceText,
        newEditorState.getCurrentInlineStyle()
      );

      onChangeForReplace(
        EditorState.push(editorState, contentState, 'change-block-data'),
        selectionsToReplace,
        currentPage - 1
      );
    }
  }

  function onChangeForReplace(
    editorState: EditorState,
    selectionsToReplace: TSelectionReplace[],
    currentPageProps: number
  ) {
    if (selectionsToReplace) {
      const newEditorState = EditorState.set(editorState, {
        decorator: generateDecorator({
          highlightTerm: searchInput,
          selectionState:
            currentSelectionToReplace + 1 === selectionsToReplace.length
              ? selectionsToReplace[0].data
              : selectionsToReplace[currentSelectionToReplace + 1].data,
          hasCaseSensitive: hasCaseSensitive
        })
      });

      setCurrentSelectionToReplace(
        currentSelectionToReplace + 1 === selectionsToReplace.length
          ? 0
          : currentSelectionToReplace + 1
      );

      const newStateArr = [...editorStateDivided];
      newStateArr[currentPageProps] = newEditorState;
      setEditorDivided(newStateArr);
      debounceSearch(searchInput, hasCaseSensitive, newStateArr);
    }
  }

  const handleReplaceAll = () => {
    editorStateDivided.map((editorState, currentIndex) => {
      let contentState = editorState.getCurrentContent();
      let mapBlocksReplaced = new Map();
      let numModifier = 0;

      selectionsToReplace.forEach((selectionState) => {
        if (currentIndex === selectionState.index) {
          const newEditorState = EditorState.acceptSelection(editorState, selectionState.data);
          const anchorOffset = selectionState.data.getAnchorOffset();
          const focusOffset = selectionState.data.getFocusOffset();
          const anchorKey = selectionState.data.getAnchorKey();

          if (replaceText !== searchInput) {
            if (mapBlocksReplaced.has(anchorKey)) {
              numModifier += replaceText.length - searchInput.length;
            } else {
              numModifier = 0;
              mapBlocksReplaced = mapBlocksReplaced.set(anchorKey, true);
            }
          }

          contentState = Modifier.replaceText(
            contentState,
            selectionState.data.merge({
              anchorOffset: anchorOffset + numModifier,
              focusOffset: focusOffset + numModifier
            }),
            replaceText,
            newEditorState.getCurrentInlineStyle()
          );
        }
      });

      let newEditorDivided = editorStateDivided;
      newEditorDivided[currentIndex] = EditorState.push(
        editorState,
        contentState,
        'change-block-data'
      );
      setEditorDivided(newEditorDivided);
      debounceSearch(searchInput, hasCaseSensitive, newEditorDivided);
    });
  };

  let timeoutScrollAfterSearch: any;
  const debounceSearch = debounce(
    (searchInput: string, hasCaseSensitive: boolean, editorStateDivided: EditorState[]) => {
      if (searchInput === '') {
        setEditorState(forceCompositeDecorator(editorState));
        setSelectionsToReplace([]);
        setCurrentSelectionToReplace(0);
        return;
      }

      const regex = new RegExp(
        normalizedStringRegex(searchInput),
        `${hasCaseSensitive ? 'gm' : 'gmi'}`
      );

      const stateSelections: TSelectionReplace[] = [];

      editorStateDivided.forEach((editorState, index) => {
        const blockMap = editorState.getCurrentContent().getBlockMap();
        blockMap.forEach((contentBlock) => {
          if (contentBlock) {
            findWithRegex(regex, contentBlock, (start: number, end: number) => {
              const blockKey = contentBlock.getKey();
              const blockSelection = SelectionState.createEmpty(blockKey).merge({
                anchorOffset: start,
                focusOffset: end
              });
              stateSelections.push({ data: blockSelection, index });
              return;
            });
          }
        });
      });

      let currentSelection = stateSelections.length ? 0 : null;
      setEditorState(
        EditorState.set(editorStateDivided[currentPage - 1], {
          decorator: generateDecorator({
            highlightTerm: searchInput,
            selectionState: stateSelections[currentSelection || 0]?.data,
            hasCaseSensitive: hasCaseSensitive
          })
        })
      );

      editorStateDivided.forEach((editorState, index) => {
        setEditorDivided((prev: EditorState[]) => {
          const newStateArray = [...prev];
          newStateArray[index] = EditorState.set(editorState, {
            decorator: generateDecorator({
              highlightTerm: searchInput,
              selectionState: stateSelections[currentSelection || 0]?.data,
              hasCaseSensitive: hasCaseSensitive
            })
          });
          return newStateArray;
        });
      });

      setSelectionsToReplace(stateSelections);
      if (currentSelection !== null) {
        setCurrentSelectionToReplace(currentSelection);
        clearTimeout(timeoutScrollAfterSearch);
        timeoutScrollAfterSearch = setTimeout(() => {
          scrollIntoView(stateSelections[0].data);
        }, 500);
      }
    },
    300
  );

  function handleSearch(searchInput: string, hasCaseSensitive: boolean) {
    setSeactInput(searchInput);
    openSearchBox && debounceSearch(searchInput, hasCaseSensitive, editorStateDivided);
    !openSearchBox && setEditorState((prev) => forceCompositeDecorator(prev));
  }

  function handleOnChange(e: EditorState) {
    setEditorState(e);
    setEditorDivided((prev: EditorState[]) => {
      const newStateArray = [...prev];
      newStateArray[currentPage - 1] = e;
      return newStateArray;
    });
  }

  function handlePlayPause() {
    if (showPreview) {
      setShowPreview(false);
    }
    setIsPlaying(!isPlaying);
  }

  function handleChangeIncrePlayBackRate() {
    const findNextIdx = PlayBackRate.findIndex((rate) => rate === playBackRate) + 1;
    const nextRate = PlayBackRate[findNextIdx];
    if (!nextRate) {
      setPlayBackRate(PlayBackRate[PlayBackRate.length - 1]);
      return;
    }
    setPlayBackRate(nextRate);
  }

  function handleChangeDecrePlayBackRate() {
    const findPrevIdx = PlayBackRate.findIndex((rate) => rate === playBackRate) - 1;
    const prevRate = PlayBackRate[findPrevIdx];
    if (!prevRate) {
      setPlayBackRate(PlayBackRate[0]);
      return;
    }
    setPlayBackRate(prevRate);
  }

  function handleKeyComment(command: string, editorState: EditorState): DraftHandleValue {
    if (command === 'backspace') {
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();
      const startKey = selectionState.getStartKey();
      const startOffset = selectionState.getStartOffset();
      const endOffset = selectionState.getEndOffset();

      const currentBlock = contentState.getBlockForKey(startKey);
      const previousBlock = contentState.getBlockBefore(startKey);

      if (currentBlock.getKey() === contentState.getFirstBlock().getKey() && startOffset === 0) {
        return 'handled';
      }

      if (startOffset === 0 && endOffset === 0 && previousBlock) {
        const removeSelection = new SelectionState({
          anchorKey: previousBlock.getKey(),
          anchorOffset: previousBlock.getText().length,
          focusKey: startKey,
          focusOffset: 0
        });

        const newContentState = Modifier.removeRange(contentState, removeSelection, 'backward');

        const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');

        const newSelection = new SelectionState({
          anchorKey: previousBlock.getKey(),
          anchorOffset: previousBlock.getText().length,
          focusKey: previousBlock.getKey(),
          focusOffset: previousBlock.getText().length
        });

        setEditorState(EditorState.forceSelection(newEditorState, newSelection));
        return 'handled';
      }

      // if (startOffset !== endOffset) {
      //   const removeSelection = new SelectionState({
      //     anchorKey: startKey,
      //     anchorOffset: startOffset,
      //     focusKey: startKey,
      //     focusOffset: endOffset
      //   });

      //   const newContentState = Modifier.removeRange(contentState, removeSelection, 'forward');
      //   const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
      //   setEditorState(newEditorState);
      //   return 'handled';
      // }

      return 'not-handled';
    }

    if (command === 'delete') {
      let contentState = editorState.getCurrentContent();
      const block = contentState.getBlockMap().first();
      const next = contentState.getBlockAfter(block.getKey());

      if (block && next) {
        const removeSelection = new SelectionState({
          anchorKey: block.getKey(),
          anchorOffset: block.getText().length,
          focusKey: next.getKey(),
          focusOffset: 0
        });

        let newContentState = Modifier.removeRange(contentState, removeSelection, 'forward');

        let newEditorState = EditorState.push(editorState, newContentState, 'remove-range');

        setEditorState(newEditorState);
      }
      return 'not-handled';
    }

    if (command === 'myeditor-save') {
      if (isChangeTranscript) {
        saveTranscript();
      }
      return 'handled';
    }

    if (command === 'play-pause') {
      handlePlayPause();
      return 'handled';
    }

    if (command === 'incre-play-rate') {
      handleChangeIncrePlayBackRate();
      return 'handled';
    }

    if (command === 'decre-play-rate') {
      handleChangeDecrePlayBackRate();
      return 'handled';
    }

    if (command === 'incre-5s') {
      if (currentMedia?.duration) {
        const newCurentTime =
          currentTime >= currentMedia.duration ? currentMedia.duration : currentTime + 5;
        setCurrentTime(newCurentTime);
        seekTo(newCurentTime);
        return 'handled';
      } else {
        return 'not-handled';
      }
    }

    if (command === 'decre-5s') {
      const newCurentTime = currentTime > 5 ? currentTime - 5 : 0;
      setCurrentTime(newCurentTime);
      seekTo(newCurentTime);
      return 'handled';
    }
    return 'not-handled';
  }

  function handleKeyBindingFn(e: React.KeyboardEvent<Element>) {
    if (e.key === 's' /* `S` key */ && hasCommandModifier(e)) {
      e.preventDefault();
      return 'myeditor-save';
    }
    if (e.key === 'Backspace') {
      return 'backspace';
    }
    if (e.key === 'Delete') {
      return 'delete';
    }
    if (e.code === 'Space' && (e.altKey || e.ctrlKey || e.metaKey) && !e.shiftKey) {
      e.preventDefault();
      return 'play-pause';
    }

    if (e.key === '.' && (e.altKey || e.ctrlKey || e.metaKey) && !e.shiftKey) {
      e.preventDefault();
      return 'incre-play-rate';
    }

    if (e.key === ',' && (e.altKey || e.ctrlKey || e.metaKey) && !e.shiftKey) {
      e.preventDefault();
      return 'decre-play-rate';
    }

    if (e.code === 'ArrowRight' && (e.altKey || e.ctrlKey || e.metaKey) && !e.shiftKey) {
      e.preventDefault();
      return 'incre-5s';
    }

    if (e.code === 'ArrowLeft' && (e.altKey || e.ctrlKey || e.metaKey) && !e.shiftKey) {
      e.preventDefault();
      return 'decre-5s';
    }
    return getDefaultKeyBinding(e);
  }

  function onUpdateBlockData(data: any, key: string, editorState: EditorState) {
    dispatch(setIsChangeTranscrip({ isChange: true }));
    return EditorState.push(
      editorState,
      Modifier.mergeBlockData(
        editorState.getCurrentContent(),
        SelectionState.createEmpty(key),
        data
      ),
      'change-block-data'
    );
  }

  function handleShowModalNoti(type: ETypeNoti, title: string) {
    dispatch(
      pushModal({
        name: EModals.NOTI_MODAL,
        data: null,
        notiData: {
          type,
          title
        }
      })
    );
  }

  function handleBlockRendererFn(block: DraftModel.ImmutableData.ContentBlock) {
    const type = block.getType();
    const autoSpeaker = block.getData().get('autoSpeaker');
    const annotation = block.getData().get('annotation');
    const blockSpeaker = block.getData().get('speaker');
    const speaker = blockSpeaker ? (JSON.parse(blockSpeaker) as TSpeaker) : undefined;
    const contentState = editorState.getCurrentContent();

    if (type === 'TranscriptRow') {
      return {
        component: TranscriptRow,
        props: {
          annotation: annotation,
          openAnnotation: checkOpenAnnotation(annotation),
          speaker: speaker,
          blocksRelevantFilter: blocksRelevantFilter,
          editorState: editorState,
          isFirstBlock: contentState.getFirstBlock().getKey() === block.getKey(),
          isLastBlock: contentState.getLastBlock().getKey() === block.getKey(),
          isSubtitled: currentMedia?.status === EStatusMedia.SUBTITLED,
          currentMedia: currentMedia,
          currentUser: currentUser,
          onChange: (editorState: EditorState) => handleOnChange(editorState),
          onJumpToBlock: (startTime: number) => seekTo(startTime, 'seconds'),
          updateAllMatchingAutoSpeaker: (speaker: TSpeaker) =>
            updateAllMatchingAutoSpeaker(speaker, autoSpeaker, block),
          updateAnnotation: (value: string) => {
            handleUpdateAnnotation(block, value);
          },
          addNewBlock: (blockKey?: string) => {
            addNewBlock(block, blockKey);
          },
          onUpdateBlockData: (data: any, newEditorState: EditorState) =>
            onUpdateBlockData(data, block.getKey(), newEditorState),
          handleShowModalNoti: (type: ETypeNoti, title: string) => {
            handleShowModalNoti(type, title);
          }
        }
      };
    }
  }

  function handleTimingPerWord(editorState: EditorState) {
    try {
      const contentState = editorState.getCurrentContent();
      let selectionState = editorState.getSelection();
      const blockKey = selectionState.getAnchorKey();
      const startBlock = contentState.getBlockForKey(blockKey);
      let start = selectionState.getStartOffset();

      if (start === 0) return false;

      let currentEntity = startBlock.getEntityAt(start);
      let nextEntity = null;
      let num = 0;
      let isEndAnEntity = false;

      // at the end of a block, the entity is null so we get the last one
      if (!currentEntity && start > 1) {
        const nextBlock = contentState.getBlockAfter(blockKey);
        currentEntity = startBlock.getEntityAt(--start);
        if (nextBlock) nextEntity = nextBlock.getEntityAt(0);
      }
      /* eslint-disable no-constant-condition */
      while (true) {
        num++;
        nextEntity = startBlock.getEntityAt(start + num);

        if (!nextEntity) break;
        if (nextEntity && nextEntity !== currentEntity) break;
      }

      if (!nextEntity) {
        const nextBlock = contentState.getBlockAfter(blockKey);
        if (nextBlock) nextEntity = nextBlock.getEntityAt(0);
      }

      if (!nextEntity) return false;

      const entityRangeStart = getEntityRange(editorState, currentEntity);

      if (entityRangeStart?.end === start || entityRangeStart?.end - 1 === start) {
        isEndAnEntity = true;
      }
      if (!isEndAnEntity) return false;

      const { endTime: newStartTime } = contentState.getEntity(currentEntity).getData();
      const { startTime: newEndTime } = contentState.getEntity(nextEntity).getData();

      const newEntity = contentState.createEntity('TIME_CODED_WORD', 'MUTABLE', {
        startTime: newStartTime,
        endTime: newEndTime
      });

      selectionState = selectionState.merge({
        anchorOffset: start + 1,
        focusOffset: start + 1
      });

      const newContentState = Modifier.insertText(
        contentState,
        selectionState,
        ' ',
        undefined,
        newEntity.getLastCreatedEntityKey()
      );
      let newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
      newEditorState = EditorState.acceptSelection(newEditorState, selectionState);
      handleOnChange(newEditorState);
    } catch (error) {
      console.log('handleTimingPerWord error', error);
      Sentry.captureMessage(JSON.stringify({ topic: 'handleTimingPerWord', message: error }));
    }
    return true;
  }

  function determineBestEntityToAmend(
    entity: string,
    block: DraftModel.ImmutableData.ContentBlock,
    start: number
  ) {
    // if we're at the last position, use the previous word's entity
    if (entity == null && start > 0) {
      entity = block.getEntityAt(start - 1);
    } else if (entity == null) {
      //if there isn't a pervious entity, creat a new one with pargraph's timings
      const startTime = block.getData().get('startTime');
      const endTime = block.getData().get('endTime');
      entity = Entity.create('TIME_CODED_WORD', 'MUTABLE', {
        startTime,
        endTime
      });
    } else if (start > 1 && block.getText().charAt(start - 1) != ' ') {
      // if there is no space at the end of the previous word, use the previous word's entity
      entity = block.getEntityAt(start - 1);
    }

    // otherwise, use the next word's entity
    return entity;
  }

  function hasHandledIdxPeriod(key: string, idxPeriod: number) {
    if (!TemponaryIdxPeriod.has(key)) {
      TemponaryIdxPeriod.set(key, [idxPeriod]);
      return false;
    }

    const arr = TemponaryIdxPeriod.get(key);
    if (arr.includes(idxPeriod)) return true;

    arr.push(idxPeriod);
    TemponaryIdxPeriod.set(key, arr);
    return false;
  }

  const isSpaceChar = (char: string) => /^\s$/.test(char);

  const isPeriodChar = (char: string) => /^\.$/.test(char);

  function hasAPeriodInsertedBefore(editorState: EditorState) {
    const selectionState = editorState.getSelection();
    const blockKeyStart = selectionState.getStartKey();
    const start = selectionState.getFocusOffset();

    const contentState = editorState.getCurrentContent();
    const contenBlock = contentState.getBlockForKey(blockKeyStart);
    const text = contenBlock.getText();

    let cursor = start;
    let isLoop = true;
    while (isLoop) {
      if (--cursor < 0) {
        isLoop = false;
        break;
      }
      const beforeChar = text.slice(cursor, cursor + 1);
      if (!isSpaceChar(beforeChar) && !isPeriodChar(beforeChar)) {
        isLoop = false;
        break;
      }
      if (isSpaceChar(beforeChar)) continue;
      if (isPeriodChar(beforeChar)) {
        if (!hasHandledIdxPeriod(blockKeyStart, start)) {
          isLoop = false;
          return true;
        } else {
          isLoop = false;
          return false;
        }
      }
      isLoop = false;
      break;
    }

    return false;
  }

  function toProperCase(text: string, blockKey: string, idxPressedPeriod: number) {
    const pattern = new RegExp(`(${AUTO_CAPS_CHARS_SUPPORTED})(\\s{1,})(\\w)`, 'gm');
    const phaseWords = text.split('');
    const idxNeedUpperCase = [];

    let match;
    while ((match = pattern.exec(text)) !== null) {
      if (!hasHandledIdxPeriod(blockKey, match.index)) {
        const charIndex = match.index + match[1].length + 1;
        // just a word nearly period must capital
        if (charIndex >= idxPressedPeriod && idxNeedUpperCase.length === 0) {
          idxNeedUpperCase.push(charIndex);
        }
      }
    }

    idxNeedUpperCase.map((idx) => {
      phaseWords[idx] = phaseWords[idx].toUpperCase();
    });

    return phaseWords.join('');
  }

  function handleAutoFormatUpperOrLowerCase(editorState: EditorState) {
    let contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const anchorOffset = selectionState.getAnchorOffset();
    const focusOffset = selectionState.getFocusOffset();

    const blockKey = selectionState.getAnchorKey();
    const block = contentState.getBlockForKey(blockKey);
    const styles = block.getCharacterList();
    let start = selectionState.getStartOffset();

    const originalText = block.getText();
    const newBlockText = toProperCase(originalText, blockKey, start);
    const originalWords = originalText.split('');
    const newWords = newBlockText.split('');

    for (let idx = start; idx < newWords.length; idx++) {
      if (newWords[idx] === originalWords[idx]) {
        continue;
      }
      const newSelectionState = new SelectionState({
        anchorKey: blockKey,
        anchorOffset: idx,
        focusKey: blockKey,
        focusOffset: idx + 1
      });

      const newStart = newSelectionState.getStartOffset();
      const newBlockKey = newSelectionState.getAnchorKey();
      const newBlock = contentState.getBlockForKey(newBlockKey);
      const newEntity = newBlock.getEntityAt(newStart);

      contentState = Modifier.replaceText(
        contentState,
        newSelectionState,
        newWords[idx],
        styles.get(idx).getStyle(),
        newEntity
      );
      break;
    }

    editorState = EditorState.push(editorState, contentState, 'insert-characters');
    handleOnChange(
      EditorState.forceSelection(
        editorState,
        editorState.getSelection().merge({
          anchorOffset,
          focusOffset
        })
      )
    );
  }

  function handleBeforeInput(
    chars: string,
    editorState: EditorState,
    eventTimeStamp: number
  ): DraftHandleValue {
    var hasHandleSpaceChar = false;
    if (/\s/.test(chars)) {
      hasHandleSpaceChar = handleTimingPerWord(editorState);
    }

    if (!hasHandleSpaceChar) {
      const content = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const start = selection.getStartOffset();
      const inlineStyle = editorState.getCurrentInlineStyle();

      const blockKey = selection.getAnchorKey();
      const block = content.getBlockForKey(blockKey);
      let entity = block.getEntityAt(start);

      entity = determineBestEntityToAmend(entity, block, start);

      const newContent = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        hasAPeriodInsertedBefore(editorState) ? chars.toUpperCase() : chars,
        inlineStyle,
        entity
      );

      const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');

      if (/\./.test(chars)) handleAutoFormatUpperOrLowerCase(newEditorState);
      else {
        handleOnChange(newEditorState);
      }
    }
    return 'handled';
  }

  function handleFilterInlineStyle(
    blocks: DraftModel.ImmutableData.ContentBlock[],
    filterStyle = 'HIGHLIGHT',
    currentIndex: number
  ) {
    const blocksRelevantToStyle: TBlockFilter[] = [];

    blocks.map((block) => {
      const { characterList } = block.toJS();
      characterList.some((char: any) => {
        if (char.style.length && char.style.includes(filterStyle)) {
          blocksRelevantToStyle.push({ blockId: block.getKey(), index: currentIndex });
          return true;
        }
      });
    });

    return [...blocksRelevantToStyle];
  }

  function handleFilterBlockData(
    blocks: DraftModel.ImmutableData.ContentBlock[],
    filterStyle = 'FILTER_BLOCK_DATA_annotation',
    currentIndex: number
  ) {
    const blocksRelevantToData: TBlockFilter[] = [];
    const regex = /FILTER_BLOCK_DATA_(.*)/;
    const dataBlockProperty = filterStyle.match(regex);

    if (dataBlockProperty) {
      blocks.map((block) => {
        const { data } = block.toJS();
        if (data[dataBlockProperty[1]] && data.blockData !== null) {
          blocksRelevantToData.push({ blockId: block.getKey(), index: currentIndex });
        }
      });
    }

    return [...blocksRelevantToData];
  }

  function handleFilter(filterStylesString: string[]) {
    if (filterStylesString.length === 0 && blocksRelevantFilter.length === 0) {
      return null;
    }
    let newBlocksRelevantFilter: TBlockFilter[] = [];

    editorStateDivided.map((iEditorState, index) => {
      const contentState = iEditorState.getCurrentContent();
      const blocks = contentState.getBlockMap().toArray();
      const regex = /\FILTER_BLOCK_DATA_.*/;

      filterStylesString.map((filter: any) => {
        if (regex.test(filter)) {
          newBlocksRelevantFilter.push(...handleFilterBlockData(blocks, undefined, index));
        } else {
          newBlocksRelevantFilter.push(...handleFilterInlineStyle(blocks, undefined, index));
        }
      });
    });

    if (JSON.stringify(blocksRelevantFilter) !== JSON.stringify(newBlocksRelevantFilter)) {
      setBlocksRelevantFilter(newBlocksRelevantFilter);
      setEditorState(EditorState.forceSelection(editorState, editorState.getSelection()));
    } else {
      return null;
    }
  }

  const insertNewBlock = (arr: ContentBlock[], index: number, newItem: ContentBlock) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index)
  ];

  function addNewBlock(blockProps: DraftModel.ImmutableData.ContentBlock, blockKey?: string) {
    try {
      const currentTimeOnPlayer = roundToTwo(currentMedia?.duration || 0 / 1000);
      const editorMerged = mergeMultipleEditorStates(editorStateDivided);
      dispatch(setIsChangeTranscrip({ isChange: true }));

      const contentState = editorMerged.getCurrentContent();
      const blockMap = contentState.getBlockMap().toArray();
      let index = blockMap.findIndex((block) => block.get('key') === blockKey);
      index += 1;
      const isFirstBlock = index === 0;
      const isLastBlock = index === blockMap.length;

      const prevBlock = blockMap[index - 1];
      const nextBlock = blockMap[index];
      let prevBlockData = prevBlock ? prevBlock.getData().toJS() : {};
      let nextBlockData = nextBlock ? nextBlock.getData().toJS() : {};

      const prevEndTime = prevBlock ? Number(getEndTimeOfBlock(prevBlock, contentState)) : 0;
      const nextStartTime = nextBlock
        ? Number(getStartTimeOfBlock(nextBlock, contentState))
        : currentMedia?.duration || 0;

      if (isFirstBlock) prevBlockData = { startTime: 0, endTime: 0 };
      if (isLastBlock)
        nextBlockData = {
          startTime: prevEndTime,
          endTime: currentMedia?.duration
        };

      const isCreateNewBlockWithinRangeOfPlayback =
        currentTimeOnPlayer >= +prevEndTime && currentTimeOnPlayer <= +nextStartTime;

      const numGap = (nextStartTime - prevEndTime) / 3;

      let newStartTime = null;
      let newEndTime = null;
      const newText = 'Placeholder ';

      if (isCreateNewBlockWithinRangeOfPlayback) {
        newStartTime = currentTimeOnPlayer;
        newEndTime = nextStartTime;
      } else {
        newStartTime = +prevEndTime + numGap;
        newEndTime =
          +nextStartTime - numGap > (currentMedia?.duration || 0)
            ? currentMedia?.duration
            : +nextStartTime - numGap;
      }

      const newBlock = new ContentBlock({
        key: genKey(),
        type: 'TranscriptRow',
        text: ''
      });

      let newBlockMap = blockMap;
      if (!isFirstBlock && !isLastBlock) newBlockMap = insertNewBlock(blockMap, index, newBlock);
      if (isFirstBlock) newBlockMap = [newBlock, ...blockMap];
      if (isLastBlock) newBlockMap = [...blockMap, newBlock];

      const newContent = ContentState.createFromBlockArray(newBlockMap);

      newContent
        .set('selectionBefore', contentState.getSelectionBefore())
        .set('selectionAfter', contentState.getSelectionAfter());

      let newEditorState = EditorState.push(editorState, newContent, 'insert-fragment');

      let newContentState = newEditorState.getCurrentContent();
      let newSelectionState = editorState.getSelection().merge({
        anchorKey: newBlock.getKey(),
        focusKey: newBlock.getKey(),
        anchorOffset: newText.length,
        focusOffset: newText.length
      });

      const newEntity = newContentState.createEntity('TIME_CODED_WORD', 'MUTABLE', {
        startTime: newStartTime,
        endTime: newEndTime
      });

      newContentState = Modifier.applyEntity(
        newEntity,
        newSelectionState,
        newEntity.getLastCreatedEntityKey()
      );

      newContentState = Modifier.mergeBlockData(
        newContentState,
        newSelectionState,
        blockProps.getData()
      );

      newEditorState = EditorState.push(editorState, newContentState, 'apply-entity');
      newContentState = Modifier.insertText(
        newContentState,
        newSelectionState,
        newText,
        undefined,
        newEntity.getLastCreatedEntityKey()
      );
      newEditorState = EditorState.push(editorState, newContentState, 'change-block-data');

      const newTranscript: TTranscript[] = getLastTranscriptRows(newEditorState);
      setTotal(newTranscript.length);
      const transcriptDivided = divideArray(newTranscript, rowPerPage + 1);
      setTranscrisptDivided(transcriptDivided);
      const rawContent = createRawContent(newTranscript);
      if (rawContent) {
        const initialContentState = convertFromRaw(rawContent);
        const newState = EditorState.createWithContent(initialContentState);
        setCurrentPlainText(newState.getCurrentContent().getPlainText());
      }
      for (var i = 0; i <= transcriptDivided.length; i++) {
        setInitialDividedEditorState({ transcriptDivided, currentPage: i });
      }

      // handleOnChange(forceCompositeDecorator(newEditorState));
    } catch (error) {
      console.log('addNewBlock error', error);
      Sentry.captureMessage(JSON.stringify({ topic: 'addNewBlock', message: error }));
    }
  }

  function removeOnlyExclusiveInlineStyles(newStyle: string, editorState: EditorState) {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    if (!exclusiveStyle[newStyle] || !exclusiveStyle[newStyle].length) return contentState;
    return _.reduce(
      exclusiveStyle[newStyle],
      (newContentState, style) => {
        return Modifier.removeInlineStyle(newContentState, selection, style);
      },
      contentState
    );
  }

  function handleInlineStyle(newStyle: string) {
    try {
      let tmpEditorState = editorState;
      let contentState = tmpEditorState.getCurrentContent();
      let selectionState = tmpEditorState.getSelection();
      const inlineStyle = tmpEditorState.getCurrentInlineStyle();

      const blockKey = selectionState.getAnchorKey();
      let startSelection: number | null = selectionState.getStartOffset();
      let endSelection: number | null = selectionState.getEndOffset();
      const block = contentState.getBlockForKey(blockKey);
      const startEntity = block.getEntityAt(startSelection);
      const endEntity = block.getEntityAt(endSelection)
        ? block.getEntityAt(endSelection)
        : block.getEntityAt(endSelection - 1);
      const entityRangeStart = getEntityRange(tmpEditorState, startEntity);
      const entityRangeEnd = getEntityRange(tmpEditorState, endEntity);
      const text = block.getText();

      const wordsInSelection = text.slice(startSelection, endSelection).trim();
      if (wordsInSelection === '') {
        startSelection = null;
        endSelection = null;
      }

      if (entityRangeEnd && endSelection) {
        const lastedWordsAtEndSelection = text.slice(endSelection, entityRangeEnd.end);
        if (lastedWordsAtEndSelection.trim() === '') endSelection = entityRangeEnd.end;
      }

      if (entityRangeStart && startSelection) {
        const lastedWordsAtStartSelection = text.slice(startSelection, entityRangeStart.end);
        if (lastedWordsAtStartSelection.trim() === '') startSelection = null;
      }

      // apply style
      contentState = removeOnlyExclusiveInlineStyles(newStyle, editorState);
      // toggle-inline-style
      if (!inlineStyle.has(newStyle)) {
        contentState = Modifier.applyInlineStyle(contentState, selectionState, newStyle);
      } else {
        contentState = Modifier.removeInlineStyle(contentState, selectionState, newStyle);
      }

      tmpEditorState = EditorState.push(editorState, contentState, 'change-inline-style');
      setEditorState(tmpEditorState);
      setEditorDivided((prev: EditorState[]) => {
        const newStateArray = [...prev];
        newStateArray[currentPage - 1] = tmpEditorState;
        return newStateArray;
      });

      // split entity
      if (
        startSelection !== null &&
        entityRangeStart?.start !== startSelection &&
        entityRangeStart?.start !== entityRangeEnd?.start
      ) {
        const { startTime, endTime } = contentState.getEntity(startEntity).getData();
        const newEntity = contentState.createEntity('TIME_CODED_WORD', 'MUTABLE', {
          startTime,
          endTime
        });

        contentState = Modifier.applyEntity(
          newEntity,
          new SelectionState({
            anchorKey: blockKey,
            anchorOffset: entityRangeStart.start,
            focusKey: blockKey,
            focusOffset: startSelection
          }),
          newEntity.getLastCreatedEntityKey()
        );

        tmpEditorState = EditorState.push(editorState, contentState, 'apply-entity');
        setEditorState(tmpEditorState);
        setEditorDivided((prev: EditorState[]) => {
          const newStateArray = [...prev];
          newStateArray[currentPage - 1] = tmpEditorState;
          return newStateArray;
        });
      }

      if (
        entityRangeEnd &&
        entityRangeStart &&
        endSelection !== null &&
        entityRangeEnd?.end !== endSelection &&
        entityRangeStart?.start !== entityRangeEnd?.start
      ) {
        const { startTime, endTime } = contentState.getEntity(endEntity).getData();
        const newEntity = contentState.createEntity('TIME_CODED_WORD', 'MUTABLE', {
          startTime,
          endTime
        });

        contentState = Modifier.applyEntity(
          newEntity,
          new SelectionState({
            anchorKey: blockKey,
            anchorOffset: entityRangeEnd.start,
            focusKey: blockKey,
            focusOffset: endSelection
          }),
          newEntity.getLastCreatedEntityKey()
        );

        tmpEditorState = EditorState.push(editorState, contentState, 'apply-entity');
        setEditorState(tmpEditorState);
        setEditorDivided((prev: EditorState[]) => {
          const newStateArray = [...prev];
          newStateArray[currentPage - 1] = tmpEditorState;
          return newStateArray;
        });
      }

      if (
        entityRangeStart &&
        entityRangeEnd &&
        startSelection !== null &&
        endSelection !== null &&
        entityRangeStart?.start === entityRangeEnd?.start
      ) {
        const { startTime, endTime } = contentState.getEntity(startEntity).getData();

        const newEntity = contentState.createEntity('TIME_CODED_WORD', 'MUTABLE', {
          startTime,
          endTime
        });

        contentState = Modifier.applyEntity(
          newEntity,
          new SelectionState({
            anchorKey: blockKey,
            anchorOffset: startSelection,
            focusKey: blockKey,
            focusOffset: endSelection
          }),
          newEntity.getLastCreatedEntityKey()
        );

        tmpEditorState = EditorState.push(editorState, contentState, 'apply-entity');
        setEditorState(tmpEditorState);
        setEditorDivided((prev: EditorState[]) => {
          const newStateArray = [...prev];
          newStateArray[currentPage - 1] = tmpEditorState;
          return newStateArray;
        });
      }

      dispatch(setIsChangeTranscrip({ isChange: true }));
      const newState = EditorState.acceptSelection(tmpEditorState, selectionState);
      setEditorState(newState);
      setEditorDivided((prev: EditorState[]) => {
        const newStateArray = [...prev];
        newStateArray[currentPage - 1] = newState;
        return newStateArray;
      });
      // handleOnChange(tmpEditorState);
    } catch (error) {
      console.log('handleInlineStyle error', error);
      Sentry.captureMessage(JSON.stringify({ topic: 'handleInlineStyle', message: error }));
    }
  }

  function handleDictionaryClick() {
    try {
      let tmpEditorState = editorState;
      let contentState = tmpEditorState.getCurrentContent();
      let selectionState = tmpEditorState.getSelection();

      const blockKey = selectionState.getAnchorKey();
      let startSelection: number | null = selectionState.getStartOffset();
      let endSelection: number | null = selectionState.getEndOffset();
      const block = contentState.getBlockForKey(blockKey);
      const text = block.getText();

      const wordsInSelection = text.slice(startSelection, endSelection).trim();

      dispatch(
        pushModal({
          name: EModals.DICTIONARY_QUICK_ADD,
          data: {
            selectedText: wordsInSelection
          }
        })
      );
    } catch (error) {
      console.log('handleDictionary error', error);
      Sentry.captureMessage(JSON.stringify({ topic: 'handleDictionary', message: error }));
    }
  }

  function handleReturn(e: any, editorState: EditorState) {
    if (e.shiftKey && e.keyCode == 13) {
      handleOnChange(RichUtils.insertSoftNewline(editorState));
      return 'handled';
    } else {
      const selectionState = editorState.getSelection();
      const contentState = editorState.getCurrentContent();

      const start = selectionState.getStartOffset();
      const end = selectionState.getEndOffset();

      const blockKeyContainingEnd = selectionState.getEndKey();
      const contentBlockForEnd = contentState.getBlockForKey(blockKeyContainingEnd);

      if (end === contentBlockForEnd.getLength() || start === 0) {
        return 'handled';
      } else {
        setAutoScroll(false);
      }
    }

    return 'not-handled';
  }

  useEffect(() => {
    if (!selectedSpeakerId) return;

    let contentState = editorState.getCurrentContent();
    let blocks = contentState.getBlockMap().toArray();

    let firstBlockFound = blocks.find((b) => {
      let json = b.getData()?.get('speaker');
      try {
        var object = JSON.parse(json);
      } catch (e) {
        object = null;
      }
      return object && object.id == selectedSpeakerId;
    });

    if (firstBlockFound) {
      let key = firstBlockFound.getKey();
      let element = document.querySelector(`div[data-offset-key="${key}-0-0"`);
      if (element) element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [selectedSpeakerId]);

  if (isLoading) return null;

  if ((!currentTranscription || currentTranscription.length === 0) && mediaId)
    return (
      <>
        <div className="no-data d-flex justify-content-center align-items-center">
          {t('Pages.TranscriptDetail.PS4.FileEmptyOrSilent')}
        </div>
      </>
    );

  return (
    <>
      <SearchTranscript
        nextSelectionToReplace={nextSelectionToReplace}
        prevSelectionToReplace={prevSelectionToReplace}
        handleReplace={handleReplace}
        handleReplaceAll={handleReplaceAll}
        searchInput={searchInput}
        hasCaseSensitive={hasCaseSensitive}
        setHasCaseSensitive={setHasCaseSensitive}
        replaceText={replaceText}
        setReplaceText={setReplaceText}
        onSearch={handleSearch}
      />
      <div
        className="ctn-ps4 py-1 rounded border position-relative"
        onClick={(e) => {
          setIsReadonly(false);
          e.stopPropagation();
        }}
        ref={ctnPS4Ref}
      >
        <Editor
          ref={editorRef}
          editorState={editorState}
          onChange={handleOnChange}
          handleKeyCommand={handleKeyComment}
          keyBindingFn={handleKeyBindingFn}
          blockRendererFn={handleBlockRendererFn}
          handleReturn={handleReturn}
          plugins={plugins}
          customStyleMap={customStyleMap}
          readOnly={isReadOnly || isNoneEdit || isLock}
          handleBeforeInput={handleBeforeInput}
          textDirectionality={isTextRightToLeft ? 'RTL' : 'LTR'}
        />
        <InlineToolbar>
          {(externalProps) => (
            <>
              {Object.keys(visibilityInlineToolbars).map((key) =>
                getInlineToolbarComps({
                  keyComp: key,
                  props: externalProps,
                  onClick: () => {
                    switch(key) {
                      case EStyle.DICTIONARY: 
                        return handleDictionaryClick();
                      default: return handleInlineStyle(EStyle[key as keyof typeof EStyle]);
                    }
                    
                  }
                })
              )}
            </>
          )}
        </InlineToolbar>
        {!isAutoScroll && (
          <div
            className="d-flex justify-content-center"
            style={{
              position: 'sticky',
              bottom: '10px',
              pointerEvents: 'none'
            }}
          >
            <div
              className="p-1 rounded-1 cursor-pointer"
              style={{
                backgroundColor: 'var(--si-primary)',
                color: 'var(--si-white)',
                pointerEvents: 'auto'
              }}
              onClick={() => {
                setAutoScroll(true);
              }}
            >
              {t('Pages.TranscriptDetail.PS4.ResumeAutoScroll')}
            </div>
          </div>
        )}
      </div>
      {!isLoading && total > rowPerPage && (
        <div className="mt-1 d-flex justify-content-center">
          <Pagination
            currentPage={currentPage}
            pageSize={rowPerPage}
            // setPageSize={setRowPerPage}
            total={total}
            setCurrentPage={setCurrentPage}
            activePage={blocksRelevantFilter.map((i) => i.index + 1)}
          />
        </div>
      )}
    </>
  );
}

export default PS4;
