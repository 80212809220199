import { getPlan } from 'apis/subscribe.api';
import { PlanItem } from 'components/Admin/PlanItem';
import { useLoaderContext } from 'context/LoaderContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from 'ts/enums';
import { TPlan } from 'ts/types';

function PlansPage() {
  const [plans, setPlans] = useState<TPlan[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loader } = useLoaderContext();

  useEffect(() => {
    (async () => {
      loader.start;
      let listPlans = await getPlan();
      listPlans.sort((a: TPlan, b: TPlan) => {
        if (a.interval == null) return -1;
        if (b.interval == null) return 1;
        if (a.interval == 'year' && b.interval == 'month') return 1;
        if (a.interval == 'month' && b.interval == 'year') return -1;
        return 0;
      });
      listPlans.sort((a: TPlan, b: TPlan) => {
        if (a.interval == b.interval) {
          if (a.type == 'COMPANY' && b.type == 'INDIVIDUAL') return 1;
          if (a.type == 'INDIVIDUAL' && b.type == 'COMPANY') return -1;
        }
        return 0;
      });
      loader.complete();
      setPlans(listPlans);
    })();
  }, []);

  return (
    <div>
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome
            size={30}
            color="var(--si-primary)"
            onClick={() => navigate(ERoutes.LASTEST_TRANSACTION)}
            className="cursor-pointer"
          />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            {t('Pages.Admin.Plans.Title')}
          </span>
        </div>
      </div>
      <div className="container py-2">
        <div className="row mb-3">
          <div className="col-12 col-lg-6">
            <div className="plan-item --active">
              <div className="title">{t('Shared.Fields.Active')}</div>
              <div className="content">{t('Pages.Admin.Plans.ActiveDescription')}</div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-2 mt-lg-0">
            <div className="plan-item --disable">
              <div className="title">{t('Shared.Fields.Inactive')}</div>
              <div className="content">{t('Pages.Admin.Plans.InactiveDescription')}</div>
            </div>
          </div>
        </div>
        {plans.map((plan) => (
          <PlanItem key={plan.id} plan={plan} />
        ))}
      </div>
    </div>
  );
}

export default PlansPage;
