import { Button, Modal, Select, notification } from 'antd';
import { changeMedia, deleteMedia, retryTranscoding } from 'apis/media.api';
import { InputTime } from 'components/common/InputTime';
import { usePageLoading } from 'context/PageLoadingContext';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose, AiOutlineWarning } from 'react-icons/ai';
import { TMedia } from 'ts/types';
import { convertTime } from 'utils/time';

type Props = {
  file: TMedia;
  index: number;
  onSuccess?: () => void;
  readOnly?: boolean;
};

type OptionFrameRate = {
  id: number;
  frameValue: number;
  frameDisplay: string;
};

const ListOptionFrameRate: OptionFrameRate[] = [
  { id: 0, frameDisplay: '23.976', frameValue: 23.976 },
  { id: 1, frameDisplay: '24', frameValue: 24 },
  { id: 2, frameDisplay: '25', frameValue: 25 },
  { id: 3, frameDisplay: '29.97DF', frameValue: 29.97 },
  { id: 4, frameDisplay: '29.97NDF', frameValue: 29.97 },
  { id: 5, frameDisplay: '30', frameValue: 30 },
  { id: 6, frameDisplay: '50', frameValue: 50 },
  { id: 7, frameDisplay: '59.94DF', frameValue: 59.94 },
  { id: 8, frameDisplay: '59.94NDF', frameValue: 59.94 },
  { id: 9, frameDisplay: '60', frameValue: 60 }
];

function CardFile({ file, index, onSuccess, readOnly = false }: Props) {
  const [fpsSelected, setFPSSelected] = useState(file.fps);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { openMessage, destroyMessage } = usePageLoading();
  const { t } = useTranslation();

  async function handleUpdateFile() {
    const dataUpdata: TMedia = {
      ...file,
      fps: fpsSelected
    };
    try {
      destroyMessage();
      openMessage('loading', t('Shared.Texts.UpdatingW3Dot'));
      const res = await changeMedia({ media: dataUpdata });
      if (res) {
        destroyMessage();
        openMessage('success', t('Shared.Texts.Updated'));
        onSuccess && onSuccess();
      }
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleRetryTranscoding() {
    try {
      destroyMessage();
      openMessage('loading', t('Shared.Texts.RetryTranscodingW3Dot'));
      const res = await retryTranscoding({ mediaId: file.id });
      if (res) {
        destroyMessage();
        openMessage('success', t('Shared.Texts.Success'));
        onSuccess && onSuccess();
      }
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleDeleteFile() {
    try {
      destroyMessage();
      openMessage('loading', t('Shared.Texts.DeletingW3Dot'));
      await deleteMedia({ mediaId: file.id });
      destroyMessage();
      openMessage('success', t('Shared.Texts.Deleted'));
      onSuccess && onSuccess();
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  return (
    <div>
      <div className="row g-2 mb-3 pb-3 rounded" style={{ border: '1px solid var(--si-primary)' }}>
        <div className="col-12 col-md-6">
          <div className="mb-1">
            <small className="fw-bold">
              {t('Shared.Fields.File')} {index + 1} /{' '}
              <a
                href={file.originalFileUrl}
                target="_blank"
                rel="noreferrer"
                className="custom-a-tag"
              >
                {t('Shared.Fields.S3Url')}
              </a>
            </small>
          </div>
          <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
            {file.name}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="mb-1">
            <small className="fw-bold">{t('Shared.Fields.ID')}</small>
          </div>
          <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
            {file.id}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="mb-1">
            <small className="fw-bold">{t('Shared.Fields.Length')}</small>
          </div>
          <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
            {convertTime(file.duration)}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="mb-1">
            <small className="fw-bold">{t('Shared.Fields.Status')}</small>
          </div>
          <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
            {file.status}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="mb-1">
            <small className="fw-bold">{t('Shared.Fields.Timecode')} (HH:MM:SS:FF)</small>
          </div>
          <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
            <InputTime
              userDefinedTimeCode={file.userDefinedTimeCode}
              frameVideo={file.userDefinedTimeCodeFrames}
              isOneInput
              readOnly
              timeCode={file.timeCode}
              isDropFrame={file.timeCode?.includes(';')}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="mb-1">
            <small className="fw-bold">{t('Shared.Fields.FPS')}</small>
          </div>
          <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
            {readOnly ? (
              <span>{file.fps}</span>
            ) : (
              <Select
                className="w-100"
                suffixIcon={null}
                value={fpsSelected}
                onSelect={(value) => {
                  setFPSSelected(value);
                }}
                placeholder={t('Pages.Admin.ProjectDetail.SelectFPS')}
                optionFilterProp="children"
                options={ListOptionFrameRate.map((fps) => {
                  return {
                    label: fps.frameDisplay,
                    value: fps.frameValue,
                    key: fps.id
                  };
                })}
              />
            )}
          </div>
        </div>
        {!readOnly && (
          <div>
            {/* <Button type="primary" onClick={handleRetryTranscoding}>
              {t('Shared.Actions.RetryTranscoding')}
            </Button> */}
            <Button
              onClick={handleUpdateFile}
              className="mx-1"
              style={{ background: 'var(--bs-success)', color: 'white' }}
            >
              {t('Shared.Actions.Update')}
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {t('Shared.Actions.Delete')}
            </Button>
            <Modal
              open={isOpen}
              width={300}
              onOk={() => {
                handleDeleteFile();
              }}
              onCancel={() => {
                setIsOpen(false);
              }}
              mask={false}
              closeIcon={<AiOutlineClose color="var(--si-white)" />}
              footer={[
                <button
                  className="no-button"
                  key={'no'}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t('Shared.Texts.No')}
                </button>,
                <button
                  className="yes-button"
                  key={'yes'}
                  onClick={() => {
                    setIsOpen(false);
                    handleDeleteFile();
                  }}
                >
                  {t('Shared.Texts.Yes')}
                </button>
              ]}
              wrapClassName="modal-delete-folder"
            >
              <div className="d-flex">
                <div className="d-flex align-items-center me-1">
                  <AiOutlineWarning size={30} color="var(--si-white)" />
                </div>
                <div className="warning-text">{t('Pages.Admin.ProjectDetail.DeletionConfirm')}</div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
}

export default CardFile;
