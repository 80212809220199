import { message } from 'antd';
import { stripeCouponApply, stripeCouponCheckAvailable } from 'apis/serverless.api';
import { deleteMyPlan, getPlan } from 'apis/subscribe.api';
import { ModalBase, SubscribeBody } from 'components/common';
import { Loader } from 'components/common/Loader';
import { usePageLoading } from 'context/PageLoadingContext';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { popModal, pushModal, setChangePlanModalDefaultOption } from 'redux/modal/modal.slice';
import { EChangePlanStep, EChangePlanOption, EModals, ERoutes, ETypeNoti } from 'ts/enums';
import { TPlan } from 'ts/types';
import { convertCreditToString } from 'utils/time';

export function ChooserOptionsPlanModal() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.currentUser.data);
  const changePlanModalDefaultOption = useAppSelector(
    (state) => state.modal.customData.changePlanModalDefaultOption
  );
  const [selectedOption, setSelectedOption] = useState<EChangePlanOption>(EChangePlanOption.NULL);
  const [step, setStep] = useState<EChangePlanStep>(EChangePlanStep.CONFIRM);
  const [deleting, setDeleting] = useState<boolean>(false);
  const { openMessage, destroyMessage } = usePageLoading();
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedOption(changePlanModalDefaultOption || EChangePlanOption.NULL);
  }, [changePlanModalDefaultOption]);

  useEffect(() => {
    let formHandler = async (event: MessageEvent) => {
      if (event.data.type == 'form-submit') {
        if (event.data.formId === 'llvNZn') {
          // Checking if can claim final offer?
          if (currentUser) {
            openMessage('loading', t('Shared.Texts.LoadingW3Dot'));
            stripeCouponCheckAvailable(currentUser?.email, '50PER_OFF_3MO')
              .then((data) => {
                destroyMessage();
                if (data.allowActivation == true) setStep(EChangePlanStep.FINAL_OFFER);
                else onClickTrulyCancel();
              })
              .catch((err) => {
                destroyMessage();
                onClickTrulyCancel();
              });
          }
        }
      }
    };

    window.addEventListener('message', formHandler, false);

    return () => {
      window.removeEventListener('message', formHandler, false);
    };
  }, [currentUser]);

  const onClickTrulyCancel = async () => {
    if (deleting) return;

    openMessage('loading', t('Shared.Texts.LoadingW3Dot'));
    setDeleting(true);
    if (await deleteMyPlan()) {
      setDeleting(false);
      destroyMessage();
      dispatch(popModal());
      dispatch(setChangePlanModalDefaultOption(null));
      setSelectedOption(EChangePlanOption.NULL);
      setStep(EChangePlanStep.CONFIRM);

      //message.success(t('Pages.AccountSetting.Profile.ChangePlanModal.PlanCanceled'), );
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: {
            type: ETypeNoti.SUCCESS,
            title: t('Pages.AccountSetting.Profile.ChangePlanModal.PlanCanceled')
          }
        })
      );
    } else {
      setDeleting(false);
      destroyMessage();
      message.error(t('Shared.Errors.SomethingWentWrong'));
    }
  };

  const onClickClaimOffer = async () => {
    openMessage('loading', t('Shared.Texts.LoadingW3Dot'));

    if (currentUser) {
      stripeCouponApply(currentUser?.email, '50PER_OFF_3MO')
        .then((data) => {
          destroyMessage();
          dispatch(popModal());
          dispatch(setChangePlanModalDefaultOption(null));
          setSelectedOption(EChangePlanOption.NULL);
          setStep(EChangePlanStep.CONFIRM);

          dispatch(
            pushModal({
              name: EModals.NOTI_MODAL,
              data: null,
              notiData: {
                type: ETypeNoti.SUCCESS,
                title: t('Pages.AccountSetting.Profile.ChangePlanModal.FinalOfferClaimed')
              }
            })
          );
        })
        .catch((err) => {
          destroyMessage();
          message.error(t('Shared.Errors.SomethingWentWrong'));
        });
    }
  };

  const RenderOption = useCallback(() => {
    switch (selectedOption) {
      case EChangePlanOption.CANCEL_PLAN:
        return (
          <>
            {step === EChangePlanStep.CANCEL_DELETED ? (
              <div
                className="p-3 border-bottom fw-bold"
                style={{ backgroundColor: 'var(--bs-gray-200)', color: 'var(--si-primary)' }}
              >
                {t('Shared.Texts.ThanksForFeedback')}
              </div>
            ) : (
              <div
                onClick={() => {
                  if (deleting) return;

                  dispatch(popModal());
                  dispatch(setChangePlanModalDefaultOption(null));
                  setSelectedOption(EChangePlanOption.NULL);
                  setStep(EChangePlanStep.CONFIRM);
                }}
                className="p-3 border-bottom fw-bold cursor-pointer d-flex align-items-center"
                style={{ backgroundColor: 'var(--bs-gray-200)', color: 'var(--si-primary)' }}
              >
                <IoMdArrowRoundBack size={25} color="var(--si-primary)" className="me-1" />{' '}
                {t('Pages.AccountSetting.Profile.ChangePlanModal.GoBackChangedMyMind')}
              </div>
            )}
            <div className="p-3">
              {step === EChangePlanStep.CANCEL_SURVEY && (
                <iframe
                  width={'100%'}
                  height={600}
                  src={`https://simonsaysai.typeform.com/to/llvNZn?typeform-embed=embed-widget&typeform-source=dev.simonsaysai.com&typeform-medium=embed-sdk&embed-hide-footer=true&embed-hide-headers=true&embed-opacity=50&typeform-embed-id=ul66f#email=${currentUser?.email}`}
                />
              )}
              {step === EChangePlanStep.CONFIRM && (
                <>
                  <h6 className="mb-3" style={{ fontWeight: 'bold' }}>
                    {t('Pages.AccountSetting.Profile.ChangePlanModal.CancelConfirmTitle')}
                  </h6>
                  <p style={{ color: 'var(--bs-code-color)', fontWeight: 'bold' }}>
                    {t('Pages.AccountSetting.Profile.ChangePlanModal.ConfirmSubTitle', {
                      credit:
                        currentUser &&
                        convertCreditToString(
                          currentUser?.credit + currentUser.subscriptionCredit,
                          t
                        )
                    })}
                  </p>
                  <p className="mb-3" style={{ color: 'var(--bs-code-color)' }}>
                    {t('Pages.AccountSetting.Profile.ChangePlanModal.ConfirmText')}
                  </p>

                  <div
                    className="d-flex justify-content-start align-items-center gap-3"
                    style={{ color: 'var(--si-primary)' }}
                  >
                    <div
                      className="action action-lg cursor-pointer"
                      onClick={() => {
                        dispatch(popModal());
                        dispatch(setChangePlanModalDefaultOption(null));
                        setSelectedOption(EChangePlanOption.NULL);
                        setStep(EChangePlanStep.CONFIRM);
                      }}
                    >
                      {t('Pages.AccountSetting.Profile.ChangePlanModal.ConfirmNo')}
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => setStep(EChangePlanStep.CANCEL_SURVEY)}
                    >
                      {t('Pages.AccountSetting.Profile.ChangePlanModal.ConfirmYes')}
                    </div>
                  </div>
                </>
              )}
              {step === EChangePlanStep.FINAL_OFFER && (
                <>
                  <div className="position-relative">
                    <div
                      className="position-absolute"
                      style={{ top: 'calc(50% - 16px)', left: 'calc(50% - 16px)' }}
                    >
                      {deleting && <Loader size="large" />}
                    </div>
                  </div>
                  <h6 className="mb-3">
                    {t('Pages.AccountSetting.Profile.ChangePlanModal.FinalOfferSummary')}
                  </h6>
                  <p className="mb-1" style={{ fontWeight: 'bold', fontSize: '1.25em' }}>
                    🎬 {t('Pages.AccountSetting.Profile.ChangePlanModal.FinalOfferTitle')}
                  </p>
                  <p
                    className="mb-1"
                    style={{
                      color: 'var(--bs-code-color)',
                      fontWeight: 'bold',
                      fontSize: '1.25em'
                    }}
                  >
                    {t('Pages.AccountSetting.Profile.ChangePlanModal.FinalOfferSubTitle')}
                  </p>
                  <p className="mb-3" style={{ color: 'var(--bs-code-color)' }}>
                    <Trans
                      t={t}
                      i18nKey="Pages.AccountSetting.Profile.ChangePlanModal.FinalOfferText"
                      tOptions={{
                        money: (currentUser?.plan.subscriptionCharge || 0) / 2,
                        plan: currentUser?.plan.fullLabel
                      }}
                      components={{ linebreak: <br />, b: <b /> }}
                    />
                  </p>
                  <div
                    className="d-flex justify-content-start align-items-center gap-3"
                    style={{ color: 'var(--si-primary)' }}
                  >
                    <div className="action action-lg cursor-pointer" onClick={onClickClaimOffer}>
                      {t('Pages.AccountSetting.Profile.ChangePlanModal.ClaimOffer')}
                    </div>
                    <div className="cursor-pointer" onClick={onClickTrulyCancel}>
                      {t('Pages.AccountSetting.Profile.ChangePlanModal.FinishCanceling')}
                    </div>
                  </div>
                </>
              )}
              {/* step === EChangePlanStep.CANCEL_DELETED && <div className='position-relative'>
                  <div className='position-absolute' style={{ top: 'calc(50% - 16px)', left: 'calc(50% - 16px)' }}>{deleting && <Loader size='large' />}</div>
                  <div style={{ fontSize: 20 }}>
                    <Trans t={t} i18nKey="Pages.AccountSetting.Profile.ChangePlanModal.SadToSeeYouGo">
                      Here is the <span style={{ color: 'var(--si-primary)' }}>Text</span>
                    </Trans>
                  </div>
                </div>
              */}
            </div>
          </>
        );

      case EChangePlanOption.DOWNGRADE_PLAN:
        return (
          <>
            <div
              onClick={() => {
                dispatch(popModal());
                dispatch(setChangePlanModalDefaultOption(null));
                setSelectedOption(EChangePlanOption.NULL);
                setStep(EChangePlanStep.CONFIRM);
              }}
              className="p-3 border-bottom fw-bold cursor-pointer"
              style={{ backgroundColor: 'var(--bs-gray-200)', color: 'var(--si-primary)' }}
            >
              {t('Pages.AccountSetting.Profile.ChangePlanModal.GoBackChangedMyMind')}
            </div>

            <div className="p-3">
              {step ? (
                <SubscribeBody type="downgrade" />
              ) : (
                <>
                  <h6 className="mb-3" style={{ fontWeight: 'bold' }}>
                    {t('Pages.AccountSetting.Profile.ChangePlanModal.CancelConfirmTitle')}
                  </h6>
                  <p style={{ color: 'var(--bs-code-color)', fontWeight: 'bold' }}>
                    {t('Pages.AccountSetting.Profile.ChangePlanModal.ConfirmSubTitle', {
                      credit:
                        currentUser &&
                        convertCreditToString(
                          currentUser?.credit + currentUser.subscriptionCredit,
                          t
                        )
                    })}
                  </p>
                  <p className="mb-3" style={{ color: 'var(--bs-code-color)' }}>
                    {t('Pages.AccountSetting.Profile.ChangePlanModal.ConfirmText')}
                  </p>

                  <div
                    className="d-flex justify-content-start align-items-center gap-3"
                    style={{ color: 'var(--si-primary)' }}
                  >
                    <div
                      className="action action-lg cursor-pointer"
                      onClick={() => {
                        dispatch(popModal());
                        dispatch(setChangePlanModalDefaultOption(null));
                        setSelectedOption(EChangePlanOption.NULL);
                      }}
                    >
                      {t('Pages.AccountSetting.Profile.ChangePlanModal.ConfirmNo')}
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => setStep(EChangePlanStep.DOWNGRADE_CONFIRMED)}
                    >
                      {t('Pages.AccountSetting.Profile.ChangePlanModal.ConfirmDowngradePlan')}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        );

      case EChangePlanOption.UPGRADE_PLAN:
        return (
          <>
            <div
              onClick={() => {
                dispatch(popModal());
                setSelectedOption(EChangePlanOption.NULL);
                setStep(EChangePlanStep.CONFIRM);
              }}
              className="p-3 border-bottom fw-bold cursor-pointer"
              style={{ backgroundColor: 'var(--bs-gray-200)', color: 'var(--si-primary)' }}
            >
              {t('Pages.AccountSetting.Profile.ChangePlanModal.GoBackChangedMyMind')}
            </div>

            <div className="p-3">
              <SubscribeBody type="upgrade" />
            </div>
          </>
        );

      default:
        return (
          <>
            <div
              className="p-3 border-bottom fw-bold"
              style={{ backgroundColor: 'var(--bs-gray-200)' }}
            >
              {t('Shared.Fields.CurrentPlan')}: <span>{currentUser?.plan.fullLabel}</span>
            </div>
            <div className="d-flex gap-1 p-3">
              <button
                onClick={() => {
                  dispatch(popModal());
                }}
                className="p-1 rounded shadow"
                style={{ backgroundColor: 'var(--bs-success)', color: 'var(--si-white)' }}
              >
                {t('Pages.AccountSetting.Profile.ChangePlanModal.KeepCurrent')}
              </button>
              <button
                onClick={() => setSelectedOption(EChangePlanOption.CANCEL_PLAN)}
                className="p-1 rounded shadow"
                style={{ backgroundColor: 'var(--bs-danger)', color: 'var(--si-white)' }}
              >
                {t('Shared.Actions.Cancel')}
              </button>
              {!currentUser?.team && (
                <button
                  onClick={() => setSelectedOption(EChangePlanOption.DOWNGRADE_PLAN)}
                  className="p-1 rounded shadow"
                  style={{ backgroundColor: 'var(--bs-danger)', color: 'var(--si-white)' }}
                >
                  {t('Shared.Actions.Downgrade')}
                </button>
              )}

              <button
                onClick={() => setSelectedOption(EChangePlanOption.UPGRADE_PLAN)}
                className="p-1 rounded shadow"
                style={{ backgroundColor: 'var(--bs-warning)', color: 'var(--si-white)' }}
              >
                {t('Shared.Actions.Upgrade')}
              </button>
            </div>
          </>
        );
    }
  }, [selectedOption, currentUser, step, deleting]);

  return (
    <ModalBase
      modalName={EModals.CHOOSER_OPTIONS_PLAN_MODAL}
      onCloseModal={() => {
        dispatch(popModal());
        setStep(EChangePlanStep.CONFIRM);
        setSelectedOption(EChangePlanOption.NULL);
        dispatch(setChangePlanModalDefaultOption(null));
      }}
      className="chooser-options-plan-modal"
    >
      <h4 className="text-center fw-medium mb-3">
        {t('Pages.AccountSetting.Profile.ChangePlanModal.Title')}
      </h4>
      <div className="border">
        <RenderOption />
      </div>
    </ModalBase>
  );
}
