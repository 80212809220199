import React, { useState } from 'react';
import { HiDotsVertical, HiUserGroup } from 'react-icons/hi';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { FaClock, FaDownload } from 'react-icons/fa';
import { BookmarkFilter } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { changeFilterStyle } from 'redux/transcription/transcription.slice';
import { pushModal } from 'redux/modal/modal.slice';
import { EModals, EStyleFilter } from 'ts/enums';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TranscriptConfigPopOver } from 'components/TranscriptPage/TranscriptConfigPopOver';
import { useEditorContext } from 'context/EditorContext';

export function MobileHeader() {
  const [searchParams] = useSearchParams();
  const mediaId = searchParams.get('mediaId');
  const currentProject = useAppSelector((state) => state.projects.currentProject);
  const dispatch = useAppDispatch();
  const [openTranscriptConfig, setOpenTransciptConfig] = useState<boolean>(false);
  const { isLock } = useEditorContext();
  const { t } = useTranslation();
  const currentFilters = useAppSelector((state) => state.transcription.currentFilters);

  const items: MenuProps['items'] = [
    {
      label: (
        <div>
          <div
            className="d-flex align-items-center gap-1 p-1"
            onClick={() => {
              if (!isLock) setOpenTransciptConfig((prev) => !prev);
            }}
          >
            <FaClock size={22} color="var(--si-primary)" />
            <span>{t('Shared.Fields.Timecode')}</span>
          </div>
        </div>
      ),
      key: '0'
    },
    {
      label: (
        <div
          className="d-flex align-items-center gap-1 p-1"
          onClick={() => {
            dispatch(
              pushModal({
                name: EModals.SHARE_PROJECT_MODAL,
                data: { project: currentProject.data }
              })
            );
          }}
        >
          <HiUserGroup size={24} color="var(--si-primary)" />
          <span>{t('Shared.Actions.Share')}</span>
        </div>
      ),
      key: '1'
    },
    {
      label: (
        <div
          className="d-flex align-items-center gap-1 p-1"
          onClick={() => {
            dispatch(pushModal({ name: EModals.EXPORT_MODAL, data: { mediaId } }));
          }}
        >
          <FaDownload size={24} color="var(--si-primary)" />
          <span>{t('Shared.Actions.Export')}</span>
        </div>
      ),
      key: '3'
    },
    {
      label: (
        <div
          className="d-flex align-items-center gap-1 p-1"
          onClick={() => {
            dispatch(changeFilterStyle({ filterStyle: EStyleFilter.HIGHLIGHT }));
          }}
        >
          <BookmarkFilter
            width="24"
            height="25"
            color={
              currentFilters.includes(EStyleFilter.HIGHLIGHT)
                ? 'var(--si-bright-yellow)'
                : 'var(--si-primary)'
            }
          />
          <span>{t('Pages.TranscriptDetail.Texts.BookmarkFilter')}</span>
        </div>
      ),
      key: '4'
    }
  ];

  return (
    <div className="mobile-header">
      <Dropdown menu={{ items }} trigger={['click']}>
        <a onClick={(e) => e.preventDefault()}>
          <HiDotsVertical size={24} color="var(--si-primary)" />
        </a>
      </Dropdown>
      <TranscriptConfigPopOver isOpen={openTranscriptConfig} setIsOpen={setOpenTransciptConfig}>
        <></>
      </TranscriptConfigPopOver>
    </div>
  );
}
