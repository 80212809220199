import { TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from '../axios-client';
import { TBlackWhiteList, TConfig } from 'ts/types';

export async function getBlackWhiteList(): Promise<TResponse<TBlackWhiteList[]>> {
  const res = await AxiosCustom.get(`admin/blacklist-whitelist`);
  return res.data;
}

export async function editBlackWhiteList({
  id,
  blackListIds,
  blackListAll,
  whiteListIds,
  whiteListAll,
  blackListIps
}: {
  id: number;
  blackListIds?: string;
  blackListAll?: 0 | 1;
  whiteListIds?: string;
  whiteListAll?: 0 | 1;
  blackListIps?: string;
}): Promise<TResponse<TBlackWhiteList>> {
  const res = await AxiosCustom.post(`admin/blacklist-whitelist`, {
    id,
    blackListIds,
    blackListAll,
    whiteListIds,
    whiteListAll,
    blackListIps
  });
  return res.data;
}

export async function deleteBlackWhiteList({
  id,
  blackListIds,
  whiteListIds,
  blackListIps
}: {
  id: number;
  blackListIds?: string;
  whiteListIds?: string;
  blackListIps?: string;
}): Promise<TResponse<TBlackWhiteList>> {
  const res = await AxiosCustom.post(`admin/blacklist-whitelist/delete`, {
    id,
    blackListIds,
    whiteListIds,
    blackListIps
  });
  return res.data;
}

export async function getConfig(): Promise<TResponse<TConfig>> {
  const res = await AxiosCustom.get(`admin/config`);
  return res.data;
}

export async function editConfig({ config }: { config: TConfig }): Promise<TResponse<TConfig>> {
  const res = await AxiosCustom.put(`admin/config`, config);
  return res.data;
}
