/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TMedia } from 'ts/types';

type MediaState = {
  currentMedia: TMedia | null;
};

const initialState: MediaState = {
  currentMedia: null
};

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setMedia: (state, { payload }: PayloadAction<{ media: TMedia | null }>) => {
      state.currentMedia = payload.media;
    }
  }
});

export const { setMedia } = mediaSlice.actions;

export default mediaSlice.reducer;
