import { useUpdateAppHeightOnResize } from 'hooks/useUpdateHeightOnResize';
import React, { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from 'router';
import { store } from 'redux/store';
import { PageLoader } from 'components/common/Loader';
import { AuthProvider } from 'context/AuthContext';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import { PageLoadingProvider } from 'context/PageLoadingContext';
import { createBrowserHistory as history } from 'history';
import { WebSocketConnectionProvider } from 'context/WebSocketConnectionContext';
import { CreditCardProvider } from 'context/CreditCardContext';
import { TimeProvider } from 'context/TimeContext';
import { CookiesProvider } from 'react-cookie';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import AppLanguages from 'lang';

import { CustomAnalyticsProvider } from 'context/CustomAnalytics';
import { notification } from 'antd';
import { LoaderProvider } from 'context/LoaderContext';
import { Crisp } from 'crisp-sdk-web';

// Object.assign(global, { WebSocket });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onRedirectCallback = (appState: any) => {
  history().push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const auth0ProviderConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  onRedirectCallback,
  authorizationParams: {
    responseType: process.env.REACT_APP_AUTH0_RESPONSE_TYPE,
    leeway: process.env.REACT_APP_AUTH0_LEEWAY,
    scope: process.env.REACT_APP_AUTH0_SCOPE,
    redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_API || window.location.origin,
    ...(process.env.REACT_APP_AUTH0_AUDIENCE
      ? { audience: process.env.REACT_APP_AUTH0_AUDIENCE }
      : null)
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: AppLanguages,
    //lng: "vi", // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const CRISP_WEBSITE_ID = '44e2d8b1-7ee7-418a-b185-b82ffb4c7d1d';
Crisp.configure(CRISP_WEBSITE_ID);

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener('offline', (event) => {
      // controllerAxiosRequest.abort();
      notification.open({
        type: 'error',
        message: t('Shared.Errors.ConnectionListEditNotSaveNowReconnecting')
      });
    });

    window.addEventListener('online', (event) => {
      notification.open({ type: 'success', message: t(`Shared.Texts.WeAreBackOnline`) });
    });

    return () => {
      window.removeEventListener('offline', () => {});
      window.removeEventListener('online', () => {});
    };
  }, [t]);

  useUpdateAppHeightOnResize();
  return (
    <>
      <CustomAnalyticsProvider>
        <Auth0Provider {...auth0ProviderConfig}>
          <WebSocketConnectionProvider>
            <CookiesProvider>
              <Provider store={store}>
                <PageLoadingProvider>
                  <Suspense fallback={<PageLoader />}>
                    <AuthProvider>
                      <CreditCardProvider>
                        <TimeProvider>
                          <LoaderProvider>
                            <RouterProvider router={router} />
                          </LoaderProvider>
                        </TimeProvider>
                      </CreditCardProvider>
                    </AuthProvider>
                  </Suspense>
                </PageLoadingProvider>
              </Provider>
            </CookiesProvider>
          </WebSocketConnectionProvider>
        </Auth0Provider>
      </CustomAnalyticsProvider>
    </>
  );
}

export default App;
