import { createAsyncThunk } from '@reduxjs/toolkit';
import { getListLanguagesByAdmin } from 'apis/admin/languages';
import { getListLanguagesAPI } from 'apis/language.api';

export const getListLanguagesAction = createAsyncThunk(
  'language/get-list-languages',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getListLanguagesAPI();
      return res;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getListLanguagesAdminAction = createAsyncThunk(
  'language/get-list-languages-admin',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getListLanguagesByAdmin();
      return res;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
