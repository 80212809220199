import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from 'error';
import { AdminLayout, MainLayout, Layout, TranscriptLayout } from 'layouts';
import { ERoutes } from 'ts/enums';
import LastestTransactionPage from 'pages/Admin/LastestTransactionPage';
import DetailTransactionPage from 'pages/Admin/DetailTransactionPage';
import UserDetailPage from 'pages/Admin/UserDetailPage';
import ProjectDetailPage from 'pages/Admin/ProjectDetailPage';
import MetricsPage from 'pages/Admin/MetricsPage';
import MergeProjectsPage from 'pages/Admin/MergeProjectsPage';
import HomePage from 'pages/HomePage';
import MainPage from 'pages/MainPage';
import SignInPage from 'pages/SignInPage';
import SignUpPage from 'pages/SignUpPage';
import NotFoundPage from 'pages/NotFoundPage';
import UploadMediaPage from 'pages/UploadMediaPage';
import TranscriptDetailPage from 'pages/TranscriptDetailPage';
import AccountSettingPage from 'pages/AccountSettingPage';
import AuthCallbackPage from 'pages/AuthCallbackPage';
import BlackWhiteListPage from 'pages/Admin/BlackWhiteListPage';
import UserLookUpPage from 'pages/Admin/UserLookUpPage';
import PlansPage from 'pages/Admin/PlansPage';
import LastestPublishedProjectPage from 'pages/Admin/LastestPublishedProjectPage';
import DefaultConfigPage from 'pages/Admin/DefaultConfigPage';
import TeamPage from 'pages/Admin/TeamPage';
import TeamDetailPage from 'pages/Admin/TeamDetailPage';
import BoxCallBackPage from 'pages/BoxCallbackPage';
import AddRemoveAdminPage from 'pages/Admin/AddRemoveAdminPage';
import LanguagesPage from 'pages/Admin/LanguagesPage';
import TestPage from 'pages/TestPage';

import PurchaseSuccessPage from 'pages/PurchaseSuccessPage';

const router = createBrowserRouter([
  {
    path: ERoutes.HOME,
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />
      },
      {
        path: ERoutes.SIGN_IN,
        element: <SignInPage />
      },
      {
        path: ERoutes.SIGN_UP,
        element: <SignUpPage />
      }
    ]
  },
  {
    path: ERoutes.MAIN,
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <MainPage />
      },
      {
        path: `${ERoutes.UPLOAD}/:projectId`,
        element: <UploadMediaPage />
      },
      {
        path: `${ERoutes.ACCOUNT_SETTING}`,
        element: <AccountSettingPage />
      },
      {
        path: `${ERoutes.PURCHASE_SUCCESS}/:type`,
        element: <PurchaseSuccessPage />
      }
    ]
  },
  {
    path: `${ERoutes.TRANSCRIPT_DETAIL}/:projectId`,
    element: <TranscriptLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <TranscriptDetailPage />
      }
    ]
  },
  {
    path: `app/test/:projectId`,
    element: <TestPage />
  },
  {
    path: `${ERoutes.PROJECT_SHARING}/:projectId`,
    element: <TranscriptLayout />
  },
  { path: ERoutes.AUTH_CALLBACK, element: <AuthCallbackPage /> },
  {
    element: <AdminLayout />,
    children: [
      {
        path: ERoutes.USER_LOOKUP,
        element: <UserLookUpPage />
      },
      {
        path: ERoutes.PUBLISHED_PROJECT,
        element: <LastestPublishedProjectPage />
      },
      {
        path: `${ERoutes.USER_LOOKUP}/:userId`,
        element: <UserDetailPage />
      },
      { path: `${ERoutes.BLACK_WHITE_LIST}`, element: <BlackWhiteListPage /> },
      {
        path: ERoutes.PLANS,
        element: <PlansPage />
      },
      {
        path: `${ERoutes.PROJECT_DETAIL_VIEW}/:projectId`,
        element: <ProjectDetailPage />
      },
      {
        path: `${ERoutes.TEAM}`,
        element: <TeamPage />
      },
      {
        path: `${ERoutes.TEAM}/:teamId`,
        element: <TeamDetailPage />
      },
      {
        path: ERoutes.LASTEST_TRANSACTION,
        element: <LastestTransactionPage />
      },
      {
        path: ERoutes.ADMIN_DEFAULT_CONFIG,
        element: <DefaultConfigPage />
      },
      {
        path: `${ERoutes.DETAIL_TRANSACTION}/:transactionId`,
        element: <DetailTransactionPage />
      },
      {
        path: ERoutes.METRICS,
        element: <MetricsPage />
      },
      {
        path: `${ERoutes.MERGE_PROJECTS}/:projectId/:userId`,
        element: <MergeProjectsPage />
      },
      {
        path: `${ERoutes.ADD_REMOVE_ADMIN}`,
        element: <AddRemoveAdminPage />
      },
      {
        path: `${ERoutes.LANGUAGES}`,
        element: <LanguagesPage />
      }
    ]
  },
  {
    path: `${ERoutes.BOX_CALL_BACK}`,
    element: <BoxCallBackPage />
  },
  {
    path: '*',
    element: <NotFoundPage />
  }
]);

export default router;
