import React, { useEffect, useRef, useState } from 'react';
import { FaBox, FaBoxOpen, FaCheck, FaDownload, FaFile, FaTag } from 'react-icons/fa';
import { LuRefreshCw } from 'react-icons/lu';
import { useDrag } from 'react-dnd';
import { EDelayTime, EModals, ERoutes, EStatusProject, ETypeDragDrop } from 'ts/enums';
import { TProject, TTag } from 'ts/types';
import moment from 'moment';
import { AiOutlineDelete } from 'react-icons/ai';
import { HiUserGroup } from 'react-icons/hi';
import PosterVideoImg from '../../assets/img/poster-video.png';
import PosterAudioImg from 'assets/img/poster-audio.png';
import { useNavigate } from 'react-router-dom';
import { convertTime } from 'utils/time';
import { Avatar, Popconfirm, Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { TagsPopOver } from './TagPopOver/TagsPopOver';
import { pushModal } from 'redux/modal/modal.slice';
import { BsTranslate } from 'react-icons/bs';
import { MediaPopOver } from './MediaPopOver';
import { deleteProjectAction, getProjectByIdAction } from 'redux/projects/projects.action';
import { changeCountFolder } from 'redux/folder/folder.slice';
import { usePageLoading } from 'context/PageLoadingContext';
import { setMedia } from 'redux/media/media.slice';
import {
  changeSelectMode,
  resetCurrentProject,
  selectProjects
} from 'redux/projects/projects.slice';
import { ImageContainer } from 'components/common/ImageContainer';
import { useTranslation } from 'react-i18next';
import { useLoaderContext } from 'context/LoaderContext';

type Props = {
  project: TProject;
  tag: TTag | null;
};

export function ProjectItem({ project, tag }: Props) {
  const currentUser = useAppSelector((state) => state.auth.currentUser.data);
  const dispatch = useAppDispatch();
  const [isDisabled, setisDisabled] = useState<boolean>(false);
  const { openMessage, destroyMessage } = usePageLoading();
  const { t } = useTranslation();
  const { loader } = useLoaderContext();
  const projectSelected = useAppSelector((state) => state.projects.projectSelected);
  const isSelected = projectSelected.map((project) => project.id).includes(project.id);
  const isSelectMultiple = useAppSelector((state) => state.projects.isSelectMultiple);
  const isOwner = currentUser?.id === project.owner?.id;
  const isSelectedRef = useRef(true);

  const handleSelect = () => {
    let tmpSelectedProject = [...projectSelected];
    const idsProject = tmpSelectedProject.map((project) => project.id);

    if (idsProject.includes(project.id)) {
      tmpSelectedProject = tmpSelectedProject.filter((item) => item.id !== project.id);
    } else {
      tmpSelectedProject = [...tmpSelectedProject, project];
    }

    dispatch(selectProjects({ projects: tmpSelectedProject }));
  };

  useEffect(() => {
    dispatch(changeSelectMode({ isSelectMultiple: projectSelected.length > 0 ? true : false }));
  }, [projectSelected]);

  useEffect(() => {
    if (isSelectMultiple) {
      isSelectedRef.current = isSelected;
    } else {
      isSelectedRef.current = true;
    }
  }, [isSelected, isSelectMultiple]);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ETypeDragDrop.PROJECT,
    item: {
      project,
      currentUser
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    }),
    canDrag: () => isSelectedRef.current
  }));

  const navigate = useNavigate();
  const isEmptyMedia = project.medias.length <= 0;
  const [openTag, setOpenTag] = useState<boolean>(false);

  const isVisible =
    project.medias.length > 0 &&
    currentUser?.id === project.owner.id &&
    project.status !== EStatusProject.OPEN;

  function redirectToEditPage() {
    dispatch(setMedia({ media: null }));
    dispatch(resetCurrentProject());
    if (project.status === EStatusProject.OPEN || project.status === EStatusProject.FAILED) {
      navigate(`${ERoutes.UPLOAD}/${project.id}`);
    } else {
      if (project.medias && project.medias.length > 0) {
        navigate({
          pathname: `${ERoutes.TRANSCRIPT_DETAIL}/${project.id}`
        });
        return;
      }
      return;
    }
  }

  function handleDeleteProject() {
    if (isDisabled) return;
    loader.start();
    setisDisabled(true);
    destroyMessage();
    openMessage('loading', t('Shared.Texts.DeletingW3Dot'));
    dispatch(deleteProjectAction({ project })).then(() => {
      destroyMessage();
      openMessage('success', t('Shared.Texts.Deleted'));
      setisDisabled(false);
      if (project.directory) {
        dispatch(changeCountFolder({ folderID: project.directory.id, count: -1 }));
      }
      loader.complete();
    });
  }

  function renderIcon(status: string) {
    switch (status) {
      case EStatusProject.OPEN:
        return <FaBoxOpen size={15} />;

      case EStatusProject.READY:
      case EStatusProject.TRANSLATED:
      case EStatusProject.SUBTITLED:
        return <FaCheck size={12} />;

      case EStatusProject.UPLOADING:
        return <FaBox size={15} />;

      case EStatusProject.TRANSCRIBING:
      case EStatusProject.TRANSLATING:
        return <LuRefreshCw size={12} />;

      default:
        return <FaBoxOpen size={15} />;
    }
  }

  return (
    <div className="project-item py-2 px-1" ref={drag}>
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-start">
          <input
            type="checkbox"
            className={`me-1`}
            checked={isSelected}
            onChange={handleSelect}
            disabled={!isOwner}
          />
          <div className="thumbnail-and-content d-flex align-items-center ">
            <div
              className="project-thumbnail cursor-pointer me-1"
              onClick={(e) => {
                redirectToEditPage();
                e.stopPropagation();
              }}
            >
              <ImageContainer
                src={
                  project.img
                    ? project.img.includes('poster-audio')
                      ? PosterAudioImg
                      : project.img
                    : PosterVideoImg
                }
                alt="project image"
                className={''}
                dummyImage={
                  project.img
                    ? project.img.includes('poster-audio')
                      ? PosterAudioImg
                      : PosterVideoImg
                    : PosterVideoImg
                }
              />
              <div className="overlay">
                <div className="text">{t('Shared.Actions.Edit')}</div>
              </div>
            </div>
            <div className="project-content d-flex flex-column d-md-block">
              <div className="d-flex">
                <div className="icon-status">{renderIcon(project.status)}</div>
                {!isOwner && (
                  <div className="avatar">
                    <Avatar size={18} src={project.owner?.avatar} />
                  </div>
                )}
                <div
                  className="title cursor-pointer"
                  onClick={(e) => {
                    redirectToEditPage();
                    e.stopPropagation();
                  }}
                >
                  {(project.status === EStatusProject.TRANSLATED ||
                    project.status === EStatusProject.TRANSLATING) && (
                    <BsTranslate color="#7e56a2" className="me-1" />
                  )}{' '}
                  <span className="align-middle project-name">{project.name}</span>
                </div>
              </div>
              <div className="d-flex align-items-center d-md-none">
                <div className="project-time">{`${
                  isEmptyMedia ? '00:00' : convertTime(project.medias[0].duration)
                }`}</div>
                <div className="project-count-and-action d-flex align-items-center justify-content-end">
                  <div className="project-count">{project.medias.length || 0}</div>

                  <div className="action">
                    <FaFile size={10} />
                  </div>
                </div>
                <div className="project-date">{moment(project.creationDate).format('ll')}</div>
              </div>
            </div>
          </div>

          <div className="action d-none d-md-flex align-items-center">
            <div className="project-time">{`${
              isEmptyMedia ? '00:00' : convertTime(project.totalTime)
            }`}</div>
            <div className="project-count-and-action d-flex align-items-center justify-content-end">
              <div className="project-count">{project.medias.length || 0}</div>

              <div className="action">
                <MediaPopOver medias={project.medias} project={project} className="pop-over-custom">
                  <div>
                    <FaFile color="var(--si-dim-gray)" />
                  </div>
                </MediaPopOver>
              </div>
            </div>
            <div
              className="project-label-and-action d-flex align-items-center"
              style={{ color: `${tag ? tag.color : 'transparent'}` }}
            >
              <span
                className="label"
                style={{ backgroundColor: `${tag ? tag.color : 'transparent'}`, color: 'white' }}
              >
                {tag ? tag.name : ''}
              </span>
              <div className="action">
                <TagsPopOver tag={tag} project={project} setOpen={setOpenTag} open={openTag}>
                  <div onClick={() => setOpenTag(!openTag)}>
                    <FaTag color={`${tag ? tag.color : 'var(--si-spanish-gray)'}`} />
                  </div>
                </TagsPopOver>
              </div>
            </div>
            <div className="project-date">{moment(project.creationDate).format('ll')}</div>
            <Popconfirm
              placement="left"
              title={t('Pages.Main.Texts.ProjectDeletionConfirm')}
              onConfirm={handleDeleteProject}
              okText={t('Shared.Texts.Yes')}
              cancelText={t('Shared.Texts.No')}
            >
              <div
                className={`action-icon delete-action cursor-pointer  ${
                  currentUser?.id === project.owner?.id ? 'visible' : 'invisible'
                }`}
              >
                <Tooltip
                  title={`${t('Shared.Actions.Delete')} "${project.name}"`}
                  trigger={'hover'}
                  mouseEnterDelay={EDelayTime.FAST}
                  placement="bottom"
                >
                  <AiOutlineDelete size={17} />
                </Tooltip>
              </div>
            </Popconfirm>
            <div
              className={`action-icon share-action cursor-pointer ${
                isVisible ? 'visible' : 'invisible'
              }`}
              onClick={() => {
                dispatch(
                  pushModal({
                    name: EModals.SHARE_PROJECT_MODAL,
                    data: { project }
                  })
                );
              }}
            >
              <Tooltip
                title={`${t('Shared.Actions.Share')} "${project.name}"`}
                trigger={'hover'}
                mouseEnterDelay={EDelayTime.FAST}
                placement="bottom"
              >
                <HiUserGroup size={17} />
              </Tooltip>
            </div>
            <div
              className={`action-icon export-action cursor-pointer ${
                isVisible ? 'visible' : 'invisible'
              }`}
              onClick={() => {
                destroyMessage();
                openMessage('loading', t('Shared.Texts.LoadingW3Dot'));
                dispatch(getProjectByIdAction({ projectId: project.id })).then(() => {
                  destroyMessage();
                });
                dispatch(
                  pushModal({
                    name: EModals.EXPORT_MODAL,
                    data: { mediaId: project.medias.length > 0 ? project.medias[0].id : null }
                  })
                );
              }}
            >
              <Tooltip
                title={`${t('Shared.Actions.Export')} "${project.name}"`}
                trigger={'hover'}
                mouseEnterDelay={EDelayTime.FAST}
                placement="bottom"
              >
                <FaDownload size={13} color="var(--si-primary)" />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
