import { TFunction } from 'i18next';
import moment from 'moment';

export function convertTime(time: number) {
  var hours = Math.floor(time / 3600);
  var minutes = Math.floor((time % 3600) / 60);
  var seconds = Math.floor(time % 60);

  if (hours < 1) return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
    seconds
  ).padStart(2, '0')}`;
}

export function renderTime({
  time,
  unit
}: {
  time: number;
  unit: 'hour' | 'minute' | 'second';
}): number {
  var timeConvert = time / 1000;
  var hours = Math.floor(timeConvert / 3600);
  var minutes = Math.floor((timeConvert % 3600) / 60);
  var seconds = Math.floor(timeConvert % 60);

  switch (unit) {
    case 'hour':
      return hours;
    case 'minute':
      return minutes;
    case 'second':
      return seconds;
    default:
      throw new Error('Invalid unit');
  }
}

export function convertNumberToString(number: number | null) {
  if (!number) return '00';
  else if (String(number).length > 2) return String(number).slice(0, 2).padStart(2, '0');
  return String(number).padStart(2, '0');
}

export function formatTimeTwoNumber(time: number) {
  let timeStr = time.toString().padStart(2, '0');
  let firstTwoDigits = timeStr.slice(0, 2);
  return firstTwoDigits;
}

export function getMiliSeconds({
  hour,
  minute,
  second
}: {
  hour: number;
  minute: number;
  second: number;
}): number {
  const newHours = Number(formatTimeTwoNumber(hour));
  const newMinute = Number(formatTimeTwoNumber(minute));
  const newSecond = Number(formatTimeTwoNumber(second));
  return (newHours * 3600 + newMinute * 60 + newSecond) * 1000;
}

export function getFormattedDate(timestamp: number | Date): string {
  return moment(timestamp).format('MMM DD, YYYY');
}

export const reformatDateFromNow = (date: Date | string | number | null) => {
  return moment(date).isSame(new Date(), 'day')
    ? moment(date).fromNow()
    : moment(date).format('MMM DD, yyyy');
};

export function convertTimeTranscrip(time: number) {
  const now = new Date().getTime();
  const delta = (now - time) / 1000;
  const days = Math.floor(delta / (24 * 3600));
  const hours = Math.floor((delta / 3600) % 24);
  const minutes = Math.floor((delta / 60) % 60);
  const seconds = Math.floor(delta % 60);

  const minuteDisplay = `${minutes} ${minutes === 1 ? 'min' : 'mins'}`;
  const secondDisplay = `${seconds} ${seconds === 1 ? 'sec' : 'secs'}`;
  const hourDisplay = `${hours} ${hours === 1 ? 'hr' : 'hrs'}`;
  const dayDisplay = `${days} ${days === 1 ? 'day' : 'days'}`;

  if (days === 0) {
    if (hours === 0) {
      return `${minuteDisplay} ${secondDisplay} ago`;
    } else {
      return `${hourDisplay} ${minuteDisplay} ago`;
    }
  } else {
    return `${dayDisplay} ${hourDisplay} ago`;
  }
}

export const convertDurationToString = (
  duration: number,
  type?: 'compact',
  t: TFunction | undefined = undefined
) => {
  const hour = parseInt((duration / 3600).toString());
  const minuteRound = Math.round((duration % 3600) / 60);

  const minutes = parseInt(minuteRound.toString());

  if (type && type === 'compact') {
    if (hour === 0) return `${minutes} ${t ? t('Shared.Fields.min', { count: 0 }) : 'min'}`;
    if (hour > 0 && minutes > 0)
      return `${hour} ${hour === 1 ? 'hr' : 'hrs'} ${minutes} ${
        t ? t('Shared.Fields.min', { count: minutes }) : 'min(s)'
      }`;
    else {
      return `${hour} ${hour === 1 ? 'hr' : 'hrs'}`;
    }
  }

  if (hour === 0)
    return `${minutes} ${t ? t('Shared.Fields.minute', { count: minutes }) : 'minute(s)'}`;
  if (hour > 0 && minutes > 0)
    return `${hour} ${hour === 1 ? 'hr' : 'hrs'} ${minutes} ${minutes === 1 ? 'min' : 'mins'}`;

  return `${hour} ${t ? t('Shared.Fields.hour', { count: hour }) : 'hour(s)'}`;
};

export function convertCreditToString(
  credit: number,
  t: TFunction | undefined = undefined
): string {
  const hour = Math.floor(credit / 60);
  const minuteRound = Math.floor(credit % 60);
  const minutes = Math.floor(minuteRound);
  const day = Math.floor(credit / 1440);

  const minuteDisplay = `${
    minutes >= 1
      ? `${minutes} ${t ? t('Shared.Fields.minute', { count: minutes }) : 'minute(s)'}`
      : ''
  }`;

  const hourConvert = day >= 1 ? hour - 24 * day : hour;
  const hourDisplay = `${
    hourConvert >= 1
      ? `${hourConvert} ${t ? t('Shared.Fields.hour', { count: hourConvert }) : 'hour(s)'}`
      : ''
  }`;

  const dayDisplay = `${
    day >= 1 ? `${day} ${t ? t('Shared.Fields.day', { count: day }) : 'day(s)'}` : ''
  }`;

  return `${dayDisplay} ${hourDisplay} ${minuteDisplay}`;
}

export function convertDateStringToMilliseconds(dateString: Date) {
  const date = new Date(dateString);

  return date.getTime();
}

export function convertDayTo({
  date,
  day,
  type
}: {
  date: Date;
  day: number;
  type: 'add' | 'subtract';
}) {
  const days = new Date(date);
  switch (type) {
    case 'add':
      return days.setDate(days.getDate() + day);

    case 'subtract':
      return days.setDate(days.getDate() - day);

    default:
      return days;
  }
}

export const convertTimestampToDate = (timestamp: number) => {
  const date = new Date(timestamp);
  return date;
};

export const convertTimesIso = ({
  timeISO,
  type
}: {
  timeISO: string;
  type: 'start' | 'end' | 'now';
}) => {
  const originalDate = new Date(timeISO + 'Z');

  switch (type) {
    case 'start': {
      originalDate.setUTCHours(0);
      originalDate.setUTCMinutes(0);
      originalDate.setUTCSeconds(0);
      break;
    }
    case 'end': {
      originalDate.setUTCHours(23);
      originalDate.setUTCMinutes(59);
      originalDate.setUTCSeconds(59);
      break;
    }
    case 'now': {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();
      const currentSecond = currentDate.getSeconds();

      originalDate.setUTCHours(currentHour);
      originalDate.setUTCMinutes(currentMinute);
      originalDate.setUTCSeconds(currentSecond);
      break;
    }
    default: {
      originalDate.setUTCHours(0);
      originalDate.setUTCMinutes(0);
      originalDate.setUTCSeconds(0);
      break;
    }
  }

  return originalDate.toISOString().substring(0, originalDate.toISOString().length - 1);
};
