import { Dropdown, MenuProps } from 'antd';
import { getDefaultConfig, updateDefaultConfig, updatePlan } from 'apis/admin/defaultConfig.api';
import { getPlan } from 'apis/subscribe.api';
import { useLoaderContext } from 'context/LoaderContext';
import { usePageLoading } from 'context/PageLoadingContext';
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { BiSolidDownArrow } from 'react-icons/bi';
import { FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { pushModal } from 'redux/modal/modal.slice';
import { EModals, ERoutes, ETypeNoti } from 'ts/enums';
import { TDefaultConfig, TPlan } from 'ts/types';

function ActionComponent({
  onOk,
  onCancel,
  className
}: {
  onOk: () => void;
  onCancel: () => void;
  className?: string;
}) {
  return (
    <div className={`d-flex gap-1 action-wrapper ${className ? className : ''}`}>
      <button className="save-btn" onClick={onOk}>
        Save
      </button>
      <button className="cancel-btn" onClick={onCancel}>
        Cancel
      </button>
    </div>
  );
}

export default function DefaultConfigPage() {
  const { openMessage, destroyMessage } = usePageLoading();
  const [listPlan, setListPlan] = useState<MenuProps['items']>([]);
  const [rawPlans, setRawPlans] = useState<TPlan[]>([]);
  const [currentConfig, setCurrentConfig] = useState<TDefaultConfig>();
  const [newUserFreeCredit, setNewUserFreeCredit] = useState<null | number>(null);
  const [ipUser, setIpUser] = useState<null | number>(null);
  const [ipHour, setIpHour] = useState<null | number>(null);
  const [isShowUserCreditConfigAction, setIsShowUserCreditConfigAction] = useState<boolean>(false);
  const [isShowPlanConfigAction, setIsShowPlanConfigAction] = useState<boolean>(false);
  const [selectedPlanId, setSelectedPlanId] = useState<number | string>();
  const navigate = useNavigate();
  const [currentSupcription, setCurrentSupcription] = useState<number | undefined>();

  const dispatch = useAppDispatch();
  const subscriptionCreditInputRef = useRef<HTMLInputElement>(null);
  const selectedPlanTextRef = useRef<HTMLDivElement>(null);
  const menuStyle: React.CSSProperties = {
    paddingLeft: 5
  };
  const { loader } = useLoaderContext();

  useEffect(() => {
    (async () => {
      destroyMessage();
      loader.start();
      openMessage('loading', 'Loading...');
      const plansData = await getPlan();
      const configData = await getDefaultConfig();
      destroyMessage();
      openMessage('success', 'Success!');
      setCurrentConfig(configData);
      setRawPlans(plansData);
      setSelectedPlanId(plansData[0].id);
      setListPlan(
        plansData.map((item) => ({
          key: item.id,
          label: <div style={{ cursor: 'pointer' }}>{item.fullLabel}</div>
        }))
      );
      setNewUserFreeCredit(configData.newUserCredit || 0);
      setIpUser(configData.ipNumOfUsers || 0);
      setIpHour(configData.ipHours || 0);
      loader.complete();
    })();
  }, []);

  useEffect(() => {
    setCurrentSupcription(rawPlans.find((item) => item.id == selectedPlanId)?.creditInMinutes);
  }, [rawPlans, selectedPlanId]);

  async function updateUserCreditConfig() {
    destroyMessage();
    openMessage('loading', 'Updating...');
    loader.start();
    if (currentConfig) {
      const data = await updateDefaultConfig({
        ...currentConfig,
        newUserCredit: Number(newUserFreeCredit) < 0 ? null : Number(newUserFreeCredit),
        ipHours: Number(ipHour) < 0 ? null : Number(ipHour),
        ipNumOfUsers: Number(ipUser) < 0 ? null : Number(ipUser)
      });
      destroyMessage();
      if (data.id == currentConfig.id) {
        setNewUserFreeCredit(data.newUserCredit);
        setIpHour(data.ipHours);
        setIpUser(data.ipNumOfUsers);
        openMessage('success', 'Updated!');
        setIsShowUserCreditConfigAction(false);
        setCurrentConfig((prev: any) => ({
          ...prev,
          ipHours: data.ipHours,
          ipNumOfUsers: data.ipNumOfUsers,
          newUserCredit: data.newUserCredit
        }));
      }
      loader.complete();
    } else {
      destroyMessage();
      loader.complete();
      openMessage('error', 'Old config not found!');
    }
  }

  async function updatePlans(id: number | string, subscriptionCredit: number) {
    if (subscriptionCredit < 0) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: { type: ETypeNoti.ERROR, title: 'Subscription credit number is invalid.' }
        })
      );
      return;
    }
    destroyMessage();
    loader.start();
    openMessage('loading', 'Updating...');
    const data = await updatePlan(id, subscriptionCredit);
    if (data == 200) {
      destroyMessage();
      openMessage('success', 'Updated!');
      setIsShowPlanConfigAction(false);
      const plansData = await getPlan();
      setRawPlans(plansData);
    }
    loader.complete();
  }

  function handleDisplayAction(userCredit: number, ipUser: number, ipHour: number) {
    if (
      userCredit != currentConfig?.newUserCredit ||
      ipUser != currentConfig?.ipNumOfUsers ||
      ipHour != currentConfig?.ipHours
    ) {
      setIsShowUserCreditConfigAction(true);
    } else {
      setIsShowUserCreditConfigAction(false);
    }
  }
  return (
    <div className="ctn-admin-default-config">
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome
            size={30}
            color="var(--si-primary)"
            onClick={() => navigate(ERoutes.LASTEST_TRANSACTION)}
            className="cursor-pointer"
          />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            Default Config
          </span>
        </div>
      </div>

      <div style={{ maxWidth: 1170 }} className="container-fluid">
        <div className="dfc-user-config px-3 mt-3">
          <div className="pt-2 input-label">NEW USER FREE CREDIT: (IN MINUTES)</div>
          <input
            min={0}
            max={999}
            type="number"
            className="w-100"
            value={newUserFreeCredit || ''}
            onChange={(value) => {
              handleDisplayAction(Number(value.target.value), Number(ipUser), Number(ipHour));
              setNewUserFreeCredit(Number(value.target.value));
            }}
          />

          <div className="row mt-3 gy-3 mb-2">
            <div className="col-12 col-lg-6">
              <div className="input-label">NUMBER OF USERS OF SAME IP</div>
              <input
                min={0}
                max={999}
                type="number"
                className="w-100"
                value={ipUser || ''}
                onChange={(value) => {
                  handleDisplayAction(
                    Number(newUserFreeCredit),
                    Number(value.target.value),
                    Number(ipHour)
                  );
                  setIpUser(Number(value.target.value));
                }}
              />
            </div>
            <div className="col-12 col-lg-6">
              <div className="input-label">HOURS</div>
              <input
                type="number"
                className="w-100"
                value={ipHour || ''}
                onChange={(value) => {
                  handleDisplayAction(
                    Number(newUserFreeCredit),
                    Number(ipUser),
                    Number(value.target.value)
                  );
                  setIpHour(Number(value.target.value));
                }}
              />
            </div>
          </div>
          <div className="my-2 config-description">
            If <span>{ipUser}</span> number of users of same IP sign up for an account within{' '}
            <span>{ipHour}</span> hours, then new users from that IP get 0 credits.
          </div>
          {isShowUserCreditConfigAction && (
            <ActionComponent
              className="mb-2"
              onOk={() => {
                updateUserCreditConfig();
              }}
              onCancel={() => {
                setIsShowUserCreditConfigAction(false);
                if (currentConfig) {
                  setNewUserFreeCredit(currentConfig.newUserCredit);
                  setIpHour(currentConfig.ipHours);
                  setIpUser(currentConfig.ipNumOfUsers);
                }
              }}
            />
          )}
        </div>

        <div className="dfc-plan-config-wrapper my-4 px-2 py-2">
          <div className="title">Plan config</div>
          <div className="row px-1 gy-3 mt-2">
            <div className="col-12 col-lg-6">
              <div className="input-label">SELECT PLAN</div>
              <Dropdown
                menu={{
                  items: listPlan,
                  onClick: (event) => {
                    if (selectedPlanTextRef.current && subscriptionCreditInputRef.current) {
                      setSelectedPlanId(event.key);
                      selectedPlanTextRef.current.innerText = rawPlans.filter(
                        (item) => item.id.toString() == event.key
                      )[0].fullLabel;
                      subscriptionCreditInputRef.current.value = rawPlans
                        .filter((item) => item.id.toString() == event.key)[0]
                        .creditInMinutes.toString();
                    }
                  }
                }}
                dropdownRender={(menu) => (
                  <div style={{}}>
                    {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
                  </div>
                )}
                trigger={['click']}
              >
                <div
                  className="w-100 drop-plan-wrapper d-flex justify-content-between pe-1"
                  ref={selectedPlanTextRef}
                  style={{ cursor: 'pointer' }}
                >
                  {rawPlans.find((item) => item.id == selectedPlanId)?.fullLabel}{' '}
                  <BiSolidDownArrow size={10} style={{ margin: '4px 0px 0px 2px' }} />
                </div>
              </Dropdown>
            </div>
            <div className="col-12 col-lg-6">
              <div className="input-label">SUBSCRIPTION CREDIT: (IN MINUTES)</div>
              <input
                ref={subscriptionCreditInputRef}
                type="number"
                className="w-100"
                defaultValue={currentSupcription}
                onChange={(value) => {
                  if (
                    Number(value.target.value) != rawPlans[Number(selectedPlanId)].creditInMinutes
                  ) {
                    setIsShowPlanConfigAction(true);
                  } else {
                    setIsShowPlanConfigAction(false);
                  }
                }}
              />
            </div>
          </div>
          {isShowPlanConfigAction && (
            <ActionComponent
              className="mt-2"
              onOk={() => {
                if (
                  subscriptionCreditInputRef.current &&
                  Number(subscriptionCreditInputRef.current.value) < 0
                ) {
                  dispatch(
                    pushModal({
                      name: EModals.NOTI_MODAL,
                      data: null,
                      notiData: {
                        type: ETypeNoti.ERROR,
                        title: 'Subscription credit number is invalid.'
                      }
                    })
                  );
                  return;
                }
                if (subscriptionCreditInputRef.current && selectedPlanId) {
                  updatePlans(selectedPlanId, Number(subscriptionCreditInputRef.current.value));
                }
              }}
              onCancel={() => {
                setIsShowPlanConfigAction(false);
                if (selectedPlanTextRef.current && subscriptionCreditInputRef.current) {
                  selectedPlanTextRef.current.innerText = rawPlans[0].fullLabel;
                  subscriptionCreditInputRef.current.value = rawPlans[0].creditInMinutes.toString();
                  setCurrentSupcription(
                    rawPlans.find((item) => item.id == selectedPlanId)?.creditInMinutes
                  );
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
