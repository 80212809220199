import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { popModal, pushModal } from 'redux/modal/modal.slice';
import { EModals } from 'ts/enums';
import { ButtonWithIcons, ModalBase } from 'components/common';
import { TLanguageDubbing, TSpeaker } from 'ts/types';
import { getLanguageDubbing } from 'apis/dubbing.api';
import { BsArrowRightCircleFill, BsCircleFill } from 'react-icons/bs';
import { usePageLoading } from 'context/PageLoadingContext';
import { GOOGLE_VOICES } from 'constant/Voices';
import { findValueByKey } from 'utils/common';
import { IoVolumeMediumOutline } from 'react-icons/io5';

export function DubbingModal() {
  const dispatch = useAppDispatch();
  const dataDubbingModal = useAppSelector((state) => state.modal.lastModal?.data);
  const listAllLanguages = useAppSelector((state) => state.languages.listAllLanguages);
  const [languageDubbing, setLanguageDubbing] = useState<TLanguageDubbing[]>([]);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const { openMessage, destroyMessage } = usePageLoading();
  const [loading, setLoading] = useState<boolean>(false);
  const currentMedia = useAppSelector((state) => state.media.currentMedia);
  const [dubbingData, setDubbingData] = useState<
    Array<{
      lang: TLanguageDubbing;
      speaker: TSpeaker;
    }>
  >([]);
  const [defaultDubbingData, setDefaultDubbingData] = useState<TLanguageDubbing | null>(null);
  const { t } = useTranslation();
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);

  const handleAudioEnded = () => {
    setCurrentAudio(null);
  };

  const handlePlay = (audioUrl: string) => {
    if (currentAudio) {
      currentAudio.pause();
    }
    const audio = new Audio(audioUrl);
    audio.play();
    setCurrentAudio(audio);
    audio.addEventListener('ended', handleAudioEnded);
  };

  function convertNameLanguage({
    languageCode,
    index,
    gender
  }: {
    languageCode: string;
    index: number;
    gender: string;
  }) {
    const languageCodeConvert = findValueByKey(GOOGLE_VOICES, languageCode);

    return `${languageCodeConvert} ${index + 1} | ${gender}`;
  }

  useEffect(() => {
    if (
      dataDubbingModal &&
      dataDubbingModal.languageCode &&
      listAllLanguages.data &&
      listAllLanguages.data?.length > 0
    ) {
      setLoading(true);
      destroyMessage();
      openMessage('loading', t('Shared.Texts.LoadingW3Dot'));
      const listLangFind = listAllLanguages.data.filter(
        (lang) => lang.id === dataDubbingModal.languageCode
      );
      if (listLangFind.length > 0) {
        (async () => {
          const res = await getLanguageDubbing({ languageCode: listLangFind[0].code });
          if (res && res?.length > 0) {
            destroyMessage();
            openMessage('success', t('Shared.Texts.Loaded'));
            setDefaultDubbingData(res[0]);
            setLanguageDubbing(res);
          } else {
            destroyMessage();
            openMessage('error', t('Shared.Errors.LanguageNotSupported'));
          }
          setLoading(false);
        })();
      }
    }
  }, [dataDubbingModal]);

  useEffect(() => {
    if (currentMedia && currentMedia.speakers?.length > 0 && languageDubbing?.length > 0) {
      const data = currentMedia.speakers.map((speaker) => {
        return {
          lang: languageDubbing[0],
          speaker
        };
      });
      if (data) {
        setDubbingData(data);
      }
    }
  }, [currentMedia, languageDubbing]);

  function handleChooseLanguageForSpeaker(lang: string, speakerData?: TSpeaker) {
    const findLang = languageDubbing.find((language) => language.name === lang);
    if (speakerData) {
      const copyArray = [...dubbingData];
      const findItem = copyArray.find((dubding) => dubding.speaker.id === speakerData.id);
      if (findItem) {
        if (findLang) {
          findItem.lang = findLang;
        }
      }
      setDubbingData(copyArray);
    } else {
      if (findLang) {
        setDefaultDubbingData(findLang);
      }
    }
  }

  if (!dataDubbingModal || loading) return null;

  return (
    <ModalBase
      modalName={EModals.DUBBING}
      onCloseModal={() => {
        dispatch(popModal());
        setCurrentAudio(null);
      }}
    >
      <div className="dubbing-container">
        <div className="text-center mb-3 fs-3">
          {t('Pages.TranscriptDetail.DubbingModal.Title')}
        </div>
        {languageDubbing.length === 0 ? (
          <span>{t('Shared.Errors.LanguageNotSupported')}</span>
        ) : (
          <>
            <div className="row px-1">
              <div className="col-6">
                <span className="fw-bold">Speaker</span>
              </div>
              <div className="col-6">
                <span className="fw-bold">Voice</span>
              </div>
            </div>
            {dubbingData && dubbingData.length > 0 ? (
              dubbingData.map(({ lang, speaker }) => {
                return (
                  <div key={speaker.id} className="choose-dubbing mt-1">
                    <div className="row align-items-center px-1">
                      <div className="col-6">
                        <BsCircleFill size={20} color={speaker.color} />
                        <span className="ms-1">{speaker.name}</span>
                      </div>
                      <div className="col-6">
                        <div className="d-flex align-items-center gap-1">
                          <select
                            value={lang.name}
                            className="p-1 me-1 w-100"
                            name="lang-dubbing"
                            onChange={(e) =>
                              handleChooseLanguageForSpeaker(e.currentTarget.value, speaker)
                            }
                          >
                            {languageDubbing.map((lang, index) => (
                              <option
                                key={`${lang.name}-${new Date().getTime() + index}`}
                                value={lang.name}
                              >
                                {convertNameLanguage({
                                  languageCode: lang.languageCodes[0],
                                  index,
                                  gender: lang.ssmlGender
                                })}
                              </option>
                            ))}
                          </select>
                          <ButtonWithIcons
                            icon={<IoVolumeMediumOutline size={20} />}
                            disabled={
                              (currentAudio && lang.sampleAudio === currentAudio.src) || false
                            }
                            className="submit-button --volume d-flex align-items-center justify-content-center"
                            onClick={() => {
                              handlePlay(lang.sampleAudio);
                            }}
                            tooltipTile={t(
                              'Pages.TranscriptDetail.DubbingModal.ListenSampleSpeaker'
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <div className="choose-dubbing mt-1">
                  <div className="row align-items-center px-1">
                    <div className="col-6">
                      <div>{t('Shared.Texts.DefaultSpeaker')}</div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex align-items-center gap-1">
                        <select
                          value={defaultDubbingData?.name}
                          className="p-1 me-1 w-100"
                          name="lang-dubbing"
                          onChange={(e) => handleChooseLanguageForSpeaker(e.currentTarget.value)}
                        >
                          {languageDubbing.map((lang, index) => (
                            <option
                              key={`${lang.name}-${new Date().getTime() + index}`}
                              value={lang.name}
                            >
                              {convertNameLanguage({
                                languageCode: lang.languageCodes[0],
                                index,
                                gender: lang.ssmlGender
                              })}
                            </option>
                          ))}
                        </select>
                        <ButtonWithIcons
                          icon={<IoVolumeMediumOutline size={20} />}
                          disabled={
                            (defaultDubbingData &&
                              currentAudio &&
                              defaultDubbingData.sampleAudio === currentAudio.src) ||
                            false
                          }
                          className="submit-button --volume d-flex align-items-center justify-content-center"
                          onClick={() => {
                            defaultDubbingData && handlePlay(defaultDubbingData.sampleAudio);
                          }}
                          tooltipTile={t('Pages.TranscriptDetail.DubbingModal.ListenSampleSpeaker')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="text-center mt-4">
              <button
                className="submit-button"
                onClick={() => {
                  setIsDisable(true);
                  dispatch(popModal());
                  dispatch(
                    pushModal({
                      name: EModals.PRICE_DUBBING_MODAL,
                      data: { dubbingData, defaultDubbingLang: defaultDubbingData }
                    })
                  );
                  setTimeout(() => {
                    setIsDisable(false);
                  }, 1500);
                }}
                disabled={isDisable}
              >
                {t('Shared.Texts.NextPayment')}{' '}
                <BsArrowRightCircleFill size={24} className="ms-1" />
              </button>
            </div>
          </>
        )}
      </div>
    </ModalBase>
  );
}
