import WordIcon from 'assets/icons/word-icon.svg';
import { PropsExport } from '.';
import { EExportName } from 'ts/enums';
import { useAppSelector } from 'redux/hooks';

export function WordExport({ exportOption, setExportOption }: PropsExport) {
  const data = useAppSelector((state) => state.modal.lastModal?.data);

  const option = {
    withTimeCode: true
  };
  return (
    <div className="word-export export-item">
      <div
        className="d-flex flex-column justify-content-center align-items-center cursor-pointer"
        onClick={() => {
          setExportOption((draft) => {
            draft.key = EExportName.WORD;
            draft.data.subData = null;
            draft.data.option = option;
            draft.allowMultipleMedia = true;
          });
        }}
      >
        <div
          className={`icon ${exportOption.key === EExportName.WORD ? 'opacity-100' : 'opacity-50'}`}
        >
          <img src={WordIcon} alt="" />
        </div>
        <div className={`text mt-1 ${exportOption.key === EExportName.WORD ? '--selected' : ''}`}>
          <span>Microsoft Word</span>
        </div>
      </div>
    </div>
  );
}
