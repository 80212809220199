import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <div id="error-page" className="tl-error">
      <h1>{ t('Shared.Errors.GenericErrorTitle') }</h1>
      <p>{ t('Shared.Errors.GenericErrorDesc') }</p>
    </div>
  );
}

export default ErrorPage;
