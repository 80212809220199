import BitcIcon from 'assets/icons/bitc-logo.svg';
import { PropsExport } from '.';
import { EExportName, EExportSubData, EStatusProject } from 'ts/enums';
import { useAppSelector } from 'redux/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function BurnInExport({ exportOption, setExportOption }: PropsExport) {
  const currentProject = useAppSelector((state) => state.projects.currentProject).data;
  const [hasVideo, setHasVideo] = useState<boolean>(false);
  const { t } = useTranslation();

  const subDataRender = [
    {
      key: EExportSubData.SUB_TITLES,
      title: t('Layouts.ExportModal.Subtitles.Title'),
      option: {},
      allowMultipleMedia: true
    },
    {
      key: EExportSubData.TIME_CODE_BITC,
      title: 'Timecode (BITC)',
      option: {},
      allowMultipleMedia: true
    }
  ];

  useEffect(() => {
    const listVideo = currentProject?.medias.find((media) => media.type == 'video');
    const hasNoExport =
      currentProject?.status === EStatusProject.TRANSLATING ||
      currentProject?.status === EStatusProject.TRANSCRIBING ||
      currentProject?.status === EStatusProject.FAILED;

    if (listVideo && !hasNoExport) {
      setHasVideo(true);
    } else {
      setHasVideo(false);
    }
  }, [currentProject?.id]);
  return (
    <div className="burn-in-export export-item">
      <div
        className={`d-flex flex-column justify-content-center align-items-center ${
          hasVideo ? 'cursor-pointer' : 'cursor-disable'
        }`}
        onClick={() => {
          if (!hasVideo) return;
          setExportOption((draft) => {
            draft.data.subData = subDataRender[0].key;
            draft.data.option = subDataRender[0].option;
            draft.allowMultipleMedia = true;
            draft.key = EExportName.BURN_IN;
          });
        }}
      >
        <div
          className={`icon ${
            exportOption.key === EExportName.BURN_IN ? 'opacity-100' : 'opacity-50'
          }`}
        >
          <img src={BitcIcon} alt="" />
        </div>
        <div
          className={`text mt-1 ${exportOption.key === EExportName.BURN_IN ? '--selected' : ''}`}
        >
          <span>Burn-In</span>
        </div>
      </div>
      <div className={`text mt-1 ${exportOption.key === EExportName.BURN_IN ? '--selected' : ''}`}>
        {exportOption.key === EExportName.BURN_IN && (
          <div className="choose-option mt-2">
            {subDataRender.map((s) => (
              <label className="d-flex align-items-center mb-1 gap-1 cursor-pointer" key={s.key}>
                <input
                  type="radio"
                  name="select"
                  defaultChecked={s.key === EExportSubData.SUB_TITLES}
                  onClick={() => {
                    setExportOption((draft) => {
                      draft.key = EExportName.BURN_IN;
                      draft.data.subData = s.key;
                      draft.data.option = s.option;
                      draft.allowMultipleMedia = s.allowMultipleMedia;
                    });
                  }}
                />
                <span>{s.title}</span>
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
