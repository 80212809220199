import { Button, DatePicker, Form, InputNumber, Popconfirm, Table, notification } from 'antd';
import {
  deleteUser,
  exportCSVUserData,
  getDetailUserByUserId,
  getListProjectsByUser,
  getListStripeInfor,
  getListTransactionsByUser,
  getSubscriptionByUserId,
  syncSubscription,
  unlockProject,
  updateUser
} from 'apis/admin/user';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EHttpStatusCode, ERoutes, EStatusProject } from 'ts/enums';
import { TCustomer, TProjectTransaction, TSubscription, TUser } from 'ts/types';

import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { downloadFileLocally } from 'utils/export';
import { usePageLoading } from 'context/PageLoadingContext';
import { useTranslation } from 'react-i18next';
import { changeInlineStyle } from 'utils/string';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const defaultSize = 20;
function UserDetailPage() {
  const { userId } = useParams();
  const [isLoadingCustomerTable, setIsLoadingCustomerTable] = useState(false);
  const [isLoadingTransactionsTable, setIsLoadingTransactionsTable] = useState(false);
  const [isLoadingProjectsTable, setIsLoadingProjectsTable] = useState(false);
  const [dataSourceCustomer, setDataSourceCustomer] = useState<TCustomer[]>([]);
  const [dataSourceTransactions, setDataSourceTransactions] = useState<{
    content: TProjectTransaction[];
    first?: boolean;
    last?: boolean;
    totalPages?: number;
    totalElements?: number;
    size?: number;
  }>({ content: [], size: defaultSize });
  const [dataSourceProjects, setDataSourceProjects] = useState<{
    content: TProjectTransaction[];
    first?: boolean;
    last?: boolean;
    totalPages?: number;
    totalElements?: number;
    size?: number;
  }>({ content: [], size: defaultSize });

  const [currentSubscription, setCurrentSubscription] = useState<TSubscription | null>(null);
  const [currentUserDetail, setCurrentUserDetail] = useState<TUser | null>(null);
  const [formUserInfo] = Form.useForm();
  const [isHasChanged, setIsHasChanged] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isLoadingApi, setIsLoadingApi] = useState<number>(0);
  const { openMessage, destroyMessage } = usePageLoading();
  const { t } = useTranslation();

  const columnTableStripeCustomer = [
    {
      title: t('Pages.Admin.UserDetail.StripeCustomerID'),
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: t('Pages.Admin.UserDetail.StripeSubscriptionID'),
      key: 'subscriptionIds',
      dataIndex: 'subscriptionIds'
    },
    {
      title: t('Pages.Admin.UserDetail.StripeCardID'),
      key: 'cardIds',
      dataIndex: 'cardIds',
      render: (record: string[]) => {
        return record.map((dt) => (
          <p className="mb-0" key={dt}>
            {dt}
          </p>
        ));
      }
    }
  ];

  const columnTableTransactions = [
    {
      title: t('Shared.Fields.Date'),
      key: 'creationDate',
      dataIndex: 'creationDate',
      render: (record: number) => {
        return <span className="text-nowrap">{moment(record).format('MM/DD/YYYY h:MM A')}</span>;
      }
    },
    {
      title: t('Shared.Fields.ProjectName'),
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: t('Shared.Fields.Status'),
      key: 'status',
      render: (stt: string) => {
        return <span className="text-capitalize">{`${stt || ''}`}</span>;
      },
      dataIndex: 'status'
    },
    {
      title: t('Shared.Fields.TotalLength'),
      key: 'totalLengthOfProject',
      dataIndex: 'totalLengthOfProject'
    },
    {
      title: t('Shared.Fields.CreditAndCost'),
      dataIndex: 'creditCost', //credit - price
      key: 'creditCost'
    },
    {
      title: t('Shared.Fields.Language'),
      dataIndex: 'languageDisplayName',
      key: 'languageDisplayName',
      render: (record: string) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: changeInlineStyle(record)
            }}
          />
        );
      }
    },
    {
      title: t('Shared.Fields.User'),
      dataIndex: 'userTranscribed', //userName - userEmail
      key: 'userTranscribed',
      render: (record: string) => {
        return (
          <div
            className="text-nowrap"
            dangerouslySetInnerHTML={{
              __html: record
            }}
          />
        );
      }
    },
    {
      title: t('Shared.Fields.AppType'),
      dataIndex: 'applicationType', //credit - price
      key: 'applicationType'
    },
    {
      title: t('Shared.Fields.ClientInfo'),
      dataIndex: 'clientInfo', //credit - price
      key: 'clientInfo'
    }
  ];

  const columnTableProjects = [
    {
      title: t('Shared.Fields.Date'),
      key: 'creationDate',
      dataIndex: 'creationDate',
      render: (record: number) => {
        return moment(record).format('MM/DD/YYYY h:MM A');
      },
      width: 150
    },
    {
      title: t('Shared.Fields.ProjectName'),
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: t('Shared.Fields.Status'),
      key: 'status',
      dataIndex: 'status',
      render: (stt: string) => {
        return <span className="text-capitalize">{`${stt || ''}`}</span>;
      },
      width: 100
    },
    {
      title: t('Shared.Fields.TotalLength'),
      key: 'totalLengthOfProject',
      dataIndex: 'totalLengthOfProject',
      width: 100
    },
    {
      title: t('Shared.Fields.CreditAndCost'),
      dataIndex: 'creditCost', //credit - price
      key: 'creditCost',
      width: 130
    },
    {
      title: t('Shared.Fields.Language'),
      dataIndex: 'languageDisplayName',
      key: 'languageDisplayName'
    },
    {
      title: t('Shared.Actions.UnlockAllFiles'),
      width: 100,
      render: (record: TProjectTransaction) => {
        return (
          <Button
            type="primary"
            onClick={async (e) => {
              e.stopPropagation();
              await unlockProject({ projectId: record.id });
            }}
          >
            {t('Shared.Texts.Yes')}
          </Button>
        );
      }
    },
    {
      title: t('Shared.Actions.PublishTranscript'),
      render: (record: TProjectTransaction) => {
        if (record.status === EStatusProject.READY || record.status === EStatusProject.TRANSLATED) {
          return (
            <Button
              type="primary"
              onClick={async (e) => {
                e.stopPropagation();
                await unlockProject({ projectId: record.id });
              }}
            >
              {t('Shared.Actions.PublishToWebflow')}
            </Button>
          );
        }
        return <></>;
      }
    }
  ];

  useEffect(() => {
    if (userId) {
      handleGetStripeInfor({ id: userId });
      handleGetCurrentUserDetail({ id: userId });
      handleGetSubscription({ id: userId });
      handleGetListProjects({ id: userId });
      handleGetListTransactions({ id: userId });
    }
  }, [userId]);
  useEffect(() => {
    if (isLoadingApi < 4) {
      destroyMessage();
      openMessage('loading', t('Shared.Texts.LoadingW3Dot'));
    } else {
      destroyMessage();
    }
  }, [isLoadingApi]);

  async function handleGetCurrentUserDetail({ id }: { id: string }) {
    try {
      const res = await getDetailUserByUserId({ userId: id });
      if (res) {
        setCurrentUserDetail(res);
        formUserInfo.setFieldsValue({
          subscriptionExpriryDate: dayjs(res.subscriptionCreditExpireDate || '01/01/1970'),
          credit: res.credit,
          minutes: res.subscriptionCredit
        });
      }
      destroyMessage();
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
    setIsLoadingApi((prev) => prev++);
  }

  async function handleGetSubscription({ id }: { id: string }) {
    try {
      const res = await getSubscriptionByUserId({ userId: id });
      if (res) {
        setCurrentSubscription(res);
      }
      destroyMessage();
    } catch (error: any) {
      // notification.open({ type: 'error', message: error.message });
    }
    setIsLoadingApi((prev) => prev++);
  }

  async function handleGetListTransactions({ id }: { id: string }) {
    try {
      const res = await getListTransactionsByUser({ userId: id, pageSize: defaultSize });
      if (res) {
        setDataSourceTransactions(res);
      }
      destroyMessage();
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
    setIsLoadingApi((prev) => prev++);
  }

  async function handleGetListProjects({ id }: { id: string }) {
    try {
      const res = await getListProjectsByUser({ userId: id, pageSize: defaultSize });
      if (res) {
        setDataSourceProjects(res);
      }
      destroyMessage();
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
    setIsLoadingApi((prev) => prev++);
  }

  async function handleGetStripeInfor({ id }: { id: string }) {
    try {
      setIsLoadingCustomerTable(true);
      const res = await getListStripeInfor({ userId: id });
      if (res) {
        setDataSourceCustomer(res);
      }
      setIsLoadingCustomerTable(false);
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleOnPageSizeChangeTransactions(page: number, pageSize: number) {
    if (!userId) return;
    try {
      setIsLoadingTransactionsTable(true);
      const res = await getListTransactionsByUser({ userId, pageNumber: page - 1, pageSize });
      if (res) {
        setDataSourceTransactions(res);
      }
      setIsLoadingTransactionsTable(false);
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleOnPageSizeChangeProjects(page: number, pageSize: number) {
    if (!userId) return;
    try {
      setIsLoadingProjectsTable(true);
      const res = await getListProjectsByUser({ userId, pageNumber: page - 1, pageSize });
      if (res) {
        setDataSourceProjects(res);
      }
      setIsLoadingProjectsTable(false);
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  function handleClickTransaction(transaction: TProjectTransaction) {
    if (transaction.status === 'deleted') return;
    window.open(`${ERoutes.DETAIL_TRANSACTION}/${transaction.id}`, '_blank');
    // navigate(`${ERoutes.DETAIL_TRANSACTION}/${transaction.id}`);
  }

  async function handleExportCSV() {
    try {
      if (!userId) return;
      const res = await exportCSVUserData({ userId });
      if (res.status === EHttpStatusCode.OK) {
        downloadFileLocally(res);
        notification.open({ type: 'success', message: t('Shared.Texts.Exported') });
      }
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleDeleteUser() {
    try {
      if (!userId) return;
      const res = await deleteUser({ userId });
      if (res === EHttpStatusCode.OK) {
        notification.open({ type: 'success', message: t('Pages.Admin.UserDetail.Deleted') });
      }
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleUpdateUser() {
    try {
      if (!userId) return;
      const { credit, minutes, subscriptionExpriryDate } = formUserInfo.getFieldsValue();
      const res = await updateUser({
        userId,
        credit,
        subscriptionCredit: minutes,
        subscriptionCreditExpireDate: subscriptionExpriryDate.toDate().getTime()
      });
      if (res) {
        notification.open({ type: 'success', message: t('Shared.Texts.Updated') });
        setCurrentUserDetail(res);
        formUserInfo.setFieldsValue({
          subscriptionExpriryDate: dayjs(res.subscriptionCreditExpireDate || '01/01/1970'),
          credit: res.credit,
          minutes: res.subscriptionCredit
        });
        setIsHasChanged(false);
      }
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleSyncSubscription() {
    try {
      if (!userId) return;
      const res = await syncSubscription({ userId });
      if (res) {
        notification.open({
          type: 'success',
          message: t('Pages.Admin.UserDetail.SubscriptionSyncSuccess', {
            email: currentUserDetail?.email
          })
        });
        handleGetStripeInfor({ id: userId });
        handleGetCurrentUserDetail({ id: userId });
        handleGetSubscription({ id: userId });
      }
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  return (
    <div className="user-detail">
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome
            size={30}
            color="var(--si-primary)"
            onClick={() => navigate(ERoutes.USER_LOOKUP)}
            className="cursor-pointer"
          />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            {t('Pages.Admin.UserDetail.Title')}
          </span>
        </div>
      </div>
      <div className="container py-2">
        <Form
          form={formUserInfo}
          onFieldsChange={() => {
            setIsHasChanged(true);
          }}
        >
          <div className="user-infor p-3 mb-5" style={{ background: '#edf1f2' }}>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4>{t('Pages.Admin.UserDetail.UserInfo')}</h4>
              <div>
                <Button className="me-1" type="primary" onClick={handleExportCSV}>
                  {t('Pages.Admin.UserDetail.ExportUserAsCSV')}
                </Button>
                <Popconfirm
                  title={t('Pages.Admin.UserDetail.DeleteUser')}
                  placement="bottom"
                  description={t('Pages.Admin.UserDetail.UserDeletionConfirm')}
                  onConfirm={handleDeleteUser}
                  okText={t('Shared.Texts.Yes')}
                  cancelText={t('Shared.Texts.No')}
                >
                  <Button type="primary" danger>
                    {t('Pages.Admin.UserDetail.DeleteUser')}
                  </Button>
                </Popconfirm>
              </div>
            </div>
            <div>
              <div className="row g-3">
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">{t('Shared.Fields.Email')}</small>
                  </div>
                  <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
                    {currentUserDetail?.email}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">{t('Shared.Fields.Name')}</small>
                  </div>
                  <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
                    {currentUserDetail?.fullName}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">{t('Shared.Fields.Role')}</small>
                  </div>
                  <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
                    {currentUserDetail?.role.name}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">{t('Shared.Fields.JoinedAt')}</small>
                  </div>
                  <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
                    {moment(currentUserDetail?.regDate).format('MM/DD/YYYY h:MM A')}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">{t('Shared.Fields.Plan')}</small>
                  </div>
                  <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
                    {currentUserDetail?.plan.fullLabel}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">{t('Shared.Fields.Team')}</small>
                  </div>
                  <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
                    {currentUserDetail?.team ? t('Shared.Texts.Yes') : t('Shared.Texts.No')}
                  </div>
                </div>
                {currentUserDetail?.team && (
                  <div className="col-12 col-md-6">
                    <div className="mb-1">
                      <small className="fw-bold">
                        {t('Shared.Fields.TeamOwner')}{' '}
                        <Link
                          className="custom-a-tag"
                          to={`${ERoutes.TEAM}/${currentUserDetail?.team.id}`}
                        >
                          {t('Pages.Admin.UserDetail.SeeTeam')}
                        </Link>
                      </small>
                    </div>
                    <div className="border rounded  px-2 py-1">{currentUserDetail?.team.owner}</div>
                  </div>
                )}
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">{t('Shared.Fields.CreditMins')}</small>
                  </div>
                  <div className="border rounded" style={{ minHeight: 34, background: 'white' }}>
                    <Form.Item name={'credit'} className="mb-0">
                      <InputNumber placeholder="Credit" bordered={false} className="w-100" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <h4 className="my-4">{t('Pages.Admin.UserDetail.SubscriptionCredit')}</h4>
              <div className="row g-3">
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">{t('Shared.Fields.Minutes')}</small>
                  </div>
                  <div className="border rounded" style={{ minHeight: 34, background: 'white' }}>
                    <Form.Item name={'minutes'} className="mb-0">
                      <InputNumber
                        placeholder={t('Shared.Fields.Minutes')}
                        variant="borderless"
                        className="w-100"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">{t('Shared.Fields.ExpiryDate')}</small>
                  </div>
                  <div className="border rounded" style={{ minHeight: 34, background: 'white' }}>
                    <Form.Item name={'subscriptionExpriryDate'} className="mb-0">
                      <DatePicker format={'MM/DD/YYYY'} bordered={false} className="w-100" />
                    </Form.Item>
                  </div>
                </div>
                {isHasChanged && (
                  <div className="col-12">
                    <div>
                      <Button type="primary" className="me-1" onClick={handleUpdateUser}>
                        {t('Shared.Actions.Save')}
                      </Button>
                      <Button
                        type="primary"
                        danger
                        onClick={() => {
                          setIsHasChanged(false);
                          formUserInfo.setFieldsValue({
                            subscriptionExpriryDate: dayjs(
                              currentUserDetail?.subscriptionCreditExpireDate
                            ),
                            credit: currentUserDetail?.credit,
                            minutes: currentUserDetail?.subscriptionCredit
                          });
                        }}
                      >
                        {t('Shared.Actions.Cancel')}
                      </Button>
                    </div>
                  </div>
                )}

                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">{t('Shared.Fields.StripeSubscription')}</small>
                  </div>
                  <div className="border rounded px-2 py-1" style={{ minHeight: 34 }}>
                    {currentSubscription?.id}{' '}
                    <span className="fw-bold">{currentSubscription?.plan?.id}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6 align-self-end">
                  <Button style={{ minHeight: 34 }} type="primary" onClick={handleSyncSubscription}>
                    {t('Pages.Admin.UserDetail.SyncWithStripe')}
                  </Button>
                </div>
              </div>
              <Table
                loading={isLoadingCustomerTable}
                className="mt-2"
                dataSource={dataSourceCustomer}
                rowKey={(val) => val.id + Math.random()}
                columns={columnTableStripeCustomer}
                scroll={{ x: 1110 }}
                pagination={false}
              />
            </div>
          </div>
        </Form>
        <div className="transactions pt-3 pb-1 px-3 mb-5" style={{ background: '#edf1f2' }}>
          <h4>{t('Shared.Fields.Transactions')}</h4>
          <Table
            loading={isLoadingTransactionsTable}
            className="mt-2"
            scroll={{ x: 1110 }}
            dataSource={dataSourceTransactions.content
              .filter((transaction) => transaction.id)
              .map((transaction) => {
                return {
                  ...transaction,
                  creditCost: `${transaction.credit || 0} mins / $${transaction.price || 0}`,
                  userTranscribed: `${transaction.username} </br> ${transaction.userEmail}`,
                  status: transaction.status === 'ready' ? 'Transcribed' : transaction.status
                };
              })}
            rowKey={(val) => val.id + Math.random()}
            columns={columnTableTransactions}
            onRow={(record) => {
              return {
                onClick: () => handleClickTransaction(record)
              };
            }}
            rowClassName={(record) => {
              if (record.status.toLowerCase().includes('deleted'))
                return 'cursor-pointer hightlight';
              if (record.status.toLowerCase().includes('failed'))
                return 'cursor-pointer failed-transaction-row ';
              return 'cursor-pointer';
            }}
            pagination={{
              total: dataSourceTransactions.totalElements,
              pageSize: defaultSize,
              showSizeChanger: false,
              position: ['bottomCenter'],
              onChange: (page: number, pageSize: number) =>
                handleOnPageSizeChangeTransactions(page, pageSize)
            }}
          />
        </div>
        <div className="projects pt-3 pb-1 px-3 mb-5" style={{ background: '#edf1f2' }}>
          <h4>{t('Shared.Fields.Projects')}</h4>
          <Table
            loading={isLoadingProjectsTable}
            className="mt-2"
            dataSource={dataSourceProjects.content.map((project) => {
              return {
                ...project,
                creditCost: `${project.credit || 0} mins / $${project.price || 0}`,
                userTranscribed: `${project.username} \n ${project.userEmail}`,
                status: project.status === 'ready' ? t('Shared.Texts.Transcribed') : project.status
              };
            })}
            rowKey={(val) => val.id + Math.random()}
            columns={columnTableProjects}
            onRow={(record) => {
              return {
                onClick: () => handleClickTransaction(record)
              };
            }}
            rowClassName={(record) => {
              if (record.status.toLowerCase().includes('deleted'))
                return 'cursor-pointer hightlight';
              if (record.status.toLowerCase().includes('failed'))
                return 'cursor-pointer failed-transaction-row ';
              return 'cursor-pointer';
            }}
            pagination={{
              total: dataSourceProjects.totalElements,
              pageSize: defaultSize,
              showSizeChanger: false,
              position: ['bottomCenter'],
              onChange: (page: number, pageSize: number) =>
                handleOnPageSizeChangeProjects(page, pageSize)
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default UserDetailPage;
