import { AutoComplete, Form, Radio, Space, Table, notification } from 'antd';
import { getListUsersDelete, getListUsersWithKeyword, getListUsersWithType } from 'apis/admin/user';
import { useLoaderContext } from 'context/LoaderContext';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import { FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { EFilterUserType, ERoutes } from 'ts/enums';
import { TUser } from 'ts/types';

type DataSourceType = {
  fullName: string;
  email: string;
  ipAddress: string;
  id: string;
  joinAt: string | null;
  deletedAt: string | null;
  deletedBy: string | null;
};

function UserLookUpPage() {
  const [listUsers, setListUsers] = useState<TUser[]>([]);
  const [dataSource, setDataSource] = useState<DataSourceType[]>([]);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const navigate = useNavigate();
  const [typeFilterUser, setTypeFilterUser] = useState<EFilterUserType>(
    EFilterUserType.RECENT_ACTIVE
  );
  const [isSearchUserDone, setIsSearchUserDone] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const { t } = useTranslation();
  const { loader } = useLoaderContext();

  const columnsTableRecently = [
    {
      title: t('Shared.Fields.Name'),
      dataIndex: 'fullName',
      key: 'fullName'
    },
    {
      title: t('Shared.Fields.Email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('Shared.Fields.IPAddress'),
      dataIndex: 'ipAddress',
      key: 'ipAddress'
    }
  ];

  const columnsTableNewJoin = [
    {
      title: t('Shared.Fields.Name'),
      dataIndex: 'fullName',
      key: 'fullName'
    },
    {
      title: t('Shared.Fields.Email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('Shared.Fields.JoinedAt'),
      dataIndex: 'joinAt',
      key: 'joinAt'
    },
    {
      title: t('Shared.Fields.IPAddress'),
      dataIndex: 'ipAddress',
      key: 'ipAddress'
    }
  ];

  const columnsTableDeleted = [
    {
      title: t('Shared.Fields.Name'),
      dataIndex: 'fullName',
      key: 'fullName'
    },
    {
      title: t('Shared.Fields.Email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('Shared.Fields.DeletedAt'),
      dataIndex: 'deletedAt',
      key: 'deletedAt'
    },
    {
      title: t('Shared.Fields.DeletedBy'),
      dataIndex: 'deletedBy',
      key: 'deletedBY'
    }
  ];

  useEffect(() => {
    if (typeFilterUser === EFilterUserType.DELETE) {
      handleGetListUsersDeleted();
    } else {
      handleGetListUsers({ type: typeFilterUser });
    }
  }, [typeFilterUser]);

  async function handleGetListUsers({
    type,
    topCount
  }: {
    type: EFilterUserType;
    topCount?: number;
  }) {
    try {
      loader.start();
      setIsLoadingTable(true);
      const res = await getListUsersWithType({ type, topCount });
      if (res && res?.length > 0) {
        setDataSource(
          res.map((user) => {
            return {
              fullName: user.fullName,
              email: user.email,
              ipAddress: '',
              id: user.id,
              joinAt: moment(user.regDate).format('MMM DD, YYYY'),
              deletedAt: null,
              deletedBy: null
            };
          })
        );
      }
      loader.complete();
      setIsLoadingTable(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleGetListUsersDeleted() {
    try {
      loader.start();
      setIsLoadingTable(true);
      const res = await getListUsersDelete();
      if (res && res?.length > 0) {
        setDataSource(
          res.map((user) => {
            return {
              fullName: user.fullName,
              email: user.email,
              ipAddress: '',
              id: Math.random().toString(),
              joinAt: null,
              deletedAt: user.regDate,
              deletedBy: user.deletedBy
            };
          })
        );
      }
      loader.complete();
      setIsLoadingTable(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  const handleChange = (userId: string) => {
    navigate(`${ERoutes.USER_LOOKUP}/${userId}`);
  };

  const debouceSearch = debounce(async (value: string) => {
    if (value.length < 2) {
      setListUsers([]);
      setIsSearchUserDone(false);
      return;
    }
    loader.start();
    const res = await getListUsersWithKeyword({ keyword: value });
    if (res) {
      setListUsers(res);
      setIsSearchUserDone(true);
    } else {
      setListUsers([]);
      setIsSearchUserDone(true);
    }
    loader.complete();
  }, 1000);

  const handleSearch = debounce((keyword: string) => {
    setSearchKeyword(keyword);
    setIsSearchUserDone(false);
    debouceSearch(keyword);
  }, 1000);

  function renderTable() {
    switch (typeFilterUser) {
      case EFilterUserType.RECENT_ACTIVE:
        return columnsTableRecently;
      case EFilterUserType.RECENT_JOINED:
        return columnsTableNewJoin;
      case EFilterUserType.DELETE:
        return columnsTableDeleted;
      default:
        return columnsTableRecently;
    }
  }

  return (
    <div className="admin-user-lookup">
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome
            size={30}
            color="var(--si-primary)"
            onClick={() => navigate(ERoutes.LASTEST_TRANSACTION)}
            className="cursor-pointer"
          />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            {t('Pages.Admin.UserLookup.Title')}
          </span>
        </div>
      </div>
      <div className="container py-2">
        <Form layout="vertical" className="border p-2">
          <div className="row">
            <Form.Item
              name={'userSelected'}
              label={t('Shared.Fields.User')}
              className="col-12 col-md-6"
            >
              <AutoComplete
                className={`w-100 user-search ${searchKeyword.length > 1 ? 'show-clear-icon' : ''}`}
                options={listUsers.map((user) => ({ value: user.email }))}
                onSelect={(value) => {
                  const user = listUsers.find((user) => user.email === value);
                  if (user) {
                    handleChange(user.id);
                  }
                }}
                onSearch={handleSearch}
                placeholder={t('Pages.Admin.UserLookup.SearchUser')}
                allowClear={{ clearIcon: <AiOutlineClose /> }}
                notFoundContent={
                  searchKeyword.length > 1 && isSearchUserDone
                    ? t('Shared.Texts.NoMatchesFound')
                    : null
                }
              />
            </Form.Item>

            <Form.Item
              name={'filterUser'}
              label={<div />}
              className="col-12 col-md-6"
              initialValue={EFilterUserType.RECENT_ACTIVE}
            >
              <Radio.Group
                onChange={(e) => {
                  setTypeFilterUser(e.target.value);
                }}
                value={typeFilterUser}
              >
                <Space direction="vertical">
                  <Radio value={EFilterUserType.RECENT_ACTIVE}>
                    {t('Pages.Admin.UserLookup.RecentlyUsed')}
                  </Radio>
                  <Radio value={EFilterUserType.RECENT_JOINED}>
                    {t('Pages.Admin.UserLookup.NewlyJoined')}
                  </Radio>
                  <Radio value={EFilterUserType.DELETE}>{t('Shared.Texts.Deleted')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
        </Form>
        <Table
          style={{ overflow: 'auto' }}
          loading={isLoadingTable}
          className="mt-2"
          dataSource={dataSource}
          columns={renderTable()}
          pagination={false}
          rowKey={'id'}
          rowClassName={'cursor-pointer'}
          onRow={(userData) => {
            return {
              onClick: (e) => {
                e.stopPropagation();
                if (typeFilterUser !== EFilterUserType.DELETE) {
                  window.open(
                    `${ERoutes.USER_LOOKUP}/${userData.id}`,
                    '_blank',
                    'noopener,noreferrer'
                  );
                }
              }
            };
          }}
        />
      </div>
    </div>
  );
}

export default UserLookUpPage;
