import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  createDictionaryAction,
  deleteDictionaryAction,
  getDictionaryAction,
  updateDictionaryAction
} from './dictionary.action';
import { TDictionary, TLanguage } from 'ts/types';

type DictionaryState = {
  currentDictionary: {
    data: TDictionary[];
    isLoading: boolean;
    isFetched: boolean;
    error: any;
  };
  isDictionaryScreen: boolean;
};

const initialDictionaryState: DictionaryState = {
  currentDictionary: {
    data: [],
    isLoading: false,
    isFetched: false,
    error: null
  },
  isDictionaryScreen: false
};

const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState: initialDictionaryState,
  reducers: {
    setDictionaryScreen: (state, { payload }: PayloadAction<{ isDictionaryScreen: boolean }>) => {
      state.isDictionaryScreen = payload.isDictionaryScreen;
    }
  },
  extraReducers(builder) {
    //Get dictionary
    builder.addCase(getDictionaryAction.pending, (state) => {
      state.currentDictionary = { ...state.currentDictionary, isLoading: true, isFetched: false };
    });
    builder.addCase(getDictionaryAction.fulfilled, (state, { payload }) => {
      state.currentDictionary = {
        ...state.currentDictionary,
        data: payload,
        isLoading: false,
        isFetched: true
      };
    });
    builder.addCase(getDictionaryAction.rejected, (state, { payload }) => {
      state.currentDictionary = {
        ...state.currentDictionary,
        data: [],
        isLoading: false,
        isFetched: true,
        error: payload
      };
    });

    //Create dictionary
    builder.addCase(createDictionaryAction.pending, (state) => {
      state.currentDictionary = { ...state.currentDictionary };
    });
    builder.addCase(createDictionaryAction.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentDictionary = {
          ...state.currentDictionary,
          data: [payload, ...state.currentDictionary.data]
        };
      }
    });
    builder.addCase(createDictionaryAction.rejected, (state, { payload }) => {
      state.currentDictionary = {
        ...state.currentDictionary,
        data: [],
        isLoading: false,
        isFetched: true,
        error: payload
      };
    });

    //Delete dictionary
    builder.addCase(deleteDictionaryAction.pending, (state) => {
      state.currentDictionary = { ...state.currentDictionary };
    });
    builder.addCase(deleteDictionaryAction.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentDictionary = {
          ...state.currentDictionary,
          data: [
            ...state.currentDictionary.data.filter((dictionary) => dictionary.id !== payload.id)
          ]
        };
      }
    });

    //Update dictionary
    builder.addCase(updateDictionaryAction.pending, (state) => {
      state.currentDictionary = { ...state.currentDictionary };
    });
    builder.addCase(updateDictionaryAction.fulfilled, (state, { payload }) => {
      if (payload) {
        const tmpListDictionary = state.currentDictionary.data;
        const findDictionary = tmpListDictionary.find((dictionary) => dictionary.id === payload.id);

        if (findDictionary) {
          findDictionary.creationDate = payload.creationDate;
          findDictionary.name = payload.name;
          findDictionary.languageId = payload.languageId;
          findDictionary.languageLabel = payload.languageLabel;
          findDictionary.modifiedDate = payload.modifiedDate;
          findDictionary.tags = payload.tags;
        }

        state.currentDictionary = {
          ...state.currentDictionary,
          data: tmpListDictionary
        };
      }
    });
    builder.addCase(updateDictionaryAction.rejected, (state, { payload }) => {
      state.currentDictionary = {
        ...state.currentDictionary,
        data: [],
        isLoading: false,
        isFetched: true,
        error: payload
      };
    });
  }
});

export const { setDictionaryScreen } = dictionarySlice.actions;

export default dictionarySlice.reducer;
