import React, { useState, useEffect } from 'react';

type Props = {
  currentPage: number;
  total: number;
  pageSize: number;
  // setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  activePage: number[];
};

const ListOptionsPageSize = [50, 100, 200, 500];

const Pagination = ({
  total,
  currentPage,
  setCurrentPage,
  pageSize,
  // setPageSize,
  activePage
}: Props) => {
  const [rangeWithDots, setRangeWithDots] = useState<any[]>([]);

  function pagination(c: number, m: number) {
    var current = c,
      last = m,
      delta = 1,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  function handleCheckStyle(range: any, currentPage: number) {
    const isActive = activePage.length > 0 ? activePage.includes(range) : true;

    switch (range) {
      case '...':
        return 'pagination-item';
      case currentPage:
        return `pagination-item --selected ms-1 me-1 px-1`;
      default:
        return `pagination-item ${
          isActive ? 'cursor-pointer' : '--disabled cursor-disabled'
        } ms-1 me-1 px-1`;
    }
  }

  function prevPage() {
    if (activePage.length === 1) {
      return;
    }
    if (currentPage !== 1) {
      let prevPage = currentPage - 1;

      if (activePage.length > 0)
        while (!activePage.includes(prevPage)) {
          prevPage -= 1;
        }

      setCurrentPage(prevPage);
    }
  }

  function nextPage() {
    if (activePage.length === 1) {
      return;
    }
    if (currentPage !== Math.ceil(total / pageSize)) {
      let nextPage = currentPage + 1;

      if (activePage.length > 0)
        while (!activePage.includes(nextPage)) {
          nextPage += 1;
        }
      setCurrentPage(nextPage);
    }
  }

  function isActiveChangePage(range: any) {
    return activePage.length > 0 ? activePage.includes(range) : true;
  }

  useEffect(() => {
    setRangeWithDots(pagination(currentPage, Math.ceil(total / pageSize)));
  }, [total, currentPage, pageSize]);

  return (
    <div className="d-flex gap-1 custom-pagination">
      <ul className="pagination">
        <li
          className={`pagination-item cursor-pointer ${
            currentPage === 1 || activePage.length === 1 ? 'cursor-disabled opacity-50' : ''
          }`}
          onClick={prevPage}
        >{`<`}</li>
        {rangeWithDots.map((range) => {
          return (
            <li
              className={`${handleCheckStyle(range, currentPage)}`}
              key={range}
              onClick={() => range !== '...' && isActiveChangePage(range) && setCurrentPage(range)}
            >
              {range}
            </li>
          );
        })}
        <li
          className={`pagination-item cursor-pointer ${
            currentPage === Math.ceil(total / pageSize) || activePage.length === 1
              ? 'cursor-disabled opacity-50'
              : ''
          }`}
          onClick={nextPage}
        >{`>`}</li>
      </ul>
      {/* <select
        name="page-size"
        id="page-size"
        defaultValue={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.currentTarget.value));
        }}
      >
        {ListOptionsPageSize.map((size) => {
          return (
            <option key={size} value={size}>
              {size} rows
            </option>
          );
        })}
      </select> */}
    </div>
  );
};

export default Pagination;
