/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TMedia, TProject, TSpeaker } from 'ts/types';
import {
  deleteProjectAction,
  changeMediaForProjectAction,
  getListProjectAction,
  getListProjectShareWithMeAction,
  getProjectByIdAction,
  moveProjectToFolderAction,
  updateCurrentProjectAction,
  createNewMediaAction
} from './projects.action';
import { EFolderIdDefault, EMoveProject, EUploaderType } from 'ts/enums';
import { TRequestMoveProjectToFolder } from 'ts/types/TRequest';

type ProjectsState = {
  currentProject: {
    data: TProject | null;
    isLoading: boolean;
    error: any;
    isFetched: boolean;
    activeOpenPaymentModal: boolean;
  };
  listProjectShareWithMe: {
    data: Array<TProject>;
    isLoading: boolean;
    error: any;
    isFetched: boolean;
  };
  listBaseProject: {
    data: Array<TProject>;
    isLoading: boolean;
    error: any;
    isFetched: boolean;
  };
  listProjectAll: {
    data: Array<TProject>;
  };
  listMyProject: {
    data: Array<TProject>;
  };
  listProjectFilterFolder: {
    data: Array<TProject>;
  };
  listTagsSelected: number[];
  translateProjectId: string | null;
  projectSelected: TProject[];
  isSelectMultiple: boolean;
};

const initialState: ProjectsState = {
  currentProject: {
    data: null,
    isLoading: false,
    error: null,
    isFetched: false,
    activeOpenPaymentModal: false
  },
  listBaseProject: {
    data: [],
    isLoading: false,
    error: null,
    isFetched: false
  },
  listProjectShareWithMe: {
    data: [],
    isLoading: false,
    error: null,
    isFetched: false
  },
  listProjectAll: {
    data: []
  },
  listMyProject: {
    data: []
  },
  listProjectFilterFolder: {
    data: []
  },
  listTagsSelected: [],
  translateProjectId: null,
  projectSelected: [],
  isSelectMultiple: false
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    updateNameProject: (state, { payload }: PayloadAction<{ name: string }>) => {
      if (state.currentProject.data)
        state.currentProject.data = { ...state.currentProject.data, name: payload.name };
    },
    filterListProjectWithTags: (state, { payload }: PayloadAction<{ tagId: number[] }>) => {
      state.listTagsSelected = payload.tagId;
    },

    searchProjectListWithFolder: (state, { payload }: PayloadAction<{ folderID: string }>) => {
      const listBaseProject = [...state.listBaseProject.data];
      const listProjectShareWithMe = [...state.listProjectShareWithMe.data];
      switch (payload.folderID) {
        case EFolderIdDefault.PROJECT_ALL: {
          state.listProjectFilterFolder = state.listBaseProject;
          return;
        }
        case EFolderIdDefault.SHARED_WITH_ME: {
          state.listProjectFilterFolder = state.listProjectShareWithMe;
          return;
        }
        case EFolderIdDefault.MY_PROJECT: {
          const newListProject = listBaseProject.filter(
            (elem) => !listProjectShareWithMe.find(({ id }) => elem.id === id)
          );
          state.listProjectFilterFolder.data = newListProject;
          return;
        }
        default: {
          state.listProjectFilterFolder.data = listBaseProject.filter(
            (p) => p.directory?.id === payload.folderID
          );
          return;
        }
      }
    },

    updateListBaseProject: (state, { payload }: PayloadAction<{ project: TProject }>) => {
      const tmpListBase = [...state.listBaseProject.data];
      const tmpListAll = [...state.listProjectAll.data];
      const tmpMyList = [...state.listMyProject.data];
      const tmpListFolder = [...state.listProjectFilterFolder.data];

      const findIndexListBase = tmpListBase.findIndex(
        (project) => project.id === payload.project.id
      );
      const findIndexListAll = tmpListAll.findIndex((project) => project.id === payload.project.id);
      const findIndexMyList = tmpMyList.findIndex((project) => project.id === payload.project.id);
      const findIndexListFolder = tmpListFolder.findIndex(
        (project) => project.id === payload.project.id
      );

      if (findIndexListBase >= 0) {
        tmpListBase[findIndexListBase] = payload.project;
        state.listBaseProject = { ...state.listBaseProject, data: tmpListBase };
      }

      if (findIndexListAll >= 0) {
        tmpListAll[findIndexListAll] = payload.project;
        state.listProjectAll = { ...state.listProjectAll, data: tmpListAll };
      }

      if (findIndexMyList >= 0) {
        tmpMyList[findIndexMyList] = payload.project;
        state.listMyProject = { ...state.listMyProject, data: tmpMyList };
      }

      if (findIndexListFolder >= 0) {
        tmpListFolder[findIndexListFolder] = payload.project;
        state.listProjectFilterFolder = { ...state.listProjectFilterFolder, data: tmpListFolder };
      }
    },

    updateListSpeakers: (
      state,
      { payload }: PayloadAction<{ speaker: TSpeaker; mediaId: string }>
    ) => {
      const copyCurrentProject = { ...state.currentProject };
      const findMedia = copyCurrentProject.data?.medias.find(
        (media) => media.id === payload.mediaId
      );

      if (findMedia) {
        const findSpeakerExist = findMedia.speakers.findIndex(
          (speaker) => speaker.id === payload.speaker.id
        );
        if (findSpeakerExist === -1) {
          findMedia.speakers.push(payload.speaker);
        } else {
          findMedia.speakers[findSpeakerExist].name = payload.speaker.name;
        }
      }
      state.currentProject = copyCurrentProject;
    },

    updateStatusMedia: (
      state,
      {
        payload
      }: PayloadAction<{
        mediaId: string;
        status: string;
        uploadInfor: {
          progress: number;
          total?: number;
          loaded?: number;
        };
      }>
    ) => {
      const tempCurrentProject = { ...state.currentProject }.data;
      if (tempCurrentProject) {
        const tempListMedias = tempCurrentProject.medias;
        if (tempListMedias) {
          const findItem = tempListMedias.find((media) => media.id === payload.mediaId);
          if (findItem) {
            findItem.status = payload.status;
            if (findItem.uploadInfor) {
              findItem.uploadInfor.progress = payload.uploadInfor.progress;
              if (payload.uploadInfor.loaded && payload.uploadInfor.total) {
                findItem.uploadInfor.loaded = payload.uploadInfor.loaded;
                findItem.uploadInfor.total = payload.uploadInfor.total;
              }
            }
          }
        }
        state.currentProject = {
          ...state.currentProject,
          data: tempCurrentProject
        };
      }
    },

    updateListMediasInCurrentProject: (state, { payload }: PayloadAction<{ media: TMedia }>) => {
      const tempCurrentProject = { ...state.currentProject }.data;
      if (tempCurrentProject) {
        const tempListMedias = tempCurrentProject.medias;
        if (tempListMedias) {
          const findIdx = tempListMedias.findIndex((media) => media.id === payload.media.id);
          if (findIdx !== -1) {
            tempListMedias[findIdx] = { ...payload.media };
          }
        }
        state.currentProject = {
          ...state.currentProject,
          data: tempCurrentProject
        };
      }
    },
    changeTimeMedia: (state, { payload }: PayloadAction<{ mediaId: string }>) => {
      const copyCurrentProject = { ...state.currentProject };
      const findMedia = copyCurrentProject.data?.medias.find(
        (media) => media.id === payload.mediaId
      );

      if (findMedia) {
        findMedia.lastSavedTranscript = new Date().toString();
      }
      state.currentProject = copyCurrentProject;
    },
    resetCurrentProject: (state) => {
      state.currentProject = {
        data: null,
        isLoading: true,
        error: null,
        isFetched: false,
        activeOpenPaymentModal: false
      };
    },
    changeActiveOpenPaymentModal: (state, { payload }: PayloadAction<boolean>) => {
      state.currentProject = {
        ...state.currentProject,
        activeOpenPaymentModal: payload
      };
    },
    updateItemProjectFolder: (state, { payload }: PayloadAction<TRequestMoveProjectToFolder>) => {
      const listUpdateProject = state.listBaseProject.data.map((project: TProject) => {
        if (payload.projectsId.includes(project.id)) {
          if (payload.isFrom === EMoveProject.MAIN_TO_FOLDER) {
            return { ...project, directory: payload.folder };
          } else {
            return { ...project, directory: null };
          }
        }
        return project;
      });

      state.listBaseProject.data = listUpdateProject;

      state.listProjectAll = {
        data: listUpdateProject.filter((project) => !project.directory)
      };
    },
    reloadCurrentProject: (state, { payload }: PayloadAction<{ project: TProject }>) => {
      state.currentProject = { ...state.currentProject, data: payload.project };
    },
    updateTranslateProjectId: (
      state,
      { payload }: PayloadAction<{ translateProjectId: string | null }>
    ) => {
      state.translateProjectId = payload.translateProjectId;
    },
    updateCurrentProject: (state, { payload }: PayloadAction<{ project: TProject }>) => {
      state.currentProject = { ...state.currentProject, data: payload.project };
    },
    selectProjects: (state, { payload }: PayloadAction<{ projects: TProject[] }>) => {
      state.projectSelected = payload.projects;
    },
    changeSelectMode: (state, { payload }: PayloadAction<{ isSelectMultiple: boolean }>) => {
      state.isSelectMultiple = payload.isSelectMultiple;
    }
  },
  extraReducers: ({ addCase }) => {
    //Get list project by id
    addCase(getProjectByIdAction.pending, (state) => {
      state.currentProject = {
        data: state.currentProject.data,
        isLoading: true,
        error: null,
        isFetched: false,
        activeOpenPaymentModal: state.currentProject.activeOpenPaymentModal
      };
    });

    addCase(getProjectByIdAction.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentProject = {
          data: {
            ...payload,
            medias:
              [...payload.medias].map((media) => {
                const progress =
                  media.fileUploadType !== EUploaderType.LOCAL
                    ? (media.prepareProgressPercentage + media.transcodedVideoProgressPercentage) /
                      2
                    : (media.prepareProgressPercentage + media.transcodedVideoProgressPercentage) /
                        2 +
                      50;
                return {
                  ...media,
                  uploadInfor: {
                    progress: progress
                  }
                };
              }) || []
          },
          isLoading: false,
          error: null,
          isFetched: true,
          activeOpenPaymentModal: state.currentProject.activeOpenPaymentModal
        };
      }
    });

    addCase(getProjectByIdAction.rejected, (state, { payload }) => {
      state.currentProject = {
        data: null,
        isLoading: false,
        error: payload,
        isFetched: true,
        activeOpenPaymentModal: state.currentProject.activeOpenPaymentModal
      };
    });

    //Update current project
    addCase(updateCurrentProjectAction.pending, (state) => {
      state.currentProject = { ...state.currentProject };
    });

    addCase(updateCurrentProjectAction.fulfilled, (state, { payload }) => {
      state.currentProject = { ...state.currentProject, data: payload };
    });

    addCase(updateCurrentProjectAction.rejected, (state, { payload }) => {
      state.currentProject = { ...state.currentProject, data: null, error: payload };
    });

    //Get list project all
    addCase(getListProjectAction.pending, (state) => {
      state.listProjectAll = { ...state.listProjectAll };
      state.listBaseProject = {
        data: [...state.listBaseProject.data],
        isLoading: true,
        isFetched: false,
        error: null
      };
    });

    addCase(getListProjectAction.fulfilled, (state, { payload }) => {
      if (payload) {
        state.listBaseProject = {
          data: payload.data,
          isLoading: false,
          isFetched: true,
          error: null
        };
        state.listProjectAll = {
          data: payload.data.filter((project) => !project.directory)
        };
        state.listMyProject = {
          data: payload.data.filter(
            (project) => project.owner && project.owner.id === payload.user.id
          )
        };
      }
    });

    addCase(getListProjectAction.rejected, (state, { payload }) => {
      state.listProjectAll = {
        data: []
      };
      state.listBaseProject = {
        data: [],
        isLoading: false,
        isFetched: false,
        error: payload
      };
    });

    //Get list project share with me
    addCase(getListProjectShareWithMeAction.pending, (state) => {
      state.listProjectShareWithMe = { ...state.listProjectShareWithMe, isLoading: true };
    });

    addCase(getListProjectShareWithMeAction.fulfilled, (state, { payload }) => {
      if (payload) {
        state.listProjectShareWithMe = {
          isFetched: true,
          data: payload,
          isLoading: false,
          error: null
        };
      }
    });

    addCase(getListProjectShareWithMeAction.rejected, (state, { payload }) => {
      state.listProjectShareWithMe = { ...state.listProjectShareWithMe, error: payload };
    });

    //Edit directory in project
    addCase(moveProjectToFolderAction.pending, (state, { payload }) => {
      state.listBaseProject = { ...state.listBaseProject, error: null };
    });

    addCase(moveProjectToFolderAction.fulfilled, (state, { payload }) => {
      // const tmpListProjectBase = state.listBaseProject.data;
      // const findList = tmpListProjectBase.filter((project) =>
      //   payload.projectsId.includes(project.id)
      // );
      // const tmpListProjectAll = state.listProjectAll.data;
      // const findListProjectAll = tmpListProjectBase.filter((project) =>
      //   payload.projectsId.includes(project.id)
      // );
      // const tmpListProjectFilterFolder = state.listProjectFilterFolder.data;
      // const findListProjectFilterFolder = tmpListProjectFilterFolder.filter((project) =>
      //   payload.projectsId.includes(project.id)
      // );
      // if (findList.length > 0) {
      //   switch (payload.folder.id) {
      //     case EFolderIdDefault.MY_PROJECT:
      //     case EFolderIdDefault.PROJECT_ALL:
      //     case EFolderIdDefault.SHARED_WITH_ME: {
      //       tmpListProjectBase.forEach((project) => {
      //         if (payload.projectsId.includes(project.id)) {
      //           project.directory = null;
      //         }
      //       });
      //       state.listBaseProject.data = tmpListProjectBase;
      //       break;
      //     }
      //     default: {
      //       tmpListProjectBase.forEach((project) => {
      //         if (payload.projectsId.includes(project.id)) {
      //           project.directory = payload.folder;
      //         }
      //       });
      //       state.listBaseProject.data = tmpListProjectBase;
      //       break;
      //     }
      //   }
      // }
      // if (findListProjectAll.length > 0) {
      //   switch (payload.folder.id) {
      //     case EFolderIdDefault.MY_PROJECT:
      //     case EFolderIdDefault.PROJECT_ALL:
      //     case EFolderIdDefault.SHARED_WITH_ME: {
      //       const newList = tmpListProjectBase.filter((project) => project.directory === null);
      //       state.listProjectAll.data = newList;
      //       break;
      //     }
      //     default: {
      //       tmpListProjectAll.forEach((project) => {
      //         if (payload.projectsId.includes(project.id)) {
      //           project.directory = payload.folder;
      //         }
      //       });
      //       const newListProject = tmpListProjectAll.filter((project) => !project.directory);
      //       state.listProjectAll.data = newListProject;
      //       break;
      //     }
      //   }
      // }
      // if (findListProjectFilterFolder.length > 0) {
      //   const newListProject = tmpListProjectFilterFolder.filter(
      //     (item) => !payload.projectsId.includes(item.id)
      //   );
      //   state.listProjectFilterFolder.data = newListProject;
      //   switch (payload.folder.id) {
      //     case EFolderIdDefault.MY_PROJECT:
      //     case EFolderIdDefault.PROJECT_ALL:
      //     case EFolderIdDefault.SHARED_WITH_ME: {
      //       const newList = tmpListProjectBase.filter((project) => project.directory === null);
      //       state.listProjectAll.data = newList;
      //       return;
      //     }
      //     default: {
      //       tmpListProjectFilterFolder.forEach((project) => {
      //         if (payload.projectsId.includes(project.id)) {
      //           project.directory = payload.folder;
      //         }
      //       });
      //       return;
      //     }
      //   }
      // }
    });

    addCase(moveProjectToFolderAction.rejected, (state, { payload }) => {
      state.listBaseProject = { ...state.listBaseProject, error: payload };
    });

    //Delete project
    addCase(deleteProjectAction.pending, (state) => {
      state.listBaseProject = { ...state.listBaseProject };
    });

    addCase(deleteProjectAction.fulfilled, (state, { payload }) => {
      if (payload) {
        state.listBaseProject = {
          ...state.listBaseProject,
          data: state.listBaseProject.data.filter((f) => f.id !== payload.id)
        };
        state.listProjectAll = {
          ...state.listProjectAll,
          data: state.listProjectAll.data.filter((f) => f.id !== payload.id)
        };
        state.listMyProject = {
          ...state.listMyProject,
          data: state.listMyProject.data.filter((f) => f.id !== payload.id)
        };
        state.listProjectFilterFolder = {
          ...state.listProjectFilterFolder,
          data: state.listProjectFilterFolder.data.filter((f) => f.id !== payload.id)
        };
      }
    });

    addCase(deleteProjectAction.rejected, (state, { payload }) => {
      state.listBaseProject = { ...state.listBaseProject, error: payload };
    });
    //change media
    addCase(changeMediaForProjectAction.pending, (state) => {
      state.currentProject = {
        ...state.currentProject,
        error: null
      };
    });

    addCase(changeMediaForProjectAction.fulfilled, (state, { payload }) => {
      state.currentProject = {
        ...state.currentProject
      };
    });

    addCase(changeMediaForProjectAction.rejected, (state, { payload }) => {
      state.currentProject = {
        data: null,
        isLoading: false,
        error: payload,
        isFetched: true,
        activeOpenPaymentModal: state.currentProject.activeOpenPaymentModal
      };
    });

    addCase(createNewMediaAction.fulfilled, (state, { payload }) => {
      const tempCurrentProject = { ...state.currentProject }.data;
      if (payload) {
        if (tempCurrentProject) {
          const tempListMedias = tempCurrentProject.medias;
          if (tempListMedias) {
            const findIdx = tempListMedias.findIndex((media) => media.id === payload.id);
            if (findIdx === -1) {
              tempListMedias.push({
                ...payload,
                uploadInfor: {
                  progress: 0
                }
              });
            }
          }
          state.currentProject = {
            ...state.currentProject,
            data: tempCurrentProject
          };
        }
      }
    });
  }
});

export const {
  filterListProjectWithTags,
  searchProjectListWithFolder,
  updateListSpeakers,
  updateListBaseProject,
  updateStatusMedia,
  changeTimeMedia,
  updateListMediasInCurrentProject,
  resetCurrentProject,
  updateNameProject,
  changeActiveOpenPaymentModal,
  updateItemProjectFolder,
  reloadCurrentProject,
  updateTranslateProjectId,
  updateCurrentProject,
  selectProjects,
  changeSelectMode
} = projectsSlice.actions;

export default projectsSlice.reducer;
