import { Modal } from 'antd';
import { ReactNode } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useAppSelector } from 'redux/hooks';
import { EModals } from 'ts/enums';

type ModalProps = {
  modalName: EModals;
  children: ReactNode;
  className?: string;
  onCloseModal(): void;
  afterClose?: () => void;
  isCloseIcon?: boolean;
  customModalInner?: string;
  customIconClose?: ReactNode;
};

export function ModalBase({
  modalName,
  children,
  onCloseModal,
  afterClose,
  isCloseIcon = true,
  className,
  customModalInner,
  customIconClose
}: ModalProps) {
  const modalStack = useAppSelector((state) => state.modal.modalStack);
  const isOpen = modalStack.find((modal) => modal.name === modalName) !== undefined;
  function handleAfterClose() {
    afterClose && afterClose();
  }

  return (
    <Modal
      open={isOpen}
      centered
      className={`modal-base ${className}`}
      footer={null}
      destroyOnClose={true}
      onCancel={() => {
        onCloseModal();
      }}
      afterClose={handleAfterClose}
      modalRender={() => (
        <div className={`modal-inner ${customModalInner ? customModalInner : ''}`}>
          {children}
          {isCloseIcon && isCloseIcon && (
            <div className="close-icon" onClick={onCloseModal}>
              {customIconClose || <AiOutlineCloseCircle size={35} />}
            </div>
          )}
        </div>
      )}
    />
  );
}
