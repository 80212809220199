import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { AddCreditModal, SubscribeModal } from 'components/Header';
import { useAppSelector } from 'redux/hooks';
import { useEffect } from 'react';
import { VideoPlayerProvider } from 'context/VideoPlayerContext';
import { AutoScrollProvider } from 'context/AutoScrollContext';
import { TranslateModal } from 'components/Header/TranslateModal';
import { QuickTipsModal } from 'components/UploadMediaPage';
import { EditorProvider } from 'context/EditorContext';
import { ExportModal } from 'components/ExportModal';
import { PaymentModal } from 'components/PaymentModal';
import { HeaderTranscript } from './HeaderTranscrip';
import { ERole, ERoutes } from 'ts/enums';
import { AddDictionaryModal } from 'components/MainPageContainer/Dictionary/AddDictionaryModal';
import { BurnInModal } from 'components/ExportModal/BurnInModal';
import { HoorayModal } from 'components/PaymentModal/HoorayModal';
import { VersionHistoryModal } from 'components/TranscriptPage/VersionHistoryModal';
import { UpdateCreditCardModal } from 'components/AccountSetting/UpdateCreditCardModal';
import { ChooserOptionsPlanModal } from 'components/AccountSetting';
import { DubbingModal } from 'components/TranscriptPage/DubbingModal';
import { PriceDubbingModal } from 'components/TranscriptPage/PriceDubbingModal';
import { NotiModal } from 'components/common/NotiModal';
import { useAuth } from 'context/AuthContext';

export const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export const MainLayout = () => {
  const { isLogged } = useAuth();
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  if (!isLogged) {
    return <Navigate to={ERoutes.SIGN_IN} replace />;
  }

  return (
    <>
      <Header />
      <div id="box-upload" className="box-upload d-none" />
      <main id="main-element">
        <Outlet />
        <AddCreditModal />
        <SubscribeModal />
        <QuickTipsModal />
        <ExportModal />
        <PaymentModal />
        <AddDictionaryModal />
        <HoorayModal />
        <UpdateCreditCardModal />
        <ChooserOptionsPlanModal />
        {currentUser.data && <TranslateModal />}
        <BurnInModal />
        <NotiModal />
      </main>
      <Footer />
    </>
  );
};

export const TranscriptLayout = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const { isLogged } = useAuth();
  const params = useParams();
  const projectId = params.projectId;
  const navigate = useNavigate();

  useEffect(() => {
    if (projectId && isLogged) {
      navigate(`${ERoutes.TRANSCRIPT_DETAIL}/${projectId}`);
    }
  }, [projectId, isLogged]);

  if (!isLogged) {
    if (!currentUser.data) {
      localStorage.setItem('redirect-url', window.location.href);
    }
    if (currentUser.data) {
      localStorage.removeItem('redirect-url');
    }
    return <Navigate to={ERoutes.SIGN_IN} replace />;
  }

  return (
    <>
      <EditorProvider>
        <HeaderTranscript />
        <VideoPlayerProvider>
          <AutoScrollProvider>
            <Outlet />
            <ExportModal />
            <BurnInModal />
            <DubbingModal />
            <PriceDubbingModal />
            <NotiModal />
            <VersionHistoryModal />
            {currentUser.data && <TranslateModal />}
          </AutoScrollProvider>
        </VideoPlayerProvider>
      </EditorProvider>
    </>
  );
};

export const AdminLayout = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser.data);
  const { isLogged } = useAuth();

  if (!isLogged) {
    return <Navigate to={ERoutes.SIGN_IN} replace />;
  }

  if (
    currentUser &&
    currentUser.role.name !== ERole.ROLE_ADMIN &&
    currentUser.role.name !== ERole.ROLE_SUPERADMIN
  ) {
    return <Navigate to={ERoutes.MAIN} replace />;
  }
  return (
    <>
      <Header />
      <main style={{ backgroundColor: 'var(--bs-gray-100)' }} id="admin-layout">
        <Outlet />
        <SubscribeModal />
        <ExportModal />
        <NotiModal />
        <AddCreditModal />
      </main>
      <Footer />
    </>
  );
};
