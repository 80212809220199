export function mergeScript(oldTranscript: any, newTranscript: any) {
  const mergedTranscript = oldTranscript.map((transcript: any) => {
    const merge = newTranscript.find((t: any) => t.id === transcript.id);
    if (merge) {
      return merge;
    }
    return transcript;
  });
  return mergedTranscript;
}

export function divideArray<T>(array: T[], chunkSize: number): T[][] {
  var dividedArrays: T[][] = [];
  for (var i = 0; i < array.length; i += chunkSize) {
    dividedArrays.push(array.slice(i, i + chunkSize));
  }
  return dividedArrays;
}
