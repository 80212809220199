import { useAutoScroll } from 'context/AutoScrollContext';
import { useVideoPlayerContext } from 'context/VideoPlayerContext';
import { DraftModel } from 'draft-js';
import React, { ReactNode, useEffect } from 'react';

type Props = {
  contentState: DraftModel.ImmutableData.ContentState;
  entityKey: string;
  blockKey: string;
  children: ReactNode;
  offsetkey: string;
};

function TimeCodedWord(props: Props) {
  const { currentTime, seekTo } = useVideoPlayerContext();
  const { setIdBlockPlaying } = useAutoScroll();

  function checkIfHighlighted() {
    let currentTimeInSeconds = Number(currentTime.toFixed(2));
    const startTimeInSeconds = Number(getStarTime().toFixed(2));
    if (currentTimeInSeconds && currentTimeInSeconds >= startTimeInSeconds) {
      return true;
    } else {
      return false;
    }
  }
  function getStarTime() {
    return +props.contentState.getEntity(props.entityKey).getData().startTime;
  }

  function playTime(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    if (event.ctrlKey || event.metaKey) {
      event.stopPropagation();
      seekTo(getStarTime(), 'seconds');
    }
  }

  useEffect(() => {
    const startTime = +props.contentState.getEntity(props.entityKey).getData()?.startTime;
    const endTime = +props.contentState.getEntity(props.entityKey).getData()?.endTime;
    if (startTime <= currentTime && currentTime <= endTime) {
      setIdBlockPlaying(props.blockKey);
    }
  }, [currentTime, props]);

  const textStyle = {
    color: checkIfHighlighted() ? '#000' : '#6b6b6b'
  };

  return (
    <span
      data-offset-key={props.offsetkey}
      style={{ ...textStyle, transition: 'all 0.1s ease' }}
      onClick={(e) => {
        playTime(e);
      }}
    >
      {props.children}
    </span>
  );
}

export default TimeCodedWord;
