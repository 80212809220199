import { TSpeaker, TTranscripHistory, TTranscript } from 'ts/types';
import { TRequestCreateNewSpeaker, TRequestGetTranscriptionByMediaId } from 'ts/types/TRequest';
import { TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from './axios-client';

export async function getTranscriptionByMediaId({
  mediaId
}: TRequestGetTranscriptionByMediaId): Promise<TResponse<Array<TTranscript>>> {
  const res = await AxiosCustom.get(`media/${mediaId}/transcription`);
  return res.data;
}

export async function createNewSpeaker({
  name,
  color,
  mediaId
}: TRequestCreateNewSpeaker): Promise<TResponse<TSpeaker>> {
  const res = await AxiosCustom.post(`media/${mediaId}/speaker`, { name, color });
  return res.data;
}

export async function updateTranscription({
  mediaId,
  listTranscript
}: {
  mediaId: string;
  listTranscript: TTranscript[];
}) {
  const res = await AxiosCustom.post(`media/${mediaId}/transcript`, listTranscript);
  return res.status;
}

export async function updateSpeaker({
  speakerId,
  name,
  mediaId,
  color,
  sex
}: {
  mediaId: string;
  speakerId: number;
  name: string;
  color: string;
  sex: any;
}): Promise<TSpeaker> {
  const res = await AxiosCustom.put(`media/${mediaId}/speaker/${speakerId}`, {
    id: speakerId,
    name,
    color,
    sex
  });
  return res.data;
}

export async function transcribing({
  projectId,
  dictionaryId
}: {
  projectId: string;
  dictionaryId?: number;
}) {
  const res = await AxiosCustom.post(`projects/${projectId}/transcribing`, { dictionaryId });
  return res;
}

export async function getTimeHistoryTranscript({
  mediaId
}: {
  mediaId: string;
}): Promise<TResponse<Array<TTranscripHistory>>> {
  const res = await AxiosCustom.get(`media/${mediaId}/transcript-history`);
  return res.data;
}

export async function getTranscriptWithVersion({
  mediaId,
  version
}: {
  mediaId: string;
  version: number;
}): Promise<TResponse<Array<TTranscript>>> {
  const res = await AxiosCustom.get(`media/${mediaId}/transcription?version=${version}`);
  return res.data;
}

export async function revertTranscriptWithVersion({
  mediaId,
  version,
  transcript
}: {
  mediaId: string;
  version: number;
  transcript: TTranscript[];
}) {
  const res = await AxiosCustom.post(`media/${mediaId}/transcript?revert=${version}`, transcript, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return res.data;
}
