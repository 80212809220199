import { ButtonWithIcons } from 'components/common';
import { useAuth } from 'context/AuthContext';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineLogin } from 'react-icons/ai';
import { Navigate, useNavigate } from 'react-router-dom';
import { ERoutes } from 'ts/enums';

function HomePage() {
  const navigate = useNavigate();
  const { isLogged } = useAuth();
  const { t } = useTranslation();

  if (isLogged) {
    return <Navigate to={ERoutes.MAIN} />;
  }

  return (
    <main className="d-flex align-items-center justify-content-center">
      <ButtonWithIcons
        icon={<AiOutlineLogin />}
        text={t('Shared.Actions.LogIn')}
        onClick={() => navigate(ERoutes.SIGN_IN)}
      />
     
    </main>
  );
}

export default HomePage;
