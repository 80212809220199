import ShareIcon from 'assets/icons/share-icon.svg';
import { PropsExport } from '.';
import { EExportName } from 'ts/enums';
import { useAppSelector } from 'redux/hooks';
import { useTranslation } from 'react-i18next';

export function ShareProjectExport({ exportOption, setExportOption }: PropsExport) {
  const userData = useAppSelector((state) => state.auth.currentUser.data);
  const currentProject = useAppSelector((state) => state.projects.currentProject.data);
  const isOwner = userData?.id === currentProject?.owner.id;
  const { t } = useTranslation();

  return (
    <div className="share-project-export export-item">
      <div
        className={`d-flex flex-column justify-content-center align-items-center ${
          isOwner ? 'cursor-pointer' : 'cursor-disable'
        } `}
        onClick={() => {
          if (isOwner)
            setExportOption((draft) => {
              draft.key = EExportName.SHARE_PROJECT;
              draft.data.subData = null;
              draft.data.option = {};
              draft.allowMultipleMedia = true;
            });
        }}
      >
        <div
          className={`icon ${
            exportOption.key === EExportName.SHARE_PROJECT ? 'opacity-100' : 'opacity-50'
          }`}
        >
          <img src={ShareIcon} alt="" />
        </div>
        <div
          className={`text mt-1 ${
            exportOption.key === EExportName.SHARE_PROJECT ? '--selected' : ''
          }`}
        >
          <span>{ t('Layouts.ExportModal.ShareProject.Title') }</span>
        </div>
      </div>
    </div>
  );
}
