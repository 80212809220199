import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import React, { ReactNode } from 'react';
import { EDelayTime } from 'ts/enums';

type Props = {
  icon: ReactNode;
  text?: string;
  className?: string;
  sizeIcon?: number;
  onClick: () => void;
  hiddenTablet?: boolean;
  hiddenLargeTablet?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  tooltipTile?: string;
  tooltipPlacement?: TooltipPlacement;
};

export function ButtonWithIcons({
  icon,
  text,
  className,
  onClick,
  hiddenTablet,
  hiddenLargeTablet,
  disabled = false,
  style,
  tooltipTile,
  tooltipPlacement
}: Props) {
  return (
    <Tooltip
      placement={tooltipPlacement ? tooltipPlacement : 'bottom'}
      trigger={'hover'}
      mouseEnterDelay={EDelayTime.FAST}
      title={tooltipTile}
      arrow={false}
      overlayClassName="custom-toolstip"
    >
      <button
        style={style}
        type="button"
        role="button"
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
        className={`${text ? 'd-flex align-items-center gap-1' : ''} ${
          className ? className : ''
        } ${disabled ? 'cursor-disabled' : ''}`}
      >
        <React.Fragment>{icon}</React.Fragment>
        {text && (
          <span
            className={`${
              hiddenLargeTablet ? 'd-none d-lg-block' : hiddenTablet ? 'd-none d-md-block' : ''
            } align-middle`}
            style={{
              userSelect: 'none'
            }}
          >
            {text}
          </span>
        )}
      </button>
    </Tooltip>
  );
}
