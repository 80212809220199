import React from 'react';
import { FaPenSquare } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { TTag } from 'ts/types';

export function TagItem({
  tag,
  idTagSelected,
  updateProjectTag,
  updateTag,
  deleteProjectTags
}: {
  tag: TTag;
  idTagSelected?: number | null;
  updateProjectTag: () => void;
  updateTag: () => void;
  deleteProjectTags: () => void;
}) {
  return (
    <div className="tag-item d-flex align-items-center mb-1">
      <div
        className="label p-1 cursor-pointer"
        style={{ backgroundColor: tag.color }}
        onClick={(e) => {
          updateProjectTag();
          e.stopPropagation();
        }}
      >
        {tag.name}
      </div>
      <button
        className="edit-button  ms-1"
        onClick={(e) => {
          e.stopPropagation();
          updateTag();
        }}
      >
        <FaPenSquare size={15} className="icon" />
      </button>
      <button
        className={`delete-button ms-1 ${
          idTagSelected && idTagSelected === tag.id ? 'visible' : 'invisible'
        }`}
        onClick={(e) => {
          e.stopPropagation();
          deleteProjectTags();
        }}
      >
        <IoClose size={15} className="icon" />
      </button>
    </div>
  );
}
