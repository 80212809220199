import { useAppSelector } from 'redux/hooks';
import { EmptyStateDictionary } from './EmptyStateDictionary';
import { DictionaryItem } from './DictionaryItem';

export function ListDictionary() {
  const currentDictionary = useAppSelector((state) => state.dictionary.currentDictionary);
  return (
    <div className="list-dictionary p-2">
      {currentDictionary.data && currentDictionary.data.length > 0 ? (
        currentDictionary.data.map((dictionary) => (
          <DictionaryItem dictionary={dictionary} key={dictionary.id} />
        ))
      ) : (
        <EmptyStateDictionary />
      )}
    </div>
  );
}
