import en from './en.json';
import vi from './vi.json';
import fr from './fr.json';
import es from './es.json';
import de from './de.json';

export default {
    en,
    fr,
    es,
    de,
    // Vietnamese language is only for test purpose, will be removed before releasing the app unless Shamir wants to keep it
    vi
}