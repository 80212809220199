import { DraftModel, DraftStyleMap, EditorBlock, EditorState } from 'draft-js';
import React, { useEffect, useRef, useState } from 'react';
import { TMedia, TSpeaker, TUser } from 'ts/types';
import { roundToTwo } from 'utils/common';
import { useEditorContext } from 'context/EditorContext';
import Annotation from './Annotation';
import Speaker from './Speaker';
import { FaPlusCircle } from 'react-icons/fa';
import { FaPencil } from 'react-icons/fa6';
import moment from 'moment';
import { ETypeNoti } from 'ts/enums';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { TBlockFilter } from '.';
import secondsToTimeFilter, { getMediaTimeCode } from 'utils/second-to-time';
import { setIsChangeTranscrip } from 'redux/transcription/transcription.slice';

function TranscriptRow(props: {
  block: DraftModel.ImmutableData.ContentBlock;
  contentState: DraftModel.ImmutableData.ContentState;
  selection: DraftModel.ImmutableData.SelectionState;
  customStyleMap?: DraftStyleMap | undefined;
  blockProps: {
    onJumpToBlock: (startTime: number) => void;
    updateAllMatchingAutoSpeaker: (speaker: TSpeaker | null) => void;
    updateAnnotation: (annotation: string) => void;
    onChangeInputAnnotation: (value: string) => void;
    addNewBlock: (blockKey?: string) => void;
    onUpdateBlockData: (data: any, newEditorState: EditorState) => EditorState;
    handleShowModalNoti: (type: ETypeNoti, title: string) => void;
    onChange: (editorState: EditorState) => void;
    editorState: EditorState;
    annotation: any;
    openAnnotation: boolean;
    blocksRelevantFilter: TBlockFilter[];
    isFirstBlock: boolean;
    isLastBlock: boolean;
    isSubtitled: boolean;
    currentMedia: TMedia;
    currentUser: TUser;
  };
}) {
  const { block, contentState, blockProps } = props;
  // const [isCurrentRowPlaying, setCurrentRowPlaying] = useState(false);
  const transcriptRowRef = useRef<HTMLDivElement>(null);
  // const { isAutoScroll, idBlockPlaying } = useAutoScroll();
  const { isNoneEdit, isLock, setIsReadonly } = useEditorContext();
  const dataAnnotation = blockProps.annotation;
  const isInvisibleBlock = !blockProps.blocksRelevantFilter.some(
    (blocksRelevantFilter) => blocksRelevantFilter.blockId === block.getKey()
  );
  const [isEditingTimestamp, setIsEitingTimestamp] = useState<boolean>(false);
  const [valueEditingTimestamp, setValueEditingTimestamp] = useState<string>('');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentMedia = useAppSelector((state) => state.media.currentMedia);
  const selectedSpeakerId = useAppSelector((state) => state.transcription.selectedSpeaker.id);

  // useEffect(() => {
  //   setCurrentRowPlaying(block.getKey() === idBlockPlaying);
  // }, [block, idBlockPlaying]);

  // useEffect(() => {
  //   if (idBlockPlaying) {
  //     setIdTranscriptRowPlaying(contentState.getBlockForKey(idBlockPlaying)?.getData()?.get('id'));
  //   }
  // }, [isCurrentRowPlaying, idBlockPlaying, contentState]);

  // useEffect(() => {
  //   if (isCurrentRowPlaying && transcriptRowRef.current && isAutoScroll) {
  //     transcriptRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //   }
  // }, [isCurrentRowPlaying, transcriptRowRef, isAutoScroll, idBlockPlaying]);

  function getStartTimeOfBlock(
    block: DraftModel.ImmutableData.ContentBlock,
    format?: boolean
  ): number {
    const { contentState } = props;
    let startEntityKey;
    let counterStartChar = 0;
    let startTime: number;
    do {
      startEntityKey = block.getEntityAt(counterStartChar++);
    } while (!startEntityKey && counterStartChar < block.getLength());

    if (startEntityKey) startTime = contentState.getEntity(startEntityKey).getData().startTime;
    else startTime = block.getData().get('startTime');
    // if (format) {
    //   return convertFormatTimeFromSecond(startTime);
    // }
    return startTime;
  }

  function getEndTimeOfBlock(
    block: DraftModel.ImmutableData.ContentBlock,
    format?: boolean
  ): number {
    const { contentState } = props;
    let endEntityKey;
    let counterEndChar = block.getLength();
    let endTime: number;

    do {
      endEntityKey = block.getEntityAt(counterEndChar--);
    } while (!endEntityKey && counterEndChar > 0);

    if (endEntityKey) endTime = contentState.getEntity(endEntityKey).getData().endTime;
    else endTime = block.getData().get('endTime');

    if (isNaN(endTime)) {
      let startEntityKey;
      let startTime: number;
      let counterStartChar = 0;
      do {
        startEntityKey = block.getEntityAt(counterStartChar++);
      } while (!startEntityKey && counterStartChar < block.getLength());

      if (startEntityKey) startTime = contentState.getEntity(startEntityKey).getData().startTime;
      else startTime = block.getData().get('startTime');

      endTime = startTime;
    }

    // if (format) {
    //   return convertFormatTimeFromSecond(endTime);
    // }
    return endTime;
  }

  function getFormattedTimestamp(time: number) {
    if (!currentMedia) return;
    const fps = currentMedia.fps;
    const startTimecodeOffset = getMediaTimeCode(currentMedia).time || '00:00:00';
    const frames = getMediaTimeCode(currentMedia).frames || 0;
    const millis = Number(frames) / fps;

    const [hours, minutes, seconds] = startTimecodeOffset.split(':').map(Number);

    const startTimeMilisecond = moment
      .duration({
        hours: hours,
        minutes: minutes,
        seconds: seconds
      })
      .asMilliseconds();

    let startTimecodeInSeconds = startTimeMilisecond / 1000 + millis;

    const filteredTime = secondsToTimeFilter();
    const timeCode = currentMedia.timeCode;
    const separator = timeCode && timeCode.includes(';') ? ';' : ':' || ':';
    let timeInSeconds = parseFloat(time?.toString() || '0');

    // ?: Should we need to find another solution?
    // temporarily fix wrong display which only happen when media fps is 23.976
    // multiply by magic number is 0.999
    if (fps === 23.976) {
      const magicNumber = 0.999;
      timeInSeconds = parseFloat(time?.toString() || '0') * magicNumber;
    }

    // console.log(filteredTime(timeInSeconds + startTimecodeInSeconds, Number(fps), separator));

    return filteredTime(timeInSeconds + startTimecodeInSeconds, Number(fps), separator);
  }

  const startTime = getStartTimeOfBlock(block, false);
  const endTime = getEndTimeOfBlock(block, false);
  const formattedTimestampStartTime = getFormattedTimestamp(startTime);
  const formattedTimestampEndTime = getFormattedTimestamp(endTime);

  const startTimeAndEndtimeStr = `${formattedTimestampStartTime} - ${formattedTimestampEndTime}`;

  useEffect(() => {
    setValueEditingTimestamp(startTimeAndEndtimeStr);
  }, [currentMedia, startTimeAndEndtimeStr]);

  function checkPermissonEdit() {
    if (
      isLock &&
      currentMedia &&
      blockProps.currentUser &&
      currentMedia.lockByUserId !== blockProps.currentUser.id &&
      !isNoneEdit
    ) {
      blockProps.handleShowModalNoti(
        ETypeNoti.WARNING,
        t('Pages.TranscriptDetail.Texts.FileLockedExplain')
      );
    }
  }

  function getSpeaker(): TSpeaker | undefined {
    if (block.getData().get('speaker')) {
      const speaker = JSON.parse(block.getData().get('speaker'));
      return speaker;
    }
    return undefined;
  }

  function handleJumpToBlock(block: DraftModel.ImmutableData.ContentBlock) {
    const startTime = getStartTimeOfBlock(block) || (0 as number);
    blockProps.onJumpToBlock(startTime);
  }

  function normalizeTimeNumber(timeStr: string) {
    if (!timeStr) return '00';

    const num = Number(timeStr);
    if (!num) return '00';
    return num < 10 ? '0' + num : num + '';
  }

  function normalizeTimestamp(startTimeAndEndtimeStr: string) {
    let [startTime, endTime] = startTimeAndEndtimeStr.split('-');
    let [shh, smm, sss, sff] = startTime.trim().split(':');
    let [ehh, emm, ess, eff] = endTime.trim().split(':');

    shh = normalizeTimeNumber(shh);
    smm = normalizeTimeNumber(smm);
    sss = normalizeTimeNumber(sss);
    sff = normalizeTimeNumber(sff);

    ehh = normalizeTimeNumber(ehh);
    emm = normalizeTimeNumber(emm);
    ess = normalizeTimeNumber(ess);
    eff = normalizeTimeNumber(eff);

    startTime = [shh, smm, sss, sff].join(':');
    endTime = [ehh, emm, ess, eff].join(':');

    return startTime + '-' + endTime;
  }

  function editTimestamp(startTimeAndEndtimeStr: string) {
    setIsEitingTimestamp(true);
    setValueEditingTimestamp(normalizeTimestamp(startTimeAndEndtimeStr));
    setIsReadonly(true);
  }

  function updateBlockData(data: any, editorState: EditorState) {
    return blockProps.onUpdateBlockData(data, editorState);
  }

  function updateEditorState(editorState: EditorState) {
    blockProps.onChange(editorState);
    dispatch(setIsChangeTranscrip({ isChange: true }));
  }

  function getStartEntityEndEntityOfBlock(block: DraftModel.ImmutableData.ContentBlock) {
    let startEntityKey;
    let endEntityKey;
    let counterStartChar = 0;
    let counterEndChar = block.getLength();

    do {
      startEntityKey = block.getEntityAt(counterStartChar++);
    } while (!startEntityKey && counterStartChar < block.getLength());

    do {
      endEntityKey = block.getEntityAt(counterEndChar--);
    } while (!endEntityKey && counterEndChar > 0);

    return {
      startEntityKey,
      endEntityKey
    };
  }

  function onChangeTimestamp(event: React.ChangeEvent<HTMLInputElement>) {
    const pattern =
      /^\d{0,2}\:\d{0,2}\:[0-5]?[0-9]?:[0-5]?[0-9]?\s*\-\s*\d{0,2}\:\d{0,2}\:[0-5]?[0-9]?:[0-5]?[0-9]?$/;
    const value = event.target.value;
    const isValid = pattern.test(value);

    if (!isValid) return;
    setValueEditingTimestamp(value);
  }

  function revertTimeStringToSecond(time: string) {
    const timeToHHMMSS = convertToHHSSMM(time);
    const timeMiliSecond = roundToTwo(moment.duration(timeToHHMMSS).asMilliseconds());

    if (!currentMedia) return timeMiliSecond;

    const fps = currentMedia.fps;
    const startTimecodeOffset = getMediaTimeCode(currentMedia).time || 0;
    const frames = getMediaTimeCode(currentMedia).frames || 0;
    const millis = Number(frames) / fps;

    const startTimeWithMoment = moment.duration(
      moment.duration(moment(startTimecodeOffset, 'HH:mm:ss').format('HH:mm:ss')).asMilliseconds()
    );

    const startTimeMilisecond = startTimeWithMoment.asMilliseconds() + millis * 1000;

    if (startTimeMilisecond != 0) {
      const originalTimeSecond = (timeMiliSecond - startTimeMilisecond) / 1000;
      return originalTimeSecond;
    }
    return timeMiliSecond / 1000;
  }

  const validateFrame = (timeStr: string) => {
    const [, , , ff] = timeStr.split(':');
    return +ff < +blockProps.currentMedia.fps;
  };

  const convertToHHSSMM = (timeStrHHSSMMFF: string) => {
    const [hh, mm, ss, ff] = timeStrHHSSMMFF.split(':');
    return [hh, mm, +ss + +ff / blockProps.currentMedia.fps].join(':');
  };

  function handleRetime(originalValue: string) {
    const blockKey = block.getKey();
    let isShortingTime = false;
    const verbose = false;
    const done = () => {
      setIsEitingTimestamp(!isEditingTimestamp);
      setValueEditingTimestamp(startTimeAndEndtimeStr);
      setIsReadonly(false);
    };

    const pattern = /^\d{1,2}\:\d{1,2}\:\d{1,2}:\d{1,2}\s*\-\s*\d{1,2}\:\d{1,2}\:\d{1,2}:\d{1,2}$/;
    const isValid = pattern.test(valueEditingTimestamp);
    if (!isValid) {
      done();
      return;
    }

    const mediaDuration = roundToTwo(blockProps.currentMedia.duration);

    let [newStartTime, newEndTime] = normalizeTimestamp(valueEditingTimestamp).split('-');
    let newStartTimeInSeconds: any = null;
    let newEndTimeInSeconds: any = null;

    if (!validateFrame(newStartTime) || !validateFrame(newEndTime)) {
      blockProps.handleShowModalNoti(ETypeNoti.ERROR, t('Shared.Errors.ErrorEditTimeFrame'));
      setValueEditingTimestamp(startTimeAndEndtimeStr);
      done();

      return;
    } else {
      // newStartTime = convertToHHSSMM(newStartTime);
      // newEndTime = convertToHHSSMM(newEndTime);
      // newStartTimeInSeconds = roundToTwo(moment.duration(newStartTime).asSeconds());
      // newEndTimeInSeconds = roundToTwo(moment.duration(newEndTime).asSeconds());
      newStartTimeInSeconds = revertTimeStringToSecond(newStartTime);
      newEndTimeInSeconds = revertTimeStringToSecond(newEndTime);
    }

    let [originalStartTime, originalEndtime] = normalizeTimestamp(originalValue).split('-');
    // originalStartTime = convertToHHSSMM(originalStartTime);
    // originalEndtime = convertToHHSSMM(originalEndtime);
    // let originalStartTimeInSeconds = roundToTwo(moment.duration(originalStartTime).asSeconds());
    // let originalEndtimeInSeconds = roundToTwo(moment.duration(originalEndtime).asSeconds());

    let originalStartTimeInSeconds = revertTimeStringToSecond(originalStartTime);
    let originalEndtimeInSeconds = revertTimeStringToSecond(originalEndtime);

    const prevBlock = contentState.getBlockBefore(blockKey);
    const nextBlock = contentState.getBlockAfter(blockKey);

    const prevBlockData = prevBlock
      ? {
          startTime: Number(getStartTimeOfBlock(prevBlock)),
          endTime: Number(getEndTimeOfBlock(prevBlock))
        }
      : { startTime: 0, endTime: 0 };
    prevBlockData.startTime = roundToTwo(prevBlockData.startTime);
    prevBlockData.endTime = roundToTwo(prevBlockData.endTime);

    const nextBlockData = nextBlock
      ? {
          startTime: Number(getStartTimeOfBlock(nextBlock)),
          endTime: Number(getEndTimeOfBlock(nextBlock))
        }
      : { startTime: mediaDuration, endTime: mediaDuration };
    nextBlockData.startTime = roundToTwo(nextBlockData.startTime);
    nextBlockData.endTime = roundToTwo(nextBlockData.endTime);

    const length = block.getLength();
    const entitiesOfBlock: any[] = [];
    for (let idx = 0; idx < length; idx++) {
      const entityKey = block.getEntityAt(idx);
      if (!entitiesOfBlock.includes(entityKey)) {
        entitiesOfBlock.push(entityKey);
      }
    }

    if (
      newStartTimeInSeconds === originalStartTimeInSeconds &&
      originalEndtimeInSeconds === newEndTimeInSeconds
    ) {
      done();
      return;
    }

    //---> IF got invalid new time THEN make it to closest valid time
    if (
      newStartTimeInSeconds < prevBlockData.endTime ||
      newStartTimeInSeconds > newEndTimeInSeconds
    ) {
      if (verbose) console.log('reassign invalid new newStartTimeInSeconds');
      newStartTimeInSeconds = prevBlockData.endTime;
    }

    if (
      newEndTimeInSeconds > nextBlockData.startTime ||
      newEndTimeInSeconds < newStartTimeInSeconds
    ) {
      if (verbose) console.log('reassign invalid new newEndTimeInSeconds');
      newEndTimeInSeconds = nextBlockData.startTime;
    }
    //<--- IF got invalid new time THEN make it to closest valid time

    if (
      newStartTimeInSeconds > originalStartTimeInSeconds &&
      newEndTimeInSeconds === originalEndtimeInSeconds
    ) {
      const diff = roundToTwo(newStartTimeInSeconds - originalStartTimeInSeconds);
      const newEndTimeInSecondsTemp = newEndTimeInSeconds + diff;
      if (verbose) console.log('reassign newEndTimeInSeconds IF having enough space', { diff });
      if (newEndTimeInSecondsTemp <= nextBlockData.startTime) {
        newEndTimeInSeconds = newEndTimeInSecondsTemp;
        if (verbose) console.log('REASSIGNED newEndTimeInSeconds');
      }
    }

    if (
      newEndTimeInSeconds < originalEndtimeInSeconds &&
      newStartTimeInSeconds === originalStartTimeInSeconds
    ) {
      if (verbose) console.log('reassign newStartTimeInSeconds IF having enough space');
      const diff = newEndTimeInSeconds - originalEndtimeInSeconds;
      const newStartTimeInSecondsTemp = newStartTimeInSeconds + diff;
      if (newStartTimeInSecondsTemp >= prevBlockData.endTime) {
        if (verbose) console.log('REASSIGNED newStartTimeInSeconds');
        newStartTimeInSeconds = newStartTimeInSecondsTemp;
      }
    }

    const beforeDiff = roundToTwo(originalEndtimeInSeconds - originalStartTimeInSeconds);
    const afterDiff = roundToTwo(newEndTimeInSeconds - newStartTimeInSeconds);
    const isShiftTime = beforeDiff === afterDiff;

    if (verbose) console.log({ beforeDiff, afterDiff, isShiftTime });

    if (
      (newStartTimeInSeconds > originalStartTimeInSeconds &&
        newStartTimeInSeconds > prevBlockData.endTime) ||
      (newEndTimeInSeconds < originalEndtimeInSeconds &&
        newEndTimeInSeconds < nextBlockData.startTime)
    ) {

      isShortingTime = true;
    }

    // CASE: startTime is increased and endTime is increased with the same number
    // AND startTime is decreased and endTime is decreased with the same number
    if (isShiftTime) {
      if (verbose) {
        console.log(
          'Shift Time',
          { newStartTimeInSeconds, originalStartTimeInSeconds, prevBlockData },
          { newEndTimeInSeconds, originalEndtimeInSeconds, nextBlockData }
        );
      }

      if (
        newStartTimeInSeconds < prevBlockData.endTime ||
        newEndTimeInSeconds > nextBlockData.startTime
      ) {
        // invalid time
        done();
        return;
      }
      
      const num = newStartTimeInSeconds - originalStartTimeInSeconds;

      let updatedEditorState = blockProps.editorState;
      const length = entitiesOfBlock.length;

      for (let idx = 0; idx < length; idx++) {
        let newContentState = null;
        let curEntityData = {};
        const curEntityKey = entitiesOfBlock[idx];
        const { startTime, endTime } = contentState.getEntity(curEntityKey).getData();
        curEntityData = { startTime: +startTime + num, endTime: +endTime + num };
        newContentState = contentState.mergeEntityData(curEntityKey, curEntityData);
        updatedEditorState = EditorState.push(
          updatedEditorState,
          newContentState,
          'change-block-data'
        );
      }

      updatedEditorState = updateBlockData(
        { startTime: newStartTimeInSeconds, endTime: newEndTimeInSeconds },
        updatedEditorState
      );

      updateEditorState(updatedEditorState);
      done();
      return;
    }

    // CASE: startTime is increased or endTime is decreased
    if (isShortingTime) {
      if (verbose) {
        console.log(
          'Shorting Time',
          { newStartTimeInSeconds, originalStartTimeInSeconds, prevBlockData },
          { newEndTimeInSeconds, originalEndtimeInSeconds, nextBlockData }
        );
      }

      const length = entitiesOfBlock.length;
      const diff = (newEndTimeInSeconds - newStartTimeInSeconds) / length;

      if (diff > 0) {
        let updatedEditorState = blockProps.editorState;
        let prevEntityDataAfterEdit: any;
        let prevEntityData: any;

        for (let idx = 0; idx < length; idx++) {
          let newContentState = null;
          let curEntityData = {};
          const curEntityKey = entitiesOfBlock[idx];
          let { startTime: curOriginalStartTime, endTime: curOriginalEndTime } = contentState
            .getEntity(curEntityKey)
            .getData();
          curOriginalStartTime = +curOriginalStartTime;
          curOriginalEndTime = +curOriginalEndTime;
          const isLastEntity = idx === length - 1;
          const isSpltedEntity =
            curOriginalStartTime === prevEntityData?.curOriginalStartTime ||
            (0 && curOriginalEndTime === prevEntityData?.curOriginalEndTime) ||
            0;

          if (idx === 0) {
            // the first entity
            curEntityData = {
              startTime: roundToTwo(newStartTimeInSeconds),
              endTime: roundToTwo(newStartTimeInSeconds + diff)
            };
            newContentState = contentState.mergeEntityData(curEntityKey, curEntityData);
          } else {
            if (!isSpltedEntity) {
              const originalBlankNum =
                (curOriginalStartTime - prevEntityData?.curOriginalEndTime || 0) * diff;
              const { endTime: endTimeAfterEdit } = prevEntityDataAfterEdit;
              const finalStartTime = endTimeAfterEdit + originalBlankNum;
              curEntityData = {
                startTime: roundToTwo(finalStartTime),
                endTime: isLastEntity
                  ? roundToTwo(newEndTimeInSeconds)
                  : roundToTwo(finalStartTime + diff)
              };
              newContentState = contentState.mergeEntityData(curEntityKey, curEntityData);

              if (verbose) {
                console.log({ originalBlankNum, finalStartTime, prevEntityDataAfterEdit });
              }
            } else {
              curEntityData = prevEntityDataAfterEdit;
              newContentState = contentState.mergeEntityData(curEntityKey, curEntityData);
            }
          }

          updatedEditorState = EditorState.push(
            updatedEditorState,
            newContentState,
            'change-block-data'
          );
          prevEntityDataAfterEdit = curEntityData;
          prevEntityData = { curOriginalStartTime, curOriginalEndTime };
        }

        updatedEditorState = updateBlockData(
          { startTime: newStartTimeInSeconds, endTime: newEndTimeInSeconds },
          updatedEditorState
        );

        updateEditorState(updatedEditorState);
        done();
        return;
      }

      done();
      return;
    }

    // CASE: startTime is decreased or endTime is increased
    if (verbose) {
      console.log(
        'startTime is decreased or endTime is increased',
        { originalStartTimeInSeconds, newStartTime, newStartTimeInSeconds, prevBlockData },
        { originalEndtimeInSeconds, newEndTime, newEndTimeInSeconds, nextBlockData }
      );
    }

    if (
      /*newStartTimeInSeconds > originalStartTimeInSeconds ||*/
      newStartTimeInSeconds < prevBlockData.endTime
    ) {
      // invalid time
      if (verbose) {
        console.log('invalid newStartTimeInSeconds');
      }
      done();
      return;
    }

    if (
      newEndTimeInSeconds < originalEndtimeInSeconds ||
      newEndTimeInSeconds > nextBlockData.startTime
    ) {
      // invalid time

      if (verbose) {
        console.log('invalid newEndTimeInSeconds');
      }
      done();
      return;
    }

    const { startEntityKey, endEntityKey } = getStartEntityEndEntityOfBlock(block);

    let newContentState = contentState.mergeEntityData(startEntityKey, {
      startTime: newStartTimeInSeconds
    });
    newContentState = contentState.mergeEntityData(endEntityKey, {
      endTime: newEndTimeInSeconds
    });
    let updatedEditorState = EditorState.push(
      blockProps.editorState,
      newContentState,
      'change-block-data'
    );

    updatedEditorState = updateBlockData(
      { startTime: newStartTimeInSeconds, endTime: newEndTimeInSeconds },
      updatedEditorState
    );

    updateEditorState(updatedEditorState);
    done();
  }

  const speaker = getSpeaker();
  const display = blockProps.blocksRelevantFilter.length && isInvisibleBlock ? 'none' : 'block';

  return (
    <div
      className={`ctn-transcript-row ${blockProps.isSubtitled ? 'mb-2' : 'mb-3'} ${
        selectedSpeakerId && speaker?.id == selectedSpeakerId ? 'transcript-hightlight' : ''
      } ${isNoneEdit ? '--disable' : ''}`}
      key={block.getKey()}
      id={block.getKey()}
      ref={transcriptRowRef}
      style={{
        display
      }}
    >
      {blockProps.isFirstBlock && blockProps.isSubtitled && !isNoneEdit && !isLock && (
        <div
          contentEditable={false}
          className={`simon-plus-bar ${(blockProps.isFirstBlock && 'first') || ''}`}
          style={{ display }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              blockProps.addNewBlock();
            }}
            className="simon-plus-icon"
            title="Add a new subtitle row here"
          >
            <FaPlusCircle />
          </div>
        </div>
      )}
      <div
        className="d-flex align-items-center justify-content-between top-transcript"
        contentEditable={false}
      >
        <div className="d-flex align-items-center">
          {!isEditingTimestamp && (
            <div>
              <span
                className="cursor-pointer transcript-time align-middle"
                onClick={(e) => {
                  e.stopPropagation();
                  handleJumpToBlock(block);
                }}
                aria-readonly={true}
              >
                {formattedTimestampStartTime} - {formattedTimestampEndTime}
              </span>
              {blockProps.isSubtitled && !isNoneEdit && !isLock && (
                <span
                  style={{ cursor: 'pointer', paddingLeft: 4 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    editTimestamp(valueEditingTimestamp);
                  }}
                >
                  <FaPencil className="fa-pencil" size={12} color="var( --si-dim-gray)" />
                </span>
              )}
            </div>
          )}
          {isEditingTimestamp && blockProps.isSubtitled && (
            <div
              className="transcription-time"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <input
                autoFocus
                style={{ width: 200 }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onFocus={(e) => {
                  e.stopPropagation();
                  setIsReadonly(true);
                }}
                onBlur={() => handleRetime(startTimeAndEndtimeStr)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleRetime(startTimeAndEndtimeStr);
                  }
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  onChangeTimestamp(e);
                }}
                value={valueEditingTimestamp}
              />
            </div>
          )}
          {!blockProps.isSubtitled && (
            <Speaker
              enable={!isLock || isNoneEdit}
              speaker={speaker}
              id={block.getKey()}
              updateAllMatchingAutoSpeaker={blockProps.updateAllMatchingAutoSpeaker}
              checkPermissionEdit={checkPermissonEdit}
            />
          )}
        </div>
        <Annotation
          dataAnnotation={dataAnnotation}
          updateAnnotation={(block, annotation) => {
            blockProps.updateAnnotation(annotation);
          }}
          autoOpen={blockProps.openAnnotation}
          block={block}
          checkPermissionEdit={checkPermissonEdit}
        />
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          checkPermissonEdit();
        }}
      >
        <EditorBlock {...props} />
      </div>
      {blockProps.isSubtitled && !isNoneEdit && !isLock && (
        <div
          contentEditable={false}
          className={`simon-plus-bar ${(blockProps.isFirstBlock && 'first') || ''}`}
          style={{ display }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              blockProps.addNewBlock(block.getKey());
            }}
            className="simon-plus-icon"
            title="Add a new subtitle row here"
          >
            <FaPlusCircle />
          </div>
        </div>
      )}
    </div>
  );
}

export default TranscriptRow;
