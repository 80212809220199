import React, { useEffect, useState } from 'react';
import Table, { ColumnsType } from 'antd/es/table';
import { EAccountSettingId, EPayType } from 'ts/enums';
import { BasicSection } from './BasicSection';
import { getTransactionHistory } from 'apis/userSetting.api';
import { convertDurationToString } from 'utils/time';
import { parseStringToArray } from 'utils/string';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface DataType {
  key: number;
  date: string;
  purchaseSummary: {
    name: string;
    payType: string | null;
    downloadLink: string | null;
    duration: number | null;
    downloadLinkExpired: boolean;
  };
  amount: { credit: number; payType: string | null; price: number; duration: number | null };

  card: { cardDigits: string | null; payType: string | null };
}

export function TransactionHistorySection() {
  const [transactionHistoryData, setTransactionHistoryData] = useState<DataType[]>([]);
  const { t } = useTranslation();

  const columns: ColumnsType<DataType> = [
    {
      title: t('Shared.Fields.Date'),
      dataIndex: 'date',
      key: 'date',
      render: (record) => {
        return (
          <div className="text-sm-nowrap" style={{ fontSize: 10 }}>
            {record}
          </div>
        );
      }
    },
    {
      title: t('Pages.AccountSetting.TransactionHistory.PurchaseSummary'),
      dataIndex: 'purchaseSummary',
      key: 'purchaseSummary',
      render: (value) => (
        <div>
          {value.payType === null &&
            parseStringToArray(value.name).map((name) => (
              <div key={name} className="name">
                {name}
              </div>
            ))}
          {value.payType === EPayType.DUB && (
            <div className="name">{`${
              value.downloadLink ? t('Shared.Texts.Dub') : t('Shared.Texts.Dubbing')
            }: ${value.name}`}</div>
          )}
          {value.downloadLink && (
            <>
              {value.payType === EPayType.BURN_IN && (
                <div>
                  {t('Pages.AccountSetting.TransactionHistory.UHasBurninSubtitles', {
                    name: value.name
                  })}
                </div>
              )}
              <a className="custom-a-tag" href={`${value.downloadLink}`}>
                {value.downloadLinkExpired
                  ? t('Shared.Texts.LinkExpired')
                  : t('Shared.Texts.DownloadHere')}{' '}
                <br />
              </a>
            </>
          )}
          {value.payType === EPayType.BURN_IN && !value.downloadLink && (
            <>
              <div>
                {t('Pages.AccountSetting.TransactionHistory.UIsBurninSubtitles', {
                  name: value.name
                })}
              </div>
            </>
          )}
          {value.duration && (
            <strong>{convertDurationToString(value.duration, 'compact', t)}</strong>
          )}
        </div>
      )
    },
    {
      title: t('Shared.Fields.AmountMoney'),
      dataIndex: 'amount',
      key: 'amount',
      render: (value) => (
        <div>
          {value.price > 0 ? (
            <div>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(value.price || 0)}
            </div>
          ) : value.credit ? (
            <div>
              {value.credit} {t('Shared.Fields.min', { count: value.credit })}
            </div>
          ) : (
            <div>0.00</div>
          )}
        </div>
      )
    },
    {
      title: t('Shared.Fields.Card'),
      dataIndex: 'card',
      key: 'card',
      render: (value) => (
        <div>
          {value.cardDigits ? (
            <div>*{value.cardDigits}</div>
          ) : value.payType &&
            value.payType !== EPayType.BURN_IN &&
            value.payType !== EPayType.DUB ? (
            <div>{value.payType}</div>
          ) : (
            <div>n/a</div>
          )}
        </div>
      )
    }
  ];

  // function formatDate(date: Date): string {
  //   const options: Intl.DateTimeFormatOptions = {
  //     month: '2-digit',
  //     day: '2-digit',
  //     year: '2-digit',
  //     hour: 'numeric',
  //     minute: 'numeric',
  //     hour12: true
  //   };

  //   const formattedDate: string = new Intl.DateTimeFormat('en-US', options).format(date);

  //   return formattedDate;
  // }

  useEffect(() => {
    (async () => {
      const data = await getTransactionHistory();
      const tempData: DataType[] = [];
      data &&
        data.length > 0 &&
        data.map((transaction, index) => {
          tempData.unshift({
            key: Number(transaction.creationDate) + index,
            date: moment(new Date(transaction.creationDate)).format('MM/DD/YYYY h:MM A'),
            purchaseSummary: {
              name: transaction.name,
              downloadLink: transaction.downloadLink,
              payType: transaction.payType,
              duration: transaction.duration,
              downloadLinkExpired: transaction.downloadLinkExpired
            },
            amount: {
              credit: transaction.credit,
              payType: transaction.payType,
              price: transaction.price,
              duration: transaction.duration
            },
            card: {
              cardDigits: transaction.cardDigits,
              payType: transaction.payType
            }
          });
        });
      setTransactionHistoryData(tempData);
    })();
  }, []);
  return (
    <BasicSection
      className="transaction-history-section mb-9"
      header={t('Pages.AccountSetting.TransactionHistory.SubTitle')}
      id={EAccountSettingId.TRANSACTION_HISTORY}
    >
      <Table
        columns={columns}
        dataSource={transactionHistoryData}
        pagination={false}
        rowKey={'key'}
        className="transaction-history-section-table"
        tableLayout="fixed"
      />
    </BasicSection>
  );
}
