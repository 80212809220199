import { useUpdateWidth } from 'hooks/useUpdateWidth';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ERoutes, EStatusProject } from 'ts/enums';
import { TMedia, TProject } from 'ts/types';
import { breakFileNameWithScreenSize } from 'utils/string';

export function MediaItem({
  index,
  media,
  projectStatus
}: {
  index: number;
  media: TMedia;
  projectStatus: string;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mediaId = searchParams.get('mediaId');
  const { width } = useUpdateWidth();

  function handleRedirectToDetail(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    if (projectStatus === EStatusProject.OPEN || projectStatus === EStatusProject.UPLOADING) {
      navigate(`${ERoutes.UPLOAD}/${media.projectId}`);
    } else {
      navigate(`${ERoutes.TRANSCRIPT_DETAIL}/${media.projectId}?mediaId=${media.id}`);
    }
  }
  return (
    <div
      className={`media-item cursor-pointer p-1 ${mediaId === media.id ? '--active' : ''}`}
      onClick={handleRedirectToDetail}
    >
      <span>{index + 1}. </span>
      <span>{breakFileNameWithScreenSize({ fileName: media.name, sizeScreen: width })}</span>
    </div>
  );
}
