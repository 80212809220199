import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type TokenState = {
  gDriveToken: {
    token: string | null;
    expired: number | undefined;
  };
};

const initialTokenState: TokenState = {
  gDriveToken: {
    token: null,
    expired: undefined
  },
};

const tokenSlice = createSlice({
  name: 'token',
  initialState: initialTokenState,
  reducers: {
    setGdriveToken: (state, { payload }: PayloadAction<{ gDriveToken: { token: string, expired: number } }>) => {
      state.gDriveToken = payload.gDriveToken;
    },
    removeGdriveToken: (state) => {
      state.gDriveToken = { token: null, expired: undefined }
    }
  },
});
export const { setGdriveToken, removeGdriveToken } = tokenSlice.actions;
export default tokenSlice.reducer;
