import AdvidMediaIcon from 'assets/icons/avid-media-logo.svg';
import { PropsExport } from '.';
import { EExportName, EExportSubData } from 'ts/enums';
import { useAppSelector } from 'redux/hooks';
import { useEffect } from 'react';

export function AvidMediaExport({ exportOption, setExportOption }: PropsExport) {
  const data = useAppSelector((state) => state.modal.lastModal?.data);
  if (!data) return <></>

  const subDataRender = [
    {
      key: EExportSubData.MARKERS_LOCATION_UTF8,
      title: 'Markers/ Locators (UTF-8)',
      option: {
        onlyBookmarks: false,
      },
      allowMultipleMedia: true
    },
    {
      key: EExportSubData.MARKERS_LOCATORS_EN,
      title: 'Markers/ Locators (Localised, en)',
      option: {
        onlyBookmarks: false,
        withoutNotesContent: false
      },
      allowMultipleMedia: true
    },
    {
      key: EExportSubData.SCRIPT_SYNC,
      title: 'ScriptSync',
      option: {
        withTimeCode: true,
      },
      allowMultipleMedia: true
    },
    {
      key: EExportSubData.SCRIPT_SYNC_NEW,
      title: 'ScriptSync (New)',
      option: {},
      allowMultipleMedia: true
    },
    {
      key: EExportSubData.SUB_CAP,
      title: 'SubCap',
      option: {},
      allowMultipleMedia: true
    },
    {
      key: EExportSubData.AAF,
      title: 'AAF',
      option: {},
      allowMultipleMedia: true
    }
  ];

  return (
    <div className="avid-media-export export-item">
      <div className='d-flex flex-column justify-content-center align-items-center cursor-pointer'
        onClick={() => {
          setExportOption(draft => {
            draft.key = EExportName.AVID_MEDIA;
            draft.data.subData = EExportSubData.MARKERS_LOCATION_UTF8;
            draft.data.option = subDataRender[0].option;
            draft.allowMultipleMedia = true;
          })
        }}>
        <div
          className={`icon ${exportOption.key === EExportName.AVID_MEDIA ? 'opacity-100' : 'opacity-50'
            }`}
        >
          <img src={AdvidMediaIcon} alt="" />

        </div>
        <div
          className={`text mt-1 ${exportOption.key === EExportName.AVID_MEDIA ? '--selected' : ''}`}
        >
          <span>Avid Media Composer</span>
        </div>
      </div>
      <div
        className={`text mt-1 ${exportOption.key === EExportName.AVID_MEDIA ? '--selected' : ''}`}
      >
        {exportOption.key === EExportName.AVID_MEDIA && (
          <div className="choose-option mt-2">
            {subDataRender.map((s) => (
              <label className="d-flex align-items-center mb-1 gap-1 cursor-pointer" key={s.key}>
                <input
                  type="radio"
                  name="select"
                  defaultChecked={s.key === EExportSubData.MARKERS_LOCATION_UTF8}
                  onClick={() => {
                    setExportOption(draft => {
                      draft.key = EExportName.AVID_MEDIA;
                      draft.data.subData = s.key;
                      draft.data.option = s.option;
                      draft.allowMultipleMedia = s.allowMultipleMedia;
                    })
                  }}
                />
                <span>{s.title}</span>
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
