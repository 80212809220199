import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/src/plugin/regions';
import { useVideoPlayerContext } from 'context/VideoPlayerContext';
import { useAppSelector } from 'redux/hooks';
import { TTranscript, TWord } from 'ts/types';
import { EStyle } from 'ts/enums';
import { Loader } from 'components/common/Loader';
import { formatTime } from 'utils/common';
import { useAutoScroll } from 'context/AutoScrollContext';
import { BlockDomItem } from 'redux/transcription/transcription.slice';

const Waveform = ({
  audio,
  duration,
  peaks,
  blockTranscription
}: {
  audio?: string;
  duration?: number;
  peaks?: string;
  blockTranscription: BlockDomItem[];
}) => {
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const { currentTime, seekTo, userDefinedTimeCode, timeDivided, setCurrentPage } =
    useVideoPlayerContext();
  const currentTranscription = useAppSelector((state) => state.transcription.currentTranscription);
  const [isLoading, setIsLoading] = useState(false);
  const remainingTime = duration ? duration - currentTime + userDefinedTimeCode : 0;

  const { setIdBlockPlaying, isAutoScroll } = useAutoScroll();

  useEffect(() => {
    setIsLoading(true);
    const waveSurfer = WaveSurfer.create({
      container: '#waveform',
      progressColor: '#7e56a2',
      waveColor: '#BAA6CB',
      height: 65,
      normalize: true,
      duration: duration || 0,
      responsive: true,
      hideScrollbar: true,
      plugins: [RegionsPlugin.create({})]
    });

    waveSurfer.setMute(true);

    if (!peaks) {
      // waveSurfer.load(audio);
      waveSurfer.drawBuffer();
      setIsLoading(false);
    } else if (peaks) {
      waveSurfer.backend.setPeaks(JSON.parse(peaks), duration ?? 0);
      waveSurfer.drawBuffer();
      waveSurfer.fireEvent('waveform-ready');
      setIsLoading(false);
    }

    waveSurfer.drawer.on('click', (e: any, progress: number) => {
      const time = progress * (duration || 0);

      setTimeout(() => {
        seekTo && seekTo(time);
        const blockKey: BlockDomItem | null = findNearestBlockItem(time);
        blockKey && setIdBlockPlaying(blockKey.id);
      }, 0);
    });

    waveSurfer.on('ready', () => {
      waveSurferRef.current = waveSurfer;
      setIsLoading(false);
    });

    if (peaks) {
      waveSurferRef.current = waveSurfer;
    }

    return () => {
      waveSurfer.destroy();
    };
  }, [audio, duration, userDefinedTimeCode, blockTranscription]);

  function findNearestBlockItem(time: number) {
    const findItemIdx = blockTranscription.findIndex(
      (block) => block.start >= time && time <= block.end
    );
    if (findItemIdx === -1) return null;
    return blockTranscription[findItemIdx - 1];
  }

  useEffect(() => {
    if (waveSurferRef.current && !isLoading) {
      waveSurferRef.current.regions.clear();
      filterTranscriptRowBookmarked({ transcriptions: currentTranscription.data || [] }).forEach(
        (words) => {
          words.forEach((word) => {
            if (word) {
              waveSurferRef.current?.regions?.add({
                start: +word.startTime,
                end: +word.endTime,
                resize: false,
                drag: false,
                color: 'rgba(254, 232, 214, 0.45)'
              });
            }
          });
        }
      );
    }
  }, [waveSurferRef.current, currentTranscription.data, isLoading]);

  useEffect(() => {
    if (currentTime && waveSurferRef.current) {
      const time = currentTime;
      waveSurferRef.current.setCurrentTime(time);
      const blockKey: BlockDomItem | null = findNearestBlockItem(time);
      blockKey && setIdBlockPlaying(blockKey.id);
    }
  }, [currentTime, waveSurferRef.current, userDefinedTimeCode]);

  useEffect(() => {
    if (!isAutoScroll) return;
    let foundIndex = -1;
    for (let i = 0; i < timeDivided.length; i++) {
      const interval = timeDivided[i];
      if (currentTime >= interval.startTime && currentTime <= interval.endTime) {
        foundIndex = i;
        break;
      }
    }

    if (foundIndex !== -1) {
      setCurrentPage(foundIndex + 1);
    }
  }, [currentTime, isAutoScroll]);

  function filterTranscriptRowBookmarked({ transcriptions }: { transcriptions: TTranscript[] }) {
    const transcriptBookmarked = transcriptions
      .filter((data) => data.bookmark)
      .map((data) => {
        const parseWordJson = JSON.parse(data.wordsJson) as Array<TWord>;
        const findWordsBookmarked = parseWordJson.filter((word) =>
          word.style?.includes(EStyle.HIGHLIGHT)
        );
        return findWordsBookmarked;
      });

    return transcriptBookmarked;
  }

  return (
    <div className="d-none d-sm-block waveform">
      <div className="position-relative">
        {!isLoading && (
          <div
            className="position-absolute start-0 bottom-0 ms-1"
            style={{
              fontSize: 12,
              color: 'var(--si-primary)',
              zIndex: 100,
              background: '#fff',
              pointerEvents: 'none'
            }}
          >
            {formatTime(currentTime + userDefinedTimeCode)}
          </div>
        )}
        {!isLoading && (
          <div
            className="position-absolute end-0 bottom-0 me-1"
            style={{
              fontSize: 12,
              color: 'var(--si-primary)',
              zIndex: 100,
              background: '#fff',
              pointerEvents: 'none'
            }}
          >
            {remainingTime > 0 ? formatTime(remainingTime) : formatTime(0 + userDefinedTimeCode)}
          </div>
        )}
        {isLoading && (
          <div
            className="position-absolute"
            style={{ top: '50%', left: '50%', zIndex: 100, transform: 'translate(-50%, -50%)' }}
          >
            <Loader />
          </div>
        )}

        <div
          id="waveform"
          onMouseMove={(e) => {
            const { clientX, clientY } = e;

            const waveform = document.getElementById('waveform');
            if (!waveform || isLoading) return;
            const _duration = duration || 0;
            const rect = waveform.getBoundingClientRect();
            const width = rect.width || 0;
            const offsetX = clientX - rect.left;
            const currentTime = (offsetX / width) * _duration;

            const tooltip = document.getElementById('tooltip-waveform');
            if (tooltip) {
              tooltip.style.display = 'block';
              tooltip.style.left = `${clientX + 15}px`;
              tooltip.style.top = `${clientY}px`;
              tooltip.innerText = `${formatTime(currentTime + userDefinedTimeCode)}`;
            }
          }}
          onMouseLeave={() => {
            const tooltip = document.getElementById('tooltip-waveform');
            if (tooltip) {
              tooltip.style.display = 'none';
            }
          }}
        />
      </div>
      <div
        id="tooltip-waveform"
        style={{
          display: 'none',
          position: 'absolute',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          padding: 5,
          borderRadius: 5,
          zIndex: 101,
          fontSize: 10
        }}
      />
    </div>
  );
};

export default Waveform;
