import { BsFillPlayFill, BsPauseFill } from 'react-icons/bs';
import { AiOutlineFullscreenExit, AiOutlineFullscreen } from 'react-icons/ai';
import { Scrubber } from 'react-scrubber';
import 'react-scrubber/lib/scrubber.css';
import SpeakerControl from './SpeakerControl';
import React from 'react';
import { useVideoPlayerContext } from 'context/VideoPlayerContext';
import { formatTime } from 'utils/common';

type Props = {
  className?: string;
};

export default function CustomControlPlayer({ className = '' }: Props) {
  const {
    currentTime,
    isPlaying,
    setIsPlaying,
    seekTo,
    markList,
    handleFullScreen,
    isFullscreen,
    videoDuration,
    userDefinedTimeCode
  } = useVideoPlayerContext();
  // const [lastPlaying, setLastPlaying] = useState<boolean>(false);
  const remainingTime = videoDuration - currentTime + userDefinedTimeCode;

  return (
    <>
      <div
        className={`ctn-custom-control-player ms-auto me-auto d-flex align-items-center control-video position-absolute ${className} rounded-bottom`}
      >
        <div
          className="play-icon ms-2 d-flex align-items-center cursor-pointer"
          onClick={() => {
            setIsPlaying((prev) => !prev);
          }}
        >
          {isPlaying ? <BsPauseFill size={20} /> : <BsFillPlayFill size={20} />}
        </div>
        <div className="current-time ms-1 d-flex justify-content-start">
          {formatTime(currentTime + userDefinedTimeCode)}
        </div>
        <div className="scrub-bar">
          <Scrubber
            min={0 + userDefinedTimeCode}
            max={videoDuration + userDefinedTimeCode}
            className="cursor-pointer"
            value={currentTime + userDefinedTimeCode}
            onScrubChange={(value: number) => {
              seekTo && seekTo(value - userDefinedTimeCode);
            }}
            onScrubEnd={(value: number) => {
              seekTo && seekTo(value - userDefinedTimeCode);
            }}
            markers={markList}
            tooltip={{
              enabledOnHover: true,
              formatString: (value: number) => formatTime(value),
              className: 'control-tooltip-class'
            }}
          />
        </div>
        <div className="left-time ms-1 d-flex justify-content-start">
          {remainingTime > 0 ? formatTime(remainingTime) : formatTime(0)}
        </div>
        <div className="volumn-control ms-1 cursor-pointer">
          <SpeakerControl />
        </div>
        <div
          className="icon-full-screen ms-2 me-2 d-flex align-items-center cursor-pointer"
          onClick={() => {
            handleFullScreen();
          }}
        >
          {!isFullscreen ? (
            <AiOutlineFullscreen size={20} />
          ) : (
            <AiOutlineFullscreenExit size={20} />
          )}
        </div>
      </div>
    </>
  );
}
