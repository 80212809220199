import React from 'react';
import { createInlineStyleButton } from '@draft-js-plugins/buttons';
import { EStyle } from 'ts/enums';

const styleDefault = createInlineStyleButton({
  style: EStyle.LOWERCASE,
  children: (
    <svg height="22" width="22" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <text
        fontWeight="bold"
        textAnchor="start"
        fontFamily="Helvetica, Arial, sans-serif"
        fontSize="11"
        y="16"
        x="0"
        strokeWidth="0"
        fill="#000"
      >
        abc
      </text>
    </svg>
  )
});

export default function (active: boolean) {
  // if(active) return styleActive
  return styleDefault;
}
