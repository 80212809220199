import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from 'components/common/Loader';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

function SignUpPage() {
  const { loginWithRedirect } = useAuth0();
  const [params] = useSearchParams();
  useEffect(() => {
    if (params.get('inviteCode')) {
      localStorage.setItem('inviteCode', params.get('inviteCode') || "");
    }
    loginWithRedirect();
  }, [])
  return (
    <>
      <Loader />
    </>
  );
}

export default SignUpPage;
