import { Form, Input, Switch } from 'antd';
import {
  deleteBlackWhiteList,
  editBlackWhiteList,
  editConfig,
  getBlackWhiteList,
  getConfig
} from 'apis/admin/blackWhiteList.api';
import { useLoaderContext } from 'context/LoaderContext';
import { usePageLoading } from 'context/PageLoadingContext';
import React, { useEffect, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from 'ts/enums';
import { TBlackWhiteList, TConfig } from 'ts/types';

function BlackWhiteListPage() {
  const { openMessage, destroyMessage } = usePageLoading();
  const [blackWhiteState, setBlackWhiteState] = useState<TBlackWhiteList>();
  const [isBlackListALl, setIsBlackListAll] = useState<boolean>(false);
  const [isWhiteListALl, setIsWhiteListAll] = useState<boolean>(false);
  const [formData] = Form.useForm();
  const [numberUser, setNumberUser] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [config, setConfig] = useState<TConfig>();
  const [loadingBlackList, setLoadingBlackList] = useState<boolean>(false);
  const [loadingWhiteList, setLoadingWhiteList] = useState<boolean>(false);
  const navigate = useNavigate();
  const { loader } = useLoaderContext();

  useEffect(() => {
    destroyMessage();
    loader.start();
    openMessage('loading', 'Get data....');
    setLoadingBlackList(true);
    setLoadingWhiteList(true);
    (async () => {
      const dataBlackWhiteList = await getBlackWhiteList();
      const dataConfig = await getConfig();
      if (dataBlackWhiteList) {
        setBlackWhiteState(dataBlackWhiteList[0]);
      }
      if (dataConfig) {
        formData.setFieldValue('userSameIp', dataConfig.ipNumOfUsers);
        setNumberUser(dataConfig.ipNumOfUsers);
        formData.setFieldValue('hours', dataConfig.ipHours);
        setHours(dataConfig.ipHours);
        setConfig(dataConfig);
      }
      setLoadingBlackList(false);
      setLoadingWhiteList(false);
      destroyMessage();
      loader.complete();
    })();
  }, []);

  useEffect(() => {
    if (blackWhiteState) {
      setIsBlackListAll(blackWhiteState.blackListAll);
      setIsWhiteListAll(blackWhiteState.whiteListAll);
    }
  }, [blackWhiteState]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function handleAddNewBlackWhiteList(e: any) {
    const blackListIds = e.blackIds;
    const whiteListIds = e.whiteIds;
    const blackListIps = e.blackIps;
    loader.start();
    if ((blackListIds || whiteListIds || blackListIps) && blackWhiteState) {
      const res = await editBlackWhiteList({
        id: blackWhiteState.id,
        blackListIds,
        whiteListIds,
        blackListIps
      });
      if (res) {
        setBlackWhiteState(res);
        formData.resetFields();
      }
      loader.complete();
    }
  }

  async function handleDeleteBlackList(e: string) {
    const blackListIds = e;
    if (blackListIds && blackWhiteState) {
      loader.start();
      const res = await deleteBlackWhiteList({ id: blackWhiteState.id, blackListIds });
      if (res) {
        setBlackWhiteState(res);
      }
      loader.complete();
    }
  }

  async function handleDeleteWhiteList(e: string) {
    const whiteListIds = e;
    if (whiteListIds && blackWhiteState) {
      loader.start();
      const res = await deleteBlackWhiteList({ id: blackWhiteState.id, whiteListIds });
      if (res) {
        setBlackWhiteState(res);
      }
      loader.complete();
    }
  }

  async function handleDeleteIpList(e: string) {
    const blackListIps = e;
    if (blackListIps && blackWhiteState) {
      loader.start();
      const res = await deleteBlackWhiteList({ id: blackWhiteState.id, blackListIps });
      if (res) {
        setBlackWhiteState(res);
      }
      loader.complete();
    }
  }

  function onCancel() {
    if (config) {
      formData.setFieldValue('userSameIp', config.ipNumOfUsers);
      setNumberUser(config.ipNumOfUsers);
      formData.setFieldValue('hours', config.ipHours);
      setHours(config.ipHours);
    }
  }

  async function handleEditConfig() {
    if (config) {
      const newConfig = { ...config, ipHours: hours, ipNumOfUsers: numberUser };

      if (newConfig) {
        loader.start();
        const res = await editConfig({ config: newConfig });
        if (res) {
          setConfig(res);
        }
        loader.complete();
      }
    }
  }

  return (
    <div className="black-white-list-page">
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome
            size={30}
            color="var(--si-primary)"
            onClick={() => navigate(ERoutes.LASTEST_TRANSACTION)}
            className="cursor-pointer"
          />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            Black/Whitelist
          </span>
        </div>
      </div>
      <div className="container py-2">
        <Form form={formData} onFinish={handleAddNewBlackWhiteList} layout="vertical">
          <section className="section-black-white-list p-2 mb-4 rounded black-list">
            <label className="mb-2 title">Blacklist</label>
            <div className="row">
              <Form.Item
                className="col-12 col-md-5 align-self-end"
                name={'blackIds'}
                label="ADD NEW"
              >
                <Input className="w-100" height={34} disabled={isBlackListALl} />
              </Form.Item>
              <Form.Item className="col-12 col-md-5" name={'submit'} label={<div />}>
                <button
                  className="submit-button"
                  style={{ minWidth: 90, height: 34 }}
                  onClick={() => formData.submit()}
                >
                  Add
                </button>
              </Form.Item>
            </div>
            <div className="list px-3 py-2">
              <div className="row">
                {blackWhiteState?.blackListIds.map((item) => (
                  <div key={item} className="col-12 col-md-4 p-1">
                    <span>
                      <RiDeleteBin6Line
                        className="cursor-pointer delete-icon"
                        size={16}
                        onClick={() => {
                          handleDeleteBlackList(item);
                        }}
                      />{' '}
                      {item}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <Switch
                style={{
                  backgroundColor: isBlackListALl ? 'var(--si-primary)' : 'var(--si-chinese-silver)'
                }}
                loading={loadingBlackList}
                checked={isBlackListALl}
                className="me-1"
                onClick={() => {
                  setLoadingBlackList(true);
                  if (blackWhiteState) {
                    editBlackWhiteList({
                      id: blackWhiteState.id,
                      blackListAll: blackWhiteState.blackListAll ? 0 : 1
                    }).then(() => {
                      setIsBlackListAll(!isBlackListALl);
                      setLoadingBlackList(false);
                    });
                  }
                }}
              />
              <span className="fs-5 fw-bold">Blacklist ALL except those on whitelist</span>
            </div>
          </section>
          <section className="section-black-white-list p-2 mb-4 rounded white-list">
            <label className="mb-2 title">Whitelist</label>
            <div className="row">
              <Form.Item
                className="col-12 col-md-5 align-self-end"
                name={'whiteIds'}
                label="ADD NEW"
              >
                <Input className="w-100" height={34} disabled={isWhiteListALl} />
              </Form.Item>
              <Form.Item className="col-12 col-md-5" name={'submit'} label={<div />}>
                <button
                  className="submit-button"
                  style={{ minWidth: 90, height: 34 }}
                  onClick={() => formData.submit()}
                >
                  Add
                </button>
              </Form.Item>
            </div>
            <div className="list px-3 py-2">
              <div className="row">
                {blackWhiteState?.whiteListIds.map((item) => (
                  <div key={item} className="col-12 col-md-4 p-1">
                    <span>
                      <RiDeleteBin6Line
                        className="cursor-pointer delete-icon"
                        size={16}
                        onClick={() => {
                          handleDeleteWhiteList(item);
                        }}
                      />{' '}
                      {item}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <Switch
                style={{
                  backgroundColor: isWhiteListALl ? 'var(--si-primary)' : 'var(--si-chinese-silver)'
                }}
                checked={isWhiteListALl}
                className="me-1"
                loading={loadingWhiteList}
                onClick={() => {
                  if (blackWhiteState) {
                    setLoadingWhiteList(true);
                    editBlackWhiteList({
                      id: blackWhiteState.id,
                      whiteListAll: blackWhiteState.whiteListAll ? 0 : 1
                    }).then(() => {
                      setIsWhiteListAll(!isWhiteListALl);
                      setLoadingWhiteList(false);
                    });
                  }
                }}
              />
              <span className="fs-5 fw-bold">Whitelist ALL except those on blacklist </span>
            </div>
          </section>
          <section className="section-black-white-list p-2 mb-4 rounded black-list-ip">
            <label className="mb-2 title">Blacklisted IP</label>
            <div className="row">
              <Form.Item
                className="col-12 col-md-5"
                name={'userSameIp'}
                label="NUMBER OF USERS OF SAME IP"
              >
                <Input
                  className="w-100"
                  height={34}
                  type="number"
                  onChange={(e) => {
                    formData.setFieldValue('userSameIp', e.target.value);
                    setNumberUser(parseInt(e.target.value));
                  }}
                />
              </Form.Item>
              <Form.Item className="col-12 col-md-5" name={'hours'} label="HOURS">
                <Input
                  className="w-100"
                  height={34}
                  type="number"
                  onChange={(e) => {
                    formData.setFieldValue('hours', e.target.value);
                    setHours(parseInt(e.target.value));
                  }}
                />
              </Form.Item>
            </div>

            <div className="row">
              <span className="fs-3">
                If {numberUser >= 0 ? numberUser : ''} number of users of same IP sign up for an
                account within {hours >= 0 ? hours : ''} hours, then new users from that IP get 0
                credits.
              </span>
            </div>

            {(config?.ipHours !== hours || config.ipNumOfUsers !== numberUser) && (
              <div className="d-flex alig-items-center gap-2 mt-1">
                <button className="btn btn-success col-1" onClick={handleEditConfig}>
                  Save
                </button>
                <button className="btn btn-danger col-1" onClick={onCancel}>
                  Cancel
                </button>
              </div>
            )}

            <div className="row mt-3">
              <Form.Item
                className="col-12 col-md-5 align-self-end"
                name={'blackIps'}
                label="ADD NEW"
              >
                <Input className="w-100" height={34} />
              </Form.Item>
              <Form.Item className="col-12 col-md-5" name={'submit'} label={<div />}>
                <button
                  className="submit-button"
                  style={{ minWidth: 90, height: 34 }}
                  onClick={() => formData.submit()}
                >
                  Add
                </button>
              </Form.Item>
            </div>
            <div className="list px-3 py-2">
              <div className="row">
                {blackWhiteState?.blackListIps.map((item) => (
                  <div key={item} className="col-12 col-md-4 p-1">
                    <span>
                      <RiDeleteBin6Line
                        className="cursor-pointer delete-icon"
                        size={16}
                        onClick={() => {
                          handleDeleteIpList(item);
                        }}
                      />{' '}
                      {item}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </Form>
      </div>
    </div>
  );
}

export default BlackWhiteListPage;
