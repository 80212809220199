import { Select } from 'antd';
import { checkServiceSupport, updateSpeechService } from 'apis/admin/languages';
import { ModalBase } from 'components/common';
import { Loader } from 'components/common/Loader';
import { useLoaderContext } from 'context/LoaderContext';
import { usePageLoading } from 'context/PageLoadingContext';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaDownload } from 'react-icons/fa6';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getListLanguagesAdminAction } from 'redux/language/language.action';
import { popModal } from 'redux/modal/modal.slice';
import { EModals } from 'ts/enums';
import { TSpeechApi } from 'ts/types';
import { TRequestUpdateService } from 'ts/types/TRequest';
import { SpeechApiIdToCode } from 'utils/string';

const SpeechApiModal = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.modal.lastModal?.data);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingChangeService, setLoadingChangeService] = useState<boolean>(false);
  const [listSpeechApi, setSpeechApi] = useState<TSpeechApi[]>([]);
  const [requestUpdateService, setUpdateRequestUpdateService] = useState<TRequestUpdateService>();
  const { openMessage, destroyMessage } = usePageLoading();
  const { t } = useTranslation();
  const { loader } = useLoaderContext();

  const getServiceSupport = async () => {
    if (!data) return;
    setLoading(true);
    loader.start();
    const res = await checkServiceSupport({ langCode: data.code });
    setSpeechApi(res);
    setLoading(false);
    loader.complete();
  };

  const changeServiceLanguage = async () => {
    setLoadingChangeService(true);
    if (!data) {
      setLoadingChangeService(false);
      return;
    }
    if (requestUpdateService) {
      try {
        loader.start();
        openMessage('loading', t('Shared.Texts.ChangingW3Dot'));
        await updateSpeechService(requestUpdateService);
        destroyMessage();
        openMessage('success', t('Shared.Texts.Changed'));
        dispatch(popModal());
        dispatch(getListLanguagesAdminAction());
        loader.complete();
      } catch (error) {
        dispatch(popModal());
        destroyMessage();
        openMessage('error', t('Shared.Errors.SomethingWentWrong'));
      }
    }
    setLoadingChangeService(false);
  };

  useEffect(() => {
    getServiceSupport();
    if (data) {
      setUpdateRequestUpdateService({
        regionId: data.regionId,
        newBackupSpeechServiceCode: SpeechApiIdToCode(data.backupSpeechApi),
        newSpeechServiceCode: SpeechApiIdToCode(data.defaultSpeechApi),
        langCode: data.code
      });
    }
  }, [data]);

  if (!data) return null;

  return (
    <ModalBase
      modalName={EModals.SPEECH_API_MODAL}
      onCloseModal={() => {
        dispatch(popModal());
      }}
    >
      <div>
        <h3 className="text-center">{t('Pages.Admin.Languages.SpeechApiModal.Title')}</h3>
        <div className="p-3">
          {loading ? (
            <Loader />
          ) : (
            <div>
              <div className="row">
                <div className="col-6">
                  <div className="title mb-1">{t('Shared.Fields.PrimarySpeechEngine')}</div>
                  <div>
                    <Select
                      value={requestUpdateService?.newSpeechServiceCode}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      className="select-speech"
                      style={{ width: '100%' }}
                      suffixIcon={<HiOutlineChevronDown size={14} />}
                      placeholder={t('Shared.Fields.PrimarySpeechEngine')}
                      onChange={(e) => {
                        if (requestUpdateService) {
                          setUpdateRequestUpdateService({
                            ...requestUpdateService,
                            newSpeechServiceCode: e
                          });
                        }
                      }}
                      options={[
                        {
                          options: listSpeechApi.map((item) => ({
                            key: item.id,
                            label: item.speechService,
                            value: item.speechService
                          }))
                        }
                      ]}
                    />
                    {/* <select
                      name="primarySpeech"
                      className="select-speech w-100 px-2 py-1"
                      onChange={(e) => {
                        if (requestUpdateService) {
                          setUpdateRequestUpdateService({
                            ...requestUpdateService,
                            newSpeechServiceCode: e.target.value
                          });
                        }
                      }}
                    >
                      {listSpeechApi.map((item) => {
                        return (
                          <option
                            value={item.speechService}
                            key={item.id}
                            selected={
                              item.speechService === SpeechApiIdToCode(data.defaultSpeechApi)
                            }
                          >
                            {item.speechService}
                          </option>
                        );
                      })}
                    </select> */}
                  </div>
                </div>
                <div className="col-6">
                  <div className="title mb-1">{t('Shared.Fields.BackupSpeechEngine')}</div>
                  <div>
                    <Select
                      value={requestUpdateService?.newBackupSpeechServiceCode}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      className="select-speech"
                      style={{ width: '100%' }}
                      suffixIcon={<HiOutlineChevronDown size={14} />}
                      placeholder={t('Shared.Fields.BackupSpeechEngine')}
                      onChange={(e) => {
                        if (requestUpdateService) {
                          setUpdateRequestUpdateService({
                            ...requestUpdateService,
                            newBackupSpeechServiceCode: e === 'null' ? null : e
                          });
                        }
                      }}
                      options={[
                        {
                          options: listSpeechApi.map((item) => ({
                            key: item.id,
                            label: item.speechService,
                            value: item.speechService
                          }))
                        }
                      ]}
                    />
                    {/* <select
                      name="backupSpeech"
                      className="select-speech w-100 px-2 py-1"
                      onChange={(e) => {
                        if (requestUpdateService) {
                          setUpdateRequestUpdateService({
                            ...requestUpdateService,
                            newBackupSpeechServiceCode:
                              e.target.value === 'null' ? null : e.target.value
                          });
                        }
                      }}
                    >
                      <option value={'null'} selected={data.backupSpeechApi === null}>
                        -
                      </option>
                      {listSpeechApi.map((item) => {
                        return (
                          <option
                            value={item.speechService}
                            key={item.id}
                            selected={
                              item.speechService === SpeechApiIdToCode(data.backupSpeechApi)
                            }
                          >
                            {item.speechService}
                          </option>
                        );
                      })}
                    </select> */}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end pt-2">
                <button
                  className="submit-button px-2 py-1"
                  style={{ borderRadius: 98 }}
                  onClick={changeServiceLanguage}
                  disabled={loadingChangeService}
                >
                  {loadingChangeService ? (
                    <Loader />
                  ) : (
                    <>
                      <FaDownload size={24} />
                      <span className="ms-1">{t('Shared.Actions.Save')}</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalBase>
  );
};

export default SpeechApiModal;
