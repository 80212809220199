import { TErrorResponse, TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from './axios-client';
import { TProject, TShareEmailInput, TShareMember, TShareProject } from 'ts/types';
import { InternalAxiosRequestConfig } from 'axios';

export async function getListSharedMember(projectId: string): Promise<TResponse<TShareMember[]>> {
  const res = await AxiosCustom.get(`/shared-projects/${projectId}/members`);
  return res.data;
}

export async function shareProjectWithEmail({
  id,
  right,
  email
}: TShareEmailInput): Promise<TResponse<InternalAxiosRequestConfig<string> | TErrorResponse>> {
  try {
    const res = await AxiosCustom.post(`/shared-projects`, { id, invite: { right, email } });
    return res.config;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function shareProject({
  id,
  linkRight,
  publicDescription,
  publishAction,
  shareMember,
  teamRight
}: TShareProject): Promise<TResponse<InternalAxiosRequestConfig<string> | TErrorResponse>> {
  try {
    const res = await AxiosCustom.post(`/shared-projects`, {
      id,
      linkRight,
      publicDescription,
      publishAction,
      shareMember,
      teamRight
    });
    return res.config;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function updateProjectPublishDesc(project: TProject, desc: string) {
  const res = await AxiosCustom.put(`/projects/${project.id}`, {
    ...project,
    publicDescription: desc
  });
  return res.data;
}

export async function checkEditProject({ projectId }: { projectId: string }) {
  const res = await AxiosCustom.get(`/shared-projects/${projectId}/isReadOnly`);
  return res.data;
}
