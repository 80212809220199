export const GOOGLE_VOICES = {
  'af-ZA': 'Afrikaans (South Africa)',
  'ar-XA': 'Arabic',
  'bn-IN': 'Bengali (India)',
  'bg-BG': 'Bulgarian (Bulgaria)',
  'ca-ES': 'Catalan (Spain)',
  'yue-HK': 'Chinese (Hong Kong)',
  'cs-CZ': 'Czech (Czech Republic)',
  'da-DK': 'Danish (Denmark)',
  'nl-BE': 'Dutch (Belgium)',
  'nl-NL': 'Dutch (Netherlands)',
  'en-AU': 'English (Australia)',
  'en-IN': 'English (India)',
  'en-GB': 'English (UK)',
  'en-US': 'English (US)',
  'fil-PH': 'Filipino (Philippines)',
  'fi-FI': 'Finnish (Finland)',
  'fr-CA': 'French (Canada)',
  'fr-FR': 'French (France)',
  'de-DE': 'German (Germany)',
  'el-GR': 'Greek (Greece)',
  'gu-IN': 'Gujarati (India)',
  'hi-IN': 'Hindi (India)',
  'hu-HU': 'Hungarian (Hungary)',
  'is-IS': 'Icelandic (Iceland)',
  'id-ID': 'Indonesian (Indonesia)',
  'it-IT': 'Italian (Italy)',
  'ja-JP': 'Japanese (Japan)',
  'kn-IN': 'Kannada (India)',
  'ko-KR': 'Korean (South Korea)',
  'lv-LV': 'Latvian (Latvia)',
  'ms-MY': 'Malay (Malaysia)',
  'ml-IN': 'Malayalam (India)',
  'cmn-CN': 'Mandarin Chinese',
  'cmn-TW': 'Mandarin Chinese',
  'nb-NO': 'Norwegian (Norway)',
  'pl-PL': 'Polish (Poland)',
  'pt-BR': 'Portuguese (Brazil)',
  'pt-PT': 'Portuguese (Portugal)',
  'pa-IN': 'Punjabi (India)',
  'ro-RO': 'Romanian (Romania)',
  'ru-RU': 'Russian (Russia)',
  'sr-RS': 'Serbian (Cyrillic)',
  'sk-SK': 'Slovak (Slovakia)',
  'es-ES': 'Spanish (Spain)',
  'es-US': 'Spanish (US)',
  'sv-SE': 'Swedish (Sweden)',
  'ta-IN': 'Tamil (India)',
  'te-IN': 'Telugu (India)',
  'th-TH': 'Thai (Thailand)',
  'tr-TR': 'Turkish (Turkey)',
  'uk-UA': 'Ukrainian (Ukraine)',
  'vi-VN': 'Vietnamese (Vietnam)'
};
