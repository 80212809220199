import SubTitleIcon from 'assets/icons/subtitle-icon.svg';
import { PropsExport } from '.';
import { EExportName, EStatusProject } from 'ts/enums';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';

export function VisualSubtitlesExport({ exportOption, setExportOption }: PropsExport) {
  const { t } = useTranslation();
  const currentProject = useAppSelector((state) => state.projects.currentProject.data);

  const option = {
    characterPerLine: 32,
    withSingleLine: false,
    withUppercase: false
  };
  return (
    <div className="visual-subtitles-export export-item">
      <div
        className={`d-flex flex-column justify-content-center align-items-center ${
          currentProject && currentProject.status === EStatusProject.SUBTITLED
            ? 'cursor-disabled'
            : 'cursor-pointer'
        }`}
        onClick={() => {
          currentProject &&
            currentProject.status !== EStatusProject.SUBTITLED &&
            setExportOption((draft) => {
              draft.key = EExportName.VISUAL_SUBTITLE_EDITOR;
              draft.data.subData = null;
              draft.data.option = option;
              draft.allowMultipleMedia = false;
            });
        }}
      >
        <div
          className={`icon ${
            exportOption.key === EExportName.VISUAL_SUBTITLE_EDITOR ? 'opacity-100' : 'opacity-50'
          }`}
        >
          <img src={SubTitleIcon} alt="" />
        </div>
        <div
          className={`text mt-1 ${
            exportOption.key === EExportName.VISUAL_SUBTITLE_EDITOR ? '--selected' : ''
          }`}
        >
          <span>{t('Layouts.ExportModal.VisualSubtitlesEditor.Title')}</span>
        </div>
      </div>
    </div>
  );
}
