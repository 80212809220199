/* eslint-disable no-unused-vars */

export enum EAccountSettingId {
  PROFILE = 'profile',
  LOGIN_PREFERENCES = 'login-preferences',
  INVITE_LINK_FREE_CREDIT = 'invite-link-free-credit',
  INVOICES = 'invoices',
  TRANSACTION_HISTORY = 'transaction-history',
  CONNECTED_APPS = 'connected-apps',
  CLOSE_ACCOUNT = 'close-account'
}

export enum EHttpStatusCode {
  /**
   * The server has received the request headers and the client should proceed to send the request body
   * (in the case of a request for which a body needs to be sent; for example, a POST request).
   * Sending a large request body to a server after a request has been rejected for inappropriate headers would be inefficient.
   * To have a server check the request's headers, a client must send Expect: 100-continue as a header in its initial request
   * and receive a 100 Continue status code in response before sending the body. The response 417 Expectation Failed indicates the request should not be continued.
   */
  CONTINUE = 100,

  /**
   * The requester has asked the server to switch protocols and the server has agreed to do so.
   */
  SWITCHING_PROTOCOLS = 101,

  /**
   * A WebDAV request may contain many sub-requests involving file operations, requiring a long time to complete the request.
   * This code indicates that the server has received and is processing the request, but no response is available yet.
   * This prevents the client from timing out and assuming the request was lost.
   */
  PROCESSING = 102,

  /**
   * Standard response for successful HTTP requests.
   * The actual response will depend on the request method used.
   * In a GET request, the response will contain an entity corresponding to the requested resource.
   * In a POST request, the response will contain an entity describing or containing the result of the action.
   */
  OK = 200,

  /**
   * The request has been fulfilled, resulting in the creation of a new resource.
   */
  CREATED = 201,

  /**
   * The request has been accepted for processing, but the processing has not been completed.
   * The request might or might not be eventually acted upon, and may be disallowed when processing occurs.
   */
  ACCEPTED = 202,

  /**
   * SINCE HTTP/1.1
   * The server is a transforming proxy that received a 200 OK from its origin,
   * but is returning a modified version of the origin's response.
   */
  NON_AUTHORITATIVE_INFORMATION = 203,

  /**
   * The server successfully processed the request and is not returning any content.
   */
  NO_CONTENT = 204,

  /**
   * The server successfully processed the request, but is not returning any content.
   * Unlike a 204 response, this response requires that the requester reset the document view.
   */
  RESET_CONTENT = 205,

  /**
   * The server is delivering only part of the resource (byte serving) due to a range header sent by the client.
   * The range header is used by HTTP clients to enable resuming of interrupted downloads,
   * or split a download into multiple simultaneous streams.
   */
  PARTIAL_CONTENT = 206,

  /**
   * The message body that follows is an XML message and can contain a number of separate response codes,
   * depending on how many sub-requests were made.
   */
  MULTI_STATUS = 207,

  /**
   * The members of a DAV binding have already been enumerated in a preceding part of the (multistatus) response,
   * and are not being included again.
   */
  ALREADY_REPORTED = 208,

  /**
   * The server has fulfilled a request for the resource,
   * and the response is a representation of the result of one or more instance-manipulations applied to the current instance.
   */
  IM_USED = 226,

  /**
   * Indicates multiple options for the resource from which the client may choose (via agent-driven content negotiation).
   * For example, this code could be used to present multiple video format options,
   * to list files with different filename extensions, or to suggest word-sense disambiguation.
   */
  MULTIPLE_CHOICES = 300,

  /**
   * This and all future requests should be directed to the given URI.
   */
  MOVED_PERMANENTLY = 301,

  /**
   * This is an example of industry practice contradicting the standard.
   * The HTTP/1.0 specification (RFC 1945) required the client to perform a temporary redirect
   * (the original describing phrase was "Moved Temporarily"), but popular browsers implemented 302
   * with the functionality of a 303 See Other. Therefore, HTTP/1.1 added status codes 303 and 307
   * to distinguish between the two behaviours. However, some Web applications and frameworks
   * use the 302 status code as if it were the 303.
   */
  FOUND = 302,

  /**
   * SINCE HTTP/1.1
   * The response to the request can be found under another URI using a GET method.
   * When received in response to a POST (or PUT/DELETE), the client should presume that
   * the server has received the data and should issue a redirect with a separate GET message.
   */
  SEE_OTHER = 303,

  /**
   * Indicates that the resource has not been modified since the version specified by the request headers If-Modified-Since or If-None-Match.
   * In such case, there is no need to retransmit the resource since the client still has a previously-downloaded copy.
   */
  NOT_MODIFIED = 304,

  /**
   * SINCE HTTP/1.1
   * The requested resource is available only through a proxy, the address for which is provided in the response.
   * Many HTTP clients (such as Mozilla and Internet Explorer) do not correctly handle responses with this status code, primarily for security reasons.
   */
  USE_PROXY = 305,

  /**
   * No longer used. Originally meant "Subsequent requests should use the specified proxy."
   */
  SWITCH_PROXY = 306,

  /**
   * SINCE HTTP/1.1
   * In this case, the request should be repeated with another URI; however, future requests should still use the original URI.
   * In contrast to how 302 was historically implemented, the request method is not allowed to be changed when reissuing the original request.
   * For example, a POST request should be repeated using another POST request.
   */
  TEMPORARY_REDIRECT = 307,

  /**
   * The request and all future requests should be repeated using another URI.
   * 307 and 308 parallel the behaviors of 302 and 301, but do not allow the HTTP method to change.
   * So, for example, submitting a form to a permanently redirected resource may continue smoothly.
   */
  PERMANENT_REDIRECT = 308,

  /**
   * The server cannot or will not process the request due to an apparent client error
   * (e.g., malformed request syntax, too large size, invalid request message framing, or deceptive request routing).
   */
  BAD_REQUEST = 400,

  /**
   * Similar to 403 Forbidden, but specifically for use when authentication is required and has failed or has not yet
   * been provided. The response must include a WWW-Authenticate header field containing a challenge applicable to the
   * requested resource. See Basic access authentication and Digest access authentication. 401 semantically means
   * "unauthenticated",i.e. the user does not have the necessary credentials.
   */
  UNAUTHORIZED = 401,

  /**
   * Reserved for future use. The original intention was that this code might be used as part of some form of digital
   * cash or micro payment scheme, but that has not happened, and this code is not usually used.
   * Google Developers API uses this status if a particular developer has exceeded the daily limit on requests.
   */
  PAYMENT_REQUIRED = 402,

  /**
   * The request was valid, but the server is refusing action.
   * The user might not have the necessary permissions for a resource.
   */
  FORBIDDEN = 403,

  /**
   * The requested resource could not be found but may be available in the future.
   * Subsequent requests by the client are permissible.
   */
  NOT_FOUND = 404,

  /**
   * A request method is not supported for the requested resource;
   * for example, a GET request on a form that requires data to be presented via POST, or a PUT request on a read-only resource.
   */
  METHOD_NOT_ALLOWED = 405,

  /**
   * The requested resource is capable of generating only content not acceptable according to the Accept headers sent in the request.
   */
  NOT_ACCEPTABLE = 406,

  /**
   * The client must first authenticate itself with the proxy.
   */
  PROXY_AUTHENTICATION_REQUIRED = 407,

  /**
   * The server timed out waiting for the request.
   * According to HTTP specifications:
   * "The client did not produce a request within the time that the server was prepared to wait. The client MAY repeat the request without modifications at any later time."
   */
  REQUEST_TIMEOUT = 408,

  /**
   * Indicates that the request could not be processed because of conflict in the request,
   * such as an edit conflict between multiple simultaneous updates.
   */
  CONFLICT = 409,

  /**
   * Indicates that the resource requested is no longer available and will not be available again.
   * This should be used when a resource has been intentionally removed and the resource should be purged.
   * Upon receiving a 410 status code, the client should not request the resource in the future.
   * Clients such as search engines should remove the resource from their indices.
   * Most use cases do not require clients and search engines to purge the resource, and a "404 Not Found" may be used instead.
   */
  GONE = 410,

  /**
   * The request did not specify the length of its content, which is required by the requested resource.
   */
  LENGTH_REQUIRED = 411,

  /**
   * The server does not meet one of the preconditions that the requester put on the request.
   */
  PRECONDITION_FAILED = 412,

  /**
   * The request is larger than the server is willing or able to process. Previously called "Request Entity Too Large".
   */
  PAYLOAD_TOO_LARGE = 413,

  /**
   * The URI provided was too long for the server to process. Often the result of too much data being encoded as a query-string of a GET request,
   * in which case it should be converted to a POST request.
   * Called "Request-URI Too Long" previously.
   */
  URI_TOO_LONG = 414,

  /**
   * The request entity has a media type which the server or resource does not support.
   * For example, the client uploads an image as image/svg+xml, but the server requires that images use a different format.
   */
  UNSUPPORTED_MEDIA_TYPE = 415,

  /**
   * The client has asked for a portion of the file (byte serving), but the server cannot supply that portion.
   * For example, if the client asked for a part of the file that lies beyond the end of the file.
   * Called "Requested Range Not Satisfiable" previously.
   */
  RANGE_NOT_SATISFIABLE = 416,

  /**
   * The server cannot meet the requirements of the Expect request-header field.
   */
  EXPECTATION_FAILED = 417,

  /**
   * This code was defined in 1998 as one of the traditional IETF April Fools' jokes, in RFC 2324, Hyper Text Coffee Pot Control Protocol,
   * and is not expected to be implemented by actual HTTP servers. The RFC specifies this code should be returned by
   * teapots requested to brew coffee. This HTTP status is used as an Easter egg in some websites, including Google.com.
   */
  I_AM_A_TEAPOT = 418,

  /**
   * The request was directed at a server that is not able to produce a response (for example because a connection reuse).
   */
  MISDIRECTED_REQUEST = 421,

  /**
   * The request was well-formed but was unable to be followed due to semantic errors.
   */
  UNPROCESSABLE_ENTITY = 422,

  /**
   * The resource that is being accessed is locked.
   */
  LOCKED = 423,

  /**
   * The request failed due to failure of a previous request (e.g., a PROPPATCH).
   */
  FAILED_DEPENDENCY = 424,

  /**
   * The client should switch to a different protocol such as TLS/1.0, given in the Upgrade header field.
   */
  UPGRADE_REQUIRED = 426,

  /**
   * The origin server requires the request to be conditional.
   * Intended to prevent "the 'lost update' problem, where a client
   * GETs a resource's state, modifies it, and PUTs it back to the server,
   * when meanwhile a third party has modified the state on the server, leading to a conflict."
   */
  PRECONDITION_REQUIRED = 428,

  /**
   * The user has sent too many requests in a given amount of time. Intended for use with rate-limiting schemes.
   */
  TOO_MANY_REQUESTS = 429,

  /**
   * The server is unwilling to process the request because either an individual header field,
   * or all the header fields collectively, are too large.
   */
  REQUEST_HEADER_FIELDS_TOO_LARGE = 431,

  /**
   * A server operator has received a legal demand to deny access to a resource or to a set of resources
   * that includes the requested resource. The code 451 was chosen as a reference to the novel Fahrenheit 451.
   */
  UNAVAILABLE_FOR_LEGAL_REASONS = 451,

  /**
   * A generic error message, given when an unexpected condition was encountered and no more specific message is suitable.
   */
  INTERNAL_SERVER_ERROR = 500,

  /**
   * The server either does not recognize the request method, or it lacks the ability to fulfill the request.
   * Usually this implies future availability (e.g., a new feature of a web-service API).
   */
  NOT_IMPLEMENTED = 501,

  /**
   * The server was acting as a gateway or proxy and received an invalid response from the upstream server.
   */
  BAD_GATEWAY = 502,

  /**
   * The server is currently unavailable (because it is overloaded or down for maintenance).
   * Generally, this is a temporary state.
   */
  SERVICE_UNAVAILABLE = 503,

  /**
   * The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.
   */
  GATEWAY_TIMEOUT = 504,

  /**
   * The server does not support the HTTP protocol version used in the request
   */
  HTTP_VERSION_NOT_SUPPORTED = 505,

  /**
   * Transparent content negotiation for the request results in a circular reference.
   */
  VARIANT_ALSO_NEGOTIATES = 506,

  /**
   * The server is unable to store the representation needed to complete the request.
   */
  INSUFFICIENT_STORAGE = 507,

  /**
   * The server detected an infinite loop while processing the request.
   */
  LOOP_DETECTED = 508,

  /**
   * Further extensions to the request are required for the server to fulfill it.
   */
  NOT_EXTENDED = 510,

  /**
   * The client needs to authenticate to gain network access.
   * Intended for use by intercepting proxies used to control access to the network (e.g., "captive portals" used
   * to require agreement to Terms of Service before granting full Internet access via a Wi-Fi hotspot).
   */
  NETWORK_AUTHENTICATION_REQUIRED = 511
}

export enum ERoutes {
  HOME = '/',
  SIGN_IN = '/signin',
  SIGN_UP = '/signup',
  TRANSCRIPT_DETAIL = '/app/projects/transcript',
  PROJECT_SHARING = '/app/projects',
  MAIN = '/app',
  AUTH_CALLBACK = '/callback',
  UPLOAD = 'projects/upload',
  ACCOUNT_SETTING = '/app/account-setting',
  LASTEST_TRANSACTION = '/app/admin/latestTransaction',
  DETAIL_TRANSACTION = '/app/admin/detailedTransaction',
  USER_LOOKUP = '/app/admin/users',
  PROJECT_DETAIL_VIEW = '/app/admin/projectDetailView',
  TEAM = '/app/admin/teams',
  PLANS = '/app/admin/plans',
  PUBLISHED_PROJECT = '/app/admin/published-projects',
  ADMIN_DEFAULT_CONFIG = '/app/admin/default-config',
  BLACK_WHITE_LIST = '/app/admin/blacklistWhitelist',
  METRICS = '/app/admin/metrics',
  MERGE_PROJECTS = '/app/admin/mergeProject',
  BOX_CALL_BACK = '/box-auth-completion',
  LANGUAGES = '/app/admin/languages',
  ADD_REMOVE_ADMIN = '/app/admin/addAdmin',
  PURCHASE_SUCCESS = '/app/purchase-success'
}

export enum EUpgradeOptions {
  STARTER_MONTHLY = 'STARTER_MONTHLY',
  STARTER_ANNUAL = 'STARTER_ANNUAL',
  PRO_MONTHLY = 'PRO_MONTHLY',
  PRO_ANNUAL = 'PRO_ANNUAL',
  TEAM_MONTHLY = 'TEAM_MONTHLY',
  TEAM_ANNUAL = 'TEAM_ANNUAL',
  BUSINESS_MONTHLY = 'BUSINESS_MONTHLY',
  BUSINESS_ANNUAL = 'BUSINESS_ANNUAL',
  PROPLUS_ANNUAL = 'PROPLUS_ANNUAL',
  PROPLUS_MONTHLY = 'PROPLUS_MONTHLY'
}

export enum EStatusProject {
  OPEN = 'open',
  UPLOADING = 'uploading',
  TRANSCRIBING = 'transcribing',
  TRANSLATING = 'translating',
  READY = 'ready',
  TRANSLATED = 'translated',
  SUBTITLED = 'subtitled',
  SHARING = 'sharing',
  FAILED = 'failed',
  TRANSLATE_PENDING = 'translate_pending',
}

export enum EStatusProjectModify {
  OPEN = 'open',
  TRANSCRIBED = 'transcribed',
  TRANSCRIBING = 'transcribing',
  SUBTITLED = 'subtitled',
  TRANSLATED = 'translated'
}

export enum EStatusMedia {
  UPLOADING = 'uploading',
  READY = 'ready',
  PREPARING = 'preparing',
  STARTING = 'starting',
  TRANSCODING = 'transcoding',
  FAILED = 'failed',
  TRANSLATED = 'translated',
  TRANSLATING = 'translating',
  TRANSCRIBED = 'transcribed',
  TRANSCRIBING = 'transcribing',
  SUBTITLED = 'subtitled',
  EMPTY = 'empty'
}

export enum ETypeDragDrop {
  PROJECT = 'project'
}

export enum EModals {
  NONE = 'none',
  ADD_CREDIT_MODAL = 'add-credit-modal',
  SUBSCRIBE_MODAL = 'subscribe-modal',
  SHARE_PROJECT_MODAL = 'share-project-modal',
  TRANSLATE_MODAL = 'translate-modal',
  QUICK_TIPS_MODAL = 'quick-tips-modal',
  EXPORT_MODAL = 'export-modal',
  PAYMENT = 'payment-modal',
  UPDATE_AVATAR = 'update-avatar',
  CHANGE_EMAIL = 'change-email',
  ADD_DICTIONARY_MODAL = 'add-dictionary-modal',
  DICTIONARY_QUICK_ADD = 'dictionary-quick-add',
  CONFIRM_DELETE_ACCOUNT = 'confirm-delete-account',
  LAST_CONFIRM_DELETE_ACCOUNT = 'last-confirm-delete-account',
  BURN_IN_MODAL = 'burn-in-modal',
  HOORAY_MODAL = 'hooray-modal',
  VERSION_HISTORY_MODAL = 'verison-history-modal',
  UPDATE_CREDIT_CARD = 'update-credit-card',
  CHOOSER_OPTIONS_PLAN_MODAL = 'chooser-options-plan-modal',
  DUBBING = 'dubbing-modal',
  PRICE_DUBBING_MODAL = 'price-dubbing-modal',
  NOTI_MODAL = 'noti-modal',
  SPEECH_API_MODAL = 'speech-api-modal',
  CONFIRM_DELETE_TEAM_MODAL = 'confirm-delete-team-modal',
  ASK_BEFORE_CHANGE_PAGE = 'ask-before-change-page'
}

export enum EFolderIdDefault {
  MY_PROJECT = 'my-project',
  SHARED_WITH_ME = 'share-with-me',
  PROJECT_ALL = 'project-all'
}

export enum EStatePopOverLabel {
  LISTING_LABEL = 'listing-label',
  CREATE_LABEL = 'create-label',
  UPDATE_LABEL = 'update-label'
}

export enum ETagColor {
  DEEP_LILAC = '#9b59b6',
  VIVID_TANGELO = '#e67e22',
  UFO_GREEN = '#2ecc71',
  CARMINE_PINK = '#e74c3c',
  SILVER_SAND = '#bdc3c7',
  JONQUI = '#f1c40f',
  MOONSTONE = '#3498bd',
  GREEN = '#16a085',
  LIGHT_SEA_GREEN = '#1abc9c',
  POLICE_BLUE = '#34495e'
}
export enum EPlanOption {
  PAY_AS_YOU_GO = 'PAY_AS_YOU_GO',
  STARTER_MONTHLY = 'STARTER_MONTHLY',
  STARTER_ANNUAL = 'STARTER_ANNUAL',
  PRO_MONTHLY = 'PRO_MONTHLY',
  PRO_ANNUAL = 'PRO_ANNUAL',
  TEAM_MONTHLY = 'TEAM_MONTHLY',
  TEAM_ANNUAL = 'TEAM_ANNUAL',
  BUSINESS_MONTHLY = 'BUSINESS_MONTHLY',
  BUSINESS_ANNUAL = 'BUSINESS_ANNUAL',
  PROPLUS_ANNUAL = 'PROPLUS_ANNUAL',
  PROPLUS_MONTHLY = 'PROPLUS_MONTHLY',
  CREATOR_MONTHLY = 'CREATOR_MONTHLY',
  CREATOR_ANNUAL = 'CREATOR_ANNUAL',
  STUDIO_ANNUAL = 'STUDIO_ANNUAL',
  STUDIO_MONTHLY = 'STUDIO_MONTHLY',
  PRO042024_MONTHLY = 'PRO042024_MONTHLY',
  PRO042024_ANNUAL = 'PRO042024_ANNUAL'
}

export enum EPeopleType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY'
}

export enum EPlanType {
  ANNUAL = 'Annual',
  MONTHLY = 'Monthly'
}

export enum EShareEmailStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE'
}

export enum EShareType {
  INVITE = 'INVITE',
  LINK = 'LINK',
  PUBLISH = 'PUBLISH'
}

export enum EShareRight {
  DISABLED = 'DISABLED',
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  PUBLISH = 'PUBLISH'
}

export enum EPublishAction {
  DISABLED = 'DISABLED',
  PUBLISH = 'PUBLISH',
  'RE-PUBLISH' = 'RE-PUBLISH'
}

export enum EMoveProject {
  MAIN_TO_FOLDER = 'MainToFolder',
  FOLDER_TO_MAIN = 'FolderToMain'
}

export enum EExportName {
  WORD = 'word',
  TRANSLATE = 'translate',
  PREMIERE_PRO = 'premiere-pro',
  FCPX = 'fcpx',
  AVID_MEDIA = 'avid-media',
  SUBTITLES = 'subtitles',
  VISUAL_SUBTITLE_EDITOR = 'visual-subtitle-editor',
  BURN_IN = 'burn-in',
  SHARE_PROJECT = 'share-project',
  OTHER = 'other'
}

export enum EExportSubData {
  MARKERS = 'markers',
  CAPTION_SUBTITLES = 'caption/subtitles',
  CAPTIONS = 'captions',
  RANGERS = 'rangers',
  TITLES = 'titles',
  MARKERS_LOCATION_UTF8 = 'markers-location-utf-8',
  MARKERS_LOCATORS_EN = 'markers-locators-en',
  SCRIPT_SYNC = 'script-sync',
  SCRIPT_SYNC_NEW = 'script-sync-new',
  SUB_CAP = 'sub-cap',
  AAF = 'aaf',
  SRT = 'srt',
  FACEBOOK_YOUTUBE = 'facebook-youtube',
  WEB_VTT = 'web-vtt',
  AVID_SUBCAP = 'avid-subcap',
  VISUAL_SUBTITLE_EDITOR = 'visual-subtitle-editor',
  SAMI = 'sami',
  BURN_IN = 'burn-in',
  SUB_TITLES = 'subtitles',
  TIME_CODE_BITC = 'time-code-bitc',
  AVID_PRO_TOOLS_AFF = 'avid-pro-tools-aff',
  DAVINCI_RESOLVE = 'davinci-resolve',
  ADOBE_AUDITION = 'adobe-audition',
  EXCEL = 'excel',
  JSON = 'json',
  XML = 'xml',
  FINAL_CUT_PRO7 = 'final-cut-pro-7',
  TEXT = 'text',
  EDIUS = 'edius',
  LUMBER_JACK_READY = 'lumber-jack-ready',
  MAXQDA = 'maxqda'
}

export enum EFileType {
  DOC = 'doc',
  SRT = 'srt',
  XML = 'xml',
  FCP_XML = 'fcpXml',
  AVID_LOCATOR = 'avidLocator',
  AVID_SCRIPT_SYNC = 'avidScriptSync',
  AVID_SCRIPT_SYNC_TC = 'avidScriptSyncTC',
  AVID_SCRIPT_SYNC_NEW = 'avidScriptSyncNew',
  STL = 'stl',
  AAF = 'aaf',
  VTT = 'vtt',
  SAMI = 'sami',
  EDL = 'edl',
  CSV = 'csv',
  JSON = 'json',
  TXT = 'txt',
  LUMBER_JACK_TEXT = 'lumberjackText',
  MAXQDA = 'maxqda'
}

export enum EJsonFileOption {
  CAPTION = '{"fcpXExportType":"captions"}',
  RANGE = '{"fcpXExportType":"ranges"}',
  TITLE = '{"fcpXExportType":"titles"}'
}

export enum EAvidLocatorType {
  AVID_LOCATOR_UTF8 = 'avidLocatorUTF8',
  AVID_SCRIPT_SYNC = 'avidScriptSync',
  AVID_SCRIPT_SYNC_TC = 'avidScriptSyncTC',
  AVID_SCRIPT_SYNC_NEW = 'avidScriptSyncNew',
  AVID_LOCATOR_MAC_OS_ROMAN = 'avidLocatorMacOSRoman',
  AAF = 'aaf',
  STL = 'stl'
}
export enum EEmptyState {
  SHARED_WITH_ME = 'share-with-me',
  DEFAULT = 'default'
}

export enum ESocketTopic {
  MEDIA_UPDATE_PROJECT = 'mediaUpdate/project',
  UPLOAD_PROGRESS = 'uploadProgress',
  MEDIA_TRANSCODING_SPEED = 'mediaTranscodingSpeed',
  PROJECT_STATUS_UPDATE = 'projectStatusUpdate',
  USER_DATA = 'userData',
  CHANGED_RIGHT_SHARED_PROJECT_WITH_USER = 'changedRightSharedProjectWith/user',
  ERRORS = 'errors'
}

export enum EPayType {
  BURN_IN = 'BURNIN',
  BURN_IN_TIME_CODE = 'burnInTimecode',
  DUB = 'DUB',
  BUY_CREDIT = 'buyCredit'
}

export enum EUploaderType {
  LOCAL = 'LOCAL',
  DROPBOX = 'DROPBOX',
  GDRIVE = 'DRIVE',
  BOX = 'BOX'
}

export enum EGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export enum ETypeNoti {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  CONFIRM = 'confirm'
}

export enum EStyle {
  UNDERLINE = 'UNDERLINE',
  ITALIC = 'ITALIC',
  BOLD = 'BOLD',
  HIGHLIGHT = 'HIGHLIGHT',
  UPPERCASE = 'UPPERCASE',
  LOWERCASE = 'LOWERCASE',
  SENTENCECASE = 'SENTENCECASE',
  STRIKETHROUGH = 'STRIKETHROUGH',
  DICTIONARY = 'DICTIONARY'
}

export enum EStyleFilter {
  HIGHLIGHT = 'HIGHLIGHT',
  ANNOTATION = 'FILTER_BLOCK_DATA_annotation'
}

export enum ERole {
  ROLE_USER = 'ROLE_USER',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_SUPERADMIN = 'ROLE_SUPERADMIN'
}

export enum EFilterUserType {
  RECENT_ACTIVE = 'recentActive',
  RECENT_JOINED = 'recentJoined',
  DELETE = 'delete'
}

export enum EMetrics {
  NEW_USERS = 'newUsers',
  PROJECTS_CREATED = 'createdProject',
  TRANSCRIBED = 'transcribed',
  TRANSLATED = 'translated',
  BURN_IN = 'burn',
  EXPORTED = 'exportedFile'
}

export enum ETypeFileAccept {
  VIDEO = '.3g2, .asf, .avchd, .flv, .m4v, .mxf, .mp4, .mpeg, .mpg, .mov, .mkv, .mts, .rm, .swf, .vob, .wmv, .aac, .aif, .aiff, .iff, .flac, .m3u, .m4a, .midi, .mkv, .mp2, .mp3, .mp4, .mpa, .ogg, .opus, .wav, .wma, .avi'
}

export enum ETypeSubcribeModal {
  UPDRAGE = 'upgrade',
  DOWNGRADE = 'downgrade'
}

export enum EFileSizeMax {
  LOCAL = 5368709120, //5 GB
  OTHER = 21474836480 //20 GB
}

export enum EDurationMax {
  LOCAL = 4 * 60 * 60 //4 hours
}

export enum EMediaType {
  VIDEO = 'video',
  AUDIO = 'audio'
}

export enum EChangePlanOption {
  NULL,
  CANCEL_PLAN,
  DOWNGRADE_PLAN,
  UPGRADE_PLAN
}

export enum EChangePlanStep {
  CONFIRM,
  CANCEL_SURVEY,
  FINAL_OFFER,
  CANCEL_DELETED,
  DOWNGRADE_CONFIRMED
}

export enum EBurnInType {
  WITH_TIME_CODE = 'burnInTimecode',
  SUBTITLE = 'burnInSubtitle'
}

const audio = 'audio';
const video = 'video';
const image = 'image';

interface SupportedCheckDuration {
  [key: string]: {
    type: string;
    mimeType: string;
  };
}

export const SUPPORTED_CHECK_DURATION: SupportedCheckDuration = {
  '3g2': {
    type: video,
    mimeType: video + '/3gpp2'
  },
  aac: {
    type: audio,
    mimeType: audio + '/aac'
  },
  mp4: {
    type: video,
    mimeType: video + '/mp4'
  },
  mp3: {
    type: audio,
    mimeType: audio + '/mp3'
  },
  mkv: {
    type: video,
    mimeType: video + '/x-matroska'
  },
  m4a: {
    type: audio,
    mimeType: audio + '/mp4'
  },
  mov: {
    type: video,
    mimeType: video + '/quicktime'
  },
  wav: {
    type: audio,
    mimeType: audio + '/wav'
  },
  ogg: {
    type: video,
    mimeType: audio + '/ogg'
  }
};

export enum EDelayTime {
  FAST = 0.1
}

export enum EMaxLength {
  LANGUAGES = 20
}

export enum EFolderSortOption {
  NAME,
  DATE
}

export enum EProjectSortOption {
  NAME,
  DATE
}
