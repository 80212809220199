/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TTranscript, TWord } from 'ts/types';
import {
  getTranscriptionByMediaIdAction,
  updateTranscriptionByMediaIdAction
} from './transcription.action';
import { EStyle } from 'ts/enums';

export interface BlockDomItem {
  id: string;
  start: number;
  end: number;
}

type TranscriptState = {
  currentTranscription: {
    data: Array<TTranscript> | null;
    isLoading: boolean;
    error: any;
    isFetched: boolean;
  };
  openSearchBox: boolean;
  lastTranscript: Array<TTranscript>;
  isChangeTranscript: boolean;
  isSaving: boolean;
  transcriptCursorTime: number;
  isHandleSave: boolean;
  currentFilters: string[];
  blockDomItem: BlockDomItem[];
  isNextTranscript: boolean;
  isPrevTranscript: boolean;
  isOnlyBookmark: boolean;
  selectedSpeaker: {
    id: number | null;
  };
  selectedText: string;
};

const initialState: TranscriptState = {
  currentTranscription: {
    data: null,
    isLoading: true,
    error: null,
    isFetched: false
  },
  openSearchBox: false,
  lastTranscript: [],
  isChangeTranscript: false,
  transcriptCursorTime: 0.0,
  isSaving: false,
  isHandleSave: false,
  currentFilters: [],
  blockDomItem: [],
  isNextTranscript: false,
  isPrevTranscript: false,
  isOnlyBookmark: false,
  selectedSpeaker: {
    id: null
  },
  selectedText: ""
};

const transcriptionSlice = createSlice({
  name: 'transcript',
  initialState,
  reducers: {
    changeOpenSearchBox: (state) => {
      state.openSearchBox = !state.openSearchBox;
    },
    closeSearchBox: (state) => {
      state.openSearchBox = false;
    },
    setLastTranscrip: (state, { payload }: PayloadAction<{ newTranscript: TTranscript[] }>) => {
      state.lastTranscript = payload.newTranscript.map((transcript) => {
        const wordJSON: TWord[] = JSON.parse(transcript.wordsJson);

        const isBookmarked =
          wordJSON.filter((word) => word.style && word.style.includes(EStyle.HIGHLIGHT)).length > 0;

        return Object.assign({}, transcript, { bookmark: isBookmarked });
      });
    },
    setIsChangeTranscrip: (state, { payload }: PayloadAction<{ isChange: boolean }>) => {
      state.isChangeTranscript = payload.isChange;
    },
    updateCurrentTranscript: (state, { payload }: PayloadAction<{ newTranscript: any }>) => {
      state.currentTranscription.data = payload.newTranscript;
    },
    resetCurrentTranscript: (state) => {
      state.currentTranscription = {
        data: null,
        isLoading: true,
        error: null,
        isFetched: false
      };
    },
    setTranscriptCursorTime: (state, { payload }: PayloadAction<{ time: number }>) => {
      state.transcriptCursorTime = payload.time;
    },
    setIsHandleSave: (state, { payload }: PayloadAction<{ isHandleSave: boolean }>) => {
      state.isHandleSave = payload.isHandleSave;
    },
    changeFilterStyle: (state, { payload }: PayloadAction<{ filterStyle: string }>) => {
      if (state.currentFilters.includes(payload.filterStyle)) {
        state.currentFilters = state.currentFilters.filter((item) => item !== payload.filterStyle);
      } else {
        state.currentFilters = [...state.currentFilters, payload.filterStyle];
      }
    },
    setBlockDomItem: (state, { payload }: PayloadAction<{ blockDomItem: BlockDomItem[] }>) => {
      state.blockDomItem = payload.blockDomItem;
    },
    handleNextTranscript: (
      state,
      { payload }: PayloadAction<{ isNext: boolean; isOnlyBookmark: boolean }>
    ) => {
      state.isNextTranscript = payload.isNext;
      state.isOnlyBookmark = payload.isOnlyBookmark;
    },
    handlePrevTranscript: (
      state,
      { payload }: PayloadAction<{ isPrev: boolean; isOnlyBookmark: boolean }>
    ) => {
      state.isPrevTranscript = payload.isPrev;
      state.isOnlyBookmark = payload.isOnlyBookmark;
    },
    setSelectedSpeaker: (state, { payload }: PayloadAction<{ id: number | null }>) => {
      state.selectedSpeaker.id = payload.id;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getTranscriptionByMediaIdAction.pending, (state) => {
      state.currentTranscription = {
        ...state.currentTranscription,
        isLoading: true,
        error: null,
        isFetched: false
      };
    });

    addCase(getTranscriptionByMediaIdAction.fulfilled, (state, { payload }) => {
      if (payload) {
        const tmpPayload = [...payload];
        tmpPayload.forEach((t) => {
          t.bookmark = false;
          const wordJsonParse = JSON.parse(t.wordsJson);
          if (wordJsonParse.length > 0) {
            wordJsonParse.forEach((w: any) => {
              if (w.style && w.style.includes('HIGHLIGHT')) {
                t.bookmark = true;
              }
            });
          }
        });
        state.currentTranscription = {
          data: tmpPayload,
          isLoading: false,
          error: null,
          isFetched: true
        };
        state.lastTranscript = tmpPayload;
      }
    });

    addCase(getTranscriptionByMediaIdAction.rejected, (state, { payload }) => {
      state.currentTranscription = {
        data: null,
        isLoading: false,
        error: payload,
        isFetched: true
      };
    });

    addCase(updateTranscriptionByMediaIdAction.pending, (state) => {
      state.isSaving = true;
    });

    addCase(updateTranscriptionByMediaIdAction.fulfilled, (state, { payload }) => {
      state.isSaving = false;
      state.lastTranscript = payload;
      // state.currentTranscription.data = payload;
      state.isChangeTranscript = false;
    });
  }
});

export const {
  changeOpenSearchBox,
  closeSearchBox,
  setLastTranscrip,
  setIsChangeTranscrip,
  updateCurrentTranscript,
  resetCurrentTranscript,
  setTranscriptCursorTime,
  setIsHandleSave,
  changeFilterStyle,
  setBlockDomItem,
  handleNextTranscript,
  handlePrevTranscript,
  setSelectedSpeaker
} = transcriptionSlice.actions;

export default transcriptionSlice.reducer;
