import { IoIosArrowForward } from 'react-icons/io';
import { TSpeaker } from 'ts/types';
import ContentPopoverSpeaker from './ContentPopoverSpeaker';
import { useEffect, useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutSide';
import { useEditorContext } from 'context/EditorContext';

type Props = {
  speaker?: TSpeaker;
  id: string;
  updateAllMatchingAutoSpeaker: (speaker: TSpeaker | null) => void;
  enable: boolean;
  checkPermissionEdit: () => void;
};

const Speaker = ({
  speaker,
  id,
  updateAllMatchingAutoSpeaker,
  enable,
  checkPermissionEdit
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const { setIsReadonly } = useEditorContext();

  useClickOutside({
    insideRef: ref,
    afterClickOutSide: () => {
      setOpen(false);
    }
  });

  useEffect(() => {
    if (!open) {
      setIsReadonly(false);
    }
  }, [open]);

  return (
    <div className="position-relative" ref={ref}>
      <span
        onClick={(e) => {
          e.stopPropagation();
          checkPermissionEdit();
          if (enable) {
            setOpen(!open);
            setIsReadonly(true);
            setTimeout(() => {
              document.getElementById(`${id}-input-add-speaker`)?.focus();
            }, 500);
          }
        }}
      >
        {speaker ? (
          <span
            contentEditable={false}
            className="ms-1 fw-bold cursor-pointer transcript-speaker align-middle"
            style={{ color: speaker.color }}
          >
            {speaker.name}
          </span>
        ) : (
          <span className="cursor-pointer">
            <IoIosArrowForward className="ms-1" color="#7e56a2" style={{ marginBottom: 2 }} />
          </span>
        )}
      </span>
      {open && (
        <ContentPopoverSpeaker
          currentSpeaker={speaker || null}
          updateAllMatchingAutoSpeaker={(speaker) => {
            updateAllMatchingAutoSpeaker(speaker);
          }}
          id={id}
        />
      )}
    </div>
  );
};

export default Speaker;
