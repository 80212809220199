import {
  EAvidLocatorType,
  EExportName,
  EExportSubData,
  EFileType,
  EJsonFileOption,
  EMediaType,
  EModals,
  ERoutes,
  EStatusProject,
  ETypeNoti
} from 'ts/enums';
import React from 'react';
import { ModalBase } from 'components/common';
import { FaDownload } from 'react-icons/fa6';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { popModal, pushModal } from 'redux/modal/modal.slice';
import { WordExport } from './WordExport';
import { TranslateExport } from './TranslateExport';
import { PremiereExport } from './PremiereExport';
import { FCPExport } from './FCPExport';
import { AvidMediaExport } from './AvidMediaExport';
import { SubtitlesExport } from './SubtitlesExport';
import { VisualSubtitlesExport } from './VisualSubtitlesExport';
import { BurnInExport } from './BurnInExport';
import { ShareProjectExport } from './ShareProjectExport';
import { OtherExport } from './OtherExport';
import { useEffect, useState } from 'react';
import { exportFile, exportVisualFile } from 'apis/export.api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { downloadFileLocally } from 'utils/export';
import { TExportOption, TMedia } from 'ts/types';
import { RenderOption } from './RenderOption';
import { usePageLoading } from 'context/PageLoadingContext';
import { TAxiosResponse } from 'ts/types/TResponse';
import { Updater, useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { resetCurrentTranscript } from 'redux/transcription/transcription.slice';
import { resetCurrentProject } from 'redux/projects/projects.slice';

export type PropsExport = {
  exportOption: TExportOption;
  setExportOption: Updater<TExportOption>;
};

export function ExportModal() {
  const dispatch = useAppDispatch();
  const currentProject = useAppSelector((state) => state.projects.currentProject).data;
  const isLoading = useAppSelector((state) => state.projects.currentProject).isLoading;

  const data = useAppSelector((state) => state.modal.lastModal?.data);
  const { openMessage, destroyMessage } = usePageLoading();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [exportOption, setExportOption] = useImmer<TExportOption>({
    key: EExportName.WORD,
    data: {
      subData: null,
      option: {
        withTimeCode: true
      }
    },
    allowMultipleMedia: true,
    currentMediaId: null
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.mediaId) {
      setExportOption((draft) => {
        draft.currentMediaId = data.mediaId;
      });
    }
    if (data?.defaultOption?.key) {
      setExportOption((draft) => {
        draft.key = data.defaultOption.key;
      });
    }
    if (data?.defaultOption?.data?.option) {
      setExportOption((draft) => {
        draft.data.option = data.defaultOption.data.option;
      });
    }
  }, [data]);

  function handleExportVisualSubtitle() {
    if (currentProject) {
      destroyMessage();
      openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
      exportVisualFile({
        projectId: currentProject.id,
        data: {
          withGapless: false,
          withSingleLine: exportOption.data.option.withSingleLine,
          withUppercase: exportOption.data.option.withUppercase,
          characterPerLine: exportOption.data.option.characterPerLine
        }
      }).then((res) => {
        destroyMessage();
        dispatch(popModal());
        searchParams.delete('mediaId');
        setSearchParams(searchParams);
        dispatch(resetCurrentTranscript());
        dispatch(resetCurrentProject());
        navigate(`${ERoutes.TRANSCRIPT_DETAIL}/${res.data.projectId}`);
      });
    }
  }

  function handleDownloadAndClose(res: TAxiosResponse<Blob> | undefined) {
    if (res) {
      downloadFileLocally(res);
      dispatch(popModal());
      destroyMessage();
    }
  }

  async function handleExportError(e: any) {
    var message = t('Shared.Errors.ErrorExporting');
    if (e?.response?.status === 422) {
      const arr = new Uint8Array(e.response.data);
      const decoder = new TextDecoder('utf-8');
      const str = decoder.decode(arr);

      if (/[\u0080-\uffff]/.test(str)) {
        message = 'This string seems to contain (still encoded) multibytes';
      } else {
        var errorJson;
        try {
          errorJson = JSON.parse(str);
        } catch (e) {
          // invalid json
        }

        if (errorJson && errorJson.message) {
          message = 'Transcription export failed due to ' + errorJson.message;
        }
      }
    } else if (e?.response?.status === 524) {
      // Cloudflare 524
      message =
        'Hi, exporting is taking longer to complete, please check your dashboard/project list in 5 mins :)';

      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: { type: ETypeNoti.SUCCESS, title: message }
        })
      );

      return;
    } else {
      message = 'Your selected media is not transcribed.';
    }

    dispatch(
      pushModal({
        name: EModals.NOTI_MODAL,
        data: null,
        notiData: { type: ETypeNoti.ERROR, title: message }
      })
    );

    destroyMessage();
    // openMessage('error', t('Shared.Errors.ErrorExporting'));
    // const res = await JSON.parse(new TextDecoder().decode(error.response.data));
    // notification.open({ type: 'error', message: res.message });
  }

  function canBurnIn(mediaId: string | null, listMedia: TMedia[]): boolean {
    if (
      mediaId != null &&
      listMedia.find((media) => media.id == mediaId)?.type == EMediaType.AUDIO
    ) {
      openMessage('warning', t('Layouts.ExportModal.BurnIn.FileNotVideo'));
      return false;
    } else {
      if (listMedia.filter((media) => media.type == EMediaType.VIDEO)) {
        return true;
      } else {
        openMessage('warning', t('Layouts.ExportModal.BurnIn.ProjectNoVideo'));
        return false;
      }
    }
  }

  function handleExport() {
    if (!currentProject) return;
    switch (exportOption.key) {
      case EExportName.WORD:
        destroyMessage();
        openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
        exportFile({
          projectId: currentProject.id,
          currentMediaId: exportOption.currentMediaId,
          fileType: EFileType.DOC,
          ...exportOption.data.option
        })
          .then(handleDownloadAndClose)
          .catch(handleExportError);

        break;

      case EExportName.TRANSLATE:
        dispatch(popModal());
        dispatch(pushModal({ name: EModals.TRANSLATE_MODAL, data: '' }));
        break;

      case EExportName.PREMIERE_PRO:
        destroyMessage();
        openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
        exportFile({
          projectId: currentProject.id,
          currentMediaId: exportOption.currentMediaId,
          fileType:
            exportOption.data.subData === EExportSubData.MARKERS ? EFileType.XML : EFileType.SRT,
          ...exportOption.data.option
        })
          .then(handleDownloadAndClose)
          .catch(handleExportError);
        break;

      case EExportName.FCPX:
        destroyMessage();
        openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
        exportFile({
          projectId: currentProject.id,
          currentMediaId: exportOption.currentMediaId,
          fileType: EFileType.FCP_XML,
          jsonFileOptions:
            exportOption.data.subData === EExportSubData.CAPTIONS
              ? EJsonFileOption.CAPTION
              : exportOption.data.subData === EExportSubData.RANGERS
              ? EJsonFileOption.RANGE
              : EJsonFileOption.TITLE,
          ...exportOption.data.option
        })
          .then(handleDownloadAndClose)
          .catch(handleExportError);
        break;

      case EExportName.AVID_MEDIA:
        destroyMessage();
        openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
        exportFile({
          projectId: currentProject.id,
          currentMediaId: exportOption.currentMediaId,
          fileType:
            exportOption.data.subData === EExportSubData.MARKERS_LOCATION_UTF8
              ? EFileType.AVID_LOCATOR
              : exportOption.data.subData === EExportSubData.MARKERS_LOCATORS_EN
              ? EFileType.AVID_LOCATOR
              : exportOption.data.subData === EExportSubData.SCRIPT_SYNC
              ? exportOption.data.option.withTimeCode
                ? EFileType.AVID_SCRIPT_SYNC_TC
                : EFileType.AVID_SCRIPT_SYNC
              : exportOption.data.subData === EExportSubData.SUB_CAP
              ? EFileType.STL
              : exportOption.data.subData === EExportSubData.SCRIPT_SYNC_NEW
              ? EFileType.AVID_SCRIPT_SYNC_NEW
              : EFileType.AAF,
          avidLocatorType:
            exportOption.data.subData === EExportSubData.MARKERS_LOCATORS_EN
              ? EAvidLocatorType.AVID_LOCATOR_MAC_OS_ROMAN
              : exportOption.data.subData === EExportSubData.SCRIPT_SYNC
              ? exportOption.data.option.withTimeCode
                ? EAvidLocatorType.AVID_SCRIPT_SYNC_TC
                : EAvidLocatorType.AVID_SCRIPT_SYNC
              : exportOption.data.subData === EExportSubData.SUB_CAP
              ? EAvidLocatorType.STL
              : exportOption.data.subData === EExportSubData.AAF
              ? EAvidLocatorType.AAF
              : exportOption.data.subData === EExportSubData.SCRIPT_SYNC_NEW
              ? EAvidLocatorType.AVID_SCRIPT_SYNC_NEW
              : EAvidLocatorType.AVID_LOCATOR_UTF8,
          ...exportOption.data.option
        })
          .then(handleDownloadAndClose)
          .catch(handleExportError);
        break;

      case EExportName.SUBTITLES:
        if (exportOption.data.subData === EExportSubData.VISUAL_SUBTITLE_EDITOR) {
          handleExportVisualSubtitle();
          break;
        }
        if (exportOption.data.subData === EExportSubData.BURN_IN) {
          if (canBurnIn(exportOption.currentMediaId, currentProject.medias)) {
            dispatch(popModal());
            dispatch(
              pushModal({
                name: EModals.BURN_IN_MODAL,
                data: {
                  subData: EExportSubData.TIME_CODE_BITC,
                  mediaId: exportOption.currentMediaId || currentProject.medias[0].id
                }
              })
            );
          }
          break;
        }
        destroyMessage();
        openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
        exportFile({
          projectId: currentProject.id,
          currentMediaId: exportOption.currentMediaId,
          fileType:
            exportOption.data.subData === EExportSubData.SRT
              ? EFileType.SRT
              : exportOption.data.subData === EExportSubData.FACEBOOK_YOUTUBE
              ? EFileType.SRT
              : exportOption.data.subData === EExportSubData.WEB_VTT
              ? EFileType.VTT
              : exportOption.data.subData === EExportSubData.AVID_SUBCAP
              ? EFileType.STL
              : EFileType.SAMI,
          ...(exportOption.data.subData === EExportSubData.FACEBOOK_YOUTUBE
            ? { customField: 'fbYouTube' }
            : {}),
          ...exportOption.data.option
        })
          .then(handleDownloadAndClose)
          .catch(handleExportError);
        break;

      case EExportName.VISUAL_SUBTITLE_EDITOR:
        handleExportVisualSubtitle();
        break;

      case EExportName.BURN_IN:
        if (canBurnIn(exportOption.currentMediaId, currentProject.medias)) {
          dispatch(popModal());
          dispatch(
            pushModal({
              name: EModals.BURN_IN_MODAL,
              data: {
                subData: exportOption.data.subData,
                mediaId: exportOption.currentMediaId || currentProject.medias[0].id
              }
            })
          );
        }
        break;

      case EExportName.SHARE_PROJECT:
        dispatch(popModal());
        dispatch(
          pushModal({ name: EModals.SHARE_PROJECT_MODAL, data: { project: currentProject } })
        );
        break;

      case EExportName.OTHER:
        switch (exportOption.data.subData) {
          case EExportSubData.AVID_PRO_TOOLS_AFF:
            destroyMessage();
            openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
            exportFile({
              projectId: currentProject.id,
              currentMediaId: exportOption.currentMediaId,
              fileType: EFileType.AAF,
              ...exportOption.data.option
            })
              .then(handleDownloadAndClose)
              .catch(handleExportError);
            break;
          case EExportSubData.DAVINCI_RESOLVE:
            destroyMessage();
            openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
            exportFile({
              projectId: currentProject.id,
              currentMediaId: exportOption.currentMediaId,
              fileType: EFileType.EDL,
              ...exportOption.data.option
            })
              .then(handleDownloadAndClose)
              .catch(handleExportError);
            break;
          case EExportSubData.ADOBE_AUDITION:
            destroyMessage();
            openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
            exportFile({
              projectId: currentProject.id,
              currentMediaId: exportOption.currentMediaId,
              fileType: EFileType.XML,
              ...exportOption.data.option
            })
              .then(handleDownloadAndClose)
              .catch(handleExportError);
            break;
          case EExportSubData.EXCEL:
            destroyMessage();
            openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
            exportFile({
              projectId: currentProject.id,
              currentMediaId: exportOption.currentMediaId,
              fileType: EFileType.CSV,
              ...exportOption.data.option
            })
              .then(handleDownloadAndClose)
              .catch(handleExportError);
            break;
          case EExportSubData.JSON:
            destroyMessage();
            openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
            exportFile({
              projectId: currentProject.id,
              currentMediaId: exportOption.currentMediaId,
              fileType: EFileType.JSON,
              ...exportOption.data.option
            })
              .then(handleDownloadAndClose)
              .catch(handleExportError);
            break;
          case EExportSubData.XML:
            destroyMessage();
            openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
            exportFile({
              projectId: currentProject.id,
              currentMediaId: exportOption.currentMediaId,
              fileType: EFileType.XML,
              ...exportOption.data.option
            })
              .then(handleDownloadAndClose)
              .catch(handleExportError);
            break;
          case EExportSubData.FINAL_CUT_PRO7:
            destroyMessage();
            openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
            exportFile({
              projectId: currentProject.id,
              currentMediaId: exportOption.currentMediaId,
              fileType: EFileType.XML,
              ...exportOption.data.option
            })
              .then(handleDownloadAndClose)
              .catch(handleExportError);
            break;
          case EExportSubData.TEXT:
            destroyMessage();
            openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
            exportFile({
              projectId: currentProject.id,
              currentMediaId: exportOption.currentMediaId,
              fileType: EFileType.TXT,
              ...exportOption.data.option
            })
              .then(handleDownloadAndClose)
              .catch(handleExportError);
            break;
          case EExportSubData.EDIUS:
            destroyMessage();
            openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
            exportFile({
              projectId: currentProject.id,
              currentMediaId: exportOption.currentMediaId,
              fileType: EFileType.EDL,
              ...exportOption.data.option
            })
              .then(handleDownloadAndClose)
              .catch(handleExportError);
            break;
          case EExportSubData.LUMBER_JACK_READY:
            destroyMessage();
            openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
            exportFile({
              projectId: currentProject.id,
              currentMediaId: exportOption.currentMediaId,
              fileType: EFileType.LUMBER_JACK_TEXT,
              ...exportOption.data.option
            })
              .then(handleDownloadAndClose)
              .catch(handleExportError);
            break;
          case EExportSubData.MAXQDA:
            destroyMessage();
            openMessage('loading', t('Shared.Texts.ExportingW3Dot'));
            exportFile({
              projectId: currentProject.id,
              currentMediaId: exportOption.currentMediaId,
              fileType: EFileType.MAXQDA,
              ...exportOption.data.option
            })
              .then(handleDownloadAndClose)
              .catch(handleExportError);
            break;
          default:
            break;
        }
        break;
      default:
        console.info('case default');
        break;
    }
  }

  function handleClose() {
    dispatch(popModal());
    setExportOption({
      key: EExportName.WORD,
      data: {
        subData: null,
        option: {
          withTimeCode: true
        }
      },
      allowMultipleMedia: true,
      currentMediaId: null
    });
  }

  if (!currentProject || !data || isLoading) return null;

  return (
    <ModalBase modalName={EModals.EXPORT_MODAL} onCloseModal={handleClose}>
      <div className="export-options position-relative">
        <div className="header mb-9">{t('Layouts.ExportModal.Title')}</div>
        <div className="body">
          {currentProject.status !== EStatusProject.OPEN && (
            <div className="export-options-body">
              <WordExport exportOption={exportOption} setExportOption={setExportOption} />
              <TranslateExport exportOption={exportOption} setExportOption={setExportOption} />
              <PremiereExport exportOption={exportOption} setExportOption={setExportOption} />
              <FCPExport exportOption={exportOption} setExportOption={setExportOption} />
              <AvidMediaExport exportOption={exportOption} setExportOption={setExportOption} />
              <SubtitlesExport exportOption={exportOption} setExportOption={setExportOption} />
              <VisualSubtitlesExport
                exportOption={exportOption}
                setExportOption={setExportOption}
              />
              <BurnInExport exportOption={exportOption} setExportOption={setExportOption} />
              <ShareProjectExport exportOption={exportOption} setExportOption={setExportOption} />
              <OtherExport exportOption={exportOption} setExportOption={setExportOption} />
            </div>
          )}
        </div>
        <RenderOption setExportOption={setExportOption} exportOption={exportOption} />
        <div className="footer mt-2 d-flex align-items-center justify-content-center">
          <button
            className="submit-button d-flex align-items-center justify-content-center gap-1"
            onClick={() => {
              handleExport();
            }}
          >
            <FaDownload size={24} /> {t('Shared.Texts.LetsDoThis')}
          </button>
        </div>
        <div className="help-export position-absolute">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.simonsaysai.com/help/2650685-which-formats-can-i-export-my-transcript-to"
          >
            {t('Layouts.ExportModal.HelpText')}
          </a>
        </div>
      </div>
    </ModalBase>
  );
}
