import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import SpeechApiModal from 'components/Admin/SpeechApiModal';
import { useLoaderContext } from 'context/LoaderContext';
import React, { useEffect, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getListLanguagesAdminAction } from 'redux/language/language.action';
import { pushModal } from 'redux/modal/modal.slice';
import { EModals, ERoutes } from 'ts/enums';
import { LanguagesConvert } from 'ts/types/interface';
import { SpeechApiIdToCode } from 'utils/string';

const LanguagesPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const listLanguages = useAppSelector(
    (state) => state.languages.listAllLanguagesAdmin?.data || []
  );
  const isLoading = useAppSelector(
    (state) => state.languages.listAllLanguagesAdmin?.isLoading || false
  );
  const { loader } = useLoaderContext();

  const columns: ColumnsType<LanguagesConvert> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Language / Region',
      dataIndex: 'languageRegion',
      key: 'languageRegion'
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Diarization',
      dataIndex: 'diarizationSupported',
      key: 'diarizationSupported',
      render: (record) => {
        return <div>{record ? 'Yes' : '-'}</div>;
      }
    },
    {
      title: 'Text Direction',
      dataIndex: 'direction',
      key: 'direction',
      render: (record) => {
        return (
          <div>
            {record === 'ltr' && 'Left-to-right'}
            {record === 'rtl' && 'Right-to-left'}
          </div>
        );
      }
    },
    {
      title: 'Primary Speech Engine	',
      dataIndex: 'defaultSpeechApi',
      key: 'defaultSpeechApi',
      render: (record) => {
        return (
          <div style={{ textTransform: 'capitalize' }}>
            {record ? SpeechApiIdToCode(record) : '-'}
          </div>
        );
      }
    },
    {
      title: 'Backup Speech Engine',
      dataIndex: 'backupSpeechApi',
      key: 'backupSpeechApi',
      render: (record) => {
        return (
          <div style={{ textTransform: 'capitalize' }}>
            {record ? SpeechApiIdToCode(record) : '-'}
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    loader.start();
    dispatch(getListLanguagesAdminAction()).then(() => {
      loader.complete();
    });
  }, []);

  return (
    <div className="languages-page">
      <SpeechApiModal />
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome
            size={30}
            color="var(--si-primary)"
            onClick={() => navigate(ERoutes.LASTEST_TRANSACTION)}
            className="cursor-pointer"
          />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            Languages
          </span>
        </div>
      </div>
      <div className="container py-2">
        <div className="title-admin mb-2">{`Languages and regions (${listLanguages.length})`}</div>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={listLanguages}
          pagination={false}
          rowKey={'id'}
          scroll={{ x: 1110 }}
          rowClassName={'cursor-pointer'}
          className="custom-table"
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                dispatch(pushModal({ name: EModals.SPEECH_API_MODAL, data: record }));
              }
            };
          }}
        />
      </div>
    </div>
  );
};

export default LanguagesPage;
