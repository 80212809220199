import SearchHighlight from 'components/common/SearchHighLight';
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  DraftInlineStyleType,
  DraftModel,
  EditorState,
  RawDraftContentState,
  RawDraftEntity,
  genKey
} from 'draft-js';
import { EStatusMedia, EStyle } from 'ts/enums';
import { TMedia, TTranscript, TWord } from 'ts/types';
import { convertFormatTimeFromSecond } from './common';

export function createRawContent(transcript: Array<TTranscript>): RawDraftContentState | undefined {
  const blocks: Array<DraftModel.Encoding.RawDraftContentBlock> = [];
  const entityMap: { [key: string]: RawDraftEntity } = {};
  if (typeof transcript === 'undefined') return undefined;
  if (transcript && transcript.length) {
    const transcripts = transcript.map((transcriptRow) => ({
      ...transcriptRow,
      wordsJson: JSON.parse(transcriptRow.wordsJson)
    }));

    transcripts.forEach((transcriptRow, rowIndex) => {
      let accumulattedOffset = 0;
      let inlineStyleRanges: Array<DraftModel.Encoding.RawDraftInlineStyleRange> = [];
      transcriptRow.wordsJson.forEach((word: TWord, index: number) => {
        if (index) {
          const previousText = transcriptRow.wordsJson[index - 1].text;
          accumulattedOffset += previousText.length;
        }
        if (word.style) {
          word.style.forEach((s: EStyle) => {
            inlineStyleRanges.push({
              offset: accumulattedOffset,
              length: word.text.length,
              style: s as DraftInlineStyleType
            });
          });
        }
      });

      accumulattedOffset = 0;

      blocks.push({
        text: transcriptRow.wordsJson.map((word: TWord) => word.text).join(''),
        type: 'TranscriptRow',
        data: {
          ...transcriptRow,
          speaker: transcriptRow.speaker ? JSON.stringify(transcriptRow.speaker) : ''
        },
        entityRanges: transcriptRow.wordsJson.map((word: TWord, index: number) => {
          const entityKey = '_' + String(rowIndex) + '_' + String(index);

          entityMap[entityKey] = {
            type: 'TIME_CODED_WORD',
            data: {
              startTime: word.startTime,
              endTime: word.endTime,
              isMatch: false
            },
            mutability: 'MUTABLE'
          };

          if (index) {
            const previousText = transcriptRow.wordsJson[index - 1].text;
            accumulattedOffset += previousText.length;
          }

          return {
            offset: accumulattedOffset,
            length: word.text.length,
            key: entityKey
          };
        }),
        inlineStyleRanges: inlineStyleRanges,
        depth: 0,
        key: genKey()
      });
    });

    return { blocks, entityMap };
  }
}

export function normalizedStringRegex(str: string) {
  return str.replace(/([^a-z0-9\s])/gi, '\\$1');
}

export const findWithRegex = (
  regex: RegExp,
  contentBlock: DraftModel.ImmutableData.ContentBlock,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: any
) => {
  const text = contentBlock.getText();
  let matchArr, start, end;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    end = start + matchArr[0].length;
    callback(start, end);
  }
};

export const generateDecorator = ({
  highlightTerm,
  selectionState,
  hasCaseSensitive
}: {
  highlightTerm: string;
  selectionState: DraftModel.ImmutableData.SelectionState | null;
  hasCaseSensitive: boolean;
}) => {
  const regex = new RegExp(
    normalizedStringRegex(highlightTerm),
    `${hasCaseSensitive ? 'gm' : 'gmi'}`
  );
  return new CompositeDecorator([
    {
      strategy: (contentBlock, callback) => {
        if (highlightTerm !== '') {
          findWithRegex(regex, contentBlock, callback);
        }
      },
      component: SearchHighlight,
      props: {
        selectionState: selectionState
      }
    }
  ]);
};

export function getLastTranscriptRows(editorState: EditorState) {
  const contentState = editorState.getCurrentContent();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transcriptRows: any = [];
  let startTime: number;
  let endTime: number;
  let firstStartTime: number;
  let firstEndTime: number;

  contentState.getBlocksAsArray().forEach((block) => {
    var blockText = block.getText();
    var wordJsonList = [];
    //find the first entity in row as the "current entity"
    let currentEntityKey = null;
    for (let i = 0; i < blockText.length; i++) {
      if (block.getEntityAt(i)) {
        currentEntityKey = block.getEntityAt(i);
        break;
      }
    }
    if (!currentEntityKey) {
      //no entity in row
      let json = null;
      //the entire row does not have an entity, take a special approach here
      //if tis isn't the first row, use te previous one's start and end
      if (startTime && endTime) {
        json = { startTime: endTime, endTime: endTime, text: blockText };
      }
      //otherwise set to 0
      else {
        json = { startTime: '0', endTime: '0', text: blockText };
      }
      wordJsonList.push(json);
    } else {
      //we have one or more entities in row
      let lastEntityKey = currentEntityKey;
      let currentText = '';
      let currentStyle = null;
      let json = null;
      // iterate over each character, and assign to json words as appropriate
      for (let i = 0; i < blockText.length; i++) {
        const isLastChar = i === blockText.length - 1;
        const blockEntity = block.getEntityAt(i);
        if (blockEntity) {
          //if the current character is part of an entity
          currentEntityKey = block.getEntityAt(i);
          if (currentEntityKey != lastEntityKey || isLastChar) {
            // we're staring on a new entity. Save the last one
            const relevantEntityKey = isLastChar ? currentEntityKey : lastEntityKey;
            const entity = contentState.getEntity(relevantEntityKey + '');
            let currentMetadata = entity.getData();
            startTime = currentMetadata.startTime;
            endTime = currentMetadata.endTime;
            if (!firstStartTime && !firstEndTime) {
              firstStartTime = startTime;
              firstEndTime = endTime;
            }
            if (isLastChar) {
              //just in time addition of last char if last iteration
              currentText += blockText.charAt(i);
            }

            json = {
              startTime: startTime,
              endTime: endTime,
              text: currentText,
              style: []
            };
            if (currentStyle && currentStyle.length > 0) {
              json.style = currentStyle.filter((s: string) => ['DICTIONARY'].indexOf(s) == -1); // We do not need to save dictionary to server
            }
            currentText = '';
            currentStyle = null;
            wordJsonList.push(json);
          }
          //ony update currentstyle if null (not empty). We want the style of the first character of entity for now
          //TODO: change to split entity into multiple JSON words.
          currentStyle = currentStyle === null ? block.getInlineStyleAt(i).toJS() : currentStyle;
          currentText += blockText.charAt(i); // we don't want to add char twice if last iteration. But it doesn't matter in this case
          lastEntityKey = currentEntityKey;
        } else {
          // if the current character is NOT part of an entity
          //add characters to last word
          currentText += blockText.charAt(i);
          //if we are now at the end, finish the node with the data we have
          if (isLastChar) {
            //last known timestamp
            const entity = contentState.getEntity(currentEntityKey + '');
            if (entity) {
              let currentMetadata = entity.getData();
              startTime = currentMetadata.startTime;
              endTime = currentMetadata.endTime;
            }
            json = {
              startTime: startTime,
              endTime: endTime,
              text: currentText
            };
            wordJsonList.push(json);
          }
        }
      }
    }

    transcriptRows.push({
      id: block.getData().get('id'),
      wordsJson: JSON.stringify(wordJsonList),
      bookmark: block.getData().get('bookmark'),
      annotation: block.getData().get('annotation'),
      speaker: block.getData().get('speaker') ? JSON.parse(block.getData().get('speaker')) : null,
      autoSpeaker: block.getData().get('autoSpeaker')
    });
  });
  return transcriptRows;
}

export function getSelectedBlocksMap(editorState: EditorState) {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
}

export function getSelectedBlocksList(editorState: EditorState) {
  return getSelectedBlocksMap(editorState).toList();
}

export function getSelectedBlock(editorState: EditorState) {
  if (editorState) {
    return getSelectedBlocksList(editorState).get(0);
  }
  return undefined;
}

export function getEntityRange(editorState: EditorState, entityKey: string) {
  const block = getSelectedBlock(editorState);
  let entityRange: any;
  if (block)
    block.findEntityRanges(
      (value) => value.getEntity() === entityKey,
      (start, end) => {
        entityRange = {
          start,
          end,
          text: block.get('text').slice(start, end)
        };
      }
    );

  return entityRange;
}

export function checkIfRTL({ currentMedia }: { currentMedia: TMedia }) {
  var langugeRegionCodeListRTL = ['ar', 'he', 'fa', 'ur'];
  var langugeTranslatedRTL = [3, 25, 44, 62];
  if (currentMedia.status === EStatusMedia.TRANSLATED) {
    if (langugeTranslatedRTL.includes(currentMedia.translateTargetLanguageId)) return true;
  } else {
    var currentLanguageCode = localStorage.getItem('ss.ai.languageRegionCode');

    if (currentLanguageCode) {
      var captionCode = currentLanguageCode.split('-')[0];
      return langugeRegionCodeListRTL.includes(captionCode);
    }
  }
  return false;
}

export function getStartTimeOfBlock(
  block: DraftModel.ImmutableData.ContentBlock,
  contentState: DraftModel.ImmutableData.ContentState,
  format?: boolean
): string | number {
  let startEntityKey;
  let counterStartChar = 0;
  let startTime: number;
  do {
    startEntityKey = block.getEntityAt(counterStartChar++);
  } while (!startEntityKey && counterStartChar < block.getLength());

  if (startEntityKey) startTime = contentState.getEntity(startEntityKey).getData().startTime;
  else startTime = block.getData().get('startTime');

  if (format) {
    return convertFormatTimeFromSecond(+startTime);
  }
  return startTime;
}

export function getEndTimeOfBlock(
  block: DraftModel.ImmutableData.ContentBlock,
  contentState: DraftModel.ImmutableData.ContentState,
  format?: boolean
): string | number {
  let endEntityKey;
  let counterEndChar = block.getLength();
  let endTime: number;

  do {
    endEntityKey = block.getEntityAt(counterEndChar--);
  } while (!endEntityKey && counterEndChar > 0);

  if (endEntityKey) endTime = contentState.getEntity(endEntityKey).getData().endTime;
  else endTime = block.getData().get('endTime');

  if (isNaN(endTime)) {
    let startEntityKey;
    let startTime: number;
    let counterStartChar = 0;
    do {
      startEntityKey = block.getEntityAt(counterStartChar++);
    } while (!startEntityKey && counterStartChar < block.getLength());

    if (startEntityKey) startTime = contentState.getEntity(startEntityKey).getData().startTime;
    else startTime = block.getData().get('startTime');

    endTime = startTime;
  }

  if (format) {
    return convertFormatTimeFromSecond(+endTime);
  }
  return +endTime;
}

export function mergeMultipleEditorStates(editorStates: EditorState[]): EditorState {
  let mergedBlocks: ContentBlock[] = [];

  editorStates.forEach((editorState) => {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlocksAsArray();
    mergedBlocks = mergedBlocks.concat(blocks);
  });

  const mergedContentState = ContentState.createFromBlockArray(mergedBlocks);

  const mergedEditorState = EditorState.createWithContent(mergedContentState);

  return mergedEditorState;
}
