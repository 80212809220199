import { Input } from 'antd';
import { updateSpeaker } from 'apis/transcript.api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import { TSpeaker } from 'ts/types';

type Props = {
  speaker: TSpeaker;
  active: boolean;
  // eslint-disable-next-line no-unused-vars
  onChooseSpeaker: (speaker: TSpeaker) => void;
};

function SpeakerItem({ speaker, active, onChooseSpeaker }: Props) {
  const [searchParams] = useSearchParams();
  const mediaId = searchParams.get('mediaId');
  const [inputSpeakerName, setInputSpeakerName] = useState(speaker.name);
  const { t } = useTranslation();

  return (
    <div className={`mb-1 d-flex align-items-center ${active ? 'active' : ''}`}>
      <BsCircleFill
        onClick={() => onChooseSpeaker(speaker)}
        size={20}
        color={speaker.color || '#edeff0'}
        className="cursor-pointer"
      />
      <Input
        id={`${speaker.id}-speaker`}
        maxLength={25}
        onBlur={async () => {
          if (mediaId && speaker.name !== inputSpeakerName) {
            updateSpeaker({
              speakerId: speaker.id,
              name: inputSpeakerName,
              mediaId,
              sex: speaker.sex,
              color: speaker.color
            }).then((value) => onChooseSpeaker(value));
          }
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            document.getElementById(`${speaker.id}-speaker`)?.blur();
          }
        }}
        className="align-middle input-edit-speaker-name ms-1"
        value={inputSpeakerName}
        onChange={(e) => setInputSpeakerName(e.target.value)}
        variant="borderless"
        placeholder={t('Pages.TranscriptDetail.PS4.EnterSpeakerName')}
      />
    </div>
  );
}

export default SpeakerItem;
