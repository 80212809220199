import { deleteMedia } from 'apis/media.api';
import { HttpStatusCode } from 'axios';
import { useTranslation } from 'react-i18next';
import { ButtonWithIcons } from 'components/common';
import React, { useEffect, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { TMedia } from 'ts/types';
import PosterVideoImg from 'assets/img/poster-video.png';
import { Progress } from 'antd';
import { usePageLoading } from 'context/PageLoadingContext';
import PosterAudioImg from 'assets/img/poster-audio.png';
import { changeActiveOpenPaymentModal, updateCurrentProject } from 'redux/projects/projects.slice';
import { getProjectById } from 'apis/projects.api';

type Props = {
  media: TMedia;
};

function MediaUpload({ media }: Props) {
  const params = useParams();
  const { projectId } = params;
  const dispatch = useAppDispatch();
  const [visibleDesStatus, setVisibleDesStatus] = useState(false);
  const { openMessage, destroyMessage } = usePageLoading();
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const currentProject = useAppSelector((state) => state.projects.currentProject.data);

  useEffect(() => {
    if (visibleDesStatus) {
      setTimeout(() => {
        setVisibleDesStatus(false);
      }, 5000);
    }
  }, [visibleDesStatus]);

  async function handleDeleteMedia() {
    destroyMessage();
    openMessage('loading', t('Pages.UploadMedia.Texts.DeletingMediaW3Dot'));
    setIsDeleting(true);
    deleteMedia({ mediaId: media.id })
      .then(async (res) => {
        if (res.status === HttpStatusCode.Ok && projectId) {
          destroyMessage();
          if (currentProject) {
            const res = await getProjectById({ projectId });
            if (res) {
              dispatch(updateCurrentProject({ project: res }));
              openMessage('success', t('Pages.UploadMedia.Texts.DeletedMedia'));
            }
          }
          setIsDeleting(false);

          dispatch(changeActiveOpenPaymentModal(true));
        }
      })
      .catch((error) => {
        destroyMessage();
        openMessage('error', t('Pages.UploadMedia.Errors.UnableToDeleteMedia'));
        setIsDeleting(false);
      });
  }

  function getCharacterWidth(
    character: string,
    fontSize: number,
    fontFamily: string,
    fontWeight: number
  ) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
      context.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
      const metrics = context.measureText(character);
      return metrics.width;
    }

    document.body.removeChild(canvas);
    return 0;
  }

  function formatNameLength(name: string) {
    if (getCharacterWidth(name, 14, 'GothamBook', 700) < 260) {
      return name;
    } else {
      let maxText = 5;
      while (
        getCharacterWidth(
          name.slice(0, maxText).concat(' ... ').concat(name.slice(-maxText)),
          14,
          'GothamBook',
          700
        ) < 260
      ) {
        maxText++;
      }
      return name
        .slice(0, maxText - 1)
        .concat(' ... ')
        .concat(name.slice(1 - maxText));
    }
  }

  return (
    <div className={`item-media col-12 col-md-6 col-lg-4 ${isDeleting ? 'd-none' : ''}`}>
      <div className="position-relative">
        <img
          className="rounded-top d-none d-md-block"
          src={
            media.frameFilePath
              ? media.frameFilePath?.includes('poster-audio')
                ? PosterAudioImg
                : media.frameFilePath
              : PosterVideoImg
          }
          alt="frame-file-path"
          width={'100%'}
          height={'100%'}
          style={{ maxHeight: 234, aspectRatio: 16 / 9, objectFit: 'cover' }}
        />
        {media.status !== 'ready' && media.uploadInfor?.progress !== 100 && (
          <>
            <div
              className="position-absolute d-none d-md-block"
              style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            >
              <Progress
                strokeColor={'#7e56a2'}
                type="circle"
                percent={media.uploadInfor?.progress}
                trailColor="#edf2f4"
                showInfo={false}
                size={110}
              />
            </div>

            <div className="position-relative d-block d-md-none">
              {media.uploadInfor && (
                <Progress
                  strokeColor={'#7e56a2'}
                  type="line"
                  percent={media.uploadInfor?.progress}
                  trailColor="#edf2f4"
                  showInfo={false}
                  className="progess-custom"
                />
              )}
              <div
                className="position-absolute"
                style={{
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: 12,
                  fontWeight: 'bold',
                  color:
                    media.uploadInfor && media.uploadInfor?.progress >= 45
                      ? 'var(--si-white)'
                      : 'black'
                }}
              >
                <div>
                  {t(
                    `Pages.UploadMedia.MediaStatusFromEnum.${media.status !== 'empty' ? media.status : 'preparing'
                    }`
                  )}
                  !
                </div>
              </div>
            </div>
          </>
        )}

        <ButtonWithIcons
          className="d-none d-md-block position-absolute end-0 top-0 mt-1 me-1"
          icon={<AiFillCloseCircle color="rgba(255,255,255,0.5)" size={32} />}
          onClick={handleDeleteMedia}
        />
      </div>
      <div
        className="rounded-md-bottom"
        style={{
          backgroundColor: 'var(--si-white)',
          transition: 'all 0.2s linear',
          padding: '15px 10px 30px'
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="media-name" title={media.name}>
            {formatNameLength(media.name)}
          </div>
          <div style={{ textAlign: 'right', maxWidth: 103 }}>
            <div
              className="cursor-pointer d-none d-md-block"
              onClick={async () => {
                setVisibleDesStatus((prev) => !prev);
              }}
              style={{
                fontSize: 12,
                color: 'var(--si-dodger-blue)',
                userSelect: 'none',
                fontWeight: 900
              }}
            >
              {t(
                `Pages.UploadMedia.MediaStatusFromEnum.${media.status !== 'empty' ? media.status : 'preparing'
                }`
              )}
            </div>
            <ButtonWithIcons
              className="d-block d-md-none"
              icon={<AiFillCloseCircle color="var(--si-primary)" size={24} />}
              onClick={handleDeleteMedia}
            />
            {media.uploadInfor?.loaded && (
              <div>
                <small
                  className="d-none d-md-block"
                  style={{ color: 'var(--si-primary)', whiteSpace: 'nowrap' }}
                >
                  {media.uploadInfor?.loaded.toFixed(1)} / {media.uploadInfor?.total?.toFixed(1)}mb
                </small>
              </div>
            )}

            <div
              className={`${visibleDesStatus ? 'd-block' : 'd-none'}`}
              style={{
                fontSize: 10.2,
                maxWidth: 85,
                fontWeight: 900,
                color: 'var(--si-primary)'
              }}
            >
              {t('Pages.UploadMedia.Texts.FileReadyToTranscribe')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaUpload;
