import SimonTyping from 'assets/img/simon_type_02_large.png';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { ERoutes, EStatusProject } from 'ts/enums';

export function Transcribing() {
  const { t } = useTranslation();
  const currentProject = useAppSelector((state) => state.projects.currentProject);
  if (!currentProject.data) return null;

  return (
    <div className="transcribing p-2 rounded">
      <div className="text-center fw-bold">
        {(currentProject.data.status === EStatusProject.TRANSLATING || currentProject.data.status === EStatusProject.TRANSLATE_PENDING)
          ? 'Translation is in progress and your translated transcript will be ready soon!'
          : 'Transcription in progress and your transcript will be ready soon!'}
      </div>
      <div className="mt-2 text-center">
        <Trans t={t} i18nKey="Pages.TranscriptDetail.Texts.MeantimeTranscribeAnother">
          This should be the <Link to={ERoutes.MAIN}>link</Link>
        </Trans>
      </div>
      <img src={SimonTyping} alt="" width="100%" />
    </div>
  );
}
