import { Button, DatePicker, Form, Select, Table, notification } from 'antd';
import { getListProjectTransactions, getListUsers } from 'apis/admin/lastestTransaction.api';
import { useLoaderContext } from 'context/LoaderContext';
import { usePageLoading } from 'context/PageLoadingContext';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ERoutes, EStatusProject } from 'ts/enums';
import { TProjectTransaction, TUser } from 'ts/types';
import { changeInlineStyle } from 'utils/string';
import { convertDayTo } from 'utils/time';

type DataSourceType = {
  date: string;
  username: string;
  userEmail: string;
  name: string;
  creditCost: string;
  jobStatus: string;
  totalLengthOfProject: string;
  languageDisplayName: string;
  applicationType: string;
  clientInfo: string;
};

const columnsTable = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (record: string) => {
      return <div className="text-nowrap">{record}</div>;
    }
  },
  {
    title: 'User Name',
    dataIndex: 'username',
    key: 'username'
  },
  {
    title: 'User Email Address',
    dataIndex: 'userEmail',
    key: 'userEmail'
  },
  {
    title: 'Project Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Credits/Cost',
    dataIndex: 'creditCost', //credit - price
    key: 'creditCost'
  },
  {
    title: 'Job Status',
    dataIndex: 'jobStatus', //status - payType
    key: 'jobStatus'
  },
  {
    title: 'Total Length',
    dataIndex: 'totalLengthOfProject',
    key: 'totalLengthOfProject'
  },
  {
    title: 'Project Language',
    dataIndex: 'languageDisplayName',
    key: 'languageDisplayName',
    render: (record: string) => {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: changeInlineStyle(record)
          }}
        />
      );
    }
  },
  {
    title: 'Application Type',
    dataIndex: 'applicationType',
    key: 'applicationType'
  },
  {
    title: 'Client Info',
    dataIndex: 'clientInfo',
    key: 'clientInfo'
  }
];

type filter = {
  fromDateSearch: string | null;
  toDateSearch: string | null;
  userIdSearch: string | null;
};
function LastestTransactionPage() {
  const [listUsers, setListUsers] = useState<TUser[]>([]);
  const [listProjectTransactions, setListProjectTransactions] = useState<TProjectTransaction[]>([]);
  const [dataSource, setDataSource] = useState<DataSourceType[]>([]);
  const [totalList, setTotalList] = useState<number>(0);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [filter, setFilter] = useState<filter>({
    fromDateSearch: null,
    toDateSearch: null,
    userIdSearch: null
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [formFilters] = Form.useForm();
  const navigate = useNavigate();
  const { openMessage, destroyMessage } = usePageLoading();
  const { loader } = useLoaderContext();

  useEffect(() => {
    loader.start();
    handleGetListUsers();
    handleGetListProjectTransactions({});
    loader.complete();
  }, []);

  useEffect(() => {
    setDataSource(
      listProjectTransactions.map((transactions) => {
        let status = 'Deleted';
        let payType = '';
        if (transactions.status === EStatusProject.READY) {
          status = 'Transcribed';
        }
        if (transactions.status === EStatusProject.TRANSLATED) {
          status = 'Translated';
        }
        if (transactions.status === EStatusProject.FAILED) {
          status = 'Failed';
        }
        if (transactions.payType) {
          if (transactions.payType === 'BURNIN') {
            payType = 'burn-in';
          } else {
            payType = transactions.payType;
          }
        }
        return {
          ...transactions,
          date: moment(transactions.creationDate).format('MM/DD/YYYY h:MM A'),
          creditCost: `${transactions.credit} mins / ${transactions.price}`,
          jobStatus: `${status} ${payType}`
        };
      })
    );
  }, [listProjectTransactions]);

  async function handleGetListProjectTransactions({
    pageNumber,
    toDateSearch,
    userIdSearch,
    fromDateSearch
  }: {
    pageNumber?: number;
    fromDateSearch?: string | null;
    toDateSearch?: string | null;
    userIdSearch?: string | null;
  }) {
    try {
      loader.start();
      setIsLoadingTable(true);
      const res = await getListProjectTransactions({
        pageNumber,
        toDateSearch,
        userIdSearch,
        fromDateSearch
      });
      if (res.projects.length > 0) {
        setListProjectTransactions(res.projects);
        setTotalList(res.countOfCall);
      } else {
        setListProjectTransactions([]);
        setTotalList(0);
      }
      setIsLoadingTable(false);
      loader.complete();
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleGetListUsers() {
    try {
      destroyMessage();
      openMessage('loading', 'Get list users....');
      const res = await getListUsers();
      if (res && res?.length > 0) {
        setListUsers(res);
        destroyMessage();
      }
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleOnPageSizeChange(page: number, pageSize: number) {
    setCurrentPage(page);
    await handleGetListProjectTransactions({
      pageNumber: page - 1,
      fromDateSearch: filter.fromDateSearch,
      toDateSearch: filter.toDateSearch,
      userIdSearch: filter.userIdSearch
    });
    document.getElementsByTagName('main')[0].scrollTo({ top: 0, behavior: 'smooth' });
  }

  async function handleFilter(values: any) {
    const fromDateSearch = values.startDate
      ? moment(convertDayTo({ date: values.startDate, day: 1, type: 'subtract' })).format(
          'YYYY-M-DD'
        )
      : null;
    const toDateSearch = values.endDate?.format('YYYY-M-DD') || null;
    const userIdSearch = values.userSelected || null;
    setFilter({ fromDateSearch, toDateSearch, userIdSearch });
    setCurrentPage(1);
    handleGetListProjectTransactions({
      fromDateSearch: fromDateSearch,
      toDateSearch: toDateSearch,
      userIdSearch: userIdSearch
    });
  }

  function handleOnClickRow(record: any) {
    if (!record.id || record.jobStatus.toLowerCase().includes('deleted')) return;
    window.open(`${ERoutes.DETAIL_TRANSACTION}/${record.id}`, '_blank');
  }

  return (
    <div className="admin-lastest-transaction">
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome
            size={30}
            color="var(--si-primary)"
            onClick={() => navigate(ERoutes.LASTEST_TRANSACTION)}
            className="cursor-pointer"
          />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            Latest Transaction
          </span>
        </div>
      </div>
      <div className="container py-2">
        <Form
          form={formFilters}
          layout="vertical"
          className="border p-2 rounded"
          onFinish={handleFilter}
        >
          <h5 className="mb-3">Filters</h5>
          <div className="row">
            <Form.Item className="col-12 col-md-6" name={'startDate'} label="Start date">
              <DatePicker className="w-100" placeholder="MM/DD/YYYY" format={'MM/DD/YYYY'} />
            </Form.Item>
            <Form.Item className="col-12 col-md-6" name={'endDate'} label="End date">
              <DatePicker className="w-100" placeholder="MM/DD/YYYY" format={'MM/DD/YYYY'} />
            </Form.Item>
          </div>

          <div className="row align-items-end">
            <Form.Item name={'userSelected'} label="User" className="col-12 col-md-6">
              <Select
                className="w-100 user-select"
                showSearch
                suffixIcon={null}
                allowClear
                placeholder="Select User"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                notFoundContent="No matches found"
                options={listUsers.map((user) => {
                  return {
                    label: user.fullName + ' (' + user.email + ')',
                    value: user.id
                  };
                })}
              />
            </Form.Item>
            <Form.Item className="col-12 col-md-6">
              <Button onClick={() => formFilters.submit()} type="primary">
                Filter
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div style={{ overflow: 'auto' }}>
          <Table
            loading={isLoadingTable}
            className="mt-2"
            dataSource={dataSource}
            columns={columnsTable}
            rowClassName={(record) => {
              if (record.jobStatus.toLowerCase().includes('deleted'))
                return 'cursor-pointer hightlight';
              if (record.jobStatus.toLowerCase().includes('failed'))
                return 'cursor-pointer failed-transaction-row ';
              return 'cursor-pointer';
            }}
            onRow={(record) => {
              return {
                onClick: () => handleOnClickRow(record)
              };
            }}
            rowKey={(val) => val.date.toString() + Math.random()}
            pagination={{
              current: currentPage,
              pageSize: 50,
              total: totalList,
              showSizeChanger: false,
              position: ['bottomCenter'],
              onChange: (page: number, pageSize: number) => handleOnPageSizeChange(page, pageSize)
            }}
            scroll={{ x: 1295 }}
          />
        </div>
      </div>
    </div>
  );
}

export default LastestTransactionPage;
