import React from 'react';
import { ListFolders } from './ListFolders';
import { ListProjects } from './ListProjects';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAppSelector } from 'redux/hooks';
import { CreateDictionary } from './Dictionary';
import { ListDictionary } from './Dictionary/ListDictionary';
import { useUpdateWidth } from 'hooks/useUpdateWidth';
import { EFolderIdDefault } from 'ts/enums';

export function MainPageContainer() {
  const isDictionaryScreen = useAppSelector((state) => state.dictionary.isDictionaryScreen);
  const currentFolderSelected = useAppSelector((state) => state.folder.currentFolderSelected);
  const { width } = useUpdateWidth();
  const isRender =
    currentFolderSelected === EFolderIdDefault.PROJECT_ALL ||
    currentFolderSelected === EFolderIdDefault.MY_PROJECT ||
    currentFolderSelected === EFolderIdDefault.SHARED_WITH_ME;

  if (isDictionaryScreen)
    return (
      <div className="main-page-container mt-2">
        <div className="container-fluid px-3">
          <div className="ctn-list-dictionary">
            <div className="create-dictionary-column" style={{ transition: 'all 0.3s ease' }}>
              <CreateDictionary />
            </div>
            <div className="list-dictionary-column" style={{ transition: 'all 0.3s ease' }}>
              <ListDictionary />
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="main-page-container mt-1 drag-and-drop">
        <div className="container-fluid mb-2 px-1 px-md-3">
          <div className="ctn-list-folder-project">
            <div className="list-folder-column" style={{ transition: 'all 0.3s ease' }}>
              <ListFolders />
            </div>
            {width < 992 && isRender && (
              <div className="list-project-column" style={{ transition: 'all 0.3s ease' }}>
                <ListProjects />
              </div>
            )}
            {width > 991 && (
              <div className="list-project-column" style={{ transition: 'all 0.3s ease' }}>
                <ListProjects />
              </div>
            )}
          </div>
        </div>
      </div>
    </DndProvider>
  );
}
