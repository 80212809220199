import React, { createContext, useContext } from 'react';
import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

if (process.env.REACT_APP_ENV !== 'DEV') {
  ReactGA.initialize('G-02857JRLV2');
}

interface CustomAnalyticsType {
  trackEvent: (eventData: UaEventOptions) => void;
}

const CustomAnalyticsContext = createContext<CustomAnalyticsType>(null!);

export function CustomAnalyticsProvider({ children }: { children: React.ReactNode }) {
  function handleTrackEvent(eventData: UaEventOptions) {
    console.group('TrackEvent');
    ReactGA.event(eventData);
    console.groupEnd();
  }

  const value = {
    trackEvent: (eventData: UaEventOptions) => {
      handleTrackEvent(eventData);
    }
  };
  return (
    <CustomAnalyticsContext.Provider value={value}>{children}</CustomAnalyticsContext.Provider>
  );
}

export function useGA() {
  return useContext(CustomAnalyticsContext);
}
