import { TResponse } from 'ts/types/TResponse';
import { AxiosCustom } from '../axios-client';
import { TLanguage, TSpeechApi } from 'ts/types';
import { TRequestUpdateService } from 'ts/types/TRequest';

export async function getListLanguagesByAdmin(): Promise<TResponse<TLanguage[]>> {
  const res = await AxiosCustom.get(`admin/languages`);
  return res.data;
}

export async function checkServiceSupport({
  langCode
}: {
  langCode: string;
}): Promise<TSpeechApi[]> {
  const res = await AxiosCustom.get(`admin/languages/check-service-support?langCode=${langCode}`);
  return res.data;
}

export async function updateSpeechService({
  langCode,
  newBackupSpeechServiceCode,
  newSpeechServiceCode,
  regionId
}: TRequestUpdateService): Promise<TSpeechApi[]> {
  const res = await AxiosCustom.patch(`admin/languages/update-speech-service`, {
    langCode,
    newBackupSpeechServiceCode,
    newSpeechServiceCode,
    regionId
  });
  return res.data;
}
