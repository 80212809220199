import React from 'react';
import { Anchor } from 'antd';
import { useTranslation } from 'react-i18next';
import { BiArrowBack } from 'react-icons/bi';
import { IoSettings } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { EAccountSettingId, ERoutes } from 'ts/enums';

export function AccountSettingNav() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const AccountSettingList = [
    {
      key: EAccountSettingId.PROFILE,
      title: t('Pages.AccountSetting.Profile.Title'),
      href: `#${EAccountSettingId.PROFILE}`
    },
    {
      key: EAccountSettingId.LOGIN_PREFERENCES,
      title: t('Pages.AccountSetting.LoginPreferences.Title'),
      href: `#${EAccountSettingId.LOGIN_PREFERENCES}`
    },
    {
      key: EAccountSettingId.INVITE_LINK_FREE_CREDIT,
      title: t('Pages.AccountSetting.Invitation.Title'),
      href: `#${EAccountSettingId.INVITE_LINK_FREE_CREDIT}`
    },
    {
      key: EAccountSettingId.INVOICES,
      title: t('Pages.AccountSetting.Invoices.Title'),
      href: `#${EAccountSettingId.INVOICES}`
    },
    {
      key: EAccountSettingId.TRANSACTION_HISTORY,
      title: t('Pages.AccountSetting.TransactionHistory.Title'),
      href: `#${EAccountSettingId.TRANSACTION_HISTORY}`
    },
    {
      key: EAccountSettingId.CONNECTED_APPS,
      title: t('Pages.AccountSetting.Connections.Title'),
      href: `#${EAccountSettingId.CONNECTED_APPS}`
    },
    {
      key: EAccountSettingId.CLOSE_ACCOUNT,
      title: t('Pages.AccountSetting.CloseAccount.Title'),
      href: `#${EAccountSettingId.CLOSE_ACCOUNT}`
    }
  ];

  function handleBack(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    navigate(ERoutes.MAIN);
  }

  return (
    <section className="account-setting-nav d-none d-md-block">
      <div className="account-setting-title d-flex flex-column align-items-start">
        <button className="back-button mb-2" onClick={handleBack}>
          <BiArrowBack />
          <span className="ms-1">{t('Pages.AccountSetting.Texts.BackToYourProjects')}</span>
        </button>
        <div className="d-flex align-items-center gap-1 mt-1 mb-1">
          <IoSettings size={18} />{' '}
          <span style={{ fontSize: 18, fontWeight: 500, textTransform: 'uppercase' }}>
            {' '}
            {t('Pages.AccountSetting.Title')}
          </span>
        </div>
      </div>

      <div className="account-setting-list">
        <Anchor
          offsetTop={54}
          getContainer={() => document.getElementById('main-element') || window}
          items={AccountSettingList}
        />
      </div>
    </section>
  );
}
