import { DatePicker, Form, Input, Popconfirm, Table, Tooltip } from 'antd';
import {
  addMemberWithId,
  deleteMemberWithId,
  editTeamWithId,
  getMemberWithId,
  getOwnerWithId,
  getTeamWithId,
  getTransactionWithId
} from 'apis/admin/teams.api';
import { usePageLoading } from 'context/PageLoadingContext';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { IoInformationCircleSharp } from 'react-icons/io5';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EDelayTime, EModals, ERoutes, ETypeNoti } from 'ts/enums';
import { useAppSelector } from 'redux/hooks';
import { TOwner, TTeam } from 'ts/types';
import { emailRegex } from './TeamPage';
import { useAppDispatch } from 'redux/hooks';
import { pushModal } from 'redux/modal/modal.slice';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type UserType = {
  id: string;
  name: string;
  email: string;
};

type DataSourceMemberInTeamType = {
  userName: UserType;
  id: string;
};

type DataSourceTransactionType = {};

export default function TeamDetailPage() {
  const { teamId } = useParams();
  const [formTeam] = Form.useForm();
  const [formAddNewMember] = Form.useForm();
  const [team, setTeam] = useState<TTeam>();
  const { openMessage } = usePageLoading();
  const [owner, setOwner] = useState<TOwner>();
  const user = useAppSelector((state) => state.auth.currentUser.data);
  const isOwner = owner?.id === user?.id;
  const [isAddingMember, setIsAddingMember] = useState<boolean>(false);

  const [dataSourceMember, setDataSourceMember] = useState<DataSourceMemberInTeamType[]>([]);
  const [dataMember, setDataMember] = useState<TOwner[]>([]);
  const [isLoadingTeamMember, setIsLoadingTeamMember] = useState<boolean>(false);
  const [isChangeFormTeam, setIsChangeFormTeam] = useState<boolean>(false);

  const [dataSourceTransaction, setDataSourceTransaction] = useState<DataSourceTransactionType[]>(
    []
  );
  const [isLoadingTransaction, setIsLoadingTransaction] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchTeamDetail, setSearchTeamDetail] = useState<string>('');
  const { t } = useTranslation();

  const columnsTableTeamMember = [
    {
      title: t('Shared.Fields.Name'),
      dataIndex: 'userName',
      key: 'userName',
      width: '50%',
      render: (record: UserType) => {
        return (
          <Link
            to={`${ERoutes.USER_LOOKUP}/${record.id}`}
          >{`${record.email} / ${record.name}`}</Link>
        );
      }
    },
    {
      title: (
        <input
          type="text"
          className="w-100 none-focus"
          placeholder={t('Shared.Actions.Search')}
          onChange={(e) => {
            setSearchTeamDetail(e.target.value);
            handleSearch(e.target.value);
          }}
        />
      ),
      dataIndex: 'userName',
      key: 'userName',
      width: '50%',
      render: (record: UserType) => {
        return (
          <Popconfirm
            title={t('Pages.Admin.TeamDetail.UserRemovalConfirm', { name: record.email })}
            onConfirm={() => {
              deleteMember(record.id);
            }}
            className="light"
          >
            <div style={{ width: 'max-content' }}>
              <RiDeleteBin6Line className="cursor-pointer delete-icon" size={16} />
            </div>
          </Popconfirm>
        );
      }
    }
  ];

  const columnsTableTransaction = [
    {
      title: t('Shared.Fields.NameOrEmail'),
      width: '30%',
      key: 'id',
      render: (record: any) => {
        return (
          <Link to={`${ERoutes.USER_LOOKUP}/${record.actor?.id}`}
          >{`${record.actor?.email} / ${record.actor?.fullName}`}</Link>
        );
      }
    },
    {
      title: t('Shared.Fields.ProjectName'),
      width: '30%',
      key: 'name',
      render: (record: any) => {
        return (
          <Link to={`${ERoutes.PROJECT_DETAIL_VIEW}/${record.id}`}>{ record.name }</Link>
        );
      }
    },
    {
      title: t('Shared.Fields.Date'),
      width: '20%',
      key: 'creationDate',
      render: (record: any) => {
        return (
          <>{ moment(new Date(record.creationDate)).format('MM/DD/YYYY h:MM A') }</>
        );
      }
    },
    { 
      title: t('Shared.Fields.TotalLength'), 
      width: '10%',
      key: 'credit',
      render: (record: any) => {
        return (
          <>{ record.totalLengthOfProject }</>
        );
      }
    },
    { 
      title: t('Shared.Fields.CreditAndCost'), 
      width: '10%',
      key: 'credit',
      render: (record: any) => {
        return (
          <>{ record.credit }m / ${ record.price }</>
        );
      }
    }
  ];

  useEffect(() => {
    if (teamId) {
      setIsLoadingTeamMember(true);
      setIsLoadingTransaction(true);
      (async () => {
        try {
          const resTeam = await getTeamWithId({ id: teamId });

          if (resTeam) {
            setTeam(resTeam);
          }
        } catch (error) {
          openMessage('error', 'Load info team fail!');
        }
      })();

      (async () => {
        try {
          const resOwner = await getOwnerWithId({ id: teamId });

          if (resOwner) {
            setOwner(resOwner);
          }
        } catch (error) {
          openMessage('error', 'Load owner fail!');
        }
      })();

      (async () => {
        try {
          const resMember = await getMemberWithId({ id: teamId });
          if (resMember) {
            setDataMember(resMember);
            setDataSourceMember(
              resMember.map((member) => {
                return {
                  userName: {
                    id: member.id,
                    name: member.fullName,
                    email: member.email
                  },
                  id: member.id
                };
              })
            );
          }
        } catch (error) {
          openMessage('error', 'Load team member fail!');
        }

        setIsLoadingTeamMember(false);
      })();

      (async () => {
        try {
          const resTransaction = await getTransactionWithId({ id: teamId });
          if (resTransaction) {
            setDataSourceTransaction(resTransaction);
          }
        } catch (error) {
          openMessage('error', 'Load transaction fail!');
        }

        setIsLoadingTransaction(false);
      })();
    }
  }, [teamId]);

  useEffect(() => {
    if (team) {
      formTeam.setFieldValue('teamId', team.id);
      formTeam.setFieldValue('teamOwner', team.owner);
      formTeam.setFieldValue('credit', team.credit);
      formTeam.setFieldValue('minutes', team.subscriptionCredit);
      formTeam.setFieldValue(
        'expiryDate',
        dayjs(team.subscriptionCreditExpireDate || '01/01/1970')
      );
    }
  }, [team]);

  useEffect(() => {
    if (owner) {
      formTeam.setFieldValue('plan', owner.plan.fullLabel);
      formTeam.setFieldValue('stripeSubscription', owner.subscriptionStatus || 'None');
    }
  }, [owner]);

  function handleSearch(e: string) {
    if (!e || e.length === 0) {
      setDataSourceMember(
        dataMember.map((member) => {
          return {
            userName: {
              id: member.id,
              name: member.fullName,
              email: member.email
            },
            id: member.id
          };
        })
      );
      return;
    }
    const newTeams = dataMember.filter((member) => {
      return member.email.indexOf(e) > -1 || member.fullName.indexOf(e) > -1;
    });

    setDataSourceMember(
      newTeams.map((member) => {
        return {
          userName: {
            id: member.id,
            name: member.fullName,
            email: member.email
          },
          id: member.id
        };
      })
    );
  }

  async function deleteMember(memberId: string) {
    if (!teamId) return;
    await deleteMemberWithId({ id: teamId, memberId });
    const newDataMember = dataMember.filter((member) => member.id !== memberId);
    setDataMember(newDataMember);
    setDataSourceMember(
      newDataMember.map((member) => {
        return {
          userName: {
            id: member.id,
            name: member.fullName,
            email: member.email
          },
          id: member.id
        };
      })
    );
    openMessage('success', t('Shared.Texts.Deleted'));
  }

  async function addMember() {
    setIsAddingMember(true);
    if (isAddingMember || !team || !teamId) return;

    const email = formAddNewMember.getFieldValue('email');

    if (!email || !emailRegex.test(email)) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: {
            type: ETypeNoti.ERROR,
            title: t('Shared.Errors.InvalidEmail')
          }
        })
      );
      setIsAddingMember(false);

      return;
    }

    formAddNewMember.resetFields(['email']);
    try {
      const res = await addMemberWithId({ id: teamId, email });
      if (res) {
        setIsLoadingTeamMember(true);
        const resMember = await getMemberWithId({ id: teamId });
        if (resMember) {
          setDataMember(resMember);
          setDataSourceMember(
            resMember.map((member) => {
              return {
                userName: {
                  id: member.id,
                  name: member.fullName,
                  email: member.email
                },
                id: member.id
              };
            })
          );
          openMessage('success', t('Shared.Texts.Added'));
        }
      }
    } catch (error: any) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: { type: ETypeNoti.ERROR, title: error.response.data.message }
        })
      );
    }

    setIsAddingMember(false);
    setIsLoadingTeamMember(false);
  }

  function handleCheckChangeForm() {
    if (!team) return;

    if (
      +formTeam.getFieldValue('minutes') !== team.subscriptionCredit ||
      +formTeam.getFieldValue('credit') !== team.credit ||
      +formTeam.getFieldValue('expiryDate') !== team.subscriptionCreditExpireDate
    ) {
      setIsChangeFormTeam(true);
    } else {
      setIsChangeFormTeam(false);
    }
  }

  function onCancelEditFormTeam() {
    if (!team) return;
    formTeam.setFieldValue('credit', team.credit);
    formTeam.setFieldValue('minutes', team.subscriptionCredit);
    setIsChangeFormTeam(false);
  }

  async function onSaveFormTeam() {
    if (!teamId || !team) return;
    if (!isChangeFormTeam) return;

    const newTeam = {
      ...team,
      credit: +formTeam.getFieldValue('credit'),
      subscriptionCredit: +formTeam.getFieldValue('minutes'),
      subscriptionCreditExpireDate: +formTeam.getFieldValue('expiryDate')
    };

    const res = await editTeamWithId({ id: teamId, team: newTeam });

    if (res) {
      setTeam(res);
    }

    setIsChangeFormTeam(false);
  }

  return (
    <div>
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome
            size={30}
            color="var(--si-primary)"
            onClick={() => navigate(ERoutes.LASTEST_TRANSACTION)}
            className="cursor-pointer"
          />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            {t('Pages.Admin.TeamDetail.Title')}
          </span>
        </div>
      </div>
      <div className="container py-2">
        <Form form={formTeam} layout="vertical" className="border p-2" onFinish={onSaveFormTeam}>
          <div className="row">
            <Form.Item className="col-12 col-md-6" name={'teamId'} label={t('Shared.Fields.ID')}>
              <Input readOnly className="form-control --readOnly" />
            </Form.Item>
            <Form.Item
              className="col-12 col-md-6"
              name={'teamOwner'}
              label={t('Shared.Fields.TeamOwner')}
            >
              <Link
                to={`${ERoutes.USER_LOOKUP}/${owner?.id}`}
                className="custom-a-tag form-control --readOnly"
              >
                {formTeam.getFieldValue('teamOwner')}
              </Link>
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item className="col-12 col-md-6" name={'plan'} label={t('Shared.Fields.Plan')}>
              <Input readOnly className="form-control --readOnly" />
            </Form.Item>
            <Form.Item
              className="col-12 col-md-6"
              name={'stripeSubscription'}
              label={t('Shared.Fields.StripeSubscription')}
            >
              <Input readOnly className="form-control --readOnly" />
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item
              className="col-12 col-md-6"
              name={'credit'}
              label={t('Shared.Fields.TeamCreditMins')}
            >
              <Input
                type="number"
                className="none-focus form-control"
                onChange={handleCheckChangeForm}
              />
            </Form.Item>
          </div>
          <div className="fs-4">{t('Pages.Admin.TeamDetail.TeamSubscriptionCredit')}</div>
          <div className="row mt-2">
            <Form.Item
              className="col-12 col-md-6"
              name={'minutes'}
              label={
                <span>
                  <span className="align-middle">{t('Shared.Fields.Minutes')}</span>
                  {+formTeam.getFieldValue('minutes') !== 0 && (
                    <Tooltip
                      placement="rightBottom"
                      arrow={false}
                      mouseEnterDelay={EDelayTime.FAST}
                      title="Due to Expiry Date. The credit is not valid anymore."
                    >
                      <IoInformationCircleSharp size={16} color="var(--si-red)" />
                    </Tooltip>
                  )}
                </span>
              }
            >
              <Input
                type="number"
                className="none-focus form-control"
                onChange={handleCheckChangeForm}
              />
            </Form.Item>
            <Form.Item
              className="col-12 col-md-6"
              name={'expiryDate'}
              label={
                <span>
                  <span className="align-middle">{t('Shared.Fields.ExpiryDate')}</span>
                  {team && !team.subscriptionCreditExpireDate && (
                    <IoInformationCircleSharp size={16} color="var(--si-info)" />
                  )}
                </span>
              }
            >
              <DatePicker
                onChange={handleCheckChangeForm}
                disabled={!isOwner}
                format="MM/DD/YYYY"
                className={`w-100 form-control ${!isOwner ? '--readOnly' : ''} `}
              />
            </Form.Item>
          </div>
          {isChangeFormTeam && (
            <div className="d-flex gap-1">
              <button className="btn btn-success" onClick={() => formTeam.submit()}>
                {t('Shared.Actions.Save')}
              </button>
              <button className="btn btn-danger" onClick={onCancelEditFormTeam}>
                {t('Shared.Actions.Cancel')}
              </button>
            </div>
          )}
        </Form>

        <Form
          form={formAddNewMember}
          layout="vertical"
          className="border p-2 mt-4"
          onFinish={addMember}
        >
          <div className="fs-4">{t('Pages.Admin.TeamDetail.AddNewNumber')}</div>
          <div className="row">
            <Form.Item
              className="col-12 col-md-6 mt-2 align-self-end"
              name={'email'}
              label={t('Shared.Fields.Email')}
            >
              <Input
                className="none-focus form-control"
                placeholder={t('Pages.Admin.TeamDetail.EnterMemberEmail')}
              />
            </Form.Item>
            <Form.Item className="col-12 col-md-6 mt-2" label={<div />}>
              <button
                className="submit-button"
                style={{ minWidth: 90, height: 34 }}
                onClick={() => formAddNewMember.submit()}
              >
                {t('Shared.Actions.Add')}
              </button>
            </Form.Item>
          </div>
        </Form>
        <Form layout="vertical" className="border p-2 mt-4">
          <div className="fs-4">{t('Shared.Fields.Members')}</div>
          <Form.Item className="col-12">
            <Table
              loading={isLoadingTeamMember}
              className="mt-2 table-comment"
              dataSource={dataSourceMember}
              pagination={false}
              columns={columnsTableTeamMember}
              rowKey={'id'}
              locale={{
                emptyText: `${
                  searchTeamDetail ? t('Shared.Texts.NoMatchesFound') : t('Shared.Texts.NoData')
                } `
              }}
            />
          </Form.Item>
        </Form>

        <Form layout="vertical" className="border p-2 mt-4">
          <div className="fs-4">{t('Shared.Fields.Transactions')}</div>
          <Form.Item className="col-12">
            <Table
              loading={isLoadingTransaction}
              className="mt-2 table-comment"
              dataSource={dataSourceTransaction}
              pagination={false}
              columns={columnsTableTransaction}
              rowKey={'id'}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
