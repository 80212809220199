export const NoteFilter = ({
  width,
  height,
  color
}: {
  width?: string;
  height?: string;
  color?: string;
}) => {
  return (
    <svg
      width={width || 34}
      height={height || 38}
      viewBox="0 0 34 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11.4453C0 9.78846 1.34315 8.44531 3 8.44531H26C27.6569 8.44531 29 9.78845 29 11.4453V27.026C29 27.8216 28.6839 28.5847 28.1213 29.1473L20.702 36.5666C20.1394 37.1292 19.3763 37.4453 18.5807 37.4453H3C1.34315 37.4453 0 36.1022 0 34.4453V11.4453ZM3 10.4453C2.44772 10.4453 2 10.893 2 11.4453V34.4453C2 34.9976 2.44772 35.4453 3 35.4453H17.1818V28.6271C17.1818 26.9703 18.525 25.6271 20.1818 25.6271H27V11.4453C27 10.893 26.5523 10.4453 26 10.4453H3ZM26.7991 27.6271H20.1818C19.6295 27.6271 19.1818 28.0748 19.1818 28.6271V35.2445C19.219 35.2165 19.2545 35.1857 19.2878 35.1524L26.7071 27.7331C26.7404 27.6998 26.7711 27.6643 26.7991 27.6271Z"
        fill={color || 'black'}
      />
      <path
        d="M34 10C34 15.5229 29.5229 20 24 20C18.4772 20 14 15.5229 14 10C14 4.47715 18.4772 0 24 0C29.5229 0 34 4.47715 34 10Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 18.5C28.6944 18.5 32.5 14.6944 32.5 10C32.5 5.30558 28.6944 1.5 24 1.5C19.3056 1.5 15.5 5.30558 15.5 10C15.5 14.6944 19.3056 18.5 24 18.5ZM24 20C29.5229 20 34 15.5229 34 10C34 4.47715 29.5229 0 24 0C18.4772 0 14 4.47715 14 10C14 15.5229 18.4772 20 24 20Z"
        fill={color || 'black'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9375 5.83147C18.0615 5.56943 18.3255 5.40234 18.6154 5.40234H29.3846C29.6745 5.40234 29.9385 5.56943 30.0625 5.83147C30.1865 6.09351 30.1484 6.40356 29.9647 6.62778L25.8269 11.676V16.1523C25.8269 16.414 25.6906 16.6567 25.4672 16.7928C25.2438 16.9289 24.9656 16.9388 24.7331 16.8189L22.5792 15.7078C22.3298 15.5791 22.1731 15.3219 22.1731 15.0412V11.676L18.0353 6.62778C17.8515 6.40356 17.8134 6.09351 17.9375 5.83147ZM20.1998 6.90234L23.5031 10.9325C23.613 11.0665 23.6731 11.2345 23.6731 11.4079V14.5842L24.3269 14.9215V11.4079C24.3269 11.2345 24.387 11.0665 24.4969 10.9325L27.8001 6.90234H20.1998Z"
        fill={color || 'black'}
      />
    </svg>
  );
};
