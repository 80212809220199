import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ProjectItem } from './ProjectItem';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  getListProjectAction,
  getListProjectShareWithMeAction
} from 'redux/projects/projects.action';
import { getColorLabel } from 'utils/project-tags';
import { EDelayTime, EEmptyState, EFolderIdDefault, EProjectSortOption } from 'ts/enums';
import { TProject } from 'ts/types';
import { EmptyStateProjectDefault, EmptyStateProjectShareWithMe } from './EmptyState';
import { usePageLoading } from 'context/PageLoadingContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useLoaderContext } from 'context/LoaderContext';
import Breadcrumb from './Breadcrumb';

const numberNextItem = 20;
export function ListProjects() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const listProjectTags = useAppSelector((state) => state.projectTags.currentListProjectTags.data);
  const listTags = useAppSelector((state) => state.tags.currentListTags.data);

  const {
    listProjectShareWithMe,
    listProjectAll,
    listMyProject,
    listProjectFilterFolder,
    listTagsSelected,
    listBaseProject
  } = useAppSelector((state) => state.projects);

  const currentFolderSelected = useAppSelector((state) => state.folder.currentFolderSelected);
  const currentUser = useAppSelector((state) => state.auth.currentUser.data);
  const [emptyState, setEmptyState] = useState<EEmptyState>(EEmptyState.DEFAULT);
  const [listProjectsRender, setListProjectsRender] = useState<TProject[]>([]);
  const { destroyMessage } = usePageLoading();
  const [countRenderItem, setCountRenderItem] = useState<number>(numberNextItem);
  const { loader } = useLoaderContext();
  const [projectSortBy, setProjectSortBy] = useState<EProjectSortOption>(EProjectSortOption.DATE);
  const [projectSortAscending, setProjectSortAscending] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const onClickSort = (sortBy: EProjectSortOption) => {
    if (sortBy != projectSortBy) {
      // Change sort type
      setProjectSortBy(sortBy);
    } else {
      // Change sort direction
      setProjectSortAscending(!projectSortAscending);
    }
  };

  function RenderEmptyState() {
    switch (emptyState) {
      case EEmptyState.DEFAULT:
        return <EmptyStateProjectDefault />;
      case EEmptyState.SHARED_WITH_ME:
        return <EmptyStateProjectShareWithMe />;
      default:
        return <EmptyStateProjectDefault />;
    }
  }

  useEffect(() => {
    loader.start();
    if (currentUser) {
      dispatch(getListProjectAction({ currentUser })).then(() => {
        destroyMessage();
        loader.complete();
      });
      dispatch(getListProjectShareWithMeAction());
    }
  }, [currentUser?.id]);

  useEffect(() => {
    switch (currentFolderSelected) {
      case EFolderIdDefault.PROJECT_ALL:
        setListProjectsRender(
          listTagsSelected.length > 0 ? filterList(listProjectAll.data) : listProjectAll.data
        );
        setCountRenderItem(numberNextItem);
        setEmptyState(EEmptyState.DEFAULT);
        break;
      case EFolderIdDefault.SHARED_WITH_ME:
        setListProjectsRender(
          listTagsSelected.length > 0
            ? filterList(listProjectShareWithMe.data)
            : listProjectShareWithMe.data
        );
        setCountRenderItem(numberNextItem);
        setEmptyState(EEmptyState.SHARED_WITH_ME);
        break;
      case EFolderIdDefault.MY_PROJECT:
        setListProjectsRender(
          listTagsSelected.length > 0 ? filterList(listMyProject.data) : listMyProject.data
        );
        setCountRenderItem(numberNextItem);
        setEmptyState(EEmptyState.DEFAULT);
        break;
      default:
        setListProjectsRender(
          listTagsSelected.length > 0
            ? filterList(listProjectFilterFolder.data)
            : listProjectFilterFolder.data
        );
        setCountRenderItem(numberNextItem);
        setEmptyState(EEmptyState.DEFAULT);
        break;
    }
  }, [
    listProjectAll,
    listProjectFilterFolder,
    currentFolderSelected,
    listProjectShareWithMe,
    listMyProject,
    listTagsSelected
  ]);

  function filterList(listProject: TProject[]) {
    if (!listProjectTags) return [];
    const filteredListProjectTags = listProjectTags
      .filter((project) => listTagsSelected.includes(project.tagId))
      .map((tag) => tag.projectId);
    const listProjectFilterLabel = listProject.filter((project) =>
      filteredListProjectTags.includes(project.id)
    );
    return listProjectFilterLabel;
  }

  // if (listBaseProject.isLoading) return <Loader />;
  useEffect(() => {
    document.getElementById('main-element')?.addEventListener('scroll', () => {
      const ctnScroll = document.getElementById('main-element');
      if (
        ctnScroll &&
        ctnScroll.offsetHeight + ctnScroll.scrollTop >= ctnScroll.scrollHeight - 400
      ) {
        setCountRenderItem((prev) => prev + numberNextItem);
      }
    });
  }, []);

  var sortListProjectsRender: TProject[] = JSON.parse(
    JSON.stringify(listProjectsRender)
  ) as TProject[];
  if (searchTerm.length > 0)
    sortListProjectsRender = sortListProjectsRender.filter((p) => {
      return p.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

  const throttledProjectSearch = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
    }, 1000),
    [JSON.stringify(listProjectsRender)]
  );

  switch (projectSortBy) {
    case EProjectSortOption.NAME:
      if (projectSortAscending)
        sortListProjectsRender.sort((a, b) => {
          if (a.name < b.name) return -1;
          else if (a.name > b.name) return 1;
          return 0;
        });
      else
        sortListProjectsRender.sort((a, b) => {
          if (a.name > b.name) return -1;
          else if (a.name < b.name) return 1;
          return 0;
        });
      break;
    case EProjectSortOption.DATE:
      if (projectSortAscending)
        sortListProjectsRender.sort((a, b) => {
          if (a.creationDate < b.creationDate) return -1;
          else if (a.creationDate > b.creationDate) return 1;
          return 0;
        });
      else
        sortListProjectsRender.sort((a, b) => {
          if (a.creationDate > b.creationDate) return -1;
          else if (a.creationDate < b.creationDate) return 1;
          return 0;
        });
      break;
  }

  useEffect(() => {
    var element: HTMLParagraphElement | null = document.querySelector('#project-list-name');
    if (element) {
      var elementText: HTMLSpanElement | null = element.querySelector('.text');
      if (elementText) element.style.minWidth = `${10 * elementText.innerHTML.length}px`;
    }
  }); // Always change because of language

  return (
    <div className="d-flex flex-column h-100">
      <Breadcrumb />
      <div className="project-list-control d-none d-lg-flex justify-content-between align-items-center ps-3 pe-16 mt-0 mb-1 py-1">
        <div className="d-flex justify-content-start align-items-center gap-1">
          <p
            id="project-list-name"
            onClick={onClickSort.bind(null, EProjectSortOption.NAME)}
            className={`mb-0 option ${
              projectSortBy == EProjectSortOption.NAME ? 'option--selected' : ''
            }`}
          >
            <span className="text">{t('Shared.Fields.ProjectName')}</span>{' '}
            {projectSortBy == EProjectSortOption.NAME ? (
              projectSortAscending ? (
                <span>&#8595;</span>
              ) : (
                <span>&#8593;</span>
              )
            ) : null}
          </p>
          <div className="search-box-wrapper">
            <input
              type="text"
              className="search-box"
              placeholder={t('Shared.Texts.SearchByProjectName')}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                throttledProjectSearch(e.target.value);
              }}
            />
            <p>&#x1F50D;</p>
          </div>
        </div>
        <div>
          <p
            onClick={onClickSort.bind(null, EProjectSortOption.DATE)}
            className={`mb-0 option ${
              projectSortBy == EProjectSortOption.DATE ? 'option--selected' : ''
            }`}
          >
            {t('Shared.Fields.CreationDate')}{' '}
            {projectSortBy == EProjectSortOption.DATE ? (
              projectSortAscending ? (
                <span>&#8595;</span>
              ) : (
                <span>&#8593;</span>
              )
            ) : null}
          </p>
        </div>
      </div>
      <div
        id="ctn-project-scroll"
        className="list-videos p-1 pb-md-2 pb-md-0"
        onScroll={() => {
          const ctnScroll = document.getElementById('ctn-project-scroll');
          if (
            ctnScroll &&
            ctnScroll.offsetHeight + ctnScroll.scrollTop >= ctnScroll.scrollHeight - 400
          ) {
            setCountRenderItem((prev) => prev + numberNextItem);
          }
        }}
      >
        {!listBaseProject.isFetched &&
          sortListProjectsRender.length === 0 &&
          [...Array(12)].map((_, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`key${index}`}
              className="d-flex align-items-center"
              style={{ padding: '16px 24px' }}
            >
              <div>
                <Skeleton style={{ width: 62, height: 35 }} />
              </div>
              <div style={{ width: '100%', marginLeft: 12 }}>
                <Skeleton style={{ width: '100%', height: 20 }} />
              </div>
            </div>
          ))}
        {sortListProjectsRender.length > 0 &&
          sortListProjectsRender.map(
            (project, index) =>
              index < countRenderItem && (
                <ProjectItem
                  key={project.id}
                  project={project}
                  tag={getColorLabel({ listProjectTags, listTags, project })}
                />
              )
          )}
        {listBaseProject.isFetched && sortListProjectsRender.length === 0 && <RenderEmptyState />}
      </div>
    </div>
  );
}
