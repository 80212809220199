import { createInlineStyleButton } from '@draft-js-plugins/buttons';

export const HightLightButton = createInlineStyleButton({
  style: 'HIGHLIGHT',
  children: (
    <svg
      width="18"
      height="14"
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="bookmark"
      className="svg-inline--fa fa-bookmark fa-w-12"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill="currentColor"
        d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
      />
    </svg>
  )
});

export const HightLightButtonSelected = createInlineStyleButton({
  style: 'HIGHLIGHT',
  children: (
    <svg
      stroke="currentColor"
      fill="currentColor"
      viewBox="0 0 384 512"
      height="14"
      width="18"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z" />
    </svg>
  )
});

export const StrikeThroughButton = createInlineStyleButton({
  style: 'STRIKETHROUGH',
  children: (
    <svg
      width="18"
      height="14"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="strikethrough"
      className="svg-inline--fa fa-strikethrough fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M496 224H293.9l-87.17-26.83A43.55 43.55 0 0 1 219.55 112h66.79A49.89 49.89 0 0 1 331 139.58a16 16 0 0 0 21.46 7.15l42.94-21.47a16 16 0 0 0 7.16-21.46l-.53-1A128 128 0 0 0 287.51 32h-68a123.68 123.68 0 0 0-123 135.64c2 20.89 10.1 39.83 21.78 56.36H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h480a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-180.24 96A43 43 0 0 1 336 356.45 43.59 43.59 0 0 1 292.45 400h-66.79A49.89 49.89 0 0 1 181 372.42a16 16 0 0 0-21.46-7.15l-42.94 21.47a16 16 0 0 0-7.16 21.46l.53 1A128 128 0 0 0 224.49 480h68a123.68 123.68 0 0 0 123-135.64 114.25 114.25 0 0 0-5.34-24.36z"
      />
    </svg>
  )
});

export const SentencecaseButton = createInlineStyleButton({
  style: 'SENTENCECASE',
  children: (
    <svg height="22" width="22" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <text
        fontWeight="bold"
        textAnchor="start"
        fontFamily="Helvetica, Arial, sans-serif"
        fontSize="11"
        y="16"
        x="0"
        strokeWidth="0"
        fill="#000000"
      >
        Abc
      </text>
    </svg>
  )
});

export const UppercaseButton = createInlineStyleButton({
  style: 'UPPERCASE',
  children: (
    <svg height="22" width="22" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <text
        fontWeight="bold"
        textAnchor="start"
        fontFamily="Helvetica, Arial, sans-serif"
        fontSize="11"
        y="16"
        x="0"
        strokeWidth="0"
        fill="#000000"
      >
        ABC
      </text>
    </svg>
  )
});

export const LowercaseButton = createInlineStyleButton({
  style: 'LOWERCASE',
  children: (
    <svg height="22" width="22" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <text
        fontWeight="bold"
        textAnchor="start"
        fontFamily="Helvetica, Arial, sans-serif"
        fontSize="11"
        y="16"
        x="0"
        strokeWidth="0"
        fill="#000000"
      >
        abc
      </text>
    </svg>
  )
});

// SENTENCECASE
