import { Link, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import {
  EDelayTime,
  EExportName,
  EModals,
  ERoutes,
  ESocketTopic,
  EStatusMedia,
  EStatusProject,
  EStyleFilter,
  ETypeNoti,
} from "ts/enums";
import { ButtonWithIcons } from "components/common";
import { BsTranslate } from "react-icons/bs";
import { popModal, pushModal } from "redux/modal/modal.slice";
import {
  FaDownload,
  FaRegClock,
  FaRegQuestionCircle,
  FaSearch,
} from "react-icons/fa";
import { ShortCut } from "components/TranscriptPage";
import { HiUserGroup } from "react-icons/hi";
import AssembleIcon from "assets/icons/video-assemble-black.svg";
import DubbingIcon from "assets/icons/dubbing-icon.svg";
import SubtitleIcon from "assets/icons/subtitle-icon.svg";
import { MobileHeader } from "components/VideoPlayer/MobileHeader";
import {
  changeFilterStyle,
  changeOpenSearchBox,
  closeSearchBox,
  resetCurrentTranscript,
  setIsHandleSave,
} from "redux/transcription/transcription.slice";
import { Loader } from "components/common/Loader";
import { TranscriptConfigPopOver } from "components/TranscriptPage/TranscriptConfigPopOver";
import { reformatDateFromNow } from "utils/time";
import { getTranscriptionByMediaIdAction } from "redux/transcription/transcription.action";
import {
  updateListMediasInCurrentProject,
  updateNameProject,
} from "redux/projects/projects.slice";
import { BookmarkFilter, NoteFilter } from "assets/icons";
import Countdown from "antd/es/statistic/Countdown";
import HistoryIcon from "assets/icons/history-icon.svg";
import { lock, editProject } from "apis/projects.api";
import { usePageLoading } from "context/PageLoadingContext";
import { useEditorContext } from "context/EditorContext";
import { AiOutlineCaretDown, AiTwotoneLock } from "react-icons/ai";
import { Popconfirm, Popover, Tooltip } from "antd";
import { setMedia } from "redux/media/media.slice";
import { useWebSocket } from "context/WebSocketConnectionContext";
import { createChannelId } from "utils/common";
import { TMedia } from "ts/types";
import { MediaItem } from "components/MainPageContainer/MediaPopOver/component";
import { useHotkeys } from "react-hotkeys-hook";
import { breakFileNameWithScreenSize } from "utils/string";
import { useUpdateWidth } from "hooks/useUpdateWidth";

export function HeaderTranscript() {
  const currentProject = useAppSelector(
    (state) => state.projects.currentProject
  );
  const [searchParams] = useSearchParams();
  const mediaId = searchParams.get("mediaId");
  const currentMedia = mediaId ? currentProject.data?.medias?.find((media) => media.id === mediaId) : ((currentProject?.data?.medias?.length || 0) > 0 ? currentProject.data?.medias[0] : undefined);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { width } = useUpdateWidth();

  const [visibleShortCut, setVisibleShortCut] = useState<boolean>(false);
  const [openTranscriptConfig, setOpenTransciptConfig] =
    useState<boolean>(false);
  const openSearchBox = useAppSelector(
    (state) => state.transcription.openSearchBox
  );

  const isChangeTranscript = useAppSelector(
    (state) => state.transcription.isChangeTranscript
  );
  const [lastTimeSaved, setLastTimeSaved] =
    useState<string>("a few seconds ago");
  const [isVisibleTime, setIsVisibleTime] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const currentUserData = useAppSelector(
    (state) => state.auth.currentUser.data
  );
  const [inputNameProject, setInputNameProject] = useState<string>();
  const params = useParams();
  const { projectId } = params;
  const { openMessage, destroyMessage } = usePageLoading();
  const { isNoneEdit, isLock, setIsLock } = useEditorContext();
  const { subscribe, unsubscribe } = useWebSocket();
  const [openMediaPopOver, setOpenMediaPopOver] = useState<boolean>(false);
  const currentFilters = useAppSelector(
    (state) => state.transcription.currentFilters
  );

  async function handleEditProject() {
    if (!inputNameProject) return;
    const inputNoHtml = inputNameProject.replace(/<[^>]+>/g, "");
    if (!projectId) return;
    if (inputNoHtml === currentProject.data?.name) return;
    destroyMessage();
    openMessage("loading", t("Shared.Texts.ChangingW3Dot"));
    inputNoHtml && dispatch(updateNameProject({ name: inputNoHtml }));

    if (inputNoHtml && currentProject.data) {
      await editProject({
        project: {
          ...currentProject.data,
          name: inputNoHtml,
        },
      })
        .then(() => {
          destroyMessage();
          openMessage("success", t("Shared.Texts.Success"));
        })
        .catch(() => {
          destroyMessage();
          openMessage("error", t("Shared.Errors.ErrorUpdatingProjectName"));
          setInputNameProject(currentProject.data?.name);
        });
    } else {
      destroyMessage();
      openMessage("error", t("Shared.Errors.ErrorUpdatingProjectName"));
      setInputNameProject(currentProject.data?.name);
    }
  }

  function getLastTimeSave() {
    if (currentMedia) {
      setLastTimeSaved(reformatDateFromNow(currentMedia.lastSavedTranscript));
    } else {
      setLastTimeSaved(t("Shared.Texts.AFewSecondsAgo"));
    }
  }

  function checkPermissionEdit() {
    if (
      isLock &&
      currentMedia &&
      currentUserData &&
      currentMedia.lockByUserId !== currentUserData.id &&
      !isNoneEdit
    ) {
      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: {
            type: ETypeNoti.WARNING,
            title: t("Pages.TranscriptDetail.Texts.FileLockedExplain"),
          },
        })
      );
    }
  }

  const onFinish = () => {
    setIsVisibleTime(false);
  };

  useEffect(() => {
    if (currentMedia && currentMedia.status !== EStatusMedia.READY) {
      setIsVisibleTime(true);
    } else {
      setIsVisibleTime(false);
    }
  }, [currentMedia]);

  useEffect(() => {
    if (currentProject.data) {
      setInputNameProject(currentProject.data.name);
    }
    return () => {
      // turn of search box when dismount this
      dispatch(closeSearchBox());
    };
  }, [currentProject]);

  useEffect(() => {
    checkPermissionEdit();
  }, [isLock, currentMedia]);

  useEffect(() => {
    if (currentProject && projectId && currentUserData && currentProject.data) {
      subscribe({
        topicChannelId: createChannelId(
          "topic",
          ESocketTopic.MEDIA_UPDATE_PROJECT,
          projectId
        ),
        callback: (res: TMedia) => {
          if (
            (res.status === EStatusMedia.TRANSCRIBED ||
              res.status === EStatusMedia.TRANSLATED)
          ) {
            dispatch(
              updateListMediasInCurrentProject({
                media: res,
              })
            );
          }
          if (res.id === mediaId)
            dispatch(
              setMedia({
                media: res,
              })
            );

          if (
            currentUserData &&
            res.lockByUserId !== null &&
            res.lockByUserId !== currentUserData.id
          ) {
            dispatch(
              pushModal({
                name: EModals.NOTI_MODAL,
                data: null,
                notiData: {
                  type: ETypeNoti.WARNING,
                  title: t(
                    "Pages.TranscriptDetail.Texts.EditPrivilegeTakenByUser",
                    {
                      name: res.lockByUser,
                    }
                  ),
                },
              })
            );
            dispatch(getTranscriptionByMediaIdAction({ mediaId: res.id }));
          }
        },
      });
    }
    return () => {
      if (projectId) {
        unsubscribe(
          createChannelId("topic", ESocketTopic.MEDIA_UPDATE_PROJECT, projectId)
        );
      }
    };
  }, [currentMedia, currentUserData, currentProject]);

  useEffect(() => {
    setOpenMediaPopOver(false);
  }, [mediaId]);

  function saveTranscript() {
    if (!isChangeTranscript) return;
    getLastTimeSave();
    dispatch(setIsHandleSave({ isHandleSave: true }));
  }

  useHotkeys("ctrl+s, Meta+s", (e) => {
    saveTranscript();
    e.preventDefault();
  });

  // const currentListMediaTranscribed = currentProject.data?.medias.filter(
  //   (media) =>
  //     media.status !== EStatusMedia.TRANSCRIBED &&
  //     media.status !== EStatusMedia.TRANSLATED &&
  //     media.status !== EStatusMedia.SUBTITLED &&
  //     media.status !== EStatusMedia.EMPTY
  // );
  // const isTranscribing =
  //   currentListMediaTranscribed && currentListMediaTranscribed.length > 0 ? true : false;
  const isTranscribing =
    (currentMedia?.status !== EStatusMedia.TRANSCRIBED &&
      currentMedia?.status !== EStatusMedia.TRANSLATED &&
      currentMedia?.status !== EStatusMedia.SUBTITLED &&
      currentMedia?.status !== EStatusMedia.EMPTY)
    ||
    (currentProject?.data?.status == EStatusProject.TRANSLATE_PENDING && !currentMedia);

  return (
    <>
      {visibleShortCut && (
        <ShortCut
          isVisible={visibleShortCut}
          setIsvisible={setVisibleShortCut}
        />
      )}
      <header className="header-transcript px-3 py-1">
        <nav className="d-flex justify-content-between align-items-center gap-3">
          <div
            className="d-flex align-items-center gap-2"
            style={{ minHeight: 57 }}
          >
            <Link
              to={ERoutes.MAIN}
              onClick={() => {
                dispatch(popModal());
                setIsLock(false);
                dispatch(setMedia({ media: null }));
                dispatch(resetCurrentTranscript());
              }}
            >
              <div>
                <img
                  src="https://app.simonsaysai.com/img/favicon.png"
                  width={45}
                  height={45}
                  alt="logo-mini"
                />
              </div>
            </Link>
            {!currentProject.isLoading &&
              currentProject.isFetched &&
              currentProject.data &&
              currentMedia && (
                <div className="left-header">
                  <div className="ctn-name-project position-relative">
                    {isEdit &&
                      currentProject.data?.owner.id === currentUserData?.id ? (
                      <input
                        className="name-project-header-input"
                        onBlur={() => {
                          setIsEdit(false);
                          handleEditProject();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            setIsEdit(false);
                            handleEditProject();
                          }
                        }}
                        value={inputNameProject}
                        onChange={(e) => setInputNameProject(e.target.value)}
                        autoFocus
                        onFocus={(e) => e.target.select()}
                        maxLength={60}
                      />
                    ) : (
                      <div
                        onClick={() => {
                          setIsEdit(true);
                        }}
                        className="name-project-header p-1"
                      >
                        <div className="truncate-1">
                          {currentProject.data?.name}
                        </div>
                      </div>
                    )}
                  </div>

                  {currentProject.isFetched && (
                    <div className="d-flex align-items-center gap-1 px-1">
                      {isLock && !isNoneEdit && (
                        <div className="d-none d-md-block">
                          <Popconfirm
                            placement="bottomRight"
                            title={
                              <div style={{ width: 240 }}>
                                <Trans
                                  t={t}
                                  i18nKey="Pages.TranscriptDetail.Texts.OtherUserEditingWarning"
                                  components={{
                                    name: (
                                      <span className="fw-bold">
                                        {currentMedia?.lockByUser}
                                      </span>
                                    ),
                                  }}
                                />
                              </div>
                            }
                            onConfirm={async () => {
                              if (currentMedia) {
                                await lock({
                                  mediaId: currentMedia?.id,
                                  forceLock: true,
                                }).then(() => {
                                  window.location.reload();
                                });
                              }
                            }}
                            okText={t("Shared.Actions.Process")}
                            cancelText={t("Shared.Actions.Cancel")}
                            overlayClassName="unlock-project"
                            trigger={"click"}
                          >
                            <div className="d-none d-md-block">
                              <AiTwotoneLock
                                className="icon"
                                color="var(--si-primary)"
                              />
                            </div>
                          </Popconfirm>
                        </div>
                      )}
                      {currentProject.data &&
                        currentProject.data.medias.length === 1 && (
                          <div className="fw-bolder media-name">
                            {breakFileNameWithScreenSize({
                              fileName: currentMedia?.name,
                              sizeScreen: width,
                            })}
                          </div>
                        )}

                      {currentProject.data &&
                        currentProject.data.medias.length > 1 && (
                          <Popover
                            placement="bottom"
                            content={
                              <div className="content-medias">
                                {currentProject.data.medias.map(
                                  (media, index) => {
                                    return (
                                      <MediaItem
                                        media={media}
                                        index={index}
                                        key={media.id}
                                        projectStatus={
                                          currentProject.data?.status ||
                                          EStatusProject.OPEN
                                        }
                                      />
                                    );
                                  }
                                )}
                              </div>
                            }
                            trigger="click"
                            overlayClassName={`pop-over-custom --no-padding`}
                            open={openMediaPopOver}
                            onOpenChange={() => {
                              setOpenMediaPopOver(!openMediaPopOver);
                            }}
                          >
                            <div className="d-flex gap-1 align-items-center">
                              <div className="fw-bolder media-name">
                                {breakFileNameWithScreenSize({
                                  fileName: currentMedia?.name,
                                  sizeScreen: width,
                                })}
                              </div>
                              <AiOutlineCaretDown
                                color="var(--si-dim-gray)"
                                onClick={() => {
                                  setOpenMediaPopOver(!openMediaPopOver);
                                }}
                              />
                            </div>
                          </Popover>
                        )}
                      <Tooltip
                        placement="top"
                        trigger={"hover"}
                        mouseEnterDelay={EDelayTime.FAST}
                        title={t("Pages.TranscriptDetail.Texts.VersionHistory")}
                        overlayClassName="custom-toolstip"
                      >
                        <img
                          className="d-none d-md-block"
                          src={HistoryIcon}
                          alt=""
                          width={18}
                          height={14}
                          onClick={() => {
                            dispatch(
                              pushModal({
                                name: EModals.VERSION_HISTORY_MODAL,
                                data: { mediaId },
                              })
                            );
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                  {currentProject.isFetched && (
                    <>
                      <div
                        className={`px-1 ${isNoneEdit || isLock ? "d-none" : "d-md-block"
                          } save-action`}
                      >
                        {isChangeTranscript ? (
                          <>
                            <span>
                              {t("Shared.Texts.LastSaved")}: {lastTimeSaved}
                            </span>
                            <span
                              className="ms-1 save-now"
                              onClick={(e) => {
                                e.stopPropagation();
                                saveTranscript();
                              }}
                            >
                              {t("Shared.Actions.SaveNow")}
                            </span>
                          </>
                        ) : (
                          !isLock && <span>{t("Shared.Texts.Saved")}</span>
                        )}
                      </div>
                      {isNoneEdit && (
                        <a
                          className="ms-1 custom-a-tag"
                          href="https://www.simonsaysai.com/help/4572046-view-only-mode"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("Shared.Texts.ViewOnlyMode")}
                        </a>
                      )}
                    </>
                  )}
                </div>
              )}
          </div>

          {!currentProject.isLoading &&
            currentProject.isFetched &&
            currentProject.data &&
            currentMedia && (
              <div
                className={`right-header d-flex align-items-center ${isNoneEdit ? "d-none" : ""
                  }`}
              >
                {currentUserData?.id === currentProject.data?.owner.id &&
                  !isLock && (
                    <div className="d-none d-md-block">
                      <ButtonWithIcons
                        icon={<HiUserGroup size={24} />}
                        tooltipTile={t("Shared.Actions.ShareProject")}
                        onClick={() => {
                          dispatch(
                            pushModal({
                              name: EModals.SHARE_PROJECT_MODAL,
                              data: { project: currentProject.data },
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                {isTranscribing || (
                  <div className="d-none d-lg-block">
                    <ButtonWithIcons
                      icon={<img src={AssembleIcon} width={24} height={24} />}
                      tooltipTile={t("Shared.Actions.LaunchAssembleMode")}
                      onClick={() => {
                        window.open("https://edit.simonsaysai.com/", "_blank");
                      }}
                    />
                  </div>
                )}
                {isTranscribing || (
                  <div className="d-none d-lg-block">
                    <ButtonWithIcons
                      icon={
                        <img
                          src={DubbingIcon}
                          width={24}
                          height={24}
                          className={`${currentMedia &&
                            currentMedia.translateTargetLanguageId
                            ? "opacity-1"
                            : "opacity-50 cursor-disabled"
                            }`}
                        />
                      }
                      tooltipTile={t(
                        "Pages.TranscriptDetail.Texts.DubbingAvailableForTranslated"
                      )}
                      onClick={() => {
                        if (
                          currentMedia &&
                          currentMedia.translateTargetLanguageId
                        ) {
                          dispatch(
                            pushModal({
                              name: EModals.DUBBING,
                              data: {
                                languageCode:
                                  currentMedia.translateTargetLanguageId,
                              },
                            })
                          );
                        }
                      }}
                    />
                  </div>
                )}
                {isTranscribing || (
                  <div className="d-none d-lg-block">
                    <ButtonWithIcons
                      icon={
                        <BsTranslate
                          size={24}
                          className={`${currentProject &&
                            currentProject.data?.status !==
                            EStatusProject.TRANSLATED
                            ? "opacity-1"
                            : "opacity-50 cursor-disabled"
                            }`}
                        />
                      }
                      tooltipTile={t("Shared.Actions.Translate")}
                      onClick={() => {
                        if (
                          currentProject &&
                          currentProject.data?.status !==
                          EStatusProject.TRANSLATED &&
                          currentProject.data?.status !== EStatusProject.OPEN
                        ) {
                          dispatch(
                            pushModal({
                              name: EModals.TRANSLATE_MODAL,
                              data: {},
                            })
                          );
                        }
                      }}
                    />
                  </div>
                )}
                {isTranscribing ||
                  (currentProject.data &&
                    currentProject.data?.status !==
                    EStatusProject.SUBTITLED && (
                      <div className="d-none d-lg-block">
                        <ButtonWithIcons
                          icon={
                            <img src={SubtitleIcon} width={24} height={24} />
                          }
                          tooltipTile={t(
                            "Pages.TranscriptDetail.Texts.VisualSubtitleEditor"
                          )}
                          onClick={() => {
                            dispatch(
                              pushModal({
                                name: EModals.EXPORT_MODAL,
                                data: {
                                  mediaId,
                                  defaultOption: {
                                    data: {
                                      subData: null,
                                      option: {
                                        characterPerLine: 32,
                                        withSingleLine: false,
                                        withUppercase: false,
                                      },
                                    },
                                    key: EExportName.VISUAL_SUBTITLE_EDITOR,
                                  },
                                },
                              })
                            );
                          }}
                        />
                      </div>
                    ))}
                {isTranscribing || (
                  <div className="d-none d-lg-block">
                    <ButtonWithIcons
                      icon={
                        <FaSearch
                          size={21}
                          color={
                            openSearchBox
                              ? "var(--si-blue-7)"
                              : "var(--si-black)"
                          }
                        />
                      }
                      tooltipTile={t("Shared.Actions.SearchAndReplace")}
                      onClick={() => {
                        dispatch(changeOpenSearchBox());
                      }}
                    />
                  </div>
                )}
                {isTranscribing || (
                  <div className="d-none d-lg-block">
                    <ButtonWithIcons
                      icon={
                        <NoteFilter
                          width="20"
                          height="25"
                          color={
                            currentFilters.includes(EStyleFilter.ANNOTATION)
                              ? "var(--si-battery-charged-blue)"
                              : "black"
                          }
                        />
                      }
                      tooltipTile={t("Pages.TranscriptDetail.Texts.NoteFilter")}
                      onClick={() => {
                        dispatch(
                          changeFilterStyle({
                            filterStyle: EStyleFilter.ANNOTATION,
                          })
                        );
                      }}
                    />
                  </div>
                )}
                {isTranscribing || (
                  <div className="d-none d-lg-block">
                    <ButtonWithIcons
                      icon={
                        <BookmarkFilter
                          width="22"
                          height="27"
                          color={
                            currentFilters.includes(EStyleFilter.HIGHLIGHT)
                              ? "var(--si-bright-yellow)"
                              : "black"
                          }
                        />
                      }
                      tooltipTile={t(
                        "Pages.TranscriptDetail.Texts.BookmarkFilter"
                      )}
                      onClick={() => {
                        dispatch(
                          changeFilterStyle({
                            filterStyle: EStyleFilter.HIGHLIGHT,
                          })
                        );
                      }}
                    />
                  </div>
                )}
                <div className="d-none d-lg-block">
                  <ButtonWithIcons
                    icon={<FaRegQuestionCircle size={20} />}
                    tooltipTile={t(
                      "Pages.TranscriptDetail.Texts.KeyboardShortcuts"
                    )}
                    onClick={() => {
                      setVisibleShortCut(true);
                    }}
                    style={{ height: 37 }}
                  />
                </div>
                {isTranscribing || (
                  <div className="d-none d-lg-block">
                    <TranscriptConfigPopOver
                      isOpen={openTranscriptConfig}
                      setIsOpen={setOpenTransciptConfig}
                    >
                      <div>
                        <ButtonWithIcons
                          icon={<FaRegClock size={22} />}
                          tooltipTile={t("Shared.Actions.EditTimecode")}
                          onClick={() => {
                            checkPermissionEdit();
                            if (!isLock)
                              setOpenTransciptConfig(!openTranscriptConfig);
                          }}
                          style={{ height: 37 }}
                        />
                      </div>
                    </TranscriptConfigPopOver>
                  </div>
                )}

                {isTranscribing ||
                  currentMedia?.status === EStatusMedia.FAILED ||
                  currentMedia?.status === EStatusMedia.EMPTY || (
                    <div
                      className="d-none d-sm-block"
                      style={{ marginLeft: 5 }}
                    >
                      <ButtonWithIcons
                        className="export-button p-1 cursor-pointer"
                        icon={<FaDownload size={18} />}
                        tooltipTile={t("Shared.Actions.Export")}
                        onClick={() => {
                          dispatch(
                            pushModal({
                              name: EModals.EXPORT_MODAL,
                              data: { mediaId },
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                {isTranscribing && (
                  <>
                    <Loader />
                    <div className="ms-1 text">
                      <div style={{ color: "var(--si-primary)" }}>
                        {(currentProject.data?.status ===
                          EStatusProject.TRANSCRIBING ||
                          currentProject.data?.status ===
                          EStatusProject.READY) &&
                          t("Pages.TranscriptDetail.Texts.TranscribingW3Dot")}
                        {(currentProject.data?.status === EStatusProject.TRANSLATING || currentProject.data?.status === EStatusProject.TRANSLATE_PENDING) &&
                          t("Pages.TranscriptDetail.Texts.TranslatingW3Dot")}
                      </div>
                      {currentMedia &&
                        currentMedia.duration !== 0 &&
                        isVisibleTime && (
                          <div
                            className="time-remaining align-middle d-flex align-items-center gap-1"
                            style={{ color: "var(--si-primary)" }}
                          >
                            {t("Pages.TranscriptDetail.Texts.TimeRemaining")}:
                            <Countdown
                              value={
                                new Date().getTime() +
                                currentMedia.duration * 250
                              }
                              format="HH:mm:ss:SSS"
                              style={{ marginBottom: 8, width: 80 }}
                              onFinish={onFinish}
                            />
                          </div>
                        )}
                    </div>
                  </>
                )}
                <div className="d-block d-sm-none">
                  <MobileHeader />
                </div>
              </div>
            )}
        </nav>
      </header>
    </>
  );
}
