import React, { useEffect, useState } from 'react';
import { AiFillLock } from 'react-icons/ai';
import IcAmericanExpress from 'assets/img/american-express-curved-128-80.png';
import IcVisa from 'assets/img/visa-curved_128-80.png';
import IcMasterCard from 'assets/img/mastercard-curved-128-80.png';
import moment from 'moment';
import { useAppSelector } from 'redux/hooks';
import { useCreditCard } from 'context/CreditCardContext';
import { useTranslation } from 'react-i18next';

type Props = {
  showUpdateCreditCard?: boolean;
};

export function CreditCard({ showUpdateCreditCard }: Props) {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const {
    cardData,
    setCardData,
    cardName,
    setCardName,
    isExistedCard,
    setIsExistedCard,
    expiryMonthData,
    setExpiryMonthData,
    expiryYearData,
    setCvcData,
    setExpiryYearData,
    cvcData,
    isUpdateCardSaved,
    setIsUpdateCardSaved,
    isUpdatePayment,
    isUpdateSubscriptionPayment,
    setIsUpdatePayment,
    setIsUpdateSubscriptionPayment
  } = useCreditCard({ currentUser: currentUser.data });
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setIsUpdateCardSaved(false);
      setExpiryYearData({ data: '', error: '' });
      setExpiryMonthData({ data: '', error: '' });
      setCardName({ data: '', error: '' });
      setCardData({ cardNumber: '', error: '', type: null });
      setCvcData({ data: '', error: '' });
      setIsUpdateSubscriptionPayment(true);
      setIsUpdatePayment(true);
    };
  }, []);

  function isAmericanExpress(cardNumber: string) {
    const regex = /^3[47]\d{1,2}(| |-)\d{6}\1\d{6}$/;
    return regex.test(cardNumber);
  }

  function isVisaCard(cardNumber: string) {
    const regex = /^4\d{3}(| |-)(?:\d{4}\1){2}\d{4}$/;
    return regex.test(cardNumber);
  }

  function isMasterCard(cardNumber: string) {
    const regex = /^5[1-5]\d{2}(| |-)(?:\d{4}\1){2}\d{4}$/;
    return regex.test(cardNumber);
  }

  function getCardType(cardNumber: string) {
    const formatDisplayRegexAmericanExpress = /\b(\d{4})(\d{6})(\d{5})\b/;
    const formatDisplayRegex = /(\d{1,4})/g;

    let formatDisplay;
    let message;
    let cardType;

    if (cardNumber.length <= 0) {
      formatDisplay = cardNumber;
      cardType = null;
      message = t('Layouts.CreditCard.EnterCardNumber');
    }

    if (cardNumber.charAt(0) === '3') {
      formatDisplay = cardNumber.replace(formatDisplayRegexAmericanExpress, '$1 $2 $3');
      message = t('Layouts.CreditCard.RequireDigit', { count: 15 });
      cardType = 'american-express';
    } else if (cardNumber.charAt(0) === '4' || cardNumber.charAt(0) === '5') {
      if (cardNumber.charAt(0) === '4') {
        cardType = 'visa';
      }
      if (cardNumber.charAt(0) === '5') {
        cardType = 'master';
      }

      formatDisplay = cardNumber.match(formatDisplayRegex)?.toString().replace(/,/g, ' ') || '';
      message = t('Layouts.CreditCard.RequireDigit', { count: 16 });
    } else {
      formatDisplay = cardNumber;
      message = t('Layouts.CreditCard.Errors.InvalidCardNumber');
    }

    if (isAmericanExpress(cardNumber)) {
      message = 'valid';
    } else if (isVisaCard(cardNumber)) {
      message = 'valid';
    } else if (isMasterCard(cardNumber)) {
      message = 'valid';
    }

    return {
      cardNumber: formatDisplay,
      cardType: cardType || null,
      message: message || ''
    };
  }

  function handleGetCardName(value: string) {
    if (value.length <= 0) {
      setCardName({ data: value, error: 'required' });
      return;
    }
    setCardName({ data: value, error: '' });
  }

  function handleVerifyCard(value: string) {
    const cardType = getCardType(value.replace(/\s/g, ''));
    if (cardType.cardNumber) {
      setCardData({
        cardNumber: cardType.cardNumber,
        error: cardType.message,
        type: cardType.cardType
      });
    } else {
      setCardData({
        cardNumber: '',
        error: '',
        type: null
      });
    }
  }

  function handleGetMonth(value: string) {
    const numMonth = Number(value);
    if (numMonth <= 0) {
      setExpiryMonthData({
        data: value,
        error: t('Layouts.CreditCard.RequireNumberBetween', { from: 1, to: 12 })
      });
      return;
    }
    if (numMonth > 12) {
      setExpiryMonthData({
        data: value,
        error: t('Layouts.CreditCard.RequireNumberBetween', { from: 1, to: 12 })
      });
      return;
    }
    setExpiryMonthData({ data: value, error: '' });
  }

  function handleGetYear(value: string) {
    const numYear = Number(value);
    const currentYear = Number(moment().toDate().getFullYear().toString().substring(2));
    if (numYear < currentYear || numYear > 99) {
      setExpiryYearData({
        data: value,
        error: t('Layouts.CreditCard.RequireNumberBetween', { from: currentYear, to: 99 })
      });
      return;
    }
    setExpiryYearData({ data: value, error: '' });
  }

  function handleGetCvc(value: string) {
    if (value.length !== 3) {
      setCvcData({ data: value, error: t('Layouts.CreditCard.RequireDigit', { count: 3 }) });
      return;
    }
    setCvcData({ data: value, error: '' });
  }

  return (
    <section className="credit-card p-2">
      <div className="secure-text d-flex align-items-center gap-1">
        <AiFillLock /> {t('Layouts.CreditCard.Title')}
      </div>
      {!showUpdateCreditCard && currentUser.data?.cardDigits && (
        <div
          className="d-flex align-items-center"
          onClick={() => {
            setIsExistedCard(!isExistedCard);
          }}
        >
          <input
            type="checkbox"
            checked={isExistedCard}
            onChange={(e) => setIsExistedCard(e.target.checked)}
            style={{ cursor: 'default' }}
          />
          <span
            className="ms-1"
            style={{ fontSize: 11, fontWeight: 700, textTransform: 'uppercase', cursor: 'default' }}
          >
            {t('Layouts.CreditCard.UseCardOnFile')}: *{currentUser.data?.cardDigits}
          </span>
        </div>
      )}
      {(showUpdateCreditCard || !isExistedCard) && (
        <div>
          <div className="mt-1 input-group d-flex flex-column">
            <input
              value={cardName.data}
              onChange={(e) => handleGetCardName(e.target.value)}
              type="text"
              placeholder={t('Layouts.CreditCard.CardHolderName')}
              required
              className="rounded"
            />
            {cardName.error && (
              <small style={{ color: 'var(--bs-danger)' }}>{cardName.error}</small>
            )}
          </div>
          <div className="input-group card-number mt-1 d-flex">
            <div className="d-flex flex-column" style={{ flex: 1 }}>
              <input
                value={cardData?.cardNumber}
                placeholder={t('Layouts.CreditCard.CardNumber')}
                required
                className="rounded"
                onChange={(e) => handleVerifyCard(e.target.value)}
              />
              {cardData?.error && cardData?.error !== 'valid' && (
                <small style={{ color: 'var(--bs-danger)' }}>{cardData?.error}</small>
              )}
            </div>

            <div className="card-logo d-flex justify-content-center align-items-center gap-1 rounded">
              <img
                className={`${cardData?.type === 'visa' ? 'actived' : ''}`}
                src={IcVisa}
                alt="master-card"
                height={20}
                width={32}
              />
              <img
                className={`${cardData?.type === 'master' ? 'actived' : ''}`}
                src={IcMasterCard}
                alt="american-express"
                height={20}
                width={32}
              />
              <img
                className={`${cardData?.type === 'american-express' ? 'actived' : ''}`}
                src={IcAmericanExpress}
                alt="visa"
                height={20}
                width={32}
              />
            </div>
          </div>
          <div className="input-group mt-1 row gx-0">
            <div className="col-4">
              <input
                value={expiryMonthData.data}
                type="number"
                placeholder={t('Layouts.CreditCard.ExpiryMonth')}
                required
                className="w-100 rounded"
                onChange={(e) => handleGetMonth(e.target.value)}
              />
              {expiryMonthData.error && (
                <small style={{ color: 'var(--bs-danger)' }}>{expiryMonthData.error}</small>
              )}
            </div>
            <div className="col-4">
              <input
                value={expiryYearData.data}
                type="number"
                placeholder={t('Layouts.CreditCard.ExpiryYear')}
                required
                className="w-100 rounded"
                onChange={(e) => handleGetYear(e.target.value)}
              />
              {expiryYearData.error && (
                <small style={{ color: 'var(--bs-danger)' }}>{expiryYearData.error}</small>
              )}
            </div>
            <div className="col-4">
              <input
                value={cvcData.data}
                placeholder={t('Layouts.CreditCard.CVCCCV')}
                required
                className="w-100 rounded"
                onChange={(e) => handleGetCvc(e.target.value)}
              />
              {cvcData.error && (
                <small style={{ color: 'var(--bs-danger)' }}>{cvcData.error}</small>
              )}
            </div>
          </div>
          {showUpdateCreditCard ? (
            <div>
              <div
                className="check-box mt-1 d-flex align-items-center"
                onClick={() => {
                  setIsUpdateSubscriptionPayment(!isUpdateSubscriptionPayment);
                }}
              >
                <input
                  checked={isUpdateSubscriptionPayment}
                  onChange={(e) => setIsUpdateSubscriptionPayment(e.target.checked)}
                  type="checkbox"
                  style={{ cursor: 'default' }}
                />
                <span className="ms-1" style={{ fontSize: 11, fontWeight: 700, cursor: 'default' }}>
                  {t('Layouts.CreditCard.UpdateCardUsedForSubscription')}
                </span>
              </div>
              <div className="check-box mt-1 d-flex align-items-center">
                <input
                  checked={isUpdatePayment}
                  onChange={(e) => setIsUpdatePayment(e.target.checked)}
                  type="checkbox"
                />
                <span className="ms-1" style={{ fontSize: 11, fontWeight: 700, cursor: 'default' }}>
                  {t('Layouts.CreditCard.UpdateCardUsedForFileTransaction')}
                </span>
              </div>
            </div>
          ) : (
            <div
              className="check-box mt-1 d-flex align-items-center"
              onClick={() => {
                setIsUpdateCardSaved(!isUpdateCardSaved);
              }}
            >
              <input
                checked={isUpdateCardSaved}
                onChange={(e) => setIsUpdateCardSaved(e.target.checked)}
                type="checkbox"
                style={{ cursor: 'default' }}
              />
              <span
                className="ms-1"
                style={{
                  fontSize: 11,
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  cursor: 'default'
                }}
              >
                {currentUser.data?.cardDigits
                  ? t('Layouts.CreditCard.UpdateSavedCard')
                  : t('Layouts.CreditCard.SaveCard')}
              </span>
            </div>
          )}
        </div>
      )}
    </section>
  );
}
