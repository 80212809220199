import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'antd';

import { BsCircleFill } from 'react-icons/bs';
import { RiSpeakFill } from 'react-icons/ri';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { EDelayTime, EStatusProject } from 'ts/enums';
import { TSpeaker } from 'ts/types';
import { setSelectedSpeaker } from 'redux/transcription/transcription.slice';

function SpeakerBox() {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const currentProject = useAppSelector((state) => state.projects?.currentProject);
  const selectedSpeakerId = useAppSelector((state) => state.transcription.selectedSpeaker.id);

  const mediaId = searchParams.get('mediaId');
  const currentMedia = currentProject.data?.medias?.find((media) => media.id === mediaId);
  if (
    currentProject.isLoading ||
    !currentProject.isFetched ||
    !currentProject.data ||
    !currentMedia ||
    currentMedia.speakers.length <= 0
  )
    return null; // No data or not a translated project? Hide the language control

  const speakers = currentMedia.speakers;

  const onClickSpeaker = (s: TSpeaker) => {
    dispatch(setSelectedSpeaker({ id: s.id == selectedSpeakerId ? null : s.id }));
  };

  return (
    <div className="transcript-speaker-box p-0 border d-flex flex-column rounded align-items-center justify-content-start border">
      <div className="box-header d-flex flex-column align-items-center justify-content-between px-1 py-1">
        <Tooltip
          placement="right"
          trigger={'hover'}
          mouseEnterDelay={EDelayTime.FAST}
          title={'Identified Speaker'}
          arrow={false}
          overlayClassName="custom-toolstip"
        >
          <p>
            <RiSpeakFill style={{ marginBottom: '4px' }} /> {speakers.length}
          </p>
        </Tooltip>
      </div>
      <div className="box-body w-100 custom-scrollbar --hidden">
        {speakers.map((s) => (
          <div
            key={s.id}
            className={`w-100 mb-1 speaker ${selectedSpeakerId == s.id ? 'selected-speaker' : ''}`}
          >
            <Tooltip
              placement="right"
              trigger={'hover'}
              mouseEnterDelay={EDelayTime.FAST}
              title={s.name}
              arrow={false}
              overlayClassName="custom-toolstip"
            >
              <p
                onClick={onClickSpeaker.bind(null, s)}
                className="d-flex flex-row align-items-center justify-content-center cursor-pointer mb-0"
                style={{ color: s.color }}
              >
                <BsCircleFill size={20} className="w-50" />
                <span className="w-25 text-left">{s.name.length > 0 ? s.name[0] : '-'}</span>
              </p>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
}

export default React.memo(SpeakerBox);
