import { TErrorResponse, TResponse, TResponseTranslatePrice } from 'ts/types/TResponse';
import { AxiosCustom } from './axios-client';
import { EPayType } from 'ts/enums';
import { StripeTransactionDetail } from 'ts/types/interface';

export async function getPriceBuyCredit(
  durationInSeconds: number
): Promise<TResponse<{ totalPrice: number }>> {
  const res = await AxiosCustom.get(`price`, { params: { buyCredit: true, durationInSeconds } });
  return res.data;
}

export async function getPriceWithPayType({
  projectId,
  mediaId,
  payType
}: {
  projectId: string;
  mediaId: string;
  payType: EPayType;
}): Promise<TResponseTranslatePrice> {
  const res = await AxiosCustom.get(
    `projects/getprice?projectId=${projectId}&currentMediaId=${mediaId}&payType=${payType}`
  );
  return res.data;
}

export async function getPriceForTranscribe({
  projectId
}: {
  projectId: string;
}): Promise<TResponseTranslatePrice | TErrorResponse> {
  try {
    const res = await AxiosCustom.get(`projects/getprice?projectId=${projectId}`);
    return res.data;
  } catch(error: any) {
    return error.response.data;
  }
  
}

export async function checkoutTranscribe({
  projectId,
  listTranslateId
}: {
  projectId: string;
  listTranslateId?: number[];
}): Promise<{ eventLogId: number; response: string, stripeTransactionDetail?: StripeTransactionDetail } | TErrorResponse> {
  try {
    const res = await AxiosCustom.post(
      `projects/checkout?projectId=${projectId}&replaceCard=false${
        listTranslateId ? '&includeTranslation=true' : ''
      }`,
      listTranslateId ? { targetLanguageIds: listTranslateId } : {}
    );
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function checkoutTranscribeWithoutCredit({
  projectId,
  cardHolderName,
  replaceCard,
  exp_month,
  exp_year,
  number,
  saveCard,
  cvc,
  listTranslateId
}: {
  projectId: string;
  replaceCard: boolean;
  cardHolderName: string;
  exp_month: number | string;
  exp_year: number | string;
  number: number | string;
  saveCard: boolean;
  cvc: number | string;
  listTranslateId?: number[];
}): Promise<{ eventLogId: number; response: string, stripeTransactionDetail?: StripeTransactionDetail } | TErrorResponse> {
  try {
    const res = await AxiosCustom.post(
      `projects/checkout?projectId=${projectId}&replaceCard=${replaceCard}&cardHolderName=${cardHolderName}&cvc=${cvc}&exp_month=${exp_month}&exp_year=${exp_year}&number=${number}&saveCard=${saveCard}${
        listTranslateId ? '&includeTranslation=true' : ''
      }`,
      listTranslateId ? { targetLanguageIds: listTranslateId } : {}
    );
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
}

export async function combinePrice(
  projectId: string,
  languageCount: number
): Promise<TResponseTranslatePrice> {
  const res = await AxiosCustom.get(
    `project/${projectId}/translation/combine-price?languageCount=${languageCount}`
  );
  return res.data;
}
