import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TSubscription, TUser } from 'ts/types';
import { changeContactAction, fetchSubscriptionAction, getUserInfoAction } from './auth.action';

type AuthState = {
  currentUser: {
    data: TUser | null;
    isLoading: boolean;
    isFetched: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
  };
  subcriptionDetail: TSubscription | null;
  justLoggedIn: boolean;
};

const initialAuthState: AuthState = {
  currentUser: {
    data: null,
    isLoading: false,
    isFetched: false,
    error: null
  },
  subcriptionDetail: null,
  justLoggedIn: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    updateCurrentUser: (state, { payload }: PayloadAction<TUser>) => {
      state.currentUser.data = payload;
    },
    setUserJustLoggedIn: (state, { payload }: PayloadAction<boolean>) => {
      state.justLoggedIn = payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(getUserInfoAction.pending, (state) => {
      state.currentUser = {
        data: null,
        isLoading: true,
        isFetched: false,
        error: null
      };
    });
    builder.addCase(getUserInfoAction.fulfilled, (state, { payload }) => {
      state.currentUser = {
        data: payload,
        isLoading: false,
        isFetched: true,
        error: null
      };
    });
    builder.addCase(getUserInfoAction.rejected, (state, { payload }) => {
      state.currentUser = {
        data: null,
        isLoading: false,
        isFetched: true,
        error: payload
      };
    });

    builder.addCase(changeContactAction.pending, (state) => {
      state.currentUser = { ...state.currentUser };
    });
    builder.addCase(changeContactAction.fulfilled, (state, { payload }) => {
      if (state.currentUser.data) {
        state.currentUser = {
          ...state.currentUser,
          data: { ...state.currentUser.data, contact: payload },
          isFetched: true,
          isLoading: false,
          error: null
        };
      }
    });
    builder.addCase(changeContactAction.rejected, (state, { payload }) => {
      state.currentUser = {
        ...state.currentUser,
        isLoading: false,
        isFetched: true,
        error: payload
      };
    });

    builder.addCase(fetchSubscriptionAction.pending, (state) => {
      state.subcriptionDetail = null;
    });
    builder.addCase(fetchSubscriptionAction.fulfilled, (state, { payload }) => {
      state.subcriptionDetail = payload;
    });
    builder.addCase(fetchSubscriptionAction.rejected, (state, { payload }) => {
      state.subcriptionDetail = null;
    });
  }
});
export const { updateCurrentUser, setUserJustLoggedIn } = authSlice.actions;
export default authSlice.reducer;
