import React, { useState, useEffect } from 'react';
import { EAccountSettingId, EHttpStatusCode, EModals, ETypeNoti } from 'ts/enums';
import { DatePicker, Select, SelectProps, Tag } from 'antd';
import dayjs from 'dayjs';
import { BasicSection } from './BasicSection';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { changeContactAction } from 'redux/auth/auth.action';
import { exportInvoices } from 'apis/export.api';
import { downloadFileLocally } from 'utils/export';
import { usePageLoading } from 'context/PageLoadingContext';
import { RangePickerProps } from 'antd/es/date-picker';
import { pushModal } from 'redux/modal/modal.slice';
import { useTranslation } from 'react-i18next';
import { convertTimesIso } from 'utils/time';
import { useLoaderContext } from 'context/LoaderContext';
import { separateFolders } from 'utils/common';

const maxAllowedDays = 30;
const maxAllowedMilliseconds = maxAllowedDays * 24 * 60 * 60 * 1000;

type TTagRender = SelectProps['tagRender'];

export function InvoicesSection() {
  var today = new Date();
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().setDate(today.getDate() - 28))
  );
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [statusSelectDate, setStatusSelectDate] = useState<string>(
    'Please first save your details and then press download again.'
  );
  const userData = useAppSelector((state) => state.auth.currentUser.data);
  const listFolder = useAppSelector((state) => state.folder.listFolders);
  const [listFolderSelected, setListFolderSelected] = useState<string[]>([]);
  const [isEnable, setIsEnable] = useState<boolean>(true);
  const [isDownload, setIsDownload] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [addressLine1, setAddressLine1] = useState<string | null>(null);
  const [addressLine2, setAddressLine2] = useState<string | null>(null);
  const [addressLine3, setAddressLine3] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [zipCode, setZipCode] = useState<string | null>(null);
  const [taxNumber, setTaxNumber] = useState<string | null>(null);
  const { openMessage, destroyMessage } = usePageLoading();
  const { t } = useTranslation();
  const { loader } = useLoaderContext();

  function checkValidDate({ startDate, endDate }: { startDate: Date; endDate: Date }): boolean {
    const fmStartDate = new Date(startDate.toISOString().split('T')[0]);
    const fmEndDate = new Date(endDate.toISOString().split('T')[0]);

    const dateDifference = fmEndDate.getTime() - fmStartDate.getTime();

    return dateDifference >= 0 && dateDifference <= maxAllowedMilliseconds;
  }

  function selectChildFolder(folderId: string[]): string[] {
    const tmpSeparateFolders = separateFolders(listFolder);
    const findListIdFolder = new Set<string>();

    tmpSeparateFolders.forEach((folder) => {
      if (folder.encodedParentId && folderId.includes(folder.encodedParentId)) {
        findListIdFolder.add(folder.id);
      }
    });

    return Array.from(findListIdFolder);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onChangeListFolderSelected(e: string[]) {
    let newValue = e.filter((id) => {
      return listFolderSelected.indexOf(id) === -1;
    });

    const selectedFolders = new Set([...e, ...selectChildFolder(newValue)]);
    setListFolderSelected(Array.from(selectedFolders));
  }

  async function handleChangeContact() {
    openMessage('loading', t('Shared.Texts.ChangingW3Dot'));
    loader.start();
    const dataContact = {
      id: userData?.contact?.id || 0,
      companyName,
      addressLine1,
      addressLine2,
      addressLine3,
      country,
      city,
      zipCode,
      taxNumber
    };
    dispatch(changeContactAction({ contact: dataContact })).then((res: any) => {
      destroyMessage();
      loader.complete();
      if (res.error?.message === 'Rejected') {
        openMessage('error', t('Shared.Errors.ErrorChanging'));
      } else {
        openMessage('success', t('Shared.Texts.Changed'));
      }
    });
    setIsEnable(false);
  }

  function handleDownloadInvoice() {
    loader.start();
    exportInvoices({
      endUtcTime: convertTimesIso({
        timeISO: endDate.toISOString().substring(0, endDate.toISOString().length - 1),
        type: 'end'
      }),
      starUtcTime: convertTimesIso({
        timeISO: startDate.toISOString().substring(0, startDate.toISOString().length - 1),
        type: 'start'
      }),
      folderIds: listFolderSelected
    })
      .then((res) => {
        loader.complete();
        if (res) {
          downloadFileLocally(res);
        }
      })
      .catch((error) => {
        loader.complete();
        if (error.response.status === EHttpStatusCode.UNPROCESSABLE_ENTITY) {
          dispatch(
            pushModal({
              name: EModals.NOTI_MODAL,
              data: null,
              notiData: {
                type: ETypeNoti.ERROR,
                title: t('Shared.Texts.EnterDateRangeWithin1Month')
              }
            })
          );
        }
      });
  }

  useEffect(() => {
    if (!checkValidDate({ startDate, endDate })) {
      const fmStartDate = new Date(startDate.toISOString().split('T')[0]);
      const fmEndDate = new Date(endDate.toISOString().split('T')[0]);
      const dateDifference = fmEndDate.getTime() - fmStartDate.getTime();

      if (dateDifference < 0) {
        setStatusSelectDate(t('Shared.Texts.StartDateCantGreaterThanEndDate'));
      } else if (dateDifference > maxAllowedMilliseconds) {
        setStatusSelectDate(t('Shared.Texts.MaxDays30'));
      }
      setIsDownload(false);
    } else if (isEnable) {
      setStatusSelectDate(t('Shared.Texts.PleaseFirstSave'));
      setIsDownload(false);
    } else {
      setStatusSelectDate('');
      setIsDownload(true);
    }
  }, [startDate, endDate, isEnable]);

  useEffect(() => {
    if (userData && userData.contact != null) {
      setCompanyName(userData.contact.companyName);
      setAddressLine1(userData.contact.addressLine1);
      setAddressLine2(userData.contact.addressLine2);
      setAddressLine3(userData.contact.addressLine3);
      setCountry(userData.contact.country);
      setCity(userData.contact.city);
      setZipCode(userData.contact.zipCode);
      setTaxNumber(userData.contact.taxNumber);
      setIsDownload(true);
    }
    if (userData?.contact) {
      setIsEnable(false);
    }
  }, [userData]);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };

  const findParentFolder = () => {
    const findListParentFolder = separateFolders(listFolder).filter((folder) =>
      listFolderSelected.includes(folder.id)
    );
    return findListParentFolder.map((folder) => folder.id);
  };

  // this is custom tag render for select
  /* eslint react/prop-types: 0 */
  const TagRender: TTagRender = (props) => {
    const { label, onClose, value } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={findParentFolder().includes(value)}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <BasicSection
      className="invoices-section mb-9"
      header={t('Pages.AccountSetting.Invoices.SubTitle')}
      id={EAccountSettingId.INVOICES}
    >
      <div className="text mb-2">{t('Pages.AccountSetting.Invoices.EnterYourDetails')}</div>
      <input
        className="mb-2"
        type="text"
        placeholder={t('Pages.AccountSetting.Invoices.CompanyOrClientName')}
        value={companyName || ''}
        onChange={(e) => {
          setCompanyName(e.target.value);
        }}
        disabled={!isEnable}
        onFocus={(e) => {
          e.target.select();
        }}
      />
      <input
        className="mb-2"
        type="text"
        placeholder={t('Pages.AccountSetting.Invoices.AddressLine1')}
        value={addressLine1 || ''}
        onChange={(e) => {
          setAddressLine1(e.target.value);
        }}
        disabled={!isEnable}
        onFocus={(e) => {
          e.target.select();
        }}
      />
      <input
        className="mb-2"
        type="text"
        placeholder={t('Pages.AccountSetting.Invoices.AddressLine2')}
        value={addressLine2 || ''}
        onChange={(e) => {
          setAddressLine2(e.target.value);
        }}
        disabled={!isEnable}
        onFocus={(e) => {
          e.target.select();
        }}
      />
      <input
        className="mb-2"
        type="text"
        placeholder={t('Pages.AccountSetting.Invoices.AddressLine3')}
        value={addressLine3 || ''}
        onChange={(e) => {
          setAddressLine3(e.target.value);
        }}
        disabled={!isEnable}
        onFocus={(e) => {
          e.target.select();
        }}
      />
      <input
        className="mb-2"
        type="text"
        placeholder={t('Pages.AccountSetting.Invoices.Country')}
        value={country || ''}
        onChange={(e) => {
          setCountry(e.target.value);
        }}
        disabled={!isEnable}
        onFocus={(e) => {
          e.target.select();
        }}
      />
      <input
        className="mb-2"
        type="text"
        placeholder={t('Pages.AccountSetting.Invoices.City')}
        value={city || ''}
        onChange={(e) => {
          setCity(e.target.value);
        }}
        disabled={!isEnable}
        onFocus={(e) => {
          e.target.select();
        }}
      />
      <input
        className="mb-2"
        type="text"
        placeholder={t('Pages.AccountSetting.Invoices.ZipCode')}
        value={zipCode || ''}
        onChange={(e) => {
          setZipCode(e.target.value);
        }}
        disabled={!isEnable}
        onFocus={(e) => {
          e.target.select();
        }}
      />
      <input
        className="mb-2"
        type="text"
        placeholder={t('Pages.AccountSetting.Invoices.TaxNumber')}
        value={taxNumber || ''}
        onChange={(e) => {
          setTaxNumber(e.target.value);
        }}
        disabled={!isEnable}
        onFocus={(e) => {
          e.target.select();
        }}
      />
      <div className="d-flex justify-content-end mb-2">
        {isEnable ? (
          <button className="btn-save-invoice-infor" onClick={handleChangeContact}>
            {t('Shared.Actions.Save')}
          </button>
        ) : (
          <button
            className="btn-edit-invoice-infor"
            onClick={() => {
              setIsEnable(true);
            }}
          >
            {t('Shared.Actions.Edit')}
          </button>
        )}
      </div>

      {listFolder.length > 0 && (
        <div className="choose-folder p-2 mb-2">
          <div className="label mb-2">{t('Pages.AccountSetting.Invoices.FilterFolders')}:</div>
          <Select
            tagRender={TagRender}
            value={listFolderSelected}
            mode="multiple"
            virtual={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            style={{ width: '100%' }}
            placeholder={'Select folder'}
            onChange={onChangeListFolderSelected}
            suffixIcon
            options={separateFolders(listFolder).map((folder) => ({
                  key: folder.id,
                  label: `${folder.encodedParentId ? 'ー' : ''}${folder.name}`,
                  value: folder.id
                }))
              }
          />
        </div>
      )}

      <div className="select-date p-2">
        <div className="row mb-2">
          <div className="col-6">
            <div className="label mb-2">{t('Shared.Fields.StartDate')}</div>
            <DatePicker
              format="MM/DD/YYYY"
              defaultValue={dayjs(startDate)}
              disabledDate={disabledDate}
              onChange={(e) => e && setStartDate(e.toDate())}
              size="large"
              className="date-picker-custom"
              suffixIcon={<></>}
            />
          </div>
          <div className="col-6">
            <div className="label mb-2">{t('Shared.Fields.EndDate')}</div>
            <DatePicker
              format="MM/DD/YYYY"
              defaultValue={dayjs(endDate)}
              disabledDate={disabledDate}
              onChange={(e) => e && setEndDate(e.toDate())}
              size="large"
              className="date-picker-custom"
              suffixIcon={<></>}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div
              className={`${
                checkValidDate({ startDate, endDate }) ? '' : 'text-danger'
              } status-text`}
            >
              {statusSelectDate
                ? statusSelectDate
                : t('Pages.AccountSetting.Invoices.DownloadInvoice')}
            </div>
          </div>
          <div className="col-6 text-center">
            <button
              className="btn-download-invoice"
              disabled={!isDownload || !checkValidDate({ startDate, endDate })}
              onClick={handleDownloadInvoice}
            >
              {t('Shared.Actions.Download')}
            </button>
          </div>
        </div>
      </div>
    </BasicSection>
  );
}
