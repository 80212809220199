import { createSlice } from '@reduxjs/toolkit';
import { TLanguage } from 'ts/types';
import { getListLanguagesAction, getListLanguagesAdminAction } from './language.action';
import { LanguagesConvert } from 'ts/types/interface';

type LanguageState = {
  listAllLanguages: {
    data: TLanguage[] | null;
    isLoading: boolean;
    isFetched: boolean;
    error: any;
  };
  listAllLanguagesAdmin: {
    data: LanguagesConvert[];
    isLoading: boolean;
    isFetched: boolean;
    error: any;
  };
};

const initialLanguageState: LanguageState = {
  listAllLanguages: {
    data: [],
    isLoading: false,
    isFetched: false,
    error: null
  },
  listAllLanguagesAdmin: {
    data: [],
    isLoading: false,
    isFetched: false,
    error: null
  }
};

const languageSlice = createSlice({
  name: 'language',
  initialState: initialLanguageState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getListLanguagesAction.pending, (state) => {
      state.listAllLanguages = { data: null, isLoading: true, isFetched: false, error: null };
    });
    builder.addCase(getListLanguagesAction.fulfilled, (state, { payload }) => {
      state.listAllLanguages = { data: payload, isLoading: false, isFetched: true, error: null };
    });
    builder.addCase(getListLanguagesAction.rejected, (state, { payload }) => {
      state.listAllLanguages = { data: null, isLoading: false, isFetched: true, error: payload };
    });

    builder.addCase(getListLanguagesAdminAction.pending, (state) => {
      state.listAllLanguagesAdmin = {
        ...state.listAllLanguagesAdmin,
        isLoading: true,
        isFetched: false,
        error: null
      };
    });
    builder.addCase(getListLanguagesAdminAction.fulfilled, (state, { payload }) => {
      if (payload) {
        const resRegions = payload.filter((lang) => lang.regions.length > 0);
        const newListLang = resRegions.flatMap((lang) =>
          lang.regions.map((region) => ({
            id: `L${lang.id}-R${region.id}`,
            languageRegion: `${lang.label} (${region.name})`,
            direction: lang.direction,
            code: `${lang.code}-${region.code}`,
            diarizationSupported: region.diarizationSupported,
            defaultSpeechApi: region.defaultSpeechApi,
            backupSpeechApi: region.backupSpeechApi,
            regionId: region.id
          }))
        );

        const sortedListLang = newListLang.slice().sort((a, b) => {
          const languageRegionA = a.languageRegion.toLowerCase();
          const languageRegionB = b.languageRegion.toLowerCase();

          if (languageRegionA < languageRegionB) {
            return -1;
          }

          if (languageRegionA > languageRegionB) {
            return 1;
          }

          return 0;
        });
        state.listAllLanguagesAdmin = {
          data: sortedListLang,
          isLoading: false,
          isFetched: true,
          error: null
        };
      }
    });
    builder.addCase(getListLanguagesAdminAction.rejected, (state, { payload }) => {
      state.listAllLanguagesAdmin = {
        data: [],
        isLoading: false,
        isFetched: true,
        error: payload
      };
    });
  }
});

export default languageSlice.reducer;
