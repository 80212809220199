import { Button, Select, notification } from 'antd';
import { getDetailTransaction, updateProjectStatus } from 'apis/admin/lastestTransaction.api';
import { InputTime } from 'components/common/InputTime';
import { useLoaderContext } from 'context/LoaderContext';
import { usePageLoading } from 'context/PageLoadingContext';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EHttpStatusCode, ERoutes, EStatusProjectModify } from 'ts/enums';
import { TLogData, TProjectTransaction } from 'ts/types';
import { changeInlineStyle } from 'utils/string';
import { convertTime } from 'utils/time';

function DetailTransactionPage() {
  const { transactionId } = useParams();
  const [transaction, setTransaction] = useState<TProjectTransaction | null>(null);
  const { openMessage, destroyMessage } = usePageLoading();
  const [openEditProjectStatus, setOpenEditProjectStatus] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string | null>(null);
  const navigate = useNavigate();
  const { loader } = useLoaderContext();

  useEffect(() => {
    if (transactionId) {
      handleGetTransactionDetail({ id: transactionId });
    }
  }, [transactionId]);

  useEffect(() => {
    if (transaction) {
      setCurrentStatus(transaction.status);
    }
  }, [transaction]);

  async function handleGetTransactionDetail({ id }: { id: string }) {
    try {
      if (id) {
        loader.start();
        destroyMessage();
        openMessage('loading', 'Fetching....');
        const res = await getDetailTransaction({ transactionId: id });
        if (res) {
          setTransaction(res);
          destroyMessage();
        }
        loader.complete();
      }
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  async function handleUpdateProjectStatus() {
    try {
      if (transactionId && currentStatus) {
        loader.start();
        destroyMessage();
        openMessage('loading', 'Saving....');
        const res = await updateProjectStatus({ projectId: transactionId, status: currentStatus });
        if (res === EHttpStatusCode.OK) {
          destroyMessage();
          openMessage('success', 'Update successfully....');
          setOpenEditProjectStatus(false);
          handleGetTransactionDetail({ id: transactionId });
        }
        loader.complete();
      }
    } catch (error: any) {
      notification.open({ type: 'error', message: error.message });
    }
  }

  return (
    <div>
      <div className="bg-body py-2 border-bottom position-sticky top-0" style={{ zIndex: 1 }}>
        <div className="container">
          <FaHome
            size={30}
            color="var(--si-primary)"
            onClick={() => navigate(ERoutes.LASTEST_TRANSACTION)}
            className="cursor-pointer"
          />
          <span
            className="align-middle ms-1 fw-light"
            style={{ color: 'var(--si-primary)', fontSize: 28 }}
          >
            Detailed Transaction
          </span>
        </div>
      </div>
      <div className="container py-2">
        <div className="row g-2 mb-3">
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">PROJECT CREATED DATE-TIME (EST-TIME)</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
              {moment(transaction?.creationDate).format('M/DD/YY hh:mm A')}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">TRANSCRIBE START DATE-TIME (EST-TIME)</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
              {transaction?.transcribeDate
                ? moment(transaction?.transcribeDate).format('M/DD/YY hh:mm A')
                : ''}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">TIME TO TRANSCRIBE</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
              {transaction?.timeOfTranscribe}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">OWNER</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
              <Link to={`${ERoutes.USER_LOOKUP}/${transaction?.userId}`} className="custom-a-tag">
                {transaction?.userEmail} / {transaction?.username}
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">
                PROJECT STATUS{' '}
                <span
                  className="cursor-pointer"
                  style={{ color: 'var(--si-primary)' }}
                  onClick={() => setOpenEditProjectStatus(!openEditProjectStatus)}
                >
                  MODIFY STATUS
                </span>
              </small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
              <Select
                disabled={!openEditProjectStatus}
                variant="borderless"
                className="w-100"
                value={currentStatus}
                onChange={(e) => setCurrentStatus(e)}
                style={{ width: 120 }}
                options={Object.values(EStatusProjectModify).map((val) => {
                  return {
                    value: val.toLowerCase(),
                    label: val
                  };
                })}
              />
              {openEditProjectStatus && (
                <div className="mt-2">
                  <Button
                    type="primary"
                    className="me-1"
                    disabled={currentStatus === transaction?.status}
                    onClick={handleUpdateProjectStatus}
                  >
                    Save
                  </Button>
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      setOpenEditProjectStatus(false);
                      if (transaction) {
                        setCurrentStatus(transaction.status);
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">PROJECT NAME</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
              <Link to={`${ERoutes.PROJECT_DETAIL_VIEW}/${transactionId}`} className="custom-a-tag">
                {transaction?.name}
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">PROJECT URL</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
              <Link to={`${ERoutes.TRANSCRIPT_DETAIL}/${transaction?.id}`} className="custom-a-tag">
                Go to project
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">TRANSLATED FROM</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
              <Link
                to={`${ERoutes.PROJECT_DETAIL_VIEW}/${transaction?.translatedFromProjectId}`}
                className="custom-a-tag"
              >
                {transaction?.translatedFromProjectName}
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">AMOUNT SPENT</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
              {transaction?.credit && transaction?.credit > 0
                ? 'Credit used'
                : 'Charged to credit card'}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">PROJECT LANGUAGE</small>
            </div>
            <div
              className="border rounded px-2 py-1"
              style={{ minHeight: 42 }}
              dangerouslySetInnerHTML={{
                __html: changeInlineStyle(transaction?.languageDisplayName)
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">LENGTH OF PROJECT</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
              {transaction?.totalLengthOfProject}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">CREDITS USED</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
              {transaction?.credit} mins
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">AMOUNT SPENT</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
              ${transaction?.price}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-1">
              <small className="fw-bold">APPLICATION TYPE</small>
            </div>
            <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
              {transaction?.applicationType}
            </div>
          </div>
        </div>
        <h4 className="mb-3">File(s):</h4>
        {transaction?.files &&
          transaction?.files.length > 0 &&
          transaction?.files.map((file, index) => {
            return (
              <div key={file.id} className="row g-2 mb-3 border-bottom pb-3">
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">
                      FILE {index + 1}
                      {file.audioFileForBrowserPath && (
                        <>
                          {` / `}
                          <a
                            href={file.audioFileForBrowserPath}
                            target="_blank"
                            rel="noreferrer"
                            className="custom-a-tag"
                          >
                            S3 URL
                          </a>
                        </>
                      )}
                    </small>
                  </div>
                  <div className="border rounded px-2 py-1">
                    <a
                      className=" custom-a-tag"
                      style={{ minHeight: 42 }}
                      href={file.audioFileForBrowserPath}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {file.name}
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">ID</small>
                  </div>
                  <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
                    {file.id}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">LENGTH</small>
                  </div>
                  <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
                    {convertTime(file.duration)}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">STATUS</small>
                  </div>
                  <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
                    {file.status}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">{`TIMECODE 
                    ${file.timeCode?.includes(';') ? `(HH:MM:SS;FF)` : `(HH:MM:SS:FF)`}`}</small>
                  </div>
                  <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
                    <InputTime
                      userDefinedTimeCode={file.userDefinedTimeCode}
                      frameVideo={file.userDefinedTimeCodeFrames}
                      isOneInput
                      readOnly
                      timeCode={file.timeCode}
                      isDropFrame={file.timeCode?.includes(';')}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-1">
                    <small className="fw-bold">FPS</small>
                  </div>
                  <div className="border rounded px-2 py-1" style={{ minHeight: 42 }}>
                    {file.fps}
                  </div>
                </div>
              </div>
            );
          })}
        <h4>Error Logs</h4>
        <div>
          <div className="row">
            <div className="col-2">
              <div className="fw-bold">File Id</div>
            </div>
            <div className="col-2">
              <div className="fw-bold">File Name</div>
            </div>
            <div className="col-3">
              <div className="fw-bold">Date</div>
            </div>
            <div className="col-5">
              <div className="fw-bold">Error</div>
            </div>
          </div>
          {transaction?.failedMediaEventLogs &&
            transaction?.failedMediaEventLogs.map((mediaEvent) => {
              const logData: TLogData = JSON.parse(mediaEvent.logData);
              return (
                <div key={logData.id} className="row">
                  <div className="col-2">
                    <div>{logData.id}</div>
                  </div>
                  <div className="col-2">
                    <div>{logData.name}</div>
                  </div>
                  <div className="col-3">
                    <div>
                      {moment(logData.startedTranscriptionTimeInUtc).format(
                        'MMM D, YYYY hh:mm:ss A [UTC]'
                      )}
                    </div>
                  </div>
                  <div className="col-5">
                    <div>{logData.errorLog}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default DetailTransactionPage;
