import { CreditCard, ModalBase, TimeDisplay } from 'components/common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { popModal, pushModal } from 'redux/modal/modal.slice';
import { EModals, EPayType, EPlanOption, ETypeNoti } from 'ts/enums';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import {
  convertCreditToHourMinute,
  convertDurationToHourMinute,
  pushStripeTransactionTracking
} from 'utils/common';
import { TResponseTranslatePrice } from 'ts/types/TResponse';
import { Link, useParams } from 'react-router-dom';
import { TChangeTime, TLanguageDubbing, TSpeaker } from 'ts/types';
import { getPriceWithPayType } from 'apis/price.api';
import {
  checkoutBeforeDubbing,
  checkoutBeforeDubbingWithoutCredit,
  dubbing
} from 'apis/export.api';
import { Loader } from 'components/common/Loader';
import { useCreditCard } from 'context/CreditCardContext';
import { changeNumberToCurrency } from 'utils/price';

export function PriceDubbingModal() {
  const dispatch = useAppDispatch();
  const [price, setPrice] = useState<TResponseTranslatePrice | null>(null);
  const params = useParams();
  const { projectId } = params;
  const currentMedia = useAppSelector((state) => state.media.currentMedia);
  const userData = useAppSelector((state) => state.auth.currentUser.data);
  const currentProject = useAppSelector((state) => state.projects.currentProject.data);
  const lastTranscript = useAppSelector((state) => state.transcription.lastTranscript);
  const [changeAbleTime, setChangeAbleTime] = useState<TChangeTime>({ hour: 0, minutes: 0 });
  const modalData = useAppSelector((state) => state.modal.lastModal?.data);
  const [loading, setLoading] = useState<boolean>(false);
  const currentUser = useAppSelector((state) => state.auth.currentUser.data);
  const [nameMedia, setNameMedia] = useState<string>('');
  const [timeMedia, setTimeMedia] = useState<number>(0);
  const {
    cardData,
    cardName,
    cvcData,
    expiryMonthData,
    expiryYearData,
    isUpdateCardSaved,
    isExistedCard,
    isReadyCard,
    triggerShowError
  } = useCreditCard({
    currentUser
  });
  const { t } = useTranslation();

  const durationToTime = (price?.totalDuration &&
    convertDurationToHourMinute(price?.totalDuration)) || {
    hour: 0,
    minutes: 0
  };

  const creditToTime = (price?.credit && convertCreditToHourMinute(price?.credit)) || {
    hour: 0,
    minutes: 0
  };

  async function getPriceData() {
    if (projectId && currentMedia) {
      return await getPriceWithPayType({
        projectId,
        mediaId: currentMedia.id,
        payType: EPayType.DUB
      });
    } else return null;
  }

  async function handleDubbing() {
    if (!isReadyCard && !isExistedCard && price && price.totalPrice > 0) {
      triggerShowError();
      return;
    }
    if (loading) return;
    setLoading(true);
    if (
      currentProject &&
      currentMedia &&
      cardData &&
      modalData.dubbingData &&
      lastTranscript &&
      modalData.defaultDubbingLang
    ) {
      const speakersDubdding: { ssmlGender: string; voiceName: string }[] = lastTranscript.map(
        (transcription) => {
          return {
            voiceName: transcription.speaker
              ? modalData.dubbingData.find(
                  (dubbingData: { lang: TLanguageDubbing; speaker: TSpeaker }) =>
                    dubbingData.speaker.id === transcription.speaker?.id
                ).lang?.name
              : modalData.defaultDubbingLang.name,
            ssmlGender: transcription.speaker
              ? modalData.dubbingData.find(
                  (dubbingData: { lang: TLanguageDubbing; speaker: TSpeaker }) =>
                    dubbingData.speaker.id === transcription.speaker?.id
                ).lang?.ssmlGender
              : modalData.defaultDubbingLang.ssmlGender
          };
        }
      );

      const checkoutData =
        price && price.totalPrice > 0
          ? isExistedCard
            ? await checkoutBeforeDubbing({
                projectId: currentProject.id,
                mediaId: currentMedia.id
              })
            : await checkoutBeforeDubbingWithoutCredit({
                projectId: currentProject.id,
                mediaId: currentMedia.id,
                cardHolderName: cardName.data,
                cvc: cvcData.data,
                exp_month: expiryMonthData.data,
                exp_year: expiryYearData.data,
                number: cardData?.cardNumber,
                saveCard: isUpdateCardSaved
              })
          : await checkoutBeforeDubbing({
              projectId: currentProject.id,
              mediaId: currentMedia.id
            });

      if (checkoutData && 'message' in checkoutData && checkoutData.message !== null) {
        setLoading(false);
        dispatch(
          pushModal({
            name: EModals.NOTI_MODAL,
            data: null,
            notiData: { type: ETypeNoti.ERROR, title: `${checkoutData.message}` }
          })
        );
        return;
      }

      if (checkoutData && !('message' in checkoutData)) {
        if (checkoutData.stripeTransactionDetail)
          pushStripeTransactionTracking(checkoutData.stripeTransactionDetail);

        const res = await dubbing({
          projectId: currentProject.id,
          eventLogId: checkoutData.eventLogId,
          mediaId: currentMedia.id,
          projectName: currentProject.name,
          ttsCode: modalData.defaultDubbingLang?.languageCodes[0],
          speakers: speakersDubdding
        });

        if (res.status === 200) {
          setLoading(false);
          dispatch(popModal());
          dispatch(
            pushModal({
              name: EModals.NOTI_MODAL,
              data: null,
              notiData: {
                type: ETypeNoti.SUCCESS,
                title: t('Pages.TranscriptDetail.DubbingModal.SuccessDetail')
              }
            })
          );
        } else {
          setLoading(false);
          dispatch(popModal());
          dispatch(
            pushModal({
              name: EModals.NOTI_MODAL,
              data: null,
              notiData: {
                type: ETypeNoti.ERROR,
                title: t('Pages.TranscriptDetail.Errors.ErrorDubbing')
              }
            })
          );
        }
      }
    }
  }

  useEffect(() => {
    if (modalData && modalData.dubbingData) {
      (async () => {
        const res = await getPriceData();
        if (res) {
          setPrice(res);
          setChangeAbleTime(convertDurationToHourMinute(res.chargeableTimeInSeconds));
          const mediaDetail = JSON.parse(res.mediaDetails);
          if (mediaDetail.length > 0) {
            setNameMedia(mediaDetail[0].name);
            setTimeMedia(mediaDetail[0].duration);
          }
        }
      })();
    }
  }, [projectId, currentProject, currentMedia, modalData]);

  const mediaToTime = convertDurationToHourMinute(timeMedia) || {
    hour: 0,
    minutes: 0
  };

  if (price === null || (!price && !userData)) return null;

  return (
    <ModalBase
      modalName={EModals.PRICE_DUBBING_MODAL}
      onCloseModal={() => {
        dispatch(popModal());
      }}
    >
      <div className="ctn-payment-modal">
        <h4 className="text-center">{t('Pages.TranscriptDetail.DubbingModal.PriceModalTitle')}</h4>

        <div
          className="ctn-cost rounded border row p-2 mx-0"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <div className="d-flex flex-column align-items-center justify-content-center col-6">
            <h4 style={{ color: 'var(--si-old-silver)' }}>{t('Shared.Fields.TotalCost')}</h4>
            <div>
              <span style={{ fontSize: 22, fontWeight: 700 }}>
                {changeNumberToCurrency(price?.totalPrice || 0).prevPriod}
              </span>
              .{changeNumberToCurrency(price?.totalPrice || 0).afterPriod} USD
            </div>
          </div>
          <div className="border-start col-6" style={{ fontSize: 10, paddingLeft: 0 }}>
            <div className="ps-5">
              <div style={{ color: 'var(--si-old-silver)', lineHeight: '12px' }}>
                <span>{t('Pages.TranscriptDetail.TranslateModal.TotalDurationToTranslate')}</span>
              </div>
              <div>
                <TimeDisplay
                  hours={durationToTime.hour}
                  minutes={durationToTime.minutes}
                  isVisibleInfo={false}
                  nameMedia={nameMedia}
                  minuteMedia={mediaToTime.minutes}
                  hoursMedia={mediaToTime.hour}
                />
              </div>
              {price && price.credit > 0 && (
                <>
                  <div style={{ color: 'var(--si-old-silver)' }}>
                    <small>{t('Shared.Fields.CreditAvailable')}:</small>
                  </div>
                  <div>
                    <TimeDisplay hours={creditToTime.hour} minutes={creditToTime.minutes} />
                  </div>
                </>
              )}

              {price && price.credit > 0 && (
                <>
                  <div style={{ color: 'var(--si-old-silver)' }}>
                    <small>{t('Shared.Fields.ChargeableTime')}:</small>
                  </div>
                  <div>
                    <TimeDisplay hours={changeAbleTime.hour} minutes={changeAbleTime.minutes} />
                  </div>
                </>
              )}
              {price && price.chargeableTimeInSeconds > 0 && (
                <>
                  <div style={{ color: 'var(--si-old-silver)' }}>
                    <small>{t('Shared.Fields.HourlyTranscriptionRate')}:</small>
                  </div>
                  <span className="fw-bolder">
                    ${price?.rate}/{t('Shared.Fields.hour', { count: 0 })}
                  </span>
                </>
              )}
              {price &&
                price.chargeableTimeInSeconds > 0 &&
                price.chargeableTimeInSeconds < price.minimumChargeableTimeInSeconds && (
                  <>
                    <div style={{ color: 'var(--si-old-silver)' }}>
                      <small>{t('Shared.Fields.MinimumCost')}:</small>
                    </div>
                    <div className="fw-bolder">
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                      }).format(price ? price.minimumPrice : 0)}
                    </div>
                  </>
                )}
                <div className='policy-text'>
                  <p className='mb-0 mt-2'>{ t('Pages.Main.PaymentModal.NoRefundPolicy') }</p>
                  <Link
                    to="https://www.simonsaysai.com/help/2657979-can-i-read-your-privacy-policy-terms-of-service"
                    target="_blank"
                    rel="noopener"
                    className="custom-a-tag"
                  >
                    {t("Shared.Texts.TermsAndConditions")}
                  </Link>
                </div>
            </div>
          </div>
        </div>
        {price && price.totalPrice > 0 && (
          <div className="mb-1">
            <CreditCard />
          </div>
        )}
        <div className="text-center">
          <button
            className="submit-button px-2 py-1"
            style={{ borderRadius: 98 }}
            onClick={handleDubbing}
            disabled={loading}
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                <FaWandMagicSparkles />
                <span className="ms-1">{t('Shared.Texts.LetsDoThis')}</span>
              </>
            )}
          </button>
        </div>
      </div>
    </ModalBase>
  );
}
