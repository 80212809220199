import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteProject,
  editProject,
  getListProject,
  getListProjectShareWithMe,
  getProjectById,
  moveProjectToFolder
} from 'apis/projects.api';
import {
  TRequestDeleteProject,
  TRequestEditProject,
  TRequestGetProjectById,
  TRequestMoveProjectToFolder
} from 'ts/types/TRequest';
import { TMedia, TProject, TUser } from 'ts/types';
import { changeMedia, createNewMedia } from 'apis/media.api';

export const getProjectByIdAction = createAsyncThunk(
  'projects/getProjectById',
  async ({ projectId }: TRequestGetProjectById, { rejectWithValue }) => {
    try {
      const res = await getProjectById({ projectId });
      return res;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateCurrentProjectAction = createAsyncThunk(
  'projects/updateProjectById',
  async ({ project }: TRequestEditProject, { rejectWithValue }) => {
    try {
      const res = await editProject({ project });
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListProjectAction = createAsyncThunk(
  'projects/getListProjects',
  async ({ currentUser }: { currentUser: TUser | null }, { rejectWithValue }) => {
    try {
      const res = await getListProject();
      return { data: res, user: currentUser } as { data: TProject[]; user: TUser };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListProjectShareWithMeAction = createAsyncThunk(
  'projects/getListProjectShareWithMe',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getListProjectShareWithMe();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const moveProjectToFolderAction = createAsyncThunk(
  'project/move',
  async ({ folder, projectsId, isFrom }: TRequestMoveProjectToFolder, { rejectWithValue }) => {
    try {
      await moveProjectToFolder({ folder, projectsId, isFrom });
      return { folder, projectsId };
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const deleteProjectAction = createAsyncThunk(
  'project/delete',
  async ({ project }: TRequestDeleteProject, { rejectWithValue }) => {
    try {
      await deleteProject({ project });
      return project;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeMediaForProjectAction = createAsyncThunk(
  'project/changeMedia',
  async ({ media, projectId }: { media: TMedia; projectId: string }, { rejectWithValue }) => {
    try {
      const res = await changeMedia({ media });
      return { res, projectId };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createNewMediaAction = createAsyncThunk(
  'project/createNewMedia',
  async ({ name, projectId }: { name: string; projectId: string }, { rejectWithValue }) => {
    try {
      const res = await createNewMedia({ name, projectId });
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
