import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCircleCheck } from "react-icons/fa6";
import { CouponVerification } from "ts/types/interface";
import { verifyCoupon } from "apis/userSetting.api";
import { useLoaderContext } from "context/LoaderContext";
import { usePageLoading } from "context/PageLoadingContext";
import { useAppDispatch } from "redux/hooks";
import { pushModal } from "redux/modal/modal.slice";
import { EModals, ETypeNoti } from "ts/enums";

type Props = {
  value: string;
  onChange: (value: string) => void;
  onCouponApply: (data: CouponVerification | null) => void;
  isVisible: boolean;
  defaultOpen: boolean;
  unapplyRequest?: boolean;
};

export function VoucherBox(props: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { loader } = useLoaderContext();
  const { openMessage, destroyMessage } = usePageLoading();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isOpenCouponBox, setIsOpenCouponBox] = useState<boolean>(
    props.defaultOpen
  );
  const [couponApplied, setCouponApplied] = useState<CouponVerification | null>(
    null
  );

  function onChange(value: string) {
    if (typeof props.onChange == "function") {
      props.onChange(value);

      if (couponApplied != null) {
        props.onCouponApply(null);
        setCouponApplied(null);
      }
    }
  }

  function onFormSubmit(e: React.FormEvent) {
    e.preventDefault();
    onClickApply();
  }

  async function onClickApply() {
    if (!props.value || loading) return;

    setLoading(true);
    loader.start();
    openMessage("loading", t("Shared.Texts.LoadingW3Dot"));

    const res = await verifyCoupon(props.value);

    if (res && "message" in res) {
      loader.complete();
      setLoading(false);
      destroyMessage();
      setCouponApplied(null);
      props.onCouponApply(null);

      dispatch(
        pushModal({
          name: EModals.NOTI_MODAL,
          data: null,
          notiData: { type: ETypeNoti.ERROR, title: res.message },
        })
      );

      return;
    } else if (res) {
      loader.complete();
      setLoading(false);
      destroyMessage();
      setCouponApplied(res);
      props.onCouponApply(res);
    }
  }

  useEffect(() => {
    if (couponApplied != null) {
      props.onCouponApply(null);
      setCouponApplied(null);
    }
  }, []);

  useEffect(() => {
    if (props.defaultOpen && props.value) {
      onClickApply();
    }
  }, [props.defaultOpen]);

  useEffect(() => {
    if(props.unapplyRequest) {
      if (couponApplied != null) {
        props.onCouponApply(null);
        setCouponApplied(null);
      }
    }
  }, [props.unapplyRequest])

  return (
    <section
      className={`voucher-box mt-1 p-2 ${props.isVisible ? "" : "d-none"}`}
    >
      <div>
        <div
          style={{ color: !isOpenCouponBox ? "var(--si-primary)" : "" }}
          className="cursor-pointer label"
          onClick={() => {
            setIsOpenCouponBox(!isOpenCouponBox);
            onChange("");
          }}
        >
          {t("Shared.Texts.ApplyACoupon")}
        </div>
        <div
          className={`input-group d-flex flex-column mt-1 ${
            isOpenCouponBox ? "d-block" : "d-none"
          }`}
        >
          <Row gutter={10}>
            <Col xl={10}>
              <form onSubmit={onFormSubmit}>
                <input
                  value={props.value || ""}
                  onChange={(e) => onChange(e.target.value)}
                  type="text"
                  placeholder={t("Shared.Texts.VoucherTip")}
                  required
                  className="rounded"
                  style={{ width: "100%" }}
                />
              </form>
            </Col>
            <Col xl={4}>
              <p
                onClick={onClickApply}
                className="custom-a-tag mb-0"
                style={{ paddingTop: "3px" }}
              >
                {couponApplied ? (
                  <FaCircleCheck size={16} style={{ paddingBottom: "2px" }} />
                ) : (
                  t("Shared.Actions.Apply")
                )}
              </p>
            </Col>
          </Row>
          {error && (
            <small style={{ color: "var(--bs-danger)" }}>{error}</small>
          )}
        </div>
      </div>
    </section>
  );
}
