import BoxccIcon from 'assets/icons/box-cc.svg';
import { PropsExport } from '.';
import { EExportName, EExportSubData } from 'ts/enums';
import { useTranslation } from 'react-i18next';

export function SubtitlesExport({ exportOption, setExportOption }: PropsExport) {
  const { t } = useTranslation();
  const subDataRender = [
    {
      key: EExportSubData.SRT,
      title: 'SRT',
      option: { withGapless: false },
      allowMultipleMedia: true
    },
    {
      key: EExportSubData.FACEBOOK_YOUTUBE,
      title: 'Facebook/ Youtube',
      option: {},
      allowMultipleMedia: true
    },
    {
      key: EExportSubData.WEB_VTT,
      title: 'WebVTT',
      option: { withGapless: false },
      allowMultipleMedia: true
    },
    {
      key: EExportSubData.AVID_SUBCAP,
      title: 'Avid SubCap',
      option: {},
      allowMultipleMedia: true
    },
    {
      key: EExportSubData.VISUAL_SUBTITLE_EDITOR,
      title: t('Layouts.ExportModal.VisualSubtitlesEditor.Title'),
      option: {
        characterPerLine: 32,
        withSingleLine: false,
        withUppercase: false
      },
      allowMultipleMedia: true
    },
    {
      key: EExportSubData.SAMI,
      title: 'SAMI',
      option: {},
      allowMultipleMedia: true
    },
    {
      key: EExportSubData.BURN_IN,
      title: 'Burn-in',
      option: {},
      allowMultipleMedia: true
    }
  ];

  return (
    <div className="subtitles-export export-item">
      <div className="d-flex flex-column justify-content-center align-items-center cursor-pointer"
        onClick={() => {
          setExportOption(draft => {
            draft.data.option = subDataRender[0].option;
            draft.data.subData = subDataRender[0].key;
            draft.key = EExportName.SUBTITLES;
            draft.allowMultipleMedia = true;
          })
        }}>
        <div
          className={`icon ${exportOption.key === EExportName.SUBTITLES ? 'opacity-100' : 'opacity-50'
            }`}
        >
          <img src={BoxccIcon} alt="" />
        </div>
        <div
          className={`text mt-1 ${exportOption.key === EExportName.SUBTITLES ? '--selected' : ''}`}
        >
          <span>{ t('Layouts.ExportModal.Subtitles.Title') }</span>
        </div>
      </div>
      <div
        className={`text mt-1 ${exportOption.key === EExportName.SUBTITLES ? '--selected' : ''}`}
      >
        {exportOption.key === EExportName.SUBTITLES && (
          <div className="choose-option mt-2">
            {subDataRender.map((s) => (
              <label className="d-flex align-items-center mb-1 gap-1 cursor-pointer" key={s.key}>
                <input
                  type="radio"
                  name="select"
                  defaultChecked={s.key === EExportSubData.SRT}
                  onClick={() => {
                    setExportOption(draft => {
                      draft.key = EExportName.SUBTITLES;
                      draft.data.subData = s.key;
                      draft.data.option = s.option;
                      draft.allowMultipleMedia = s.allowMultipleMedia;
                    })
                  }}
                />
                <span>{s.title}</span>
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
